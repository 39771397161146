import React from 'react'
import { Avatar, Button, List, Space, Layout, Divider, Typography } from 'antd';
import { UserOutlined, QqOutlined, } from '@ant-design/icons';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userState } from '../../atoms/Atoms'



const kf_avatar = 'http://static.yunzitui.com/upload/user/common/kf1.jpg';

const { Text } = Typography
const { Content } = Layout
const textGroupStyle = { display: 'flex', flexDirection: 'column', alignItems: 'start' }
const ServiceQQ = () => {
    const sessionUser = useRecoilValue(userState)

    return (
        <Content style={{ paddingLeft: '24px' }}>
            <Divider style={{ margin: '8px 0' }} />
            <List bordered={false}>
                <List.Item>
                    <Space>
                        <Avatar size={32} src={_.isNil(sessionUser.manager.manager_avatar) ? kf_avatar : sessionUser.manager.manager_avatar || kf_avatar} icon={<UserOutlined />} />
                        <div style={textGroupStyle}>
                            <Button style={{ padding: 0 }} onClick={() => window.open(`http://wpa.qq.com/msgrd?v=3&uin=${sessionUser.manager.manager_qq}&site=qq&menu=yes`, 'top')} type='link'>
                                <QqOutlined />专属客服
                            </Button>
                            <span>
                                QQ:<Text copyable>{sessionUser.manager.manager_qq}</Text>
                            </span>
                        </div>
                    </Space>
                </List.Item>
                <List.Item>
                    <Space>
                        <Avatar size={32} src={_.isNil(sessionUser.manager.platform_avatar) ? kf_avatar : sessionUser.manager.platform_avatar || kf_avatar} icon={<UserOutlined />} />
                        <div style={textGroupStyle}>
                            <Button style={{ padding: 0 }} onClick={() => window.open(`http://wpa.qq.com/msgrd?v=3&uin=${sessionUser.manager.platform_qq}&site=qq&menu=yes`, 'top')} type='link'>
                                <QqOutlined />平台客服
                            </Button>
                            <span>
                                QQ:<Text copyable>{sessionUser.manager.platform_qq}</Text>
                            </span>
                        </div>
                    </Space>
                </List.Item>
                <List.Item>
                    <Space>
                        <Avatar size={32} src={kf_avatar} icon={<UserOutlined />} />
                        <div style={textGroupStyle}>
                            <Button style={{ padding: 0 }} onClick={() => window.open(`http://wpa.qq.com/msgrd?v=3&uin=3001325409&site=qq&menu=yes`, 'top')} type='link'>
                                <QqOutlined />百科客服
                            </Button>
                            <span>
                                QQ:<Text copyable>3001325409</Text>
                            </span>
                        </div>
                    </Space>
                </List.Item>
                <List.Item>
                    <Space>
                        <Avatar size={32} src={kf_avatar} icon={<UserOutlined />} />
                        <div style={textGroupStyle}>
                            <Button style={{ padding: 0 }} onClick={() => window.open(`http://wpa.qq.com/msgrd?v=3&uin=3001364726&site=qq&menu=yes`, 'top')} type='link'>
                                <QqOutlined />代理加盟
                            </Button>
                            <span>
                                QQ:<Text copyable>3001364726</Text>
                            </span>
                        </div>
                    </Space>
                </List.Item>
                <List.Item>
                    <Space>
                        <Avatar size={32} src={kf_avatar} icon={<UserOutlined />} />
                        <div style={textGroupStyle}>
                            <Button style={{ padding: 0 }} onClick={() => window.open(`http://wpa.qq.com/msgrd?v=3&uin=3001330390&site=qq&menu=yes`, 'top')} type='link'>
                                <QqOutlined />投诉建议
                            </Button>
                            <span>
                                QQ:<Text copyable>3001330390</Text>
                            </span>
                        </div>
                    </Space>
                </List.Item>
            </List>
        </Content>
    )
}

export default ServiceQQ
