import React from 'react'
import { Tag, Typography, Button, Popconfirm, Space } from 'antd';
import { EditOutlined, DeleteOutlined, SettingOutlined, UnorderedListOutlined, DownloadOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';
import qs from 'qs';

import { userRoleState, } from '../../Apps/atoms/Atoms'
import { formatTime } from '../../utils/TimeUtils'
import { resultResponse } from '../../Apps/common/ResultResponse';

const axios = require('axios');
const { Text } = Typography

export const WorkOrderTableConfig = ({ modal, setModal, handleOrders }) => {

    const role = useRecoilValue(userRoleState)

    const { selectOrders, reloadTable, bindUser, confirmOrder, checkOrders, hideData } = handleOrders;
    const size = 'small'

    const deleteWorkOrder = async (id) => {
        await axios({
            method: 'delete',
            url: `/workOrder/delete/${id}`,
        }).then((res) => {
            resultResponse(res, '已删除')
            reloadTable()
        })
    }

    const editWorkOrder = async (id) => {
        await axios({
            method: 'post',
            url: `/workOrder/reedit/${id}`,
        }).then((res) => {
            resultResponse(res, '已修改')
            reloadTable()
        })
    }


    /**
     * 后款客户工单 已支付
     * @param {工单ID} id 
     */
    const paidOrder = async (id) => {
        await axios({
            method: 'post',
            url: `/workOrder/paid/${id}`,
        }).then((res) => {
            resultResponse(res, '已完成')
        })
    }

    const downloadOrders = async (id, title) => {
        await axios({
            method: 'post',
            url: `/workOrder/download/orders`,
            data: qs.stringify({
                sale_work_order_id: id,
                sale_work_order_status: 'PENDING'
            }),
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${title}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }


    const salesConfig = [
        {
            title: '销售名称',
            dataIndex: 'account',
            checked: 1,
            render: (val, data) => {
                return <Space direction='vertical'>
                    <Text>{val}</Text>
                    <Button size='small' type='link' onClick={() => hideData(data.id)}>隐藏</Button>
                </Space>
            }
        },
    ]

    const defaultConfig = [
        {
            title: '所属客户',
            dataIndex: 'customer_name',
            checked: 1,
            render: (t, data) => {
                if (!data.customer_name) {
                    return <Button type='link' size='small' onClick={() => bindUser(data.id)}>绑定用户</Button>
                } else {
                    return <Text>{t}</Text>
                }
            }
        }, {
            title: '名称',
            dataIndex: 'name',
            checked: 1,
        }, {
            title: '成本价格',
            dataIndex: 'price',
            checked: 1,
            render: (t, data) => {
                return <Space direction='vertical'>
                    <Text>成本价格: {data.price}</Text>
                    <Text>媒体售价: {data.paid_money}</Text>
                </Space>
            }
        }, {
            title: '销售总价格',
            dataIndex: 'sale_price',
            checked: 1,
            render: (t, data) => {
                return <Space direction='vertical'>
                    <Text>总售价: {data.sale_price}</Text>
                    <Text>其他资源售价: {(data.sale_price > 0 ? data.sale_price - data.paid_money : 0).toFixed(2)}</Text>
                </Space>
            }
        }, {
            title: '备注',
            dataIndex: 'remark',
            checked: 1,
        }
    ]

    const orderTime = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        },
        {
            title: '完成时间',
            dataIndex: 'finish_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        },
    ]

    const orderStatus = {
        title: '状态',
        dataIndex: 'state',
        width: 72,
        checked: 1,
        render: (text) => {
            let result = '';
            switch (text) {
                case 'PENDING':
                    result = <Tag style={{ margin: 0 }} color="blue">未完成</Tag>;
                    break;
                case 'CONFIRMED':
                    result = <Tag style={{ margin: 0 }} color="geekblue">待支付</Tag>;
                    break;
                case 'UNCHECKED':
                    result = <Tag style={{ margin: 0 }} color="gold">待审核</Tag>;
                    break;
                case 'UNPAID':
                    result = <Tag style={{ margin: 0 }} color="purple">未打款</Tag>;
                    break;
                case 'SUCCESS':
                    result = <Tag style={{ margin: 0 }} color="green">已支付</Tag>;
                    break;
                case 'FAILED':
                    result = <Tag style={{ margin: 0 }} color="red">已取消</Tag>;
                    break;
                default:
                    break;
            }
            return result
        }
    }

    const handleCol = [
        {
            title: '操作',
            dataIndex: 'id',
            checked: 1,
            render: (t, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text code style={{ marginBottom: '8px' }}>订单数量: {data.total}</Text>
                        <Button.Group>
                            {
                                data.state === 'PENDING' &&
                                <>
                                    <Popconfirm
                                        title="是否确认删除"
                                        onConfirm={() => deleteWorkOrder(data.id)}
                                        onCancel={() => { console.log('取消') }}
                                        okText="确认"
                                        cancelText="取消"
                                    >
                                        <Button icon={<DeleteOutlined />} size={size}>删除</Button>
                                    </Popconfirm>
                                    <Button size={size} onClick={() => selectOrders(data.id, data.customer_id)}><SettingOutlined />关联订单</Button>
                                </>
                            }
                            {
                                data.state === 'CONFIRMED' &&
                                <>
                                    <Popconfirm
                                        title="是否继续编辑"
                                        onConfirm={() => editWorkOrder(data.id)}
                                        onCancel={() => { console.log('取消') }}
                                        okText="确认"
                                        cancelText="取消"
                                    >
                                        <Button icon={<EditOutlined />} size={size}>编辑</Button>
                                    </Popconfirm>
                                </>
                            }
                            {
                                ((data.state !== 'PENDING' && data.state !== 'SUCCESS') && role === 'FINANCE') &&
                                <Popconfirm
                                    title="是否确认删除"
                                    onConfirm={() => deleteWorkOrder(data.id)}
                                    onCancel={() => { console.log('取消') }}
                                    okText="确认"
                                    cancelText="取消"
                                >
                                    <Button icon={<DeleteOutlined />} size={size}>删除</Button>
                                </Popconfirm>
                            }
                            <Button size={size} onClick={() => setModal({ ...modal, orders: true, id: data.id })} icon={<UnorderedListOutlined />}>查看</Button>
                            <Button size={size} onClick={() => downloadOrders(data.id, data.name)} icon={<DownloadOutlined />}>下载</Button>
                        </Button.Group>
                    </div>
                )
            }
        }, {
            title: '处理订单',
            dataIndex: 'id',
            checked: 1,
            render: (t, data) => {
                // 待支付部分 分为直接打款的客户之后开发票 和 先开发票后打款的客户
                switch (role) {
                    case "SALE_LEADER":
                    case "SALE_ATTACHE":
                    case "SALE":
                    case 'FRANCHISE':
                        return (
                            <Space>
                                {
                                    data.state === 'PENDING' && <>
                                        {data.total > 0 && <Button type='primary' size={size} onClick={() => confirmOrder(data.id, "CONFIRMED")}>完成</Button>}
                                        <Button danger size={size} onClick={() => confirmOrder(data.id, "FAILED")}>取消</Button>
                                    </>
                                }
                                {
                                    data.state === 'CONFIRMED' && <>
                                        <Button type='primary' size={size} onClick={() => setModal({ ...modal, finish: true, id: data.id, paid: true })}>已结算</Button>
                                        <Button size={size} onClick={() => setModal({ ...modal, finish: true, id: data.id, paid: false })}>需开票</Button>
                                    </>
                                }
                            </Space>
                        )
                    case 'FINANCE':
                        if (!data.customer_name) {
                            return <Text mark>请绑定所属客户</Text>
                        } else {
                            return (
                                <Space>
                                    {
                                        data.state === 'UNCHECKED'
                                        &&
                                        <Popconfirm
                                            title="是否确认已打款"
                                            onConfirm={() => checkOrders(data.id)}
                                            onCancel={() => { console.log('取消') }}
                                            okText="确认"
                                            cancelText="取消"
                                        >
                                            <Button type='primary' size={size}>通过</Button>
                                        </Popconfirm>
                                    }
                                    {
                                        data.state === 'UNPAID'
                                        &&
                                        <Popconfirm
                                            title="是否确认已打款"
                                            onConfirm={() => paidOrder(data.id)}
                                            onCancel={() => { console.log('取消') }}
                                            okText="确认"
                                            cancelText="取消"
                                        >
                                            <Button type='primary' size={size}>已打款</Button>
                                        </Popconfirm>
                                    }
                                </Space>
                            )
                        }
                    default:
                        break;
                }

            }
        }
    ]

    let result = []

    switch (role) {
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case "SALE":
        case 'FRANCHISE':
        case 'FINANCE':
            result = _.concat(
                salesConfig,
                defaultConfig,
                orderTime,
                orderStatus,
                handleCol,
            )
            break;
        default:
            break;
    }
    return result
}
