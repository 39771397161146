import { Button, Col, Divider, Form, Input, Layout, message, Row, Tag } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { tablePagination } from '../../common/tableCommon';

const { Content } = Layout;
const { CheckableTag } = Tag;
const layout = { layout: 'vertical' };
const axios = require('axios');

const MediaTagTable = () => {

    const [form] = Form.useForm()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ ...tablePagination, pageSize: 50 })
    const [selected, setSelected] = useState([])

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        await axios({
            method: 'post',
            url: '/tag/myList',
            data: params
        }, {
            cancelToken: source.token
        }).then((res) => {
            setData(res.data.data)
            let total = res.data.recordsFiltered
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    return <>
        <Content className='table-search'>
            <Form
                {...layout}
                name="basic"
                form={form}
                onFinish={val => fetchData(_.pickBy(val, _.identity))}
            >
                <Form.Item label='标签' name='tag'>
                    <Input />
                </Form.Item>
                <Divider style={{ margin: '8px 0' }} />
                <Row>
                    <Col span={12}>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>
                    </Col>
                </Row>
            </Form>
            <>
                <span style={{ marginRight: 8 }}>标签:</span>
                {data.map(tag => (
                    <CheckableTag
                        key={tag.id}
                        checked={_.indexOf(selected, tag.id) !== -1}
                        onChange={checked => {
                            if (checked && _.size(selected) >= 5) {
                                message.warning("最多选择5个标签")
                                return;
                            }
                            const nextSelectedTags = checked ? _.concat(selected, tag.id) : _.pull(selected, tag.id);
                            setSelected(_.uniq(nextSelectedTags))
                        }}
                    >
                        {tag.tag}
                    </CheckableTag>
                ))}
            </>
        </Content>
    </>;
}


export default MediaTagTable
