import React from 'react';
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import WechatTable from '../wechat/WechatTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;

// /dashboard/resource/weibo/hot
// 入住编辑页面
const EditorWechat = () => {

    let pageTitle = "我的微信"
    const action = 'EDIT'

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content>
                <div style={{ margin: 24 }}>
                    <WechatTable action={action} url='/wechat/editor/list' />
                </div>
            </Content>
        </HelmetProvider>

    )
}

export default EditorWechat;