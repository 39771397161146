import React, { useState, useEffect } from 'react';
import { Form, Table, Button, Row, Col, Avatar, Typography, Divider, Input, Layout } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { initFormData, tablePagination } from '../../common/tableCommon';
import qs from 'qs';
import { resultResponse } from '../../common/ResultResponse';

const { Content } = Layout;
const { Text, Paragraph } = Typography;

const axios = require('axios');

// 销售端管理员(FRANCHISE) 组长关联组员子账号 查看组员
const UserChildTable = ({ uid, resetVisible, reloadTable }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("attache", 1)
        _.toPairs(val).filter(e => e[1] !== '').map(e => params.append(`${e[0]}`, e[1]))
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/user/getUserList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [pagination.current, pagination.pageSize])

    // 编辑用户管理
    const editUserChild = async (id) => {
        await axios({
            method: 'post',
            url: `/user/edit/child`,
            data: qs.stringify({ child: id, id: uid })
        }).then((res) => {
            resultResponse(res, '已关联账号')
            resetVisible()
            reloadTable()
        })
    }


    const columns = [
        {
            title: '',
            dataIndex: 'avatar_img',
            width: 40,
            render: (t, data) => {
                let base_img = data.avatar_img ? data.avatar_img + "_xs" : `http://origin-static.oss-cn-beijing.aliyuncs.com/static/images/defaultPic.jpg_xs`;
                return <Avatar shape="square" icon={<UserOutlined />} src={base_img} />;
            }
        }, {
            title: '账号名',
            dataIndex: 'account',
            render: (t, data) => {
                return (
                    <>
                        <div className='flex-justify'>
                            <Text>{data.sell_group_name}</Text>
                        </div>
                        <div className='flex-justify'>
                            <Text strong>{t}</Text>
                        </div>
                    </>
                )
            }
        }, {
            title: '备注',
            render: (text, data) => {
                return <Paragraph>{data.remark}</Paragraph>
            }
        }, {
            title: '关联账号',
            dataIndex: 'id',
            width: 190,
            fixed: 'right',
            render: (text, data) => {
                return <Button type='link' onClick={() => editUserChild(data.id)}>关联</Button>
            }
        }
    ]

    return <>
        <Content style={{ background: '#fff' }}>
            <div style={{ paddingBottom: 16 }}>
                <Form layout='vertical' name="basic" onFinish={val => fetchData(_.pickBy(val, _.identity))}>
                    <Form.Item label="用户名" name='account'>
                        <Input />
                    </Form.Item>
                    <Divider style={{ margin: '8px 0' }} />
                    <Row>
                        <Col span={12}></Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Table
                size="small"
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                }}
            />
        </Content>
    </>;
}


export default UserChildTable
