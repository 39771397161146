import React from 'react';
import { Layout,  } from 'antd';
import ConsumeTable from './ConsumeTable'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;

const Consumes = ({ size }) => {
    let pageTitle = '消费列表'

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content style={{ margin: 24 }}>
                <ConsumeTable rowSelectionDisplay={false} />
            </Content>
        </HelmetProvider>
    )
}

export default Consumes;