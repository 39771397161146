import React, { useEffect, useState } from 'react';
import {
    Button, Card, Col, Form, Modal, Row, Table, Space
} from 'antd';
import _ from 'lodash';

import SearchForm from '../../../common/SearchForm';
import { initFormData, tablePagination } from '../../../common/tableCommon';
import { SalaryTableConfig } from '../../../../role/user/SalaryTableConfig';
import UserSalaryForm from '../UserSalaryForm';


const layout = { layout: 'vertical' };
const axios = require('axios');
const initialModal = {
    type: '', addUser: false, recharge: false, id: '',
    editUser: false, group: false, sale_group_id: '',
    changeManage: false, consume: false, loan: false
}

// 销售工资构成
function SalaryTable({ searchFormDisplay = true, url, dispatch }) {

    const searchDatas = [
        {
            name: 'sale_name',
            label: '销售名称',
            placeholder: '请输入销售名称',
            type: 'input',
        }, {
            name: 'post_name',
            label: '岗位名称',
            placeholder: '请输入岗位名称',
            type: 'input',
        }, {
            name: 'status',
            label: '是否在职',
            type: 'radio',
            value: '1',
            children: [
                ['所有', ''],
                ['正常', '1'],
                ['离职', '0'],
            ]
        },
    ]
    const initialFormData = initFormData(searchDatas)

    const [form] = Form.useForm()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState(initialModal)

    const reloadTable = () => form.submit()
    const resetModal = () => setModal(initialModal)

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function fetchData(val = _.pickBy(initialFormData, _.identity)) {

        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))

        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/user/salary/list',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])


    const modifyUserSalary = (id) => setModal({ ...modal, salary: true, id: id })
    const handleUsers = { modifyUserSalary }
    const columns = SalaryTableConfig({ visible: modal, setVisible: setModal, handleUsers, dispatch })

    return <>
        <Space direction='vertical' size={16} className='full-width'>
            <Card>
                {searchFormDisplay && <div>
                    <Form
                        {...layout}
                        name="basic"
                        form={form}
                        initialValues={initialFormData}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                    >
                        <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                        <Row>
                            <Col span={12} />
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Button type="primary" htmlType="submit">
                                    搜索
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>}
            </Card>
            <Card >
                <Table
                    size="small"
                    columns={columns}
                    rowKey={record => record.user_id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                />
            </Card>
        </Space>
        <Modal
            title="修改销售薪资" open={modal.salary} width={1200}
            onOk={() => resetModal()}
            onCancel={() => resetModal()}
            destroyOnClose={true}
            maskClosable={false}
        >
            <UserSalaryForm uid={modal.id} reloadTable={reloadTable} />
        </Modal>
    </>;
}


export default SalaryTable
