import React, { useState, useEffect } from 'react';
import { Table, Button, Row, Col, Layout, Form, Space, message, } from 'antd';
import { SearchOutlined, } from '@ant-design/icons';
import _ from 'lodash'
import qs from 'qs';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { resultResponse } from '../../common/ResultResponse';
import { TimeSearchButtonGroup, initFormData, tablePagination } from '../../common/tableCommon';
import SearchForm from '../../common/SearchForm'
import { userRoleState, refreshUserState } from '../../atoms/Atoms'
import { check } from '../../../utils/permission/rules';
import { ProjectTableConfig } from '../../../role/order/ProjectTableConfig';

const axios = require('axios');
const { Content } = Layout;

const layout = { layout: 'vertical' };
const seartchLayout = { lg: 24, xl: 12 };
const initialModal = {
    target_id: undefined,
    verifyFailed: false, publishSuccess: false, publishOrderFailed: false,
    updateUrl: false, recallBox: false, errorBox: false,
    download: false, rename: false, history: false, censor: false, updateCost: false,
}

// 项目表格
const ProjectTable = (props) => {

    const {
        order_id = '', // 是否是绑定订单ID
        hasSelect = true

    } = props

    const action = order_id === '' ? 'MANAGE' : 'MODIFY'


    const role = useRecoilValue(userRoleState)
    const setRefresh = useSetRecoilState(refreshUserState)

    const permission = {
        'module:media:order:money:agent': check(role, 'module:media:order:money:agent', null),
        'module:media:order:money:sales:agent': check(role, 'module:media:order:money:sales:agent', null),
    }
    const [orders, setOrders] = useState([])

    const searchDatas = [
        {
            name: 'proj_name',
            label: '项目名称',
            placeholder: '请输入项目名称',
            type: 'input'
        },
    ]
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState(initialModal)
    const [form] = Form.useForm();

    const reloadTable = () => {
        form.submit();
        setOrders([]);
        setRefresh(true)
    }

    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))

        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/proj/projects',
                data: params
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    /**
     * 更新项目名称
     * @param {项目id} id 
     * @param {项目新名称} name 
     */
    const updateProjectName = async (id, name) => {
        await axios({
            method: 'post',
            url: "/proj/update/name",
            data: qs.stringify({
                name: name,
                project_id: id
            })
        }).then(res => {
            resultResponse(res, "已成功修改")
            reloadTable()
        })
    }

    /**
     * 删除项目
     * @param {number} id 项目id
     */
    const deleteProject = async (id) => {
        await axios({
            method: 'post',
            url: `/proj/delete/${id}`
        }).then(res => {
            resultResponse(res, "已成功删除")
            reloadTable()
        })
    }

    /**
     * 打开项目订单列表
     * @param {number} id 项目id 
     * @returns 
     */
    const showProjectOrders = (id) => setModal({ ...modal, orders: true, project_id: id })

    /**
     * 修改订单所属项目
     * @param {number} order_id 订单ID
     * @param {number} project_id 项目ID
     * @param {string} project_name 项目名称 
     */
    async function modify(project_id, project_name) {
        if (order_id !== '') {
            await axios({
                method: 'post',
                url: `/proj/modify/order`,
                data: qs.stringify({
                    order_id: order_id,
                    project_id: project_id,
                    project_name: project_name,
                })
            }).then(res => {
                resultResponse(res, '已修改')
                fetchData()
            })
        } else {
            message.warn('并未选择订单')
        }
    }

    // 订单操作函数
    const handleOrders = {
        reloadTable,
        updateProjectName,
        deleteProject,
        showProjectOrders,
        modify
    }

    useEffect(() => {
        fetchData()
    }, [])


    const columns = ProjectTableConfig({ handleOrders, action })

    return <>
        <Content style={{ background: '#fff', padding: 24, margin: '0 0 24px 0', }}>
            <Form
                {...layout}
                name="basic"
                form={form}
                initialValues={initialFormData}
                onFinish={val => fetchData(_.pickBy(val, _.identity))}
            >
                <SearchForm searchDatas={searchDatas} />
                <Row>
                    <Col {...seartchLayout} >
                        <Space>
                        </Space>
                    </Col>
                    <Col  {...seartchLayout} style={{ textAlign: 'right' }}>
                        <Space>
                            <TimeSearchButtonGroup form={form} />
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >搜索</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Content>
        <Content style={{ background: '#fff', padding: 24 }}>
            <Table
                size='small'
                columns={columns.filter(e => e.checked === 1)}
                rowSelection={hasSelect ? {
                    selectedRowKeys: orders.map(e => e.id),
                    onSelect: (record, selected) => {
                        let items = selected ? _.concat(orders, record) : _.differenceBy(orders, [record], 'id');
                        setOrders(_.uniqBy(items, 'id'))
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        let items = selected ? _.concat(orders, changeRows) : _.differenceBy(orders, changeRows, 'id');
                        setOrders(_.uniqBy(items, 'id'))
                    }
                } : null}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => { setPagination(pagination); form.submit() }}
            />
        </Content>
    </>;
}


export default ProjectTable
