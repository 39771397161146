import React from 'react';
import _ from 'lodash'
import { Space, Typography, Tag, Button, Popconfirm } from 'antd';
import qs from 'qs';
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { formatTime } from '../../utils/TimeUtils'
import { resultResponse } from '../../Apps/common/ResultResponse';
import { check } from '../../utils/permission/rules';
import { number2currency } from '../../utils/NumberUtils';

const axios = require('axios');
const { Text } = Typography


const InvoiceTableConfig = ({ handleMedia }) => {
    const role = useRecoilValue(userRoleState)

    const { reloadTable, sendExpressModal, payTax } = handleMedia;
    const permission = {
        'module:invoice:unpaid:pay': check(role, 'module:invoice:unpaid:pay', null),
        'module:invoice:unpaid:post:pay': check(role, 'module:invoice:unpaid:post:pay', null),
        'module:invoice:unpaid:offline:pay': check(role, 'module:invoice:unpaid:offline:pay', null),
        'module:invoice:prepaid:express': check(role, 'module:invoice:prepaid:express', null),
        'module:invoice:pending:express': check(role, 'module:invoice:pending:express', null),
        'module:invoice:in_transit:achieve': check(role, 'module:invoice:in_transit:achieve', null),
        'module:invoice:pre_in_transit:comfirm': check(role, 'module:invoice:pre_in_transit:comfirm', null),
        'module:invoice:paid:achieve': check(role, 'module:invoice:paid:achieve', null),
    }

    // 驳回客户申请
    const refuse = async (id) => {
        await axios({
            method: 'post',
            url: "/invoice/refuse",
            data: qs.stringify({ id })
        }).then(res => {
            resultResponse(res, '已驳回')
            reloadTable()
        })
    }

    // 财务确认客户已经下线付款
    const checkOfflinePaid = async (id) => {
        await axios({
            method: 'post',
            url: "/invoice/check",
            data: qs.stringify({ id })
        }).then(res => {
            resultResponse(res, '已支付')
            reloadTable()
        })
    }

    // 销售修改客户所属类别
    const modify = async (id) => {
        await axios({
            method: 'post',
            url: "/invoice/modify",
            data: qs.stringify({ id })
        }).then(res => {
            resultResponse(res, '已修改')
            reloadTable()
        })
    }

    // 销售确认客户已经付款
    const confirmPaid = async (id) => {
        await axios({
            method: 'post',
            url: "/invoice/confirm",
            data: qs.stringify({ id })
        }).then(res => {
            resultResponse(res, '已支付')
            reloadTable()
        })
    }

    // 订单归档
    const achieveOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/invoice/achieve",
            data: qs.stringify({ id })
        }).then(res => {
            resultResponse(res, '已支付')
            reloadTable()
        })
    }

    const timeCol = {
        title: '申请时间',
        dataIndex: 'create_time',
        render: (t) => formatTime(t)
    }

    const userCol = [
        {
            title: '用户',
            dataIndex: 'account',
            render: (t, data) => {
                let tag;
                if (_.indexOf(['UNPAID', 'PENDING', 'IN_TRANSIT'], data.status) !== -1) {
                    tag = <Tag style={{ margin: 0 }} color="blue">先款用户</Tag>
                } else if (_.indexOf(['PREPAID', 'PRE_IN_TRANSIT', 'PAID'], data.status) !== -1) {
                    tag = <Tag style={{ margin: 0 }} color="red">后款客户</Tag>
                }

                if (data.customer_id === data.creator_id) {
                    return <Space direction='vertical'>
                        <Text>{data.account}</Text>
                        <Tag style={{ margin: 0 }} color="green">用户申请</Tag>
                    </Space>
                } else {
                    return <Space direction='vertical'>
                        <Text>{data.account}</Text>
                        <Space>
                            <Tag style={{ margin: 0 }} color="gold" >销售申请</Tag>
                            {tag}
                        </Space>
                    </Space>
                }
            }
        }, {
            title: '所属销售',
            dataIndex: 'sale_name',
        }
    ]

    const invoiceCol = [
        {
            title: '发票类型',
            dataIndex: 'invoice_type',
            render: (t, data) => {
                if (data.invoice_type === 1) {
                    return <Text>普票</Text>
                } else if (data.invoice_type === 2) {
                    return <Text>专票</Text>
                }
            }
        }, {
            title: '银行信息',
            dataIndex: 'bank_name',
            render: (t, data) => {
                return <Space direction='vertical'>
                    <Text>{data.bank_name}</Text>
                    <Text>{data.bank_id}</Text>
                </Space>
            }
        }, {
            title: '公司信息',
            dataIndex: 'bank_name',
            render: (t, data) => {
                return <Space direction='vertical'>
                    <Text>{data.title}</Text>
                    <Text>{data.licence_id}</Text>
                </Space>
            }
        }, {
            title: '金额',
            dataIndex: 'money',
            render: (t, data) => {
                let real_money = data.real_money ?? data.money + data.tax_money
                return <Space direction='vertical'>
                    <div style={{ width: 180 }}>
                        <Text strong style={{ fontSize: 14 }}>票面金额: {number2currency(real_money)}</Text>
                    </div>
                    <Text>{number2currency(data.money)} + {number2currency(data.tax_money)} (税点)</Text>
                </Space>
            }
        }, {
            title: '姓名',
            dataIndex: 'name',
            render: (t, data) => {
                return <Space direction='vertical'>
                    <Space>
                        <div style={{ width: 120 }}>
                            <Text>{data.name}</Text>
                        </div>
                        <Text>{data.tel}</Text>
                    </Space>
                    <Text>{data.address}</Text>
                </Space>
            }
        }, {
            title: '备注',
            dataIndex: 'remark',
        },
    ]

    const handleTimeCol = [
        {
            title: '支付时间',
            dataIndex: 'pay_time',
            render: (t) => formatTime(t)
        }, {
            title: '邮寄时间',
            dataIndex: 'sending_time',
            render: (t) => formatTime(t)
        }, {
            title: '完成时间',
            dataIndex: 'handle_time',
            render: (t) => formatTime(t)
        }
    ]

    const stateCol = {
        title: '状态',
        dataIndex: 'status',
        width: 72,
        render: (text, data) => {
            let result = '';
            switch (text) {
                case 'UNPAID':
                    result = <Tag style={{ margin: 0 }} color="gold">未支付</Tag>;
                    break;
                case 'PREPAID':
                    result = <Tag style={{ margin: 0 }} color="gold">未打款</Tag>
                    break;
                case 'PENDING':
                    result = <Tag style={{ margin: 0 }} color="blue">待确认</Tag>;
                    break;
                case 'IN_TRANSIT':
                    result = <Tag style={{ margin: 0 }} color="geekblue">邮寄中</Tag>;
                    break;
                case 'PRE_IN_TRANSIT':
                    result = <Tag style={{ margin: 0 }} color="geekblue">邮寄中</Tag>
                    break;
                case 'PAID':
                    result = <Tag style={{ margin: 0 }} color="green">已打款</Tag>
                    break;
                case 'SUCCESS':
                    result = <Tag style={{ margin: 0 }} color="orange">已支付</Tag>;
                    break;
                case 'INVALID':
                    result = <Tag style={{ margin: 0 }} color="red">已取消</Tag>;
                    break;
                default:
                    break;
            }
            return result
        }
    }

    const handleCol = {
        title: '操作',
        dataIndex: 'id',
        render: (text, data) => {
            let result = ''
            switch (data.status) {
                case 'UNPAID':
                    return <Space>
                        {
                            permission['module:invoice:unpaid:pay'] &&
                            <Button size='small' type="primary" onClick={() => payTax(data.id, data.tax_money)}>支付税点</Button>
                        }
                        {
                            permission['module:invoice:unpaid:post:pay'] &&
                            <Button size='small' type="primary" onClick={() => modify(data.id)}>后款客户</Button>
                        }
                        {
                            permission['module:invoice:unpaid:offline:pay'] &&
                            <Button size='small' type="primary" onClick={() => checkOfflinePaid(data.id)}>已线下支付</Button>
                        }
                    </Space>
                case 'PREPAID':
                    return permission['module:invoice:prepaid:express'] && <Space>
                        <Button size='small' type="primary" onClick={() => sendExpressModal(data.id)}>已寄出</Button>
                    </Space>
                case 'PENDING':
                    return permission['module:invoice:pending:express'] && <Space>
                        <Button size='small' type="primary" onClick={() => sendExpressModal(data.id)}>已寄出</Button>
                        <Popconfirm
                            title="是否确认驳回申请"
                            onConfirm={() => refuse(data.id)}
                            onCancel={() => { console.log('取消') }}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button size='small' danger>驳回</Button>
                        </Popconfirm>
                    </Space>
                case 'IN_TRANSIT':
                    return permission['module:invoice:in_transit:achieve'] &&
                        <Button size='small' type="primary" onClick={() => achieveOrder(data.id)}>已完成</Button>
                case 'PRE_IN_TRANSIT':
                    return permission['module:invoice:pre_in_transit:comfirm'] &&
                        <Button size='small' type="primary" onClick={() => confirmPaid(data.id)}>已打款</Button>
                case 'PAID':
                    return permission['module:invoice:paid:achieve'] &&
                        <Button size='small' type="primary" onClick={() => achieveOrder(data.id)}>已完成</Button>
                case 'SUCCESS':
                    break;
                case 'INVALID':
                    break;
                default:
                    break;
            }
            return result
        }
    }


    let result = []
    switch (role) {
        case "FINANCE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
            result = _.concat(
                timeCol,
                userCol,
                invoiceCol,
                handleTimeCol,
                stateCol,
                handleCol
            )
            break;
        case 'CUSTOMER':
            result = _.concat(
                timeCol,
                invoiceCol,
                handleTimeCol,
                stateCol,
                handleCol
            )
            break;
        default:
            break;
    }
    return result
}
export default InvoiceTableConfig;
