import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';


import { userRoleState } from '../../Apps/atoms/Atoms'
const axios = require('axios');

const WeiboHotDatasConfig = () => {
    const role = useRecoilValue(userRoleState)
    const [tags, setTags] = useState([])

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getTags = async () => {
        let params = new FormData()
        params.append('tag_type', 3)
        await axios({
            method: 'post',
            url: `/tag`,
            data: params
        }, {
            cancelToken: source.token
        }).then((res) => {
            let data = res.data;
            setTags(data.map(e => ({ 'id': e.id, 'name': e.tag, 'val': e.tag })))
        })
    }

    useEffect(() => {
        getTags()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    const mediaBasicDatas = [
        {
            name: 'name',
            label: '微博名称',
            required: true,
            placeholder: '请输入微博名称',
            type: 'input'
        },
        {
            name: 'sample_url',
            label: '案例地址',
            required: true,
            placeholder: '请输入案例地址',
            type: 'input'
        },
        {
            name: 'uid',
            label: '微博唯一标识',
            required: true,
            placeholder: '请输入微博唯一标识, 例如 /weibo.com/u/1234567890中的1234567890',
            type: 'input'
        },
    ]

    const mediaPriceDatas = [
        {
            name: 'post_price',
            label: '直发价格',
            placeholder: '请输入直发价格',
            type: 'inputnumber'
        },
        {
            name: 'repost_price',
            label: '转发价格',
            placeholder: '请输入转发价格',
            type: 'inputnumber'
        },
        {
            name: 'duties_post_price',
            label: '微任务直发价格',
            placeholder: '请输入微任务直发价格',
            type: 'inputnumber'
        },
        {
            name: 'duties_repost_price',
            label: '微任务转发价格',
            placeholder: '请输入微任务转发价格',
            type: 'inputnumber'
        },
    ]

    const salePrice = [
        {
            name: 'sale_post_price',
            label: '直发价格',
            placeholder: '请输入直发价格',
            type: 'inputnumber'
        },
        {
            name: 'sale_repost_price',
            label: '转发价格',
            placeholder: '请输入转发价格',
            type: 'inputnumber'
        },
        {
            name: 'sale_duties_post_price',
            label: '微任务直发价格',
            placeholder: '请输入微任务直发价格',
            type: 'inputnumber'
        },
        {
            name: 'sale_duties_repost_price',
            label: '微任务转发价格',
            placeholder: '请输入微任务转发价格',
            type: 'inputnumber'
        },
    ]

    const mediaPropsDatas = [
        {
            name: 'area',
            label: '媒体地区',
            placeholder: '请输入媒体名称',
            type: 'select',
            children: [
                '全国', '北京', '上海', '广东', '浙江', '湖北',
                '湖南', '江苏', '陕西', '天津', '山西', '重庆',
                '河北', '河南', '四川', '安徽', '福建', '广西', '宁夏',
                '山东', '辽宁', '吉林', '海南', '内蒙古', '黑龙江', '新疆', '西藏',
                '江西', '贵州', '云南', '甘肃', '青海', '台湾', '其它',
            ]
        },
        {
            name: 'verified_type',
            label: '认证类型',
            placeholder: '请输入认证类型',
            type: 'select',
            children: [
                '未认证', '个人认证', '金V', '企业认证'
            ]
        }, {
            name: 'tag',
            label: '资源标签',
            placeholder: '请输入资源标签',
            type: 'select',
            value: [],
            mode: 'multiple',
            children: tags
        }
    ]

    const editorDatas = [
        {
            name: 'editor_name',
            label: '编辑姓名',
            placeholder: '请输入媒体名称',
            type: 'input'
        }, {
            name: 'editor_tel',
            label: '联系方式',
            placeholder: '请输入媒体名称',
            type: 'input'
        },
    ]

    const manageDatas = [
        {
            name: 'inner_remarks',
            label: '内部备注',
            placeholder: '请输入内部备注',
            type: 'area'
        },
    ]

    const commentDatas = [
        {
            name: 'remarks',
            label: '备注',
            placeholder: '请输入备注',
            type: 'area'
        },
    ]


    let result = []

    switch (role) {
        case "OPERATOR":
            result = [
                mediaBasicDatas,
                mediaPriceDatas,
                salePrice,
                mediaPropsDatas,
                editorDatas,
                manageDatas,
                commentDatas
            ]
            break;
        case "CUSTOMER":
            result = [
                mediaBasicDatas,
                mediaPriceDatas,
                mediaPropsDatas,
                editorDatas,
                commentDatas
            ]
            break;
        default:
            break;
    }

    return result
}

export default WeiboHotDatasConfig