import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Row, Col, Typography, Form, Input, InputNumber, DatePicker, message, Steps, Tag } from 'antd';
import _ from 'lodash'
import dayjs from 'dayjs';
import qs from 'qs';
import { PlusOutlined } from '@ant-design/icons';

import CouponsTable from './CouponsTable';
import CouponPackSetting from './CouponPackSetting';
import { tablePagination } from '../../common/tableCommon';
import CouponPackTableConfig from '../../../role/coupon/CouponPackTableConfig';


const axios = require('axios');
const { Title } = Typography;
const { Step } = Steps;
const formItemLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 24 },
    layout: 'vertical'
};
const CouponsPackTable = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [count, setCount] = useState(0)
    const [modal, setModal] = useState({ create: false, detail: false })
    const [coupons, setCoupons] = useState([])
    const [current, setCurrent] = useState(0)
    const [couponPack, setCouponPack] = useState({})
    const [amounts, setAmounts] = useState([])
    const [couponsLoading, setCouponsLoading] = useState(false)
    const [couponsDatas, setCouponsDatas] = useState([])

    const reloadTable = () => setCount(count + 1)
    const resetModal = () => setModal({ create: false, detail: false })

    const fetchData = async (params = new FormData()) => {
        setLoading(true)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        await axios({
            method: 'post',
            url: '/couponPack/all',
            data: params
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
            let total = res.data.recordsFiltered
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [pagination.current, pagination.pageSize, count,])

    const createCouponPack = async (coupons) => {
        await axios({
            method: 'post',
            url: '/couponPack/create',
            dataType: 'json',
            data: qs.stringify({ ...couponPack, coupons: JSON.stringify(coupons) })
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功添加")
                resetModal()
                reloadTable()
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const deleteCouponPack = async (id) => {
        await axios({
            method: 'post',
            url: `/couponPack/delete/${id}`,
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功删除")
                reloadTable()
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const getCouponPack = async (id) => {
        setModal({ ...modal, detail: true })
        setCouponsLoading(true)
        await axios({
            method: 'post',
            url: `/couponPack/select/${id}`,
        }).then((res) => {
            if (res.data.code === 1) {
                setCouponsDatas(res.data.data)
                setCouponsLoading(false)
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const handleMedia = {
        getCouponPack,
        deleteCouponPack,
        reloadTable,
    }


    const steps = [
        {
            title: '填写信息',
            content: (
                <div style={{ width: '800px', margin: '0 auto' }}>
                    <Form
                        name="add-coupon-pack" {...formItemLayout}
                        onFinish={(values) => {
                            setCouponPack({
                                ...values,
                                expire_time_string: values['expire_time_string'].format('YYYY-MM-DD')
                            })
                            setCurrent(current + 1)
                        }}
                    >
                        <Form.Item label="名称" name="pack_name">
                            <Input />
                        </Form.Item>
                        <Form.Item label="描述" name="pack_description">
                            <Input />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="失效时间" name="expire_time_string">
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="失效天数" name="expire_day">
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="限购数量" name="max_nums">
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="会员限购数量" name="max_vip_nums">
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="总金额" name="price">
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="优惠价" name="price_vip_1">
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                            <Button type="primary" htmlType="submit">
                                继续
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            ),
        }, {
            title: '选择优惠券',
            content: (
                <>
                    <CouponsTable coupons={coupons} setCoupons={setCoupons} miniMode={true} />
                    <Button style={{ marginLeft: 8 }} onClick={() => setCurrent(current - 1)}>返回</Button>
                    <Button style={{ marginLeft: 8 }} type="primary" onClick={() => {
                        setCurrent(current + 1)
                    }}>
                        继续
                    </Button>
                </>
            ),
        }, {
            title: '提交',
            content: (
                <>
                    <CouponPackSetting coupons={coupons} setAmounts={setAmounts} />
                    <Button style={{ marginLeft: 8 }} onClick={() => setCurrent(current - 1)}>返回</Button>
                    <Button style={{ marginLeft: 8 }} type="primary" onClick={() => {
                        createCouponPack(coupons.map(e =>
                            _.assign(
                                {}, e, { amount: amounts[`input_${e.id}`] }
                            )))
                    }}>
                        保存
                    </Button>
                </>
            ),
        },
    ];
    const columns = CouponPackTableConfig({ handleMedia })

    return (
        <>
            <Table
                size='middle'
                title={() => (
                    <>
                        <Row>
                            <Col span={12}>
                                <Title level={4}>优惠券卡包列表</Title>
                            </Col>
                            <Col span={12} style={{ textAlign: 'end' }}>
                                <Button type="primary" onClick={() => setModal({ ...modal, create: true })}><PlusOutlined />创建</Button>
                            </Col>
                        </Row>
                    </>
                )}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination, filters, sorter) => {
                    setPagination(pagination)
                }}
            />
            <Modal title="创建优惠券包" open={modal.create} footer={null}
                onCancel={() => resetModal()}
                width={1080}
            >
                <div style={{ margin: '0 auto' }}>
                    <Steps current={current} style={{ padding: '16px 0' }}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div className="steps-content" style={{ margin: '16px 0' }}>
                        {steps[current].content}
                    </div>
                </div>
            </Modal>
            <Modal title="查看优惠券包" open={modal.detail} footer={null}
                onCancel={() => resetModal()}
                width={1080}
            >
                <Table
                    size='middle'
                    rowKey={record => record.id}
                    loading={couponsLoading}
                    columns={[
                        {
                            title: '名称',
                            dataIndex: 'coupon_name',
                        }, {
                            title: '描述',
                            dataIndex: 'coupon_description',
                        }, {
                            title: '抵用金额',
                            dataIndex: 'money',
                        }, {
                            title: '抵用数量',
                            dataIndex: 'amount',
                        }, {
                            title: '创建时间',
                            dataIndex: 'create_time',
                        }, {
                            title: '失效时间',
                            dataIndex: 'end_time',
                        }, {
                            title: '状态',
                            dataIndex: 'id',
                            render: (t, data) => {
                                var d = dayjs(data.end_time).diff(dayjs(), 'days');
                                if (d >= 0) {
                                    return < Tag color='green'> 未过期</Tag >
                                } else {
                                    return < Tag color='red'> 已过期</Tag >
                                }
                            }
                        }
                    ]}
                    dataSource={couponsDatas}
                ></Table>
            </Modal>
        </>
    )
}

export default CouponsPackTable