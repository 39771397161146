import { useEffect, useState } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'

const axios = require('axios');

const FinanceIncomeSearchConfig = ({ tab }) => {

    const [sales, setSales] = useState([])

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getSaleLeaders = async () => {
        let params = new FormData()
        params.append("attache", 2)
        params.append("length", 30)
        await axios({
            method: 'post',
            url: `/user/getUserList`,
            data: params
        }, {
            cancelToken: source.token
        }).then((res) => {
            if (res.data.code === 1) {
                let data = res.data.data;
                data = data.map(e => {
                    return { 'id': e.id, 'name': e.account }
                })
                setSales(data)
            }
        })
    }

    useEffect(() => {
        if (tab === 'sales') {
            getSaleLeaders()
        }
    }, [])

    const defaultSearch = [
        {
            name: 'name',
            label: '编辑账号',
            placeholder: '请输入编辑账号',
            type: 'input'
        }, {
            name: 'media_name',
            label: '媒体名称',
            placeholder: '请输入媒体名称',
            type: 'input'
        }, {
            name: 'editor_name',
            label: '编辑联系方式',
            placeholder: '请输入编辑联系方式',
            type: 'input'
        }, {
            name: 'paid_status',
            label: '结算状态',
            type: 'radio',
            value: 'unpaid',
            children: [
                ['全部', ''],
                ['未结算', 'unpaid'],
                ['已结算', 'paid'],
                ['已申请', 'pending'],
            ]
        }, {
            name: 'price',
            label: '价格范围',
            type: 'range',
            datas: [
                {
                    name: 'min_money',
                    label: '最低金额',
                    placeholder: '最低金额',
                    type: 'input'
                },
                {
                    name: 'max_money',
                    label: '最高金额',
                    placeholder: '最高金额',
                    type: 'input'
                },
            ]
        },
    ]

    const salesSearch = [
        {
            name: 'manager_name',
            label: '组长名称',
            placeholder: '请输入组长名称',
            type: 'input'
        }, {
            name: 'name',
            label: '销售名称',
            placeholder: '请输入销售名称',
            type: 'input'
        }, {
            name: 'leaders',
            label: '选择组长',
            type: 'select',
            value: [],
            mode: 'multiple',
            children: sales
        }, {
            name: 'customer_name',
            label: '客户名称',
            placeholder: '请输入客户名称',
            type: 'input'
        },
    ]

    const timeSearch = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: dayjs().subtract(1, 'months')
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        },
    ]

    let result = []

    switch (tab) {
        case "media":
            result = _.concat(
                defaultSearch,
                timeSearch
            )
            break;
        case "sales":
            result = _.concat(
                salesSearch,
                timeSearch
            )
            break;
        default:
            break;
    }

    return result
}

export default FinanceIncomeSearchConfig