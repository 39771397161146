import React from 'react';
import { Typography, Button, Layout, message, Divider } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const { Paragraph, Text, Title } = Typography;
const { Header, Footer, Content } = Layout;

const ResponseArticle = ({ order }) => {

    const remarkStyle = {
        padding: '6px',
        backgroundColor: '#ffe58f',
        borderRadius: '4px',
        userSelect: 'none'
    }

    const copyToClip = (str) => {
        const listener = (e) => {
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
        message.success('已成功复制')
    }


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{order.title}</title>
                    <meta name="description" content={order.title} />
                </Helmet>
                <Layout style={{ width: '100%', margin: '0 auto', backgroundColor: '#fff' }}>
                    <Header style={{ padding: 0, background: '#ffffff' }}>
                        <Button type="primary" onClick={e => {
                            copyToClip(order.back_info)
                        }}>复制内容</Button>
                    </Header>
                    <Divider />
                    <Content id='content'>
                        <Title >{order.title}</Title>
                        {order.remarks !== '' && <Text style={remarkStyle}>备注: {order.remarks}</Text>}
                        <Paragraph style={{ fontSize: 16 }}>{ReactHtmlParser(order.back_info)}</Paragraph>
                        {order.remarks !== '' && <Text style={remarkStyle}>备注: {order.remarks}</Text>}
                    </Content>
                    <Divider />
                    <Footer style={{ padding: 0, background: '#ffffff' }}>
                        <Button type="primary" onClick={e => {
                            copyToClip(order.back_info)
                        }}>复制内容</Button>
                    </Footer>
                </Layout>
            </HelmetProvider>

        </>
    )
}

export default ResponseArticle