import React, { useState, useEffect } from 'react';
import { Table, Typography, Form, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState, userState } from '../../atoms/Atoms'
import { tablePagination } from '../../common/tableCommon';
import SearchForm from '../../common/SearchForm';
import OrderSearchConfig from '../../../role/order/OrderSearchConfig';
import { formatTime } from '../../../utils/TimeUtils'

const axios = require('axios');
const { Text, Paragraph } = Typography;
const layout = { layout: 'vertical' };


/**
 * 用于展示派单所属订单
 * @param {*} param0 
 */
const EarnOrderDetailTable = ({ id = "" }) => {

    const sessionUser = useRecoilValue(userState)
    const role = useRecoilValue(userRoleState)
    let attache = sessionUser.role.attache
    const [form] = Form.useForm();
    const searchDatas = OrderSearchConfig()

    const orderTableColumnConfig = [
        {
            title: '销售姓名',
            dataIndex: 'sale_name',
            render: (t, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text strong>{data.customer_name}</Text>
                        {attache === 0 &&
                            <Text>{t}</Text>
                        }
                    </div>
                )
            }
        }, {
            title: '媒体名称',
            dataIndex: 'media_name',
        }, {
            title: '标题',
            dataIndex: 'title',
            width: 400,
            render: (text, data) => {
                let url = `http://p.yunzitui.com/post/${data.order_no}`
                let copyUrl = data.source_url === "" ? url : data.source_url
                let copyText = `媒体：${data.media_name}\n标题：${data.title}\n地址：${copyUrl}\n`
                return (
                    <>
                        <Paragraph style={{ marginBottom: 0 }} copyable={{
                            text: (data.remarks === '' || data.remarks === null) ? copyText : copyText + `备注：${data.remarks}`
                        }}>{text}</Paragraph>
                    </>
                )
            }
        }, {
            title: '成本',
            dataIndex: 'sale_price',
            width: 96,
            render: (t, data) => {
                switch (role) {
                    case 'FRANCHISE':
                    case 'SALE':
                    case 'SALE_ATTACHE':
                    case 'SALE_LEADER':
                        return data.agent_paid_money
                    default:
                        return data.paid_money
                }
            }
        }, {
            title: '付款',
            dataIndex: 'paid_money',
            width: 96,
            render: (t, data) => {
                switch (role) {
                    case 'SALE':
                    case 'FRANCHISE':
                    case 'SALE_ATTACHE':
                    case 'SALE_LEADER':
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text strong>付款: {data.paid_money}</Text>
                                {data.coupon_money > 0 && <Text type="secondary" style={{ fontSize: '12px' }}>优惠金额{data.coupon_money}</Text>}
                            </div>
                        )
                    default:
                        return data.paid_money
                }
            }
        }, {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            render: (t) => formatTime(t)
        },
        {
            title: '审核时间',
            dataIndex: 'handle_time',
            width: 96,
            render: (t) => formatTime(t)
        },
        {
            title: '发布时间',
            dataIndex: 'media_handle_time',
            width: 96,
            render: (t) => formatTime(t)
        }, {
            title: '回执信息',
            dataIndex: 'back_info',
            width: 150,
            render: (text) => {
                return <a href={text} className='table-btn' target="_blank" rel="noopener noreferrer">查 看</a>
            }
        }
    ]

    const [loading, setLoading] = useState(false)
    const [datas, setDatas] = useState([])
    const [pagination, setPagination] = useState(tablePagination)

    const checkDetail = async (val) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("earn_order_id", id)
        params.append("earn_order_status", 'PENDING')
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (loading === false) {
            setLoading(true)

            await axios({
                method: 'post',
                url: "/earnOrder/getOrders",
                data: params
            }).then(res => {
                setDatas(res.data.data)
                setLoading(false)
                let total = res.data.recordsFiltered
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }


    useEffect(() => {
        if (id !== "") {
            checkDetail()
        }
    }, [pagination.current, pagination.pageSize, id])



    return <>
        <Form
            {...layout}
            name="basic"
            form={form}
            onFinish={val => checkDetail(_.pickBy(val, _.identity))}
        >
            <>
                <SearchForm searchDatas={searchDatas} />
                <Button type="primary" htmlType="submit">
                    <SearchOutlined /> 搜索
                </Button>
            </>
        </Form>
        <Table
            size='middle'
            rowKey={record => record.id}
            loading={loading}
            columns={orderTableColumnConfig}
            pagination={pagination}
            dataSource={datas}
            onChange={(pagination) => { setPagination(pagination) }}
            summary={pageData => {
                let totalBorrow = 0;
                let totalRepayment = 0;
                pageData.forEach(({ agent_paid_money, paid_money }) => {
                    totalBorrow += agent_paid_money;
                    totalRepayment += paid_money;
                });

                return (
                    <>
                        <tr>
                            <th>汇总</th>
                            <td colSpan={2} />
                            <td>
                                <Text type="danger">{totalBorrow}</Text>
                            </td>
                            <td>
                                <Text>{totalRepayment}</Text>
                            </td>
                            <td colSpan={4} />
                        </tr>
                        <tr>
                            <th>收益</th>
                            <td colSpan={2} />
                            <td colSpan={2}>
                                <Text type="danger">{totalRepayment - totalBorrow}</Text>
                            </td>
                            <td colSpan={4} />
                        </tr>
                    </>
                );
            }}
        ></Table>
    </>;
}


export default EarnOrderDetailTable
