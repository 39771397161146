import React, { useState, useEffect } from 'react';
import { PlusOutlined, SearchOutlined, ExportOutlined, UploadOutlined } from '@ant-design/icons';
import { Form, Table, Row, Space, Col, Button, Upload, Divider, Drawer, message, Layout, Modal, Spin, Input, Typography } from 'antd';
import _ from 'lodash'
import qs from 'qs';
import { useRecoilValue } from 'recoil';

import { userRoleState, userState } from '../../atoms/Atoms'
import SearchForm from '../../common/SearchForm'
import { BaikeEntity } from '../../../entities/Baike'
import { initFormData, tablePagination } from '../../common/tableCommon';
import BaikeTableConfig from '../../../role/baike/BaikeTableConfig';
import BaikeSearchConfig from '../../../role/baike/BaikeSearchConfig';
import AddBaikeForm from './AddBaikeForm';

const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios');
const layout = { layout: 'vertical' };
const BaikeTable = ({
    action,
    baikes = [], setBaikes = () => console.log('empty'),
    rowOnSelect = (record, selected) => {
        let tempMedia = {
            id: record.id,
            name: record.name,
            price: record.user_price
        }
        let newMedias = selected ? _.concat(baikes, tempMedia) : _.differenceBy(baikes, [tempMedia], 'id');
        setBaikes(_.uniqBy(newMedias, 'id'))
    },
    rowSelectionDisplay = true,
    simpleMode = false
}) => {

    const sessionUser = useRecoilValue(userState)
    const role = useRecoilValue(userRoleState)
    const attache = sessionUser.role.attache

    const isOperator = role === 'OPERATOR'

    const searchDatas = BaikeSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [visible, setVisible] = useState({ addMedia: false, addMcnMedia: false, type: '', action: 'ADD' })
    const [baike, setBaike] = useState(BaikeEntity)
    const [modal, setModal] = useState({ download: false, saleGroupPrice: false })
    const [saleGroupDatas, setSaleGroupDatas] = useState([])
    const [saleGroupLoading, setSaleGroupLoading] = useState(false)

    const resetBaike = () => setBaike(BaikeEntity)
    const reloadTable = () => { form.submit(); resetBaike() }

    const [saleGroupForm] = Form.useForm();
    const [form] = Form.useForm();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        console.log(val, params)

        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/baike/getBaikeList',
                data: params,
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    useEffect(() => {
        if (_.size(saleGroupDatas) > 0) {
            let result = {}
            saleGroupDatas.map(e => _.assign(result, {
                [`price_${e.sale_group_id}`]: e.price
            }))
            saleGroupForm.setFieldsValue(result)
        }
    }, [saleGroupDatas, saleGroupForm])

    // 创建媒体
    const createMedia = async () => {
        await axios({
            method: 'post',
            url: '/baike/addBaike',
            data: qs.stringify(baike)
        }).then((res) => {
            if (res.data.code === '200') {
                message.success("已成功添加")
                reloadTable()
            } else {
                message.error(res.data.message)
            }
        })
    }

    // 修改媒体
    const updateMedia = async () => {
        await axios({
            method: 'post',
            url: '/baike/addBaike',
            data: qs.stringify(baike)
        }).then((res) => {
            if (res.data.code === '200') {
                message.success("已成功修改")
                reloadTable()
            } else {
                message.error(res.data.message)
            }
        })
    }

    const drawerAction = { visible, setVisible, createMedia, updateMedia }

    // 获取媒体信息
    const getMedia = async (id) => {
        resetBaike()
        setVisible({ ...visible, addMedia: true, action: 'EDIT' })
        await axios({
            method: 'post',
            url: '/baike/getBaikeList',
            data: qs.stringify({ id })
        }).then((res) => {
            let tempMedia = res.data.data[0]
            let newMedia = _.pick(tempMedia, _.keysIn(baike))
            setBaike(newMedia)
        })
    }

    const getTitle = () => action === 'EDIT' ? "编辑百科" : '创建百科'

    const handleStatus = async (id, key, val) => {
        await axios({
            method: 'post',
            url: `/baike/edit`,
            data: qs.stringify({
                id,
                is_common: 'yes',
                [key]: val
            })
        }).then((res) => {
            if (res.data.code === '200') {
                message.success("已成功修改")
                reloadTable()
            } else {
                message.error(res.data.message)
            }
        })
    }

    const getBaikeSaleGroupPrice = async (id, type) => {
        setSaleGroupLoading(true)
        setModal({ ...modal, saleGroupPrice: true })
        await axios({
            method: 'get',
            url: `/baike/getBaikeGroupsPrice?id=${id}&type=${type}`,
        }).then(res => {
            if (res.data.code === '200') {
                setSaleGroupDatas(res.data.data)
                setSaleGroupLoading(false)
            } else {
                message.error(res.data.message)
            }
        })
    }

    const updateSaleGroupPrice = async (val) => {
        let formData = new FormData()
        let tmp = _.pickBy(val, (v, k) => parseInt(v) > 0)
        formData.append('resource_id', saleGroupDatas[0].resource_id)
        formData.append('prices', JSON.stringify(tmp))
        await axios({
            method: 'post',
            url: `/baike/update/saleGroupPrice`,
            data: formData
        }).then(res => {
            if (res.data.code === 1) {
                message.success("已成功更新")
                setModal({ ...modal, saleGroupPrice: false })
            } else {
                message.error(res.data.msg)
            }
        })
    }
    const downloadMedias = async () => {
        setModal({ ...modal, download: true })
        let data = _.pickBy(form.getFieldsValue(), _.identity);
        await axios({
            url: `/baike/downLoadMediaIndex?${qs.stringify(data)}`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '媒体资源详情.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }

    const downloadMediasPrice = async () => {
        setModal({ ...modal, download: true })
        let data = _.pickBy(form.getFieldsValue(), _.identity);
        await axios({
            url: `/baike/downLoadPackageMediaPrice?${qs.stringify(data)}`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '媒体价格分配.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }


    const props = {
        name: 'upload_file',
        action: '/baike/importPrice',
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} 上传成功!`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败!`);
            }
        },
    };

    const handleMedia = {
        getMedia, handleStatus,
        getBaikeSaleGroupPrice, reloadTable,
    }

    const columns = BaikeTableConfig({ action, attache, handleMedia, simpleMode })


    return <>
        <Content className='table-search'>
            <Form
                {...layout}
                name="baike-form"
                form={form}
                initialValues={initialFormData}
                onFinish={val => fetchData(_.pickBy(val, _.identity))}
            >
                <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                <Divider style={{ margin: '8px 0' }} />
                <Row>
                    <Col span={12}>
                        {isOperator &&
                            <Button.Group>
                                {!simpleMode && <>
                                    <Button type="primary"
                                        onClick={() => {
                                            setVisible({ ...visible, addMedia: true, type: '', action: 'ADD' })
                                            resetBaike()
                                        }}>
                                        <PlusOutlined />添加百科
                                    </Button>
                                </>}
                            </Button.Group>}
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Space>
                            <Button.Group>
                                {
                                    (action !== 'EDIT' && sessionUser.id > 0 && !simpleMode && role === 'CUSTOMER') &&
                                    <Button onClick={() => downloadMedias()}>
                                        <ExportOutlined /> 导出
                                    </Button>
                                }
                                {
                                    (sessionUser.id > 0 && !simpleMode && (role === 'SALE' || role === 'FRANCHISE')) &&
                                    <>
                                        <Button onClick={() => downloadMediasPrice()}>
                                            <ExportOutlined /> 导出价格
                                        </Button>
                                        {attache === 0 && <Upload {...props}>
                                            <Button icon={<UploadOutlined />}>导入价格</Button>
                                        </Upload>}
                                    </>
                                }
                            </Button.Group>
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />} loading={loading}>搜索</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Content>
        <Content style={{ background: '#fff', padding: 24, margin: 0, }}>
            <Table
                size='middle'
                columns={columns}
                rowSelection={rowSelectionDisplay ? {
                    selectedRowKeys: baikes.map(e => e.id),
                    onSelect: rowOnSelect,
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        let tempMedias = changeRows.map(e => ({
                            id: e.id,
                            name: e.name,
                            price: e.user_price,
                        }))
                        let newMedias = selected ? _.concat(baikes, tempMedias) : _.differenceBy(baikes, tempMedias, 'id');
                        setBaikes(_.uniqBy(newMedias, 'id'))
                    },
                } : null}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination);
                    form.submit()
                }}
            />
        </Content>
        {/* 编辑百科 */}
        <Drawer
            title={getTitle()}
            width={720}
            onClose={() => setVisible({ ...visible, addMedia: false })}
            open={visible.addMedia}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <AddBaikeForm baike={baike} drawerAction={drawerAction} />
        </Drawer>
        {/* 分配价格 */}
        <Modal
            forceRender
            title="媒体价格分配"
            width={480}
            open={modal.saleGroupPrice}
            footer={null}
            onOk={() => setModal({ ...modal, saleGroupPrice: false })}
            onCancel={() => setModal({ ...modal, saleGroupPrice: false })}
        >
            {
                saleGroupLoading
                    ? <Spin />
                    : <Form form={saleGroupForm} onFinish={val => updateSaleGroupPrice(val)}>
                        <Row gutter={16}>
                            {saleGroupDatas.map(e => {
                                return (
                                    <React.Fragment key={e.sale_group_id}>
                                        <Col span={24} style={{ display: 'flex' }}>
                                            <Form.Item style={{ flex: '1 0 0' }}>
                                                <Text strong>{e.sale_group_name}</Text>
                                            </Form.Item>
                                            <Form.Item name={`price_${e.sale_group_id}`}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </React.Fragment>
                                )
                            })}
                        </Row>
                        <Button type="primary" htmlType="submit">保存</Button>
                    </Form>
            }
        </Modal>
    </>;
}

export default BaikeTable