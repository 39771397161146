import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import MediaTable from './MediaTable';
import BaikeTable from '../baike/BaikeTable';
import MediaIndexTable from '../mediaindex/MediaIndexTable';
import QATable from '../qa/QATable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;

const getHash = (str) => {
    switch (str) {
        case "#baike":
            return "baike"
        case "#mcn":
            return "mcn"
        case "#index":
            return "index"
        case "#qa":
            return "qa"
        case "#weiboHot":
            return "weiboHot"
        case "#wechat":
            return "wechat"
        default:
            return "media"
    }
}

const MediaCenter = (props) => {
    let pageTitle = "资源中心"
    const [tab, setTab] = useState(getHash(props?.location?.hash))

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: 'media', label: `新闻媒体`, },
                            { key: 'mcn', label: `自媒体`, },
                            { key: 'baike', label: `百科`, },
                            { key: 'index', label: `文字链`, },
                            { key: 'qa', label: `问答`, },
                        ]}
                    />
                }
            />
            < Content style={{ margin: 24, marginTop: 16 }}>
                {tab === "media" && <MediaTable rowSelectionDisplay={false} media_from="网络媒体" />}
                {tab === "mcn" && <MediaTable rowSelectionDisplay={false} media_from="自媒体" />}
                {tab === "baike" && <BaikeTable />}
                {tab === "index" && <MediaIndexTable />}
                {tab === "qa" && <QATable />}
            </Content >
        </HelmetProvider >
    )
}

export default MediaCenter;