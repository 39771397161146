export const User = {
    id: '',
    account: '',
    password: '',
    real_name: '',
    tel: '',
    qq: '',
    email: '',
    company: '',
    remark: '',
}

export const SearchUser = {
    account: '',
    real_name: '',
    tel: '',
    qq: '',
    level: '',
    min_points: '',
    max_points: '',
    min_coins: '',
    max_coins: '',
    min_money: '',
    max_money: '',
    last_login_time: '',
    login_time: '',
    sell_group_id: '',
    sale_user: '',
}