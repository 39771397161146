import { useRecoilValue } from 'recoil';

import { userRoleState, platformState } from '../../atoms/Atoms'
import { getRouter } from '../../../utils/permission/rules';
import { Platform } from '../../common/CONSTANTS';

const UserLayoutConifg = () => {
    const role = useRecoilValue(userRoleState)
    const platform = useRecoilValue(platformState)

    const router = [
        {
            key: 'page:user:info',
            title: '个人信息',
            url: '/dashboard/user',
            platform: Platform.media.id
        }, {
            key: 'page:invoice:info',
            title: '发票信息',
            url: '/dashboard/invoice/info',
            platform: Platform.media.id
        }, {
            key: 'page:invoice:apply',
            title: '申请发票',
            url: '/dashboard/invoice/apply',
            platform: Platform.media.id
        }, {
            key: 'page:user:consume',
            title: '消费详情',
            url: '/dashboard/user/consume',
            platform: Platform.media.id
        }, 
        // {
        //     key: 'page:coupon:center',
        //     title: '优惠券中心',
        //     url: '/dashboard/coupons/center',
        //     platform: Platform.media.id
        // }, 
        // {
        //     key: 'page:event:center',
        //     title: '活动中心',
        //     url: '/dashboard/events/center',
        //     platform: Platform.media.id
        // }, 
        {
            key: 'page:mission:center',
            title: '任务中心',
            url: '/dashboard/missions',
            platform: Platform.media.id
        },
    ]

    return getRouter({ role, router, data: { platform } })
}

export default UserLayoutConifg