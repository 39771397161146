import React from "react"
import Coupon from '../coupon/Coupon'
import CouponPack from '../coupon/CouponPack'
import CouponCenter from '../coupon/CouponCenter'

const CouponRoutesConfig = [
    {
        path: '/dashboard/coupons',
        component: <Coupon />
    },
    {
        path: '/dashboard/coupons/pack',
        component: <CouponPack />
    },
    {
        path: '/dashboard/coupons/center',
        component: <CouponCenter />
    },
]

export default CouponRoutesConfig