import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil'
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Layout, Modal, Row, Table } from 'antd';
import _ from 'lodash';
import qs from 'qs';

import { userState, userRoleState } from '../../atoms/Atoms'
import { TagTableConfig } from '../../../role/TagTableConfig';
import { resultResponse } from '../../common/ResultResponse';
import { tablePagination } from '../../common/tableCommon';

const { Content } = Layout;

const layout = { layout: 'vertical' };
const axios = require('axios');
const initialVisible = {
    type: '', addUser: false, recharge: false, id: '',
    editUser: false, group: false, sale_group_id: '',
    changeManage: false, target_id: '', consume: false
}
const TagTable = ({ searchFormDisplay = true, type, tableLoading = false, setTableLoading = null }) => {

    const sessionUser = useRecoilValue(userState)
    const role = useRecoilValue(userRoleState)
    const attache = sessionUser.role.attache;

    const [form] = Form.useForm()
    const [tagForm] = Form.useForm();

    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ ...tablePagination, pageSize: 50 })
    const [visible, setVisible] = useState(initialVisible)

    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        let value = _.pickBy(form.getFieldsValue(), _.identity)
        value && _.toPairs(value).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        type && params.append('tag_type', type)
        if (tableLoading === false) {
            setTableLoading(true)
            await axios({
                method: 'post',
                url: '/tag/myList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                let total = res.data.recordsFiltered
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
                setTableLoading(false)
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [type])

    const createTag = async (val) => {
        await axios({
            method: 'post',
            url: '/tag/add',
            data: qs.stringify(_.assign(val, { tag_type: type }))
        }).then((res) => {
            resultResponse(res, '已成功')
            reloadTable()
        })
    }


    const handleUsers = {
    }

    let columns = TagTableConfig({ role, attache, visible, setVisible, handleUsers })

    return <>
        <Content className='table-search'>
            {searchFormDisplay && <div>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    onFinish={val => fetchData()}
                >
                    <Form.Item label='标签' name='tag'>
                        <Input />
                    </Form.Item>
                    <Divider style={{ margin: '8px 0' }} />
                    <Row>
                        <Col span={12}>
                            <Button type="primary" onClick={() => setVisible({ ...visible, add: true })}>
                                <PlusOutlined />添加标签
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>}
        </Content>
        <Content style={{ background: '#fff', padding: 24, margin: 0, }}        >
            <Table
                size="small"
                columns={columns.filter(e => e.checked === 1)}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={tableLoading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>
        <Modal
            title="创建标签"
            okText="创建"
            cancelText="取消"
            open={visible.add}
            onOk={() => {
                tagForm.validateFields()
                    .then(values => {
                        createTag(values);
                        tagForm.resetFields();
                        setVisible({ ...visible, add: false })
                    }).catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            onCancel={() => {
                tagForm.resetFields();
                setVisible({ ...visible, add: false })
            }}
            maskClosable={false}
        >
            <Form form={tagForm} onFinish={val => createTag(val)} layout='vertical'>
                <Form.Item label="名称" name='tag'>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    </>;
}


export default TagTable
