import React from 'react'
import { Form, Input, Button } from 'antd';
import axios from 'axios';
import qs from 'qs';
import { resultResponse } from '../../common/ResultResponse';

const UserForm = ({ sessionUser }) => {
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 24 },
        layout: "vertical"
    };

    const handleSubmit = async (values) => {
        await axios({
            method: 'post',
            url: '/user/edit/info',
            dataType: 'json',
            data: qs.stringify(values)
        }).then((res) => {
            resultResponse(res, '已成功修改')
        })
    };

    return (
        <div style={{ minWidth: '224px', maxWidth: '448px', flex: '1' }} >
            <Form
                {...layout}
                name="basic"
                initialValues={{
                    username: sessionUser.account,
                    realname: sessionUser.basic.real_name,
                    tel: sessionUser.basic.tel,
                    qq: sessionUser.basic.qq,
                    email: sessionUser.basic.email,
                }}
                onFinish={handleSubmit}
            >
                <Form.Item label="账号名称" name="username">
                    <Input disabled />
                </Form.Item>
                <Form.Item label="昵称" name="nickname">
                    <Input />
                </Form.Item>
                <Form.Item label="真实姓名" name="realname">
                    <Input />
                </Form.Item>
                <Form.Item label="密码" name="password">
                    <Input.Password />
                </Form.Item>
                <Form.Item label="联系电话" name="tel">
                    <Input />
                </Form.Item>
                <Form.Item label="QQ" name="qq">
                    <Input />
                </Form.Item>
                <Form.Item label="邮箱" name="email">
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" >
                        保存
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}


export default UserForm