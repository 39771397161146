import React from 'react'
import { Tag, Typography, Button, message, Space } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import _ from 'lodash'
import qs from 'qs'
import { useRecoilValue } from 'recoil';

import { userRoleState, userAttacheState } from '../../Apps/atoms/Atoms'
import { formatTimeToDay } from '../../utils/TimeUtils'

const axios = require('axios');
const { Text } = Typography

export const EarnOrderTableConfig = ({ modal, setModal, handleOrders }) => {
    const role = useRecoilValue(userRoleState)
    const attache = useRecoilValue(userAttacheState)

    const { finishSaleOrder } = handleOrders;
    const size = 'small'

    const handleSendOrder = async (id, state) => {
        await axios({
            method: 'post',
            url: `/sendOrder/handle`,
            data: qs.stringify({
                id,
                state
            })
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功操作")
            } else {
                message.warning(res.data.msg)
            }
        })
    }




    const saleCol = [
        {
            title: '所属销售',
            dataIndex: 'account',
            checked: 1,
        },
    ]

    const defaultConfig = [
        {
            title: '名称',
            dataIndex: 'name',
            checked: 1,
        }, {
            title: '营收',
            dataIndex: 'price',
            checked: 1,
            render: (t, data) => {
                return <>
                    <Text code>总消费: {t}</Text>
                    <br />
                    <Text code>总成本: {data.cost}</Text>
                </>
            }
        }, {
            title: '收益',
            dataIndex: 'price',
            checked: 1,
            render: (t, data) => {
                let profit = t - data.cost
                if (attache === 1) {
                    return <>
                        <Space direction='vertical'>
                            <Space>
                                <Text code>总收益: {profit}</Text>
                                <Text code>收益率: {Math.round((1 - data.ratio) * 100)} % </Text>
                            </Space>
                            <Text strong>收益: {(profit * (1 - data.ratio)).toFixed(2)}</Text>
                        </Space>
                    </>
                } else if (attache === 2) {
                    return <>
                        <Space direction='vertical'>
                            <Space>
                                <Text code>总收益: {profit}</Text>
                                <Text code>收益率: {Math.round(data.leader_ratio * 100)} % </Text>
                            </Space>
                            <Text strong>收益: {(profit * data.leader_ratio).toFixed(2)}</Text>
                        </Space>
                    </>
                }
            }
        }
    ]

    const orderTime = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            checked: 1,
            render: (t) => formatTimeToDay(t)
        }, {
            title: '申请时间',
            dataIndex: 'end_time',
            width: 96,
            checked: 1,
            render: (t, data) => attache === 1 ? formatTimeToDay(t) : formatTimeToDay(data.leader_end_time)
        },
    ]

    const orderStatus = {
        title: '状态',
        dataIndex: 'state',
        width: 72,
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            let result = '';
            switch (text) {
                case 'WAITING':
                    result = <Tag style={{ margin: 0 }} color="blue">待申请</Tag>;
                    break;
                case 'SUCCESS':
                    result = <Tag style={{ margin: 0 }} color="orange">已申请</Tag>;
                    break;
                case 'COMPLETE':
                    result = <Tag style={{ margin: 0 }} color="green">已完成</Tag>;
                    break;
                default:
                    break;
            }
            return result
        }
    }

    const orderLeaderStatus = {
        title: '提现状态',
        dataIndex: 'leader_state',
        width: 72,
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            let result = '';
            switch (text) {
                case 'WAITING':
                    result = <Tag style={{ margin: 0 }} color="blue">待申请</Tag>;
                    break;
                case 'SUCCESS':
                    result = <Tag style={{ margin: 0 }} color="orange">已申请</Tag>;
                    break;
                case 'COMPLETE':
                    result = <Tag style={{ margin: 0 }} color="green">已完成</Tag>;
                    break;
                default:
                    break;
            }
            return result
        }
    }

    const handleCol = [
        {
            title: '操作',
            dataIndex: 'id',
            checked: 1,
            fixed: 'right',
            render: (t, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text code style={{ marginBottom: '8px' }}>订单数量: {data.total}</Text>
                        <Button.Group>
                            {
                                role !== 'CUSTOMER' &&
                                <Button size={size} onClick={() => {
                                    setModal({ ...modal, orders: true, targetId: data.id })
                                }}><UnorderedListOutlined />查看</Button>
                            }
                        </Button.Group>
                    </div>
                )
            }
        },
        {
            title: '处理订单',
            dataIndex: 'id',
            checked: 1,
            fixed: 'right',
            render: (t, data) => {
                switch (role) {
                    case "SALE":
                    case "SALE_LEADER":
                    case "SALE_ATTACHE":
                    case 'FRANCHISE':
                        return (
                            <Space>
                                {
                                    data.state === 'PENDING' &&
                                    <>
                                        <Button type='primary' onClick={() => {
                                            setModal({ ...modal, targetId: data.id, finish: true })
                                        }}>完成</Button>
                                        <Button danger onClick={() => finishSaleOrder(data.id, "FAILED", 0)}>取消</Button>
                                    </>
                                }
                            </Space>
                        )
                    case "CUSTOMER":
                        return (
                            <Space>
                                {
                                    data.state === 'PENDING' &&
                                    <>
                                        <Button type='primary' onClick={() => {
                                            handleSendOrder(data.id, "accept")
                                        }}>接单</Button>
                                        <Button danger onClick={() => handleSendOrder(data.id, "decline")}>拒绝</Button>
                                    </>
                                }
                            </Space>
                        )
                    default:
                        break;
                }
            }
        }
    ]

    let result = []

    switch (role) {
        case "SALE":
        case 'FRANCHISE':
        case "SALE_ATTACHE":
        case "CUSTOMER":
            result = _.concat(
                defaultConfig,
                orderTime,
                orderStatus,
                handleCol,
            )
            break;
        case "SALE_LEADER":
            result = _.concat(
                saleCol,
                defaultConfig,
                orderTime,
                orderLeaderStatus,
                handleCol,
            )
            break;
        default:
            break;
    }
    return result
}
