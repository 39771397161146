import React from 'react'
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import EnterpriseInvoiceTable from './EnterpriseInvoiceTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;
const EnterpriseInvoice = () => {

    let pageTitle = "发票管理"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content style={{ margin: '24px' }}>
                <EnterpriseInvoiceTable />
            </Content>
        </HelmetProvider>
    );
}
export default EnterpriseInvoice