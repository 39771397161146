import React, { useEffect } from 'react'
import { Typography, Space } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const { Paragraph, Text } = Typography;

const MediaPriceTableConfig = ({ names, type }) => {
    const role = useRecoilValue(userRoleState)

    useEffect(() => {
        if (names) {
            dealPriceCol(names)
        }
    }, [names])

    const nameCol = [
        {
            title: '媒体名称',
            dataIndex: 'name',
            width: 160,
            render: (text, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text >{text}</Text>
                        <span><a href={data.sample_url} target='_blank' rel='noopener noreferrer'>查看案例</a></span>
                    </div>
                )
            }
        }
    ]

    const priceCol = [
        {
            title: '价格',
            dataIndex: 'sale_price',
        }, {
            title: '发稿奖励',
            dataIndex: 'back_coins',
            render: (text, data) => {
                return <Space>
                    <Text>奖励金额：</Text>
                    <Text>{data.back_coins}</Text>
                </Space>
            }
        },
    ]

    const dealPriceCol = (data) => {
        let names = _.split(data, ',')
        let temp = _.orderBy(names.map(name => {
            let arr = _.split(name, '_')
            return {
                idx: parseInt(arr[0]),
                value: arr[1]
            }
        }), 'idx', 'asc')
        let tmp = _.reduce(temp, (result, n) => {
            return _.concat(result, {
                title: n.value,
                dataIndex: `group-${n.value}`
            })
        }, [])
        return _.uniqBy(tmp, 'dataIndex')
    }

    const groupPriceCol = dealPriceCol(names)


    const mediaRemarkCol = {
        title: '备注',
        dataIndex: 'comment',
        width: '25%',
        render: (text) => (
            <Paragraph ellipsis={{ rows: 2, expandable: true }} style={{ margin: 0 }}>
                {text}
            </Paragraph>
        )
    }


    let result = []

    switch (role) {
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case "SALE":
        case "FRANCHISE":
            result = _.concat(
                nameCol,
                priceCol,
                groupPriceCol,
                mediaRemarkCol,
            )
            break;
        case "PUBLIC":
            result = _.concat(
                nameCol,
                groupPriceCol,
                mediaRemarkCol,
            )
            break;
        default:
            result = _.concat(
            )
            break;
    }
    return result
}
export default MediaPriceTableConfig;
