import React, { useState } from 'react'
import { Layout, Form, Steps, Button, Typography, Tooltip, message, Row, Col, Divider, Input, Tabs, InputNumber } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageHeader from '../../layout/PageHeader';

import InvoiceInfoForm from './InvoiceInfoForm';
import { resultResponse } from '../../common/ResultResponse';
import InvoicesTable from './InvoicesTable';
import WorkOrderTable from '../orders/WorkOrderTable';
import { InvoiceText, defaultInvoice } from './CommonInvoice'


const axios = require('axios');
const { Content } = Layout;

const { Step } = Steps;
const { Title, Text } = Typography;
const SalesInvoice = () => {

    let pageTitle = "销售申请发票"

    const [current, setCurrent] = useState(0)
    const [workOrders, setWorkOrders] = useState([])
    const [invoice, setInvoice] = useState(defaultInvoice)
    const [tab, setTab] = useState('invoice')

    const nextPage = () => setCurrent(current + 1)
    const prevPage = () => setCurrent(current - 1)

    const getTax = (money) => {
        let taxRatio = 0.06
        let result = money * taxRatio
        return (Math.round(result * 100) / 100).toFixed(2)
    }

    const taxInfo = "本平台开具专票收取6%的税点（票面6%）"

    /**添加发票 */
    const addInvoice = async (val) => {
        let params = new FormData();
        _.toPairs(invoice).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        val && _.toPairs(val).forEach(e => params.set(`${e[0]}`, e[1] ?? ''))
        params.append('type', _.uniq(workOrders.map(e => e.state)))

        if (_.size(invoice.customer_id) > 1) {
            message.warn("当前工单所属客户不唯一")
            return
        } else {
            await axios({
                method: 'post',
                url: '/invoice/sales/add',
                data: params
            }).then((res) => {
                resultResponse(res, "已成功")
                setTab('list')
            })
        }
    }

    const steps = [
        {
            title: '选择工单',
            content: (
                <>
                    <InvoiceText />
                    <Content className='table-main'>
                        <WorkOrderTable hasRowSelection={true} workOrders={workOrders} setWorkOrders={setWorkOrders} initialState='SUCCESS' />
                        <Row style={{ width: '100%' }}>
                            <Col span={24} key='next' style={{ textAlign: "right" }}>
                                <Button type="primary" onClick={() => {
                                    const money = _.sumBy(workOrders, 'sale_price')
                                    if (money <= 0) {
                                        message.error('请选择订单');
                                    } else {
                                        if (money < 200) {
                                            console.log(money)
                                            message.warning('发票累计金额不得低于200元');
                                        } else {
                                            if (_.size(_.uniq(workOrders.map(e => e.customer_id))) > 1) {
                                                message.warn("当前工单所属客户不唯一")
                                                return
                                            }
                                            if (_.size(_.uniq(workOrders.map(e => e.state))) > 1) {
                                                message.warn("当前工单所属类型不唯一")
                                                return
                                            }
                                            nextPage()
                                            setInvoice({
                                                ...invoice,
                                                money: money,
                                                work_order_ids: workOrders.map(e => e.id),
                                                customer_id: workOrders.map(e => e.customer_id)
                                            })
                                        }
                                    }
                                }}>继续</Button>
                            </Col>
                        </Row>
                    </Content>
                </>
            ),
        }, {
            title: '填写信息', // 传递选择媒体数据，搜索表格数据，是否读取完成数据
            content: (
                <>
                    <Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
                        <div style={{ maxWidth: 960, margin: '0 auto' }}>
                            <Title level={4} style={{ textAlign: 'center' }}>
                                当前所选开票金额为 {_.sumBy(workOrders, 'sale_price')} 元
                            </Title>
                            <div style={{ textAlign: 'center', }}>
                                <Text type="secondary" style={{ margin: '0 8px' }}>
                                    还需补交{getTax(_.sumBy(workOrders, 'sale_price'))} 元 (税点)
                                </Text>
                                <Tooltip title={taxInfo}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                            <Divider />
                            <InvoiceInfoForm uid={invoice?.customer_id} />
                            <Divider />
                            <Form layout='vertical' onFinish={val => addInvoice(val)}>
                                <Form.Item label="收件人姓名" name='name'
                                    rules={[{ required: true, message: '请填写收件人姓名！' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item label="手机号" name='tel'
                                    rules={[{ required: true, message: '请填写收件人手机号！' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item label="收件地址" name='address'
                                    rules={[{ required: true, message: '请填写收件人地址！' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item label="实际开票金额" name='real_money'
                                    help='请填写实际开票金额, 如果无其他需求, 空白即可'
                                >
                                    <InputNumber min={200} />
                                </Form.Item>
                                <Form.Item label="备注" name='remark'>
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Row>
                                        <Col span={12}>
                                            <Button onClick={prevPage}>返回</Button>
                                        </Col>
                                        <Col span={12} style={{ textAlign: "right" }}>
                                            <Button type="primary" htmlType='submit'>
                                                提交
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </div>
                    </Content>
                </>
            ),
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey='invoice' activeKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: 'invoice', label: `申请发票`, },
                            { key: 'list', label: `查看申请`, },
                            { key: '3', label: `待确认`, },
                            { key: '4', label: `待发布`, },
                            { key: '5', label: `已完成`, },
                            { key: '6', label: `已退稿`, },
                        ]}
                    />
                } />
            <Content style={{ margin: '24px' }}>
                {
                    tab === 'invoice' && <>
                        <div style={{ backgroundColor: '#FFF', padding: 16, marginBottom: 16 }}>
                            <Steps current={current} size={"small"}>
                                {steps.map(item => <Step key={item.title} title={item.title} />)}
                            </Steps>
                        </div>
                        <div className="steps-content">
                            {steps[current].content}
                        </div>
                    </>
                }
                {
                    tab === 'list' && <InvoicesTable />
                }
            </Content>
        </HelmetProvider>
    );
}
export default SalesInvoice