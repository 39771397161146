import React from 'react';
import { Layout, Typography, Row, Col, Card } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Homeheader from '../Homeheader';

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;

const paragraphStyle = { fontSize: '16px', fontFamily: '"PingFang SC", "Microsoft Yahei", Arial, sans-serif', lineHeight: '28px', textAlign: 'justify' }
const cardStyle = { borderRadius: 6, border: "2px solid rgb(0, 103, 237)", padding: 36, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: 360, alignItems: 'center' }
const MarketingInfo = () => {
    return <HelmetProvider>
        <Helmet>
            <title>整合营销</title>
            <meta name="description" content="整合营销" />
        </Helmet>
        <Homeheader />
        <Content style={{ background: '#fff', margin: '0 auto' }}>
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '440px', float: 'left', textAlign: 'left', padding: '100px 0', }}>
                        <Title level={3} style={{ fontSize: 32 }}>云自推营销平台</Title>
                        <Title style={{ fontSize: '56px', color: '#0067ed', margin: '24px 0 0 0' }}>汇聚多营销渠道</Title>
                        <Title level={2} style={{ fontSize: 40, marginTop: 0 }}>构筑企业流量护城河</Title>
                        <Paragraph style={paragraphStyle}>
                            品牌承载的是一部分人对其产品以及服务的认可，是一种品牌商与顾客购买行为间相互磨合衍生出的产物。
                            整合营销可以为企业提供多渠道的曝光，在碎片化的互联网时代迅速占领用户心智。
                            同时，通过第三方平台为企业发声，全面覆盖流量入口，精准推送目标用户。
                            在全渠道流量加持下，对潜在用户群体进行口碑的引导，产品知名度便可迅速提升，成功树立品牌形象。
                        </Paragraph>
                        <a href={{}} style={{
                            display: 'block',
                            width: '260px',
                            height: '60px',
                            backgroundColor: '#0067ed',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            fontSize: '22px',
                            lineHeight: '60px'
                        }}>
                            整合营销咨询
                        </a>
                    </div>
                    <img style={{ float: 'right', width: 760 }} src='http://static.yunzitui.com/static/asset/biz/marketing/marketing-banner-1.png' alt='' />
                </div>
            </div>

            {/* 优势阐述 */}
            <div style={{ margin: '0 auto', textAlign: 'center', width: 1200 }}>
                <div style={{ marginTop: 40, textAlign: 'center' }}>
                    <Title style={{ fontSize: 40, marginBottom: 30 }}>募集全网优质资源 打造立体营销矩阵</Title>
                    <div className='title-divider' />
                    <Paragraph style={{ ...paragraphStyle, textAlign: 'center' }}>
                        随着互联网以及移动互联网的迅猛发展，新的营销方式、营销载体不断涌现，{<br />}
                        云自推深耕渠道资源，与大量媒体主直接建立合作，成本领先全行业，利用多渠道构建丰富资源矩阵，为企业的发展带来质变。
                    </Paragraph>
                    <div style={{ marginTop: 40, textAlign: 'center' }}>
                        <Paragraph style={{ ...paragraphStyle, textAlign: 'center', fontSize: 22, marginBottom: 30 }}>
                            云自推营销平台可为企业提供...
                        </Paragraph>
                        <Row gutter={48}>
                            <Col span={8}>
                                <div style={cardStyle}>
                                    <Title style={{ fontSize: 24, marginBottom: 16 }}>新闻媒体</Title>
                                    <svg t="1597916576844" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1308" width="64" height="64"><path d="M660.8 992h-544c-48 0-84.8-38.4-84.8-84.8V403.2C32 356.8 68.8 320 116.8 320h544c46.4 0 84.8 38.4 84.8 84.8v504c-3.2 44.8-40 83.2-84.8 83.2z" fill="#E1E1DF" p-id="1309"></path><path d="M992 249.6v652.8c0 49.6-40 88-89.6 88H281.6c-49.6 0-89.6-40-89.6-88V120C192 70.4 232 32 281.6 32h489.6" fill="#4E8DF6" p-id="1310"></path><path d="M844.8 254.4c-40 0-73.6-33.6-73.6-73.6V32L992 254.4h-147.2z" fill="#A7C7F8" p-id="1311"></path><path d="M596.8 830.4H377.6c-24 0-43.2-19.2-43.2-43.2 0-24 19.2-43.2 43.2-43.2h219.2c24 0 43.2 19.2 43.2 43.2 0 24-19.2 43.2-43.2 43.2zM771.2 832h-44.8c-24 0-44.8-19.2-44.8-44.8 0-24 19.2-44.8 44.8-44.8h44.8c24 0 44.8 19.2 44.8 44.8 0 24-20.8 44.8-44.8 44.8zM507.2 283.2h-131.2c-24 0-43.2-19.2-43.2-43.2 0-24 19.2-43.2 43.2-43.2h131.2c24 0 43.2 19.2 43.2 43.2 0 24-19.2 43.2-43.2 43.2zM758.4 633.6H379.2c-24 0-44.8-20.8-44.8-44.8V425.6c0-25.6 20.8-44.8 44.8-44.8h379.2c25.6 0 44.8 19.2 44.8 44.8v163.2c0 24-20.8 44.8-44.8 44.8z" fill="#FFFFFF" p-id="1312"></path></svg>
                                    <Paragraph style={{ ...paragraphStyle, textAlign: 'center' }}>
                                        在纸媒盛行乃至互联网初期，由于企业对外宣传渠道的局限，新闻媒体成为了很多企业链接消费者的主要渠道，因而它也承载了很多老一代公关人的青春与梦想。
                                    </Paragraph>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div style={cardStyle}>
                                    <Title style={{ fontSize: 24, marginBottom: 16 }}>百科知道问答</Title>
                                    <svg t="1597916671468" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1460" width="64" height="64"><path d="M846.4 80H412.8c-80 0-145.6 65.6-145.6 147.2v331.2c0 81.6 65.6 147.2 145.6 147.2h256l102.4 94.4c28.8 27.2 78.4 8 78.4-32v-64c78.4 0 142.4-65.6 142.4-145.6V227.2C992 145.6 926.4 80 846.4 80z" fill="#2166CC" p-id="1461"></path><path d="M611.2 211.2H177.6c-80 0-145.6 65.6-145.6 147.2v331.2c0 80 64 145.6 142.4 147.2v64c0 40 49.6 59.2 78.4 32l102.4-94.4h256c80 0 145.6-65.6 145.6-147.2V358.4c0-81.6-65.6-147.2-145.6-147.2z" fill="#4E8DF6" p-id="1462"></path><path d="M416 462.4H225.6c-17.6 0-32-14.4-32-32v-9.6c0-17.6 14.4-32 32-32H416c17.6 0 32 14.4 32 32v9.6c0 17.6-14.4 32-32 32zM524.8 614.4H225.6c-17.6 0-32-14.4-32-32v-9.6c0-17.6 14.4-32 32-32h297.6c17.6 0 32 14.4 32 32v9.6c1.6 17.6-12.8 32-30.4 32z" fill="#FFFFFF" p-id="1463"></path></svg>
                                    <Paragraph style={{ ...paragraphStyle, textAlign: 'center' }}>
                                        百度百科、百度知道、百度文库、百度经验等是百度自己旗下的产品，权重高，排名效果好，又有百度搜索引擎为其带来源源不断的用户流量。
                                    </Paragraph>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div style={cardStyle}>
                                    <Title style={{ fontSize: 24, marginBottom: 16 }}>KOL（垂直类媒体）</Title>
                                    <svg t="1597916705581" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1613" width="64" height="64"><path d="M905.6 771.2l-254.4-62.4c-4.8-1.6-9.6-1.6-12.8-1.6C603.2 704 576 673.6 576 640v-67.2c0-35.2 27.2-64 62.4-67.2 4.8 0 9.6-1.6 12.8-1.6l254.4-62.4c25.6-6.4 54.4 1.6 70.4 20.8 9.6 11.2 16 25.6 16 41.6v203.2c0 16-6.4 30.4-16 41.6-16 20.8-44.8 28.8-70.4 22.4z" fill="#E1E1DF" p-id="1614"></path><path d="M392 198.4a121.6 118.4 0 1 0 243.2 0 121.6 118.4 0 1 0-243.2 0Z" fill="#2166CC" p-id="1615"></path><path d="M222.4 235.2a86.4 84.8 0 1 0 172.8 0 86.4 84.8 0 1 0-172.8 0Z" fill="#2166CC" p-id="1616"></path><path d="M705.6 944H160c-70.4 0-128-57.6-128-128V436.8c0-70.4 57.6-128 128-128h545.6c70.4 0 128 57.6 128 128V816c0 70.4-57.6 128-128 128z" fill="#4E8DF6" p-id="1617"></path><path d="M526.4 612.8l-172.8-97.6c-11.2-6.4-24 1.6-24 14.4v195.2c0 12.8 12.8 19.2 24 14.4L526.4 640c11.2-6.4 11.2-20.8 0-27.2z" fill="#FFFFFF" p-id="1618"></path></svg>
                                    <Paragraph style={{ ...paragraphStyle, textAlign: 'center' }}>
                                        基于媒体属性的分类，从行业的角度来细分，可分为旅游行业垂直类媒体（途牛旅游）、电商行业垂直类媒体(小红书)、母婴行业垂直类媒体（宝宝树）等。
                                    </Paragraph>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {/* 案例展示 */}
            <div style={{ margin: '0 auto', textAlign: 'center', width: 1440 }}>
                <Title style={{ fontSize: 40, margin: '30px auto' }}><Text strong style={{ color: '#0067ed' }}>「 </Text>案例展示<Text strong style={{ color: '#0067ed' }}> 」</Text></Title>
                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'right', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>APP推广营销</Title>
                        <Paragraph style={paragraphStyle}>
                            <Text strong>趣约会</Text>是一款面向单身用户的社交产品，旨在给单身男女提供一个真诚透明的交友环境，采用1红娘+1男嘉宾+1女嘉宾的3人视频聊天模式。{<br />}
                            我们为品牌建立百科，同时在各大媒体渠道投放PR稿件，同时在百度搜索引擎首页提供多个下载渠道，为趣约会APP成功引流。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/biz/marketing/marketing-case-1.png' alt='' />
                </div>
                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'left', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>企业产品推广营销</Title>
                        <Paragraph style={paragraphStyle}>
                            <Text strong>莱克吉米</Text>是莱克电气正式对外发布了全新品牌。与莱克主要针对中产阶级，定位高端截然不同，JIMMY更多的用户群体是追求科技、时尚的年轻消费群体。{<br />}
                            由于吸尘器属于白色家电，所以我们选择了已分享为主的方式，通过自媒体以及KOL等相关渠道对吉米吸尘器进行介绍引流。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/biz/marketing/marketing-case-2.png' alt='' />
                </div>
            </div>
        </Content>
    </HelmetProvider>
}

export default MarketingInfo;