import React from 'react';
import { Layout, Typography } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Homeheader from '../Homeheader';

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;

const paragraphStyle = { fontSize: '16px', fontFamily: '"PingFang SC", "Microsoft Yahei", Arial, sans-serif', lineHeight: '28px' }
const EnterpriseSolution = () => {
    return <HelmetProvider>
        <Helmet>
            <title>企业客户</title>
            <meta name="description" content="查看企业客户解决方案" />
        </Helmet>
        <Homeheader />
        <Content style={{ background: '#fff', margin: '0 auto' }}>
            {/* 揭示行业痛点 */}
            <div style={{ height: '640px' }}>
                <div style={{
                    backgroundImage: 'url(http://static.yunzitui.com/static/asset/solution/enterprise_solution_banner.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '640px',
                    width: '100%',
                    position: 'absolute'
                }} />
                <div style={{ width: '1200px', margin: '0 auto' }}>
                    <div style={{
                        position: 'relative',
                        display: 'inline-block',
                        width: '520px',
                        backgroundColor: '#fff',
                        textAlign: 'left',
                        verticalAlign: 'middle',
                        padding: '50px',
                        marginTop: '40px'
                    }}>
                        <Title level={2}>企业客户使用云自推</Title>
                        <Title style={{ fontSize: '56px', color: '#0067ed', marginTop: '30px' }}>建立品牌形象</Title>
                        <Paragraph style={paragraphStyle}>
                            企业客户在互联网+的环境下，想要在同行中脱颖而出，乃至建立品牌价值，对公司进行品牌营销是必不可少的。
                            云自推打通媒体资源渠道与客户的界限，客户通过云自推一站式进行全渠道网络营销，通过线上为企业进行引流，提升消费者对品牌的新来，为企业实现持续，高质量的新闻曝光。
                        </Paragraph>
                        <a href={{}} style={{
                            display: 'block',
                            width: '260px',
                            height: '60px',
                            backgroundColor: '#0067ed',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            fontSize: '22px',
                            lineHeight: '60px'
                        }}>
                            联系我们
                        </a>
                    </div>
                </div>
            </div>
            {/* 强调云自推优势 */}
            <div style={{ margin: '0 auto', backgroundColor: '#0067ed', padding: '30px 0' }}>
                <Title style={{ color: '#FFFFFF', textAlign: 'center' }}>全渠道优势资源 高效进行网络营销</Title>
                <Paragraph style={{ ...paragraphStyle, color: '#FFFFFF', textAlign: 'center' }}>
                    云自推长期深耕资源终端，获取一手资源渠道，高效处理订单，降低沟通成本；多元的资源渠道铸就了强大的资源矩阵，{<br />}
                    可以在市场上抢占先机，不为成本烦恼，方便开发客户，提高利润空间。
                </Paragraph>
            </div>
            {/* 优势阐述 */}
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'left', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>多渠道资源对接 节约运营人力</Title>
                        <Paragraph style={paragraphStyle}>
                            营销推广渠道丰富，推广方式繁多，公司运营人员往往要同事对接多个资源渠道，多渠道频繁对接消耗了大量的时间，
                            营销内容的确认也让人头疼。云自推可以同时管理多个营销渠道，更有排版工具可以提升文章美观度，让运营人员确认内容准确性。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/solution/service_quality.png' alt='' />
                </div>

                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'right', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>自媒体投放 借助大V力量</Title>
                        <Paragraph style={paragraphStyle}>
                            各种自媒体平台已经成为不亚于网络媒体的一股力量，借助自媒体在搜索引擎排名上的优异表现，
                            公司可以以较低的成本获取更多的流量。云自推提供海量自媒体资源，帮助企业找到对应行业的自媒体账号进行投放，
                            提高用户关注的同时，更有机会提高转化率，打造爆品、提升品牌知名度。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/solution/mcn_details.png' alt='' />
                </div>

                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'left', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>风险预防 智能管理</Title>
                        <Paragraph style={paragraphStyle}>
                            云自推可设置风险关键词，亦可设置客户无法收到或发送风险关键词，并且可提醒编辑本人和运营人员，大大降低了营销服务中存在的风险。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/solution/keywords.png' alt='' />
                </div>

                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'right', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>客服QQ直接资讯 响应及时高效</Title>
                        <Paragraph style={paragraphStyle}>
                            营销项目服务对接中，沟通的时效性至关重要。云自推售后部门可通过QQ对接，7 x 24 小时在线，即时响应，帮客户快速定位、解决问题。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/solution/service_qq.png' alt='' />
                </div>
            </div>

            {/* 邀请加入 */}
            <div style={{
                backgroundImage: 'url(https://bqq.gtimg.com/qdweb_2018/static/img/solution/apply_bg.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '400px',
                width: '100%',
                position: 'absolute',
                textAlign: 'center'
            }}>
                <Title style={{ margin: '80px auto 60px auto', fontWeight: 'normal' }}>
                    为了帮助您更好地了解和使用 <span style={{ fontWeight: 'bold' }}>云自推</span>{<br />}
                    我们特别为您提供了产品体验活动
                </Title>
                <a href={{}} style={{
                    display: 'block',
                    width: '320px',
                    height: '72px',
                    backgroundColor: '#0067ed',
                    color: '#FFFFFF',
                    fontSize: '22px',
                    lineHeight: '72px',
                    margin: '0 auto',
                    boxShadow: '0 24px 64px #b4b2b2'
                }}>
                    申请体验
                </a>
            </div>
        </Content>
    </HelmetProvider>
}

export default EnterpriseSolution;