import React from "react"
import Douyin from "../douyin/Douyin"

const DouyinRoutesConfig = [
    // {
    //     path: '/dashboard/douyin/order',
    //     component: WechatOrder
    // },
    // {
    //     path: '/dashboard/douyin/order/add',
    //     component: CreateWechatOrder
    // },
    {
        path: '/dashboard/douyin',
        component: <Douyin />
    }
]

export default DouyinRoutesConfig