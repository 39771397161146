import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const UserDatasConfig = ({ type }) => {

    const role = useRecoilValue(userRoleState)

    const userBasicDatas = [
        {
            name: 'account',
            label: '用户名',
            required: true,
            placeholder: '请输入用户名',
            type: 'input'
        },
        {
            name: 'password',
            label: '密码',
            required: true,
            placeholder: '请输入用户密码',
            type: 'input'
        },
    ]

    const userEditDatas = [
        {
            name: 'account',
            label: '用户名',
            disabled: true,
            placeholder: '请输入用户名',
            type: 'input'
        },
    ]

    const userInfoDatas = [
        {
            name: 'real_name',
            label: '真实姓名',
            placeholder: '请输入真实姓名',
            type: 'input'
        },
        {
            name: 'tel',
            label: '联系电话',
            placeholder: '请输入联系电话',
            type: 'input'
        },
        {
            name: 'qq',
            label: 'QQ',
            placeholder: '请输入QQ',
            type: 'input'
        },
        {
            name: 'email',
            label: '邮箱',
            placeholder: '请输入邮箱',
            type: 'input'
        },
        {
            name: 'company',
            label: '所属公司',
            placeholder: '请输入所属公司',
            type: 'input'
        },
        {
            name: 'remark',
            label: '备注',
            placeholder: '请输入备注',
            type: 'textarea'
        },
    ]

    const commentDatas = [
        {
            name: 'remark',
            label: '备注',
            placeholder: '请输入备注',
            type: 'area'
        },
    ]


    let result = []

    switch (role) {
        case "OPERATOR":
            result = [
                userBasicDatas,
                userInfoDatas,
                commentDatas
            ]
            break;
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
            if (type === 'ADD') {
                result = [
                    userBasicDatas,
                    userInfoDatas,
                    commentDatas
                ]
            } else if (type === 'EDIT') {
                result = [
                    userEditDatas,
                    userInfoDatas,
                    commentDatas
                ]
            }
            break;
        default:
            break;
    }

    return result
}

export default UserDatasConfig