import React, { useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Layout, Input, Button, Typography, message, Card } from 'antd'
import qs from 'qs';
import _ from 'lodash'
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;
const { Title } = Typography;
const axios = require('axios');
const initialDatas = [];

const UserRecharge = () => {
    let pageTitle = "充值余额"

    const [money, setMoney] = useState(0)
    const [datas, setDatas] = useState(initialDatas)
    const alipayUrl = 'https://mapi.alipay.com/gateway.do?_input_charset=utf-8'
    const inputItemStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '16px 0'
    }

    const chargeBalance = () => {
        if (money <= 0) {
            message.error('请正确输入提现金额');
            return
        }
        if (isNaN(money)) {
            message.error('请正确输入数字')
            return
        }
        submitRechargeMoney()
    }

    const Form = ({ obj }) => {
        let data = _.toPairs(obj)
        return (
            <>
                <form id='alipayForm' action={alipayUrl} method='POST'>
                    {
                        data.map(e => <input type='hidden' name={e[0]} value={e[1]} key={e[0]}></input>)
                    }
                </form>
            </>
        )
    }

    const submitRechargeMoney = async () => {
        await axios({
            method: 'post',
            url: '/pay/new/alipay',
            data: qs.stringify({ amount: money })
        }).then(res => {
            setDatas(res.data.data.data)
        })
    }

    useEffect(() => {
        if (datas !== initialDatas) {
            document.getElementById('alipayForm').submit()
        }
    }, [datas])

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <Content>
                <PageHeader title={pageTitle} />
                <div style={{ margin: 24 }}>
                    <Card
                        style={{
                            background: '#fff',
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <div style={{
                            textAlign: 'center'
                        }}>
                            <Title level={2}>用户充值</Title>
                            <img src='/static/images/webapp/alipay-recharge.png' alt='支付宝' style={{
                                maxWidth: 550,
                                margin: '24px auto'
                            }} />

                            <div style={{
                                maxWidth: 500,
                                margin: '0 auto'
                            }}>
                                <div style={inputItemStyle}>
                                    <span>充值金额：</span>
                                    <Input placeholder="请输入充值金额"
                                        value={money}
                                        onChange={e => setMoney(e.target.value)}
                                        style={{ width: 400 }} />
                                </div>
                            </div>
                            <Form obj={datas}></Form>
                            <Button type='primary' onClick={() => chargeBalance()}>提交</Button>
                        </div>
                    </Card>
                </div>
            </Content>

        </HelmetProvider>
    )
}

export default UserRecharge