import React, { useState, useEffect } from 'react';
import { Form, Table, Button, Row, Col, Avatar, Divider, Layout, Typography, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import _ from 'lodash'

import SearchForm from '../../common/SearchForm'
import UserSearchConfig from '../../../role/user/UserSearchConfig'
import { tablePagination } from '../../common/tableCommon';

const { Content } = Layout;
const { Text } = Typography;

const layout = { layout: 'vertical' };
const axios = require('axios');

const WechatEditorTable = ({ mid, allotUser }) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [form] = Form.useForm();

    const userTableConfig = [
        {
            title: '',
            dataIndex: 'avatar_img',
            width: 40,
            render: (t, data) => {
                let base_img = data.avatar_img ? data.avatar_img + "_xs" : `http://origin-static.oss-cn-beijing.aliyuncs.com/static/images/defaultPic.jpg_xs`;
                return <Avatar shape="square" icon={<UserOutlined />} src={base_img} />;
            }
        }, {
            title: '账号名',
            dataIndex: 'account',
            render: (t, data) => {
                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>{data.sell_group_name}</Text>
                    <div className='flex-justify'>
                        <Text strong>{t}</Text>
                        <Text type="secondary" style={{ fontSize: '12px' }}>uid: {data.id}</Text>
                    </div>
                    <Text>{data.available_money === null ? 0 : data.available_money}元</Text>
                </div>
            }
        }, {
            title: '联系方式',
            dataIndex: 'handle_user',
            render: (t, data) => {
                return (
                    <>
                        <div className='flex-justify'>
                            <Text>QQ：</Text>
                            <Text copyable>{data.qq}</Text>
                        </div>
                        <div className='flex-justify'>
                            <Text>电话：</Text>
                            <Text copyable>{data.tel}</Text>
                        </div>
                    </>
                )
            }
        }, {
            title: '操作',
            dataIndex: 'id',
            render: (t, data) => {
                return <Button onClick={() => allotUser(mid, data.id)} type="link">分配</Button>
            }
        }
    ]

    const fetchData = async (val = {}) => {
        let params = new FormData()
        params.append("attache", 3)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/user/getUserList',
                data: params
            }).then((res) => {
                setData(res.data.data)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
                setLoading(false)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        fetchData()
        return () => {
            console.log("FETCH DATA FINISHED")
        }
    }, [])

    let miniMode = true
    const searchDatas = UserSearchConfig({ miniMode })

    return <>
        <Content>
            <Space direction='vertical'>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} />
                    <Divider style={{ margin: '8px 0' }} />
                    <Row>
                        <Col span={12}>
                            <Button type='dashed' onClick={() => allotUser(mid, 2)}>
                                平台自运营
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Table
                    size="small"
                    columns={userTableConfig}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                />
            </Space>

        </Content>
    </>;
}


export default WechatEditorTable
