import React from 'react'
import { Form, Row, Col, Button, Input, message } from 'antd';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CouponPackSetting = ({ coupons, setAmounts }) => {


    return (
        <div style={{ width: '800px', margin: '0 auto' }}>
            <Form
                name="validate_other"
                {...formItemLayout}
                onFinish={values => {
                    setAmounts(values)
                    message.success("已保存")
                }}
                initialValues={coupons}
            >
                <Row gutter={12}>
                    {coupons.map(e => {
                        return (
                            <React.Fragment key={e.id}>
                                <Col span={12}>
                                    <Form.Item label="优惠券名称">
                                        <span className="ant-form-text">{e.coupon_name}</span>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="数量" name={`input_${e.id}`}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </React.Fragment>
                        )
                    })}
                </Row>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                        确认
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default CouponPackSetting
