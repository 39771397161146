import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { check } from '../../utils/permission/rules'

const RoleModule = (props) => {
    const role = useRecoilValue(userRoleState)

    const { action, data = null } = props
    if (!check(role, action, data)) {
        return null
    }
    return props.children
}

export default RoleModule