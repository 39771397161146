import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import qs from 'qs';

const axios = require('axios');
const MediaMenu = ({ setEventId }) => {
    const [datas, setDatas] = useState([])

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getMenu = async () => {
        await axios({
            method: 'post',
            url: '/event/collection',
            data: qs.stringify({ status: 1, length: 30, })
        }, {
            cancelToken: source.token
        }).then((res) => {
            setDatas(res.data.data)
        })
    }

    useEffect(() => {
        getMenu()
        return source.cancel('Operation canceled by the user.');
    }, [])


    return <Tabs defaultActiveKey="1" onChange={e => setEventId(e ? e : "")}
        items={[
            { key: '', label: `全部`, },
        ].concat(datas.map(e => ({ label: e.name, key: e.id })))}
    />
}

export default MediaMenu