import React from 'react'
import { Typography, Tooltip, Button, Space, Popconfirm, Avatar, Tag } from 'antd';
import _ from 'lodash'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';

import { userRoleState, userAttacheState } from '../../Apps/atoms/Atoms'
import { WEIBO, getPrice } from '../../Apps/compenents/weibo/weiboCommon';
import { check } from '../../utils/permission/rules';

const { Paragraph, Text } = Typography;
const WeiboHotTableConfig = ({ action, handleMedia, type }) => {
    const role = useRecoilValue(userRoleState)
    const attache = useRecoilValue(userAttacheState)

    const { getMedia, getSaleGroupPrice, deleteMedia, changeEditor } = handleMedia;

    let permission = {
        'module:weibo:money:cost': check(role, 'module:weibo:money:cost', { action }),
        'module:weibo:money:user': check(role, 'module:weibo:money:user', { action }),
        'module:weibo:money:sale': check(role, 'module:weibo:money:sale', null),
        'module:weibo:money:sale:cost': check(role, 'module:weibo:money:sale:cost', null),
    }

    const formatStr = str => {
        if (_.startsWith(str, '"') && _.endsWith(str, '"')) {
            return str.substring(1, str.length - 1)
        } else {
            return str
        }
    }

    const nameCol = {
        title: '媒体名称',
        dataIndex: 'name',
        width: 220,
        checked: 1,
        render: (val, data, index) => {
            let url = data.uid ? `https://weibo.com/u/${data.uid}` : `https://weibo.com/${data.screen_name}`
            return (
                <Space>
                    <div>
                        <Avatar
                            size={72}
                            src={formatStr(data.profile_image_url)}
                        />
                        {/* <img style={{ position: 'absolute', left: 64, bottom: 8 }} src='https://mbdp03.bdstatic.com/static/superlanding/img/vip-2_b663fc3.png?v=md5' alt="官方认证" /> */}
                    </div>
                    <div>
                        <div>
                            <a href={url} style={{ color: '#333', fontSize: 14, fontWeight: 'bold' }}
                                target='_blank' rel='noopener noreferrer'>{formatStr(val)}</a>
                        </div>
                        <div style={{ width: 150 }}>
                            <Paragraph ellipsis={{ rows: 1 }}>{formatStr(data.descriptions)}</Paragraph>
                        </div>
                        <div>
                            <Text>粉丝数: {data.follower_num}</Text>
                        </div>
                    </div>
                </Space>
            )
        }
    }

    const priceCol = [
        {
            title: '价格',
            dataIndex: 'prices',
            checked: 1,
            render: (val, data) => {
                let vertical_size = 4
                let priceMap = getPrice(data.user_price)
                return <Space size={8}>
                    <Space direction='vertical' size={vertical_size}>
                        <Text strong>发布类型</Text>
                        <Text strong>直发</Text>
                        <Text strong>转发</Text>
                    </Space>
                    {
                        permission['module:weibo:money:cost'] &&
                        <Space direction='vertical' size={vertical_size}>
                            <div>成本</div>
                            <div>{data.post_price || '暂无'}</div>
                            <div>{data.repost_price || '暂无'}</div>
                        </Space>
                    }
                    {
                        permission['module:weibo:money:sale:cost'] &&
                        <Space direction='vertical' size={vertical_size}>
                            <div>销售</div>
                            <div>{data.sale_post_price || '暂无'}</div>
                            <div>{data.sale_repost_price || '暂无'}</div>
                        </Space>
                    }
                    {
                        permission['module:weibo:money:sale'] &&
                        <Space direction='vertical' size={vertical_size}>
                            <div>成本</div>
                            <div>{data.sale_post_price || '暂无'}</div>
                            <div>{data.sale_repost_price || '暂无'}</div>
                        </Space>
                    }
                    {
                        permission['module:weibo:money:user'] &&
                        <Space direction='vertical' size={vertical_size}>
                            <div>报价</div>
                            <div>{priceMap[WEIBO.POST.id] || '暂无'}</div>
                            <div>{priceMap[WEIBO.REPOST.id] || '暂无'}</div>
                        </Space>
                    }
                </Space>
            }
        }, {
            title: '微任务',
            dataIndex: 'duties_prices',
            checked: 1,
            render: (val, data) => {
                let vertical_size = 4
                let priceMap = getPrice(data.user_price)
                return <Space size={8}>
                    <Space direction='vertical' size={vertical_size}>
                        <Text strong>发布类型</Text>
                        <Text strong>直发</Text>
                        <Text strong>转发</Text>
                    </Space>
                    {
                        permission['module:weibo:money:cost'] &&
                        <Space direction='vertical' size={vertical_size}>
                            <div>成本</div>
                            <div>{data.duties_post_price || '暂无'}</div>
                            <div>{data.duties_repost_price || '暂无'}</div>
                        </Space>
                    }
                    {
                        permission['module:weibo:money:sale:cost'] &&
                        <Space direction='vertical' size={vertical_size}>
                            <div>销售</div>
                            <div>{data.duties_sale_post_price || '暂无'}</div>
                            <div>{data.duties_sale_repost_price || '暂无'}</div>
                        </Space>
                    }
                    {
                        permission['module:weibo:money:sale'] &&
                        <Space direction='vertical' size={vertical_size}>
                            <div>成本</div>
                            <div>{data.duties_sale_post_price || '暂无'}</div>
                            <div>{data.duties_sale_repost_price || '暂无'}</div>
                        </Space>
                    }
                    {
                        permission['module:weibo:money:user'] &&
                        <Space direction='vertical' size={vertical_size}>
                            <div>报价</div>
                            <div>{priceMap[WEIBO.DUTIES_POST.id] || '暂无'}</div>
                            <div>{priceMap[WEIBO.DUTIES_REPOST.id] || '暂无'}</div>
                        </Space>
                    }
                </Space>
            }
        }
    ]

    const mediaCol = [
        {
            title: '媒体详情',
            dataIndex: 'location',
            checked: 1,
            render: (text, data) => {
                return <Text strong>所在地区:{data.area}</Text>
            }
        }, {
            title: '标签',
            dataIndex: 'tag',
            checked: 1,
            render: (text, data) => {
                if (data.tag !== '') {
                    let arr = _.split(data.tag, ',')
                    return <div>
                        {arr.map(tag => <Tag color='blue' key={tag}>{tag}</Tag>)}
                    </div>
                }
            }
        }, {
            title: '认证类型',
            dataIndex: 'verified_type',
            checked: 1,
            render: (val, data) => {
                switch (val) {
                    case '金V':
                        return <Tag color='gold'>金V认证</Tag>
                    case '黄V':
                        return <Tag color='yellow'>黄V认证</Tag>
                    case '蓝V':
                        return <Tag color='blue'>蓝V认证</Tag>
                    default:
                        return <Tag>未认证</Tag>
                }
            }
        }
    ]


    const mediaEditorCol = [
        {
            title: '编辑',
            dataIndex: 'editor_name',
            checked: 1,
            render: (t, data) => {
                return (
                    <Space direction='vertical' size={4}>
                        <span>
                            <Text>{data.creator_name}</Text>
                            <Button type='link' size='small' icon={<EditOutlined />} onClick={() => changeEditor(data.id)} />
                        </span>
                        <Text>{data.editor_name}</Text>
                        <Text>{data.editor_tel}</Text>
                    </Space>
                )
            }
        }, {
            title: '渠道',
            dataIndex: 'owned',
            checked: 1,
            render: (text, data) => {
                return Boolean(data.owned) ? '博主' : '媒介'
            }
        }, {
            title: '内部备注',
            dataIndex: 'inner_remarks',
            checked: 1,
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    <Paragraph ellipsis={{ rows: 3 }}>
                        {text}
                    </Paragraph>
                </Tooltip>
            )
        }
    ]

    const mediaRemarkCol = {
        title: '备注',
        dataIndex: 'remarks',
        checked: 1,
        render: (text) => (
            <Tooltip placement="topLeft" title={text}>
                <Paragraph ellipsis={{ rows: 3 }}>
                    {text}
                </Paragraph>
            </Tooltip>
        )
    }

    const modifyMediaCol = [
        {
            title: '操作',
            dataIndex: 'id',
            fixed: 'right',
            width: 100,
            checked: 1,
            render: (text, data) => (
                <Space>
                    <Button type="link" icon={<EditOutlined />} size='small' onClick={() => getMedia(data.id)} />
                    <Popconfirm
                        title={`确认删除${data.name}吗?`}
                        onConfirm={() => deleteMedia(data.id)}
                        onCancel={e => console.log(e)}
                        okText="确认"
                        cancelText="取消">
                        <Button type="link" icon={<DeleteOutlined />} size='small' />
                    </Popconfirm>
                </Space>
            )
        }
    ]


    const updateSaleGroupPriceCol = {
        title: '操作',
        dataIndex: 'id',
        fixed: 'right',
        width: 100,
        checked: 1,
        render: (text, data) => {
            switch (attache) {
                case 0:
                    return <Space>
                        <Button type="link" size='small' onClick={() => getSaleGroupPrice(data.id, 3)}>客户价格分配</Button>
                        <Button type="link" size='small' onClick={() => getSaleGroupPrice(data.id, 9)}>会员价格分配</Button>
                    </Space>
                default:
                    break;
            }
        }
    }

    let result = []
    switch (role) {
        case "CUSTOMER":
            if (action === 'EDIT') {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    modifyMediaCol,
                )
            } else {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaRemarkCol,
                )
            }

            break;
        case "OPERATOR":
            switch (attache) {
                case 0:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaEditorCol,
                        mediaRemarkCol,
                        modifyMediaCol,
                    )
                    break;
                case 1:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                    break;
                default:
                    break;
            }
            break;
        case "SALE":
        case "FRANCHISE":
            switch (attache) {
                case 0:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                        updateSaleGroupPriceCol
                    )
                    break;
                case 1:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                        updateSaleGroupPriceCol
                    )
                    break;
                case 2:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
    return result
}
export default WeiboHotTableConfig;
