import React, { useState } from 'react';
import { Layout, Button, Avatar, Modal, Divider, Typography, Row, Col, Space, Drawer, Menu, Dropdown, Popover } from 'antd';
import {
    UserOutlined, LogoutOutlined, AlipayOutlined, MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilValue, useRecoilState } from 'recoil';

import { userState, sidebarCollapsed } from '../atoms/Atoms'
import ProfitBillboard from '../compenents/user/ProfitBillboard';
import { useAuthUser } from '../../utils/AuthUser'
import PremiunUser from '../compenents/user/PremiunUser';
import { signOut } from '../auth/SignOut';
import RoleModule from '../../utils/permission/RoleModule';
import HeaderNav from './HeaderNav';
import Chat from '../compenents/chat/Chat'

const { Header } = Layout;
const { Title, Paragraph, Text } = Typography;

const user_avatar = 'http://static.yunzitui.com/upload/user/common/yh1.jpg';
const textCenterStyle = { textAlign: 'center' }
const DefaultHeader = () => {
    const { resetLoginState } = useAuthUser();
    const sessionUser = useRecoilValue(userState)
    const [collapsed, setCollapsed] = useRecoilState(sidebarCollapsed)
    const [visible, setVisible] = useState({ chat: false, qq: false })

    const onCollapse = () => setCollapsed(!collapsed)

    let navigate = useNavigate();
    const [modal, setModal] = useState({
        bindWechat: false, buyVip: false, profit: false
    })

    function logout() {
        signOut()
        resetLoginState()
        navigate("/")
    }

    const items = [
        {
            key: 'profile',
            label: <Link to="/dashboard/user">个人资料</Link>,
            icon: <UserOutlined />
        },
        {
            key: 'recharge',
            label: <Link to="/dashboard/user/recharge">余额充值</Link>,
            icon: <AlipayOutlined />
        },
        {
            key: 'logout',
            label: <Button type='link' onClick={logout} style={{ color: 'rgba(0,0,0,.65)', padding: 0 }}>
                登出
            </Button>,
            icon: <LogoutOutlined />
        },
    ];

    return (
        <Header className="header"
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid #e9e9e9',
                padding: '0 12px',
                position: 'fixed',
                zIndex: 99,
                width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)',
            }}>
            <Space className='header-left'>
                {
                    collapsed
                        ? <MenuUnfoldOutlined className='trigger' onClick={onCollapse} />
                        : <MenuFoldOutlined className='trigger' onClick={onCollapse} />
                }
                <Popover placement="bottomLeft" title={null} content={<HeaderNav />} >
                    <Button type='primary'>更多资源</Button>
                </Popover>
            </Space>
            <Space size="small" style={{ display: 'flex', flexDirection: 'row' }}>
                {/* <Button type="primary" onClick={() => setModal({ ...modal, profit: true })}>会员收益排行</Button> */}
                <RoleModule action='module:header:vip'>
                    <Space>
                        <Button type="primary" danger onClick={() => { window.open(`http://wpa.qq.com/msgrd?v=3&uin=3001301493&site=qq&menu=yes`, 'top') }}>申请账期</Button>
                        <Button type="primary" onClick={() => setModal({ ...modal, buyVip: true })}>成为会员</Button>
                        <Button onClick={() => setModal({ ...modal, bindWechat: true })}>绑定微信</Button>
                    </Space>
                </RoleModule>
                {/* <Button onClick={() => setVisible({ ...visible, chat: true })}>聊天</Button> */}

                <Dropdown menu={{ items }} placement="bottomLeft" trigger={['click']}>
                    <span style={{ cursor: 'pointer', height: '64px', minWidth: '80px' }}>
                        <div style={{ height: '64px', marginLeft: '12px' }}>
                            <Avatar src={sessionUser.basic.avatar_img || user_avatar} size={24} icon={<UserOutlined />} style={{ marginRight: '8px' }} />
                            <Text>{sessionUser.account}</Text>
                        </div>
                    </span>
                </Dropdown>
            </Space>

            <Modal
                title="绑定微信号"
                width={800}
                open={modal.bindWechat}
                onOk={() => { setModal({ ...modal, bindWechat: false }) }}
                onCancel={() => { setModal({ ...modal, bindWechat: false }) }}
            >
                <Title style={textCenterStyle}>需要关注公众号，之后扫码绑定微信即可</Title>
                <Divider />
                <Row gutter={16}>
                    <Col span={12}>
                        <div>
                            <Paragraph style={textCenterStyle}>1，使用微信扫码关注公众号</Paragraph>
                            <Divider />
                            <div style={textCenterStyle}>
                                <img style={{
                                    maxWidth: '180px',
                                    width: '100%'
                                }} src="/static/images/asset/wechat/wechat_yzt_qrcode.png" alt='' />
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <Paragraph style={textCenterStyle} >2，使用微信扫码，绑定微信号，即使获取订单详情</Paragraph>
                            <Divider />
                            <div style={textCenterStyle}>
                                <img id="img" style={{
                                    maxWidth: '180px',
                                    width: '100%'
                                }} src="/wechat/account/qrcode" alt='' />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="购买会员"
                width={1200}
                open={modal.buyVip}
                onOk={() => setModal({ ...modal, buyVip: false })}
                onCancel={() => setModal({ ...modal, buyVip: false })}
            >
                <PremiunUser modal={modal} setModal={setModal} />
            </Modal>
            <Modal
                width={450}
                open={modal.profit}
                onOk={() => { setModal({ ...modal, profit: false }) }}
                onCancel={() => { setModal({ ...modal, profit: false }) }}
            >
                <ProfitBillboard />
            </Modal>

            <Drawer
                title="Basic Drawer"
                placement="right"
                closable={false}
                onClose={() => setVisible({ ...visible, chat: false })}
                open={visible.chat}
                width={800}
            >
                <Chat />
            </Drawer>
        </Header>
    );
}

export default DefaultHeader