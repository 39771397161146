import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'


const EventDatasConfig = ({ type }) => {
    const role = useRecoilValue(userRoleState)

    const eventBasicDatas = [
        {
            name: 'name',
            label: '活动名称',
            required: true,
            placeholder: '请输入活动名称',
            type: 'input'
        },
    ]

    const timeCol = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker'
        },
        {
            name: 'end_time',
            label: '截止时间',
            placeholder: '请输入截止时间',
            type: 'datepicker'
        },
    ]

    const remarkCol = [
        {
            name: 'description',
            label: '备注',
            placeholder: '请输入备注',
            type: 'area'
        },
    ]

    const ruleCol = [
        {
            name: 'price',
            label: '总奖金',
            placeholder: '请输入总奖金',
            type: 'inputnumber'
        },
        {
            name: 'target',
            label: '目标数',
            placeholder: '请输入目标数字',
            type: 'inputnumber'
        },
    ]


    let result = []

    switch (role) {
        case "OPERATOR":
        case "SALE":
        case "FRANCHISE":
            if (type === 'collections') {
                result = [
                    eventBasicDatas,
                    remarkCol
                ]
            } else if (type === 'event') {
                result = [
                    eventBasicDatas,
                    timeCol,
                    ruleCol,
                    remarkCol,
                ]
            }
            break;
        default:
            result = [
                eventBasicDatas,
                remarkCol
            ]
            break;
    }

    return result
}

export default EventDatasConfig