import React, { useState, useEffect, createRef } from 'react'
import { List, Collapse, Typography, Tag, Modal, Carousel, Button } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash'
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';

import { userRoleState } from '../../atoms/Atoms'
import { check } from '../../../utils/permission/rules';

const { Panel } = Collapse;
const { Text, Title } = Typography;
const axios = require('axios');

const dataState = atom({
    key: 'noitceDatas', // unique ID (with respect to other atoms/selectors)
    default: [] // default value (aka initial value)
});
const popupDataState = selector({
    key: 'popupDataState', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const text = get(dataState);
        return text.filter(e => e.popup === 1);
    },
});
// 用户控制台右侧平台公告列表 以及 首页弹窗公告
const NoticeList = () => {

    const role = useRecoilValue(userRoleState)

    const [data, setData] = useRecoilState(dataState)
    const popupData = useRecoilValue(popupDataState)
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)

    const slideRef = createRef();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getData = async () => {
        if (!loading) {
            setLoading(true)
            await axios({
                url: '/notice/noticeList',
                method: 'post',
                contentType: 'application/json'
            }, {
                cancelToken: source.token
            }).then(res => {
                if (res.data.code === 1) {
                    let tempdata = res?.data?.data
                    setData(tempdata)
                    if (_.size(tempdata.filter(e => e.popup === 1)) > 0) {
                        if (check(role, 'module:dashboard:popup', null)) {
                            setModal(true)
                        }
                    }
                }
                setLoading(false)
            })
        }
    };

    const next = () => slideRef.current.next()
    const prev = () => slideRef.current.prev()

    useEffect(() => {
        getData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    const getTitle = item => {
        if (item.pinned === 1) {
            return <Text strong style={{ fontSize: 14 }}><Tag color="red">置顶</Tag>{item.title}</Text>
        } else {
            return <Text style={{ fontSize: 14 }}>{item.title}</Text>
        }
    }
    return <>
        <List
            className='notice-list'
            loading={loading}
            itemLayout="vertical"
            bordered={false}
            dataSource={data}
            split={false}
            renderItem={item => (
                <List.Item key={item.id}>
                    <Collapse
                        accordion
                        bordered={false}
                        expandIconPosition='end'
                        ghost
                    >
                        <Panel header={getTitle(item)} key="1">
                            <div className='notice-detail'>{ReactHtmlParser(item.content)}</div>
                        </Panel>
                    </Collapse>
                </List.Item>
            )}
        />
        <Modal
            title="通知"
            open={modal}
            footer={null}
            onCancel={() => setModal(false)}
        >
            <div>
                <Carousel ref={slideRef}>
                    {
                        popupData.map(e => <div key={e?.id}>
                            <Title level={4}>
                                {e.title}
                            </Title>
                            <span>{ReactHtmlParser(e?.content)}</span>
                            {
                                _.size(popupData) > 1 &&
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button onClick={prev}>上一条</Button>
                                    <Button type='primary' onClick={next}>下一条</Button>
                                </div>
                            }
                        </div>)
                    }
                </Carousel>
            </div>
        </Modal>
    </>
}

export default NoticeList