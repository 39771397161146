import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageHeader from '../../layout/PageHeader';

import WithdrawManageTable from './WithdrawManageTable';


// 管理员打款用页面
// /dashboard/withdraw/all/list

const WithdrawManage = () => {
    let pageTitle = '财务提现管理'

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <div style={{ margin: 24 }}>
                <WithdrawManageTable />
            </div>
        </HelmetProvider>
    )
}

export default WithdrawManage;