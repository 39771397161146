import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const QaDatasConfig = () => {
    const role = useRecoilValue(userRoleState)

    const qaBasicDatas = [
        {
            name: 'name',
            label: '问答名称',
            required: true,
            placeholder: '请输入问答名称',
            type: 'input'
        },
        {
            name: 'sample_url',
            label: '案例地址',
            required: true,
            placeholder: '请输入案例地址',
            type: 'input'
        },
    ]

    const qaPriceDatas = [
        {
            name: 'cost',
            label: '问答价格',
            placeholder: '请输入问答价格',
            type: 'inputnumber'
        },
        {
            name: 'sale_price',
            label: '相对成本',
            placeholder: '请输入相对成本',
            type: 'inputnumber'
        },
        {
            name: 'agent_price',
            label: '代理端价格',
            placeholder: '请输入代理端价格',
            type: 'inputnumber'
        },
    ]

    const qaPropsDatas = [
        {
            name: 'category',
            label: '问答类别',
            type: 'radio',
            children: [
                ['一问一答', '一问一答'],
                ['一问多答', '一问多答'],
            ]
        },
        {
            name: 'platform',
            label: '百科平台',
            type: 'radio',
            children: [
                ['百度', '百度'],
                ['搜狗', '搜狗'],
                ['360', '360'],
                ['互动', '互动'],
            ]
        },
    ]
    const commentDatas = [
        {
            name: 'comment',
            label: '备注',
            placeholder: '请输入备注',
            type: 'area'
        },
    ]

    let result = []

    switch (role) {
        case "OPERATOR":
            result = [
                qaBasicDatas,
                qaPriceDatas,
                qaPropsDatas,
                commentDatas
            ]
            break;
        default:
            break;
    }

    return result
}

export default QaDatasConfig