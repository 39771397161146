import React, { useState, useEffect } from 'react'
import { Card, Layout, Row, Col, Modal, Typography, Empty, Spin } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { UnorderedListOutlined, FileDoneOutlined, BarsOutlined } from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs';


import { initFormData, tablePagination } from '../../common/tableCommon';
import RankModal from './RankModal';
import ResultModal from './ResultModal';
import MediaModal from './MediaModal';
import PageHeader from '../../layout/PageHeader';


const axios = require('axios');

const { Content } = Layout
const { Title, Paragraph, Text } = Typography
const EventsCenter = () => {

    let pageTitle = "活动中心"

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState({ rank: false, withdraw: false, result: false, medias: false })

    const fetchData = async (params = new FormData()) => {
        setLoading(true)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        await axios({
            method: 'post',
            url: '/event/eventList',
            data: params
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
            let total = res.data.total
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    const cardBottom = (e) => {
        let isFinish = e.state === 'Finish'
        let isOutOfTime = dayjs().diff(dayjs(e.end_time), 'days') > 30

        if (isFinish) {
            if (isOutOfTime) {
                return [<span>活动已结束</span>]
            } else {
                return [
                    <span onClick={() => setModal({ ...modal, result: true, id: e.id, rule: e.rule })}>
                        <FileDoneOutlined key="shop" /> 查看结果
                    </span>
                ]
            }
        } else {
            return [
                <span onClick={() => setModal({ ...modal, rule: e.rule, id: e.id, rank: true })}>
                    <UnorderedListOutlined key="shop" /> 查看排名
                </span>,
                <span onClick={() => setModal({ ...modal, medias: true, id: e.id })}>
                    <BarsOutlined key="media" /> 媒体范围
                </span>,
            ]
        }
    }

    useEffect(() => {
        fetchData()
    }, [])


    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content style={{ margin: 24 }}>
                <div style={{ background: '#fff', padding: 24, margin: 0 }}>
                    {
                        loading
                            ? <div style={{ display: 'grid', placeContent: 'center' }}>
                                <Spin />
                            </div>
                            : <>
                                {_.size(data) === 0
                                    ? <Empty />
                                    : <Row gutter={[24, 24]}>
                                        {data.map(e => {
                                            return <React.Fragment key={e.id}>
                                                <Col span={8} >
                                                    <Card title={e.name} hoverable
                                                        actions={cardBottom(e)}>
                                                        <Title level={4}>{e.description}</Title>
                                                        <Paragraph>
                                                            <Text>开始时间:{e.start_time}</Text>
                                                            <br />
                                                            <Text>截止时间:{e.end_time}</Text>
                                                        </Paragraph>
                                                    </Card>
                                                </Col>
                                            </React.Fragment>
                                        })}
                                    </Row>}
                            </>
                    }
                </div>
            </Content>

            <Modal open={modal.rank} width={800}
                title="排行榜"
                onOk={() => setModal({ ...modal, rank: false })}
                onCancel={() => setModal({ ...modal, rank: false })}>
                <RankModal rule={modal.rule} id={modal.id} />
            </Modal>

            <Modal open={modal.result} width={800}
                title="活动结果"
                onOk={() => setModal({ ...modal, result: false })}
                onCancel={() => setModal({ ...modal, result: false })}>
                <ResultModal rule={modal.rule} id={modal.id} />
            </Modal>

            <Modal open={modal.medias} width={800}
                title="媒体范围"
                onOk={() => setModal({ ...modal, medias: false })}
                onCancel={() => setModal({ ...modal, medias: false })}>
                <MediaModal eventId={modal.id} />
            </Modal>
        </HelmetProvider>
    )
}

export default EventsCenter
