import React from 'react'
import CKEditor from 'ckeditor4-react'

import CkeditorPref from '../config/CkeditorPref'

function CKEditorInstance({ content, setContent }) {
    CKEditor.editorUrl =
        // 'https://lf6-cdn-tos.bytecdntp.com/cdn/expire-1-M/ckeditor/4.17.2/ckeditor.js' ||
        'https://lib.baomitu.com/ckeditor/4.20.1/ckeditor.js' ||
        'https://cdn.staticfile.org/ckeditor/4.20.1/ckeditor.js' ||
        'https://cdn.bootcdn.net/ajax/libs/ckeditor/4.18.0/ckeditor.js';


    return <CKEditor
        onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
        config={CkeditorPref}
        data={content}
        onChange={e => setContent(e.editor.getData())}
        onBlur={e => setContent(e.editor.getData())}
    />

}
export default CKEditorInstance;