export const timeConvert = (val) => {

    let numbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    let times = ['', '十', '二十', '三十']

    let tens = Math.floor(val / 10)
    let number = val % 10

    return times[tens] + numbers[number]

}

export const timeToChinese = (year, month) => {
    if (year === 0) {
        return `${timeConvert(month)}个月`
    } else {
        if (month === 0) {
            return `${timeConvert(year)}年`
        } else {
            return `${timeConvert(year)}年${timeConvert(month)}个月`
        }
    }
}