import React, { useState, useEffect } from 'react'
import { Row, Button, Form, Space } from 'antd';
import _ from 'lodash'

import CreateForm from '../../common/CreateForm';
import MediaDatasConfig from '../../../role/media/MediaDatasConfig';
import { resultResponse } from '../../common/ResultResponse';
import dayjs from 'dayjs';

const axios = require('axios');

const AddMediaForm = (props) => {
    const { mediaType, media, drawerAction } = props;
    const { visible, setVisible, reloadTable } = drawerAction

    const [form] = Form.useForm();                  // 媒体搜索form
    const [loading, setLoading] = useState(false)

    const datas = MediaDatasConfig({ mediaType })  // 确定字段

    const resetForm = () => form.resetFields();

    const disposeMedia = media => {
        media = _.pickBy(media, _.identity)
        let names = _.flatten(datas).filter(e => e.type === 'timepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(media, (v, k) => {
                if (v && k === name) {
                    media = { ...media, [k]: dayjs(v.$d).format('HH:mm:ss') }
                }
            })
        })
        let params = new FormData()
        _.toPairs(media).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        return params
    }

    // 创建媒体
    const createMedia = async (media) => {
        let params = disposeMedia(media)
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/media/addMedia',
                data: params
            }).then((res) => {
                resultResponse(res, '已成功添加')
                reloadTable()
                setLoading(false)
            })
        }
    }

    // 修改媒体
    const updateMedia = async (media) => {
        let params = disposeMedia(media)
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post', url: '/media/edit', data: params
            }).then((res) => {
                resultResponse(res, '已成功修改')
                reloadTable()
                setLoading(false)
            })
        }
    }


    useEffect(() => {
        if (media?.id) {
            let tmp = _.reduce(_.flatten(datas), (result, ele) => {
                if (ele.type === 'timepicker') {
                    return {
                        ...result, [ele.name]: dayjs(media[ele.name], 'HH:mm:ss')
                    }
                } else {
                    return result
                }
            }, media)
            form.setFieldsValue(tmp)
        } else {
            form.resetFields()
            let valArr = _.flatten(datas).filter(e => e.value)
            let val = _.reduce(valArr, (result, ele) => {
                if (ele.type === 'timepicker') {
                    return {
                        ...result, [ele.name]: dayjs(ele.value, 'HH:mm:ss')
                    }
                } else {
                    return {
                        ...result, [ele.name]: ele.value
                    }
                }
            }, {})
            form.setFieldsValue(val)
        }
    }, [media, form, datas])

    return (
        <>
            <Form layout='vertical' form={form} name="media_form"
                onFinish={(val) => {
                    let tempMedia = _.assignInWith(media, val, customizer)
                    if (visible.action === 'ADD') {
                        createMedia(tempMedia)
                    } else if (visible.action === 'EDIT') {
                        updateMedia(tempMedia)
                    }
                    setVisible({ ...visible, addMedia: false })
                }}
            >
                {
                    datas.map(data => <Row gutter={16} key={data[0].name}>
                        <CreateForm datas={data} span={12} />
                    </Row>)
                }
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0' }}>
                    <Button type='primary' danger onClick={resetForm} style={{ marginRight: 8 }} >
                        重置媒体
                    </Button>
                    <Space>
                        <Button onClick={() => { setVisible({ ...visible, addMedia: false }) }} >
                            取消
                        </Button>
                        <Button htmlType='submit' loading={loading} type='primary'>提交</Button>
                    </Space>
                </div>
            </Form>
        </>
    )
}

export default AddMediaForm

function customizer(objValue, srcValue) {
    return _.isUndefined(srcValue) ? "" : srcValue;
}