import React from 'react'
import { Button, Tag } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'

const PackagesTableConfig = ({ handleMedia }) => {
    const role = useRecoilValue(userRoleState)

    const { bindMedia } = handleMedia;

    const nameCol = [
        {
            title: '媒体名称',
            dataIndex: 'name',
            checked: 1,
        }
    ]

    const detailCol = [
        {
            title: '详情',
            dataIndex: 'names',
            checked: 1,
            render: (t, data) => {
                if (t) {
                    let arr = _.split(t, ',')
                    return <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            arr.map(e => {
                                let media = _.split(e, '_')
                                return <span key={media[0]}>{`${media[0]}: ${media[1]}`}</span>
                            })
                        }
                    </div>
                }
            }
        }, {
            title: '状态',
            dataIndex: 'state',
            checked: 1,
            render: (t, data) => {
                let state = <Tag style={{ margin: 0 }} color="green">可用</Tag>
                switch (t) {
                    case 'AVAILABLE':
                        state = <Tag style={{ margin: 0 }} color="green">可用</Tag>
                        break;
                    case 'INVALID':
                        state = <Tag style={{ margin: 0 }} color="red">已停用</Tag>
                        break;
                    default:
                        break;
                }
                return state
            }
        }
    ]



    const packTimeCol = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            checked: 1,
        }, {
            title: '结束时间',
            dataIndex: 'expire_time',
            checked: 1,
        }
    ]

    const modifyMediaCol = {
        title: '操作',
        dataIndex: 'id',
        checked: 1,
        render: (text, data) => {
            return <Button type='link' onClick={() => bindMedia(data.id)}>绑定媒体</Button>
        }
    }



    let result = []

    switch (role) {
        case "CUSTOMER":
        case "OPERATOR":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case "SALE":
        case "FRANCHISE":
            result = _.concat(
                nameCol,
                detailCol,
                packTimeCol,
                modifyMediaCol
            )
            break;
        default:
            break;
    }
    return result
}
export default PackagesTableConfig;
