import React from 'react'
import { Typography, Tooltip, Button } from 'antd';
import _ from 'lodash'
import { EditOutlined, CaretRightOutlined, PauseOutlined, } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';

import { userRoleState, userAttacheState } from '../../Apps/atoms/Atoms'

const { Paragraph, Text } = Typography;

const QaTableConfig = ({ action, handleMedia, simpleMode }) => {
    const role = useRecoilValue(userRoleState)
    const attache = useRecoilValue(userAttacheState)
    const { getMedia, handleStatus, getQaSaleGroupPrice, reloadTable } = handleMedia;

    const nameCol = [
        {
            title: '媒体名称',
            dataIndex: 'name',
            render: (text, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text >{text}</Text>
                        <span><a href={data.sample_url} target='_blank' rel='noopener noreferrer'>查看案例</a></span>
                    </div>
                )
            }
        }
    ]

    const priceCol = {
        title: '价格',
        dataIndex: 'sale_price',
        render: (t, data) => {
            let result = <Text strong>{t}</Text>
            switch (role) {
                case 'CUSTOMER':
                    result = <Text strong>{data.user_price}</Text>
                    break;
                case 'OPERATOR':
                    result = <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text strong>成本：{data.cost}</Text>
                        <Text strong>销售：{data.sale_price}</Text>
                        <Text strong>代理：{data.agent_price}</Text>
                    </div>
                    break;
                case 'FRANCHISE':
                case 'SALE':
                    result = <Text>{data.sale_price}</Text>
                    break;
                default:
                    result = <Text>{data.user_price}</Text>
                    break;
            }
            return <div style={{ display: 'flex', flexDirection: 'column' }}>
                {result}
            </div>
        }
    }

    const mediaCol = [
        {
            title: '问答详情',
            dataIndex: 'platform',
            render: (text, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <Text className="table-text">问答平台:</Text>
                            <Text strong>{data.platform}</Text>
                        </div>
                        <div>
                            <Text className="table-text">问答类型:</Text>
                            <Text strong>{data.category}</Text>
                        </div>
                    </div>
                )
            }
        },
    ]

    const mediaCheckCol = {
        title: '审核',
        dataIndex: 'checked',
        render: (text, data) => {
            let stateCol = data.status === 1
                ? <Tooltip title="媒体正常">
                    <Button onClick={() => handleStatus(data.id, 'status', 2)} style={{ backgroundColor: '#3dbd7d', color: '#ffffff' }}>
                        <CaretRightOutlined />
                    </Button>
                </Tooltip>
                : <Tooltip title="媒体暂停">
                    <Button onClick={() => handleStatus(data.id, 'status', 1)} style={{ backgroundColor: '#ffbf00', color: '#ffffff' }}>
                        <PauseOutlined />
                    </Button>
                </Tooltip>
            return stateCol
        }
    }

    const mediaRemarkCol = {
        title: '备注',
        dataIndex: 'comment',
        width: '36%',
        render: (text) => (
            <Paragraph ellipsis={{ rows: 3 }}>
                {text}
            </Paragraph>
        )
    }

    const modifyMediaCol = {
        title: '操作',
        dataIndex: 'id',
        fixed: 'right',
        width: 100,
        render: (text, data) => {
            return <Button type="link" icon={<EditOutlined />} size='small' onClick={() => getMedia(data.id)} />;
        }
    }

    const updateSaleGroupPriceCol = {
        title: '操作',
        dataIndex: 'id',
        fixed: 'right',
        width: 100,
        render: (text, data) => {
            switch (attache) {
                case 0:
                    return <>
                        <Button type="link" size='small'
                            onClick={() => {
                                getQaSaleGroupPrice(data.id, 3)
                            }}>
                            客户价格分配
                </Button>
                    </>
                default:
                    break;
            }
        }
    }


    let result = []
    switch (role) {
        case "CUSTOMER":
            if (action === 'EDIT') {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaCheckCol,
                    mediaRemarkCol,
                    modifyMediaCol,
                )
            } else {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaRemarkCol,
                )
            }

            break;
        case "OPERATOR":
            switch (attache) {
                case 0:
                    if (simpleMode) {
                        result = _.concat(
                            nameCol,
                            priceCol,
                            mediaCol,
                        )
                    } else {
                        result = _.concat(
                            nameCol,
                            priceCol,
                            mediaCol,
                            mediaCheckCol,
                            mediaRemarkCol,
                            modifyMediaCol,
                        )
                    }
                    break;
                case 1:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                    break;
                default:
                    break;
            }
            break;
        case "SALE":
        case "FRANCHISE":
            switch (attache) {
                case 0:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                        updateSaleGroupPriceCol
                    )
                    break;
                case 1:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                    break;
                case 2:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                        updateSaleGroupPriceCol,
                    )
                    break;
                default:
                    break;
            }
            break;
        default:
            result = _.concat(
                nameCol,
                priceCol,
                mediaCol,
                mediaRemarkCol,
            )
            break;
    }
    return result
}
export default QaTableConfig;
