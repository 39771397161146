import React, { useState } from 'react'
import { InboxOutlined, AlignLeftOutlined, SnippetsOutlined } from '@ant-design/icons';
import {
    Form, Layout, Steps, Button, message, Row, Col, Input, Upload, Space,
    Descriptions, Card, Tag, Affix, Divider, Typography
} from 'antd';
import qs from 'qs';
import _ from 'lodash';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import PageHeader from '../../layout/PageHeader';
import BaikeTable from './BaikeTable';
import { resultResponse } from '../../common/ResultResponse';
import Formatter from '../../service/Formatter'
import CKEditorInstance from '../../../utils/CkeditorInstance';
import { Feedback } from '../../common/CreateCommon'

const axios = require('axios');

const { Content } = Layout;
const { Text } = Typography;
const { TextArea } = Input;
const { Step } = Steps;
const { Dragger } = Upload;
const layout = {
    labelCol: { sm: 6, xs: 24 },
    wrapperCol: { md: 12, sm: 12, xs: 24 },
    layout: 'horizontal'
};


const CreateBaikeOrder = () => {

    let pageTitle = "创建百科"

    const [content, setContent] = useState("")
    const [current, setCurrent] = useState(0)
    const [baikes, setBaikes] = useState([])
    const [order, setOrder] = useState({ title: '', remark: '', url: '' })
    const [feedback, setFeedback] = useState({})
    const [fetching, setFetching] = useState(false)
    const [visible, setVisible] = useState({ preview: false })

    const nextPage = () => setCurrent(current + 1)
    const prevPage = () => setCurrent(current - 1)
    const resetAll = () => {
        setCurrent(0);
        setBaikes([])
        setOrder({ title: '', remark: '', url: '' })
        setContent('')
    }

    // ANTD 拖拽上传 docx 配置
    const props = {
        name: 'upload_file',
        multiple: true,
        action: `/article/upload/docx?_r=${Math.random()}`,
        beforeUpload(file) {
            const isLt10M = file.size / 1024 / 1024 < 12;
            if (!isLt10M) {
                message.error('当前支持文档最大尺寸为12M!');
                return false
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                let response = info.file.response
                setContent(response.data.content)
                message.success(`${info.file.name} 已成功上传！`);
            } else if (status === 'error') {
                message.error(`${info.file.name} 上传失败，请检查文件格式`);
            }
        },
    };

    // 发布订单
    const sendOrder = async () => {
        if (_.size(baikes) === 0) { message.error("请选择媒体!"); return; }
        if (order.title === '') { message.error('请填写标题后提交！'); return }
        if (order.url === '' && content.trim() === '') { message.error('请填写内容后提交！'); return; }
        if (!fetching) {
            setFetching(true)
            nextPage()
            await axios({
                method: 'post',
                url: '/baike/article',
                data: qs.stringify({
                    title: order.title,
                    remarks: order.remark,
                    content,
                    media_datas: JSON.stringify(_.uniqBy(baikes, 'id')),
                })
            }).then((res) => {
                resultResponse(res, '已成功发布！')
                setFeedback(res.data)
                setFetching(false)
            })
        }
    }

    // 分布发稿
    const steps = [
        {
            title: '创建稿件',
            content: (
                <div style={{ background: '#fff', padding: '24px', }}>
                    <Form {...layout}>
                        <Row gutter={[12, 12]}>
                            <Col span={24} key='title' >
                                <Form.Item label="订单标题">
                                    <Input placeholder="请输入订单标题" value={order.title}
                                        onChange={e => setOrder({ ...order, title: e.target.value.trim() })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} key='remark' >
                                <Form.Item label='订单备注'>
                                    <TextArea placeholder="请输入订单备注, 如:图片注释居中, 小标题加粗, 注意排版" value={order.remark} rows={3}
                                        onChange={e => setOrder({ ...order, remark: _.trimStart(e.target.value) })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} key='upload'>
                                <Form.Item label='上传文本'>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">点击或者拖拽上传文本，支持doc / docx格式转码</p>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                            <Col span={12} key='format'>
                                <Button.Group>
                                    <Button onClick={() => Formatter({ content, setContent })}><AlignLeftOutlined />自动排版</Button>
                                    <Button onClick={() => setVisible({ ...visible, preview: true })}><SnippetsOutlined />预览</Button>
                                </Button.Group>
                            </Col>
                            <Col span={12} key='next' style={{ textAlign: "right" }}>
                                <Button type="primary" onClick={nextPage}>继续</Button>
                            </Col>
                        </Row>
                    </Form>
                    <div id='create' style={{ padding: '1px 0', marginBottom: '8px' }}>
                        <CKEditorInstance content={content} setContent={setContent} />
                    </div>
                    <Row style={{ width: '100%' }}>
                        <Col span={24} key='next' style={{ textAlign: "right" }}>
                            <Button type="primary" onClick={nextPage}>继续</Button>
                        </Col>
                    </Row>
                </div>
            ),
        }, {
            title: '选择百科', // 传递选择媒体数据，搜索表格数据，是否读取完成数据
            content: (
                <>
                    <BaikeTable aciton="CREATE" baikes={baikes} setBaikes={setBaikes} />
                    <Affix offsetBottom={24}>
                        <Card title={<div>
                            <Text>已选择{_.size(baikes)}个媒体</Text>
                            <Divider type='vertical' />
                            <Text>共计{_.sumBy(baikes, 'price')}元</Text>
                        </div>}>
                            {baikes.map(e => {
                                return (
                                    <Tag className="tag-cloud" closable key={e.id} onClose={() => {
                                        setBaikes(baikes.filter(baike => baike.id !== e.id))
                                    }}>
                                        {e.name}
                                    </Tag>
                                )
                            })}
                            <Divider />
                            <Button style={{ margin: '8px 8px 0 0' }} onClick={prevPage}>
                                返回
                            </Button>
                            <Button style={{ margin: '8px 8px 0 0' }} type="primary" onClick={() => {
                                if (_.size(baikes) === 0) { message.error("请选择媒体!"); return; }
                                if (order.title === '') { message.error('请填写标题后提交！'); return }
                                if (order.url === '' && content.trim() === '') { message.error('请填写内容后提交！'); return; }
                                setCurrent(current + 1)
                            }}>
                                继续
                            </Button>
                        </Card>
                    </Affix>
                </>
            ),
        }, {
            title: '确定下单',
            content: (
                <Space direction="vertical">
                    <div style={{ background: '#fff', padding: 24 }}>
                        <Descriptions title="订单信息">
                            <Descriptions.Item label="订单标题" span={3}>{order.title}</Descriptions.Item>
                            <Descriptions.Item label="订单备注" span={3}>{order.remark}</Descriptions.Item>
                            <Descriptions.Item label="订单来源" span={3}>{order.url}</Descriptions.Item>
                            <Descriptions.Item label="总价格" span={3}>{_.sum(baikes.map(e => parseInt(e.price)))}</Descriptions.Item>
                        </Descriptions>
                    </div>

                    <Card title={<div>
                        <Text>已选择{_.size(baikes)}个媒体</Text>
                        <Divider type='vertical' />
                        <Text>共计{_.sumBy(baikes, 'price')}元</Text>
                    </div>}
                        style={{ marginBottom: '16px' }}>
                        {baikes.map(e => {
                            return (
                                <Tag className="tag-cloud" closable key={e.id} onClose={() => {
                                    setBaikes(baikes.filter(baike => baike.id !== e.id))
                                }}>
                                    {e.name}
                                </Tag>
                            )
                        })}
                    </Card>
                    <div style={{ background: '#fff', padding: '24px' }}>
                        <Row>
                            <Col span={12}>
                                <Button onClick={prevPage}>
                                    返回
                                </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <Button type="primary" onClick={sendOrder}>
                                    提交
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Space>

            ),
        }, {
            title: '提交反馈',
            content: <Feedback fetching={fetching} feedback={feedback} resetAll={resetAll} prevPage={prevPage} />,
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}>
                <Steps current={current} size={"small"}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
            </PageHeader>
            <Content>
                <div style={{ margin: '24px' }}>
                    <div className="steps-content">
                        {steps[current].content}
                    </div>
                </div>
            </Content>
        </HelmetProvider>
    )
}

export default CreateBaikeOrder