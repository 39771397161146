import React from 'react';
import { Button, Space } from 'antd';
import { useNavigate } from "react-router-dom";
import { signOut } from '../../auth/SignOut';
import { useAuthUser } from '../../../utils/AuthUser';

const Logged = () => {
    const { resetLoginState } = useAuthUser();

    let navigate = useNavigate();

    const initLoginState = () => {
        signOut()
        resetLoginState()
        navigate("/")
    }


    return (
        <Space>
            <Button type='link' style={{ fontSize: 14 }} onClick={() => navigate("/dashboard")}>控制台</Button>
            <Button danger style={{ fontSize: 14 }} onClick={initLoginState}>登出</Button>
        </Space>
    );
}
export default Logged