import React from 'react'
import { Typography, Space, Tooltip, Button, Tag, message, Radio, Popconfirm } from 'antd';
import _ from 'lodash'
import {
    EditOutlined, StopOutlined,
    ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined,
    PlusOutlined, MinusSquareOutlined,
    StarOutlined, StarTwoTone,
    CaretUpOutlined, CaretDownOutlined, UpSquareOutlined, DownSquareOutlined
} from '@ant-design/icons';
import qs from 'qs';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { userRoleState, userAttacheState } from '../../Apps/atoms/Atoms'
import { resultResponse } from '../../Apps/common/ResultResponse';
import { pageViewMap, availableTimeMap, fansNum, mediaIcons, minute2hour } from '../../entities/MediaProperty';
import { check } from '../../utils/permission/rules'
import { getName } from '../order/orderCommon';

const axios = require('axios');
const { Paragraph, Text } = Typography;

const MediaTableConfig = ({ action, handleMedia, simpleMode, type }) => {
    const role = useRecoilValue(userRoleState)
    const attache = useRecoilValue(userAttacheState)

    const { getMedia, handleStatus, updateProirity, exclude,
        pinMedia, addTags, addIcons, deleteMedia,
        getSaleGroupPrice, reloadTable, changeEditor } = handleMedia;

    let permission = {
        'module:media:redeem': check(role, 'module:media:redeem', null),
        'module:media:level': check(role, 'module:media:level', null),
        'module:media:time:modify': check(role, 'module:media:time:modify', { action }),
        'module:media:status': check(role, 'module:media:status', { action }),
        'module:media:check': check(role, 'module:media:check', null),
        'module:media:weight:refresh': check(role, 'module:media:weight:refresh', null),
        'module:media:edit': check(role, 'module:media:edit', { action }),
        'module:media:delete': check(role, 'module:media:delete', null),
        'module:media:type': check(role, 'module:media:type', null),
        'module:media:weekend': check(role, 'module:media:weekend', null),
        'module:media:weight': check(role, 'module:media:weight', null),
        'module:media:money:cost': check(role, 'module:media:money:cost', { action }),
        'module:media:money:user': check(role, 'module:media:money:user', { action }),
        'module:media:money:sale': check(role, 'module:media:money:sale', null),
        'module:media:money:sale:cost': check(role, 'module:media:money:sale:cost', null),
        'module:media:time_limit': check(role, 'module:media:time_limit', { action }),

    }

    // let isWeekend = ['1', '2'].indexOf(calander[dayjs().format('YYYYMM')][dayjs().format('DD')]) !== -1 // 根据json中的日期来判断是否是休息日
    let isWeekend = false

    // 删除活动 或者 收藏夹中的媒体
    const blockMedia = async (id) => {
        await axios({
            method: 'post',
            url: `/media/block/${id}`
        }).then((res) => {
            resultResponse(res, "已成功")
            reloadTable()
        })
    }

    // 删除活动 或者 收藏夹中的媒体
    const removeMediaFromEvent = async (id) => {
        await axios({
            method: 'delete',
            url: `/eventMedia/del/${id}`
        }).then((res) => {
            resultResponse(res, "已成功删除")
        })
    }

    // 更改媒体在活动/收藏夹中的顺序
    const updateMediaProirity = async (id, action) => {
        await axios({
            method: 'post',
            url: `/eventMedia/priority/${id}`,
            data: qs.stringify({ action })
        }).then((res) => {
            resultResponse(res, '已成功删除')
            reloadTable()
        })
    }

    const nameCol = [
        {
            title: '置顶',
            dataIndex: 'id',
            width: 40,
            checked: 1,
            render: (t, data) => {
                let val = role === 'CUSTOMER' ? 1 : 2
                return data.pinned === 99
                    ? <Tooltip title="置顶媒体">
                        <StarOutlined style={{ fontSize: '1.5em' }} onClick={() => pinMedia(data.id, val)} />
                    </Tooltip>
                    : <Tooltip title="取消置顶">
                        <StarTwoTone style={{ fontSize: '1.5em' }} twoToneColor="#f5222d" onClick={() => pinMedia(data.id, 0)} />
                    </Tooltip>
            }
        }, {
            title: '拉黑',
            dataIndex: 'id',
            width: 40,
            checked: 1,
            render: (t, data) => {
                return <Tooltip title="拉黑媒体">
                    <StopOutlined style={{ fontSize: '1.2em' }} onClick={() => blockMedia(data.id)} />
                </Tooltip>
            }
        }, {
            title: '媒体名称',
            dataIndex: 'name',
            checked: 1,
            render: (text, data) => {
                let media_name = getName(data);
                if (data.media_from === '自媒体') {
                    if (data.auth_type === 1) {
                        media_name = <>
                            {media_name}
                            <img src='https://mbdp03.bdstatic.com/static/superlanding/img/vip-2_b663fc3.png?v=md5' alt="官方认证" />
                        </>
                    } else if (data.auth_type === 2) {
                        media_name = <>
                            {media_name}
                            <img src='https://mbdp03.bdstatic.com/static/superlanding/img/vip-3_3b84e9a.png?v=md5' alt='个人认证' />
                        </>
                    }
                }

                let date = `${dayjs().format("YYYY-MM-DD")} ${data.end_date}`
                let out_of_time = dayjs(date).diff(dayjs(), 'seconds');

                let tagList = _.split(data.media_icons, ",").map(e => mediaIcons.get(e))

                let type = <Radio.Group defaultValue={data.include_type} buttonStyle="solid" size="small"
                    onChange={e => handleStatus(data.id, 'include_type', e.target.value)}
                >
                    <Radio.Button value="A类">A</Radio.Button>
                    <Radio.Button value="B类">B</Radio.Button>
                    <Radio.Button value="C类">C</Radio.Button>
                    <Radio.Button value="D类">D</Radio.Button>
                    <Radio.Button value="E类">E</Radio.Button>
                    <Radio.Button value="Z类">Z</Radio.Button>
                </Radio.Group>

                return (
                    <Space direction='vertical' size={2}>
                        {permission['module:media:type'] && type}
                        <div>
                            {data.media_icons && tagList}
                            {data.new_media === 1 && <Tag color="green" key="new">新增</Tag>}
                            {
                                (Boolean(data.closed) || out_of_time < 0 || (isWeekend && data.week_available === '周末休息')) &&
                                <Tag color="red" key="close">已截稿</Tag>
                            }
                            {
                                _.size(data.event_ids) > 0 &&
                                <Tooltip title={data.events} key="event">
                                    <Tag color="red">活动中</Tag>
                                </Tooltip>
                            }
                        </div>
                        <Text>{media_name}</Text>
                        <Space>
                            <a href={data.sample_url} target='_blank' rel='noopener noreferrer'>查看案例</a>
                            {
                                attache === 0 &&
                                <Text type="secondary" style={{ fontSize: '10px' }}>id: {data.id}</Text>
                            }
                        </Space>
                    </Space >
                )
            }
        }
    ]

    const priceCol = {
        title: '价格',
        dataIndex: 'sale_price',
        checked: 1,
        render: (t, data) => {
            let price;
            if (data.price_update !== null) {
                let price_status = data.modify * data.price_update;
                if (price_status === 1) {
                    price = <Tooltip title="最近涨价" key="price-up">
                        <CaretUpOutlined style={{ color: "#f5222d" }} />
                    </Tooltip>
                } else if (price_status === -1) {
                    price = <Tooltip title="最近降价" key="price-down">
                        <CaretDownOutlined style={{ color: "rgb(135, 208, 104)" }} />
                    </Tooltip>
                }
            }

            return <Space>
                <Space direction='vertical' size={2}>
                    {permission['module:media:money:cost'] && <Text>成本：{data.cost}</Text>}
                    {permission['module:media:money:sale:cost'] && <Text>销售：{data.sale_price}</Text>}
                    {permission['module:media:money:sale'] && <Text>成本：{data.sale_price}</Text>}
                    {permission['module:media:money:user'] && <Text>价格：{data.user_price}</Text>}
                </Space>
                {price}
            </Space>
        }
    }

    const mediaIconsCol = [
        {
            title: '标签',
            dataIndex: 'tags',
            width: 120,
            checked: 1,
            render: (text, data) => {
                let addButton = <Button type='link' size='small' icon={<PlusOutlined />} onClick={e => addTags(data.id)} disabled={_.size(text) >= 5}>标签</Button>
                let addIcon = <Button type='link' size='small' icon={<PlusOutlined />} onClick={e => addIcons(data.id)} >标识</Button>
                let tagList = text?.map(e => <Tag className="table-tags" color='blue'>{e.tag}</Tag>)
                return <div>
                    <div>
                        {addButton}
                        {addIcon}
                    </div>
                    {_.size(text) > 0 && tagList}
                </div>
            }
        }
    ]

    const mediaDetailCol = [
        {
            title: '频道类型',
            dataIndex: 'channel_type',
            checked: 1,
            width: 80
        }, {
            title: '收录效果',
            dataIndex: 'effect',
            checked: 1,
            width: 90
        },
    ]

    const mcnDetailCol = [
        {
            title: '媒体时效',
            dataIndex: 'available_time',
            checked: 1,
            render: (text) => <Text strong>{availableTimeMap.get(text)}</Text>
        }, {
            title: '粉丝数',
            dataIndex: 'fans_num',
            checked: 1,
            render: (text) => <Text strong>{fansNum.get(text)}</Text>
        }, {
            title: '阅读量',
            dataIndex: 'page_views',
            checked: 1,
            render: (text) => <Text strong>{pageViewMap.get(text)}</Text>
        },
    ]


    const publishCol = [
        {
            title: '截稿时间',
            dataIndex: 'end_date',
            checked: 1,
            width: 90,
            render: (text, data) => {
                let week_available = data.week_available === '周末休息'
                    ? <Tag color="blue"
                        onClick={() => permission['module:media:weekend'] && handleStatus(data.id, 'week_available', '周末可发')} >
                        周末休息
                    </Tag >
                    : <Tag color="#1677ff"
                        onClick={() => permission['module:media:weekend'] && handleStatus(data.id, 'week_available', '周末休息')}>
                        周末可发
                    </Tag >
                return <Space direction='vertical' size={2}>
                    <Text strong editable={
                        permission['module:media:time:modify']
                            ? {
                                onChange: e => {
                                    let reg = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/
                                    if (reg.test(e)) {
                                        handleStatus(data.id, 'end_date', e)
                                    } else {
                                        message.warn("时间格式错误")
                                    }
                                }
                            }
                            : false}>{text}</Text>
                    <Text>{week_available}</Text>
                </Space>
            }
        }, {
            title: "发布率",
            dataIndex: 'publish_rate',
            width: 80,
            checked: 1,
            render: (text, data) => {
                return <Text strong>{text === null ? 0 : text}%</Text>
            }
        }, {
            title: "发布详情",
            dataIndex: 'publish_time',
            width: 90,
            checked: 1,
            render: (text, data) => {
                return minute2hour(data.publish_time)
            }
        }, {
            title: "媒体时效",
            dataIndex: 'time_limit',
            checked: 1,
            render: (val, data) => {
                return <Space direction='vertical'>
                    <Text editable={
                        permission['module:media:time_limit']
                            ? { onChange: e => (e && Number(e) >= 0) && handleStatus(data.id, 'time_limit', Number(e)) }
                            : false
                    }>{val}天</Text>
                </Space>
            }
        },
    ]

    const mediaCol = _.concat(type === '自媒体' ? mcnDetailCol : mediaDetailCol, publishCol)

    const cerityCol = [
        {
            title: '认证',
            dataIndex: 'certified',
            width: 72,
            checked: 1,
            render: (val, data) => {
                return <Space direction='vertical' size='small'>
                    {val === 1
                        ? <Tag className="tag-center" onClick={() => handleStatus(data.id, 'certified', 2)} color="#87d068">已认证</Tag>
                        : <Tag className="tag-center" onClick={() => handleStatus(data.id, 'certified', 1)} color="green">未认证</Tag>}
                </Space>
            }
        }, {
            title: '媒体认证',
            dataIndex: 'auth_type',
            checked: 1,
            render: (t, data) => {
                let tag = ""
                switch (t) {
                    case 1:
                        tag = <Tag color='red'>官方认证</Tag>
                        break;
                    case 2:
                        tag = <Tag color='orange'>个人认证</Tag>
                        break;
                    case 3:
                        tag = <Tag color='blue'>未认证</Tag>
                        break;
                    default:
                        tag = <Tag color='blue'>未认证</Tag>
                        break;
                }
                return <Space direction='vertical'>
                    <Radio.Group value={data.auth_type} size="small"
                        onChange={e => handleStatus(data.id, 'auth_type', e.target.value)}
                    >
                        <Radio.Button value={1}>官方</Radio.Button>
                        <Radio.Button value={2}>个人</Radio.Button>
                        <Radio.Button value={3}>未认证</Radio.Button>
                    </Radio.Group>
                    {tag}
                </Space>
            }
        },
    ]

    const mediaLevelCol = {
        title: <Tooltip title="用户达到指定等级后, 每次成功发布该媒体, 便可获得返现金">发稿返现</Tooltip>,
        dataIndex: 'back_coins',
        width: 100,
        checked: 1,
        render: (text, data) => {
            return <Space>
                <Text>返现金额</Text>
                <Text editable={
                    permission['module:media:redeem']
                        ? { onChange: e => (e && Number(e) >= 0) && handleStatus(data.id, 'back_coins', Number(e)) }
                        : false
                }>{data.back_coins}</Text>
            </Space>
        }
    }

    const mediaCheckCol = {
        title: '审核',
        dataIndex: 'checked',
        checked: 1,
        render: (text, data) => {
            let checkCol = data.checked === 1 ? <Tag color="#87d068">已通过</Tag> : <Tag color="#f50">待审核</Tag>
            let status = <Radio.Group value={data.status} size="small"
                onChange={e => handleStatus(data.id, 'status', e.target.value)}
            >
                <Radio.Button value={1}>正常</Radio.Button>
                <Radio.Button value={2}>暂停</Radio.Button>
            </Radio.Group>

            let check = <Radio.Group value={data.checked} size="small"
                onChange={e => handleStatus(data.id, 'checked', e.target.value)}
            >
                <Radio.Button value={1}>通过</Radio.Button>
                <Radio.Button value={2}>待审</Radio.Button>
            </Radio.Group>
            return <Space direction='vertical'>
                {permission['module:media:status'] && status}
                {permission['module:media:check'] ? check : checkCol}
            </Space>
        }
    }

    const mediaEditorCol = {
        title: '编辑',
        dataIndex: 'editor_name',
        checked: 1,
        render: (t, data) => {
            return <Space direction='vertical' size='small'>
                <span>
                    <Text>{data.creator_name}</Text>
                    <Button type='link' size='small' icon={<MinusSquareOutlined />} onClick={() => exclude(data.creator_id)} />
                    <Button type='link' size='small' icon={<EditOutlined />} onClick={() => changeEditor(data.id)} />
                </span>
                <Text editable={{ onChange: e => _.trim(e) !== data.editor_name && handleStatus(data.id, 'editor_name', _.trim(e)) }}>{data.editor_name}</Text>
                <Text>{data.editor_tel}</Text>
            </Space>
        }
    }

    const mediaRemarkCol = {
        title: '备注',
        dataIndex: 'comment',
        width: '18%',
        checked: 1,
        render: (text) => (
            <Paragraph ellipsis={{ rows: 3, expandable: true }} style={{ margin: 0 }}>
                {text}
            </Paragraph>
        )
    }

    const modifyMediaCol = {
        title: '操作',
        dataIndex: 'id',
        width: 100,
        checked: 1,
        render: (text, data) => {
            let type = data.media_from === '自媒体' ? 'MCN' : ''
            let editMedia = <Button type="link" icon={<EditOutlined />} size='small' onClick={() => getMedia(data.id, type)} />
            let delMedia = <Popconfirm
                title="是否确认删除媒体"
                onConfirm={() => deleteMedia(data.id)}
                onCancel={() => { console.log('取消') }}
                okText="确认"
                cancelText="取消"
            >
                <Button type="link" icon={<DeleteOutlined />} size='small' />
            </Popconfirm>
            return <Space size='small'>
                {permission['module:media:edit'] && editMedia}
                {permission['module:media:delete'] && delMedia}
            </Space>
        }
    }

    const modifyEventCol = [
        {
            title: '优先级',
            dataIndex: 'priority',
            checked: 1,
            render: (t, data) => {
                return (
                    <div>
                        <Text>优先级: {t}</Text>
                        <br />
                        <Button type="link" icon={<UpSquareOutlined />} onClick={() => {
                            updateMediaProirity(data.event_media_id, 'add')
                            reloadTable()
                        }}></Button>
                        <Button type="link" icon={<DownSquareOutlined />} onClick={() => {
                            updateMediaProirity(data.event_media_id, 'minus')
                            reloadTable()
                        }
                        }></Button>
                    </div>
                )
            }
        }, {
            title: '活动操作',
            dataIndex: 'id',
            checked: 1,
            render: (text, data) => (
                <Space>
                    <Button type="link" icon={<DeleteOutlined />} size='small' onClick={() => {
                        removeMediaFromEvent(data.event_media_id)
                        reloadTable()
                    }} />
                </Space>

            )
        }
    ]

    const mediaOrderCol = {
        title: '排序管理',
        dataIndex: 'id',
        checked: 1,
        render: (text, data) => {
            return permission['module:media:weight'] &&
                <Space direction='vertical' size='small'>
                    <span style={{ display: 'flex' }}>
                        <Text>权重:</Text>
                        <Text editable={{
                            onChange: e => (e && Number(e) >= 0) && handleStatus(data.id, 'order_weight', Number(e))
                        }}>{data.order_weight}</Text>
                    </span>
                    <Button.Group>
                        <Button size='small' type='link' icon={<ArrowUpOutlined />} onClick={() => updateProirity(data.id, 'add')}></Button>
                        <Button size='small' type='link' icon={<ArrowDownOutlined />} onClick={() => updateProirity(data.id, 'minus')}></Button>
                    </Button.Group>
                </Space>
        }
    }


    const updateSaleGroupPriceCol = {
        title: '媒体价格',
        dataIndex: 'id',
        width: 144,
        checked: 1,
        render: (text, data) => {
            return <Space>
                <Button type="link" size='small' onClick={() => getSaleGroupPrice(data.id, 3)}>客户价格分配</Button>
                <Button type="link" size='small' onClick={() => getSaleGroupPrice(data.id, 9)}>会员价格分配</Button>
            </Space>
        }
    }

    let result = []

    switch (role) {
        case "CUSTOMER":
            if (simpleMode) {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                )
            } else {
                if (action === 'EDIT') {
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaCheckCol,
                        mediaRemarkCol,
                        modifyMediaCol,
                    )
                } else {
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaLevelCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                }
            }
            if (action === 'event') {
                result = _.concat(
                    nameCol,
                    priceCol,
                    modifyEventCol
                )
            }
            break;
        case "OPERATOR":
        case "OPERATOR_LEADER":
            if (simpleMode) {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                )
            } else {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaIconsCol,
                    mediaCol,
                    cerityCol,
                    mediaLevelCol,
                    mediaCheckCol,
                    mediaEditorCol,
                    mediaRemarkCol,
                    mediaOrderCol,
                    modifyMediaCol,
                )
            }
            break;
        case "SALE_LEADER":
            result = _.concat(
                nameCol,
                priceCol,
                mediaCol,
                mediaRemarkCol,
            )
            break;
        case "SALE_ATTACHE":
            if (action === 'EDIT') {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaCheckCol,
                    mediaRemarkCol
                )
            } else {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaRemarkCol
                )
            }
            break;
        case "FRANCHISE":
            if (simpleMode) {
                result = _.concat(
                    nameCol,
                    priceCol,
                )
                if (action === 'event') {
                    result = _.concat(
                        result,
                        modifyEventCol
                    )
                }
            } else {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaRemarkCol,
                    updateSaleGroupPriceCol
                )
            }
            break;
        case "PUBLIC":
            result = _.concat(
                nameCol,
                priceCol,
                mediaCol,
                mediaRemarkCol,
            )
            break;
        default:
            result = _.concat(
                nameCol,
                priceCol,
                mediaCol,
                mediaRemarkCol,
            )
            break;
    }
    return result
}
export default MediaTableConfig;
