import React from 'react';
import { Typography, Space, Card } from 'antd';
import { useSetRecoilState } from 'recoil';

import { platformState, platformChange } from '../atoms/Atoms'
import { Platform } from '../common/CONSTANTS';
const { Title, Paragraph, Text } = Typography;


const HeaderNav = ({ footer = false }) => {
    const textStyle = {
        color: 'white',
        padding: '4px 8px',
        borderRadius: 4,
        fontSize: 14,
    }

    const setPlatform = useSetRecoilState(platformState)
    const setPlatformLoading = useSetRecoilState(platformChange)

    let millisecondsToWait = 500;

    return <div style={{ padding: 12 }}>
        <Space size={24}>
            {
                !footer &&
                <div>
                    <Title level={2}>一站式整合平台</Title>
                    <Paragraph style={{ width: 260 }}>
                        我们致力于打造一站式解决客户推广需求的平台，全方位洞察媒体生态，发掘潜力资源，持续传播动态；打通公众号链路，有效助力企业形象打造。
                    </Paragraph>
                </div>
            }
            <Space direction='vertical'>
                <Text type='secondary' style={{ fontSize: 14 }}>更多资源</Text>
                <Space size={12}>
                    <Card title={null} hoverable style={{ width: 200 }}
                        onClick={() => {
                            // history.push('/dashboard/media-center')
                            setPlatform(Platform.media.id)
                            setPlatformLoading(true)
                            setTimeout(function () {
                                setPlatformLoading(false)
                            }, millisecondsToWait);
                        }}>
                        <Space direction='vertical' size={8} style={{ padding: '0 12px' }}>
                            <Text strong style={{ fontSize: 16 }}>媒体发布平台</Text>
                            <Text type='secondary'>树立品牌形象</Text>
                            <Text style={{
                                ...textStyle,
                                background: 'linear-gradient(270deg, rgb(255, 155, 171), rgb(202, 76, 113))',
                            }}>平台入口</Text>
                        </Space>
                    </Card>
                    <Card title={null} hoverable style={{ width: 200 }}
                        onClick={() => {
                            // history.push('/dashboard/wechat')
                            setPlatform(Platform.wechat.id)
                            setPlatformLoading(true)
                            setTimeout(function () {
                                setPlatformLoading(false)
                            }, millisecondsToWait);
                        }} >
                        <Space direction='vertical' size={8} style={{ padding: '0 12px' }}>
                            <Text strong style={{ fontSize: 16 }}>微信发布平台</Text>
                            <Text type='secondary'>社群裂变</Text>
                            <Text style={{
                                ...textStyle,
                                background: 'linear-gradient(90deg, rgb(255, 80, 80) 0%, rgb(255, 116, 47) 100%)',
                            }}>平台入口</Text>
                        </Space>
                    </Card>
                    <Card title={null} hoverable style={{ width: 200 }}
                        onClick={() => {
                            // history.push('/dashboard/weibo')
                            setPlatform(Platform.weibo.id)
                            setPlatformLoading(true)
                            setTimeout(function () {
                                setPlatformLoading(false)
                            }, millisecondsToWait);
                        }}>
                        <Space direction='vertical' size={8} style={{ padding: '0 12px' }}>
                            <Text strong style={{ fontSize: 16 }}>微博发布平台</Text>
                            <Text type='secondary'>社群裂变</Text>
                            <Text style={{
                                ...textStyle,
                                background: 'linear-gradient(270deg, rgb(231, 69, 93) 0%, rgb(235, 79, 219) 100%)',
                            }}>平台入口</Text>
                        </Space>
                    </Card>
                    <Card title={null} hoverable style={{ width: 200 }}
                        onClick={() => {
                            // history.push('/dashboard/wechat')
                            setPlatform(Platform.xiaohongshu.id)
                            setPlatformLoading(true)
                            setTimeout(function () {
                                setPlatformLoading(false)
                            }, millisecondsToWait);
                        }} >
                        <Space direction='vertical' size={8} style={{ padding: '0 12px' }}>
                            <Text strong style={{ fontSize: 16 }}>小红书发布平台</Text>
                            <Text type='secondary'>种草</Text>
                            <Text style={{
                                ...textStyle,
                                background: 'linear-gradient(90deg, rgb(255, 80, 80) 0%, rgb(255, 116, 47) 100%)',
                            }}>平台入口</Text>
                        </Space>
                    </Card>
                    <Card title={null} hoverable style={{ width: 200 }}
                        onClick={() => {
                            // history.push('/dashboard/weibo')
                            setPlatform(Platform.douyin.id)
                            setPlatformLoading(true)
                            setTimeout(function () {
                                setPlatformLoading(false)
                            }, millisecondsToWait);
                        }}>
                        <Space direction='vertical' size={8} style={{ padding: '0 12px' }}>
                            <Text strong style={{ fontSize: 16 }}>抖音发布平台</Text>
                            <Text type='secondary'>社群裂变</Text>
                            <Text style={{
                                ...textStyle,
                                background: 'linear-gradient(270deg, rgb(231, 69, 93) 0%, rgb(235, 79, 219) 100%)',
                            }}>平台入口</Text>
                        </Space>
                    </Card>
                </Space>
            </Space>
        </Space>
    </div>
}

export default HeaderNav