import React from "react"
import Agent from "../home/Agent"
import AgentSolution from "../home/solution/AgentSolution"
import EnterpriseSolution from "../home/solution/EnterpriseSolution"
import BaikeInfo from "../home/business/BaikeInfo"
import MarketingInfo from "../home/business/MarketingInfo"
import HomePriceList from "../home/resource/HomePriceList"

const HomeRoutesConfig = [
    {
        path: '/solution/agent',
        component: <AgentSolution />
    }, {
        path: '/solution/enterprise',
        component: <EnterpriseSolution />
    },
    {
        path: '/alliance',
        component: <Agent />
    },
    {
        path: '/info/baike',
        component: <BaikeInfo />
    },
    {
        path: '/info/marketing',
        component: <MarketingInfo />
    },
    {
        path: '/price',
        component: <HomePriceList />
    }
]

export default HomeRoutesConfig