import React from 'react'
import _ from 'lodash'
import { Button, Tag, Typography, Popconfirm, Switch, message } from 'antd'
import dayjs from 'dayjs';
import qs from 'qs';
import { DeleteOutlined, SettingOutlined, UnorderedListOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const axios = require('axios');

const { Text } = Typography
const CouponTableConfig = ({ handleMedia, miniMode }) => {
    const role = useRecoilValue(userRoleState)

    const { deleteCoupon, selectMedias, checkDetail, buyCoupon } = handleMedia
    const size = 'small'
    const dateFormat = `YYYY.MM.DD`

    const setDailyCoupon = async (id, e) => {
        let val = e ? 1 : 0
        await axios({
            method: 'post',
            url: `/coupon/edit`,
            data: qs.stringify({
                id,
                is_daily: val
            })
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功修改")
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const couponCol = [
        {
            title: '名称',
            dataIndex: 'coupon_name',
            render: (t, data) => {
                if (data.total === 0) {
                    return <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text>{t}</Text>
                        <Text type="warning" style={{ fontSize: '12px' }}>当前优惠券尚未关联媒体</Text>
                    </div>
                } else {
                    return t
                }
            }
        }, {
            title: '描述',
            dataIndex: 'coupon_description',
        }, {
            title: '抵用金额',
            dataIndex: 'money',
        }, {
            title: '售价',
            dataIndex: 'price',
            render: (t, data) => {
                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text style={{ marginBottom: '8px' }}>普通用户: {data.price} 元</Text>
                    <Text type="warning">会员用户: {data.price_vip} 元</Text>
                </div>
            }
        }, {
            title: '会员限购',
            dataIndex: 'max_num_vip',
            render: (t, data) => {
                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text style={{ marginBottom: '8px' }}>普通用户: 1份</Text>
                    <Text type="warning">会员用户: {t}份</Text>
                </div>
            }
        }, {
            title: '时效',
            dataIndex: 'end_time',
            render: (t, data) => {
                let start_time = dayjs(data.create_time).format(dateFormat)
                let end_time = dayjs(t).subtract(1, 'days').format(dateFormat)
                return <>
                    {`${start_time} - ${end_time}`}
                </>
            }
        }, {
            title: '状态',
            dataIndex: 'id',
            render: (t, data) => {
                var d = dayjs(data.end_time).diff(dayjs(), 'days');
                var e = dayjs(data.end_time).diff(dayjs(), 'hours');
                if (d >= 0 && e > 0) {
                    return < Tag color='green'> 未过期</Tag >
                } else {
                    return < Tag color='red'> 已过期</Tag >
                }
            }
        },
    ]

    const handleCol = [
        {
            title: '日常刷新',
            dataIndex: 'is_daily',
            render: (t, data) => {
                if (t === 1) {
                    return <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked onChange={e => setDailyCoupon(data.id, e)} />
                } else {
                    return <Switch checkedChildren="开启" unCheckedChildren="关闭" onChange={e => setDailyCoupon(data.id, e)} />
                }
            }
        }, {
            title: '操作',
            dataIndex: 'id',
            render: (t, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text code style={{ marginBottom: '8px' }}>媒体数量: {data.total}</Text>
                        <Button.Group>
                            {!miniMode &&
                                <>
                                    <Popconfirm
                                        title="是否确认删除"
                                        onConfirm={() => deleteCoupon(data.id)}
                                        onCancel={() => { console.log('取消') }}
                                        okText="确认"
                                        cancelText="取消"
                                    >
                                        <Button size={size}><DeleteOutlined />删除</Button>
                                    </Popconfirm>
                                    <Button size={size} onClick={() => selectMedias(data.id)}><SettingOutlined />关联媒体</Button>
                                </>
                            }
                            <Button size={size} onClick={() => checkDetail(data.id)}><UnorderedListOutlined />查看</Button>
                        </Button.Group>
                    </div>
                )
            }
        },
    ]

    const userHandleCol = [
        {
            title: '操作',
            dataIndex: 'id',
            render: (t, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text code style={{ marginBottom: '8px' }}>媒体数量: {data.total}</Text>
                        <Button.Group>
                            <Button size={size} onClick={() => buyCoupon(data.id)}><ShoppingCartOutlined />购买</Button>
                            <Button size={size} onClick={() => checkDetail(data.id)}><UnorderedListOutlined />查看</Button>
                        </Button.Group>
                    </div>
                )
            }
        },
    ]

    let result = []
    switch (role) {
        case 'CUSTOMER':
            result = _.concat(
                couponCol,
                userHandleCol
            )
            break;
        case 'OPERATOR':
            result = _.concat(
                couponCol,
                handleCol
            )
            break;
        default:
            result = _.concat(
                couponCol,
            )
            break;
    }
    return result
}
export default CouponTableConfig;
