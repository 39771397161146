import React, { useState, useEffect } from 'react';
import { Table, Modal, Tabs, Input, Layout, Typography, Spin } from 'antd';
import _ from 'lodash'
import qs from 'qs';
import { useRecoilValue } from 'recoil';

import { userRoleState, userState } from '../../atoms/Atoms'
import { QaOrderTableConfig } from '../../../role/qa/QaOrderTableConfig'
import { initFormData, tablePagination } from '../../common/tableCommon';
import ResponseArticle from './ResponseArticle'
import { resultResponse } from '../../common/ResultResponse';
import CKEditorInstance from '../../../utils/CkeditorInstance';

const axios = require('axios');

const { Content } = Layout;
const { Text, Paragraph } = Typography;

const { TextArea } = Input;

const initialStatus = {
    qa_status: '',
    status: '',
}
const initialModal = {
    target_id: undefined,
    verifyFailed: false,
    publishSuccess: false,
    publishOrderFailed: false,
    updateUrl: false,
    response: false,
    unconfirm: false,
    data: {},
    order: {}
}

const QAOrderTable = () => {

    const sessionUser = useRecoilValue(userState)
    const role = useRecoilValue(userRoleState)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState('1')
    const [pagination, setPagination] = useState(tablePagination)
    const [search, setSearch] = useState({ start_time: '', end_time: '', })
    const [status, setStatus] = useState(initialStatus)
    const [modal, setModal] = useState(initialModal)
    const [rowSelected, setRowSelected] = useState({ keys: [], rows: [] })
    const [content, setContent] = useState("")
    const [count, setCount] = useState(0)

    const reloadTable = () => setCount(count + 1)
    const resetModal = () => { setModal(initialModal); setContent("") } // 重置弹出框 以及 文本框

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async () => {
        let params = new FormData()
        setLoading(true)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        _.toPairs(search).filter(e => e[1] !== '').map(e => params.append(`${e[0]}`, e[1]))
        _.toPairs(status).filter(e => e[1] !== '').map(e => params.append(`${e[0]}`, e[1]))
        await axios({
            method: 'post',
            url: '/qa/order/myList',
            data: params
        }, {
            cancelToken: source.token
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
            let total = res.data.total
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    // 订单审核通过
    const verifyOrderSuccess = async (id) => {
        await axios({
            method: 'post',
            url: "/qa/order/verify",
            data: qs.stringify({
                ids: id === undefined ? _.join(rowSelected.keys, ',') : id,
                status: "waitPublish"
            })
        }).then(res => {
            resultResponse(res, "已通过审核")
            reloadTable()
        })
    }

    // 订单审核不通过
    const verifyOrderFailed = async (id, info) => {
        await axios({
            method: 'post',
            url: "/qa/order/verify",
            data: qs.stringify({
                ids: id === undefined ? _.join(rowSelected.keys, ',') : id,
                status: "notPass",
                reason: info
            })
        }).then(res => {
            resultResponse(res, "未通过审核")
            reloadTable()
        })
    }
    // 订单发布回执
    const publishOrderSuccess = async (id, info) => {
        await axios({
            method: 'post',
            url: "/qa/order/publish",
            data: qs.stringify({
                order_ids: id,
                status: "success",
                reason: info
            })
        }).then(res => {
            resultResponse(res, '已发布回执')
            reloadTable()
        })
    }
    // 订单退稿回执
    const publishOrderFailed = async (id, info) => {
        await axios({
            method: 'post',
            url: "/qa/order/publish",
            data: qs.stringify({
                order_ids: id,
                status: "back",
                reason: info
            })
        }).then(res => {
            resultResponse(res, '已退稿')
            reloadTable()
        })
    }

    // 订单操作函数
    const handleOrders = {
        verifyOrderSuccess,
        reloadTable
    }

    const rowSelection = {
        selectedRowKeys: rowSelected.keys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowSelected({ keys: selectedRowKeys, rows: selectedRows })
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
        selections: rowSelected.rows
    };

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [pagination.current, pagination.pageSize, status, count])


    const filterMediaStatus = (e) => {
        setTab(e)
        setRowSelected({ keys: [], rows: [] })
        switch (e) {
            case '2':
                return setStatus({ status: 'waitVerify', qa_status: 'waitPublish' })
            case '3':
                return setStatus({ status: 'unconfirmed', qa_status: 'waitPublish' })
            case '4':
                return setStatus({ status: 'waitPublish', qa_status: 'waitPublish' })
            case '5':
                return setStatus({ status: 'complete', qa_status: 'success' })
            case '6':
                return setStatus({ status: '', qa_status: 'back' })
            default:
                return setStatus({ status: '', qa_status: '' })
        }
    }

    // const searchDatas = qaOrderSearchConfig({ role, start_time: search.start_time, end_time: search.end_time })
    const columns = QaOrderTableConfig({ modal, setModal, handleOrders })

    return <>
        <Content className='table-search'>
            {/* {searchFormDisplay && <div>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={searchDatas.map(e => {
                        return {
                            [e.name]: e.value || ""
                        }
                    })}
                    onFinish={(val) => {
                        let dateDatas = searchDatas.filter(e => e.type === 'datepicker')
                        _.forEach(dateDatas, data => {
                            _.forEach(val, (v, k) => {
                                if (!_.isNil(v) && !_.isEmpty(v)) {
                                    if (k === data.name) {
                                        val = {
                                            ...val,
                                            [k]: dayjs(v.$d).format('YYYY-MM-DD')
                                        }
                                    }
                                }
                            })
                        })
                        setSearch(_.assignInWith(search, val, customizer))
                        fetchData()
                    }}
                    onFinishFailed={errorInfo => {
                        console.log('Failed:', errorInfo);
                    }}
                >
                    <SearchForm search={search} setSearch={setSearch} searchDatas={searchDatas} />
                    <Row>
                        <Col span={12} >
                            {(role === 'OPERATOR' || action === 'EDIT') && <>
                                {tab === '2' &&
                                    <Button.Group style={buttonStyle}>
                                        <Button type="primary" onClick={() => { verifyOrderSuccess(modal.target_id) }}>批量通过</Button>
                                        <Button danger style={{ margin: '0 8px 0 0' }}
                                            onClick={() => { setModal({ ...modal, verifyFailed: true }) }}>批量不通过</Button>
                                    </Button.Group>}
                                {tab === '3' && <Button danger style={buttonStyle}>批量退稿</Button>}
                                {(tab === '4' && sessionUser.role.attache === 0) && <Button style={buttonStyle}>批量结款</Button>}
                            </>}
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button.Group style={buttonStyle}>
                                <Button onClick={() => refreshTableByDays(3)}>3天内</Button>
                                <Button onClick={() => refreshTableByDays(7)}>7天内</Button>
                                <Button onClick={() => refreshTableByDays(30)}>30天内</Button>
                            </Button.Group>
                            <Button type="primary" htmlType="submit" style={{ margin: "0 0 0 8px" }}>
                                <SearchOutlined /> 搜索
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>} */}
        </Content>
        <Content className='table-search'>
            <Tabs defaultActiveKey="1" onChange={filterMediaStatus}
                items={[
                    { key: '1', label: `全部稿件`, },
                    { key: '2', label: `待审核`, },
                    { key: '3', label: `待确认`, },
                    { key: '4', label: `待发布`, },
                    { key: '5', label: `已完成`, },
                    { key: '6', label: `已退稿`, },
                ]}
            />
            <Table
                size="middle"
                columns={columns}
                rowSelection={role === 'OPERATOR' ? rowSelection : undefined}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => { setPagination(pagination) }}
                scroll={{ x: 1500 }}
                footer={() => {
                    switch (role) {
                        case 'CUSTOMER':
                            return <Paragraph>
                                当前页面共<Text strong>{_.size(data)}</Text>条订单, 共计<Text strong>{_.sumBy(data, 'paid_money')}</Text>元
                            </Paragraph>
                        case 'OPERATOR':
                            if (sessionUser.role.attache === 0) {
                                return <Paragraph>
                                    当前页面共<Text strong>{_.size(data)}</Text>条订单, 成本共计<Text strong>{_.sumBy(data, 'cost_money')}</Text>元, 客户付款共计<Text strong>{_.sumBy(data, 'paid_money')}</Text>元
                                </Paragraph>
                            } else {
                                return <Paragraph>
                                    当前页面共<Text strong>{_.size(data)}</Text>条订单, 共计<Text strong>{_.sumBy(data, 'cost_money')}</Text>元
                                </Paragraph>
                            }

                        case 'SALE':
                            return <Paragraph>
                                当前页面共<Text strong>{_.size(data)}</Text>条订单, 共计<Text strong>{_.sumBy(data, 'paid_money')}</Text>元
                            </Paragraph>
                        default:
                            return <Paragraph>
                                当前页面共<Text strong>{_.size(data)}</Text>条订单, 共计<Text strong>{_.sumBy(data, 'paid_money')}</Text>元
                            </Paragraph>
                    }
                }}
            />
        </Content>

        <Modal title="拒稿理由" open={modal.verifyFailed}
            onOk={() => {
                verifyOrderFailed(modal.target_id, content)
                resetModal()
            }}
            onCancel={() => { resetModal() }}>
            <TextArea rows={4} value={content} onChange={e => setContent(e.target.value)} />
        </Modal>
        <Modal title="填写回执" width={960}
            open={modal.publishSuccess}
            onOk={() => {
                publishOrderSuccess(modal.target_id, content)
                resetModal()
            }}
            onCancel={() => { resetModal() }}>
            <div id='create' style={{ padding: '1px 0', marginBottom: '8px' }}>
                <CKEditorInstance content={content} setContent={setContent} />
            </div>
        </Modal>

        <Modal title="退稿理由" open={modal.publishOrderFailed}
            onOk={() => {
                publishOrderFailed(modal.target_id, content)
                resetModal()
            }}
            onCancel={() => { resetModal() }}>
            <TextArea rows={4} value={content} onChange={e => setContent(e.target.value)} />
        </Modal>
        <Modal title="查看回执"
            style={{ top: 20 }}
            open={modal.response}
            width={1170}
            onOk={() => { resetModal() }}
            onCancel={() => { resetModal() }}>
            <ResponseArticle order={modal.data} />
        </Modal>

    </>;
}


export default QAOrderTable
