import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const BaikeDatasConfig = () => {
    const role = useRecoilValue(userRoleState)

    const baikeBasicDatas = [
        {
            name: 'name',
            label: '百科名称',
            required: true,
            placeholder: '请输入百科名称',
            type: 'input'
        }, {
            name: 'sample_url',
            label: '案例地址',
            required: true,
            placeholder: '请输入案例地址',
            type: 'input'
        },
    ]

    const baikePriceDatas = [
        {
            name: 'cost',
            label: '百科价格',
            placeholder: '请输入百科价格',
            type: 'inputnumber'
        }, {
            name: 'sale_price',
            label: '相对成本',
            placeholder: '请输入相对成本',
            type: 'inputnumber'
        }, {
            name: 'agent_price',
            label: '代理端价格',
            placeholder: '请输入代理端价格',
            type: 'inputnumber'
        },
    ]

    const baikePropsDatas = [
        {
            name: 'category',
            label: '百科类别',
            placeholder: '请输入百科类别',
            type: 'radio',
            children: [
                ['企业百科', '企业百科'],
                ['品牌百科', '品牌百科'],
                ['人物百科', '人物百科'],
                ['网站百科', '网站百科'],
                ['产品百科', '产品百科'],
                ['软件APP', '软件APP'],
            ]
        },
        {
            name: 'platform',
            label: '百科平台',
            type: 'radio',
            children: [
                ['百度', '百度'],
                ['搜狗', '搜狗'],
                ['360', '360'],
                ['互动', '互动'],
            ]
        },
        {
            name: 'cycle',
            label: '执行周期',
            placeholder: '请输入执行周期',
            type: 'inputnumber',
        },
    ]
    const commentDatas = [
        {
            name: 'comment',
            label: '备注',
            placeholder: '请输入备注',
            type: 'area'
        },
    ]

    let result = []

    switch (role) {
        case "OPERATOR":
            result = [
                baikeBasicDatas,
                baikePriceDatas,
                baikePropsDatas,
                commentDatas
            ]
            break;
        default:
            break;
    }

    return result
}

export default BaikeDatasConfig