import { Button, Col, Form, Row, Table, Space, Typography, Popconfirm, Tag, Card, Progress, Statistic } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import SearchForm from '../../common/SearchForm';
import { initFormData, tablePagination } from '../../common/tableCommon';
import { formatTime } from '../../../utils/TimeUtils'
import { check } from '../../../utils/permission/rules';
import { resultResponse } from '../../common/ResultResponse';
import { number2currency, numberFixed } from '../../../utils/NumberUtils';


const { Text } = Typography;

const layout = { layout: 'vertical' };
const axios = require('axios');

const UserLoanTable = () => {
    const role = useRecoilValue(userRoleState)
    const permission = {
        'module:user:loan:reset': check(role, 'module:user:loan:reset', null),
    }

    const initialFormData = initFormData(searchDatas)

    const [form] = Form.useForm()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))

        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/user/getLoanList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
                setLoading(false)
            })
        }
    }

    async function resetUserLoan(id) {
        await axios({
            method: 'post',
            url: `/user/loan/reset/${id}`,
        }).then((res) => {
            resultResponse(res, '已成功清零')
            reloadTable()
        })
    }

    function hideUserLoan(id) {
        setData(_.filter(data, d => d.id !== id))
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    const functions = { resetUserLoan, hideUserLoan }
    const columns = getColumns(permission, functions)

    return <>
        <Space direction='vertical' size={16} className='full-width'>
            <Card>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                    <Row>
                        <Col span={12} />
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card>
                <Table
                    size="small"
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                    summary={pageData => {
                        let totalCost = _.sumBy(data, 'available_money');
                        let totalLoan = _.sumBy(data, 'money');
                        let totalLoanLimit = _.sumBy(data, 'limit_money');
                        let totalNum = _.size(columns)
                        let total = _.size(data)
                        switch (role) {
                            case 'SALE':
                            case 'FRANCHISE':
                            case 'SALE_LEADER':
                            case 'SALE_ATTACHE':
                            case 'FINANCE':
                                return <tr>
                                    <th>汇总</th>
                                    <td colSpan={totalNum - 1}>
                                        <Space>
                                            <span>总条数: <Text type="danger">{total}</Text></span>
                                            <span>总余额: <Text type="danger">{totalCost}</Text></span>
                                            <span>总负债: <Text type="danger">{totalLoanLimit - totalLoan}</Text></span>
                                        </Space>
                                    </td>
                                </tr>
                            default:
                                return null
                        }
                    }}
                />
            </Card>
        </Space>
    </>;
}


export default UserLoanTable

const searchDatas = [
    {
        name: 'account',
        label: '用户名',
        placeholder: '请输入用户名',
        type: 'input'
    }, {
        name: 'sale_name',
        label: '销售名称',
        placeholder: '请输入销售名称',
        type: 'input'
    }, {
        name: 'money',
        label: '余额',
        type: 'range',
        datas: [
            {
                name: 'min_money',
                label: '最小余额',
                placeholder: '请输入最小余额',
                type: 'input'
            },
            {
                name: 'max_money',
                label: '最大余额',
                placeholder: '请输入最大余额',
                type: 'input'
            },
        ]
    }, {
        name: 'has_loan',
        label: '状态',
        type: 'radio',
        value: true,
        children: [
            ['所有', false],
            ['已开通', true],
        ]
    },
]

function getColumns(permission, functions) {
    const { resetUserLoan, hideUserLoan } = functions
    return [
        {
            title: '用户',
            dataIndex: 'account',
            render: (val, data) => {
                return <Space direction='vertical'>
                    <Text>{val}</Text>
                    <Button size='small' type='link' onClick={() => hideUserLoan(data.id)}>隐藏</Button>
                </Space>
            }
        }, {
            title: '所属销售',
            dataIndex: 'sale_name'
        }, {
            title: '最早未结算时间',
            dataIndex: 'create_time',
            width: 96,
            render: (t) => formatTime(t)
        }, {
            title: '状态',
            dataIndex: 'state',
            render: (val, data) => {
                return val === 1 ? <Tag style={{ margin: 0 }} color="green">正常</Tag> : <Tag style={{ margin: 0 }} color="orange">暂停</Tag>
            }
        }, {
            title: '白条状态',
            dataIndex: 'limit_money',
            render: (t, data) => {
                let percent = data.money / data.limit_money * 100
                let status = 'active'
                if (percent > 90) status = 'success'
                if (percent < 30) status = 'exception'
                return <Space direction='vertical'>
                    <Progress percent={numberFixed(percent)} status={status} format={(percent) => `${percent}%`} style={{ width: 120 }} />
                    <Text>{number2currency(data.money)} / {number2currency(data.limit_money)}</Text>
                </Space>
            }
        }, {
            title: '白条负债（元）',
            dataIndex: 'limit_money',
            render: (t, data) => {
                return <Statistic value={(data.limit_money - data.money)} />
            }
        }, {
            title: '处理',
            dataIndex: 'id',
            render: (t, data) => {
                return <Space direction='vertical'>
                    {permission['module:user:loan:reset'] && <Popconfirm
                        title={`确认购买清零白条吗?`}
                        onConfirm={() => resetUserLoan(data.id)}
                        onCancel={e => console.log(e)}
                        okText="确认"
                        cancelText="取消">
                        <Button size='small' type='primary'>清零</Button>
                    </Popconfirm>}
                </Space>
            }
        }
    ]
} 