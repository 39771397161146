import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Button, Form, message, InputNumber, Radio, Divider } from 'antd';
import _ from 'lodash'
import { MinusCircleOutlined, PlusOutlined, } from '@ant-design/icons';
import qs from 'qs';

const axios = require('axios');
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

const SingleRule = ({ prefix }) => {

    const [numberArray, setNumberArray] = useState([1, 2, 3])
    const changeNumberArray = e => {
        if (e < 0) {
            e = 0
        } else if (e > 10) {
            e = 10
        }
        let i;
        let result = []
        for (i = 1; i <= e; i++) {
            result = _.concat(result, i)
        }
        setNumberArray(result)
    }

    return <>
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Form.Item name={`${prefix}_price`} label="奖金">
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={`${prefix}_people`} label="人数">
                    <InputNumber min={0} max={10} onChange={e => changeNumberArray(e)} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={`${prefix}_ratio`} label="会员倍率">
                    <InputNumber />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            {
                numberArray.map(e => {
                    return <Col span={6} key={e}>
                        <Form.Item name={`${prefix}_detail_${e}`} label={`第${e}名`}>
                            <InputNumber />
                        </Form.Item>
                    </Col>
                })
            }
        </Row>
        <Divider />
    </>
}


const AddRulesForm = ({ data }) => {

    const [count, setCount] = useState(['1', '2'])           // 规则数目

    const addRules = async (values) => {
        await axios({
            method: 'post',
            url: `/event/rule/add`,
            data: values
        }).then(res => {
            if (res.data.code === 1) {
                message.success("已成功添加")
            } else {
                message.error(res.data.message)
            }
        })
    }


    const postHandleValues = (val) => {
        let newVal = []
        let newValObj = {}
        _.forEach(count, e => {
            let obj = {}
            let detail = []
            let detailObj = {}
            _.forEach(val, (v, k) => {
                let code = _.split(k, '_')
                if (code[1] === e) {
                    if (code[2] === 'detail') {
                        detail = _.concat(detail, { [code[3]]: v })
                        detailObj = _.assign(detailObj, { [code[3]]: v })
                    } else {
                        obj = _.assign(obj, { [code[2]]: v })
                    }
                }
            })
            obj = _.assign(obj, { detail: detailObj })
            newVal = _.concat(newVal, obj)
            newValObj = _.assign(newValObj, obj)

        })
        return newVal
    }

    return <Form name="dynamic_form_item"
        layout="vertical"
        onFinish={values => {
            let tempVal = postHandleValues(values)
            addRules({
                total_money: values['total_money'],
                order_num: values['order_num'],
                rule: tempVal
            })
        }}>
        <Radio.Group onChange={e => setCount(e.target.value)}>
            <Radio.Button value={['1', '2']}>两条规则</Radio.Button>
            <Radio.Button value={['1', '2', '3']}>三条规则</Radio.Button>
        </Radio.Group>
        <Form.Item label="总金额" name="total_money">
            <InputNumber />
        </Form.Item>
        <Form.Item label="目标数" name="order_num">
            <InputNumber />
        </Form.Item>
        {
            count.map(e => {
                return <SingleRule prefix={`rule_${e}`} key={e} />
            })
        }
        <Form.Item>
            <Button type="primary" htmlType="submit">提交</Button>
        </Form.Item>
    </Form >

}

export default AddRulesForm
