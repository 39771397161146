import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const EnterpriseInvoiceSearchConfig = () => {
    const role = useRecoilValue(userRoleState)

    const defaultSearch = [
        {
            name: 'invoice_number',
            label: '发票代码',
            placeholder: '请输入发票代码',
            type: 'input'
        }, {
            name: 'price',
            label: '价格范围',
            type: 'range',
            datas: [
                {
                    name: 'min_amount',
                    label: '最低价格',
                    placeholder: '最低价格',
                    type: 'input'
                },
                {
                    name: 'max_amount',
                    label: '最高价格',
                    placeholder: '最高价格',
                    type: 'input'
                },
            ]
        }, {
            name: 'enterprise_name',
            label: '所属公司',
            type: 'radio',
            children: [
                ['全部', ''],
                ['搜力科技', '搜力科技'],
                ['举世传媒', '举世传媒'],
            ],
            value: ''
        }, {
            name: 'status',
            label: '发票状态',
            type: 'radio',
            children: [
                ['全部', ''],
                ['正常', '1'],
                ['作废', '0'],
                ['已使用', '2'],
            ],
            value: '1'
        }, {
            name: 'type',
            label: '发票类型',
            type: 'radio',
            children: [
                ['全部', ''],
                ['专票', 1],
                ['普票', 2],
            ],
            value: ''
        },
    ]

    const timeSearch = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: null
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        },
    ]


    let result = []

    switch (role) {
        case "FINANCE":
            result = _.concat(
                defaultSearch,
                timeSearch
            )
            break;
        default:
            break;
    }

    return result
}

export default EnterpriseInvoiceSearchConfig