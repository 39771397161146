import React, { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Divider, Layout } from 'antd';
import _ from 'lodash'
import MediaSearchConfig from '../../../../role/media/MediaSearchConfig'
import { SearchFormHori, SearchFormInput } from '../../../common/SearchFormTile';
import MediaPriceTableConfig from '../../../../role/media/MediaPriceTableConfig'
import { tablePagination } from '../../../common/tableCommon';

const axios = require('axios');
const { Content } = Layout;
const layout = { layout: 'vertical' };

const HomePriceTable = ({ media_from }) => {

    const [form] = Form.useForm();

    const searchDatas = MediaSearchConfig({ type: media_from })
    const initialFormData = _.reduce(searchDatas, (result, data) => {
        return _.assign(result, { [data.name]: data.value ?? '' })
    }, {})

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("media_from", media_from)
        if (val) {
            _.toPairs(val)
                .filter(e => e[1].length > 0)
                .forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: `/public/media/getMediaPriceList`,
                data: params,
            }, {
                cancelToken: source.token
            }).then((res) => {
                let data = res?.data?.data?.map(e => {
                    let prices = _.split(e.prices, ',')
                    let obj = _.reduce(prices, (result, col) => {
                        let name = _.split(col, '_')[0];
                        let price = _.split(col, '_')[1];
                        return _.assign(result, { [name]: price })
                    }, {})
                    return _.assign(e, obj)
                })
                setData(data)
                setLoading(false)
                let total = res.data.total
                if (total < ((media_from - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }
    // 初始化列表
    useEffect(() => {
        fetchData()
        return source.cancel('Operation canceled by the user.');
    }, [])

    let columns = MediaPriceTableConfig({ data, type: 'PUBLIC' })

    return <>
        <Content className='table-search'>
            <Form
                {...layout}
                name="media-form"
                form={form}
                initialValues={initialFormData}
                onFinish={val => fetchData(_.pickBy(val, _.identity))}
            >
                <SearchFormHori searchDatas={searchDatas} reloadTable={reloadTable} />
                <Divider />
                <Row>
                    <Col span={12}>
                        <SearchFormInput searchDatas={searchDatas} reloadTable={reloadTable} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button.Group>
                        </Button.Group>
                        <Button type="primary" htmlType="submit" style={{ margin: "0 0 0 8px" }}>
                            <SearchOutlined /> 搜索
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Content>

        <Content style={{ background: '#fff', padding: 24, margin: 0, }}>
            <Table
                size='middle'
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination, filters, sorter) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>
    </>;
}

export default HomePriceTable