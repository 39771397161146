import _ from 'lodash'

const levelArray = [
    {
        level: 1,
        exp: 1
    }, {
        level: 2,
        exp: 49
    }, {
        level: 3,
        exp: 149
    }, {
        level: 4,
        exp: 299
    }, {
        level: 5,
        exp: 499
    }, {
        level: 6,
        exp: 999
    }, {
        level: 7,
        exp: 1999
    }, {
        level: 8,
        exp: 4999
    }, {
        level: 9,
        exp: 9999
    }, {
        level: 10,
        exp: 19999
    }, {
        level: 11,
        exp: 29999
    },
]

export const getExp = level => {
    if (!level) return 0
    if (level < 1) return 0
    let currentLevel = _.filter(levelArray, e => {
        return e.level === level
    })
    return currentLevel[0].exp
}
