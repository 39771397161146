import React, { useState, useEffect } from 'react';
import { PlusOutlined, ExportOutlined, UploadOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Divider, Drawer, message, Layout, Modal, Spin, Input, Typography, Upload, Space } from 'antd';
import _ from 'lodash'
import qs from 'qs';

import AddXiaohongshuForm from './AddXiaohongshuForm';
import { XIAOHONGSHU, getPrice } from './xiaohongshuCommon'
import SearchForm from '../../common/SearchForm'
import { initFormData, tablePagination, TableTitle } from '../../common/tableCommon';
import { resultResponse } from '../../common/ResultResponse';
import XiaohongshuTableConfig from '../../../role/xiaohongshu/XiaohongshuTableConfig';
import XiaohongshuSearchConfig from '../../../role/xiaohongshu/XiaohongshuSearchConfig';
import RoleModule from '../../../utils/permission/RoleModule';

const { Text } = Typography;
const { Content } = Layout;
const axios = require('axios');

const layout = { layout: 'vertical' };

const XiaohongshuTable = ({ action, medias = [], setMedias = () => console.log(""),
    url, simpleMode = false, type = null
}) => {

    const [form] = Form.useForm();
    const [saleGroupForm] = Form.useForm();

    const searchDatas = XiaohongshuSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const [visible, setVisible] = useState({ addMedia: false, action: 'ADD' })
    const [media, setMedia] = useState({})
    const [drawerLoading, setDrawerLoading] = useState(false)
    const [modal, setModal] = useState({ download: false, saleGroupPrice: false, editor: false, target: '' })
    const [saleGroupDatas, setSaleGroupDatas] = useState([])
    const [saleGroupLoading, setSaleGroupLoading] = useState(false)

    const reloadTable = () => form.submit()
    const resetModal = () => setModal({ download: false, saleGroupPrice: false, editor: false, target: '' })
    const resetMedia = () => setMedia({})

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val)
                .filter(e => e[1].length > 0)
                .forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: url || '/xiaohongshu/resource/list',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    // 初始化媒体分配价格
    useEffect(() => {
        if (_.size(saleGroupDatas) > 0) {
            saleGroupForm.resetFields()
            let result = {}
            saleGroupDatas.forEach(e => {
                let prices = _.split(e.prices, ',')
                prices.forEach(price => {
                    let tmp = _.split(price, '_')
                    _.assign(result, {
                        [`${tmp[0]}_price_${e.sale_group_id}`]: tmp[1]
                    })
                })
            })
            saleGroupForm.setFieldsValue(result)
        }
    }, [saleGroupDatas, saleGroupForm])

    const createMedia = async (media) => {
        const formData = new FormData();
        _.toPairs(media).map(e => formData.append(`${e[0]}`, e[1]))
        if (!drawerLoading) {
            setDrawerLoading(true)
            await axios({
                method: 'post',
                url: '/xiaohongshu/addWeChat',
                data: formData
            }).then((res) => {
                resultResponse(res, '已成功添加')
                setDrawerLoading(false)
                resetMedia()
                reloadTable()
                resetModal()
            })
        }
    }

    const updateMedia = async (media) => {
        const formData = new FormData();
        _.toPairs(media).map(e => formData.append(`${e[0]}`, e[1]))
        if (!drawerLoading) {
            setDrawerLoading(true)
            await axios({
                method: 'post',
                url: '/xiaohongshu/addWeChat',
                data: formData
            }).then((res) => {
                resultResponse(res, '已成功修改')
                setDrawerLoading(false)
                resetMedia()
                reloadTable()
                resetModal()
            })
        }
    }

    const downloadRepeat = async () => {
        setModal({ ...modal, download: true })
        let params = new FormData()
        let val = _.pickBy(form.getFieldsValue(), _.identity);
        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        await axios({
            url: `/xiaohongshu/downloadRepeat`, //your url
            method: 'POST',
            responseType: 'blob', // important
            data: params
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '小红书重复表.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }

    const downloadMedias = async () => {
        setModal({ ...modal, download: true })
        let params = new FormData()
        let val = _.pickBy(form.getFieldsValue(), _.identity);
        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        await axios({
            url: `/xiaohongshu/downLoadMediaIndex`, //your url
            method: 'GET',
            responseType: 'blob', // important
            data: params
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '媒体资源详情.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }

    const downloadMediasPrice = async () => {
        setModal({ ...modal, download: true })
        let params = new FormData()
        let val = _.pickBy(form.getFieldsValue(), _.identity);
        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        await axios({
            url: `/xiaohongshu/downloadPrice`, //your url
            method: 'post',
            responseType: 'blob', // important
            data: params
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '媒体价格分配.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }


    const props = {
        name: 'upload_file',
        action: '/xiaohongshu/importPrice',
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} 上传成功!`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败!`);
            }
        },
    };

    const drawerAction = { visible, setVisible, createMedia, updateMedia }

    const getMedia = async (id) => {
        setVisible({ ...visible, addMedia: true, action: 'EDIT' })
        if (!drawerLoading) {
            setDrawerLoading(true)
            await axios({
                method: 'post',
                url: url || `/xiaohongshu/resource/list`,
                data: qs.stringify({ id })
            }).then((res) => {
                let tempMedia = res.data.data[0]
                setMedia(tempMedia)
                setDrawerLoading(false)
            })
        }
    }

    const deleteMedia = async (id) => {
        await axios({
            method: 'post',
            url: `/xiaohongshu/delete`,
            data: qs.stringify({ id })
        }).then((res) => {
            resultResponse(res, '已成功删除')
            reloadTable()
        })
    }

    const getDetail = async (id) => {
        await axios({
            method: 'get',
            url: `/xiaohongshu/detail/${id}`
        }).then((res) => {
        })
    }

    const getTitle = () => {
        let title = '添加媒体';
        if (action === 'ADD') {
            title = '添加媒体'
        } else if (action === 'EDIT') {
            title = '编辑媒体'
        }
        return title
    }

    const getSaleGroupPrice = async (id, type) => {
        setSaleGroupLoading(true)
        setModal({ ...modal, saleGroupPrice: true })
        await axios({
            method: 'get',
            url: `/xiaohongshu/getSaleGroupPrices?id=${id}&type=${type}`,
        }).then(res => {
            if (res.data.code === 1) {
                setSaleGroupDatas(res.data.data)
                setSaleGroupLoading(false)
            } else {
                message.error(res.data.data)
            }
        })
    }

    const updateSaleGroupPrice = async (val) => {
        await axios({
            method: 'post',
            url: `/xiaohongshu/update/saleGroupPrice`,
            data: qs.stringify(val)
        }).then(res => {
            if (res.data.code === 1) {
                message.success("已成功更新")
                setModal({ ...modal, saleGroupPrice: false })
            } else {
                message.error(res.data.msg)
            }
        })
    }

    const changeEditor = (id) => setModal({ ...modal, editor: true, target: id })

    const handleMedia = { getMedia, getSaleGroupPrice, deleteMedia, changeEditor, getDetail }
    let defaultColumns = XiaohongshuTableConfig({ action, handleMedia })
    const localStorageColumnsName = "____XiaohongshuTableConfig____";
    let localStorageColumns = JSON.parse(localStorage.getItem(localStorageColumnsName))
    _.forEach(defaultColumns, (e) => {
        _.forEach(localStorageColumns, (val) => {
            if (e.title === val.title) {
                e.checked = val.checked
            }
        })
    })
    const [columns, setColumns] = useState(defaultColumns)

    return <>
        <Content className='table-search'>
            <Form
                {...layout}
                form={form}
                name="wechat-hot-form"
                initialValues={initialFormData}
                onFinish={val => fetchData(_.pickBy(val, _.identity))}
            >
                <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                <Divider />
                <Row>
                    <Col span={12}>
                        <RoleModule action='module:media:add' data={{ action: action }}>
                            <Button.Group>
                                <Button type="primary" onClick={() => setVisible({ ...visible, addMedia: true, type: '' })}>
                                    <PlusOutlined />添加资源
                                </Button>
                            </Button.Group>
                        </RoleModule>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Space>
                            <Button.Group>
                                <RoleModule action='module:media:export' data={{ action }}>
                                    <Button icon={<ExportOutlined />} onClick={() => downloadMedias()}>
                                        导出
                                    </Button>
                                </RoleModule>
                                <RoleModule action='module:media:export:repeat'>
                                    <Button icon={<ExportOutlined />} onClick={() => downloadRepeat()}>
                                        导出重复资源
                                    </Button>
                                </RoleModule>
                                <RoleModule action='module:media:import:price'>
                                    <Button.Group>
                                        <Button icon={<ExportOutlined />} onClick={() => downloadMediasPrice()}>
                                            导出价格
                                        </Button>
                                        <Upload {...props}>
                                            <Button icon={<UploadOutlined />}>导入价格</Button>
                                        </Upload>
                                    </Button.Group>
                                </RoleModule>
                            </Button.Group>
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                                搜索
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Content>
        <Content style={{ background: '#fff', padding: 24, margin: 0, }}        >
            <Table
                size='middle'
                title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} />}
                columns={columns.filter(e => e.checked === 1)}
                rowSelection={{
                    columnWidth: '24px',
                    selectedRowKeys: medias.map(e => e.id),
                    onSelect: (record, selected) => {
                        let price = record.user_price
                        if (type) {
                            let priceMap = getPrice(record.user_price)
                            price = priceMap[type]
                        }
                        let tempMedia = {
                            id: record.id,
                            name: record.name,
                            price: price
                        }
                        let newMedias = selected ? _.concat(medias, tempMedia) : _.differenceBy(medias, [tempMedia], 'id');
                        setMedias(_.uniqBy(newMedias, 'id'))
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        let tempMedias = changeRows.map(e => {
                            let price = e.user_price
                            if (type) {
                                let priceMap = getPrice(e.user_price)
                                price = priceMap[type]
                            }
                            return {
                                id: e.id,
                                name: e.name,
                                price: price
                            }
                        })
                        let newMedias = selected ? _.concat(medias, tempMedias) : _.differenceBy(medias, tempMedias, 'id');
                        setMedias(_.uniqBy(newMedias, 'id'))
                    },
                    getCheckboxProps: (record) => ({
                        disabled: !Boolean(getPrice(record.user_price)[type]),
                        name: record.name,
                    }),
                }}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>

        <Drawer
            title={getTitle()}
            width={720}
            onClose={() => setVisible({ ...visible, addMedia: false })}
            open={visible.addMedia}
        >
            <Spin spinning={drawerLoading}>
                <AddXiaohongshuForm media={media} drawerAction={drawerAction} drawerLoading={drawerLoading} />
            </Spin>
        </Drawer>
        {/* 销售组分配价格 */}
        <Modal
            title="媒体价格分配"
            destroyOnClose={true}
            width={780}
            open={modal.saleGroupPrice}
            okText="确认"
            cancelText="取消"
            onOk={() => {
                saleGroupForm
                    .validateFields()
                    .then((values) => {
                        let data = {
                            prices: JSON.stringify(values),
                            resource_id: saleGroupDatas[0].resource_id
                        }
                        updateSaleGroupPrice(data)
                    })
            }}
            onCancel={() => setModal({ ...modal, saleGroupPrice: false })}
        >
            <Spin spinning={saleGroupLoading} >
                <SaleGroupForm datas={saleGroupDatas} form={saleGroupForm} />
            </Spin>
        </Modal>
        {/* 下载媒体提示框 */}
        <Modal
            open={modal.download}
            footer={null}
            onOk={() => setModal({ ...modal, download: false })}
            onCancel={() => setModal({ ...modal, download: false })}
            maskClosable={false}
        >
            <div style={{ textAlign: 'center' }}>
                <Spin tip="下载中..." />
            </div>
        </Modal>
    </>;
}

export default XiaohongshuTable

const SaleGroupForm = ({ form, datas }) => {
    return <Form form={form}>
        <Row gutter={16}>
            {
                datas.map(e => <React.Fragment key={e.sale_group_id}>
                    <Col span={24} style={{ display: 'flex' }}>
                        <Form.Item style={{ flex: '1 0 0' }}>
                            <Text strong>{e.sale_group_name}</Text>
                        </Form.Item>
                    </Col>
                    {
                        _.map(XIAOHONGSHU, (val) => {
                            return <Col span={24 / _.size(XIAOHONGSHU)} key={val.id}>
                                <Form.Item label={val.name} name={`${val.id}_price_${e.sale_group_id}`}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        })
                    }
                </React.Fragment>)
            }
        </Row>
    </Form>
}