import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import { Spin } from 'antd';
import _ from 'lodash'

import { userLoginState, platformChange, userRoleState, userState } from '../Apps/atoms/Atoms'
import { paths } from '../utils/permission/path'
import { check } from '../utils/permission/rules'

const ERROR_PAGE = '/dashboard/error'

function AuthorizedRoute({ children }) {
    const loggedIn = useRecoilValue(userLoginState)
    const loading = useRecoilValue(platformChange)

    const role = useRecoilValue(userRoleState)
    const user = useRecoilValue(userState)

    let location = useLocation();

    if (loggedIn === null) return <div style={{ height: '100%', width: '100%', display: 'grid', placeContent: 'center' }}><Spin /></div>
    if (loggedIn !== true) return <Navigate push to="/" />

    // console.log(location)
    let arr = _.split(location.pathname, '/')
    let end_str = arr[_.size(arr) - 1]
    let local_path = isNumeric(end_str) ? _.join(_.concat(_.dropRight(arr), ':id'), '/') : location.pathname

    let alias = _.find(paths, function (e) {
        return e.path === local_path
    })

    if (location.pathname !== ERROR_PAGE) {
        if (alias === undefined) {
            return <Navigate push to={ERROR_PAGE} />
        }
        if (!check(role, alias?.key, { user })) {
            return <Navigate push to={ERROR_PAGE} />
        }
    }
    return children
}

export default AuthorizedRoute

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}