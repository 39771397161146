import { useRecoilValue } from 'recoil';

import { getRouter } from '../../../utils/permission/rules';
import { userState, userRoleState, platformState } from '../../atoms/Atoms'
import { Platform } from '../../common/CONSTANTS';
const ManageLayoutConfig = () => {
    const role = useRecoilValue(userRoleState)
    const user = useRecoilValue(userState)
    const platform = useRecoilValue(platformState)

    const router = [
        {
            key: 'page:notice:center',
            title: '公告中心',
            url: '/dashboard/notices',
            platform: Platform.media.id
        }, {
            key: 'page:user:list',
            title: '用户列表',
            url: '/dashboard/user/users',
            platform: Platform.media.id
        }, {
            key: 'page:user:loan:manage',
            title: '白条管理',
            url: '/dashboard/user/loan/manage',
            platform: Platform.media.id
        }, {
            key: 'page:user:salary',
            title: '薪资管理',
            url: '/dashboard/user/salary',
            platform: Platform.media.id
        }, {
            key: 'page:user:liar:list',
            title: '骗子管理',
            url: '/dashboard/user/liars',
            platform: Platform.media.id
        }, {
            key: 'page:user:level:list',
            title: '等级分组',
            url: '/dashboard/user/levels',
            platform: Platform.media.id
        },
        // {
        //     key: 'page:tag:list',
        //     title: '标签管理',
        //     url: '/dashboard/tags',
        //     platform: Platform.media.id
        // },
        // {
        //     key: 'page:coupon:list',
        //     title: '优惠券管理',
        //     url: '/dashboard/coupons',
        //     platform: Platform.media.id
        // },
        //  {
        //     key: 'page:coupon:pack:list',
        //     title: '优惠券包',
        //     url: '/dashboard/coupons/pack',
        //     platform: Platform.media.id
        // }, {
        //     key: 'page:role:manage',
        //     title: '权限管理',
        //     url: '/dashboard/role',
        //     platform: Platform.media.id
        // },
    ]

    return getRouter({ role, router, data: { user, platform } })

}

export default ManageLayoutConfig