import CreateMediaIndex from '../mediaindex/CreateMediaIndex'
import React from "react"

const MediaIndexRoutesConfig = [
    {
        path: '/dashboard/index/add',
        component: <CreateMediaIndex />
    },
]

export default MediaIndexRoutesConfig