import _ from 'lodash'
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';

import { userRoleState } from '../../Apps/atoms/Atoms'
const WithdrawManageSearchConfig = () => {
    const role = useRecoilValue(userRoleState)

    const defaultSearch = [
        {
            name: 'alipay_id',
            label: '支付宝账号',
            placeholder: '请输入支付宝账号',
            type: 'input'
        },
        {
            name: 'alipay_name',
            label: '支付宝实名',
            placeholder: '请输入支付宝实名',
            type: 'input'
        },
    ]

    const userSearch = [
        {
            name: 'create_name',
            label: '申请用户名称',
            placeholder: '请输入申请用户名称',
            type: 'input'
        },
        {
            name: 'alipay_id',
            label: '支付宝账号',
            placeholder: '请输入支付宝账号',
            type: 'input'
        },
        {
            name: 'alipay_name',
            label: '支付宝实名',
            placeholder: '请输入支付宝实名',
            type: 'input'
        }, {
            name: 'withdraw_type',
            label: '提现类型',
            type: 'radio',
            value: '',
            children: [
                ['全部', ''],
                ['外部申请', 1],
                ['内部申请', 2],
            ]
        },
    ]

    const timeSearch = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: dayjs().subtract(3, 'months')
        },
        {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        },
    ]

    let result = []

    switch (role) {
        case "OPERATOR_ADMIN":
            result = _.concat(
                defaultSearch,
                timeSearch
            )
            break;
        case "FINANCE":
            result = _.concat(
                userSearch,
                timeSearch
            )
            break;
        default:
            break;
    }
    return result
}

export default WithdrawManageSearchConfig