import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const WorkOrderSearchConfig = () => {
    const role = useRecoilValue(userRoleState)

    const defaultSearch = [
        {
            name: 'name',
            label: '订单标题',
            placeholder: '请输入标题',
            type: 'input'
        }, {
            name: 'customer_name',
            label: '客户名称',
            placeholder: '请输入客户名称',
            type: 'input'
        }, {
            name: 'sale_name',
            label: '销售名称',
            placeholder: '请输入销售名称',
            type: 'input'
        },
    ]

    const timeSearch = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: null
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        },
    ]



    let result = []

    switch (role) {
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
        case 'FINANCE':
            result = _.concat(
                defaultSearch,
                timeSearch
            )
            break;
        default:
            break;
    }

    return result
}

export default WorkOrderSearchConfig