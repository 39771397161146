import React from 'react'
import _ from 'lodash'
import { Space } from 'antd';

export const recallReason = val => {
    switch (val) {
        case 1:
            return "选择错误媒体"
        case 2:
            return "来源填写错误"
        case 3:
            return "稿件内容有误"
        default:
            return "其他"
    }
}

export const errorReason = val => {
    switch (val) {
        case 1:
            return "稿件内容遭修改"
        case 2:
            return "稿件被删除"
        case 3:
            return "稿件未收录"
        default:
            return "其他"
    }
}

export const getName = data => {
    let media_name = data.media_name ?? data.name;
    if (data.media_from === '自媒体') {
        let array = ['(', ')', '（', '）']
        let isTrue = false
        array.forEach(item => {
            if (_.indexOf(media_name, item) !== -1) {
                isTrue = true
                return
            }
        })
        media_name = isTrue ? media_name : `${data.door_type}（${media_name}）`
    }
    return media_name
}

export const getText = data => {
    let copyUrl = data.source_url === "" ? `http://p.yunzitui.com/post/${data.order_no}` : data.source_url
    let copyText = `媒体：${getName(data)}\n标题：${data.title}\n地址：${copyUrl}\n`
    return (data.remarks === '' || data.remarks === null) ? copyText : copyText + `备注：${data.remarks}\n`
}

export const getResponse = data => {
    let copyUrl = data.back_info
    return `媒体：${getName(data)}\n标题：${data.title}\n地址：${copyUrl}\n`
}

export const getStatus = data => {
    switch (data.media_status) {
        case 'waitPublish':
            if (data.status === 'waitVerify') {
                return 'WAIT_VERIFY'
            }
            return 'WAIT_PUBLISH'
        case 'success':
            return 'SUCCESS'
        case 'recall':
            return 'RECALL'
        case 'error':
            return 'ERROR'
        case 'back':
            return 'BACK'
        default:
            return
    }
}

export const ContentCell = ({ data }) => {
    let content = <a href={`http://p.yunzitui.com/post/${data.order_no}`} target="_blank" rel='noopener noreferrer'>文本查看</a>;
    let url = <a href={`${data.source_url}`} target="_blank" rel='noopener noreferrer'>来源地址</a>;
    let file_url = <a href={`http://platform.yunzitui.com${data.file_url}`}>文档下载</a>;
    return <Space direction='vertical'>
        {data.content === 1 && content}
        {!(_.isNil(data.source_url) || data.source_url === "") && url}
        {!(_.isNil(data.file_url) || data.file_url === "" || data.file_url === 'undefined') && file_url}
    </Space>
}