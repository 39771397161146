import React, { useEffect, useState } from 'react';
import { Timeline, Skeleton, Typography, Space } from 'antd';
import _ from 'lodash'
import qs from 'qs'

import { propertyType, notificationType, notificationColorType } from './NotificationConfig'

const { Text, Paragraph, Title, Link } = Typography

const CONNECTOR = '=>'
const axios = require('axios');
const MediaChangeHistory = ({ id }) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getMediaChangeHistory = async () => {
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: `/notifications/notificationList`,
                data: qs.stringify({
                    media_id: id
                })
            }).then(res => {
                if (res.data.code === 1) {
                    setData(res.data.data)
                }
            })
        }
        setLoading(false)
    }


    useEffect(() => {
        getMediaChangeHistory()
    }, [id])

    return <Skeleton active loading={loading}>
        <Timeline>
            {
                data.map(item => {
                    let data = item.content
                    let array = _.filter(_.split(data, '|'), e => e !== '')
                    return item.content && <Timeline.Item key={item.id} color={notificationColorType.get(item.type_id)}>
                        <Title level={4}>
                            {`${item.account} ${notificationType.get(item.type_id)}`}
                        </Title>
                        <Space direction='vertical' size='small'>
                            <Space direction='vertical' size='small'>
                                {
                                    array.map(e => {
                                        let tmp = _.split(e, ':')
                                        let name = tmp[0]
                                        if (name === 'url') {
                                            let vals = _.split(tmp[2], '->')
                                            let old_url = `${tmp[1]}:${vals[0]}`
                                            let new_url = `${vals[1]}:${tmp[3]}`
                                            return <Paragraph key={name}>
                                                {propertyType.get(name)}: <Link href={old_url}>原案例</Link> {CONNECTOR} <Link href={new_url}>新案例</Link>
                                            </Paragraph>
                                        } else {
                                            let vals = _.split(tmp[1], '->')
                                            return <Text key={name}>
                                                {`${propertyType.get(name)}: ${vals[0]} ${CONNECTOR} ${vals[1]}`}
                                            </Text >
                                        }
                                    })
                                }
                            </Space>
                            <Text style={{ fontSize: 12 }} italic>{item.create_time}</Text>
                        </Space>
                    </Timeline.Item>
                })
            }
        </Timeline>
    </Skeleton >
}

export default MediaChangeHistory;