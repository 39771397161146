import React from 'react';
import { Timeline } from 'antd';
import _ from 'lodash'

const OrderTimeLine = ({ history }) => {
    const getItem = (data) => {
        switch (data.action) {
            case 'verify':
                if (data.detail === 'notPass') {
                    return `${data.create_time} ${data.account} 审核拒稿`
                } else if (data.detail === 'waitPublish') {
                    return `${data.create_time} ${data.account} 通过审核`
                }
                break;
            case 'publish':
                if (data.detail === 'success') {
                    return `${data.create_time} ${data.account} 成功发布`
                } else if (data.detail === 'back') {
                    return `${data.create_time} ${data.account} 发布失败`
                }
                break;
            case 'reject':
                return `${data.create_time} ${data.account} 稿件退稿`
            case 'recall':
                return `${data.create_time} ${data.account} 申请撤稿`
            case 'err':
                return `${data.create_time} ${data.account} 申请异常`
            case 'cancel':
                return `${data.create_time} ${data.account} 自行撤稿`
            case 'confirm':
                if (data.detail === 'recall') {
                    return `${data.create_time} ${data.account} 恢复撤稿订单`
                } else if (data.detail === 'error') {
                    return `${data.create_time} ${data.account} 恢复异常订单`
                }
                break;
            case 'pay':
                if (_.upperCase(data.status) === 'UNPAID') {
                    return `${data.create_time} ${data.account} 点击订单未结算`
                } else {
                    return `${data.create_time} ${data.account} 点击订单已结算`
                }
            case 'sales-pay':
                if (_.upperCase(data.status) === 'UNPAID') {
                    return `${data.create_time} ${data.account} 点击订单未打款`
                } else {
                    return `${data.create_time} ${data.account} 点击订单已打款`
                }
            default:
                return ``
        }
    }


    return (
        <Timeline>
            {
                history.map(e => {
                    return <Timeline.Item key={e.create_time}>{getItem(e)}</Timeline.Item>
                })
            }
        </Timeline>
    )
}

export default OrderTimeLine;