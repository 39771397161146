import React, { useState, useEffect } from 'react'
import { message, Typography, Divider, Spin, Tabs, Button, Popconfirm, Space } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash'

const axios = require('axios');
const { Text, Title } = Typography;

const platforms = [
    ['媒体平台', 1],
    ['微博平台', 2],
    ['微信平台', 3],
    ['小红书平台', 4],
    ['抖音平台', 5],
]
const PremiunUser = ({ modal, setModal }) => {
    const [saleGroups, setSaleGroups] = useState([])
    const [loading, setLoading] = useState(false)

    const getPremiumInfo = async () => {
        if (!loading) {
            setLoading(true)
            await axios.get("/user/plus/info")
                .then(res => {
                    if (res.data.code === 1) {
                        setSaleGroups(res.data.data)
                    } else {
                        message.error(res.data.data)
                    }
                    setLoading(false)
                })
        }

    }

    useEffect(() => {
        getPremiumInfo()
    }, [])

    return <Spin spinning={loading}>
        <div>
            <div style={{ textAlign: 'center', marginBottom: 24 }}>
                <Title level={1} style={{ color: '#1890ff', }}>选择一种会员类型</Title>
                <Text style={{ color: '#1890ff' }}>找到符合你需求的云自推账户</Text>
            </div>
            <Space direction='vertical' style={{ width: '100%' }} size='large'>
                {
                    platforms.map(e => <PlatfromPremium title={e[0]} idx={e[1]} saleGroups={saleGroups} modal={modal} setModal={setModal} key={e[1]} />)
                }
            </Space>
        </div>
        <Divider />
        <div style={{ textAlign: 'center' }}>
            <Text disabled>购买会员费用会从您的账户余额中直接扣除</Text>
        </div>
        <Divider />
    </Spin>
}
export default PremiunUser

const Strategy = ({ strategy, saleGroup, modal, setModal }) => {
    const [buying, setBuying] = useState(false)
    const buyPremium = async (id) => {
        if (!buying) {
            setBuying(true)
            await axios.get(`/user/premium/${id}`)
                .then(res => {
                    if (res.data.code === 1) {
                        message.info("已成功购买")
                        setModal({ ...modal, buyVip: false })
                    } else {
                        message.error(res.data.msg)
                    }
                    setBuying(false)
                })
        }
    }

    let price = strategy.price
    let hasDiscount = dayjs().diff(dayjs(strategy.start_time), 'days') > 0
        && dayjs(strategy.end_time).diff(dayjs(), 'days') > 0
        && Boolean(strategy.discount < 1)
    let total_month = strategy.length + strategy.extra_length

    return <div key={strategy.id} style={{ border: '1px solid #333', borderRadius: 8, padding: 30 }}>
        <div style={{ textAlign: 'center' }}>
            <Title level={3}>{strategy.name}</Title>
            <Divider style={{ margin: 8 }} />
            {
                hasDiscount
                    ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: 8, display: 'flex', flexDirection: 'column' }}>
                            <Text style={{ fontSize: 20, color: '#1890ff' }}>活动价: {strategy.discount * price}</Text>
                            <Text delete style={{ fontSize: 16 }}>原价:{price}</Text>
                        </div>
                        <div style={{ marginBottom: 8 }}>
                            <Text style={{ fontSize: 16 }}>合</Text>
                            <Text style={{ fontSize: 20 }}>{(strategy.discount * price / total_month).toFixed(1)}</Text>
                            <Text style={{ fontSize: 16 }}>/月</Text>
                        </div>
                        {
                            strategy.extra_length > 0
                                ? <Text type='secondary'>共计{total_month}个月(含额外补贴{strategy.extra_length}个月)</Text>
                                : <Text type='secondary'>共计{total_month}个月</Text>
                        }
                        <Title level={4}>活动时间</Title>
                        <Text>{dayjs(strategy.start_time).format("YYYY-DD-MM")} - {dayjs(strategy.end_time).format("YYYY-MM-DD")}</Text>
                    </div>
                    : <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: 16, display: 'flex', flexDirection: 'column' }}>
                            <Text style={{ fontSize: 20 }}>价格:{price}</Text>
                        </div>
                        <div style={{ marginBottom: 8 }}>
                            <Text style={{ fontSize: 16 }}>合</Text>
                            <Text style={{ fontSize: 18 }}>{(price / total_month).toFixed(1)}</Text>
                            <Text style={{ fontSize: 16 }}>/月</Text>
                        </div>
                        {
                            strategy.extra_length > 0
                                ? <Text type='secondary'>共计{total_month}个月(含额外补贴{strategy.extra_length}个月)</Text>
                                : <Text type='secondary'>共计{total_month}个月</Text>
                        }
                    </div>
            }
        </div>
        <div style={{ margin: 16, display: 'grid', placeContent: 'center' }}>
            <Popconfirm
                title="是否确认付款"
                onConfirm={() => buyPremium(strategy.id)}
                onCancel={() => console.log('取消')}
                okText="确认"
                cancelText="取消"
            >
                <Button type='primary' loading={buying}>升级为{saleGroup.sale_name}</Button>
            </Popconfirm>
        </div>
    </div>
}

const PlatfromPremium = ({ title, idx, saleGroups, modal, setModal }) => {

    const getPremiumByPlatform = (platform_id, data) => {
        return _.filter(data, e => e.platform_id === platform_id)
    }

    let datas = _.filter(getPremiumByPlatform(idx, saleGroups), sg => _.size(sg?.strategy) > 0)

    return _.size(datas) > 0 && <div key={idx}>
        <div style={{ textAlign: 'center' }}>
            <Title level={3}>{title}</Title>
        </div>
        <Tabs defaultActiveKey="1" onChange={e => console.log(e)} centered
            items={[
                datas.map(saleGroup => {
                    let strategys = saleGroup?.strategy
                    let size = _.size(strategys)
                    return {
                        tab: < Title level={4}>{saleGroup.sale_name} </Title>,
                        key: saleGroup.id,
                        children: <div style={{ marginTop: 16 }}>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: `repeat(${size}, 280px)`,
                                columnGap: 24,
                                placeContent: 'center'
                            }}>
                                {
                                    strategys.map(strategy =>
                                        <Strategy strategy={strategy} saleGroup={saleGroup} modal={modal} setModal={setModal} />
                                    )
                                }
                            </div>
                        </div>
                    }
                })
            ]}
        />
    </div >
}