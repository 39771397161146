import React, { useState } from 'react';
import { Layout, Tabs, Badge } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useRecoilValue } from 'recoil'
import _ from 'lodash'

import { userRoleState, orderNumsState } from '../../atoms/Atoms'
import { check } from '../../../utils/permission/rules';
import PageHeader from '../../layout/PageHeader';
import ProjectTable from './ProjectTable';

const { Content } = Layout;

// 项目中心页面
// dashboard/project
const ProjectCenter = () => {
    let pageTitle = "项目中心"
    const role = useRecoilValue(userRoleState)
    const [tab, setTab] = useState("media")

    const permission = {
        'tab:media:order': check(role, 'tab:media:order', { action: null }),
        'tab:baike:order': check(role, 'tab:baike:order', null),
        'tab:index:order': check(role, 'tab:index:order', { action: null }),
        'tab:qa:order': check(role, 'tab:qa:order', null),
        'tab:weibo:fans:order': check(role, 'tab:weibo:fans:order', null),
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)} items={[
                        { key: 'media', label: <TabName target='MEDIA' /> },
                    ]}
                    />
                }
            />
            < Content style={{ margin: 24 }}>
                <ProjectTable />
            </Content >
        </HelmetProvider >
    )
}

export default ProjectCenter;

const TabName = ({ target }) => {

    const orderNums = useRecoilValue(orderNumsState)

    const names = {
        MEDIA: { name: '新闻媒体', key: 'MEDIA' },
        BAIKE: { name: '百科订单', key: 'BAIKE' },
        QA: { name: '问答订单', key: 'QA' },
        INDEX: { name: '文字链订单', key: 'INDEX' }
    }

    const hasOrder = (name) => {
        let array = _.find(orderNums, e => e?.name === name)
        return array && array.customer > 0
    }

    return <span>
        {names[target]?.name}
        {hasOrder(names[target]?.key) && <Badge dot offset={[2, -8]} />}
    </span>
}
