import React, { useState, useEffect } from 'react'
import { Space, Typography, message, Timeline } from 'antd';

import { formatTime } from '../../../utils/TimeUtils'
const { Text } = Typography;
const axios = require('axios');

const MediaNoticeList = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        if (!loading) {
            setLoading(true)
            await axios({
                url: '/resource/media/notice',
                type: 'json',
                method: 'post',
                contentType: 'application/json'
            }).then(res => {
                if (res.data.code === 1) {
                    setData(res.data.data)
                } else {
                    message.warning(res.data.data || res.data.msg || res.data)
                }
                setLoading(false)
            })
        }
    }

    return <Timeline>
        {
            data.map(item => <Timeline.Item key={item.id}>
                <div className='flex-justify'>
                    <Text style={{ fontSize: 12 }}>{formatTime(item.create_time)}</Text>
                    <Space direction='vertical' size={2} style={{ marginLeft: 16, textAlign: 'right' }}>
                        <Text style={{ fontSize: 12 }}>{item.name}</Text>
                        <Text strong style={{ fontSize: 12 }}>{item.content}</Text>
                    </Space>
                </div>
            </Timeline.Item>)
        }
    </Timeline>
}

export default MediaNoticeList