import React from 'react'
import { SyncOutlined } from '@ant-design/icons';
import { Button, Card, Result } from 'antd';

export const Feedback = ({ fetching, feedback, resetAll, prevPage }) => {
    const obj = feedback.code === 1
        ? {
            status: 'success', title: '已成功提交', onClick: resetAll, text: '继续提交'
        } : {
            status: 'warning', title: feedback.data, onClick: prevPage, text: '返回'
        }

    return <Card>
        {
            fetching
                ? <Result icon={<SyncOutlined spin />} title="正在提交稿件" />
                : <Result
                    status={obj.status}
                    title={obj.title}
                    extra={[
                        <Button type="primary" key="console" onClick={obj.onClick}>
                            {obj.text}
                        </Button>
                    ]}
                />
        }
    </Card>
}