import React from 'react'
import { PayCircleOutlined } from '@ant-design/icons';
import { Tag, Typography, Button, message, Popconfirm, Space } from 'antd';
import _ from 'lodash'
import qs from 'qs';
import { useRecoilValue } from 'recoil';

import { userRoleState, userAttacheState } from '../../Apps/atoms/Atoms'
import { formatTime } from '../../utils/TimeUtils'
import { resultResponse } from '../../Apps/common/ResultResponse';
import { check } from '../../utils/permission/rules';

const axios = require('axios');

const { Paragraph, Text } = Typography;
const tableStyle = { display: 'flex', flexDirection: 'column' }

export const MediaIndexOrderTableConfig = ({ modal, setModal, handleOrders, tab, action }) => {
    const role = useRecoilValue(userRoleState)
    const attache = useRecoilValue(userAttacheState)
    const permission = {
        'module:media:order:money:cost': check(role, 'module:media:order:money:cost', { action }),
        'module:media:order:cost:modify': check(role, 'module:media:order:cost:modify', null),
        'module:media:order:money:paid': check(role, 'module:media:order:money:paid', { action }),
        'module:media:order:money:agent': check(role, 'module:media:order:money:agent', null),
        'module:media:order:money:sales:agent': check(role, 'module:media:order:money:sales:agent', null),
        'module:media:order:sales:status': check(role, 'module:media:order:sales:status', null),
        'module:media:order:status': check(role, 'module:media:order:status', null),
        'module:media:order:history': check(role, 'module:media:order:history', null),
        'module:media:order:editor:edit': check(role, 'module:media:order:editor:edit', null),
        'module:media:order:recall:handle': check(role, 'module:media:order:recall:handle', { action }),
        'module:media:order:error:handle': check(role, 'module:media:order:error:handle', { action }),
        'module:media:order:success:reject': check(role, 'module:media:order:success:reject', null),
        'module:media:order:success:handle': check(role, 'module:media:order:success:handle', { action }),
        'module:media:order:success:error': check(role, 'module:media:order:success:error', { action }),
        'module:media:order:publish:handle': check(role, 'module:media:order:publish:handle', { action }),
        'module:media:order:publish:recall': check(role, 'module:media:order:publish:recall', { action }),
        'module:media:order:verify:handle': check(role, 'module:media:order:verify:handle', { action }),
        'module:media:order:verify:cancel': check(role, 'module:media:order:verify:cancel', { action }),
        'module:media:order:customer:name': check(role, 'module:media:order:customer:name', null),
        'module:media:order:sales:name': check(role, 'module:media:order:sales:name', null),
    }
    const { verifyOrderSuccess, updatePayinfo, rejectOrder, reloadTable } = handleOrders;
    const size = 'small'

    // 订单已结算 
    const paidOrder = async (id, status) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/pay",
            data: qs.stringify({
                ids: id,
                paid_status: status,
            })
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
        })
    }

    const paidSalesOrder = async (id, status) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/sales/pay",
            data: qs.stringify({
                ids: id,
                sales_status: status,
            })
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
        })
    }

    const orderSalesAndCustomer = {
        title: '销售姓名',
        dataIndex: 'sale_name',
        checked: 1,
        render: (t, data) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text strong>{data.customer_name}</Text>
                    {attache === 0 && <Text>{t}</Text>}
                </div>
            )
        }
    }

    const defaultConfig = [
        {
            title: '媒体名称',
            dataIndex: 'mediaIndex_name',
            checked: 1,
            width: 120
        }, {
            title: '标题',
            dataIndex: 'title',
            width: 400,
            checked: 1,
            render: (text, data) => {
                let url = `http://p.yunzitui.com/post/${data.order_no}`
                let copyUrl = data.source_url === "" ? url : data.source_url
                let copyText = `媒体：${data.mediaIndex_name}\n标题：${data.title}\n地址：${copyUrl}\n`
                return (
                    <>
                        <Paragraph style={{ marginBottom: 0 }} copyable={{
                            text: (data.remarks === '' || data.remarks === null) ? copyText : copyText + `备注：${data.remarks}`
                        }}>{text}</Paragraph>
                    </>
                )
            }
        }
    ]

    const orderPrice = {
        title: '价格',
        dataIndex: 'paid_money',
        width: 96,
        checked: 1,
        render: (t, data) => {
            switch (role) {
                case 'CUSTOMER':
                    return action === 'EDIT' ? data.cost_money : <div>
                        <Text>{data.paid_money}</Text><br />
                    </div>
                case 'OPERATOR':
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text strong>成本：{data.cost_money}</Text>
                            <Text strong>付款：{data.paid_money}</Text>
                            <Text strong>相对：{data.agent_paid_money}</Text>
                        </div>
                    )
                case "OPERATOR_MEDIA":
                case 'SALE':
                case 'SALE_LEADER':
                case 'SALE_ATTACHE':
                case 'FRANCHISE':
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text strong>成本：{data.agent_paid_money}</Text>
                            <Text strong>付款：{data.paid_money}</Text>
                        </div>
                    )
                default:
                    return data.paid_money
            }
        }
    }

    const orderContent = [
        {
            title: '内容',
            dataIndex: 'content',
            width: 96,
            checked: 1,
            render: (text, data) => {
                let content = <a href={`http://p.yunzitui.com/post/${data.order_no}`} target="_blank" rel='noopener noreferrer'>文本查看</a>;
                let url = <a href={`${data.source_url}`} target="_blank" rel='noopener noreferrer' className="text-info" >来源地址</a>;
                return (
                    <div style={tableStyle}>
                        {data.content === 1 && <span>{content}</span>}
                        {data.source_url !== '' && <span>{url}</span>}
                    </div>
                )
            }
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            checked: 1,
        }
    ]

    const orderTime = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        },
        {
            title: '审核时间',
            dataIndex: 'handle_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        },
        {
            title: '发布时间',
            dataIndex: 'publish_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        },
    ]

    const orderStatus = {
        title: '状态',
        dataIndex: 'status',
        width: 72,
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            let result = '';
            switch (data.status) {
                case 'WAIT_VERIFY':
                    result = <Tag style={{ margin: 0 }} color="blue">待审核</Tag>;
                    break;
                case 'WAIT_PUBLISH':
                    result = <Tag style={{ margin: 0 }} color="gold">待发布</Tag>;
                    break;
                case 'SUCCESS':
                    result = <Tag style={{ margin: 0 }} color="green">已发布</Tag>;
                    break;
                case 'BACK':
                case 'NOT_PASS':
                    result = <Tag style={{ margin: 0 }} color="red">已退稿</Tag>;
                    break;
                default:
                    break;
            }
            return result
        }
    }

    const orderStatusCol = {
        title: '结算状态',
        dataIndex: 'paid_status',
        width: 72,
        checked: 1,
        render: (val, data) => {
            let obj = val === 'UNPAID'
                ? { color: 'red', text: '未支付', state: 'PAID' }
                : { color: 'green', text: '已支付', state: 'UNPAID' }
            // 必须为管理员才能处理结算状态
            return <Tag style={{ margin: 0 }} onClick={() => permission['module:media:order:status'] && paidOrder(data.id, obj.state)} color={obj.color}>
                {obj.text}
            </Tag>
        }
    }

    const salesOrderStatusCol = {
        title: '结算状态',
        dataIndex: 'sales_status',
        width: 72,
        checked: 1,
        render: (val, data) => {
            let obj = val === 'UNPAID'
                ? { color: 'red', text: '未打款', state: 'PAID' }
                : { color: 'green', text: '已打款', state: 'UNPAID' }
            return <Tag style={{ margin: 0 }} onClick={() => permission['module:media:order:sales:status'] && paidSalesOrder(data.id, obj.state)} color={obj.color}>{obj.text}</Tag>
        }
    }

    const orderBackInfo = {
        title: '回执信息',
        dataIndex: 'back_info',
        width: 150,
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            let result = '';
            if (data.reason) {
                result = data.reason;
            } else if (data.back_info) {
                result = data.back_info;
            }
            switch (data.status) {
                case 'SUCCESS':
                    result = <Space direction='vertical'>
                        <Space>
                            <a href={data.back_info} className='table-btn' target="_blank" rel="noopener noreferrer">查看</a>
                            {
                                !(_.isNil(data.back_info_img) || data.back_info_img === "") &&
                                <a href={data.back_info_img} target='_blank' rel='noopener noreferrer'>查看图片</a>
                            }
                        </Space>
                        <Button size={size} className='table-btn'
                            onClick={() => {
                                setModal({ ...modal, target_id: data.id, errorBox: true })
                            }}>异议</Button>
                    </Space>
                    break;
                default:
                    result = <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>{result}</Paragraph>
                    break;
            }
            return result
        }
    }

    const orderSalesBackInfo = {
        title: '回执信息',
        dataIndex: 'back_info',
        width: 150,
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            let result = '';
            if (data.reason) {
                result = data.reason;
            } else if (data.back_info) {
                result = data.back_info;
            }
            switch (data.status) {
                case 'SUCCESS':
                    result = <>
                        <a href={data.back_info} className='table-btn' target="_blank" rel="noopener noreferrer">查 看</a>
                    </>
                    break;
                default:
                    result = <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>{result}</Paragraph>
                    break;
            }

            return result
        }
    }

    const handleUser = {
        title: '所属运营',
        dataIndex: 'handle_user',
        checked: 1,
        render: (t, data) => {
            return (
                <div style={{ ...tableStyle, alignItems: 'start' }}>
                    <span>{t}</span>
                    {tab === '4' && <div>
                        {'paid' === data.paid_status ? <Tag color="#87d068">已结算</Tag> : <Tag color="#f50">未结算</Tag>}
                        <Button size='small' icon={<PayCircleOutlined />} onClick={() => { updatePayinfo(data.id, data.paid_status) }}></Button>
                    </div>}
                </div>
            );
        }
    }
    const mediaEditor = {
        title: '所属编辑',
        dataIndex: 'editor_name',
        checked: 1,
        render: (t, data) => {
            return <div style={tableStyle}>
                <span>{data.editor_name}</span>
                <span>{data.editor_tel}</span>
            </div>
        }
    }
    const orderCustomer = {
        title: '所属客户',
        dataIndex: 'customer_name',
        checked: 1,
        render: (t, data) => {
            if (_.size(data.qq) > 0) {
                return <div style={tableStyle} >
                    <Button type='link'
                        onClick={() => {
                            let url = `http://wpa.qq.com/msgrd?v=3&uin=${data.qq}&site=qq&menu=yes`
                            window.open(url, 'top')
                        }}>联系客户</Button>
                </div >
            } else {
                return ""
            }
        }
    }

    const handleOrder = {
        title: '处理订单',
        dataIndex: 'back_info',
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            let result = '';
            if (data.reason) {
                result = data.reason;
            } else if (data.back_info) {
                result = data.back_info;
            }

            switch (data.status) {
                case 'WAIT_VERIFY':
                    result = (
                        <Space>
                            <Button size={size} type="primary" className='table-btn'
                                onClick={() => {
                                    verifyOrderSuccess(data.id)
                                }}
                            >通过</Button>
                            <Button size={size} danger className='table-btn'
                                onClick={() => {
                                    setModal({
                                        ...modal, target_id: data.id, verifyFailed: true
                                    })
                                }}>不通过</Button>
                        </Space>
                    )
                    break;
                case 'WAIT_PUBLISH':
                    result = (
                        <Space>
                            <Button size={size} type="primary" className='table-btn'
                                onClick={() => {
                                    setModal({
                                        ...modal, target_id: data.id, publishSuccess: true
                                    })
                                }}>回执</Button>
                            <Button size={size} danger className='table-btn'
                                onClick={() => {
                                    setModal({
                                        ...modal, target_id: data.id, publishOrderFailed: true
                                    })
                                }}>退稿</Button>
                        </Space>
                    );
                    break;
                case 'SUCCESS':
                    result = (
                        <>
                            <Space>
                                <Button type="primary" size={size} className='table-btn'
                                    onClick={() => {
                                        setModal({ ...modal, target_id: data.id, updateUrl: true })
                                    }}>编辑</Button>
                                {role === "OPERATOR" && <Popconfirm
                                    title="是否退稿"
                                    onConfirm={() => rejectOrder(data.id)}
                                    onCancel={() => message.warn('已取消')}
                                    okText="确认"
                                    cancelText="取消"
                                >
                                    <Button danger size={size} >退稿</Button>
                                </Popconfirm>}
                            </Space>
                            <br />
                            <Button.Group style={{ marginTop: '8px' }}>
                                <Button size={size} className='table-btn'
                                    onClick={() => window.open(data.back_info, '_blank')}>查看回执</Button>
                                <Button size={size} className='table-btn'
                                    onClick={() => window.open(data.back_info_img, '_blank')}>查看图片</Button>
                            </Button.Group>


                        </>
                    );
                    break;
                default:
                    break;
            }

            return result
        }
    }



    let result = []
    let defaultOrder = _.concat(
        defaultConfig,
        orderContent,
        orderPrice,
    )

    switch (role) {
        case "CUSTOMER":
            if (action === 'EDIT') {
                result = _.concat(
                    defaultOrder,
                    orderCustomer,
                    orderTime,
                    orderStatus,
                    handleOrder,
                )
            } else {
                result = _.concat(
                    defaultOrder,
                    orderTime,
                    orderStatus,
                    orderBackInfo,
                )
            }
            break;
        case "OPERATOR":
        case "OPERATOR_ADMIN":
        case "OPERATOR_LEADER":
            result = _.concat(
                orderSalesAndCustomer,
                defaultOrder,
                handleUser, mediaEditor,
                orderTime,
                orderStatusCol,
                orderStatus,
                handleOrder,
            )
            break;
        case "SALE":
        case 'SUPPORT_STAFF':
        case 'FRANCHISE':
        case "SALE_LEADER":
        case "SALE_ATTACHE":
            result = _.concat(
                orderSalesAndCustomer,
                defaultOrder,
                orderTime,
                salesOrderStatusCol,
                orderStatus,
                orderSalesBackInfo,
            )
            break;
        default:
            break;
    }
    return result
}
