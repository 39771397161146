import React from 'react';
import { Layout } from 'antd';
import { useRecoilValue } from 'recoil';

import Sidebar from './Sidebar';
import { sidebarCollapsed } from '../atoms/Atoms';

const { Sider } = Layout;

const logoImg = 'http://static.yunzitui.com/img/index/yzt/logo-7.png'
const smLogo = 'http://static.yunzitui.com/img/index/yzt/logo-sm.png'

const DefaultSidebar = () => {

    const collapsed = useRecoilValue(sidebarCollapsed)

    return <Sider
        collapsible collapsed={collapsed} trigger={null}
        width={200}
        style={{
            height: '100vh',
            position: 'fixed',
            zIndex: 99,
            background: '#fff',
            boxShadow: '2px 0 8px 0 rgba(29,35,41,.05)',
            overflow: 'auto'
        }}
    >
        <Logo collapsed={collapsed} />
        <Sidebar />
    </Sider>
}

export default DefaultSidebar

const Logo = ({ collapsed }) => {
    return collapsed
        ? <div style={{ height: 64, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={smLogo} alt="" style={{ height: 32 }} />
        </div>
        : <div className="logo">
            <img src={logoImg} alt="" />
        </div>
}