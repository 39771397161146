import React, { useState } from 'react';
import { Table, Typography, Form, Input, Button } from 'antd';
import _ from 'lodash'

const { Title } = Typography

const WhiteList = () => {
    const data = [
        {
            type: '中央新闻网站',
            web: ['人民网', '新华网', '中国网', '央视网', '国际在线', '中国日报网', '中国青年网', '中国经济网', '中国新闻网', '光明网', '央广网',
                '中国台湾网', '中国西藏网', '中工网', '党建网', '中青在线', '环球网', '中国军网', '法制网', '海外网', '中国搜素', '参考消息网', '中国警察网',
                '消费日报网', '中国侨网', '未来网', '中国发展网', '正义网', '中国消费网', '中国安全生产网', '中国煤炭网', '中国海洋在线', '求是网', '中国共产党历史网',
                '中华人民共和国国史网', '中国社会科学网', '理论网', '马克思主义研究网', '理论中国网', '人民论坛网'],
            size: 2
        }, {
            type: '中央新闻单位',
            web: ['人民日报', '人民日报海外版', '新华社', '新华每日电讯', '瞭望', '中国新闻社', '中国新闻周刊', '解放军报', '求是', '光明日报', '经济日报', '中央人民广播电台',
                '中央电视台', '中国国际广播电台', '中国日报', '北京周报', '半月谈', '经济参考报', '中国证券报', '环球时报', '科技日报', '工人日报', '中国青年报', '中国妇女报', '农民日报', '法制日报', '人民政协报',
                '人民中国', '人民画报', '今日中国', '健康报', '中国劳动保障报', '中国教育报', '中国工商报', '中国电力报', '中国民航报', '人民铁道报', '人民邮电报', '中国交通报',
                '中国医药报', '中国质量报', '中国税务报', '中国休育报', '中国财经报', '检察日报', '人民法院报', '中国建设报', '中国环境报', '中国文化报', '人民公安报', '中国水利报',
                '中华工商时报', '中国纪检监察报', '中国审计报', '金融时报', '国际商报', '中国商报', '中国经济周刊', '中华儿女', '中国青年', '中国妇女', '瞭望东方周刊', '《网络传播》杂志',
                '《求是》杂志', '《党建》杂志', '中国经济时报', '《紫光阁》杂志', '中国组织人事报', '中国气象报', '中国绿色时报', '中国经济导报', '中国消费者报', '中国安全生产报', '中国煤炭报',
                '当代世界', '中国海洋报', '环球人物', '《人民论坛》杂志', '中国报道杂志', '三联生活周刊'],
            size: 2
        }, {
            type: '部委网站',
            web: ['外交部网站', '卫生部网站', '商务部网站', '财政部网站', '国家发改委网站', '中国网信网', '中国文明网', '中国记协网', '中国政府网', '中央纪委监察部网站',
                '国务院发展研究中心网站', '紫光阁网', '国家宗教事务局网站', '教育部网站', '中国气象网'],
            size: 2
        }, {
            type: '省级新闻单位',
            size: 1,
            immutable: true
        }, {
            type: '地方',
            web: ['网络'],
            newspaper: ['报刊'],
            boardcast: ['广电'],
            immutable: true
        }, {
            type: '北京',
            web: ['千龙新闻网', '财新网', '宣讲家网'],
            newspaper: ['北京日报', '北京晚报', '北京晨报', '京华时报', '新京报', '北京青年报'],
            boardcast: ['北京人民广播电台', '北京电视台']
        }, {
            type: '天津',
            web: ['北方网'],
            newspaper: ['天津日报', '今晚报', '滨海时报'],
            boardcast: ['天津人民广播电台', '天津电视台']
        }, {
            type: '河北',
            web: ['长城网', '河北新闻网'],
            newspaper: ['河北日报', '石家庄日报', '燕赵晚报'],
            boardcast: ['河北人民广播电台', '河北电视台', '石家庄人民广播电台', '石家庄电视台']
        }, {
            type: '山西',
            web: ['黄河新闻网', '山西新闻网'],
            newspaper: ['山西日报', '太原日报', '山西晚报', '太原晚报'],
            boardcast: ['山西人民广播电台', '山西电视台', '太原人民广播电台', '太原电视台']
        }, {
            type: '内蒙古',
            web: ['内蒙古新闻网'],
            newspaper: ['内蒙古日报', '呼和浩特市日报', '呼和浩特晚报'],
            boardcast: ['内蒙古人民广播电台', '内蒙古电视台', '呼和浩特市人民广播电台', '呼和浩特市电视台']
        }, {
            type: '辽宁',
            web: ['东北新闻网', '北国网'],
            newspaper: ['辽宁日报', '沈阳晚报', '大连日报', '沈阳日报'],
            boardcast: ['辽宁人民广播电台', '辽宁电视台', '大连人民广播电台', '大连电视台', '沈阳人民广播电台', '沈阳电视台']
        }, {
            type: '吉林',
            web: ['中国吉林网'],
            newspaper: ['吉林日报', '长春晚报', '长春日报'],
            boardcast: ['吉林人民广播电台', '吉林电视台', '长春人民广播电台', '长春电视台']
        }, {
            type: '黑龙江',
            web: ['东北网', '哈尔滨新闻网'],
            newspaper: ['黑龙江日报', '哈尔滨日报', '黑龙江晨报', '生活报'],
            boardcast: ['黑龙江人民广播电台', '黑龙江电视台', '哈尔滨人民广播电台', '哈尔滨电视台']
        }, {
            type: '上海',
            web: ['东方网', '新民网', '一财网', '解放网', '看看新闻网', '澎湃新闻网'],
            newspaper: ['解放日报', '文汇报', '新民晚报'],
            boardcast: ['上海人民广播电台', '上海电视台', '东方电视台', '东方广播电台']
        }, {
            type: '江苏',
            web: ['中国江苏网', '新华报业网'],
            newspaper: ['新华日报', '南京日报', '扬子晚报', '金陵晚报'],
            boardcast: ['江苏省广播电视总台', '南京人民广播电台', '南京电视台']
        }, {
            type: '浙江',
            web: ['浙江在线', '中国宁波网', '杭州网', '新蓝网'],
            newspaper: ['浙江日报', '钱江晚报', '杭州日报', '宁波日报', '今日早报'],
            boardcast: ['浙江人民广播电台', '浙江电视台', '杭州人民广播电台', '杭州电视台', '宁波人民广播电台', '宁波电视台']
        }, {
            type: '安徽',
            web: ['中国安徽在线'],
            newspaper: ['安徽日报', '合肥日报', '合肥晚报'],
            boardcast: ['安徽人民广播电台', '安徽电视台', '合肥人民广播电台', '合肥电视台']
        }, {
            type: '福建',
            web: ['东南新闻网'],
            newspaper: ['福建日报', '福州日报', '福州晚报', '厦门日报'],
            boardcast: ['福建人民广播电台', '福建电视台', '福州人民广播电台', '福州电视台', '厦门人民广播电台', '厦门电视台']
        }, {
            type: '江西',
            web: ['中国江西网', '大江网', '今视网'],
            newspaper: ['江西日报', '南昌日报', '南昌晚报'],
            boardcast: ['江西省人民广播电台', '江西电视台', '南昌人民广播电台', '南昌电视台']
        }, {
            type: '山东',
            web: ['大众网', '齐鲁网', '胶东在线', '鲁网', '中国山东网'],
            newspaper: ['大众日报', '齐鲁晚报', '济南日报', '青岛日报', '山东商报'],
            boardcast: ['山东人民广播电台', '山东电视台', '济南人民广播电台', '济南电视台', '青岛人民广播电台', '青岛电视台']
        }, {
            type: '河南',
            web: ['大河网', '映象网'],
            newspaper: ['河南日报', '郑州日报', '郑州晚报'],
            boardcast: ['河南人民广播电台', '河南电视台', '郑州人民广播电台', '郑州电视台']
        }, {
            type: '湖北',
            web: ['荆楚网', '长江网'],
            newspaper: ['湖北日报', '武汉晚报', '长江日报'],
            boardcast: ['湖北人民广播电台', '湖北电视台', '武汉人民广播电台', '武汉电视台']
        }, {
            type: '湖南',
            web: ['红网', '华声在线', '金鹰网'],
            newspaper: ['湖南日报', '长沙晚报'],
            boardcast: ['湖南人民广播电台', '湖南电视台', '长沙人民广播电台', '长沙电视台']
        }, {
            type: '广东',
            web: ['南方新闻网', '大洋网', '深圳新闻网', '金羊网'],
            newspaper: ['南方日报', '广州日报', '羊城晚报', '深圳特区报'],
            boardcast: ['广东人民广播电台', '广东电视台', '南方电视台', '广州人民广播电台', '广州电视台', '深圳人民广播电台', '深圳电视台']
        }, {
            type: '广西',
            web: ['广西新闻网'],
            newspaper: ['广西日报', '南宁日报', '南宁晚报', '南方科技报'],
            boardcast: ['广西人民广播电台', '广西电视台', '南宁人民广播电台', '南宁电视台']
        }, {
            type: '海南',
            web: ['南海网（海南新闻网）'],
            newspaper: ['海南日报', '海口晚报', '海南特区报', '国际旅游岛商报'],
            boardcast: ['海南广播电视台', '海口广播电视台']
        }, {
            type: '重庆',
            web: ['华龙网', '视界网'],
            newspaper: ['重庆日报', '重庆晚报', '重庆晨报'],
            boardcast: ['重庆人民广播电台', '重庆电视台']
        }, {
            type: '四川',
            web: ['四川新闻网', '四川在线', '成都全搜素新闻网'],
            newspaper: ['四川日报', '成都日报', '成都晚报', '华西都市报', '成都商报'],
            boardcast: ['四川人民广播电台', '四川电视台', '成都人民广播电台', '成都电视台']
        }, {
            type: '贵州',
            web: ['多彩贵州网', '当代先锋网'],
            newspaper: ['贵州日报', '贵阳日报', '贵阳晚报'],
            boardcast: ['贵州人民广播电台', '贵州电视台', '贵阳人民广播电台', '贵阳电视台']
        }, {
            type: '云南',
            web: ['云南网'],
            newspaper: ['云南日报', '昆明日报'],
            boardcast: ['云南人民广播电台', '云南电视台', '昆明人民广播电台', '昆明电视台']
        }, {
            type: '西藏',
            web: ['中国西藏新闻网'],
            newspaper: ['西藏日报', '拉萨晚报'],
            boardcast: ['西藏人民广播电台', '西藏电视台']
        }, {
            type: '陕西',
            web: ['西部网', '陕西传媒网'],
            newspaper: ['陕西日报', '西安日报', '西安晚报'],
            boardcast: ['陕西人民广播电台', '陕西电视台', '西安人民广播电台', '西安电视台']
        }, {
            type: '甘肃',
            web: ['中国甘肃网', '每日甘肃网'],
            newspaper: ['甘肃日报', '兰州日报', '兰州晚报'],
            boardcast: ['甘肃广播电影电视总台', '兰州人民广播电台', '兰州电视台']
        }, {
            type: '青海',
            web: ['青海新闻网'],
            newspaper: ['青海日报', '西宁晚报', '西海都市报'],
            boardcast: ['青海人民广播电台', '青海电视台', '西宁人民广播', '西宁电视台']
        }, {
            type: '宁夏',
            web: ['宁夏新闻网'],
            newspaper: ['宁夏日报', '银川晚报'],
            boardcast: ['宁夏广播电视总台', '银川人民广播电台', '银川电视台']
        }, {
            type: '新疆',
            web: ['天山网'],
            newspaper: ['新疆日报', '乌鲁木齐晚报', '兵团日报'],
            boardcast: ['新疆人民广播电台', '新疆电视台', '乌鲁木齐人民广播电台', '乌鲁木齐电视台', '兵团广播电视台']
        },
    ]
    const [tableData, setTableData] = useState(data)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            title: '地方',
            dataIndex: 'type',
            key: 'type',
            width: 144,
            render: (val, data) => {
                const obj = {
                    children: val,
                    props: {},
                }
                if (data.size === 1) {
                    obj.children = <Title level={3}>{val}</Title>
                    obj.props.colSpan = 4
                } else {
                    obj.children = <Title level={4}>{val}</Title>
                }
                return obj
            }
        },
        {
            title: '网络',
            dataIndex: 'web',
            key: 'web',
            render: (val, data) => {
                if (data.type === '地方') {
                    return <Title level={4}>{val}</Title>
                }
                const obj = {
                    children: val,
                    props: {},
                }
                if (data.web) {
                    let web = data.web
                    if (data.size === 2) {
                        obj.props.colSpan = 3
                    }
                    obj.children = <div className='whitelist-list'>
                        {web.map(e => <div key={e}>{e}</div>)}
                    </div>
                    return obj
                } else {
                    obj.props.colSpan = 0
                    return obj
                }
            }
        },
        {
            title: '报刊',
            dataIndex: 'newspaper',
            key: 'newspaper',
            render: (val, data) => {
                if (data.type === '地方') {
                    return <Title level={4}>{val}</Title>
                }
                if (data.newspaper) {
                    return <div className='whitelist-list'>
                        {data.newspaper.map(e => <div key={e}>{e}</div>)}
                    </div>
                } else {
                    const obj = {
                        children: val,
                        props: {},
                    }
                    obj.props.colSpan = 0
                    return obj
                }
            }
        },
        {
            title: '广电',
            dataIndex: 'boardcast',
            key: 'boardcast',
            render: (val, data) => {
                if (data.type === '地方') {
                    return <Title level={4}>{val}</Title>
                }
                if (data.boardcast) {
                    return <div className='whitelist-list'>
                        {data.boardcast.map(e => <div key={e}>{e}</div>)}
                    </div>
                } else {
                    const obj = {
                        children: val,
                        props: {},
                    }
                    obj.props.colSpan = 0
                    return obj
                }
            }
        }
    ]

    const ifExist = (text, obj) => {
        if (text !== '' && text !== undefined) {
            if (obj.immutable) {
                return obj
            } else {
                console.log(text)
                return {
                    ...obj,
                    web: obj?.web?.filter(e => e.indexOf(text) !== -1),
                    newspaper: obj?.newspaper?.filter(e => e.indexOf(text) !== -1),
                    boardcast: obj?.boardcast?.filter(e => e.indexOf(text) !== -1)
                }
            }
        } else {
            return obj
        }
    }

    const isEmpty = (obj) => {
        if (obj.immutable) {
            return false
        } else {
            return !(_.size(obj?.web) + _.size(obj?.newspaper) + _.size(obj?.boardcast) > 0)
        }
    }

    return <div style={{ margin: '16px auto' }}>
        <Form style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 16 }}
            onFinish={e => {
                setLoading(true)
                const array2 = data.filter(row => {
                    if (e.area !== '' && e.area !== undefined) {
                        return row.type.indexOf(e.area) !== -1 || row.immutable
                    } else {
                        return true
                    }
                })
                const array3 = array2.map(tmp => ifExist(e.name, tmp))
                const array4 = array3.filter(row => !isEmpty(row))
                setTableData(array4)
                setLoading(false)
            }}
        >
            <Form.Item name='area' label='地区'>
                <Input />
            </Form.Item>
            <Form.Item name='name' label='名称'>
                <Input />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    搜索
                </Button>
            </Form.Item>
        </Form>
        <Table
            loading={loading}
            columns={columns}
            dataSource={tableData}
            showHeader={false}
            rowKey={row => row.type}
            pagination={{ pageSize: 50, hideOnSinglePage: true }}
        />
    </div>
}



export default WhiteList