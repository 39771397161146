import React from 'react'
import { Button, message, Popconfirm, Switch, Typography } from 'antd';
import {
    FolderOpenOutlined, PlusOutlined, DeleteOutlined, EditOutlined,
    UpSquareOutlined, DownSquareOutlined
} from '@ant-design/icons';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'

const { Text } = Typography
const CollectionsTableConfig = ({ handleFunctions, setSelectId }) => {
    const role = useRecoilValue(userRoleState)

    const { getEvent, delEvent, eventAddMedia, checkEventMedia, handleStatus, updateProirity } = handleFunctions;

    const nameCol = [
        {
            title: '收藏夹名',
            dataIndex: 'name',
        }, {
            title: '描述',
            dataIndex: 'description',
        },
    ]

    const timeCol = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
        },
    ]

    const statusCol = [
        {
            title: '是否公开',
            dataIndex: 'is_public',
            render: (t, data) => {
                let status = t === 1;
                let value = t === 1 ? 0 : 1;
                return <Switch checkedChildren="正常" unCheckedChildren="停用" defaultChecked={status}
                    onChange={() => handleStatus(data.id, 'is_public', value)} />
            }
        }
    ]

    const infoCol = [
        {
            title: '描述',
            dataIndex: 'description',
        },
        {
            title: '优先级',
            dataIndex: 'priority',
            render: (t, data) => {
                return (
                    <div>
                        <Text>优先级: {t}</Text>
                        <br />
                        <Button.Group>
                            <Button size="small" type="link" icon={<UpSquareOutlined />} onClick={() => updateProirity(data.id, 'add')}></Button>
                            <Button size="small" type="link" icon={<DownSquareOutlined />} onClick={() => updateProirity(data.id, 'minus')}></Button>
                        </Button.Group>
                    </div>
                )
            }
        },
    ]

    const handleCol = [
        {
            title: '编辑',
            dataIndex: 'id',
            render: (t, data) => {
                return <>
                    <Button.Group>
                        <Button onClick={() => getEvent(data.id)}><EditOutlined />编辑</Button>
                        <Button onClick={() => {
                            eventAddMedia(data.id)
                            setSelectId(data.id)
                        }}><PlusOutlined />添加</Button>
                        <Button onClick={() => {
                            checkEventMedia()
                            setSelectId(data.id)
                        }
                        }><FolderOpenOutlined />查看</Button>
                        <Popconfirm
                            title="是否确认删除该活动？"
                            onConfirm={() => delEvent(data.id)}
                            onCancel={() => message.warn('已取消')}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button><DeleteOutlined />删除</Button>
                        </Popconfirm>
                    </Button.Group>
                </>;
            }
        },
    ]

    let result = []
    switch (role) {
        case "CUSTOMER":
        case "OPERATOR":
        case "SALE_ATTACHE":
        case "SALE_LEADER":
            result = _.concat(
                nameCol,
                timeCol,
                handleCol,
            )
            break;
        case "SALE":
        case "FRANCHISE":
            result = _.concat(
                nameCol,
                timeCol,
                statusCol,
                infoCol,
                handleCol,
            )
            break;
        default:
            break;
    }
    return result
}
export default CollectionsTableConfig;
