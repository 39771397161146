import React from 'react';
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import PackagesTable from './PackagesTable'

const { Content } = Layout;

// 媒体优惠套餐
const Packages = () => {

    let pageTitle = "媒体优惠套餐"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <Content style={{ margin: 24 }}>
                <PackagesTable />
            </Content>
        </HelmetProvider>
    )
}

export default Packages;