import React, { useState, useEffect } from 'react';
import { Space, Table, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import qs from 'qs';

import { tablePagination } from '../../common/tableCommon';
import { resultResponse } from '../../common/ResultResponse';

const axios = require('axios');


/**
 * 用于展示派单所属订单
 * @param {*} param0 
 */
const ResourceInfoTable = ({ id = "" }) => {

    const orderTableColumnConfig = [
        {
            title: '名称',
            dataIndex: 'nickname',
        }, {
            title: 'QQ',
            dataIndex: 'qq',
        }, {
            title: '联系电话',
            dataIndex: 'tel',
        }, {
            title: '微信',
            dataIndex: 'wechat_id',
        }, {
            title: '支付宝账号',
            dataIndex: 'alipay_id',
        }, {
            title: '支付宝实名',
            dataIndex: 'alipay_name',
        }, {
            title: '操作',
            dataIndex: 'id',
            width: 150,
            render: (val, data) => {
                return <Space>
                    <Button type="link" icon={<EditOutlined />} size='small'>修改</Button>
                    <Popconfirm
                        title={`确认删除吗?`}
                        onConfirm={() => deleteResourceInfo(data.id)}
                        onCancel={e => console.log(e)}
                        okText="确认"
                        cancelText="取消">
                        <Button type="link" icon={<DeleteOutlined />} size='small' >删除</Button>
                    </Popconfirm>
                </Space>
            }
        }
    ]

    const [loading, setLoading] = useState(false)
    const [datas, setDatas] = useState([])
    const [pagination, setPagination] = useState(tablePagination)

    const checkDetail = async () => {
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: "/order/get/resource-info",
                data: qs.stringify({ ids: id })
            }).then(res => {
                setDatas(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    const deleteResourceInfo = async (id) => {
        await axios({
            method: 'get',
            url: `/resource/info/delete/${id}`,
        }).then(res => {
            resultResponse(res, '已删除')
            checkDetail()
        })
    }

    useEffect(() => {
        if (id !== "") {
            checkDetail()
        }
    }, [pagination.current, pagination.pageSize, id])

    return <>

        <Table
            size='middle'
            rowKey={record => record.id}
            loading={loading}
            columns={orderTableColumnConfig}
            pagination={pagination}
            dataSource={datas}
            onChange={(pagination) => { setPagination(pagination) }}
        />
    </>;
}


export default ResourceInfoTable
