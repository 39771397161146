import _ from 'lodash'

export const WEIBO = {
    POST: {
        id: 20001,
        name: '直发价格'
    },
    REPOST: {
        id: 20002,
        name: '转发价格'
    },
    DUTIES_POST: {
        id: 20011,
        name: '微任务直发价格'
    },
    DUTIES_REPOST: {
        id: 20012,
        name: '微任务转发价格'
    },
}

export const getPrice = price => {
    let prices = _.split(price, ',')
    return _.reduce(prices, (result, price) => {
        let tmp = _.split(price, '_')
        return _.assign(result, { [tmp[0]]: tmp[1] })
    }, {})
}