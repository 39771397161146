import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Layout, Menu } from 'antd';
import SaleGroupTable from './SaleGroupTable'
import PageHeader from '../../layout/PageHeader';
import { UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
const { Content } = Layout;


// 用户所属销售组管理 
const SaleGroups = () => {
    let pageTitle = "用户分组管理"

    const menuItems = [
        {
            label: "用户角色分组",
            key: '1',
            icon: <UserOutlined />
        },
        {
            label: "用户等级分组",
            key: '2',
            icon: <UsergroupAddOutlined />
        },
    ]

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <div style={{ margin: 24 }}>
                <Content
                    style={{
                        background: '#fff',
                        padding: "16px 16px 16px 0",
                        margin: 0,
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Menu
                            onClick={e => console.log(e.key)}
                            style={{ maxWidth: '256px', marginRight: '16px', flex: '1' }}
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            mode="inline"
                            items={menuItems} />
                        <SaleGroupTable />
                    </div>
                </Content>
            </div>
        </HelmetProvider>
    )
}

export default SaleGroups
