import Tags from '../tag/Tags'

import React from "react"
const TagsRoutesConfig = [
    {
        path: '/dashboard/tags',
        component: <Tags/>
    },
]

export default TagsRoutesConfig