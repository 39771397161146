import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Row, Col, Button, Drawer, Space } from 'antd';
import _ from 'lodash'
import qs from 'qs';

import AddEventForm from './AddEventForm';
import MediaTable from '../medias/MediaTable';
import { initFormData, tablePagination } from '../../common/tableCommon';
import CollectionsTableConfig from '../../../role/CollectionsTableConfig';
import CollectionDetailTable from './CollectionDetailTable';
import { resultResponse } from '../../common/ResultResponse';

const axios = require('axios');

const CollectionsTable = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectId, setSelectId] = useState('')
    const [pagination, setPagination] = useState(tablePagination)
    const [medias, setMedias] = useState([])
    const [count, setCount] = useState(0)
    const [visible, setVisible] = useState({
        addEvent: false,
        addMedias: false,
        delMedias: false,
        action: 'ADD'
    })
    const initialEvent = {
        id: '',
        name: '',
        description: '',
        type: 'collections'
    }
    const [event, setEvent] = useState(initialEvent)

    const reloadTable = () => setCount(count + 1)
    const resetEvent = () => setEvent(initialEvent)
    const resetMedias = () => setMedias([])

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async (params = new FormData()) => {
        setLoading(true)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("dispatch", "manage")
        await axios({
            method: 'post',
            url: '/event/collection',
            data: params
        }, {
            cancelToken: source.token
        }).then((res) => {
            setData(res.data.data)
            let total = res.data.total
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination)
    };

    useEffect(() => {
        fetchData();
        return source.cancel('Operation canceled by the user.');
    }, [pagination.current, pagination.pageSize, count])

    // 创建收藏夹
    const createEvent = async () => {
        await axios({
            method: 'post',
            url: '/event/add',
            data: qs.stringify(event)
        }).then((res) => {
            resultResponse(res, '已成功添加')
            resetEvent()
            reloadTable()
        })
    }
    // 修改收藏夹
    const updateEvent = async () => {
        await axios({
            method: 'post',
            url: '/event/add',
            data: qs.stringify(event)
        }).then((res) => {
            resultResponse(res, '已成功更新')
            resetEvent()
            reloadTable()
        })
    }
    // 获取活动信息
    const getEvent = async (id) => {
        setVisible({ ...visible, addEvent: true, action: 'EDIT' })
        await axios({
            method: 'post',
            url: `/event/collection`,
            data: qs.stringify({ id, length: 1 })
        }).then((res) => {
            let tempMedia = res.data.data[0]
            setEvent(_.pick(tempMedia, _.keysIn(event)))
        })
    }
    // 打开活动添加媒体页面 并读取以添加媒体
    const eventAddMedia = async (id) => {
        await axios({
            method: 'post',
            url: `/eventMedia/select/${id}`
        }).then((res) => {
            const datas = res.data
            setMedias(datas.map(id => {
                return { 'id': id }
            }))
        })
        setVisible({ ...visible, addMedias: true, action: 'ADD' })
    }

    // 查看或者移除活动媒体
    const checkEventMedia = () => setVisible({ ...visible, delMedias: true, action: 'ADD' })

    // 添加活动媒体
    const addEventMedias = async () => {
        let params = new FormData()
        params.append("event_id", selectId)
        params.append("ids", medias.map(e => e.id))
        await axios({
            method: 'post',
            url: `/eventMedia/add`,
            data: params
        }).then((res) => {
            resultResponse(res, "已成功")
            resetMedias()
            reloadTable()
        })
    }

    // 移除活动
    const delEvent = async (id) => {
        await axios({
            method: 'delete',
            url: `/event/${id}`
        }).then((res) => {
            resultResponse(res, "已成功删除")
            reloadTable()
        })
    }

    // 修改活动状态
    const handleStatus = async (id, key, val) => {
        await axios({
            method: 'post',
            url: `/event/modify`,
            data: qs.stringify({ id, [key]: val })
        }).then((res) => {
            resultResponse(res, "已成功修改")
            reloadTable()
        })
    }

    const updateProirity = async (id, action) => {
        await axios({
            method: 'post',
            url: `/event/priority/${id}`,
            data: qs.stringify({ action })
        }).then((res) => {
            resultResponse(res, "已成功修改")
            reloadTable()
        })
    }


    const getTitle = () => {
        return visible.action === 'EDIT' ? '编辑活动' : '添加活动'
    }


    const handleFunctions = {
        getEvent, delEvent, eventAddMedia, checkEventMedia, handleStatus, updateProirity
    }

    const drawerAction = { visible, setVisible, createEvent, updateEvent, handleStatus }

    const columns = CollectionsTableConfig({ handleFunctions, setSelectId })

    return <>
        <Table
            size='middle'
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            title={() => (
                <>
                    <Row>
                        <Col span={12}></Col>
                        <Col span={12} style={{ textAlign: 'end' }}>
                            <Button.Group>
                                <Button type="primary" onClick={() => setVisible({ ...visible, addEvent: true, type: '' })}>
                                    <PlusOutlined />添加收藏夹
                                </Button>
                            </Button.Group>
                        </Col>
                    </Row>
                </>
            )}
        />
        {/* 创建收藏夹 */}
        <Drawer
            title={getTitle()}
            width={960}
            onClose={() => {
                setVisible({ ...visible, addEvent: false })
                resetEvent()
            }}
            open={visible.addEvent}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <AddEventForm event={event} type="collections" drawerAction={drawerAction} />
        </Drawer>
        {/* 添加媒体 */}
        <Drawer
            title='添加媒体'
            width={1170}
            onClose={() => {
                setVisible({ ...visible, addMedias: false })
            }}
            open={visible.addMedias}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <MediaTable
                medias={medias}
                setMedias={setMedias}
                rowOnSelect={(record, selected) => {
                    let tempMedia = { id: record.id }
                    let newMedias = selected ? _.concat(medias, tempMedia) : _.differenceBy(medias, [tempMedia], 'id');
                    setMedias(_.uniqBy(newMedias, 'id'))
                }}
                menu={false}
                simpleMode={true}
                url='/media/getMediaList'
            />
            <Space className="side-drawer">
                <Button onClick={() => { setVisible({ ...visible, addMedias: false }) }}>
                    取消
                </Button>
                <Button onClick={() => { addEventMedias(); setVisible({ ...visible, addMedias: false }); }} type="primary">
                    提交
                </Button>
            </Space>
        </Drawer>
        {/* 查看媒体 */}
        <Drawer
            title='查看媒体'
            width={1170}
            onClose={() => { setVisible({ ...visible, delMedias: false }); }}
            open={visible.delMedias}
        >
            <CollectionDetailTable initialEventId={selectId} />
        </Drawer>
    </>;
}

export default CollectionsTable