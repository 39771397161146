import React from 'react'
import { DeleteTwoTone, EditOutlined } from '@ant-design/icons';
import { Switch, Button, message, Popconfirm, Space } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'
import { resultResponse } from '../Apps/common/ResultResponse';
import { formatTime } from '../utils/TimeUtils'

const axios = require('axios');


export const NoticeTableConfig = ({ handleOrders }) => {
    const role = useRecoilValue(userRoleState)

    const { checkContent, getNotice, reloadTable } = handleOrders
    const size = 'small'

    // 置顶公告
    const pinNotice = async (id, status) => {
        let params = new FormData()
        params.append('id', id)
        params.append('pinned', status)
        await axios({
            method: 'post',
            url: `/notice/edit`,
            data: params
        }).then((res) => {
            resultResponse(res, "已更新")
            reloadTable()
        })
    }

    // 置顶公告
    const delNotice = async (id) => {
        await axios({
            method: 'delete',
            url: `/notice/${id}`
        }).then((res) => {
            resultResponse(res, "已删除")
            reloadTable()
        })
    }

    // 弹窗公告
    const popNotice = async (id, status) => {
        await axios({
            method: 'patch',
            url: `/notice/${id}`,
            data: JSON.stringify({ popup: status }),
            headers: { 'content-type': 'application/json' }
        }).then((res) => {
            resultResponse(res, "已更新")
            reloadTable()
        })
    }


    const noticeBasicCol = [
        {
            title: '公告标题',
            dataIndex: 'title',
            checked: 1,
        }, {
            title: '公告内容',
            dataIndex: 'content',
            checked: 1,
            render: (t, data) => {
                return <Button type='link' size={size} onClick={() => checkContent(t)} style={{ padding: 0 }}>查看详情</Button>
            }
        }, {
            title: '置顶',
            dataIndex: 'pinned',
            checked: 1,
            render: (t, data) => <Switch checkedChildren="置顶" unCheckedChildren="普通" checked={t === 1}
                onChange={() => pinNotice(data.id, (1 - t))} />
        }, {
            title: '弹窗公告',
            dataIndex: 'popup',
            checked: 1,
            render: (t, data) => <Switch checkedChildren="弹窗" unCheckedChildren="普通" checked={t === 1}
                onChange={() => popNotice(data.id, (1 - t))} />
        }, {
            title: '创建时间',
            dataIndex: 'create_time',
            checked: 1,
            render: (t, data) => formatTime(t)
        }, {
            title: '操作',
            dataIndex: 'id',
            checked: 1,
            render: (t, data) => {

                return (
                    <Space>
                        <Button type="link" icon={<EditOutlined />} size='small' onClick={() => getNotice(data.id)} />
                        <Popconfirm
                            title="是否确认删除该活动？"
                            onConfirm={() => delNotice(data.id)}
                            onCancel={() => message.warn('已取消')}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button icon={<DeleteTwoTone />} size={size} type={'link'} style={{ padding: 0 }}>删除</Button>
                        </Popconfirm>
                    </Space>
                )
            }
        },

    ]

    let result = []

    switch (role) {
        default:
            result = _.concat(
                noticeBasicCol
            )
            break;
    }
    return result
}
