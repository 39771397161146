import React, { useState } from 'react'
import { InboxOutlined, AlignLeftOutlined, SnippetsOutlined } from '@ant-design/icons';
import {
    Form, Layout, Steps, Button, message, Row, Col, Input, Upload, Descriptions,
    Card, Tag, Affix, Divider, Typography
} from 'antd';
import qs from 'qs';
import _ from 'lodash';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import MediaIndexTable from './MediaIndexTable';
import Formatter from '../../service/Formatter'
import CKEditorInstance from '../../../utils/CkeditorInstance';
import { resultResponse } from '../../common/ResultResponse';
import { Feedback } from '../../common/CreateCommon'
import PageHeader from '../../layout/PageHeader';

const axios = require('axios');


const { Content } = Layout;
const { Text } = Typography;
const { Step } = Steps;
const { TextArea } = Input;
const { Dragger } = Upload;
const layout = {
    labelCol: { sm: 6, xs: 24 },
    wrapperCol: { md: 12, sm: 12, xs: 24 },
    layout: 'horizontal'
};

const CreateMediaIndex = () => {

    let pageTitle = "创建文字链"

    const [content, setContent] = useState("")
    const [current, setCurrent] = useState(0)
    const [medias, setMedias] = useState([])
    const [order, setOrder] = useState({ title: '', remark: '', url: '' })
    const [feedback, setFeedback] = useState({})
    const [fetching, setFetching] = useState(false)
    const [visible, setVisible] = useState({ preview: false })


    const nextPage = () => setCurrent(current + 1)
    const prevPage = () => setCurrent(current - 1)
    const resetAll = () => {
        setCurrent(0);
        setMedias([])
        setOrder({ title: '', remark: '', url: '' })
        setContent('')
    }


    // ANTD 拖拽上传 docx 配置
    const props = {
        name: 'upload_file',
        multiple: true,
        action: `/article/upload/docx?_r=${Math.random()}`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                let response = info.file.response
                setContent(response.data.content)
                message.success(`${info.file.name} 已成功上传！`);
            } else if (status === 'error') {
                message.error(`${info.file.name} 上传失败，请检查文件格式`);
            }
        },
    };

    // 发布订单
    const sendOrder = async (params = new FormData()) => {
        if (_.size(medias) === 0) { message.error("请选择媒体!"); return; }
        if (order.title === '') { message.error('请填写标题后提交！'); return }
        if (order.url === '' && content.trim() === '') { message.error('请填写内容后提交！'); return; }
        setFetching(true)
        const param = {
            title: order.title,
            remarks: order.remark,
            source_url: order.url,
            content,
            media_datas: JSON.stringify(_.uniqBy(medias, 'id')),
        }
        await axios({
            method: 'post',
            url: '/mediaIndexArticle',
            data: qs.stringify(param)
        }).then((res) => {
            resultResponse(res, '已成功提交')
            setFeedback(res.data)
            setFetching(false)
        })
    }

    // 分布发稿
    const steps = [
        {
            title: '创建稿件',
            content: (
                <div style={{ background: '#fff', padding: '24px', }}>
                    <Form {...layout}>
                        <Row gutter={[12, 12]}>
                            <Col span={24} key='title' >
                                <Form.Item label="订单标题">
                                    <Input placeholder="请输入订单标题" value={order.title}
                                        onChange={e => setOrder({ ...order, title: e.target.value.trim() })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} key='source_url' >
                                <Form.Item label='订单来源'>
                                    <Input placeholder="请输入以https://或者http://开头的订单来源链接" value={order.url}
                                        onChange={e => setOrder({ ...order, url: e.target.value.trim() })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} key='remark' >
                                <Form.Item label='订单备注'>
                                    <TextArea placeholder="请输入订单备注, 如:图片注释居中, 小标题加粗, 注意排版" value={order.remark} rows={3}
                                        onChange={e => setOrder({ ...order, remark: _.trimStart(e.target.value) })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} key='upload'>
                                <Form.Item label='上传文本'>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">点击或者拖拽上传文本，支持doc / docx格式转码</p>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                            <Col span={12} key='format'>
                                <Button.Group>
                                    <Button onClick={() => Formatter({ content, setContent })}><AlignLeftOutlined />自动排版</Button>
                                    <Button onClick={() => setVisible({ ...visible, preview: true })}><SnippetsOutlined />预览</Button>
                                </Button.Group>
                            </Col>
                            <Col span={12} key='next' style={{ textAlign: "right" }}>
                                <Button type="primary" onClick={nextPage}>继续</Button>
                            </Col>
                        </Row>
                    </Form>
                    <div id='create' style={{ padding: '1px 0', marginBottom: '8px' }}>
                        <CKEditorInstance content={content} setContent={setContent} />
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={nextPage}>继续</Button>
                    </div>
                </div>
            ),
        },
        {
            title: '选择媒体', // 传递选择媒体数据，搜索表格数据，是否读取完成数据
            content: (
                <>
                    <MediaIndexTable aciton="CREATE" medias={medias} setMedias={setMedias} />
                    <Affix offsetBottom={10}>
                        <Card title={<div>
                            <Text>已选择{_.size(medias)}个媒体</Text>
                            <Divider type='vertical' />
                            <Text>共计{_.sumBy(medias, 'price')}元</Text>
                        </div>}
                            style={{ marginBottom: '16px' }}>
                            {medias.map(e => {
                                return (
                                    <Tag className="tag-cloud" closable key={e.id} onClose={() => {
                                        setMedias(medias.filter(media => media.id !== e.id))
                                    }}>
                                        {e.name}
                                    </Tag>
                                )
                            })}
                            <Divider />
                            <Row>
                                <Col span={12}>
                                    <Button onClick={prevPage}>
                                        返回
                                    </Button>
                                </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    <Button type="primary" onClick={() => {
                                        if (_.size(medias) === 0) { message.error("请选择媒体!"); return; }
                                        if (order.title === '') { message.error('请填写标题后提交！'); return }
                                        if (order.url === '' && content.trim() === '') { message.error('请填写内容后提交！'); return; }
                                        nextPage()
                                    }}>
                                        继续
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Affix>
                </>
            ),
        },
        {
            title: '确定下单',
            content: (
                <>
                    <Descriptions title="订单信息">
                        <Descriptions.Item label="订单标题" span={3}>{order.title}</Descriptions.Item>
                        <Descriptions.Item label="订单备注" span={3}>{order.remark}</Descriptions.Item>
                        <Descriptions.Item label="订单来源" span={3}>{order.url}</Descriptions.Item>
                        <Descriptions.Item label="总价格" span={3}>{_.sum(medias.map(e => parseInt(e.price)))}</Descriptions.Item>
                    </Descriptions>
                    <Card
                        title={<div>
                            <Text>已选择{_.size(medias)}个媒体</Text>
                            <Divider type='vertical' />
                            <Text>共计{_.sumBy(medias, 'price')}元</Text>
                        </div>}
                        style={{ marginBottom: '16px' }}>
                        {medias.map(e => {
                            return (
                                <Tag className="tag-cloud" closable key={e.id} onClose={() => {
                                    setMedias(medias.filter(media => media.id !== e.id))
                                }}>
                                    {e.name}
                                </Tag>
                            )
                        })}
                    </Card>
                    <Row>
                        <Col span={12}>
                            <Button onClick={prevPage}>
                                返回
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <Button type="primary" onClick={() => {
                                nextPage()
                                sendOrder()
                            }}>
                                提交
                            </Button>
                        </Col>
                    </Row>

                </>
            ),
        }, {
            title: '提交反馈',
            content: <Feedback fetching={fetching} feedback={feedback} resetAll={resetAll} prevPage={prevPage} />,
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}>
                <Steps current={current} size={"small"}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
            </PageHeader>
            <Content>
                <div style={{ margin: '24px' }}>
                    <div className="steps-content">
                        {steps[current].content}
                    </div>
                </div>
            </Content>
        </HelmetProvider>
    )
}

export default CreateMediaIndex