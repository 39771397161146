import React, { useState } from 'react'
import { Form, Input, Radio, Typography } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil'

import { userState } from '../../atoms/Atoms'
const layout = { layout: 'vertical' };

const WithdrawForm = (props) => {
    const sessionUser = useRecoilValue(userState)
    const { withdrawForm } = props;
    const [type, setType] = useState(null)

    return (
        <>
            <Form
                {...layout}
                name="basic"
                form={withdrawForm}
                initialValues={{
                    alipay_id: sessionUser.basic.alipay_id,
                    alipay_name: sessionUser.basic.alipay_name,
                }}
                hideRequiredMark>
                <Form.Item>
                    <Typography.Title level={3}>因公司财务流程调整, 今后每周四统一打款, 望周知</Typography.Title>
                </Form.Item>
                <Form.Item label="提现方式" name='type'>
                    <Radio.Group
                        value={type}
                        onChange={(e) => setType(e.target.value)}>
                        <Radio value={1}>支付宝</Radio>
                        {/* <Radio value={2}>银行卡</Radio> */}
                        <Radio value={3}>转换余额</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    type === 1 &&
                    <>
                        <Form.Item
                            label="支付宝账号"
                            name="alipay_id"
                            rules={[{ required: true, message: '请填写支付宝账号！' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="支付宝名称"
                            name="alipay_name"
                            rules={[{ required: true, message: '请填写支付宝名称！' }]}
                        >
                            <Input />
                        </Form.Item>
                    </>
                }
            </Form>
        </>
    )
}

export default WithdrawForm