import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Row, Col, Typography, Form, Input, InputNumber, DatePicker, message, Layout } from 'antd';
import _ from 'lodash'
import qs from 'qs';
import { PlusOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import { tablePagination } from '../../common/tableCommon';
import CouponTableConfig from '../../../role/coupon/CouponTableConfig';
import MediaTable from '../medias/MediaTable';
import { resultResponse } from '../../common/ResultResponse';

const axios = require('axios');
const { Title } = Typography;
const { Content } = Layout

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const colLayout = {
    xs: 24, sm: 12,
    md: 12, lg: 8,
    xl: 8, xxl: 6,
}

const CouponsTable = ({
    coupons = [],
    setCoupons = () => console.log('empty'),
    rowOnChange = (selectedRowKeys, selectedRows) => {
        setCoupons(selectedRows.map(e => {
            return {
                id: e.id,
                coupon_name: e.coupon_name,
            }
        }))
    },
    miniMode = false
}) => {
    const role = useRecoilValue(userRoleState)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [count, setCount] = useState(0)
    const [modal, setModal] = useState({ create: false, select: false, id: undefined, medias: false })
    const [mediasLoading, setMediasLoading] = useState(false)
    const [medias, setMedias] = useState([])
    const [mediaDatas, setMediaDatas] = useState([])
    const reloadTable = () => setCount(count + 1)
    const resetModal = () => setModal({ create: false, select: false, id: undefined, medias: false })

    const fetchData = async (val) => {
        let params = new FormData()
        setLoading(true)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        _.toPairs(val).filter(e => e[1] !== '').map(e => params.append(`${e[0]}`, e[1]))
        role === 'CUSTOMER' && params.append("is_expired", 'no')
        await axios({
            method: 'post',
            url: '/coupon/all',
            data: params
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
            let total = res.data.total
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }


    useEffect(() => {
        fetchData()
    }, [pagination.current, pagination.pageSize, count,])

    const createCoupon = async (params) => {
        await axios({
            method: 'post',
            url: '/coupon/create',
            dataType: 'json',
            data: qs.stringify({ ...params, is_enabled: 1 })
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功添加")
                reloadTable()
                resetModal()
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const buyCoupon = async (id) => {
        await axios({
            method: 'post',
            url: `/coupon/buy/${id}`,
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功购买")
                reloadTable()
            } else if (res.data.code === 90002) {
                message.warning("以达到当前用户等级限购数量")
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const deleteCoupon = async (id) => {
        await axios({
            method: 'post',
            url: `/coupon/delete/${id}`,
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功删除")
                reloadTable()
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const selectMedias = (id) => {
        setMedias([])
        setModal({ ...modal, select: true, id })
    }

    const bindMediaCoupon = async (params = new FormData()) => {
        params.append("id", modal.id)
        params.append("ids", medias.map(e => e.id))
        await axios({
            method: 'post',
            url: "/coupon/bind",
            data: params
        }).then((res) => {
            resultResponse(res, "已成功绑定")
            reloadTable()
            resetModal()
        })
    }

    const checkDetail = async (id) => {
        setMediasLoading(true)
        setModal({ ...modal, medias: true })
        await axios({
            method: 'post',
            url: `/coupon/detail/${id}`,
        }).then((res) => {
            if (res.data.code === 1) {
                setMediaDatas(res.data.data)
            } else {
                message.warning(res.data.msg)
            }
        })
        setMediasLoading(false)
    }
    const handleMedia = { deleteCoupon, selectMedias, reloadTable, checkDetail, buyCoupon }
    const columns = CouponTableConfig({ handleMedia, miniMode })

    function customizer(objValue, srcValue) {
        return _.isUndefined(srcValue) ? "" : srcValue;
    }

    return (
        <>
            {!miniMode &&
                <Content className='table-search'>
                    <Form onFinish={val => fetchData(_.assignInWith([], val, customizer))}>
                        <Row gutter={[24, 24]}>
                            <Col {...colLayout}>
                                <Form.Item label="优惠券名称" name="coupon_name">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col {...colLayout}>
                                <Form.Item label="媒体搜索" name="media_name">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                {
                                    role === 'OPERATOR' &&
                                    <Button type="primary" onClick={() => setModal({ ...modal, create: true })}><PlusOutlined />创建</Button>
                                }
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Button type="primary" htmlType="submit">
                                    搜索
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Content>
            }
            <Content className='table-search'>
                <Table
                    size='middle'
                    title={() => (
                        <>
                            <Row>
                                <Col span={12}>
                                    <Title level={4}>优惠券列表</Title>
                                </Col>
                            </Row>
                        </>
                    )}
                    columns={columns}
                    rowSelection={{
                        selectedRowKeys: coupons.map(e => e.id),
                        onChange: rowOnChange,
                    }}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                    }}
                />
            </Content>

            <Modal title="选择媒体" open={modal.select} footer={null} onCancel={resetModal} width={1500}>
                <MediaTable
                    medias={medias}
                    setMedias={setMedias}
                    rowOnSelect={(record, selected) => {
                        let tempMedia = { id: record.id }
                        let newMedias = selected ? _.concat(medias, tempMedia) : _.differenceBy(medias, [tempMedia], 'id');
                        setMedias(_.uniqBy(newMedias, 'id'))
                    }}
                    simpleMode={true}
                />
                <Button onClick={() => bindMediaCoupon()}>提交</Button>
            </Modal>
            <Modal title="创建优惠券" open={modal.create} footer={null} onCancel={resetModal}>
                <Form
                    name="add-coupon" {...formItemLayout}
                    onFinish={(val) => {
                        const params = {
                            ...val, end_time_string: val['end_time_string'].format('YYYY-MM-DD')
                        }
                        createCoupon(params)
                    }}
                >
                    <Form.Item label="名称" name="coupon_name">
                        <Input />
                    </Form.Item>
                    <Form.Item label="描述" name="coupon_description">
                        <Input />
                    </Form.Item>
                    <Form.Item label="失效时间" name="end_time_string">
                        <DatePicker />
                    </Form.Item>
                    <Form.Item label="抵用金额" name="money">
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item label="售价" name="price">
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item label="会员售价" name="price_vip">
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item label="会员限购数量" name="max_num_vip">
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="查看媒体" open={modal.medias} footer={null} onCancel={resetModal}>
                <Table
                    size='middle'
                    rowKey={record => record.id}
                    loading={mediasLoading}
                    columns={[
                        {
                            title: '名称',
                            dataIndex: 'name',
                        }, {
                            title: '抵用金额',
                            dataIndex: 'money',
                        }
                    ]}
                    dataSource={mediaDatas}
                />
            </Modal>
        </>
    )
}

export default CouponsTable