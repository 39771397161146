import React from 'react'
import { Tag, Button, Typography, Space } from 'antd';
import _ from 'lodash'
import { UnorderedListOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const { Text } = Typography
export const WithdrawManageTableConfig = ({ handleOrders, modal, setModal }) => {
    const role = useRecoilValue(userRoleState)

    const { confirmOrder, } = handleOrders;
    const size = 'small'

    const consumeType = [
        {
            title: '消费类型',
            dataIndex: 'type',
            checked: 1,
        },
        {
            title: '支付宝账号',
            dataIndex: 'alipay_id',
            checked: 1,
            render: (t, data) => {
                return (<>
                    <Text>{t}</Text>
                    <br></br>
                    <Text>{data.alipay_name}</Text>
                </>)
            }
        },
    ]

    const consumeMoney = [
        {
            title: '申请金额',
            dataIndex: 'money',
            checked: 1,
        },
        {
            title: '申请人',
            dataIndex: 'create_name',
            checked: 1,
        },
    ]

    const applyTime = [
        {
            title: '申请时间',
            dataIndex: 'create_time',
            checked: 1,
        },
        {
            title: '结算时间',
            dataIndex: 'end_time',
            checked: 1,
        },
    ]


    const withdrawState = [
        {
            title: '申请状态',
            dataIndex: 'state',
            checked: 1,
            render: (t, data) => {
                if (data.amount === 0) {
                    return <Tag style={{ margin: 0 }} color="red">申请异常</Tag>
                }
                if (data.state === 'FAILED') {
                    return <Tag style={{ margin: 0 }} color="red">已拒绝</Tag>
                } else if (data.state === 'PAID') {
                    return <Tag style={{ margin: 0 }} color="green">已结算</Tag>
                } else if (data.state === 'PENDING') {
                    return <Tag style={{ margin: 0 }} color="blue">处理中</Tag>
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'id',
            checked: 1,
            render: (t, data) => {
                return (
                    <Space direction='vertical'>
                        {
                            _.indexOf([1, 3], data.type_id) !== -1 &&
                            <Space>
                                <Text code>订单数量: {data.amount}</Text>
                                <Text code>无效数量: {data.invalid}</Text>
                            </Space>
                        }
                        <Space>
                            <Button
                                size={size}
                                icon={<UnorderedListOutlined />}
                                onClick={() => {
                                    setModal({ ...modal, detail: true, target_id: data.id, type: data.withdraw_type })
                                }}>查看详情</Button>
                            {
                                (data.state === 'PENDING' && role === 'FINANCE')
                                && <Space>
                                    <Button type="primary" size={size} onClick={() => confirmOrder(data.id, "PAID")}>通过</Button>
                                    <Button danger size={size} onClick={() => confirmOrder(data.id, "FAILED")}>拒绝</Button>
                                </Space>
                            }
                        </Space>
                    </Space>
                )
            }
        },
    ]

    let result = []

    switch (role) {
        case "CUSTOMER":
        case "OPERATOR_ADMIN":
            result = _.concat(
                applyTime,
                consumeType,
                consumeMoney,
                withdrawState
            )
            break;
        case "FINANCE":
            result = _.concat(
                applyTime,
                consumeType,
                consumeMoney,
                withdrawState
            )
            break;
        default:
            break;
    }
    return result
}
