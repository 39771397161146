
const WbApiArea = [
    { id: "0", text: "随机地区" },
    { id: "11", text: "北京" },
    { id: "31", text: "上海" },
    { id: "50", text: "重庆" },
    { id: "12", text: "天津" },
    { id: "34", text: "安徽" },
    { id: "44", text: "广东" },
    { id: "35", text: "福建" },
    { id: "46", text: "海南" },
    { id: "13", text: "河北" },
    { id: "41", text: "河南" },
    { id: "23", text: "黑龙江" },
    { id: "42", text: "湖北" },
    { id: "43", text: "湖南" },
    { id: "62", text: "甘肃" },
    { id: "45", text: "广西" },
    { id: "52", text: "贵州" },
    { id: "22", text: "吉林" },
    { id: "36", text: "江西" },
    { id: "32", text: "江苏" },
    { id: "21", text: "辽宁" },
    { id: "15", text: "内蒙古" },
    { id: "64", text: "宁夏" },
    { id: "63", text: "青海" },
    { id: "37", text: "山东" },
    { id: "14", text: "山西" },
    { id: "61", text: "陕西" },
    { id: "51", text: "四川" },
    { id: "54", text: "西藏" },
    { id: "65", text: "新疆" },
    { id: "53", text: "云南" },
    { id: "33", text: "浙江" },
    { id: "71", text: "台湾" },
    { id: "81", text: "香港" },
    { id: "82", text: "澳门" },
    { id: "400", text: "海外" }
]

export default WbApiArea