import _ from 'lodash'

export const XIAOHONGSHU = {
    DEFAULT: {
        id: 40001,
        name: '默认',
        idx: 1
    }
}

export const getPrice = price => {
    let prices = _.split(price, ',')
    return _.reduce(prices, (result, price) => {
        let tmp = _.split(price, '_')
        return _.assign(result, { [tmp[0]]: tmp[1] })
    }, {})
}