export const QaEntity = {
    id: '',
    name: '',
    sample_url: '',
    cost: '',
    sale_price: '',
    agent_price: '',
    comment: '',
    category: '',
    platform: '',
}