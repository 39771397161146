import React, { lazy, Suspense } from 'react';
import { Route, Routes, } from 'react-router-dom'
import { RecoilRoot } from 'recoil';
import { ConfigProvider, Spin } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

import HomeRoutesConfig from './Apps/compenents/route/HomeRoutesConfig';
import { AuthProvider } from './utils/AuthUser'
import AuthorizedRoute from './utils/AuthorizedRoute'
import MainLayout from './Apps/layout/MainLayout'

import './App.css';

const Home = lazy(() => import('./Apps/compenents/home/Home'));
const Article = lazy(() => import('./Apps/compenents/articles/Article'));
const SignUp = lazy(() => import('./Apps/compenents/home/SignUp'));

const App = () => (
  <ConfigProvider locale={zhCN}>
    <RecoilRoot>
      <Suspense fallback={<FullSpin />}>
        <AuthProvider>
          <Routes>
            <Route index element={<Home />} />
            <Route path='p/:id' element={<Article />} />
            {
              HomeRoutesConfig.map(({ path, component }, key) =>
                <Route path={path} element={component} key={path} />)
            }
            <Route path='signup' element={<SignUp />} />
            <Route path='dashboard/*' element={
              <AuthorizedRoute>
                <MainLayout />
              </AuthorizedRoute>
            } />
          </Routes>
        </AuthProvider>
      </Suspense>
    </RecoilRoot>
  </ConfigProvider>
)

export default App;

function FullSpin() {
  return <div style={{ height: '100%', width: '100%', display: 'grid', placeContent: 'center' }}><Spin /></div>
}