import React, { useState, useEffect } from 'react';
import { Table, Button, Row, Col, Modal, Tabs, Input, Card, Radio, Layout, Form, Typography, InputNumber, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs';
import qs from 'qs';

import { BaikeOrderTableConfig } from '../../../role/baike/BaikeOrderTableConfig'
import BaikeOrderSearchConfig from '../../../role/baike/BaikeOrderSearchConfig'
import SearchForm from '../../common/SearchForm'
import { TimeSearchButtonGroup, initFormData, tablePagination } from '../../common/tableCommon';
import { resultResponse } from '../../common/ResultResponse';
import RoleModule from '../../../utils/permission/RoleModule';

const axios = require('axios');
const { Content } = Layout;
const { Text, Paragraph } = Typography;

const { TextArea } = Input;

const initialStatus = { baike_status: '', status: '', }
const initialModal = {
    target_id: undefined,
    verifyFailed: false,
    publishSuccess: false,
    publishOrderFailed: false,
    updateUrl: false,
    unconfirm: false,
    order: {}
}

const BaikeOrderTable = ({ searchFormDisplay = true }) => {

    const searchDatas = BaikeOrderSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState('1')
    const [pagination, setPagination] = useState(tablePagination)
    const [status, setStatus] = useState(initialStatus)
    const [modal, setModal] = useState(initialModal)
    const [rowSelected, setRowSelected] = useState({ keys: [], rows: [] })
    const [text, setText] = useState("")
    const [replyTab, setReplyTab] = useState(0)
    const [money, setMoney] = useState(0)

    const [form] = Form.useForm();

    const reloadTable = () => form.submit();
    const resetModal = () => { setModal(initialModal); setText("") } // 重置弹出框 以及 文本框

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        setLoading(true)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        _.toPairs(status).filter(e => e[1] !== '').map(e => params.append(`${e[0]}`, e[1]))
        await axios({
            method: 'post',
            url: '/baike/order/myList',
            data: params
        }, {
            cancelToken: source.token
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
            let total = res.data.total
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    // 订单审核通过
    const verifyOrderSuccess = async (id) => {
        await axios({
            method: 'post',
            url: "/baike/order/verify",
            data: qs.stringify({
                ids: id === undefined ? _.join(rowSelected.keys, ',') : id,
                status: "waitPublish"
            })
        }).then(res => {
            resultResponse(res, '已通过审核')
            reloadTable()
        })
    }
    // 订单审核通过
    const verifyOrderUnconfirm = async (id, info) => {
        await axios({
            method: 'post',
            url: "/baike/order/verify",
            data: qs.stringify({
                ids: id === undefined ? _.join(rowSelected.keys, ',') : id,
                status: "unconfirmed",
                reason: info,
                money
            })
        }).then(res => {
            resultResponse(res, '已提交')
            reloadTable()
        })
    }
    // 订单审核不通过
    const verifyOrderFailed = async (id, info) => {
        await axios({
            method: 'post',
            url: "/baike/order/verify",
            data: qs.stringify({
                ids: id === undefined ? _.join(rowSelected.keys, ',') : id,
                status: "notPass",
                reason: info
            })
        }).then(res => {
            resultResponse(res, '未通过审核')
            reloadTable()
        })
    }
    // 订单发布回执
    const publishOrderSuccess = async (id, info) => {
        await axios({
            method: 'post',
            url: "/baike/order/publish",
            data: qs.stringify({
                ids: id,
                status: "success",
                reason: info
            })
        }).then(res => {
            resultResponse(res, '已发布回执')
            reloadTable()
        })
    }
    // 订单退稿回执
    const publishOrderFailed = async (id, info) => {
        await axios({
            method: 'post',
            url: "/baike/order/publish",
            data: qs.stringify({
                ids: id,
                status: "back",
                reason: info
            })
        }).then(res => {
            resultResponse(res, '已退稿')
            reloadTable()
        })
    }

    // 订单结算状态修改
    const updatePayinfo = async (id, status) => {
        await axios({
            method: 'post',
            url: "/baike/order/pay",
            data: qs.stringify({
                ids: id === undefined ? _.join(rowSelected.keys, ',') : id,
                paid_status: status === 'paid' ? 'unpaid' : 'paid'
            })
        }).then(res => {
            resultResponse(res, '已修改')
            reloadTable()
        })
    }

    // 待审核 订单 撤回
    const cancelOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/baike/order/cancel",
            data: qs.stringify({ id: id })
        }).then(res => {
            resultResponse(res, '已撤回')
            reloadTable()
        })
    }
    // 待确认订单 确认
    const confirmOrder = async (id, status) => {
        await axios({
            method: 'post',
            url: "/baike/order/confirm",
            data: qs.stringify({ id: id, status, })
        }).then(res => {
            resultResponse(res, '已确认')
            reloadTable()
            setModal({ ...modal, unconfirm: false })
        })
    }
    // 订单操作函数
    const handleOrders = {
        verifyOrderSuccess, updatePayinfo, cancelOrder, reloadTable,
        verifyOrderUnconfirm, confirmOrder,
    }

    const rowSelection = {
        selectedRowKeys: rowSelected.keys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowSelected({ keys: selectedRowKeys, rows: selectedRows })
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
        selections: rowSelected.rows
    };

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    const filterMediaStatus = (e) => {
        setTab(e)
        setRowSelected({ keys: [], rows: [] })
        switch (e) {
            case '2':
                setStatus({ status: 'WAIT_VERIFY' })
                break
            case '3':
                setStatus({ status: 'UNCONFIRMED' })
                break
            case '4':
                setStatus({ status: 'WAIT_PUBLISH' })
                break
            case '5':
                setStatus({ status: 'SUCCESS' })
                break
            case '6':
                setStatus({ status: 'BACK' })
                break
            default:
                setStatus({ status: '' })
        }
        form.submit()
    }

    const columns = BaikeOrderTableConfig({ modal, setModal, handleOrders, tab })

    return <>
        <Space direction='vertical' size={16} className='full-width'>
            <Content className={!searchFormDisplay && 'hidden'}>
                <Card title='媒体搜索'>
                    {
                        searchFormDisplay &&
                        <Form
                            layout='vertical'
                            name="basic"
                            form={form}
                            initialValues={initialFormData}
                            onFinish={val => fetchData(_.pickBy(val, _.identity))}
                        >
                            <SearchForm searchDatas={searchDatas} />
                            <Row>
                                <Col span={12} >
                                    <RoleModule action='module:baike:order:toolbar' >
                                        {
                                            tab === '2' &&
                                            <Button.Group>
                                                <Button type="primary" onClick={() => verifyOrderSuccess(modal.target_id)}>批量通过</Button>
                                                <Button danger onClick={() => setModal({ ...modal, verifyFailed: true })}>批量不通过</Button>
                                            </Button.Group>
                                        }
                                    </RoleModule>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Space>
                                        <TimeSearchButtonGroup form={form} />
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >搜索</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    }
                </Card>
            </Content>
            <Card>
                <Tabs defaultActiveKey="1" onChange={filterMediaStatus}
                    items={[
                        { key: '1', label: `全部稿件`, },
                        { key: '2', label: `待审核`, },
                        { key: '3', label: `待确认`, },
                        { key: '4', label: `待发布`, },
                        { key: '5', label: `已完成`, },
                        { key: '6', label: `已退稿`, },
                    ]}
                />
                <Table
                    size="middle"
                    columns={columns}
                    rowSelection={rowSelection}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => { setPagination(pagination); form.submit() }}
                />
            </Card>
        </Space>

        <Modal title="拒稿理由" open={modal.verifyFailed}
            onOk={() => {
                if (replyTab === 1) {
                    verifyOrderFailed(modal.target_id, text)
                } else if (replyTab === 2) {
                    verifyOrderUnconfirm(modal.target_id, text)
                }
                resetModal()
            }}
            onCancel={() => { resetModal() }}>
            <Radio.Group onChange={e => setReplyTab(e.target.value)} style={{ marginBottom: '16px' }}>
                <Radio value={1}>拒稿</Radio>
                <Radio value={2}>议价</Radio>
            </Radio.Group>
            {replyTab === 2 && <InputNumber min={0} onChange={e => setMoney(e)} />}
            {replyTab > 0 && <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />}
        </Modal>
        <Modal title="填写回执" open={modal.publishSuccess}
            onOk={() => {
                publishOrderSuccess(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => { resetModal() }}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>
        <Modal title="退稿理由" open={modal.publishOrderFailed}
            onOk={() => {
                publishOrderFailed(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => { resetModal() }}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>
        <Modal
            title='订单详情'
            open={modal.unconfirm}
            footer={null}
            onOk={() => setModal({ ...modal, unconfirm: false })}
            onCancel={() => setModal({ ...modal, unconfirm: false })}
        >
            <div style={{ textAlign: 'center' }}>
                <Paragraph>理由: <Text strong>{modal.order.reason}</Text></Paragraph>
                <Paragraph>需要再支付<Text strong>{modal.order.add_money}</Text>元</Paragraph>
                <Paragraph>总计共支付<Text strong>{modal.order.add_money + modal.order.paid_money}</Text>元</Paragraph>
                <Row gutter={16}>
                    <Col span={12}>
                        <Button type='primary' onClick={() => confirmOrder(modal.target_id, "waitPublish")}>接受</Button>
                    </Col>
                    <Col span={12}>
                        <Button danger onClick={() => confirmOrder(modal.target_id, "back")}>拒绝</Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    </>;
}
export default BaikeOrderTable