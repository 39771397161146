import React from 'react'
import { Typography, Card } from 'antd';

const { Paragraph } = Typography;


export const InvoiceText = () => {
    return <Card>
        <Paragraph>
            <ul>
                <li>
                    后付费月结算单的可开票金额为当月实际结算金额。
                </li>
                <li>
                    本月产生的后付费业务月结算单在下个月二号以后才可以索取发票。
                </li>
                <li>
                    发票基于订单、月结算单开具（单个订单、月结算单不可拆分开票）。
                </li>
                <li>
                    申请纸质增值税普通发票，如金额未满200元，快递费用到付需自行承担。
                </li>
            </ul>
        </Paragraph>
    </Card>
}

export const defaultInvoice = {
    money: 0, name: '',
    address: '', tel: '',
    remark: '', consume_ids: []
}

export const defaultInvoiceInfo = {
    type: 2, title: '',
    invoice_type: 1, licence_id: '',
    bank_name: '', bank_id: '',
    address: '', tel: '',
}