import React from 'react'
import { UnorderedListOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Tag, Typography, Button, Popconfirm, Space, Tooltip, } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';
import { Link, useNavigate } from "react-router-dom";


import { userRoleState } from '../../Apps/atoms/Atoms'
import { formatTime } from '../../utils/TimeUtils'
import { check } from '../../utils/permission/rules';

const { Text } = Typography

export const ProjectTableConfig = (props) => {

    const {
        handleOrders, // functions
        action, // 操作类型
    } = props

    const {
        updateProjectName,
        deleteProject,
        modify,
    } = handleOrders;

    const role = useRecoilValue(userRoleState)
    const permission = {
        'module:media:project:modify': check(role, 'module:media:project:modify', null),
    }

    const defaultConfig = [
        {
            title: '项目标题',
            dataIndex: 'proj_name',
            checked: 1,
            render: (val, data) => {
                return <Text editable={{
                    onChange: e => updateProjectName(data.id, e.trim())
                }}>{val}</Text>

            }
        }, {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        }, {
            title: '项目状态',
            dataIndex: 'status',
            checked: 1,
            render: (val, data) => {
                let obj = {}
                switch (val) {
                    case 'PROCESSING':
                        obj = { color: 'green', text: '处理中' }
                        break;
                    case 'MONITORING':
                        obj = { color: 'orange', text: '抓取中' }
                        break;
                    case 'ACHIEVED':
                        obj = { color: 'geekblue', text: '已归档' }
                        break;
                    default:
                        break
                }
                return <Tag style={{ margin: 0 }} color={obj?.color}>
                    {obj?.text}
                </Tag>
            }
        }, {
            title: '订单数量',
            dataIndex: 'num',
            checked: 1,
            render: (val, data) => {
                return <Text code>订单数量: {data.num}</Text>
            }
        }, {
            title: '项目详情',
            dataIndex: 'id',
            checked: 1,
            render: (val, data) => {
                return <Link to={`${data.id}`} state={{ pid: data.id, status: data.status }} >
                    <Button icon={<UnorderedListOutlined />} type='link'>
                        查看详情
                    </Button>
                </Link>
            }
        },
    ]


    const handler = {
        title: '操作',
        dataIndex: 'id',
        width: 160,
        checked: 1,
        render: (val, data) => {
            return <Space>
                {
                    action === 'MANAGE'
                        ? <>
                            <Popconfirm
                                title="是否确认删除项目"
                                onConfirm={() => deleteProject(data.id)}
                                okText="确认"
                                cancelText="取消"
                            >
                                <Button type="link" icon={<DeleteOutlined />} size='small' >
                                    删除
                                </Button>
                            </Popconfirm>
                            {
                                data.status === 'PROCESSING' &&
                                <Tooltip title="项目内稿件成功发布后，自动抓取入口以及页面截图。">
                                    <Button type='link' size='small'>
                                        抓取
                                    </Button>
                                </Tooltip>
                            }
                            {
                                data.status === 'MONITORING' &&
                                <Tooltip title="项目归档，不再抓取截图">
                                    <Button type='primary' size='small'>
                                        完成
                                    </Button>
                                </Tooltip>
                            }
                            {
                                data.status === 'ACHIEVED' &&
                                <Tooltip title="恢复已归档项目，可重新进行自动抓取监听。">
                                    <Button type='primary' size='small'>
                                        恢复
                                    </Button>
                                </Tooltip>
                            }
                        </>
                        : <>
                            {
                                permission['module:media:project:modify'] &&
                                <Button type="link" icon={<EditOutlined />} size='small' onClick={() => modify(data.id, data.proj_name)}>
                                    修改
                                </Button>
                            }
                        </>
                }
            </Space>
        }
    }

    return _.concat(
        defaultConfig,
        handler,
    )
}