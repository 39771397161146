import React from 'react';
import { Row, Col, Input, DatePicker, Radio, Form, Space, Select } from 'antd';

const { Option } = Select;

export const SearchFormHori = ({ searchDatas, reloadTable }) => {
    const colLayout = { span: 24 }

    return <Row gutter={16}>
        {
            searchDatas.map(data => {
                switch (data.type) {
                    case 'select':
                        return <Col {...colLayout} key={data.name} >
                            <Form.Item label={data.label} name={data.name}>
                                {
                                    data.mode
                                        ? <Select mode={data.mode} optionFilterProp="name">
                                            {data.children?.map(e => <Option key={e.id} name={e.name} value={e.id}>{e.name}</Option>)}
                                        </Select>
                                        : <Radio.Group buttonStyle="solid" onChange={e => reloadTable()} size='small'>
                                            {data.children.map(e => <Radio.Button className="borderless-radio-button" key={e} value={e}>{e || '不限'}</Radio.Button>)}
                                        </Radio.Group>
                                }

                            </Form.Item>
                        </Col>
                    case 'radio':
                        return <Col {...colLayout} key={data.name} >
                            <Form.Item label={data.label} name={data.name}>
                                <Radio.Group buttonStyle="solid" onChange={e => reloadTable()} size='small'>
                                    {data.children.map(e => <Radio.Button className="borderless-radio-button" key={e} value={e[1]}>{e[0]}</Radio.Button>)}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    default:
                        break;
                }
                return null;
            })
        }
    </Row>
}

export const SearchFormInput = ({ searchDatas, reloadTable }) => {
    const colLayout = { span: 12 }
    return <Row gutter={16}>
        {
            searchDatas.map(data => {
                switch (data.type) {
                    case 'input':
                        return <Col {...colLayout} key={data.name} >
                            <Form.Item label={data.label} name={data.name}                            >
                                <Input placeholder={data.placeholder} />
                            </Form.Item>
                        </Col>
                    case 'range':
                        let datas = data.datas
                        return <Col {...colLayout} key={data.name} >
                            <Form.Item label={data.label}>
                                <Input.Group compact>
                                    <Form.Item name={datas[0].name} noStyle>
                                        <Input style={{ width: 'calc(50% - 15px)' }} placeholder={datas[0].placeholder} />
                                    </Form.Item>
                                    <Input className='range-connector' placeholder="-" disabled />
                                    <Form.Item name={datas[1].name} noStyle>
                                        <Input style={{ width: 'calc(50% - 15px)', borderLeft: 0 }} placeholder={datas[1].placeholder} />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                    case 'datepicker':
                        return <Col {...colLayout} key={data.name} className='time-filter' >
                            <Form.Item label={data.label} name={data.name}>
                                <DatePicker style={{ width: '100%' }} placeholder={data.placeholder} />
                            </Form.Item>
                        </Col>
                    default:
                        break;
                }
                return null;
            })
        }
    </Row>
}