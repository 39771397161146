import React, { useState } from 'react';
import { Menu, Badge } from 'antd';
import {
    UserOutlined, DatabaseOutlined, UnorderedListOutlined,
    AppstoreOutlined, IdcardOutlined, BankOutlined, HomeOutlined
} from '@ant-design/icons';
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil';

import { userRoleState, sidebarCollapsed } from '../atoms/Atoms'
import LayoutConfig from '../layout/LayoutConifg'
import ServiceQQ from '../compenents/dashboard/ServiceQQ';
import RoleModule from '../../utils/permission/RoleModule';

const { SubMenu } = Menu;
const {
    UserLayoutConfig,
    OrderLayoutConfig,
    CustomerLayoutConfig,
    MediaLayoutConfig,
    ManageLayoutConfig,
    FinanceLayoutConfig,
} = LayoutConfig;

const Sidebar = () => {

    const defaultOpenKeys = 'defaultOpenKeys'
    const defaultKeys = _.isNil(localStorage.getItem(defaultOpenKeys))
        ? ['order-center', 'resource-center', 'manage-center', 'myResource']
        : JSON.parse(localStorage.getItem(defaultOpenKeys))
    const [openKeys, setOpenKeys] = useState(defaultKeys)


    const role = useRecoilValue(userRoleState)
    const collapsed = useRecoilValue(sidebarCollapsed)
    const configList = [
        {
            title: '个人中心',
            key: 'user-center',
            data: UserLayoutConfig(),
            rule: true,
            icon: <UserOutlined />
        }, {
            title: '订单中心',
            key: 'order-center',
            data: OrderLayoutConfig(),
            rule: true,
            icon: <UnorderedListOutlined />
        }, {
            title: '资源中心',
            key: 'resource-center',
            data: MediaLayoutConfig(),
            rule: true,
            icon: <AppstoreOutlined />
        }, {
            title: '管理中心',
            key: 'manage-center',
            data: ManageLayoutConfig(),
            rule: true,
            icon: <IdcardOutlined />
        }, {
            title: '财务中心',
            key: 'finance-center',
            data: FinanceLayoutConfig(),
            rule: true,
            icon: <BankOutlined />
        }, {
            title: '我的资源',
            key: 'myResource',
            data: CustomerLayoutConfig(),
            rule: role === 'CUSTOMER' || role === 'SALE_ATTACHE',
            icon: <DatabaseOutlined />
        },
    ]

    const configMenuList = _.reduce(_.filter(configList, cfg => cfg.rule && _.size(cfg.data) > 0), (result, e) => {
        return _.concat(result, {
            label: e.title,
            key: e.key,
            icon: e.icon,
            children: _.reduce(e.data, (result, d) => _.concat(result, {
                label: <Link to={d.url}>
                    {d.title}
                    {Boolean(d.value) && <Badge dot={true} />}
                </Link>,
                key: d.key,
                disabled: d.disabled
            }), []),
        })
    }, [])

    const menuItems = _.concat([
        {
            label: <Link to='/dashboard'>工作台</Link>,
            key: 'dashboard',
            icon: <HomeOutlined />
        },
    ], configMenuList)

    return <>
        <Menu
            mode="inline"
            defaultSelectedKeys={['0']}
            defaultOpenKeys={openKeys}
            onOpenChange={openKeys => {
                setOpenKeys(openKeys)
                localStorage.setItem(defaultOpenKeys, JSON.stringify(openKeys))
            }}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
        />
        {/* {
                !collapsed &&
                <RoleModule action='module:sidebar:qq'>
                    <ServiceQQ />
                </RoleModule>
            } */}
    </>
}

export default Sidebar