import _ from 'lodash'

const isEditor = (action) => action && action === 'EDIT'

const customer_rules = {
    CUSTOMER: {
        static: [
            'page:dashboard',
            'page:user:info',
            'page:user:consume',
            'page:user:recharge',
            'page:invoice:info',
            'page:invoice:apply',
            'page:coupon:center',
            'page:event:center',
            'page:mission:center',
            'page:media:order:add',
            'page:baike:order:add',
            'page:index:order:add',
            'page:qa:order:add',
            'page:media:order:list',
            'page:media:project:list',
            'page:media:project:detail',
            'page:media:list',
            'page:media:collection',
            'page:resource:media',
            'page:resource:order',
            'page:resource:weibo:hot',
            'page:resource:wechat',
            'page:withdraw:apply',
            'page:weibo:list',
            'page:weibo:order:add',
            'page:weibo:order:list',
            'page:wechat:list',
            'page:wechat:order:add',
            'page:wechat:order:list',
            'page:xiaohongshu:list',
            'page:xiaohongshu:order:add',
            'page:xiaohongshu:order:list',
            'page:media:blacklist',
            'tab:media:order',
            'tab:index:order',
            'tab:baike:order',
            'tab:qa:order',
            'tab:weibo:order',
            'module:dashboard:navbar',
            'module:dashboard:order:list',
            'module:dashboard:popup',
            'module:dashboard:balance',
            'module:header:recharge',
            'module:header:vip',
            'module:sidebar:qq',
            'module:invoice:unpaid:pay',
            'module:baike:order:money:paid',
            'module:user:wechat:unique_key',
            'module:user:api',
            'module:media:project:modify',

        ],
        dynamic: {
            'page:order:sendorder': ({ user }) => user?.sale_group && user?.sale_group?.vip !== 1 && user?.role?.attache === 3,
            'module:media:export': ({ action }) => !isEditor(action),
            'module:media:add': ({ action }) => isEditor(action),
            'module:media:order:toolbar': ({ action }) => isEditor(action),
            'module:media:time:modify': ({ action }) => isEditor(action),
            'module:media:status': ({ action }) => isEditor(action),
            'module:media:edit': ({ action }) => isEditor(action),
            'module:media:money:cost': ({ action }) => isEditor(action),
            'module:media:money:user': ({ action }) => !isEditor(action),

            'module:media:order:money:cost': ({ action }) => isEditor(action),
            'module:media:order:money:paid': ({ action }) => !isEditor(action),
            'module:media:order:recall:handle': ({ action }) => isEditor(action),
            'module:media:order:error:handle': ({ action }) => isEditor(action),
            'module:media:order:success:handle': ({ action }) => isEditor(action),
            'module:media:order:success:error': ({ action }) => !isEditor(action),
            'module:media:order:publish:handle': ({ action }) => isEditor(action),
            'module:media:order:publish:recall': ({ action }) => !isEditor(action),
            'module:media:order:verify:handle': ({ action }) => isEditor(action),
            'module:media:order:verify:cancel': ({ action }) => !isEditor(action),
            'module:weibo:money:cost': ({ action }) => isEditor(action),
            'module:weibo:money:user': ({ action }) => !isEditor(action),
            'module:wechat:money:cost': ({ action }) => isEditor(action),
            'module:wechat:money:user': ({ action }) => !isEditor(action),
            'module:media:time_limit': ({ action }) => isEditor(action),

        }
    }
}

const sales_rules = {
    SALE_LEADER: {
        inherits: 'SALE_ATTACHE',
        static: [
            'module:invoice:unpaid:post:pay',
            'module:media:order:sales:name',
            'module:user:add:attache',
            'module:salegroup:add:sales:attache',
        ],
    },
    SALE_ATTACHE: {
        static: [
            'page:dashboard',
            'page:user:info',
            'page:media:order:list',
            'page:media:project:list',
            'page:media:project:detail',
            'page:order:workorder',
            'page:order:workorder:invoice:apply',
            'page:order:earnorder',
            'page:media:list',
            'page:media:price',
            'page:notice:center',
            'page:user:list',
            'page:user:loan:manage',
            'page:weibo:order:list',
            'tab:media:order',
            'tab:baike:order',
            'tab:index:order',
            'tab:qa:order',
            'module:media:whitelist',
            'module:media:order:money:sales:agent',
            'module:media:order:money:paid',
            'module:media:order:customer:name',
            'module:media:money:sale',
            'module:weibo:money:sale',
            'module:wechat:money:sale',
            'module:user:edit',
            'module:user:salary:list',
            'module:user:salegroup:edit',
            'module:baike:order:money:sales:agent',
            'module:baike:order:money:paid',
            'module:media:time_limit',
        ],
        solo: [
            'module:user:code',
            'module:user:add:customer',
            'module:media:add',
            'page:resource:media',
        ],
        dynamic: {
            'page:user:salary': ({ user }) => {
                let sale_group_id = user?.sale_group?.sell_group_id
                return sale_group_id === 421 || sale_group_id === 423
            },
        }
    },
}

const admin_rules = {
    FRANCHISE: {
        static: [
            'page:dashboard',
            'page:user:info',
            'page:user:consume',
            'page:media:order:list',
            'page:media:project:list',
            'page:media:project:detail',
            'page:order:sendorder',
            'page:media:list',
            'page:media:price',
            'page:media:collection',
            'page:event:manage',
            'page:media:package',
            'page:notice:center',
            'page:user:list',
            'page:user:loan:manage',
            'page:user:level:list',
            'page:statistics',
            'page:weibo:list',
            'page:weibo:order:list',
            'page:wechat:list',
            'page:wechat:order:list',
            'page:xiaohongshu:list',
            'page:xiaohongshu:order:list',
            'page:douyin:list',
            'page:douyin:order:list',
            'tab:media:order',
            'tab:baike:order',
            'tab:index:order',
            'tab:qa:order',
            'module:media:import:price',
            'module:user:salary',
            'module:media:order:money:paid',
            'module:media:order:money:sales:agent',
            'module:media:order:sales:status',
            'module:media:order:customer:name',
            'module:media:order:sales:name',
            'module:media:money:sale',
            'module:weibo:money:sale',
            'module:wechat:money:sale',
            'module:user:add:leader',
            'module:user:salegroup:edit',
            'module:user:show:uid',
            'module:user:sale:recharge',
            'module:user:loan:edit',
            'module:user:manager:edit',
            'module:user:children:edit',
            'module:user:type:edit',
            'module:user:edit',
            'module:user:manage:consume',
            'module:user:reset:balance',
            'module:baike:order:money:paid',
            'module:baike:order:money:sales:agent',
            'module:salegroup:add:customer',
            'module:salegroup:add:vip',
            'module:salegroup:add:sales:leader',
            'module:order:search:workorder'
        ]
    },
    FINANCE: {
        static: [
            'page:dashboard',
            'page:user:info',
            'page:invoice:manage',
            'page:withdraw:manage',
            'page:order:workorder:manage',
            'page:invoice:enterprise:manage',
            'page:income:stats',
            'page:salary:stats',
            'page:user:salary',
            'page:user:loan:manage',
            'module:user:loan:reset',
            'module:invoice:unpaid:offline:pay',
            'module:invoice:paid:achieve',
            'module:invoice:prepaid:express',
            'module:invoice:pending:express',
            'module:invoice:in_transit:achieve',
            'module:invoice:pre_in_transit:comfirm',
            'module:finance:detail',
            'module:finance:sales:list',
            'module:withdraw:batch:comfirm',
            'module:user:salary:list',
            'module:user:salary:edit',
            'module:operater:salary',
        ]
    }
}

const operator_rules = {
    OPERATOR: {
        inherits: 'OPERATOR_ADMIN',
        static: [
            'page:order:stats',
            'page:event:manage',
            'page:media:package',
            'page:media:list',
            'page:notification:list',
            'page:event:manage',
            'page:notice:center',
            'page:coupon:list',
            'page:coupon:pack:list',
            'page:tag:list',
            'page:role:manage',
            'module:media:add',
            'module:media:edit',
            'module:media:delete',
            'module:media:package:add',
            'module:media:weight:refresh',
            'module:media:time:modify',
            'module:media:redeem',      // 媒体返现
            'module:media:level',
            'module:media:status',
            'module:media:check',
            'module:media:type',
            'module:media:weekend',
            'module:media:weight',
            'module:media:order:modify',
            'module:media:order:editor:paid',
            'module:media:order:cost:modify',
            'module:media:order:editor:edit',
            'module:media:order:history',
            'module:media:order:success:reject',
            'module:media:order:money:paid',
            'module:baike:order:toolbar',
            'module:media:prepay:charge',
            'module:media:prepay:add',
            'module:media:prepay:del',
            'module:media:order:status',
        ]
    },
    OPERATOR_LEADER: {
        inherits: 'OPERATOR_ADMIN',
        static: [
            'page:order:stats',
            'page:event:manage',
            'page:media:package',
            'page:media:list',
            'page:notification:list',
            'page:event:manage',
            'page:coupon:list',
            'page:coupon:pack:list',
            'page:tag:list',
            'page:user:salary',
            'module:media:add',
            'module:media:edit',
            'module:media:delete',
            'module:media:package:add',
            'module:media:weight:refresh',
            'module:media:time:modify',
            'module:media:redeem',      // 媒体返现
            'module:media:level',
            'module:media:status',
            'module:media:check',
            'module:media:type',
            'module:media:weekend',
            'module:media:weight',
            'module:media:order:modify',
            'module:media:order:editor:paid',
            'module:media:order:cost:modify',
            'module:media:order:editor:edit',
            'module:media:order:history',
            'module:media:order:success:reject',
            'module:baike:order:toolbar',
            'module:media:prepay:charge',
            'module:media:prepay:add',
            'module:media:prepay:del',
            'module:media:order:status',
            'module:operater:salary',
        ]
    },
    OPERATOR_ADMIN: {
        inherits: 'OPERATOR_MEDIA',
        static: [
            'page:weibo:list',
            'page:weibo:order:list',
            'page:wechat:list',
            'page:wechat:order:list',
            'page:xiaohongshu:list',
            'page:xiaohongshu:order:list',
            'page:media:prepay',
            'page:douyin:list',
            'page:douyin:order:list',
            'tab:baike:order',
            'tab:index:order',
            'tab:qa:order',
            'module:media:order:money:cost',
            'module:media:order:customer:name',
            'module:media:order:sales:name',
            'module:media:order:recall:handle',
            'module:media:order:error:handle',
            'module:media:money:cost',
            'module:media:money:sale:cost',
            'module:weibo:money:cost',
            'module:weibo:money:sale:cost',
            'module:wechat:money:cost',
            'module:wechat:money:sale:cost',
            'module:baike:order:money:cost',
            'module:baike:order:money:paid',
            'module:baike:order:money:agent',
            'module:user:wechat:unique_key',
            'module:media:export:repeat',
            'module:media:order:editor:apply',
            'module:media:time_limit',
        ],
    },
    OPERATOR_MEDIA: {
        static: [
            'page:dashboard',
            'page:user:info',
            'page:media:order:list',
            'tab:media:order',
            'module:media:order:toolbar',
            'module:media:order:solo',
            'module:media:order:money:agent',
            'module:media:order:verify:handle',
            'module:media:order:publish:handle',
            'module:media:order:success:handle',
        ]
    },
    OPERATOR_BAIKE: {
        static: [
            'page:dashboard',
            'page:user:info',
            'page:baike:order:list',
            'tab:baike:order',
            'module:baike:order:toolbar',
            'module:baike:order:money:agent',
        ]
    },
    SUPPORT_STAFF: {
        static: [
            'page:dashboard',
            'page:user:info',
            'page:media:order:list',
            'page:user:list',
            'page:user:loan:manage',
            'tab:baike:order',

            'module:media:order:customer:name',
            'module:media:order:sales:name',
        ]
    }
}

const resource_rules = {
    RESOURCE: {
        inherits: 'RESOURCE_MEDIA',
        static: [
        ]

    },
    RESOURCE_MEDIA: {
        static: [
            'page:dashboard',
            'page:user:info',
            'page:media:list',
            'page:media:prepay',
            'module:media:money:cost',
            'module:media:money:sale:cost',
            'module:media:add',
            'module:media:edit',
            'module:media:status',
            'module:media:check',
        ]
    }
}

const danger = 'color: red';
const warning = 'color: yellow; background: #333';
const normal = 'color: blue';

export const check = (role, action, data = null) => {
    let rule = getPermission(role)
    if (rule) {
        if (rule.static) {
            let static_rules = _.concat(rule.static, rule?.solo)
            if (_.indexOf(static_rules, action) !== -1) {
                // 判断静态权限
                return true
            }
        }
        if (rule.dynamic) {
            // 判断动态权限
            let dynamic = rule.dynamic
            if (dynamic[action]) {
                return dynamic[action](data)
            } else {
                // console.log(`%c 无权限操作! ${action}`, danger)
                return false
            }
        }
        // console.log(`%c 未配置权限! ${action}`, warning)
        return false
    } else {
        // console.log(`%c 未获取规则! ${action}`, normal)
        return false
    }
}

const rules = _.assign(
    {},
    customer_rules,
    sales_rules,
    operator_rules,
    resource_rules,
    admin_rules,
)

export default rules;

// 静态权限合并
const combineStatic = (src, target) => {
    if (target.static) {
        return _.concat(src.static, target.static)
    } else {
        return src.static
    }
}
// 动态权限合并
const combineDynamic = (src, target) => {
    if (target.dynamic) {
        return _.assign(src.dynamic, target.dynamic)
    } else {
        return src.dynamic
    }
}

const getPermission = (role) => {
    let permission = rules[role]
    if (permission?.inherits) {
        let src = getPermission(permission?.inherits)    // 继承用户权限
        let target = _.omit(permission, 'inherits')     // 当前用户独有权限
        return src.inherits
            ? {
                static: combineStatic(src, target),
                dynamic: combineDynamic(src, target),
                inherits: src.inherits,
                solo: target.solo
            } : {
                static: combineStatic(src, target),
                dynamic: combineDynamic(src, target),
                solo: target.solo
            }
    } else {
        return permission
    }
}

export const getRouter = ({ role, router, data }) => {
    const { platform } = data
    let platform_router = _.filter(router, e => e.platform === platform)
    if (role && platform_router) {
        let target_rule = getPermission(role)
        let static_rules = _.concat(target_rule?.static, target_rule?.solo)
        if (static_rules) {
            // 静态路径
            let static_pages = static_rules.filter(rule => _.startsWith(rule, 'page'))
            let result = platform_router.filter(route => _.indexOf(static_pages, route.key) !== -1)
            // 动态路径
            let dynamic = target_rule.dynamic
            if (dynamic && data) {
                _.forEach(dynamic, function (val, key) {
                    if (_.startsWith(key, 'page')) {
                        if (val(data)) {
                            let path = _.find(platform_router, route => route.key === key)
                            if (path) {
                                result = _.concat(result, path)
                            }
                        }
                    }
                })
            }
            return result
        } else {
            return []
        }
    } else {
        return []
    }
}