import React, { useState, useEffect } from 'react';
import {
    SearchOutlined, SyncOutlined
} from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Divider, Layout, Typography, Space, Tag, Tabs } from 'antd';
import dayjs from 'dayjs'
import _ from 'lodash'

import SearchForm from '../../common/SearchForm'
import OrderSearchConfig from '../../../role/order/OrderSearchConfig'
import { initFormData, tablePagination } from '../../common/tableCommon';
import { formatTime } from '../../../utils/TimeUtils'
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;

const { Text, Paragraph } = Typography;
const axios = require('axios');

const MediaPrepayOrderDetailTable = ({ prepay_id }) => {
    const [form] = Form.useForm();                  // 媒体搜索form

    const searchDatas = OrderSearchConfig({})
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [status, setStatus] = useState({ media_status: '', status: '', })

    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("prepay_id", prepay_id)

        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        _.toPairs(status).filter(e => e[1]).map(e => params.append(`${e[0]}`, e[1] ?? ''))

        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/media/prepay/getOrderList',
                data: params,
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    // 初始化列表
    useEffect(() => {
        fetchData()
        return source.cancel('Operation canceled by the user.');
    }, [prepay_id])

    const columns = [
        {
            title: '媒体名称',
            dataIndex: 'media_name',
        }, {
            title: '标题',
            dataIndex: 'title',
            render: (text, data) => {
                let url = `http://p.yunzitui.com/post/${data.order_no}`
                let copyUrl = data.source_url === "" ? url : data.source_url
                let copyText = `媒体：${data.media_name}\n标题：${data.title}\n地址：${copyUrl}\n`
                return (
                    <>
                        <Paragraph style={{ marginBottom: 0 }} copyable={{
                            text: (data.remarks === '' || data.remarks === null) ? copyText : copyText + `备注：${data.remarks}`
                        }}>{text}</Paragraph>
                    </>
                )
            }
        }, {
            title: '成本',
            dataIndex: 'cost_money',
            width: 96
        }, {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            render: (t) => formatTime(t)
        },
        {
            title: '审核时间',
            dataIndex: 'handle_time',
            width: 96,
            render: (t) => formatTime(t)
        },
        {
            title: '发布时间',
            dataIndex: 'media_handle_time',
            width: 96,
            render: (t) => formatTime(t)
        }, {
            title: '状态',
            dataIndex: 'status',
            width: 72,
            checked: 1,
            render: (text, data) => {
                if (data.err_status === 2 || data.err_status === -1) {
                    return <Tag style={{ margin: 0 }} color="red">价格异常</Tag>
                }
                let result = '';
                switch (data.media_status) {
                    case 'waitPublish':
                        result = <Tag style={{ margin: 0 }} color="gold">待发布</Tag>;
                        if (data.status === 'waitVerify') {
                            result = <Tag style={{ margin: 0 }} color="blue">待审核</Tag>;
                        }
                        break;
                    case 'success':
                        result = <Tag style={{ margin: 0 }} color="green">已发布</Tag>;
                        break;
                    case 'back':
                        result = <Tag style={{ margin: 0 }} color="red">已退稿</Tag>;
                        break;
                    case 'recall':
                        result = <Tag style={{ margin: 0 }} color="orange">撤稿中</Tag>;
                        break;
                    case 'error':
                        result = <Tag style={{ margin: 0 }} color="geekblue">申诉中</Tag>;
                        break;
                    default:
                        break;
                }
                return <Space direction='vertical' size='small'>
                    {result}
                </Space>
            }
        }, {
            title: '回执信息',
            dataIndex: 'back_info',
            width: 150,
            render: (text) => {
                return <a href={text} className='table-btn' target="_blank" rel="noopener noreferrer">查 看</a>
            }
        }
    ]
    const filterMediaStatus = (e) => {
        switch (e) {
            case '2':
                setStatus({ status: 'waitVerify', media_status: 'waitPublish' })
                break;
            case '3':
                setStatus({ status: 'waitPublish', media_status: 'waitPublish' })
                break;
            case '4':
                setStatus({ status: 'complete', media_status: 'success' })
                break;
            case '5':
                setStatus({ status: '', media_status: 'back' })
                break;
            case '6':
                setStatus({ status: 'waitPublish', media_status: 'recall' })
                break;
            case '7':
                setStatus({ status: 'complete', media_status: 'error' })
                break;
            default:
                setStatus({ status: '', media_status: '' })
        }
        form.submit()
    }


    return <>
        <Content className='table-search' style={{ padding: 0 }}>
            <PageHeader
                style={{ padding: 0 }}
                title={<Text level={4}>媒体搜索</Text>}
            >
                <Form
                    layout={'horizontal'}
                    labelAlign="left"
                    name="media-form"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                    <Divider style={{ margin: '8px 0' }} />
                    <Row>
                        <Col span={12}>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button onClick={() => form.resetFields()}>
                                    <SyncOutlined /> 重置搜索
                                </Button>
                                <Button type="primary" htmlType="submit" >
                                    <SearchOutlined /> 搜索
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </PageHeader>
        </Content>
        <Content className='table-main'>
            <Tabs defaultActiveKey="1" onChange={filterMediaStatus}
                items={[
                    { key: '1', label: `全部稿件`, },
                    { key: '2', label: `待审核`, },
                    { key: '3', label: `待发布`, },
                    { key: '4', label: `已完成`, },
                    { key: '5', label: `已退稿`, },
                    { key: '6', label: `撤稿申请`, },
                    { key: '7', label: `异常申请`, },
                ]}
            />
            <Table size='small'
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
                summary={pageData => {
                    let totalCost = _.sumBy(data, 'cost_money');
                    return <tr>
                        <td />
                        <th>汇总</th>
                        <td colSpan={7}>
                            <Space>
                                <span>成本: <Text type="danger">{totalCost}</Text></span>
                            </Space>
                        </td>
                    </tr>
                }}
            />
        </Content>
    </>;
}

export default MediaPrepayOrderDetailTable