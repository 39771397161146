import React, { useState } from 'react'
import {
    Layout, Typography, Menu, Avatar, Upload, Button, message, Card, Space
} from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { UploadOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil'

import { userState } from '../../atoms/Atoms'
import UserForm from './UserForm';
import UserAdvancedForm from './UserAdvancedForm';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;
const { Title, Text } = Typography;
const getHash = (str) => {
    switch (str) {
        case "#advanced":
            return "advanced"
        default:
            return "basic"
    }
}
const User = (props) => {
    let pageTitle = '个人资料'
    const sessionUser = useRecoilValue(userState)

    const [key, setKey] = useState(getHash(props?.location?.hash))


    let img = sessionUser.basic.avatar_img
    let avatar = `http://static.yunzitui.com/upload/user/common/default.png`
    let base_img = img ? img : avatar;

    const menuItems = [
        { label: '基本设置', key: 'basic', },
        { label: '高级设置', key: 'advanced', },
    ]

    const uploadProps = {
        name: 'files',
        action: '/user/uploadAvatar',
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
                console.log(info.file.response.data);   // 头像回执
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} 上传成功`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败`);
            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('请上传JPG/PNG格式的图片!');
                return false
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('当前支持图片最大尺寸为2M!');
                return false
            }
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader title={pageTitle} />
            <Card className='m-24'>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Menu
                        onClick={e => setKey(e.key)}
                        style={{ maxWidth: '256px', marginRight: '16px', flex: '1' }}
                        selectedKeys={key}
                        mode="inline"
                        items={menuItems}
                    />
                    <div style={{ flex: 'auto', padding: '0 24px' }}>
                        {
                            key === "basic" &&
                            <Space direction='vertical' size={16} className='full-width'>
                                <Title level={4}>基本设置</Title>
                                <div style={{ display: 'flex' }}>
                                    <UserForm sessionUser={sessionUser} />
                                    <div style={{ display: 'flex', marginLeft: '48px', flexDirection: 'column' }}>
                                        <Text style={{ paddingBottom: '8px' }}>头像</Text>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Avatar size={144} src={base_img} style={{ marginBottom: '16px' }} />
                                            <Upload {...uploadProps}>
                                                <Button>
                                                    <UploadOutlined /> 点击上传
                                                </Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </div>
                            </Space>
                        }
                        {
                            key === "advanced" && <Space direction='vertical' size={16} className='full-width'>
                                <Title level={4}>高级设置</Title>
                                <UserAdvancedForm sessionUser={sessionUser} />
                            </Space>
                        }
                    </div>
                </div>
            </Card>
        </HelmetProvider>
    )
}
export default User