import React from 'react'
import { Divider, Typography, Tooltip, Button, Space, Popconfirm, Radio, Tag } from 'antd';
import _ from 'lodash'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';

import { userRoleState, userAttacheState } from '../../Apps/atoms/Atoms'
const { Paragraph, Text } = Typography;
const MediaIndexTableConfig = ({ action, handleMedia }) => {
    const role = useRecoilValue(userRoleState)
    const attache = useRecoilValue(userAttacheState)

    const { getMedia, handleStatus, getSaleGroupPrice, deleteMedia, changeEditor } = handleMedia;

    let isOperator = role === 'OPERATOR';
    let isEditor = role === 'CUSTOMER' && action === 'EDIT'

    const nameCol = {
        title: '媒体名称',
        dataIndex: 'name',
        checked: 1,
        render: (text, data, index) => {
            return (
                <>
                    <Paragraph >{text}</Paragraph>
                    {
                        data.sample_url === ""
                            ? <span>暂无案例</span>
                            : <span><a href={data.sample_url} target='_blank' rel='noopener noreferrer'>查看案例</a></span>
                    }
                    {
                        !(_.isNil(data.url) || data.url === "") &&
                        <span style={{ marginLeft: 8 }}><a href={data.url} target='_blank' rel='noopener noreferrer'>查看图片案例</a></span>
                    }
                </>
            )
        }
    }

    const priceCol = {
        title: '价格',
        dataIndex: 'sale_price',
        checked: 1,
        render: (t, data) => {
            let result = <Text strong>价格：{t}</Text>
            switch (role) {
                case 'CUSTOMER':
                    if (action === 'EDIT') {
                        result = <Text strong>价格：{data.cost}</Text>
                    } else {
                        result = <Text strong>{data.user_price}</Text>
                    }
                    break;
                case 'OPERATOR':
                    result = <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text strong>成本：{data.cost}</Text>
                        <Text>相对成本：{data.sale_price}</Text>
                    </div>
                    break;
                case 'FRANCHISE':
                case 'SALE':
                case 'SALE_LEADER':
                case 'SALE_ATTACHE':
                    result = <Text>{data.sale_price}</Text>
                    break;
                default:
                    result = <Text>{data.user_price}</Text>
                    break;
            }
            return result
        }
    }

    const mediaCol = {
        title: '媒体详情',
        dataIndex: 'location',
        checked: 1,
        render: (text, data) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text strong>资源位置:{data.location}</Text>
                    <Text strong>频道类型:{data.profession}</Text>
                    <Text strong>所在地区:{data.area}{isOperator && <><Divider type="vertical" />{data.include_type}</>}</Text>
                </div>
            )
        }
    }

    const mediaCheckCol = {
        title: '审核',
        dataIndex: 'checked',
        checked: 1,
        render: (text, data) => {
            let checkCol = data.checked === 1 ? <Tag color="#87d068">已通过</Tag> : <Tag color="#f50">待审核</Tag>

            let status = <Radio.Group value={data.status} size="small"
                onChange={e => handleStatus(data.id, 'status', e.target.value)}
            >
                <Radio.Button value={1}>正常</Radio.Button>
                <Radio.Button value={2}>暂停</Radio.Button>
            </Radio.Group>
            let check = <Radio.Group value={data.checked} size="small"
                onChange={e => handleStatus(data.id, 'checked', e.target.value)}
            >
                <Radio.Button value={1}>通过</Radio.Button>
                <Radio.Button value={2}>待审</Radio.Button>
            </Radio.Group>

            return <Space>
                {
                    isOperator &&
                    <Space direction='vertical'>
                        {status}
                        {check}
                    </Space>
                }
                {
                    isEditor &&
                    <Space direction='vertical'>
                        {status}
                        {checkCol}
                    </Space>
                }
            </Space>
        }
    }

    const mediaEditorCol = {
        title: '编辑',
        dataIndex: 'editor_name',
        checked: 1,
        render: (t, data) => {
            return (
                <>
                    <span>
                        <Text>{data.creator_name}</Text>
                        <Button type='link' size='small' icon={<EditOutlined />} onClick={() => changeEditor(data.id)} />
                    </span>
                    <br />
                    <Text>{data.editor_name}</Text>
                    <br />
                    <Text>{data.editor_tel}</Text>
                </>
            )
        }
    }

    const mediaRemarkCol = {
        title: '备注',
        dataIndex: 'comment',
        checked: 1,
        render: (text) => (
            <Tooltip placement="topLeft" title={text}>
                <Paragraph ellipsis={{ rows: 3 }}>
                    {text}
                </Paragraph>
            </Tooltip>
        )
    }

    const modifyMediaCol = {
        title: '操作',
        dataIndex: 'id',
        fixed: 'right',
        width: 100,
        checked: 1,
        render: (text, data) => (
            <Space>
                <Button type="link" icon={<EditOutlined />} size='small' onClick={() => getMedia(data.id)} />
                <Popconfirm
                    title={`确认删除${data.name}吗?`}
                    onConfirm={() => deleteMedia(data.id)}
                    onCancel={e => console.log(e)}
                    okText="确认"
                    cancelText="取消">
                    <Button type="link" icon={<DeleteOutlined />} size='small' />
                </Popconfirm>

            </Space>
        )
    }

    const mediaOrderCol = {
        title: '排序管理',
        dataIndex: 'id',
        checked: 1,
        render: (text, data) => {
            return <Space direction='vertical' size='small'>
                {
                    isOperator &&
                    <>
                        <Text editable={{
                            onChange: e => (e && Number(e) >= 0) && handleStatus(data.id, 'order_weight', Number(e))
                        }}>权重:{data.order_weight}</Text>
                    </>
                }
            </Space>
        }
    }


    const updateSaleGroupPriceCol = {
        title: '操作',
        dataIndex: 'id',
        fixed: 'right',
        width: 100,
        checked: 1,
        render: (text, data) => {
            switch (attache) {
                case 0:
                    return <Space>
                        <Button type="link" size='small' onClick={() => getSaleGroupPrice(data.id, 3)}>客户价格分配</Button>
                        <Button type="link" size='small' onClick={() => getSaleGroupPrice(data.id, 9)}>会员价格分配</Button>
                    </Space>
                default:
                    break;
            }
        }
    }

    let result = []
    switch (role) {
        case "CUSTOMER":
            if (action === 'EDIT') {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaCheckCol,
                    mediaRemarkCol,
                    modifyMediaCol,
                )
            } else {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaRemarkCol,
                )
            }

            break;
        case "OPERATOR":
        case "OPERATOR_LEADER":
            switch (attache) {
                case 0:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaCheckCol,
                        mediaEditorCol,
                        mediaRemarkCol,
                        mediaOrderCol,
                        modifyMediaCol,
                    )
                    break;
                case 1:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                    break;
                default:
                    break;
            }
            break;
        case "SALE":
        case "FRANCHISE":
            switch (attache) {
                case 0:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                        updateSaleGroupPriceCol
                    )
                    break;
                case 1:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                        updateSaleGroupPriceCol
                    )
                    break;
                case 2:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                    break;
                default:
                    break;
            }
            break;
        case "PUBLIC":
            result = _.concat(
                nameCol,
                priceCol,
                mediaCol,
                mediaRemarkCol,
            )
            break;
        default:
            break;
    }
    return result
}

export default MediaIndexTableConfig;