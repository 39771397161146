import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';
import MediaTable from '../medias/MediaTable'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MediaIndexTable from '../mediaindex/MediaIndexTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;


// /dashboard/resource/media
// 入住编辑页面
const EditorMedias = () => {

    let pageTitle = "我的媒体"
    const [tab, setTab] = useState("media")
    const action = 'EDIT'

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: 'media', label: `新闻媒体`, },
                            { key: 'index', label: `文字链`, },
                        ]}
                    />
                }
            />
            <Content>
                <div style={{ margin: 24 }}>
                    {
                        tab === "media" && <MediaTable url='/media/getEditorResourceList' action={action} menu={false} />
                    }
                    {
                        tab === "index" && <MediaIndexTable url='/mediaIndex/getEditorResourceList' action={action} />
                    }
                </div>
            </Content>
        </HelmetProvider>

    )
}

export default EditorMedias;