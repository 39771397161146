import React from 'react';
import { Layout,  } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageHeader from '../../layout/PageHeader';

import XiaohongshuOrderTable from './XiaohongshuOrderTable';

const { Content } = Layout;

const XiaohongshuOrder = () => {
    let pageTitle = "小红书订单"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content>
                <div style={{ margin: 24 }}>
                    <XiaohongshuOrderTable />
                </div>
            </Content>
        </HelmetProvider>
    )
}

export default XiaohongshuOrder;