import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const axios = require('axios');

const WechatDatasConfig = () => {

    const role = useRecoilValue(userRoleState)
    const [tags, setTags] = useState([])

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getTags = async () => {
        let params = new FormData()
        params.append('tag_type', 3)
        await axios({
            method: 'post',
            url: `/tag`,
            data: params
        }, {
            cancelToken: source.token
        }).then((res) => {
            let data = res.data;
            setTags(data.map(e => ({ 'id': e.id, 'name': e.tag, 'val': e.tag })))
        })
    }

    useEffect(() => {
        getTags()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    const mediaBasicDatas = [
        {
            name: 'name',
            label: '公众号名称',
            required: true,
            placeholder: '请输入公众号名称',
            type: 'input'
        },
        {
            name: 'sample_url',
            label: '案例地址',
            required: true,
            placeholder: '请输入案例地址',
            type: 'input'
        },
        {
            name: 'account',
            label: '微信公众号ID',
            required: true,
            placeholder: '请输入微信公众号ID',
            type: 'input'
        },
    ]

    const mediaPriceDatas = [
        {
            name: 'price_1',
            label: '单图文价格',
            placeholder: '请输入单图文价格',
            type: 'inputnumber'
        },
        {
            name: 'price_2',
            label: '多图文头条价格',
            placeholder: '请输入多图文头条价格',
            type: 'inputnumber'
        },
        {
            name: 'price_3',
            label: '多图文次条价格',
            placeholder: '请输入多图文次条价格',
            type: 'inputnumber'
        },
        {
            name: 'price_4',
            label: '多图文其他价格',
            placeholder: '请输入多图文其他价格',
            type: 'inputnumber'
        },
    ]

    const salePrice = [
        {
            name: 'sale_price_1',
            label: '单图文相对价格',
            placeholder: '请输入单图文相对价格',
            type: 'inputnumber'
        },
        {
            name: 'sale_price_2',
            label: '多图文头条相对价格',
            placeholder: '请输入多图文头条相对价格',
            type: 'inputnumber'
        },
        {
            name: 'sale_price_3',
            label: '多图文次条相对价格',
            placeholder: '请输入多图文次条相对价格',
            type: 'inputnumber'
        },
        {
            name: 'sale_price_4',
            label: '多图文其他相对价格',
            placeholder: '请输入多图文其他相对价格',
            type: 'inputnumber'
        },
    ]

    const mediaPropsDatas = [
        {
            name: 'tag',
            label: '资源标签',
            placeholder: '请输入资源标签',
            type: 'select',
            value: [],
            mode: 'multiple',
            children: tags
        }
    ]

    const editorDatas = [
        {
            name: 'editor_name',
            label: '编辑姓名',
            placeholder: '请输入媒体名称',
            type: 'input'
        },
        {
            name: 'editor_tel',
            label: '联系方式',
            placeholder: '请输入媒体名称',
            type: 'input'
        }, 
    ]

    const manageDatas = [
        {
            name: 'inner_remarks',
            label: '内部备注',
            placeholder: '请输入内部备注',
            type: 'area'
        },
    ]

    const commentDatas = [
        {
            name: 'remarks',
            label: '备注',
            placeholder: '请输入备注',
            type: 'area'
        },
    ]


    let result = []

    switch (role) {
        case "OPERATOR":
            result = [
                mediaBasicDatas,
                mediaPriceDatas,
                salePrice,
                mediaPropsDatas,
                editorDatas,
                manageDatas,
                commentDatas
            ]
            break;
        case "CUSTOMER":
            result = [
                mediaBasicDatas,
                mediaPriceDatas,
                mediaPropsDatas,
                editorDatas,
                commentDatas
            ]
            break;
        default:
            break;
    }

    return result
}

export default WechatDatasConfig