import React from 'react'
import {
    Typography, Button, Avatar, Popconfirm, message, Switch, Space, Tooltip, Radio, Dropdown, Menu, Tag, Popover, List, Divider, Descriptions
} from 'antd';
import {
    UserOutlined, EditTwoTone, DeleteTwoTone, FileTextTwoTone,
    InfoCircleOutlined, DownOutlined
} from '@ant-design/icons';
import _ from 'lodash'
import qs from 'qs';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { formatTime } from '../../utils/TimeUtils'
import { check } from '../../utils/permission/rules';
import { resultResponse } from '../../Apps/common/ResultResponse';

const { Text, Paragraph } = Typography;
const axios = require('axios');

const userType = new Map([
    [1, "媒介"], [2, "推广公司"], [3, "企业客户"], [4, "其他"]
])

export const UserTableConfig = ({ visible, setVisible, handleUsers, dispatch }) => {
    const role = useRecoilValue(userRoleState)
    const { getUserSaleGroup, getUserPost, deleteUser, getUser, resetPassword,
        handleUserStatus, applyLoan, modifyUserInvoice, pauseLoan, reloadTable } = handleUsers;
    const size = 'small'

    const permission = {
        'module:user:salegroup:edit': check(role, 'module:user:salegroup:edit', null),
        'module:user:show:uid': check(role, 'module:user:show:uid', null),
        'module:user:sale:recharge': check(role, 'module:user:sale:recharge', null),
        'module:user:loan:edit': check(role, 'module:user:loan:edit', null),
        'module:user:manager:edit': check(role, 'module:user:manager:edit', null),
        'module:user:children:edit': check(role, 'module:user:children:edit', null),
        'module:user:type:edit': check(role, 'module:user:type:edit', null),
        'module:user:edit': check(role, 'module:user:edit', null),
        'module:user:reset:balance': check(role, 'module:user:reset:balance', null),
    }

    async function resetBalance(uid) {
        const url = `/user/reset/balance/${uid}`
        await axios.get(url).then(res => {
            resultResponse(res, '已成功清零!')
            reloadTable()
        })
    }

    const modifyUserGroup = ({ uid, platform_id }) => {
        getUserSaleGroup({ platform_id })
        setVisible({ ...visible, group: true, id: uid })
    }

    const modifyUserPost = ({ uid }) => {
        getUserPost()
        setVisible({ ...visible, post: true, id: uid })
    }

    const defaultConfig = [
        {
            title: '',
            dataIndex: 'avatar_img',
            width: 40,
            checked: 1,
            render: (t, data) => {
                let base_img = data.avatar_img ? data.avatar_img + "_xs" : `http://origin-static.oss-cn-beijing.aliyuncs.com/static/images/defaultPic.jpg_xs`;
                return <Avatar shape="square" icon={<UserOutlined />} src={base_img} />;
            }
        }, {
            title: '账号名',
            dataIndex: 'account',
            checked: 1,
            render: (t, data) => {
                return (
                    <>
                        {
                            dispatch !== 'customer'
                            && <div className='flex-justify'>
                                <Text>{data.sell_group_name}</Text>
                                {
                                    // 修改用户所属等级分组 只有管理员可以进行分配
                                    permission['module:user:salegroup:edit'] &&
                                    <Button type='link' size={size} onClick={() => {
                                        modifyUserGroup({ uid: data.id })
                                    }}>修改</Button>
                                }
                            </div>
                        }
                        <div className='flex-justify'>
                            <Text strong>{t}</Text>
                            {permission['module:user:show:uid'] && <Text type="secondary" style={{ fontSize: '12px' }}>uid: {data.id}</Text>}
                        </div>
                        {
                            data.attache === 3 &&
                            <div className='flex-justify'>
                                <Text>{data.available_money}元</Text>
                                {
                                    // 用户充值 只有管理员能够充值
                                    permission['module:user:sale:recharge'] && <Button type='link' size={size} onClick={() => {
                                        setVisible({ ...visible, recharge: true, id: data.id })
                                    }}>充值</Button>
                                }
                            </div>
                        }
                        {
                            data.attache !== 3 &&
                            <div className='flex-justify'>
                                <Tag color='orange'>{data.post_name}</Tag>
                                {
                                    // 用户充值 只有管理员能够充值
                                    permission['module:user:sale:recharge'] && <Button type='link' size={size} onClick={() => {
                                        modifyUserPost({ uid: data.id })
                                    }}>修改职位</Button>
                                }
                            </div>
                        }
                    </>
                )
            }
        },
    ]



    const userInfoCol = [
        {
            title: '用户信息',
            checked: 1,
            render: (text, data) => {
                return (
                    <>
                        {
                            (!dispatch || dispatch === 'attache' || dispatch === 'customer') && <div className='flex-justify'>
                                <Text>所属销售：{data.create_name}</Text>
                                {
                                    permission['module:user:manager:edit'] &&
                                    <Button type='link' size={size} onClick={() => {
                                        setVisible({ ...visible, changeManage: true, id: data.id })
                                    }}>修改</Button>
                                }
                            </div>
                        }
                        {
                            dispatch === 'leader' && <div className='flex-justify'>
                                <Text>子账号: {data.child_name}</Text>
                                {
                                    permission['module:user:children:edit'] &&
                                    <Button type='link' size={size} onClick={() => {
                                        setVisible({ ...visible, child: true, id: data.id })
                                    }}>修改</Button>
                                }
                            </div>
                        }
                        <Text>真实姓名：{data.real_name}</Text>
                        <br />
                        <Text>所属公司：{data.company}</Text>
                    </>
                )
            }
        },
    ]

    const customerCol = [
        {
            title: '分组信息',
            dataIndex: 'vip',
            checked: 1,
            render: (t, data) => {
                let groups = _.filter(data?.user_groups, e => e.platform_id === 1)
                let defaultGroupName = groups[0]?.sale_name ?? data?.sell_group_name

                return <div>
                    <Popover placement="topLeft" content={<Descriptions title="用户信息" bordered>
                        <Descriptions.Item label="用户会员组">
                            <UserPlatform data={data} permission={permission} modifyUserGroup={modifyUserGroup} uid={data.id} />
                        </Descriptions.Item>
                        <Descriptions.Item label="用户类型">
                            <Space direction='vertical'>
                                <Text strong>{userType.get(data.user_type)}</Text>
                                {
                                    permission['module:user:type:edit'] &&
                                    <Radio.Group defaultValue={data.user_type} buttonStyle="solid" size={size}
                                        onChange={e => handleUserStatus(data.id, 'user_type', e.target.value)}
                                    >
                                        {Array.from(userType).map(e => <Radio.Button value={e[0]} key={e[0]}>{e[1]}</Radio.Button>)}
                                    </Radio.Group>
                                }
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>}>
                        <Space size={4}>
                            <Text>{defaultGroupName}</Text>
                            <Divider type="vertical" />
                            <Text>{userType.get(data.user_type)}</Text>
                        </Space>
                    </Popover>
                </div>
            }
        }, {
            title: '备注',
            checked: 1,
            render: (text, data) => <Paragraph ellipsis={{ rows: 3 }}>{data.remark}</Paragraph>
        }
    ]

    const userBalanceInfoCol = [
        {
            title: '用户积分',
            checked: 1,
            render: (t, data) => {
                return (
                    <>
                        <div className='flex-justify'>
                            <Text>当前等级：</Text>
                            <Text>{`${data.level_group_id}(${data.exp})`}</Text>
                        </div>
                        <div className='flex-justify'>
                            <Text>当前积分：</Text>
                            <Text>{data.points}</Text>
                        </div>
                        <div className='flex-justify'>
                            <Text>当前S币：</Text>
                            <Text>{data.coins}</Text>
                        </div>
                    </>
                )
            }
        }, {
            title: '用户额度',
            checked: 1,
            render: (t, data) => {
                if (data.loan_state === 1) {
                    return <>
                        {permission['module:user:loan:edit'] && <div className='flex-justify'>
                            <Button type='link' style={{ padding: 0 }} danger size={size} onClick={() => pauseLoan(data.id)}>暂停用户白条</Button>
                            <Button type='link' size={size} onClick={() => {
                                setVisible({ ...visible, loan: true, id: data.id })
                            }}>修改</Button>
                        </div>}
                        <div className='flex-justify'>
                            <Text>当前额度：</Text>
                            <Text>{`${data.money} / ${data.limit_money}`}</Text>
                        </div>
                        <div className='flex-justify'>
                            <Text>已消费: </Text>
                            <Text>{(data.limit_money - data.money).toFixed(2)}</Text>
                        </div>
                    </>
                } else {
                    return permission['module:user:loan:edit']
                        ? <Space>
                            <Popconfirm
                                title="是否开通当前用户额度权限"
                                onConfirm={() => applyLoan(data.id)}
                                onCancel={() => message.warn('已取消')}
                                okText="确认"
                                cancelText="取消"
                            >
                                <Button type='link' size={size}>开通额度</Button>
                            </Popconfirm>
                            {
                                (data.money && data.limit_money) &&
                                <>
                                    <div className='flex-justify'>
                                        <Text>当前额度：</Text>
                                        <Text>{`${data.money} / ${data.limit_money}`}</Text>
                                    </div>
                                    <div className='flex-justify'>
                                        <Text>已消费: </Text>
                                        <Text>{(data.limit_money - data.money).toFixed(2)}</Text>
                                    </div>
                                </>
                            }
                        </Space>
                        : <Text>暂未开通</Text>
                }
            }
        }
    ]

    const userTimeCol = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        }, {
            title: '最后登录',
            dataIndex: 'last_login_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        }, {
            title: '登录次数',
            dataIndex: 'login_times',
            width: 96,
            checked: 1,
            render: (t) => {
                return <Tooltip title="自2020年6月11日起" mouseEnterDelay={0.3}>
                    <Text>{t} 次 <InfoCircleOutlined /></Text>
                </Tooltip>
            },
        }
    ]

    const userContactCol = {
        title: '联系方式',
        dataIndex: 'handle_user',
        checked: 1,
        render: (t, data) => {
            return (
                <>
                    <div className='flex-justify'>
                        <Text>QQ：</Text>
                        <Text copyable>{data.qq}</Text>
                    </div>
                    <div className='flex-justify'>
                        <Text>电话：</Text>
                        <Text copyable>{data.tel}</Text>
                    </div>
                    <div className='flex-justify'>
                        <Text>微信:</Text>
                        <Text copyable>{data.wechat_account}</Text>
                    </div>
                </>
            )
        }
    }

    const userStatus = {
        title: '状态',
        dataIndex: 'status',
        width: 80,
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            let status_desc = data.status === 1;
            return (
                <>
                    <Switch checkedChildren="正常" unCheckedChildren="停用" defaultChecked={status_desc}
                        onChange={e => {
                            axios({
                                method: 'post',
                                url: `/user/update`,
                                data: qs.stringify({
                                    id: data.id,
                                    status: e ? 1 : 0
                                })
                            }).then((res) => {
                                if (res.data.code === '200') {
                                    message.success("已修改")
                                } else {
                                    message.error(res.data.message)
                                }
                            }).catch(err => {
                                console.log(err)
                            })
                        }} />
                </>
            )
        }
    }


    const handleUser = {
        title: '用户操作',
        dataIndex: 'back_info',
        width: 220,
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            const menuItems = _.concat(
                { label: <Button onClick={() => modifyUserInvoice(data.id)} type='link'>修改发表信息</Button>, key: 'update-user-invoice' }, // 菜单项务必填写 key
                permission['module:user:reset:balance'] && {
                    label: <Popconfirm
                        title="是否重置该用户余额"
                        onConfirm={() => resetBalance(data.id)}
                        onCancel={() => message.warn('已取消')}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button type='link'>账户清零</Button>
                    </Popconfirm>,
                    key: 'reset-user-balance'
                },
            )

            return (
                <Space>
                    {
                        permission['module:user:edit'] && <>
                            <Tooltip title="编辑用户" mouseEnterDelay={0.3}>
                                <Button type='link' size={size} icon={<EditTwoTone />} onClick={() => getUser(data.id)} />
                            </Tooltip>
                            <Tooltip title="删除用户" mouseEnterDelay={0.3}>
                                <Popconfirm
                                    title="是否删除该用户"
                                    onConfirm={() => deleteUser(data.id)}
                                    onCancel={() => message.warn('已取消')}
                                    okText="确认"
                                    cancelText="取消"
                                >
                                    <Button type='link' icon={<DeleteTwoTone />} size={size} />
                                </Popconfirm>
                            </Tooltip>
                        </>
                    }
                    <Tooltip title="消费列表" mouseEnterDelay={0.3}>
                        <Button type='link' icon={<FileTextTwoTone />} size={size} onClick={() => setVisible({ ...visible, consume: true, id: data.id })} />
                    </Tooltip>
                    <Popconfirm
                        title="是否重置该用户密码"
                        onConfirm={() => resetPassword(data.id)}
                        onCancel={() => message.warn('已取消')}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button type='link' size={size}>重置密码</Button>
                    </Popconfirm>
                    <Dropdown menu={<Menu items={menuItems} />}>
                        <a className="ant-dropdown-link" href="true" onClick={e => e.preventDefault()}>
                            更多 <DownOutlined />
                        </a>
                    </Dropdown>
                </Space >
            )
        }
    }



    let result = []

    switch (role) {
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
        case 'SUPPORT_STAFF':
            result = dispatch === 'customer'
                ? _.concat(
                    defaultConfig,
                    userInfoCol,
                    customerCol,
                    userBalanceInfoCol,
                    userContactCol,
                    userTimeCol,
                    userStatus,
                    handleUser,
                )
                : _.concat(
                    defaultConfig,
                    userInfoCol,
                    userContactCol,
                    userTimeCol,
                    userStatus,
                    handleUser,
                )
            break;
        default:
            break;
    }
    return result
}

const UserPlatform = ({ data, permission, modifyUserGroup, uid }) => {

    const groups = data?.user_groups

    return <Space>
        {
            _.size(groups) > 0
                ? <List
                    itemLayout="horizontal"
                    dataSource={groups}
                    renderItem={group => {
                        let platform
                        switch (group.platform_id) {
                            case 1:
                                platform = <Tag color="blue">媒体平台</Tag>
                                break;
                            case 3:
                                platform = <Tag color="red">微信平台</Tag>
                                break;
                            case 2:
                                platform = <Tag color="orange">微博平台</Tag>
                                break;
                            case 4:
                                platform = <Tag color="geekblue">小红书平台</Tag>
                                break;
                            case 5:
                                platform = <Tag color="green">抖音平台</Tag>
                                break;
                            default:
                                break;
                        }

                        let isVip = Boolean(group?.expired_time)
                        return <List.Item actions={[<Button type='link' key="list-loadmore-edit" onClick={() => {
                            if (isVip) {
                                message.warn('当前等级为会员等级, 无法修改')
                                return false;
                            }
                            permission['module:user:salegroup:edit'] && modifyUserGroup({ uid, platform_id: group?.platform_id })
                        }}>修改</Button>]}>
                            <Space direction='vertical' size='small'>
                                <Space>
                                    <div style={{ width: 90 }}>
                                        {platform}
                                    </div>
                                    <Text strong>{group?.sale_name}</Text>
                                </Space>
                                {isVip
                                    ? <Space>
                                        <div style={{ width: 90 }}>
                                            <Tag color="gold">会员分组</Tag>
                                        </div>
                                        <Text>过期时间: {dayjs(group?.expired_time).format('YYYY-MM-DD')}</Text>
                                    </Space>
                                    : <Tag color="orange">普通分组</Tag>}
                            </Space>
                        </List.Item>
                    }}
                />
                : <List
                    itemLayout="horizontal"
                    dataSource={groups}
                >
                    <List.Item actions={[<Button type='link' key="list-loadmore-edit" onClick={() => {
                        permission['module:user:salegroup:edit'] && modifyUserGroup({ uid, platform_id: 1 })
                    }}>修改</Button>]}>
                        <Space direction='vertical' size='small'>
                            <Space>
                                <div style={{ width: 90 }}>
                                    <Tag color="blue">媒体平台</Tag>
                                </div>
                                <Text strong>{data?.sell_group_name}</Text>
                            </Space>
                        </Space>
                    </List.Item>
                </List>
        }

    </Space>
}

