import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Row, Col, Typography, message, Tag, Layout } from 'antd';
import dayjs from 'dayjs';

import { initFormData, tablePagination } from '../../common/tableCommon';

const axios = require('axios');
const { Title } = Typography;
const UseCoupon = ({ medias, coupon = {}, setCoupon }) => {

    const dateFormat = `YYYY.MM.DD`

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState({ create: false, medias: false })
    const [mediaDatas, setMediaDatas] = useState([])

    const resetModal = () => setModal({ create: false, medias: false })
    const fetchData = async (params = new FormData()) => {
        setLoading(true)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("medias", JSON.stringify(medias))
        await axios({
            method: 'post',
            url: '/coupon/bought/list',
            data: params
        }).then((res) => {
            let tempData = res.data.data
            setData(tempData.map(e => {
                var n = dayjs(e.end_time).diff(dayjs(), 'days');
                var m = dayjs(e.expire_time).diff(dayjs(), 'days');
                if (data.expire_time === null) {
                    e = 0
                }
                var p = dayjs(e.end_time).diff(dayjs(), 'hours');
                return ({
                    ...e,
                    enabled: !((n >= 0) && (m >= 0) && p > 0)
                })
            }))
            setLoading(false)
            let total = res.data.total
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    const rowSelection = {
        type: 'radio',
        selectedRowKeys: [coupon.coupon_buy_id] || [],
        onSelect: (record, selected) => {
            console.log(record, selected)
            let tempCoupon = {
                coupon_buy_id: record.id,
                id: record.coupon_id,
                money: record.coupon_money
            }
            if (selected) {
                setCoupon(tempCoupon)
            } else {
                setCoupon({})
            }
        },
    };

    const checkDetail = async (id) => {
        setModal({ ...modal, medias: true })
        await axios({
            method: 'post',
            url: `/coupon/detail/${id}`,
        }).then((res) => {
            console.log(res.data)
            if (res.data.code === 1) {
                setMediaDatas(res.data.data)
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [pagination.current, pagination.pageSize,])

    const columns = [
        {
            title: '名称', dataIndex: 'coupon_name',
        }, {
            title: '描述', dataIndex: 'coupon_description',
        }, {
            title: '抵用金额', dataIndex: 'coupon_money',
        }, {
            title: '状态',
            dataIndex: 'id',
            render: (t, data) => {
                var d = dayjs(data.end_time).diff(dayjs(), 'days');
                var e = dayjs(data.expire_time).diff(dayjs(), 'days');
                if (data.expire_time === null) {
                    e = 0
                }
                var f = dayjs(data.end_time).diff(dayjs(), 'hours');
                if ((d >= 0) && (e >= 0) && f > 0) {
                    return < Tag color='green'> 未过期</Tag >
                } else {
                    return < Tag color='red'> 已过期</Tag >
                }
            }
        }, {
            title: '购买时间', dataIndex: 'create_time',
        }, {
            title: '时效',
            dataIndex: 'end_time',
            render: (t, data) => {
                let start_time = dayjs(data.create_time).format(dateFormat)
                let end_time = dayjs(t).subtract(1, 'days').format(dateFormat)
                return <>
                    {`${start_time} - ${end_time}`}
                </>
            }
        }, {
            title: '过期时间', dataIndex: 'expire_time',
        }, {
            title: '数量', dataIndex: 'amount',
        }, {
            title: '查看', dataIndex: 'id', render: (t, data) => <Button onClick={() => checkDetail(data.coupon_id)}>查看范围</Button>,
        },
    ]

    return (
        <>
            <Layout.Content style={{ background: '#fff', padding: 24, margin: 0 }}>
                <Table
                    size='middle'
                    title={() => (
                        <>
                            <Row>
                                <Col span={12}>
                                    <Title level={4}>可用优惠券包</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'end' }}>
                                </Col>
                            </Row>
                        </>
                    )}
                    columns={columns}
                    rowSelection={rowSelection}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination, filters, sorter) => {
                        setPagination(pagination)
                    }}
                />
            </Layout.Content>
            <Modal title="查看媒体" open={modal.medias} footer={null}
                onCancel={() => resetModal()}
            >
                <Table
                    size='middle'
                    rowKey={record => record.id}
                    columns={[
                        {
                            title: '名称',
                            dataIndex: 'name',
                        }, {
                            title: '抵用金额',
                            dataIndex: 'money',
                        }, {
                            title: '数量',
                            dataIndex: 'amount',
                        },
                    ]}
                    dataSource={mediaDatas}
                ></Table>
            </Modal>
        </>
    )
}

export default UseCoupon