import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import MediaIndexOrderTable from '../mediaindex/MediaIndexOrderTable';
import { check } from '../../../utils/permission/rules';
import OrderTable from '../orders/OrderTable'
import WeiboHotOrderTable from '../weibo/WeiboHotOrderTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;


const EditorOrders = () => {

    let pageTitle = "订单审核"

    const [tab, setTab] = useState("media")
    const [orders, setOrders] = useState([])

    const role = 'CUSTOMER'
    const action = 'EDIT'
    const permission = {
        'tab:media:order': check(role, 'tab:media:order', { action }),
        'tab:index:order': check(role, 'tab:index:order', { action }),
        'tab:weibo:order': check(role, 'tab:weibo:order', { action }),
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            permission['tab:media:order'] && { key: 'media', label: `新闻媒体`, },
                            permission['tab:weibo:order'] && { key: 'weibo', label: `微博订单`, },
                            permission['tab:index:order'] && { key: 'index', label: `文字链订单`, },
                        ]}
                    />
                }
            />
            < Content >
                <div style={{ margin: 24 }}>
                    {tab === "media"
                        &&
                        <OrderTable orders={orders} setOrders={setOrders}
                            url='/order/editor/list'
                            action={action}
                        />
                    }
                    {tab === "index"
                        &&
                        <MediaIndexOrderTable
                            url='/mediaIndex/order/my_order_list'
                            action={action}
                        />
                    }
                    {tab === "weibo"
                        &&
                        <WeiboHotOrderTable
                            url='/weibo/hot/order/editor/myList'
                            action={action}
                        />
                    }
                </div>
            </Content >
        </HelmetProvider >
    )
}

export default EditorOrders;