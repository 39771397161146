import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Layout, Tabs } from 'antd';

import HomePriceTable from './HomePriceTable'

const { Content } = Layout;

const HomePriceList = () => {
    const [tab, setTab] = useState('media')

    return <HelmetProvider>
        <Helmet>
            <title>媒体列表</title>
            <meta name="description" content="查看媒体列表" />
        </Helmet>
        <Content style={{ background: '#fff', marginTop: 30 }}>
            <div style={{ maxWidth: "1200px", margin: '0 auto' }}>
                <Tabs defaultActiveKey={tab} onChange={e => setTab(e)} items={[
                    { key: 'media', label: `新闻媒体`, },
                    { key: 'mcn', label: `自媒体`, },
                    { key: '3', label: `待确认`, },
                    { key: '4', label: `待发布`, },
                    { key: '5', label: `已完成`, },
                    { key: '6', label: `已退稿`, },
                ]}
                />
                {tab === "media" && <HomePriceTable media_from="网络媒体" />}
                {tab === "mcn" && <HomePriceTable media_from="自媒体" />}
            </div>
        </Content>
    </HelmetProvider>
}

export default HomePriceList;