import React, { useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Tabs, Layout, Breadcrumb, Typography } from 'antd';
import MissionsTable from './MissionsTable';
import { Link } from 'react-router-dom'

const { Content } = Layout;
const { Title } = Typography;
const Missions = () => {

    let pageTitle = "任务中心"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <Content>
                <div className="page-content-header">
                    <Title level={4}>{pageTitle}</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/dashboard">首页</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            任务中心
                    </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div style={{ margin: 24 }}>
                    <MissionsTable />
                </div>
            </Content>
        </HelmetProvider>
    )
}

export default Missions
