import React, { useState, useEffect } from 'react'
import { Modal, message, Form, Button, Radio, Input, Skeleton, Typography } from 'antd';
import qs from 'qs';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userState } from '../../atoms/Atoms'
import { getMoney } from '../../../utils/EventUtils';


const axios = require('axios');
const { Title, Paragraph, Text } = Typography

const ResultModal = ({ id, rule }) => {
    const sessionUser = useRecoilValue(userState)
    const [modal, setModal] = useState({ rank: false, withdraw: false })
    const [withdraw, setWithdraw] = useState({})
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})

    const getRankFromMysql = async () => {
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: `/billboard/getFinalRank/${id}`,
            }).then((res) => {
                setData(res.data.data)
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        getRankFromMysql()
    }, [])

    const submitWithDraw = async (val) => {
        if (withdraw.type === 1) {
            if (val.alipay_id === "" || val.alipay_name === "") {
                message.warning("请填写支付宝信息")
                return
            }
            if (val.alipay_id === undefined || val.alipay_name === undefined) {
                message.warning("请正确填写支付宝信息,以方便提现")
                return
            }
        }
        await axios({
            method: 'post',
            url: `/withDraw/event/${id}`,
            data: qs.stringify({
                account: val.alipay_id === undefined ? "" : val.alipay_id,
                name: val.alipay_name === undefined ? "" : val.alipay_name,
                money: getMoney(data['userRank']?.id, data['eventDetail']?.userNums).toFixed(2),
                withdraw_type: withdraw.type,
                type_id: 2
            })
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功申请")
            } else {
                message.error(res.data.message)
            }
        })
    }

    return (
        <>
            {
                loading
                    ? <Skeleton />
                    : <>
                        <Title level={2}>本次活动共有{data['eventDetail']?.userNums}人达到要求</Title>
                        {
                            data['userRank']?.numbers > 0
                                ? <Paragraph>您已成功发布{data['userRank']?.numbers}篇, <Text strong>排名{data['userRank']?.id}</Text></Paragraph>
                                : <Paragraph>本次您未发布任何活动稿件</Paragraph>
                        }
                        {
                            (data['userRank']?.numbers < data['eventDetail']?.targetNums)
                                ? <Paragraph>本次活动尚未达到发布{data['eventDetail']?.targetNums}篇, 请期待本平台下次活动</Paragraph>
                                : <>
                                    <Paragraph>可分金额为{getMoney(data['userRank']?.id, data['eventDetail']?.userNums, rule)}元</Paragraph>
                                    {_.filter(data['rankList'], e => e.user > 0)[0]?.finished === 1
                                        ? <Button onClick={() => setModal({ ...modal, withdraw: true })}>申请提现</Button>
                                        : <Text strong>已申请提现</Text>}
                                </>
                        }
                    </>
            }
            <Modal open={modal.withdraw}
                onOk={() => setModal({ ...modal, withdraw: false })}
                onCancel={() => setModal({ ...modal, withdraw: false })}>
                <Form
                    name="basic"
                    initialValues={{
                        alipay_id: sessionUser.basic.alipay_id,
                        alipay_name: sessionUser.basic.alipay_name,
                    }}
                    onFinish={(val) => {
                        submitWithDraw(val)
                    }}
                    onFinishFailed={errorInfo => {
                        console.log('Failed:', errorInfo);
                    }}
                    hideRequiredMark>
                    <Form.Item label="提现方式">
                        <Radio.Group
                            value={withdraw.type}
                            onChange={(e) => {
                                setWithdraw({
                                    ...withdraw,
                                    type: e.target.value
                                })
                            }}
                        >
                            <Radio value={1}>支付宝</Radio>
                            <Radio value={3}>转换余额</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        withdraw.type === 1 &&
                        <>
                            <Form.Item
                                label="支付宝账号"
                                name="alipay_id"
                                rules={[{ required: true, message: '请填写支付宝账号！' }]}
                            >
                                <Input
                                    onBlur={e => setWithdraw({
                                        ...withdraw,
                                        alipay_id: e.target.value
                                    })} />
                            </Form.Item>
                            <Form.Item
                                label="支付宝名称"
                                name="alipay_name"
                                rules={[{ required: true, message: '请填写支付宝名称！' }]}
                            >
                                <Input
                                    onBlur={e => setWithdraw({
                                        ...withdraw,
                                        alipay_name: e.target.value
                                    })} />
                            </Form.Item>
                        </>
                    }
                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}

export default ResultModal
