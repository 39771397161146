import React, { lazy } from 'react';
import { Layout } from 'antd';
import _ from 'lodash'
import { Route, Routes, } from 'react-router-dom'
import { useRecoilValue } from 'recoil';

import DefaultHeader from './DefaultHeader';
import DefaultSidebar from './DefaultSidebar';
import Dashboard from '../compenents/dashboard/Dashboard';
import RoutesConfig from '../compenents/route/RoutesConfig'
import { sidebarCollapsed } from '../atoms/Atoms';

const ErrorPage = lazy(() => import('../common/ErrorPage'));

const { Content } = Layout
const MainLayout = () => {

    const collapsed = useRecoilValue(sidebarCollapsed)
    const routesDatas = _.flatten(_.toPairs(RoutesConfig).map(e => e[1]))

    return <Layout hasSider>
        <DefaultSidebar />
        <div className='dashboard-right' style={{ marginLeft: collapsed ? 80 : 200, }}>
            <DefaultHeader />
            <Content className='dashboard-content'>
                <Routes>
                    <Route path='' element={<Dashboard />} />
                    <Route path='error' element={<ErrorPage />} />
                    {
                        routesDatas.map(({ path, component }, key) => {
                            let relative_path = path.replace('/dashboard/', '')
                            return <Route path={relative_path} element={component} key={path} />
                        })
                    }
                </Routes>
            </Content>
        </div>
    </Layout >
}

export default MainLayout