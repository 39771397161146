import React, { useState } from 'react';
import { Tabs, Button, Drawer, message, DatePicker, Space, Statistic, Row, Col, Divider, Spin, Typography } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import FinanceTable from './FinanceTable'
import PageHeader from '../../layout/PageHeader';
import { numberFixed } from '../../../utils/NumberUtils';

const axios = require('axios');
const { Title } = Typography
const pageTitle = '财务统计'

const FinanceStats = () => {

    const [finance, setFinance] = useState(null);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const [tab, setTab] = useState('media')

    const getFinance = async (date, dateString) => {
        let params = new FormData()
        params.append('date', dateString)
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: "/finance/get/finance",
                data: params
            }).then(res => {
                if (res.data.code === 1) {
                    setFinance(res.data.data[0])
                } else {
                    message.warn(res.data.data)
                }
                setLoading(false)
            })
        }
    }


    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                extra={[
                    <Button key="1" type="primary" onClick={() => showDrawer()}>
                        资产统计
                    </Button>,
                ]}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: 'media', label: `新闻媒体`, },
                            { key: 'baike', label: `百科订单`, },
                            { key: 'index', label: `文字链订单`, },
                            { key: 'qa', label: `问答订单`, },
                        ]}
                    />
                }
            />
            <div style={{ margin: 24 }}>
                <FinanceTable tab={tab} />
            </div >

            <Drawer title="资产汇总" placement="right" size='large' onClose={onClose} open={visible}>
                <Spin tip="Loading..." spinning={loading}>
                    <Space direction="vertical">
                        <DatePicker onChange={getFinance} />
                        {
                            finance
                                ? <>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <Title level={4}>资产</Title>
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title="白条" value={finance?.loan} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title="预付款" value={finance?.prepay} />
                                        </Col>
                                        <Divider />
                                        <Col span={24}>
                                            <Title level={4}>应付债款</Title>
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title="用户余额" value={finance?.balance} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title="S币" value={finance?.coins} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title="未结算" value={finance?.unpaid_media} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title="已申请" value={finance?.pending_media} />
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <Title level={4}>总计</Title>
                                    <Statistic title="当前资产（元）" value={getTotal(finance)} />
                                </>
                                : <Title level={3}>请选择日期</Title>
                        }
                    </Space>
                </Spin>
            </Drawer>
        </HelmetProvider >
    )
}

export default FinanceStats;

function getTotal(finance) {
    const money = finance?.loan + finance?.prepay - finance?.balance - finance?.coins - finance?.unpaid_media - finance?.pending_media
    return money ? numberFixed(money) : 0
}