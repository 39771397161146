import React, { useState } from 'react'
import { Button, Layout, Input, Form, Switch, Typography } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil'

import { userState } from '../../atoms/Atoms'
const io = require('socket.io-client');

const { Content } = Layout
const { Text } = Typography

const Chat = () => {
    const sessionUser = useRecoilValue(userState)

    let img = sessionUser.basic.avatar_img
    let avatar = `http://static.yunzitui.com/upload/user/common/default.png`
    let base_img = img ? img : avatar;

    const [online, setOnline] = useState(false)
    const [data, setData] = useState([
        {
            time: '1589450577267',
            msg: 'Racing car sprays burning fuel into crowd.',
            self: false
        }, {
            time: '1589450501767',
            msg: 'Japanese princess to wed commoner.',
            self: true
        }, {
            time: '1589450401767',
            msg: 'Australian walks 100km after outback crash.',
            self: true
        }, {
            time: '1589450201767',
            msg: 'Man charged over missing wedding girl.',
            self: false
        }, {
            time: '1589424201767',
            msg: 'Los Angeles battles huge wildfires.',
            self: false
        }
    ])

    const [form] = Form.useForm();

    const socket = io('ws://localhost:9000', {
        transports: ['websocket'],
        query: `uid=${sessionUser.id}`
    })

    socket.on('connect', () => {
        console.log("connect")
        setOnline(true)
    });

    socket.on('OnMSG', (event) => {
        console.log("connect event", event)
        setData(_.concat(data, { msg: event.message, self: false, time: new Date().getTime() }))
    });

    socket.on('disconnect', () => {
        console.log("disconnect")
        setOnline(false)
    });

    function sendDisconnect() {
        socket.disconnect();
    }

    function sendMessage(msg) {
        msg = _.trimStart(msg)
        if (_.size(msg) === 0) {
            return;
        }
        let jsonObject = {
            from: sessionUser.id,
            to: 2375,
            userName: sessionUser.account,
            message: msg
        };
        socket.emit('OnMSG', jsonObject);
        // setData(_.concat(data, { msg, self: true, time: new Date().getTime() }))
        // form.setFieldsValue({ text: '' })
    }

    return (
        <div>
            {/* <List
                bordered={false}
                dataSource={data}
                renderItem={item => {
                    return item.self
                        ? <List.Item style={{ flexDirection: 'row-reverse' }}>
                            <div>
                                <Text style={{ marginRight: '16px' }}>
                                    {item.msg}
                                </Text>
                                <Avatar size={32} src={base_img} />
                            </div>
                        </List.Item>
                        : <List.Item>
                            <div>
                                <Avatar size={32} src={base_img} />
                                <Text style={{ marginLeft: '16px' }}>
                                    {item.msg}
                                </Text>
                            </div>
                        </List.Item>
                }}
            /> */}
            <Switch checked={online} />
            <div className='side-drawer'>
                <Form
                    onFinish={val => sendMessage(val['text'])}
                    form={form}
                >
                    <Form.Item name='text'>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item >
                        <Button type='primary' htmlType='submit'>Send </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Chat
