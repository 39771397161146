import React from 'react'
import { Tag, Typography, Tooltip, Button, Space } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'
import { formatTime } from '../utils/TimeUtils'

const { Text } = Typography;

export const ConsumeTableConfig = ({ action, functions }) => {

    const { deleteUserRechargeLog } = functions
    const role = useRecoilValue(userRoleState)

    const managerCol = {
        title: '用户',
        dataIndex: 'account',
        checked: 1,
    }

    const orderId = {
        title: '订单编号',
        dataIndex: 'order_no',
        checked: 1,
    }

    const consumeType = {
        title: '消费类型',
        dataIndex: 'type',
        checked: 1,
    }

    const consumeMoney = [
        {
            title: '收入金额',
            dataIndex: 'money',
            checked: 1,
            render: (val, data) => {
                return <Space direction='vertical'>
                    {data.amount > 0 && <Text style={{ color: '#03be67' }}>+{data.amount}</Text>}
                </Space>
            }
        }, {
            title: '支出金额',
            dataIndex: 'money',
            checked: 1,
            render: (t, data) => {
                return <Space direction='vertical'>
                    {data.amount < 0 && <Text style={{ color: '#e01515' }}>{data.amount}</Text>}
                </Space>
            }
        }, {
            title: '账户余额',
            dataIndex: 'total_money',
            checked: 1,
            render: (val, data) => {
                if (data.balance_id === 2) {
                    return <Space size='small'>
                        <Text>{data.balance}</Text>
                        <Text type='secondary'>(云白条额度)</Text>
                    </Space>
                } else {
                    return <Space direction='vertical'>
                        <Text>{data.balance}</Text>
                    </Space>
                }
            }
        }
    ]

    const consumeTime = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            checked: 1,
            width: 96,
            render: (t) => formatTime(t)
        },
    ]

    const handler = [
        {
            title: '操作',
            dataIndex: 'id',
            checked: 1,
            render: (t, data) => {
                return <>
                    {
                        _.indexOf([1002, 1003], data.type_id) !== -1 &&
                        <Tooltip title="撤销用户充值记录" mouseEnterDelay={0.3}>
                            <Button type='link' size='small' icon={<RollbackOutlined />} onClick={() => deleteUserRechargeLog(data.id)} />
                        </Tooltip>
                    }
                </>
            }
        },
    ]

    const invoiceState = [
        {
            title: '状态',
            dataIndex: 'id',
            checked: 1,
            width: 72,
            render: (t, data) => {
                if (data.invoice_id > 0) {
                    return <Tag className="tag-center" color="blue">已申请</Tag>
                }
                return data.in_date === 1
                    ? <Tag className="tag-center" color="green">可申请</Tag>
                    : <Tooltip title="此订单已经超过三个月有效申请期限"><Tag className="tag-center" color="red">已过期</Tag></Tooltip>
            }
        }
    ]

    let result = []

    switch (role) {
        case "CUSTOMER":
            if (action === "INVOICE") {
                result = _.concat(
                    consumeTime,
                    orderId,
                    consumeType,
                    consumeMoney,
                    invoiceState,
                )
            } else {
                result = _.concat(
                    consumeTime,
                    orderId,
                    consumeType,
                    consumeMoney,
                )
            }

            break;
        case "OPERATOR":
            result = _.concat(
                consumeTime,
                orderId,
                consumeType,
                consumeMoney,
            )
            break;
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
            result = _.concat(
                managerCol,
                consumeTime,
                orderId,
                consumeType,
                consumeMoney,
            )
            break;
        case 'FRANCHISE':
            result = _.concat(
                managerCol,
                consumeTime,
                orderId,
                consumeType,
                consumeMoney,
                handler
            )
            break;
        default:
            break;
    }
    return result
}
