import React from "react"
import CreateBaikeOrder from '../baike/CreateBaikeOrder'

const BaikeRoutesConfig = [
    {
        path: '/dashboard/baike/add',
        component: <CreateBaikeOrder />
    },
]

export default BaikeRoutesConfig