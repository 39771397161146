import React from 'react'
import _ from 'lodash'
import { Button, Tag } from 'antd'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const CouponBoughtTableConfig = ({ handleMedia }) => {

    const role = useRecoilValue(userRoleState)

    const { selectCouponPack, checkCouponPack, } = handleMedia

    const couponCol = [
        {
            title: '购买时间',
            dataIndex: 'create_time',
        },
        {
            title: '名称',
            dataIndex: 'pack_name',
        },
        {
            title: '描述',
            dataIndex: 'pack_description',
        },
        {
            title: '抵用金额',
            dataIndex: 'money',
        },
        {
            title: '失效时间',
            dataIndex: 'expire_time',
        },
        {
            title: '状态',
            dataIndex: 'is_used',
            render: (t) => {
                return t === 0
                    ? <Tag style={{ margin: 0 }} color="green">未使用</Tag>
                    : <Tag style={{ margin: 0 }} color="red">已使用</Tag>
            }
        },
        {
            title: '操作',
            dataIndex: 'id',
            render: (t, data) => {
                return (
                    <>
                        <Button.Group>
                            <Button onClick={() => selectCouponPack(data.coupon_pack_id)}>查看</Button>
                            {
                                data.is_used === 0 && <Button onClick={() => checkCouponPack(data.id)}>展开</Button>
                            }
                        </Button.Group>

                    </>
                )
            }
        },
    ]

    let result = []
    switch (role) {
        case "FINANCE":
            result = _.concat(
                couponCol,
            )
            break;
        default:
            result = _.concat(
                couponCol,
            )
            break;
    }
    return result
}
export default CouponBoughtTableConfig;
