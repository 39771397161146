import React from 'react'
import { Typography, Button, Space } from 'antd';
import { UnorderedListOutlined, EditOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const { Text } = Typography;

// 平台员工角色表
export const UserPostsTableConfig = ({ handleUsers }) => {
    const role = useRecoilValue(userRoleState)
    const defaultConfig = [
        {
            title: '职位名称',
            dataIndex: 'name',
        }, {
            title: '基本薪资',
            dataIndex: 'base_salary',
        }, {
            title: '绩效薪资',
            dataIndex: 'performance_salary',
            render: (val, data) => {
                return <Space direction='vertical'>
                    <Text>基础绩效薪资：{data.performance_salary}</Text>
                    <Text>绩效提成：{data.performance_ratio * 100}%</Text>
                </Space>
            }
        }, {
            title: '岗位薪资',
            dataIndex: 'post_salary',
        }, {
            title: '补助薪资',
            dataIndex: 'subsidized_salary',
        }, {
            title: '管理',
            dataIndex: 'id',
            render: (val, data) => {
                return <Space>
                    <Button type="link" icon={<UnorderedListOutlined />}>
                        员工列表
                    </Button>
                    <Button type="link" icon={<EditOutlined />}>
                        编辑
                    </Button>
                </Space>
            }
        },
    ]


    let result = []

    switch (role) {
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
        case 'SUPPORT_STAFF':
        case 'FINANCE':
            result = _.concat(
                defaultConfig
            )
            break;
        default:
            break;
    }
    return result
}
