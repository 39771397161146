import React, { useState, useEffect } from 'react';
import { Typography, Layout } from 'antd';
import _ from 'lodash';

const axios = require('axios');
const { Title } = Typography;
const UsePackage = ({ medias, setPackages, setDiscount }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchData = async (params = new FormData()) => {
        if (!loading) {
            setLoading(true)
            params.append("medias", medias.map(e => e.id))
            await axios({
                method: 'post',
                url: '/packages/getAvailablePacks',
                data: params
            }).then((res) => {
                let tmp = res.data.data
                setData(tmp?.map(e => {
                    return { ...e, checked: false }
                }))
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])


    const getPrice = (originPrice, newPrice) => {
        if (originPrice <= newPrice) {
            return 0;
        } else {
            return originPrice - newPrice;
        }
    }

    return (
        <>
            {
                _.size(data) > 0 && <Layout.Content style={{ background: '#fff', padding: 24, margin: 0 }}>
                    <div className='coupon-list'>
                        {
                            data.map(e => {
                                return <div key={e.id} className={e.checked ? "checked" : ""} onClick={event => {
                                    let newData = e.checked
                                        ? data.map(val => { return { ...val, checked: false } })
                                        : data.map(val => val.id === e.id ? { ...val, checked: true } : { ...val, checked: false })
                                    let tmpDiscount = _.sum(_.split(e.names, ',').map(e => {
                                        let info = _.split(e, '_')
                                        const getOriginPrice = _.find(medias, function (o) { return o.id === parseInt(info[2]) })
                                        return getPrice(getOriginPrice?.price, info[1])
                                    }))
                                    setDiscount(!e.checked ? tmpDiscount : 0)
                                    setData(newData)
                                    setPackages(e.checked ? 0 : e.id)
                                }}>
                                    <div className='coupon-left'>
                                        <Title style={{ color: '#FFF', fontSize: 20 }}>{e.name}</Title>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {
                                                _.split(e.names, ',').map(e => {
                                                    let info = _.split(e, '_')
                                                    const getOriginPrice = _.find(medias, function (o) { return o.id === parseInt(info[2]) })
                                                    return <span style={{ color: '#FFF' }} key={info[2]}>
                                                        {`${info[0]}立省${getPrice(getOriginPrice?.price, info[1])}`}
                                                    </span>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='coupon-right'>
                                        <span>满减券</span>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </Layout.Content>
            }
        </>
    )
}

export default UsePackage