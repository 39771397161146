import React, { useState, useEffect } from 'react';
import { Layout, Tabs } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from 'recoil'

import { userState } from '../../atoms/Atoms'
import SendOrderTable from './SendOrderTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;


const SendOrders = () => {
    let pageTitle = "派单列表"
    const sessionUser = useRecoilValue(userState)

    const [tab, setTab] = useState("media")
    let navigate = useNavigate();

    useEffect(() => {
        let isVip = sessionUser.sale_group && sessionUser.sale_group.vip !== 1 && sessionUser.role.attache === 3
        if (isVip) {
            navigate("/dashboard/error")
        }
    }, [])


    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: 'media', label: `新闻媒体`, },
                            { key: 'baike', label: `百科订单`, },
                            { key: 'index', label: `文字链订单`, },
                        ]}
                    />
                }
                subTitle="用于记录用户订单压款收益"
            />
            <Content>
                <div style={{ margin: 24 }}>
                    {tab === "media" && <SendOrderTable />}
                </div>
            </Content>
        </HelmetProvider>
    )
}

export default SendOrders;