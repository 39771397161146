import React, { useState } from 'react'
import { Tabs, Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CouponsBoughtTable from './CouponsBoughtTable';
import UserCouponsTable from './UserCouponsTable';
import CouponBuyList from './CouponBuyList';
import CouponsTable from './CouponsTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout
/**
 * /dashboard/coupons/center
 */
const CouponCenter = () => {

    let pageTitle = "优惠券中心"
    const [tab, setTab] = useState("1")

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: '1', label: `优惠券购买`, },
                            { key: '2', label: `优惠券包购买`, },
                            { key: '3', label: `已购买优惠券包`, },
                            { key: '4', label: `优惠券中心`, },
                        ]}
                    />
                }
            />
            <Content>
                <div style={{ margin: 24 }}>
                    {tab === '1' && <CouponsTable />}
                    {tab === '2' && <CouponBuyList />}
                    {tab === '3' && <Content style={{ background: '#fff', margin: 0, }}>
                        <CouponsBoughtTable />
                    </Content>}
                    {tab === '4' && <UserCouponsTable />}
                </div >
            </Content>
        </HelmetProvider >
    )
}

export default CouponCenter
