import React from 'react'
import { Input, Button, message, Divider, Form, Typography } from 'antd'
import qs from 'qs';
import { useRecoilValue } from 'recoil'

import { userState } from '../../atoms/Atoms'

const { Title } = Typography
const axios = require('axios');
const WithdrawInfo = () => {
    const sessionUser = useRecoilValue(userState)

    const saveAlipayInfo = async (val) => {
        await axios({
            method: 'post',
            url: '/user/alipay',
            data: qs.stringify(val)
        }).then(res => {
            if (res.data.code === 1) {
                message.success("已提交")
            }
        })
    }

    return (
        <div style={{ textAlign: 'center' }}>
            <Title level={3}>请填写实名认证的支付宝名称，以便正确打款</Title>
            <img src='/static/images/webapp/alipay-recharge.png' alt='支付宝'
                style={{ maxWidth: 400, margin: '24px auto' }} />
            <Divider />
            <div style={{ maxWidth: 400, margin: '0 auto' }}>
                <Form
                    name="basic"
                    initialValues={{
                        id: sessionUser.basic.alipay_id,
                        name: sessionUser.basic.alipay_name,
                    }}
                    onFinish={(val) => {
                        saveAlipayInfo(val)
                    }}
                    onFinishFailed={errorInfo => {
                        console.log('Failed:', errorInfo);
                    }}
                >
                    <Form.Item label="支付宝账号" name='id'>
                        <Input />
                    </Form.Item>
                    <Form.Item label="支付宝姓名" name='name'>
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>保存</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default WithdrawInfo