import User from '../user/User'
import UserRechage from '../user/UserRecharge'
import Consumes from '../consume/Consumes'
import Users from '../user/Users'
import InvoiceInfo from '../money/InvoiceInfo'
import Invoices from '../money/Invoices'
import Missions from '../missions/Missions'
import UsersLoan from '../user/UsersLoan'
import UserSalary from '../user/UserSalary'

import React from "react"
const UserRoutesConfig = [
    {
        path: '/dashboard/user',
        component: <User />
    },
    {
        path: '/dashboard/user/recharge',
        component: <UserRechage />
    },
    {
        path: '/dashboard/invoice/apply',
        component: <Invoices />
    },
    {
        path: '/dashboard/user/consume',
        component: <Consumes />
    },
    {
        path: '/dashboard/user/users',
        component: <Users />
    },
    // {
    //     path: '/dashboard/user/liars',
    //     component: <LiarUsers/>
    // },
    {
        path: '/dashboard/invoice/info',
        component: <InvoiceInfo />
    },
    {
        path: '/dashboard/missions',
        component: <Missions />
    },
    {
        path: '/dashboard/user/loan/manage',
        component: <UsersLoan />
    },
    {
        path: '/dashboard/user/salary',
        component: <UserSalary />
    }
]

export default UserRoutesConfig