import React, { useState } from 'react'
import { AlignLeftOutlined, CloudDownloadOutlined, SnippetsOutlined, SaveOutlined, ClearOutlined } from '@ant-design/icons';
import {
    Form, Layout, Steps, Button, message, Row, Col, Input, Descriptions, Radio,
    Card, Tag, Modal, Affix, Divider, Typography, Space
} from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import _ from 'lodash';
import { useSetRecoilState } from 'recoil';

import { refreshUserState } from '../../atoms/Atoms'
import { resultResponse } from '../../common/ResultResponse';
import PreviewArticle from '../articles/PreviewArticle';
import WeiboHotTable from './WeiboHotTable'
import Formatter from '../../service/Formatter'
import CKEditorInstance from '../../../utils/CkeditorInstance';
import { WEIBO } from './weiboCommon';
import { Feedback } from '../../common/CreateCommon'
import PageHeader from '../../layout/PageHeader';

const axios = require('axios');


const { Content } = Layout;
const { TextArea } = Input;
const { Text, Paragraph } = Typography;
const { Step } = Steps;
const { confirm } = Modal;
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
    layout: 'horizontal'
};


const CreateWeiboHotOrder = () => {

    const setRefresh = useSetRecoilState(refreshUserState)

    let pageTitle = "创建文章"

    const [content, setContent] = useState('')
    const [current, setCurrent] = useState(0)
    const [medias, setMedias] = useState([])
    const [order, setOrder] = useState({ title: '', remark: '', url: '' })
    const [feedback, setFeedback] = useState({})
    const [fetching, setFetching] = useState(false)
    const [visible, setVisible] = useState({ preview: false })
    const [type, setType] = useState(WEIBO.POST.id)

    const isPost = [WEIBO.POST.id, WEIBO.DUTIES_POST.id].indexOf(type) !== -1
    const isRepost = [WEIBO.REPOST.id, WEIBO.DUTIES_REPOST.id].indexOf(type) !== -1

    // 编辑器样式
    const localStorageEditorContentName = "____EditorContent____";
    const localStorageEditorContentTitleName = "____EditorContentTitle____";
    const localStorageOrderMediaName = "____OrderWeiboHot____";

    const nextPage = () => setCurrent(current + 1)
    const prevPage = () => setCurrent(current - 1)
    const resetAll = () => {
        setCurrent(0);                                  // step步数
        setMedias([]);                                  // 选择媒体信息
        setOrder({ title: '', remark: '', url: '' })    // 文章信息
        setContent('')                                  // 富文本信息
    }

    const setTitle = e => setOrder({ ...order, title: e.target.value.trim() })
    const setUrl = e => setOrder({ ...order, url: e.target.value.trim() })
    const setRemark = e => setOrder({ ...order, remark: _.trimStart(e.target.value) })

    const EditorButtons = () => <Space>
        <Button.Group>
            <Button onClick={() => Formatter({ content, setContent })}><AlignLeftOutlined />自动排版</Button>
            <Button onClick={() => setVisible({ ...visible, preview: true })}><SnippetsOutlined />预览</Button>
        </Button.Group>
        <Button.Group>
            <Button onClick={() => saveLocalStorageContent(content)}><SaveOutlined />保存草稿</Button>
            <Button onClick={() => loadLocalStorageContent()}><CloudDownloadOutlined />读取草稿</Button>
            <Button onClick={() => {
                setOrder({ title: '', remark: '', url: '', file_url: '' });
                setContent("")
            }}><ClearOutlined />重置文本</Button>
        </Button.Group>
    </Space>

    const saveLocalStorageContent = (val) => {
        localStorage.setItem(localStorageEditorContentName, val)
        localStorage.setItem(localStorageEditorContentTitleName, JSON.stringify(order))
        message.success("成功保存草稿")
    }
    const loadLocalStorageContent = () => {
        setContent(localStorage.getItem(localStorageEditorContentName))
        setOrder(JSON.parse(localStorage.getItem(localStorageEditorContentTitleName)))
        message.success("成功读取草稿")
    }
    const saveLocalStorageMedias = (val) => {
        localStorage.setItem(localStorageOrderMediaName, JSON.stringify(val))
        message.success("成功保存发稿记录")
    }

    // 发布订单
    const sendOrder = async () => {
        if (_.size(medias) === 0) { message.error("请选择媒体!"); return; }
        if (order.title === '') { message.error('请填写标题后提交！'); return }
        if (order.url === '' && content.trim() === '') { message.error('请填写内容后提交！'); return; }
        let formData = new FormData()
        const data = {
            title: order.title,
            remarks: order.remark,
            url: order.url,
            content,
            datas: JSON.stringify(_.uniqBy(medias, 'id')),
            type: type
        }
        for (let key in data) {
            if (typeof (data[key]) === 'object') {
                for (let subKey in data[key]) {
                    formData.append(`${key}.${subKey}`, data[key][subKey]);
                }
            } else {
                formData.append(key, data[key]);
            }
        }
        if (fetching === false) {
            setFetching(true)
            nextPage()
            await axios({
                method: 'post',
                url: '/weibo/hot/article',
                data: formData
            }).then((res) => {
                resultResponse(res, '已成功发布！')
                setFeedback(res.data)
                setFetching(false)
                setRefresh(true)
            })
        }
        saveLocalStorageMedias(medias)
    }

    const checkRepeat = () => {
        let params = new FormData()
        params.append("title", order.title)
        params.append("ids", medias.map(e => e.id))
        axios({
            method: 'post',
            url: '/order/repeat_check',
            data: params
        }).then((res) => {
            if (res.data.code !== 1) {
                confirm({
                    title: <Paragraph>
                        <Text strong>{res.data.data}</Text> 已发布过相同标题稿件, 请确认!
                    </Paragraph>,
                    content: '是否继续提交',
                    onOk() { nextPage() },
                    onCancel() { return false },
                })
                return false
            } else {
                nextPage()
            }
        })
    }

    // 分布发稿
    const steps = [
        {
            title: '创建稿件',
            content: (
                <div style={{ background: '#fff', padding: '24px' }}>
                    <div style={{ width: 800, margin: '0 auto' }}>
                        <Form {...layout}>
                            <Row gutter={[12, 12]}>
                                <Col span={24} key='title' >
                                    <Form.Item label="订单标题">
                                        <Input placeholder="请输入订单标题"
                                            value={order.title} onChange={setTitle} />
                                    </Form.Item>
                                </Col>
                                <Col span={24} key='type' >
                                    <Form.Item label="任务类型">
                                        <Radio.Group onChange={e => setType(e.target.value)}>
                                            {_.map(WEIBO, (v, k) => <Radio.Button key={v.id} value={v.id}>{v.name.substring(0, v.name.length - 2)}</Radio.Button>)}
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                {
                                    isRepost &&
                                    <Col span={24} key='url' >
                                        <Form.Item label='订单来源'>
                                            <Input placeholder="请输入以https://或者http://开头的转载来源链接"
                                                value={order.url} onChange={setUrl} />
                                        </Form.Item>
                                    </Col>
                                }
                                <Col span={24} key='remarks' >
                                    <Form.Item label='订单备注'>
                                        <TextArea placeholder="请输入订单备注, 如:图片注释居中, 小标题加粗, 注意排版"
                                            value={order.remark} rows={3} onChange={setRemark} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} key='format'>
                                    {isPost && <EditorButtons />}
                                </Col>
                                <Col span={12} key='next' style={{ textAlign: "right" }}>
                                    <Button type="primary" onClick={nextPage}>继续</Button>
                                </Col>
                            </Row>
                        </Form>
                        {
                            isPost && <div id='create' style={{ padding: '1px 0', margin: '8px 0' }}>
                                <CKEditorInstance content={content} setContent={setContent} />
                            </div>
                        }
                        {
                            !isRepost && <Row style={{ width: '100%' }}>
                                <Col span={24} key='next' style={{ textAlign: "right" }}>
                                    <Button type="primary" onClick={nextPage}>继续</Button>
                                </Col>
                            </Row>
                        }
                    </div>
                </div>
            ),
        }, {
            title: '选择媒体', // 传递选择媒体数据，搜索表格数据，是否读取完成数据
            content: (
                <>
                    <WeiboHotTable action="CREATE" medias={medias} setMedias={setMedias} hasTitle={true} type={type} />
                    {
                        _.size(medias) > 0
                            ? <Affix offsetBottom={24}>
                                <Card title={<Space>
                                    <MediaCardTitle medias={medias} />
                                    <Button danger onClick={() => setMedias([])}>清空媒体</Button>
                                </Space>}>
                                    {medias.map(e => <Tag className="tag-cloud" closable color="blue" key={e.id}
                                        onClose={() => setMedias(medias.filter(media => media.id !== e.id))}>
                                        {e.name}
                                    </Tag>)}
                                    <Divider />
                                    <Row>
                                        <Col span={12}>
                                            <Button onClick={prevPage}>返回</Button>
                                        </Col>
                                        <Col span={12} style={{ textAlign: "right" }}>
                                            <Button type="primary" onClick={() => {
                                                if (order.title === '') { message.error('请填写标题后提交！'); return }
                                                if (order.url === '' && content.trim() === '') { message.error('请填写内容后提交！'); return; }
                                                if (checkRepeat()) { nextPage() }
                                            }}>
                                                继续
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Affix>
                            : <div style={{ background: '#fff', padding: '24px', paddingTop: '0' }}>
                                <Row>
                                    <Col span={12}>
                                        <Button onClick={prevPage}>返回</Button>
                                    </Col>
                                    <Col span={12} style={{ textAlign: "right" }}>
                                        <Button type="primary" onClick={() => {
                                            if (_.size(medias) === 0) { message.error("请选择媒体!"); return; }
                                            if (order.title === '') { message.error('请填写标题后提交！'); return }
                                            if (order.url === '' && content.trim() === '') { message.error('请填写内容后提交！'); return; }
                                        }}>
                                            继续
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                    }
                </>
            ),
        }, {
            title: '确定下单',
            content: (
                <Space direction="vertical">
                    <div style={{ background: '#fff', padding: 24 }}>
                        <Descriptions title="订单信息">
                            <Descriptions.Item label="订单标题" span={3}>{order.title}</Descriptions.Item>
                            <Descriptions.Item label="订单备注" span={3}>{order.remark}</Descriptions.Item>
                            <Descriptions.Item label="订单来源" span={3}>{order.url}</Descriptions.Item>
                        </Descriptions>
                        <Card title={<MediaCardTitle medias={medias} />} bordered={false}>
                            {medias.map(e => <Tag className="tag-cloud" closable color="blue" key={e.id}
                                onClose={() => setMedias(medias.filter(media => media.id !== e.id))}>
                                {e.name}
                            </Tag>)}
                        </Card>
                    </div>
                    <div style={{ background: '#fff', padding: '24px' }}>
                        <Row>
                            <Col span={12}>
                                <Button onClick={prevPage}>返回</Button>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <Button type="primary" loading={fetching} onClick={sendOrder}>提交</Button>
                            </Col>
                        </Row>
                    </div>
                </Space>
            ),
        }, {
            title: '提交反馈',
            content: <Feedback fetching={fetching} feedback={feedback} resetAll={resetAll} prevPage={prevPage} />,
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}>
                <Steps current={current} size={"small"}>
                    {steps.map(item => <Step key={item.title} title={item.title} />)}
                </Steps>
            </PageHeader>
            <Content style={{ margin: '24px' }}>
                <div className="steps-content">
                    {steps[current].content}
                </div>
            </Content>
            {/* 预览文章 */}
            <Modal width={870} open={visible.preview}
                onCancel={() => setVisible({ ...visible, preview: false })}
                footer={null}
            >
                <PreviewArticle order={{ ...order, content }} />
            </Modal>
        </HelmetProvider>
    )
}

export default CreateWeiboHotOrder

const MediaCardTitle = ({ medias }) => {
    return <Space>
        <Text>已选择{_.size(medias)}个媒体</Text>
        <Text>共计{_.sum(_.map(medias, e => parseFloat(e.price)))}元</Text>
    </Space>
}