

const CkeditorPref = {
    autoGrow_minHeight: 500,
    autoGrow_maxHeight: 900,
    autoGrow_bottomSpace: 100,
    toolbar: [
        {
            name: 'document',
            items: ['Preview', 'Source']
        },
        {
            name: 'clipboard',
            items: ['Undo', 'Redo']
        },
        {
            name: 'styles',
            items: ['Format', 'Font', 'FontSize', 'RemoveFormat']
        },
        {
            name: 'colors',
            items: ['TextColor']
        },
        {
            name: 'align',
            items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
        },
        {
            name: 'basicstyles',
            items: ['Bold', 'Italic']
        },
        {
            name: 'insert',
            items: ['Image', 'Table']
        }
    ],
    extraPlugins: 'format,font,justify,autogrow,preview,uploadimage,widget,clipboard,pastefromword',
    imageUploadUrl: '/article/upload/img',
}

export default CkeditorPref