import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const QaSearchConfig = () => {
    const role = useRecoilValue(userRoleState)

    const statusSearch = [
        {
            name: 'platform',
            label: '问答平台',
            type: 'radio',
            children: [
                ['全部', ''],
                ['百度', '百度'],
                ['搜狗', '搜狗'],
                ['360', '360'],
                ['互动', '互动'],
                ['其他', '其他'],
            ]
        },
        {
            name: 'category',
            label: '问答类型',
            type: 'radio',
            children: [
                ['全部', ''],
                ['一问一答', '一问一答'],
                ['一问多答', '一问多答'],
            ]
        },
    ]

    let result = []

    switch (role) {
        case "CUSTOMER":
            result = _.concat(
                statusSearch
            )
            break;
        case "OPERATOR":
            result = _.concat(
                statusSearch,
            )
            break;
        case "SALE":
        case 'FRANCHISE':
            result = _.concat(
                statusSearch,
            )
            break;
        default:
            result = _.concat(
                statusSearch
            )
            break;
    }

    return result
}

export default QaSearchConfig