import React from "react"
import MediaIndexs from '../mediaindex/MediaIndexs'
import MediaCenter from '../medias/MediaCenter'
import MediasPrice from '../medias/MediasPrice'
import MediaBlacklist from '../medias/MediaBlacklist'
import MediaPrepay from '../medias/MediaPrepay'
import Calandar from '../medias/Calandar'

const MediaRoutesConfig = [
    {
        path: '/dashboard/media-center',
        component: <MediaCenter />
    },
    {
        path: '/dashboard/medias/price',
        component: <MediasPrice />
    },
    {
        path: '/dashboard/mediaindexs',
        component: <MediaIndexs />
    },
    {
        path: '/dashboard/medias/blacklist',
        component: <MediaBlacklist />
    },
    {
        path: '/dashboard/medias/prepay',
        component: <MediaPrepay />
    },
    {
        path: '/dashboard/medias/calandar',
        component: <Calandar />
    },

]

export default MediaRoutesConfig