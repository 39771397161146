import { message } from 'antd';
import axios from 'axios';

export const signOut = async () => {
    await axios({
        method: 'get',
        url: "/logout"
    }).then((res) => {
        if (res.data.code === 1) {  // 成功登出后 跳转
            message.success("已登出")
        }
    })
}
