import React, { useState } from 'react';
import { Layout, Tabs, Typography, Form, Row, Col, Input, Button, Radio, InputNumber, Select } from 'antd';
import _ from 'lodash';
import qs from 'qs';
import WbApiArea from '../../../../entities/WbApiArea';

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Title, Text } = Typography;
const axios = require('axios');

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const colLayout = {
    span: 24
}
const WbfollowerForm = ({ size }) => {

    const [formType, setFormType] = useState({
        ids: "60",
        iftiming: '0',
        order_speed: '2'
    })

    const fetchData = async (val) => {
        console.log(val)
        await axios({
            method: 'post',
            url: '/wbApi/wbFollower/addFans',
            data: qs.stringify(val)
        }).then((res) => {
        })
    }


    function customizer(objValue, srcValue) {
        return _.isUndefined(srcValue) ? "" : srcValue;
    }

    return (
        <Content style={{ background: "#fff", padding: '24px' }}>
            
            <div style={{ maxWidth: '960px', margin: '0 auto' }}>
                <Form
                    {...formItemLayout}
                    initialValues={{
                        ids: formType.ids,
                        iftiming: formType.iftiming,
                        order_speed: formType.order_speed,
                        area: "0",
                        fast_rate: '30'
                    }}
                    onFinish={val => fetchData(_.assignInWith([], val, customizer))}>
                    <Row gutter={[24, 24]}>
                        <Col {...colLayout}>
                            <Form.Item label="关注类型" name="ids">
                                <Radio.Group>
                                    <Radio value="60">普通关注</Radio>
                                    <Radio value="61">高级关注</Radio>
                                    <Radio value="62">顶级关注</Radio>
                                    <Radio value="63">超级关注</Radio>
                                    <Radio value="64">特级关注</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="微博地址" name="wb_url">
                                <Input />
                            </Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <Text type="secondary">输入微博首页地址，格式：http://weibo.com/u/182679**** 或个性域名或微博UID</Text>
                            </div>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="本次数量" name="order_num_total">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="时间计划" name="iftiming">
                                <Radio.Group onChange={e => setFormType({ ...formType, iftiming: e.target.value })}>
                                    <Radio value="0">自动计划</Radio>
                                    <Radio value="1">手动计划</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {
                            formType.iftiming === '1' &&
                            <>
                                <Col {...colLayout}>
                                    <Form.Item label="计划开始日期" name="startdate">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col {...colLayout}>
                                    <Form.Item label="开始时刻点" name="perhour">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col {...colLayout}>
                                    <Form.Item label="每次执行添加多少关注" name="pernum">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                        <Col {...colLayout}>
                            <Form.Item label="地区" name="area">
                                <Select placeholder="选择地区">
                                    {WbApiArea.map(e => {
                                        return <Option value={e.id} key={e.id}>{e.text}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <Text type="secondary">指定地区单价上浮300%</Text>
                            </div>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="订单速度" name="order_speed">
                                <Radio.Group onChange={e => setFormType({ ...formType, order_speed: e.target.value })}>
                                    <Radio value="1">慢速</Radio>
                                    <Radio value="2">正常</Radio>
                                    <Radio value="3">加急</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {
                            formType.order_speed === "3" &&
                            <Col {...colLayout}>
                                <Form.Item label="加急比率" name="fast_rate">
                                    <Select placeholder="请选择加急比率">
                                        <Option value="30">30%</Option>
                                        <Option value="40">40%</Option>
                                        <Option value="50">50%</Option>
                                        <Option value="60">60%</Option>
                                        <Option value="70">70%</Option>
                                        <Option value="80">80%</Option>
                                        <Option value="90">90%</Option>
                                        <Option value="100">100%</Option>
                                        <Option value="150">150%</Option>
                                        <Option value="200">200%</Option>
                                    </Select>
                                </Form.Item>
                                <div style={{ textAlign: 'center' }}>
                                    <Text type="secondary">上浮比例越高执行速度越快，选择150%及以上时无需排队</Text>
                                </div>
                            </Col>
                        }
                        <Col {...colLayout}>
                            <Form.Item label="备注" name="note">
                                <TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Content>
    )
}

export default WbfollowerForm;