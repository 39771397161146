import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import _ from 'lodash'
import { MCNs, mediaChannelTypes, areas, mediaDoorTypes, mediaEffectTypes } from '../../Apps/common/media/MediaInfo'

import { userRoleState } from '../../Apps/atoms/Atoms'
const axios = require('axios');

const MediaSearchConfig = ({ action = '', type }) => {
    const role = useRecoilValue(userRoleState)

    const [creator, setCreator] = useState([])
    const getCreators = async () => {
        await axios({
            method: 'get',
            url: `/media/getAllEditor`,
        }).then((res) => {
            if (res.data.code === 1) {
                let data = res.data.data;
                data = data.map(e => {
                    return {
                        'id': e.creator_id,
                        'name': e.account
                    }
                })
                setCreator(data)
            }
        })
    }

    useEffect(() => {
        if (role === 'OPERATOR') {
            getCreators()
        }
    }, [])

    const mcnType = [
        {
            name: 'door_type',
            label: '门户类型',
            placeholder: '请输入媒体名称',
            type: 'select',
            children: _.concat([''], MCNs.map(e => e.text))
        }, {
            name: 'auth_type',
            label: '认证类型',
            type: 'radio',
            children: [
                ['全部', ''],
                ['官方认证', '1'],
                ['个人认证', '2'],
                ['未认证', '3'],
            ]
        }, {
            name: 'available_time',
            label: '媒体时效',
            type: 'radio',
            children: [
                ['全部', ''],
                ['一天', '1'],
                ['三天', '2'],
                ['一周', '3'],
                ['一个月', '4'],
                ['一个月以上', '5'],
            ]
        }, {
            name: 'fans_num',
            label: '粉丝数',
            type: 'radio',
            children: [
                ['全部', ''],
                ['少于1000', '1'],
                ['1000~5000', '2'],
                ['5000~1万', '3'],
                ['1万~5万', '4'],
                ['5万~10万', '5'],
                ['10万~50万', '6'],
                ['50万以上', '7'],
            ]
        }, {
            name: 'page_views',
            label: '阅读量',
            type: 'radio',
            children: [
                ['全部', ''],
                ['少于1000', '1'],
                ['1000~5000', '2'],
                ['5000~1万', '3'],
                ['1万~5万', '4'],
                ['5万~10万', '5'],
                ['10万以上', '6'],
            ]
        },
    ]

    const normalType = [
        {
            name: 'door_type',
            label: '门户类型',
            placeholder: '请输入媒体名称',
            type: 'select',
            children: _.concat([''], mediaDoorTypes)
        }, {
            name: 'effect',
            label: '收录效果',
            type: 'radio',
            children: _.concat([['全部', '']], mediaEffectTypes.map(e => [e, e]))
        }
    ]

    const conmmonSearch = [
        {
            name: 'name',
            label: '媒体名称',
            placeholder: '请输入媒体名称',
            type: 'input'
        }, {
            name: 'channel_type',
            label: '频道类型',
            placeholder: '请输入频道类型',
            type: 'select',
            children: _.concat([''], mediaChannelTypes)
        }, {
            name: 'area',
            label: '媒体地区',
            placeholder: '请输入地区',
            type: 'select',
            children: _.concat([''], areas)
        }
    ]

    const mediaType = {
        name: 'media_from',
        label: '媒体类型',
        type: 'radio',
        children: [
            ['全部', ''],
            ['网络媒体', '网络媒体'],
            ['自媒体', '自媒体'],
        ]
    }

    const defaultSearch = _.concat(
        conmmonSearch,
        type === '自媒体' ? mcnType : normalType,
        type === '' && mediaType,
        {
            name: 'prices',
            label: '价格范围',
            type: 'range',
            datas: [
                {
                    name: 'min_price',
                    label: '最低价格',
                    placeholder: '最低价格',
                    type: 'input'
                },
                {
                    name: 'max_price',
                    label: '最高价格',
                    placeholder: '最高价格',
                    type: 'input'
                },
            ]
        }
    )


    const operatorSearch = [
        {
            name: 'creator_name',
            label: '创建者名称',
            placeholder: '请输入创建者名称',
            type: 'input'
        },
        {
            name: 'editor_name',
            label: '所属编辑',
            placeholder: '请输入编辑名称',
            type: 'input'
        },
        {
            name: 'exclude_creator',
            label: '排除创建者名称',
            type: 'select',
            value: [],
            mode: 'multiple',
            children: creator
        },
    ]

    const statusSearch = [
        {
            name: 'status',
            label: '媒体状态',
            type: 'radio',
            children: [
                ['全部', ''],
                ['正常', '1'],
                ['暂停', '2'],
            ],
            value: '1'
        }, {
            name: 'week_available',
            label: '周末可发',
            type: 'radio',
            children: [
                ['全部', ''],
                ['周末可发', '周末可发'],
                ['周末休息', '周末休息'],
            ]
        }, {
            name: 'media_icons',
            label: '媒体标签',
            type: 'radio',
            children: [
                ['全部', ''],
                ['荐', '荐'],
                ['秒', '秒'],
                ['独', '独'],
                ['惠', '惠'],
                ['优', '优'],
                ['新', '新'],
                ['包', '包'],
                ['奖', '奖'],
                ['活', '活'],
                ['热', '热'],
            ]
        },
    ]

    const mediaTypeSearch = [
        {
            name: 'include_type',
            label: '媒体类型',
            placeholder: '请输入媒体名称',
            type: 'radio',
            children: [
                ['全部', ''],
                ['A类', 'A类'],
                ['B类', 'B类'],
                ['C类', 'C类'],
                ['D类', 'D类'],
                ['E类', 'E类'],
                ['F类', 'F类'],
                ['G类', 'G类'],
                ['H类', 'H类'],
                ['Z类', 'Z类'],
            ]
        }
    ]

    const manageSearch = [
        {
            name: 'checked',
            label: '审核状态',
            type: 'radio',
            children: [
                ['全部', ''],
                ['已通过', '1'],
                ['未通过', '2'],
            ]
        },
    ]

    let result = []
    switch (role) {
        case "CUSTOMER":
            if (action === 'EDIT') {
                result = _.concat(
                    defaultSearch,
                    statusSearch,
                    manageSearch
                )
            } else {
                result = _.concat(
                    defaultSearch,
                    statusSearch
                )
            }

            break;
        case "OPERATOR":
        case "OPERATOR_LEADER":
            result = _.concat(
                defaultSearch,
                mediaTypeSearch,
                operatorSearch,
                statusSearch,
                manageSearch,
            )
            break;
        case "SALE":
        case 'FRANCHISE':
        case "SALE_LEADER":
        case "SALE_ATTACHE":
            result = _.concat(
                defaultSearch,
                mediaTypeSearch,
                statusSearch,
            )
            break;
        default:
            result = _.concat(
                defaultSearch,
                statusSearch
            )
            break;
    }

    return result
}

export default MediaSearchConfig