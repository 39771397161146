import React, { useState, useEffect, Suspense } from 'react';
import { Row, Col, Form, Button, Spin } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import dayjs from 'dayjs'
import _ from 'lodash'
import PageHeader from '../../layout/PageHeader';

import SearchForm from '../../common/SearchForm';
const axios = require('axios');
const OrderStatsChart = React.lazy(() => import("./OrderStatsChart.js"));

const OrderStats = () => {
    let pageTitle = "订单统计"

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm();
    const reloadTable = () => form.submit()
    const searchDatas = [
        {
            name: 'customer_name',
            label: '客户名称',
            placeholder: '请输入客户名称',
            type: 'input'
        }, {
            name: 'sale_name',
            label: '销售名称',
            placeholder: '请输入销售名称',
            type: 'input'
        }, {
            name: 'media_name',
            label: '媒体名称',
            placeholder: '请输入媒体名称',
            type: 'input'
        }, {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: null
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        }, {
            name: 'type',
            label: '时间类型',
            type: 'radio',
            value: 'month',
            children: [
                ['月', 'month'],
                ['日', 'day'],
            ]
        }
    ]
    const initialFormData = _.reduce(searchDatas, (result, data) => {
        return _.assign(result, { [data.name]: data.value ?? '' })
    }, {})

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: "/order/statsCount",
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data)
                setLoading(false)
            })
        }

    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <div style={{ margin: 24 }}>
                <div style={{ background: '#fff', padding: 24, margin: '0 0 24px 0' }}>
                    <Form
                        layout='vertical'
                        form={form}
                        initialValues={initialFormData}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                    >
                        <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                        <Row gutter={[12, 12]}>
                            <Col span={12} />
                            <Col span={12} key='next' style={{ textAlign: "right" }}>
                                <Button type="primary" htmlType="submit">继续</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div style={{ display: 'flex', placeContent: 'center', width: '100%' }}>
                    <Suspense fallback={<div style={{ height: '100%', width: '100%', display: 'grid', placeContent: 'center' }}><Spin /></div>}>
                        <OrderStatsChart data={data} loading={loading} span={form.getFieldValue('type') === 'month' ? 12 : 24} />
                    </Suspense>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default OrderStats;