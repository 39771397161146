import React, { useState, useEffect } from 'react'
import { Button, Layout, Input, Modal, Form, Menu, Tree, Checkbox, Space, Divider, Typography, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import _ from 'lodash'
import qs from 'qs'
import { resultResponse } from '../../common/ResultResponse';
import PageHeader from '../../layout/PageHeader';
import { aliasArray } from './RoleAlias'

const axios = require('axios');

const { Title } = Typography
const { Content } = Layout
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const Role = () => {

    let pageTitle = '权限管理'

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState([])
    const [role, setRole] = useState()
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [permission, setPermission] = useState([])
    const [modal, setModal] = useState({})
    const [filter, setFilter] = useState([])
    const [form] = Form.useForm();
    const [menuItems, setMenuItems] = useState([])

    const getRoles = async () => {
        await axios({
            method: 'post',
            url: "/role/getRoleList",
        }).then(res => {
            let data = res.data.data
            setData(data)
            setMenuItems(_.reduce(data, (result, e) => {
                return _.concat(result,
                    {
                        label: e.role_name,
                        key: e.name
                    }
                )
            }, []))
        })
    }

    const getPermission = async () => {
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: "/role/getPermission",
            }).then(res => {
                let data = res.data.data
                let strs = data.map(e => ({ alias: e.alias, id: e.id }))
                let result = _.reduce(strs, (res, str) => {
                    let keys = _.split(str.alias, '.')
                    if (_.size(filter) === 0 || (_.size(filter) > 0 && _.indexOf(filter, keys[0])) > -1) {
                        let id = str.id
                        let arr = _.reduce(keys, (r, name, i, array) => {
                            let key = setKey(i, array)
                            let arr = _.find(r, e => e.key === key)
                            if (!arr) {
                                if (i + 1 === _.size(array)) {
                                    r.push({ title: getName(key), key: key, id: id, children: [] })
                                } else {
                                    r.push({ title: getName(key), key: key, disableCheckbox: true, children: [] })
                                }
                            }
                            return _.find(r, e => e.key === key).children
                        }, res)
                        return _.concat(res, arr)
                    } else {
                        return res
                    }
                }, [])
                setResult(result)
                setPermission(data)
                setLoading(false)
            })
        }
    }

    const getName = (key) => {
        let array = _.find(aliasArray, e => e.alias === key)
        return array?.name ?? key
    }

    const setKey = (idx, array) => {
        let str = _.slice(array, 0, idx + 1)
        return _.join(str, '.')
    }

    useEffect(() => {
        getRoles()
    }, [])

    useEffect(() => {
        getPermission()
    }, [filter])


    const changePermission = (id) => {
        let array = _.find(data, e => e.name === id)
        setRole(array?.id)
        if (array) {
            setSelectedKeys(_.map(array.permissions, e => e.alias))
        } else {
            setSelectedKeys([])
        }
    }

    const savePermission = async () => {
        let ids = _.reduce(selectedKeys, (r, k) => {
            let arr = _.find(permission, e => e.alias === k)
            return _.concat(r, arr.id)
        }, [])

        let formData = new FormData()
        formData.append('ids', ids)
        formData.append('role_id', role)
        await axios({
            method: 'post',
            url: "/role/bind",
            data: formData
        }).then(res => {
            resultResponse(res, '已成功保存')
        })
        getRoles()
    }

    const createPermission = async (val) => {
        await axios({
            method: 'post',
            url: "/role/permission/add",
            data: qs.stringify(val)
        }).then(res => {
            resultResponse(res, '已成功创建')
            setModal({ ...modal, permission: false })
            getPermission()
        })
    }

    const options = [
        { label: '用户', value: 'user' },
        { label: '媒体', value: 'media' },
        { label: '百科', value: 'baike' },
        { label: '收藏夹', value: 'collection' },
        { label: '媒体订单', value: 'order' },
    ];



    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                extra={
                    <Space>
                        <Button onClick={() => {
                            setModal({ ...modal, permission: true })
                            form.setFieldsValue({})
                        }} type='primary'>新建权限</Button>
                        <Button onClick={savePermission} type='primary'>新建角色</Button>
                        <Button onClick={savePermission} type='primary'>保存</Button>
                    </Space>
                }
            />
            <div style={{ margin: '24px' }}>
                <Content className="content">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Menu
                            onClick={e => changePermission(e.key)}
                            style={{ maxWidth: '256px', marginRight: '16px', flex: '1' }}
                            mode="inline"
                            items={menuItems}
                        />
                        <div style={{ flex: '1' }}>
                            <div>
                                <Title level={4}>筛选</Title>
                                <Checkbox.Group options={options} disabled={loading} onChange={e => setFilter(e)} />
                            </div>
                            <Divider />
                            {
                                loading
                                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                                    : <Tree
                                        selectable={false}
                                        checkable
                                        defaultExpandAll={true}
                                        onCheck={(checkedKeys) => setSelectedKeys(checkedKeys)}
                                        checkedKeys={selectedKeys}
                                        treeData={result}
                                    />
                            }
                        </div>
                    </div>
                </Content>
            </div>
            <Modal title="创建权限" open={modal.permission} footer={null}
                onCancel={() => setModal({ ...modal, permission: false })}
            >
                <Form
                    name="add-coupon" {...formItemLayout}
                    onFinish={(values) => createPermission(values)}
                    form={form}
                >
                    <Form.Item label="名称" name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label="层级" name="translate">
                        <Input />
                    </Form.Item>
                    <Form.Item label="简写" name="alias">
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

        </HelmetProvider>
    )
}

export default Role
