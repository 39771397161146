import React, { useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Layout, Tabs } from 'antd';
import UserTable from './UserTable'
import { useRecoilValue } from 'recoil'

import { userState } from '../../atoms/Atoms'
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;


const Users = () => {

    let pageTitle = "用户列表"

    const sessionUser = useRecoilValue(userState)
    const attache = sessionUser.role.attache

    const [tab, setTab] = useState("user")

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: 'user', label: `用户列表`, },
                            attache === 0 && { key: 'leader', label: `组长列表`, },
                            (attache === 0 || attache === 2) && { key: 'attache', label: `组员列表`, },
                        ]}
                    />
                }
            />
            <Content style={{ margin: 24 }}>
                {tab === "user" && <UserTable />}
                {tab === "leader" && <UserTable dispatch="leader" />}
                {tab === "attache" && <UserTable dispatch="attache" />}
            </Content>
        </HelmetProvider>
    )
}

export default Users
