import React from 'react'
import { Typography, Button, Switch, message, Popconfirm } from 'antd';
import {
    FolderOpenOutlined, CloseOutlined, CheckOutlined,
    PlusOutlined, DeleteOutlined, EditOutlined,
    UploadOutlined, UpSquareOutlined, DownSquareOutlined
} from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'

const { Text } = Typography
const EventTableConfig = ({ handleFunctions, setSelectId }) => {
    const role = useRecoilValue(userRoleState)

    const { getEvent, delEvent, eventAddMedia,
        eventDelMedia, handleStatus, finishEvent,
        updateProirity, eventCheckRule } = handleFunctions;

    const nameCol = {
        title: '活动名称',
        dataIndex: 'name',
    }

    const timeCol = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
        }, {
            title: '时间',
            dataIndex: 'start_time',
            render: (t, data) => {
                return <>
                    {t}<br />
                    {data.end_time}
                </>
            }
        },
    ]

    const statusCol = [
        {
            title: '活动状态',
            dataIndex: 'status',
            render: (t, data) => {
                let checkCol = t === 1
                    ? <Button onClick={() => handleStatus(data.id, 'status', 0)} style={{ backgroundColor: '#3dbd7d', color: '#ffffff' }}>
                        <CheckOutlined />
                    </Button>
                    : <Button onClick={() => handleStatus(data.id, 'status', 1)} style={{ backgroundColor: '#f04134', color: '#ffffff' }}>
                        <CloseOutlined />
                    </Button>
                return checkCol
            }
        }, {
            title: '是否公开',
            dataIndex: 'is_public',
            render: (t, data) => {
                let status = t === 1;
                let value = t === 1 ? 0 : 1;
                return (
                    <>
                        <Switch checkedChildren="正常" unCheckedChildren="停用" defaultChecked={status}
                            onChange={() => {
                                handleStatus(data.id, 'is_public', value)
                            }} />
                    </>
                )
            }
        }, {
            title: '开启排名',
            dataIndex: 'is_rank',
            render: (t, data) => {
                let status = t === 1;
                let value = t === 1 ? 0 : 1;
                return (
                    <>
                        <Switch checkedChildren="正常" unCheckedChildren="停用" defaultChecked={status}
                            onChange={() => {
                                handleStatus(data.id, 'is_rank', value)
                            }} />
                    </>
                )
            }
        },
    ]

    const infoCol = [
        {
            title: '描述',
            dataIndex: 'description',
        },
        {
            title: '优先级',
            dataIndex: 'priority',
            render: (t, data) => {
                return (
                    <div>
                        <Text>优先级: {t}</Text>
                        <br />
                        <Button.Group>
                            <Button size="small" type="link" icon={<UpSquareOutlined />} onClick={() => updateProirity(data.id, 'add')}></Button>
                            <Button size="small" type="link" icon={<DownSquareOutlined />} onClick={() => updateProirity(data.id, 'minus')}></Button>
                        </Button.Group>
                    </div>
                )
            }
        },
    ]

    const handleCol = [
        {
            title: '编辑',
            dataIndex: 'id',
            render: (t, data) => {
                let d = dayjs(data.end_time).diff(dayjs(), 'hours');
                let is_out_of_time = d >= 0 ? false : true
                return <>
                    <Button.Group>
                        <Button onClick={() => getEvent(data.id)}><EditOutlined />编辑</Button>
                        <Button onClick={() => {
                            eventCheckRule()
                            setSelectId(data.id)
                        }}><EditOutlined />绑定</Button>
                        <Button onClick={() => {
                            eventAddMedia(data.id)
                            setSelectId(data.id)
                        }}><PlusOutlined />添加</Button>
                        <Button onClick={() => {
                            eventDelMedia(data.id)
                            setSelectId(data.id)
                        }
                        }><FolderOpenOutlined />查看</Button>
                        <Popconfirm
                            title="是否确认删除该活动？"
                            onConfirm={() => delEvent(data.id)}
                            onCancel={() => message.warn('已取消')}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button><DeleteOutlined />删除</Button>
                        </Popconfirm>
                        {
                            (data.is_rank === 1 && is_out_of_time) &&
                            <Popconfirm
                                title="是否完成该活动？"
                                onConfirm={() => finishEvent(data.id)}
                                onCancel={() => message.warn('已取消')}
                                okText="确认"
                                cancelText="取消"
                            >
                                <Button><UploadOutlined />完成</Button>
                            </Popconfirm>
                        }

                    </Button.Group>
                </>;
            }
        },
    ]

    let result = []
    switch (role) {
        case "OPERATOR":
            result = _.concat(
                nameCol,
                timeCol,
                statusCol,
                infoCol,
                handleCol,
            )
            break;
        case "SALE_LEADER":
            result = _.concat(
                nameCol,
                timeCol,
                statusCol,
                infoCol,
                handleCol,
            )
            break;
        case "SALE_ATTACHE":
            result = _.concat(
                nameCol,
                timeCol,
                statusCol,
                infoCol,
                handleCol,
            )
            break;
        case "SALE":
        case "FRANCHISE":
            result = _.concat(
                nameCol,
                timeCol,
                statusCol,
                infoCol,
                handleCol,
            )
            break;
        default:
            break;
    }
    return result
}
export default EventTableConfig;
