import React, { useEffect } from 'react'
import { Form, Row, Button, Space } from 'antd';
import _ from 'lodash'

import CreateForm from '../../common/CreateForm';
import WeiboHotDatasConfig from '../../../role/weibo/WeiboHotDatasConfig';


const AddWeiboHotForm = (props) => {
    const { media, drawerAction } = props;
    const { visible, setVisible, createMedia, updateMedia, fileList, setFileList } = drawerAction

    const Datas = WeiboHotDatasConfig()
    const customizer = (objValue, srcValue) => _.isUndefined(srcValue) ? "" : srcValue;

    const [form] = Form.useForm()

    useEffect(() => {
        let data = media
        if (media.tag) {
            data = { ...media, tag: _.split(media.tag, ',') }
        }
        form.setFieldsValue(data)
    }, [media, form])

    const closeModal = () => setVisible({ ...visible, addMedia: false })

    return (
        <>
            <Form layout='vertical' form={form} name="basic"
                onFinish={(val) => {
                    let data = _.assignInWith(media, val, customizer)
                    data = { ...data, tag: _.join(data.tag, ',') }
                    if (visible.action === 'ADD') {
                        createMedia(data)
                    } else if (visible.action === 'EDIT') {
                        updateMedia(data)
                    }
                }}
            >
                {Datas.map(data => <Row gutter={16} key={data[0].name}>
                    <CreateForm datas={data} span={12} fileList={fileList} setFileList={setFileList} />
                </Row>
                )}
                <div style={{ display: 'flex', flexDirection: 'row-reverse', margin: '8px 0' }}>
                    <Space>
                        <Button onClick={closeModal}>取消</Button>
                        <Button htmlType='submit' type='primary'>提交</Button>
                    </Space>
                </div>
            </Form>
        </>
    )
}

export default AddWeiboHotForm