import React, { useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Menu, Row, Col, Spin, Button, Modal, Form, Input } from 'antd';
import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import qs from 'qs';
import _ from 'lodash'

import TagTable from './TagTable'
import { TagTypes } from './TagTypes';
import { resultResponse } from '../../common/ResultResponse';
import PageHeader from '../../layout/PageHeader';

const axios = require('axios');

const Tags = (props) => {

    let pageTitle = "标签管理"

    const [key, setKey] = useState('1')
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState({})
    const [tagTypeForm] = Form.useForm();
    const [tableLoading, setTableLoading] = useState(false)
    const [menuItems, setMenuItems] = useState([])


    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async () => {
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/tag/types',
            }, {
                cancelToken: source.token
            }).then((res) => {
                let data = res.data
                setLoading(false)
                setMenuItems(_.reduce(data, (result, e) => {
                    return _.concat(result,
                        {
                            label: e.name,
                            key: e.id
                        }
                    )
                }, []))
            })
        }
    }
    useEffect(() => {
        fetchData()
    }, [])

    const createTagType = async (val) => {
        await axios({
            method: 'post',
            url: '/tag/add/type',
            data: qs.stringify(val)
        }).then((res) => {
            resultResponse(res, '已成功')
            fetchData()
        })
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                extra={[
                    <Button type="primary" key='add-tag-type' onClick={() => setModal({ ...modal, addType: true })}>
                        <PlusOutlined />添加标签分类
                    </Button>,
                    <Button type="primary" key='select-tag-type' onClick={() => setModal({ ...modal, types: true })}>
                        <UnorderedListOutlined />查看标签分类
                    </Button>
                ]}
            />
            <div style={{ margin: 24 }}>
                <Row gutter={12}>
                    <Col flex="156px">
                        {
                            loading
                                ? <Spin style={{ height: '100%', background: "white", width: '100%', paddingTop: 200 }} />
                                : <Menu
                                    onClick={e => setKey(e.key)}
                                    style={{ height: '100%' }}
                                    selectedKeys={key}
                                    mode="inline"
                                    items={menuItems}
                                />
                        }
                    </Col>
                    <Col flex="auto">
                        <TagTable type={key} tableLoading={tableLoading} setTableLoading={setTableLoading} />
                    </Col>
                </Row>
            </div>
            <Modal
                title="创建标签分类"
                okText="创建"
                cancelText="取消"
                open={modal.addType}
                onOk={() => {
                    tagTypeForm.validateFields()
                        .then(values => {
                            createTagType(values);
                            tagTypeForm.resetFields();
                            setModal({ ...modal, addType: false })
                        }).catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
                onCancel={() => {
                    tagTypeForm.resetFields();
                    setModal({ ...modal, addType: false })
                }}
                maskClosable={false}
            >
                <Form form={tagTypeForm} layout='vertical'>
                    <Form.Item label="标签分类" name='name'>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title='标签分类'
                open={modal.types}
                footer={[
                    <Button key="submit" type="primary"
                        onClick={() => setModal({ ...modal, types: false })}>
                        确认
                    </Button>,
                ]}
                onOk={() => setModal({ ...modal, types: false })}
                onCancel={() => setModal({ ...modal, types: false })}
                destroyOnClose={true}
            >
                <TagTypes />
            </Modal>
        </HelmetProvider>
    )
}

export default Tags
