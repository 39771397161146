import React from 'react'
import { Tag, Typography, Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import _ from 'lodash'
import qs from 'qs'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { formatTimeToDay } from '../../utils/TimeUtils'
import { resultResponse } from '../../Apps/common/ResultResponse';

const axios = require('axios');
const { Text } = Typography

export const SendOrderTableConfig = ({ modal, setModal, handleOrders }) => {
    const role = useRecoilValue(userRoleState)
    const { selectOrders, finishSaleOrder } = handleOrders;
    const size = 'small'

    const deleteSendOrder = async (id) => {
        await axios({
            method: 'delete',
            url: `/sendOrder/delete/${id}`,
        }).then((res) => {
            resultResponse(res, '已成功删除')
        })
    }

    const handleSendOrder = async (id, state) => {
        await axios({
            method: 'post',
            url: `/sendOrder/handle`,
            data: qs.stringify({ id, state })
        }).then((res) => {
            resultResponse(res, '已成功操作')
        })
    }

    const handleConfig = [
        {
            title: '派单对象',
            dataIndex: 'target_account',
            checked: 1,
            render: (t, data) => {
                if (_.isNil(t) || t === "") {
                    return <Space>
                        <Text>暂未分配</Text>
                        <Button type="link" onClick={() => {
                            setModal({ ...modal, send: true, targetId: data.id })
                        }}>分配</Button>
                    </Space>
                } else {
                    return t
                }
            }
        }
    ]
    const defaultConfig = [
        {
            title: '名称',
            dataIndex: 'name',
            checked: 1,
        }, {
            title: '认领价格',
            dataIndex: 'price',
            checked: 1,
        }, {
            title: '预计收益',
            dataIndex: 'benefit',
            checked: 1,
        }, {
            title: '备注',
            dataIndex: 'remark',
            checked: 1,
        }
    ]

    const orderTime = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            checked: 1,
            render: (t) => formatTimeToDay(t)
        },
        {
            title: '预计回款时间',
            dataIndex: 'target_time',
            width: 96,
            checked: 1,
            render: (t) => formatTimeToDay(t)
        },
    ]

    const orderStatus = {
        title: '状态',
        dataIndex: 'state',
        width: 72,
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            let result = '';
            switch (text) {
                case 'WAITING':
                    result = <Tag style={{ margin: 0 }} color="gold">待派发</Tag>;
                    break;
                case 'PENDING':
                    result = <Tag style={{ margin: 0 }} color="blue">待支付</Tag>;
                    break;
                case 'SUCCESS':
                    result = <Tag style={{ margin: 0 }} color="orange">已支付</Tag>;
                    var d = dayjs(data.target_time).diff(dayjs(), 'days');
                    if (d < 0) {
                        result = <Tag style={{ margin: 0 }} color="geekblue">可结算</Tag>;
                    }
                    break;
                case 'UNPAID':
                    result = <Tag style={{ margin: 0 }} color="blue">已申请</Tag>;
                    break;
                case 'COMPLETE':
                    result = <Tag style={{ margin: 0 }} color="green">已完成</Tag>;
                    break;
                case 'FAILED':
                    result = <Tag style={{ margin: 0 }} color="red">已取消</Tag>;
                    break;
                default:
                    break;
            }
            return result
        }
    }

    const handleCol = [
        {
            title: '操作',
            dataIndex: 'id',
            checked: 1,
            fixed: 'right',
            render: (t, data) => {
                return (
                    <Space direction='vertical'>
                        <Text code >订单数量: {data.total}</Text>
                        {
                            role !== 'CUSTOMER' &&
                            <Button.Group>
                                {
                                    data.state === 'WAITING' &&
                                    <>
                                        <Popconfirm
                                            title="是否确认删除"
                                            onConfirm={() => deleteSendOrder(data.id)}
                                            onCancel={() => { console.log('取消') }}
                                            okText="确认"
                                            cancelText="取消"
                                        >
                                            <Button size={size}><DeleteOutlined />删除</Button>
                                        </Popconfirm>
                                        <Button size={size} onClick={() => selectOrders(data.id)}><SettingOutlined />关联订单</Button>
                                    </>
                                }

                                <Button size={size} onClick={() => {
                                    setModal({ ...modal, orders: true, targetId: data.id })
                                }}><UnorderedListOutlined />查看</Button>
                            </Button.Group>
                        }
                    </Space>
                )
            }
        },
        {
            title: '处理订单',
            dataIndex: 'id',
            checked: 1,
            fixed: 'right',
            render: (t, data) => {
                switch (role) {
                    case "SALE":
                    case "SALE_LEADER":
                    case 'FRANCHISE':
                        return (
                            <Space>
                                {
                                    data.state === 'PENDING' &&
                                    <>
                                        <Button type='primary' onClick={() => {
                                            setModal({ ...modal, targetId: data.id, finish: true })
                                        }}>完成</Button>
                                        <Button danger onClick={() => finishSaleOrder(data.id, "FAILED", 0)}>取消</Button>
                                    </>
                                }
                            </Space>
                        )
                    case "CUSTOMER":
                        return (
                            <Space>
                                {
                                    data.state === 'PENDING' &&
                                    <>
                                        <Button type='primary' size='small' onClick={() => handleSendOrder(data.id, "accept")}>接单</Button>
                                        <Button danger size='small' onClick={() => handleSendOrder(data.id, "decline")}>拒绝</Button>
                                    </>
                                }
                            </Space>
                        )
                    default:
                        break;
                }
            }
        }
    ]

    let result = []

    switch (role) {

        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case "SALE":
        case 'FRANCHISE':
            result = _.concat(
                handleConfig,
                defaultConfig,
                orderTime,
                orderStatus,
                handleCol,
            )
            break;
        case "CUSTOMER":
            result = _.concat(
                defaultConfig,
                orderTime,
                orderStatus,
                handleCol,
            )
            break;
        default:
            break;
    }
    return result
}
