import React from "react"
import NewWithdraw from '../withdraw/Withdraw'
import WithdrawManage from '../withdraw/WithdrawManage'

import StatisticsCenter from '../statistics/StatisticsCenter'

import FinanceStats from '../finance/FinanceStats'
import FinanceOrderCenter from '../finance/order/FinanceOrderCenter'

import SalesInvoice from '../money/SalesInvoice'
import InvoicesList from '../money/InvoicesList'
import EnterpriseInvoice from '../money/EnterpriseInvoice'
import FinanceSalary from '../finance/FinanceSalary'

const FinanceRoutesConfig = [
    {
        path: '/dashboard/invoices/list',
        component: <InvoicesList />
    }, {
        path: '/dashboard/withdraw/v2/list',
        component: <NewWithdraw />
    }, {
        path: '/dashboard/withdraw/all/list',
        component: <WithdrawManage />
    }, {
        path: '/dashboard/statistics',
        component: <StatisticsCenter />
    }, {
        path: '/dashboard/finance/income/stats',
        component: <FinanceStats />
    }, {
        path: '/dashboard/finance/sale-order/center',
        component: <FinanceOrderCenter />
    }, {
        path: '/dashboard/order/workorder/invoice/apply',
        component: <SalesInvoice />
    }, {
        path: '/dashboard/enterprise/invoice',
        component: <EnterpriseInvoice />
    }, {
        path: '/dashboard/finance/salary/stats',
        component: <FinanceSalary />
    },
]

export default FinanceRoutesConfig