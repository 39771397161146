import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const BaikeOrderSearchConfig = () => {
    const role = useRecoilValue(userRoleState)

    const defaultSearch = [
        {
            name: 'title',
            label: '标题',
            placeholder: '请输入标题',
            type: 'input'
        },
        {
            name: 'baike_name',
            label: '百科名称',
            placeholder: '请输入百科名称',
            type: 'input'
        },
    ]

    const timeSearch = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: null
        },
        {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        },
    ]

    const operatorSearch = [
        {
            name: 'sale_name',
            label: '所属销售',
            placeholder: '请输入销售名称',
            type: 'input'
        },
    ]
    const saleSearch = [
        {
            name: 'sale_name',
            label: '所属销售',
            placeholder: '请输入销售名称',
            type: 'input'
        },
        {
            name: 'customer_name',
            label: '所属客户',
            placeholder: '请输入客户名称',
            type: 'input'
        },
    ]

    let result = []

    switch (role) {
        case "CUSTOMER":
            result = _.concat(
                defaultSearch,
                timeSearch
            )
            break;
        case "OPERATOR":
        case "OPERATOR_BAIKE":
            result = _.concat(
                defaultSearch,
                operatorSearch,
                timeSearch
            )
            break;
        case "SALE":
        case 'FRANCHISE':
            result = _.concat(
                defaultSearch,
                saleSearch,
                timeSearch
            )
            break;
        default:
            break;
    }

    return result
}

export default BaikeOrderSearchConfig