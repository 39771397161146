import UserRoutesConfig from './UserRoutesConfig'
import OrderRoutesConfig from './OrderRoutesConfig'
import MediaRoutesConfig from './MediaRoutesConfig'
import ResourceRoutesConfig from './ResourceRoutesConfig'
import NoticeRoutesConfig from './NoticeRoutesConfig'
import EventRoutesConfig from './EventRoutesConfig'
import LevelRoutesConfig from './LevelRoutesConfig'
import FinanceRoutesConfig from './FinanceRoutesConfig'
import CouponRoutesConfig from './CouponRoutesConfig'
import BaikeRoutesConfig from './BaikeRoutesConfig'
import MediaIndexRoutesConfig from './MediaIndexRoutesConfig'
import QARoutesConfig from './QARoutesConfig'
import StatisticsRoutesConfig from './StatisticsRoutesConfig'
import RoleRoutesConfig from './RoleRoutesConfig'
import TagsRoutesConfig from './TagsRoutesConfig'
import PackagesRoutesConfig from './PackagesRoutesConfig'
import WeiboRoutesConfig from './WeiboRoutesConfig'
import WechatRoutesConfig from './WechatRoutesConfig'
import XiaohongshuRoutesConfig from './XiaohongshuRoutesConfig'
import DouyinRoutesConfig from './DouyinRoutesConfig'

const RoutesConfig = {
    UserRoutesConfig,
    OrderRoutesConfig,
    MediaRoutesConfig,
    ResourceRoutesConfig,
    NoticeRoutesConfig,
    EventRoutesConfig,
    LevelRoutesConfig,
    FinanceRoutesConfig,
    CouponRoutesConfig,
    BaikeRoutesConfig,
    MediaIndexRoutesConfig,
    QARoutesConfig,
    StatisticsRoutesConfig,
    RoleRoutesConfig,
    TagsRoutesConfig,
    PackagesRoutesConfig,
    WeiboRoutesConfig,
    WechatRoutesConfig,
    XiaohongshuRoutesConfig,
    DouyinRoutesConfig,
}

export default RoutesConfig