import React from 'react'
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageHeader from '../../layout/PageHeader';

import InvoicesTable from './InvoicesTable';

const { Content } = Layout;

const InvoicesList = () => {
    let pageTitle = "发票提现"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
            />
            <Content style={{ margin: 24 }}>
                <InvoicesTable />
            </Content>
        </HelmetProvider>
    );
}
export default InvoicesList