import React, { useEffect, useState } from 'react'
import { List, Alert, Card, Row, Col, Typography, Skeleton } from 'antd';
import _ from 'lodash'
import { getMoney } from '../../../utils/EventUtils';

const axios = require('axios');


const { Title, Paragraph, Text } = Typography

const RankModal = ({ id, rule }) => {

    const [loading, setLoading] = useState(false)
    const [datas, setDatas] = useState({})

    const getRank = async () => {
        setLoading(true)
        await axios({
            method: 'post',
            url: `/billboard/getRank/${id}`,
        }).then((res) => {
            setDatas(res.data.data)
        })
        setLoading(false)
    }

    useEffect(() => {
        getRank()
    }, [])


    return (
        <>
            {
                loading
                    ? <Skeleton />
                    : <>
                        {/* 用户提交稿件 */}
                        {
                            datas['orderInfo']?.map(e => {
                                return (
                                    <React.Fragment key={e.id}>
                                        <Alert style={{ marginBottom: '8px' }} message={`${e.time}, 用户${e.user}成功发布了一篇${e.media}!`} type="success" />
                                    </React.Fragment>
                                )
                            })
                        }
                        <Row gutter={[24, 24]} >
                            <Col span={12}>
                                <Card title="订单排行榜">
                                    <List
                                        dataSource={datas['userList']}
                                        renderItem={item => (
                                            <List.Item key={item.id}>
                                                <Title level={4}>{item.id}</Title>
                                                <Paragraph>{item.name} {item.numbers}篇</Paragraph>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>
                            {
                                id !== 146 &&
                                <Col span={12}>
                                    <Card title="媒体排行榜">
                                        <List
                                            dataSource={datas['mediaList']}
                                            renderItem={item => (
                                                <List.Item key={item.id}>
                                                    <Title level={4}>{item.id}</Title>
                                                    <Paragraph>{item.name} {item.numbers}篇</Paragraph>
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                </Col>
                            }
                            {
                                id === 146 &&
                                <Col span={12}>
                                    <Card title="积分排行榜">
                                        <List
                                            dataSource={datas['pointList']}
                                            renderItem={item => (
                                                <List.Item key={item.id}>
                                                    <Title level={4}>{item.id}</Title>
                                                    <Paragraph>{item.name} {item.numbers}积分</Paragraph>
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                </Col>
                            }
                        </Row >
                        <div style={{ textAlign: 'center' }}>
                            <Title level={4}>
                                已发{datas['userRank']?.numbers}篇，
                            {
                                    datas['userRank']?.id === -1
                                        ? `目前尚无排名`
                                        : `当前排名${datas['userRank']?.id}`
                                }
                            </Title>
                            <Text>当前已有{datas['eventDetail']?.userNums}人达到要求</Text> <br />
                            {
                                (datas['userRank']?.numbers < datas['eventDetail']?.targetNums)
                                    ? <Text strong>还需发布{datas['eventDetail']?.targetNums - datas['userRank']?.numbers}篇, 才达到目标!</Text>
                                    : <Text strong>可分金额为{getMoney(datas['userRank']?.id, datas['eventDetail']?.userNums, rule)}元</Text>
                            }
                        </div>
                    </>
            }
        </>
    )
}

export default RankModal
