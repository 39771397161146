import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Form, Layout, message, Modal, Popconfirm, Radio, Row, Table, Space, Typography, InputNumber } from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import { User } from '../../../entities/User';
import UserSearchConfig from '../../../role/user/UserSearchConfig';
import { UserTableConfig } from '../../../role/user/UserTableConfig';
import { resultResponse } from '../../common/ResultResponse';
import SearchForm from '../../common/SearchForm';
import { initFormData, tablePagination, TableTitle } from '../../common/tableCommon';
import AddUserForm from './AddUserForm';
import UserManagerTable from './UserManagerTable';
import ConsumeTable from '../consume/ConsumeTable'
import UserChildTable from './UserChildTable';
import InvoiceForm from '../money/InvoiceForm';
import { check } from '../../../utils/permission/rules';

const { Content } = Layout;
const { Text } = Typography;

const layout = { layout: 'vertical' };
const axios = require('axios');
const initialModal = {
    type: '', addUser: false, recharge: false, id: '',
    editUser: false, group: false, sale_group_id: '',
    changeManage: false, consume: false, loan: false
}
const UserTable = ({ searchFormDisplay = true, url, dispatch = 'customer' }) => {
    const role = useRecoilValue(userRoleState)

    const permission = {
        'module:user:manage:consume': check(role, 'module:user:manage:consume'),
        'module:user:add:customer': check(role, 'module:user:add:customer'),
        'module:user:add:attache': check(role, 'module:user:add:attache'),
        'module:user:add:leader': check(role, 'module:user:add:leader'),
    }

    const searchDatas = UserSearchConfig({ miniMode: false })
    const initialFormData = initFormData(searchDatas)

    const [form] = Form.useForm()
    const [rechargeForm] = Form.useForm()
    const [postForm] = Form.useForm()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [user, setUser] = useState(User)
    const [modal, setModal] = useState(initialModal)
    const [loanMoney, setLoanMoney] = useState(0)
    const [saleGroup, setSaleGroup] = useState([])
    const [saleGroupId, setSaleGroupId] = useState(0)
    const [posts, setPosts] = useState([]);
    const [postLoading, setPostLoading] = useState(false);

    const reloadTable = () => form.submit()
    const resetUserForm = () => setUser(User)
    const resetModal = () => setModal(initialModal)

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        if (dispatch === 'leader') {
            params.append("attache", 2)
        } else if (dispatch === 'attache') {
            params.append("attache", 1)
        } else {
            params.append("attache", 3)
        }
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: url || '/user/getUserList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])


    let action = 'add_customer', buttonText = '添加客户';
    if (dispatch === 'leader') {
        action = 'add_sale_group';
        buttonText = '添加组长';
    } else if (dispatch === 'attache') {
        action = 'add_attache';
        buttonText = '添加专员';
    }

    const createUser = async () => {
        await axios({
            method: 'post',
            url: `/user/add?action=${action}`,
            data: qs.stringify(user)
        }).then((res) => {
            resultResponse(res, "已成功添加")
            resetUserForm()
            reloadTable()
        })
    }

    const getUser = async (id) => {
        setModal({ ...modal, addUser: true, type: 'EDIT' })
        await axios({
            method: 'post',
            url: `/user/asyn/detail/${id}`,
            data: qs.stringify(user)
        }).then((res) => {
            let data = res.data.data
            setUser(_.pick(data, _.keysIn(user)))
        })
    }

    const editUser = async () => {
        await axios({
            method: 'post',
            url: `/user/update`,
            data: qs.stringify(user)
        }).then((res) => {
            if (res.data.code === '200') {
                message.success("已成功修改")
            } else {
                message.error(res.data.message)
            }
            reloadTable()
        })
    }

    const drawerAction = { visible: modal, setVisible: setModal, createUser, editUser, resetUserForm, resetVisible: resetModal }

    // 删除用户
    const deleteUser = async (id) => {
        await axios({
            method: 'post',
            url: `/user/freeze/${id}`,
        }).then((res) => {
            resultResponse(res, "已成功删除用户")
            reloadTable()
        })
    }

    // 重置密码
    const resetPassword = async (id) => {
        await axios({
            method: 'post',
            url: `/user/resetPass`,
            data: qs.stringify({ id })
        }).then((res) => {
            resultResponse(res, "已成功重置密码")
        })
    }

    // 用户修改状态
    const handleUserStatus = async (id, key, val) => {
        await axios({
            method: 'post',
            url: `/user/handle`,
            data: qs.stringify({ id, [key]: val })
        }).then((res) => {
            resultResponse(res, "已成功修改")
            reloadTable()
        })
    }

    // 用户充值
    const balanceRecharge = async (id, text, type) => {
        await axios({
            method: 'post',
            url: `/user/addMoney`,
            data: qs.stringify({ money: text, user_id: id, type: type })
        }).then((res) => {
            resultResponse(res, "已成功充值")
            reloadTable()
        })
    }

    // 获取用户分组
    const getUserSaleGroup = async ({ platform_id }) => {
        let params = new FormData()
        if (dispatch === 'leader') {
            params.append('type', 1);
        } else if (dispatch === 'attache') {
            params.append('type', 2);
        } else {
            params.append('type', 3);
            params.append('platform_id', platform_id)
        }
        params.append('length', 25);
        await axios({
            method: 'post',
            url: `/sale_group/saleGroupsList`,
            data: params
        }).then((res) => {
            if (res.data.code === 1) {
                setSaleGroup(res.data.data)
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    async function getUserPost() {
        setPostLoading(true)
        await axios({
            method: 'post',
            url: `/user/salary/posts`,
        }).then((res) => {
            setPostLoading(false)
            if (res.data.code === 1) {
                setPosts(res.data.data)
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    // 编辑用户分组
    const editUserSaleGroup = async () => {
        await axios({
            method: 'post',
            url: `/user/edit/saleGroup`,
            data: qs.stringify({
                sell_group_id: saleGroupId,
                id: modal.id
            })
        }).then((res) => {
            resultResponse(res, '已成功修改')
            reloadTable()
        })
    }

    const applyLoan = async (id) => {
        await axios({
            method: 'get',
            url: `/user/loan/apply/${id}`
        }).then(res => {
            resultResponse(res, '已成功开通')
            reloadTable()
        })
    }

    const pauseLoan = async (id) => {
        await axios({
            method: 'post',
            url: `/user/loan/pause/${id}`
        }).then(res => {
            resultResponse(res, '已成功修改')
            reloadTable()
        })
    }

    const modifyLoan = async (id, money) => {
        await axios({
            method: 'post',
            url: `/user/loan/modify`,
            data: qs.stringify({
                user_id: id,
                money: money
            })
        }).then(res => {
            resultResponse(res, '已成功修改')
            reloadTable()
        })
    }

    const modifyUserInvoice = (id) => setModal({ ...modal, invoice: true, id: id })

    const handleUsers = {
        getUserSaleGroup, resetPassword, getUserPost,
        deleteUser, getUser, handleUserStatus,
        applyLoan, modifyUserInvoice, pauseLoan, reloadTable
    }

    let defaultColumns = UserTableConfig({ visible: modal, setVisible: setModal, handleUsers, dispatch })
    const localStorageColumnsName = "____UserTableConfig____";
    let localStorageColumns = JSON.parse(localStorage.getItem(localStorageColumnsName))
    _.forEach(defaultColumns, (e) => {
        _.forEach(localStorageColumns, (val) => {
            if (e.title === val.title) {
                e.checked = val.checked
            }
        })
    })
    const [columns, setColumns] = useState(defaultColumns)

    return <>
        <Content className='table-search'>
            {searchFormDisplay && <div>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                    <Divider style={{ margin: '8px 0' }} />
                    <Row>
                        <Col span={12}>
                            {
                                ((permission['module:user:add:leader'] && dispatch === 'leader')
                                    || (permission['module:user:add:attache'] && dispatch === 'attache')
                                    || permission['module:user:add:customer']) &&
                                <Button type="primary" onClick={() => setModal({ ...modal, addUser: true, type: 'ADD' })}>
                                    <PlusOutlined />{buttonText}
                                </Button>
                            }
                            {
                                (permission['module:user:manage:consume'] && Boolean(dispatch === 'customer')) &&
                                <Button type="text" onClick={() => setModal({ ...modal, consume: true, user_id: '' })}>
                                    查看消费记录
                                </Button>
                            }
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>}
        </Content>
        <Content style={{ background: '#fff', padding: 24, margin: 0, }}        >
            <Table
                size="small"
                title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} />}
                columns={columns.filter(e => e.checked === 1)}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
                summary={pageData => <Summary data={pageData} role={role} columns={columns} />}
            />
        </Content>
        {/* 创建用户 */}
        <Drawer
            title={modal.type === 'EDIT' ? '编辑用户' : '创建用户'}
            width={720}
            onClose={() => {
                resetModal()
                resetUserForm()
            }}
            open={modal.addUser}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <AddUserForm user={user} drawerAction={drawerAction} type={modal.type} />
        </Drawer>
        {/* 充值 */}
        <Modal
            title="充值" open={modal.recharge}
            footer={null}
            onCancel={() => resetModal()}
        >
            <Form
                form={rechargeForm}
                onFinish={values => {
                    resetModal()
                    rechargeForm.resetFields()
                    balanceRecharge(modal.id, values?.money, values?.type)
                }}
                initialValues={{ type: 0, money: 0 }}
            >
                <Form.Item label="税点" name='type'>
                    <Radio.Group>
                        <Radio value={0}>不含税点</Radio>
                        <Radio value={1}>含税点</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="金额" name='money'>
                    <InputNumber min={0} />
                </Form.Item>
                <Divider />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        确认
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        {/* 修改用户额度 */}
        <Modal
            title="充值" open={modal.loan}
            footer={null}
            onCancel={() => resetModal()}
        >
            <InputNumber type="text" value={loanMoney} onChange={e => {
                if (e < 0) {
                    message.error("请输入大于0的数值")
                    return
                }
                return setLoanMoney(e)
            }} />
            <Divider />
            <div style={{ textAlign: "right" }}>
                <Popconfirm
                    title={`确认修改当前用户可用额度为${loanMoney}吗`}
                    onConfirm={() => {
                        resetModal()
                        modifyLoan(modal.id, loanMoney)
                    }}
                    onCancel={() => console.log('取消')}
                    okText="确认"
                    cancelText="取消"
                >
                    <Button type='primary'>修改</Button>
                </Popconfirm>
            </div>
        </Modal>
        {/* 用户组 */}
        <Modal
            title="分组管理" open={modal.group}
            onOk={() => {
                resetModal()
                editUserSaleGroup()
            }}
            onCancel={() => resetModal()}
        >
            <Radio.Group onChange={(e) => setSaleGroupId(e.target.value)}>
                {saleGroup.map(e => <Radio value={e.id} key={e.id}>{e.sale_name}</Radio>)}
            </Radio.Group>
        </Modal>
        <Modal title="选择管理" open={modal.changeManage} footer={null} onCancel={() => resetModal()}        >
            <UserManagerTable uid={modal.id} resetVisible={resetModal} reloadTable={reloadTable} dispatch={dispatch} />
        </Modal>
        <Modal title="选择子账号" open={modal.child} footer={null} onCancel={() => resetModal()}        >
            <UserChildTable uid={modal.id} resetVisible={resetModal} reloadTable={reloadTable} />
        </Modal>
        <Modal
            title="查看消费详情" open={modal.consume} width={1200}
            footer={null}
            onCancel={() => resetModal()}
            destroyOnClose={true}
        >
            <ConsumeTable searchEntity={{ user_id: modal.id }} />
        </Modal>
        <Modal
            title="修改发票详情" open={modal.invoice} width={1200}
            onOk={() => resetModal()}
            onCancel={() => resetModal()}
            destroyOnClose={true}
        >
            <InvoiceForm uid={modal.id} />
        </Modal>
        <Modal
            title="修改职位" open={modal.post}
            loading={postLoading}
            onOk={() => {
                handleUserStatus(modal.id, 'post_id', postForm.getFieldsValue()['post_id'])
                resetModal()
            }}
            onCancel={() => resetModal()}
        >
            <Form form={postForm}>
                <Form.Item label="职位" name='post_id'>
                    <Radio.Group>
                        {
                            posts.map(post => {
                                return <Radio value={post.id} key={post.id}>{post.name}</Radio>
                            })
                        }
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    </>;
}


export default UserTable

const Summary = ({ data, role, columns }) => {
    let totalCost = _.sumBy(data, 'available_money');
    let totalLoan = _.sumBy(data, 'money');
    let totalLoanLimit = _.sumBy(data, 'limit_money');
    let totalNum = _.size(columns.filter(e => e.checked === 1))
    switch (role) {
        case 'SALE':
        case 'FRANCHISE':
        case 'SALE_LEADER':
        case 'SALE_ATTACHE':
            return <tr>
                <td />
                <th>汇总</th>
                <td colSpan={totalNum - 1}>
                    <Space>
                        <span>总余额: <Text type="danger">{totalCost.toFixed(2)}</Text></span>
                        <span>总负债: <Text type="danger">{(totalLoanLimit - totalLoan).toFixed(2)}</Text></span>
                    </Space>
                </td>
            </tr>
        default:
            return null
    }
}