const Balance = {
    available_money: 0,
    earn_money: 0,
    invoice_money: 0,
    total_earn_money: 0,
    total_money: 0,
    total_invoice_money: 0,
}

const Basic = {
    alipay_id: '',
    alipay_name: '',
    avatar_img: '',
    coins: 0,
    exp: 0,
    level: 0,
    points: 0,
    qq: '',
    real_name: '',
    tel: '',
    total_coins: 0,
}

const Manager = {
    manager_avatar: '',
    manager_name: '',
    manager_qq: '',
    platform_avatar: '',
    platform_name: '',
    platform_qq: '',
}

const Preference = {
    notice_status: 0
}

const Role = {
    attache: -1,
    enterprise_id: 0,
    role_id: 0,
    role: 'PUBLIC'
}

const sale_group = {
    vip: 0,
    expired_time: '',
    sale_group_name: '',
    sale_group_id: 0,
}

const User = {
    account: '',
    balance: Balance,
    basic: Basic,
    id: null,
    manager: Manager,
    preference: Preference,
    role: Role,
    sale_group
}

export default User