import React, { useState, useEffect } from 'react'
import { Table, Row, Col, Modal, Popconfirm, Layout, Typography, Card, Empty, message, Form, Select, Tag, Button, Input } from 'antd';
import _ from 'lodash'
import { BarsOutlined, ShoppingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import qs from 'qs';
import { useRecoilValue } from 'recoil'

import { userState } from '../../atoms/Atoms'
import { mediaChannelTypes } from '../../common/media/MediaInfo'

const axios = require('axios');
const { Option } = Select;
const { Content } = Layout
const { Text, Paragraph } = Typography;
const colLayout = {
    xs: 24, sm: 12,
    md: 12, lg: 8,
    xl: 8, xxl: 6,
}

const CouponBuyList = () => {
    const sessionUser = useRecoilValue(userState)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState({ detail: false })
    const [couponsLoading, setCouponsLoading] = useState(false)
    const [couponsDatas, setCouponsDatas] = useState([])
    const resetModal = () => setModal({ detail: false })

    const fetchData = async (value) => {
        setLoading(true)
        await axios({
            method: 'post',
            url: '/couponPack/all',
            data: qs.stringify(value)
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
        })
    }

    const buyCouponPack = async (id) => {
        await axios({
            method: 'post',
            url: `/couponPack/buy/${id}`,
        }).then((res) => {
            console.log(res)
            if (res.data.code === 1) {
                message.success("已成功购买")
            } else if (res.data.code === 90002) {
                message.warning("以达到购买上线")
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const getCouponPack = async (id) => {
        setModal({ ...modal, detail: true })
        setCouponsLoading(true)
        await axios({
            method: 'post',
            url: `/couponPack/select/${id}`,
        }).then((res) => {
            if (res.data.code === 1) {
                setCouponsDatas(res.data.data)
                setCouponsLoading(false)
            } else {
                message.warning(res.data.msg)
            }
        })
    }


    useEffect(() => {
        fetchData()
    }, [])



    return (
        <>
            <Content className='table-search'>
                <Form name="nest-messages"
                    initialValues={{ channel_type: "" }}
                    onFinish={value => fetchData(value)}
                >
                    <Row gutter={[24, 24]}>
                        <Col {...colLayout}>
                            <Form.Item name="media_name" label="媒体名称">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item name="channel_type" label="媒体类型">
                                <Select>
                                    <Option value="">全部</Option>
                                    {
                                        mediaChannelTypes.map(e => <Option value={e} key={e}>{e}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
            <Content className='table-search'>
                {_.size(data) === 0
                    ? <Empty />
                    : <>
                        <Row gutter={[24, 24]}>
                            {data.map(e => {
                                return <React.Fragment key={e.id}>
                                    <Col {...colLayout} >
                                        <Card
                                            title={<div>
                                                {e.pack_name}
                                            </div>}
                                            hoverable
                                            style={{ marginBottom: '16px' }}
                                            actions={[
                                                <Popconfirm
                                                    title={`确认购买${e.pack_name}吗?`}
                                                    onConfirm={() => buyCouponPack(e.id)}
                                                    onCancel={e => console.log(e)}
                                                    okText="确认"
                                                    cancelText="取消">
                                                    <ShoppingOutlined key="shop" /> 购买
                                                        </Popconfirm>,
                                                <React.Fragment>
                                                    <span onClick={() => getCouponPack(e.id)}>
                                                        <BarsOutlined key="info" /> 查看详情
                                                            </span>
                                                </React.Fragment>
                                            ]}>
                                            <Paragraph>
                                                <Text>下线时间: {e.expire_time}</Text>
                                            </Paragraph>
                                            {sessionUser.sale_group.vip === 0
                                                ? <>
                                                    <Paragraph style={{ fontSize: '14px' }}>
                                                        <Text style={{ fontSize: '24px', color: '#1890ff', marginRight: 8 }}>原价:￥{e.price}</Text>
                                                        <Text delete style={{ fontSize: '18px' }}>会员价: ￥{e.price_vip_1}</Text>
                                                    </Paragraph>
                                                    <Paragraph>
                                                        <Text strong>普通用户限购{e.max_nums}份,会员限购{e.max_vip_nums}份</Text>
                                                    </Paragraph>
                                                </>
                                                : <>
                                                    <Paragraph style={{ fontSize: '18px' }}>
                                                        <Text style={{ fontSize: '24px', color: '#1890ff', marginRight: 8 }}>会员价: ￥{e.price_vip_1}</Text>
                                                        <Text delete style={{ fontSize: '18px' }}>原价: ￥{e.price}</Text>
                                                    </Paragraph>
                                                    <Paragraph>
                                                        <Text strong>会员限购{e.max_vip_nums}份,普通用户限购{e.max_nums}份</Text>
                                                    </Paragraph>
                                                </>
                                            }
                                            <Paragraph ellipsis={{ rows: 3, expandable: true }}>{e.pack_description}</Paragraph>
                                            {
                                                e.expire_day !== undefined &&
                                                <Paragraph>
                                                    <Text type="secondary" >当前卡包购买后,须在{e.expire_day}天内使用,过期则作废</Text>
                                                </Paragraph>
                                            }
                                        </Card>
                                    </Col>
                                </React.Fragment>
                            })}
                        </Row>
                    </>}
            </Content>

            <Modal title="查看优惠券包" open={modal.detail} footer={null}
                onCancel={() => resetModal()}
                width={1080}
            >
                <Table
                    size='middle'
                    rowKey={record => record.id}
                    loading={couponsLoading}
                    columns={[
                        {
                            title: '名称',
                            dataIndex: 'coupon_name',
                        }, {
                            title: '描述',
                            dataIndex: 'coupon_description',
                        }, {
                            title: '抵用金额',
                            dataIndex: 'money',
                        }, {
                            title: '抵用数量',
                            dataIndex: 'amount',
                        }, {
                            title: '创建时间',
                            dataIndex: 'create_time',
                        }, {
                            title: '失效时间',
                            dataIndex: 'end_time',
                        }, {
                            title: '状态',
                            dataIndex: 'id',
                            render: (t, data) => {
                                var d = dayjs(data.end_time).diff(dayjs(), 'days');
                                if (d >= 0) {
                                    return < Tag color='green'> 未过期</Tag >
                                } else {
                                    return < Tag color='red'> 已过期</Tag >
                                }
                            }
                        }
                    ]}
                    dataSource={couponsDatas}
                ></Table>
            </Modal>
        </>
    )
}

export default CouponBuyList