import React, { useState, useEffect } from 'react';
import { PlusOutlined, SearchOutlined, UploadOutlined, ExportOutlined } from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Divider, Drawer, message, Layout, Modal, Spin, Upload, Input, Typography, Space } from 'antd';
import _ from 'lodash'
import qs from 'qs';

import AddWeiboHotForm from './AddWeiboHotForm';
import { getPrice, WEIBO } from './weiboCommon'

import SearchForm from '../../common/SearchForm'
import { initFormData, tablePagination, TableTitle } from '../../common/tableCommon';
import { resultResponse } from '../../common/ResultResponse';
import WeiboHotSearchConfig from '../../../role/weibo/WeiboHotSearchConfig';
import WeiboHotTableConfig from '../../../role/weibo/WeiboHotTableConfig'
import RoleModule from '../../../utils/permission/RoleModule';
import WeiboEditorTable from './WeiboEditorTable'

const { Text } = Typography;
const { Content } = Layout;
const axios = require('axios');
const layout = { layout: 'vertical' };

const WeiboHotTable = ({ action, medias = [], setMedias = () => console.log(""), url, type = null }) => {
    const [form] = Form.useForm();
    const [saleGroupForm] = Form.useForm();

    const searchDatas = WeiboHotSearchConfig({ type })
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const [visible, setVisible] = useState({ addMedia: false, action: 'ADD' })
    const [media, setMedia] = useState({})
    const [drawerLoading, setDrawerLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [modal, setModal] = useState({ download: false, saleGroupPrice: false, editor: false, target: '' })
    const [saleGroupDatas, setSaleGroupDatas] = useState([])
    const [saleGroupLoading, setSaleGroupLoading] = useState(false)

    const reloadTable = () => form.submit()
    const resetModal = () => setModal({ download: false, saleGroupPrice: false, editor: false, target: '' })
    const resetMedia = () => setMedia({})

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val)
                .filter(e => e[1].length > 0)
                .forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: url || '/weibo/hot/resource/list',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    // 初始化媒体分配价格
    useEffect(() => {
        if (_.size(saleGroupDatas) > 0) {
            let result = {}
            saleGroupDatas.forEach(e => {
                let prices = _.split(e.prices, ',')
                prices.forEach(price => {
                    let tmp = _.split(price, '_')
                    _.assign(result, {
                        [`${tmp[0]}_price_${e.sale_group_id}`]: tmp[1]
                    })
                })
            })
            saleGroupForm.setFieldsValue(result)
        }
    }, [saleGroupDatas, saleGroupForm])


    const createMedia = async (media) => {
        const formData = new FormData();
        _.toPairs(media).forEach(e => {
            _.identity(e[1]) && formData.append(`${e[0]}`, e[1])
        })
        if (!drawerLoading) {
            setDrawerLoading(true)
            await axios({
                method: 'post',
                url: '/weibo/hot/addWeiBoHot',
                data: formData
            }).then((res) => {
                resultResponse(res, '已成功添加')
                setDrawerLoading(false)
                resetMedia()
                reloadTable()
                resetModal()
            })
        }
    }

    const updateMedia = async (media) => {
        const formData = new FormData();
        _.toPairs(media).forEach(e => {
            _.identity(e[1]) && formData.append(`${e[0]}`, e[1])
        })
        if (!drawerLoading) {
            setDrawerLoading(true)
            await axios({
                method: 'post',
                url: '/weibo/hot/addWeiBoHot',
                data: formData
            }).then((res) => {
                resultResponse(res, '已成功修改')
                setDrawerLoading(false)
                resetMedia()
                reloadTable()
                resetModal()
            })
        }
    }

    const updateAll = async (media) => {
        await axios({
            method: 'get',
            url: '/weibo/hot/update/all',
        }).then((res) => {
        })
    }

    const downloadMediasPrice = async () => {
        setModal({ ...modal, download: true })
        let params = new FormData()
        let val = _.pickBy(form.getFieldsValue(), _.identity);
        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        await axios({
            url: `/weibo/hot/downloadPrice`, //your url
            method: 'post',
            responseType: 'blob', // important
            data: params
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '媒体价格分配.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }

    const props = {
        name: 'upload_file',
        action: '/weibo/hot/importPrice',
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} 上传成功!`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败!`);
            }
        },
    };

    const drawerAction = { visible, setVisible, createMedia, updateMedia, fileList, setFileList }

    const getMedia = async (id) => {
        setVisible({ ...visible, addMedia: true, action: 'EDIT' })
        if (!drawerLoading) {
            setDrawerLoading(true)
            await axios({
                method: 'post',
                url: url || `/weibo/hot/resource/list`,
                data: qs.stringify({ id })
            }).then((res) => {
                let tempMedia = res.data.data[0]
                setMedia(tempMedia)
                setDrawerLoading(false)
            })
        }
    }

    const deleteMedia = async (id) => {
        await axios({
            method: 'post',
            url: `/weibo/hot/delete`,
            data: qs.stringify({ id })
        }).then((res) => {
            resultResponse(res, '已成功删除')
            reloadTable()
        })
    }

    const getTitle = () => {
        let title = '添加微博';
        if (visible.action === 'ADD') {
            title = '添加微博'
        } else if (visible.action === 'EDIT') {
            title = '编辑微博'
        }
        return title
    }

    const handleStatus = async (id, key, val) => {
        await axios({
            method: 'post',
            url: `/mediaIndex/editMediaIndex`,
            data: qs.stringify({
                id,
                is_common: 'yes',
                [key]: val
            })
        }).then((res) => {
            resultResponse(res, '已成功修改')
            reloadTable()
        })
    }

    const getSaleGroupPrice = async (id, type) => {
        if (!saleGroupLoading) {
            setSaleGroupLoading(true)
            setModal({ ...modal, saleGroupPrice: true })
            await axios({
                method: 'get',
                url: `/weibo/hot/getSaleGroupPrices?id=${id}&type=${type}`,
            }).then(res => {
                if (res.data.code === 1) {
                    setSaleGroupDatas(res.data.data)
                    setSaleGroupLoading(false)
                } else {
                    message.error(res.data.data)
                }
            })
        }
    }

    const updateSaleGroupPrice = async (val) => {
        if (!saleGroupLoading) {
            setSaleGroupLoading(true)
            await axios({
                method: 'post',
                url: `/weibo/hot/update/saleGroupPrice`,
                data: qs.stringify(val)
            }).then(res => {
                if (res.data.code === 1) {
                    message.success("已成功更新")
                    setModal({ ...modal, saleGroupPrice: false })
                    setSaleGroupLoading(false)
                } else {
                    message.error(res.data.msg)
                }
            })
        }
    }

    // 修改资源所属
    const allotUser = async (mid, uid) => {
        await axios({
            method: 'post',
            url: '/weibo/hot/update/editor',
            data: qs.stringify({
                resource_id: mid,
                uid: uid
            })
        }).then((res) => {
            resultResponse(res, '已成功绑定')
            resetModal()
            reloadTable()
        })
    }

    const changeEditor = (id) => setModal({ ...modal, editor: true, target: id })

    const handleMedia = {
        getMedia, getSaleGroupPrice, handleStatus,
        reloadTable, deleteMedia, changeEditor
    }
    let defaultColumns = WeiboHotTableConfig({ action, handleMedia, type })
    const localStorageColumnsName = "____WeiboHotTableConfig____";
    let localStorageColumns = JSON.parse(localStorage.getItem(localStorageColumnsName))
    _.forEach(defaultColumns, (e) => {
        _.forEach(localStorageColumns, (val) => {
            if (e.title === val.title) {
                e.checked = val.checked
            }
        })
    })
    const [columns, setColumns] = useState(defaultColumns)


    return <>
        <Content className='table-search'>
            <Form
                {...layout}
                form={form}
                name="weibo-hot-form"
                initialValues={initialFormData}
                onFinish={val => fetchData(_.pickBy(val, _.identity))}
            >
                <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                <Divider />
                <Row>
                    <Col span={12}>
                        <Space>
                            <RoleModule action='module:media:add' data={{ action: action }}>
                                <Button.Group>
                                    <Button type="primary" icon={<PlusOutlined />} onClick={() => setVisible({ ...visible, addMedia: true, type: '' })}>
                                        添加资源
                                    </Button>
                                    {/* <Button onClick={updateAll}>更新</Button> */}
                                </Button.Group>
                            </RoleModule>
                            <RoleModule action='module:media:import:price'>
                                <Button.Group>
                                    <Button icon={<ExportOutlined />} onClick={() => downloadMediasPrice()}>
                                        导出价格
                                    </Button>
                                    <Upload {...props}>
                                        <Button icon={<UploadOutlined />}>
                                            导入价格
                                        </Button>
                                    </Upload>
                                </Button.Group>
                            </RoleModule>
                        </Space>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Space>
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                                搜索
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Content>
        <Content style={{ background: '#fff', padding: 24, margin: 0, }}        >
            <Table
                size='middle'
                title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} />}
                columns={columns.filter(e => e.checked === 1)}
                rowSelection={{
                    columnWidth: '24px',
                    selectedRowKeys: medias.map(e => e.id),
                    onSelect: (record, selected) => {
                        let price = record.user_price
                        if (type) {
                            let priceMap = getPrice(record.user_price)
                            price = priceMap[type]
                        }
                        let tempMedia = {
                            id: record.id,
                            name: record.name,
                            price: price,
                            status: record.status
                        }
                        let newMedias = selected ? _.concat(medias, tempMedia) : _.differenceBy(medias, [tempMedia], 'id');
                        setMedias(_.uniqBy(newMedias, 'id'))
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        let tempMedias = changeRows.map(e => {
                            let price = e.user_price
                            if (type) {
                                let priceMap = getPrice(e.user_price)
                                price = priceMap[type]
                            }
                            return {
                                id: e.id,
                                name: e.name,
                                price: price,
                                status: e.status
                            }
                        })
                        let newMedias = selected ? _.concat(medias, tempMedias) : _.differenceBy(medias, tempMedias, 'id');
                        setMedias(_.uniqBy(newMedias, 'id'))
                    },
                    getCheckboxProps: (record) => ({
                        disabled: !Boolean(getPrice(record.user_price)[type]),
                        name: record.name,
                    }),
                }}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>

        <Drawer
            title={getTitle()}
            width={720}
            onClose={() => setVisible({ ...visible, addMedia: false })}
            open={visible.addMedia}
        >
            <Spin spinning={drawerLoading}>
                <AddWeiboHotForm media={media} drawerAction={drawerAction} />
            </Spin>
        </Drawer>
        {/* 销售组分配价格 */}
        <Modal
            title="媒体价格分配"
            destroyOnClose={true}
            width={780}
            open={modal.saleGroupPrice}
            okText="确认"
            cancelText="取消"
            onOk={() => {
                saleGroupForm
                    .validateFields()
                    .then((values) => {
                        let data = {
                            prices: JSON.stringify(values),
                            resource_id: saleGroupDatas[0].resource_id
                        }
                        updateSaleGroupPrice(data)
                    })
            }}
            onCancel={() => setModal({ ...modal, saleGroupPrice: false })}
        >
            <Spin spinning={saleGroupLoading} >
                <SaleGroupForm datas={saleGroupDatas} form={saleGroupForm} />
            </Spin>
        </Modal>
        {/* 下载媒体提示框 */}
        <Modal
            open={modal.download}
            footer={null}
            onOk={() => setModal({ ...modal, download: false })}
            onCancel={() => setModal({ ...modal, download: false })}
        >
            <div style={{ textAlign: 'center' }}>
                <Spin tip="下载中..." />
            </div>
        </Modal>
        <Modal
            title='修改资源所属编辑'
            open={modal.editor}
            footer={null}
            onOk={() => setModal({ ...modal, editor: false })}
            onCancel={() => setModal({ ...modal, editor: false })}
            width={960}
        >
            <WeiboEditorTable mid={modal.target} allotUser={allotUser} />
        </Modal>
    </>;
}

export default WeiboHotTable

const SaleGroupForm = ({ form, datas }) => {
    return <Form form={form}>
        <Row gutter={16}>
            {datas.map(e => <React.Fragment key={e.sale_group_id}>
                <Col span={24} style={{ display: 'flex' }}>
                    <Form.Item style={{ flex: '1 0 0' }}>
                        <Text strong>{e.sale_group_name}</Text>
                    </Form.Item>
                </Col>
                {
                    _.map(WEIBO, (val) => {
                        return <Col span={24 / _.size(WEIBO)} key={val.id}>
                            <Form.Item label={val.name} name={`${val.id}_price_${e.sale_group_id}`}>
                                <Input />
                            </Form.Item>
                        </Col>
                    })
                }
            </React.Fragment>
            )}
        </Row>
    </Form>
}