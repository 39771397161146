import React, { useState, useEffect } from 'react'
import { Descriptions, Skeleton } from 'antd';
import { useRecoilValue } from 'recoil'
import _ from 'lodash'

import { defaultInvoiceInfo } from './CommonInvoice'
import { userState } from '../../atoms/Atoms'

const axios = require('axios');

const InvoiceInfoForm = ({ uid = null }) => {

    const [invoice, setInvoice] = useState(defaultInvoiceInfo)
    const [loading, setLoading] = useState(false)
    const sessionUser = useRecoilValue(userState)

    const getUserInvoiceInfo = async (id) => {
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: `/invoice/user/${id}`,
            }).then((res) => {
                let tempInvoice = res.data.data[0]
                setInvoice(_.pick(tempInvoice, _.keysIn(invoice)))
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        if (uid) {
            getUserInvoiceInfo(uid)
        } else {
            sessionUser?.id && getUserInvoiceInfo(sessionUser.id)
        }
    }, [sessionUser, uid])

    const typeMap = new Map([
        [1, '个人'], [2, '企业']
    ])
    const invoiceTypeMap = new Map([
        [1, '增值税普通发票'], [2, '增值税专用发票']
    ])


    return <>
        {
            loading
                ? <Skeleton active />
                : <Descriptions title="开票信息" column={2}>
                    <Descriptions.Item label="开具类型">{typeMap.get(invoice.type)}</Descriptions.Item>
                    <Descriptions.Item label="发票抬头">{invoice.title}</Descriptions.Item>
                    <Descriptions.Item label="发票类型">{invoiceTypeMap.get(invoice.invoice_type)}</Descriptions.Item>
                    <Descriptions.Item label="税务登记证号">{invoice.licence_id}</Descriptions.Item>
                    <Descriptions.Item label="开户银行名称">{invoice.bank_name}</Descriptions.Item>
                    <Descriptions.Item label="基本开户账号">{invoice.bank_name}</Descriptions.Item>
                    <Descriptions.Item label="注册场所地址">{invoice.address}</Descriptions.Item>
                    <Descriptions.Item label="注册固定电话">{invoice.tel}</Descriptions.Item>
                </Descriptions>
        }

    </>
}

export default InvoiceInfoForm