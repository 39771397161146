import _ from 'lodash'

const user = [
    { name: '用户', alias: 'user' },

    { name: '创建用户', alias: 'user.create' },
    { name: '创建客户', alias: 'user.create.customer' },
    { name: '创建专员', alias: 'user.create.attache' },
    { name: '创建组长', alias: 'user.create.leader' },

    { name: '删除用户', alias: 'user.delete' },
    { name: '编辑用户', alias: 'user.edit' },
    { name: '编辑用户信息', alias: 'user.edit.info' },
    { name: '修改所属用户组', alias: 'user.edit.group' },
    { name: '修改所属销售', alias: 'user.edit.sale' },
    { name: '充值密码', alias: 'user.edit.reset' },

    { name: '查看用户', alias: 'user.read' },
    { name: '查看用户列表', alias: 'user.read.list' },

]

const media = [
    { name: '媒体', alias: 'media' },

    { name: '创建媒体', alias: 'media.create' },

    { name: '查看媒体', alias: 'media.read' },
    { name: '查看媒体列表', alias: 'media.read.list' },

    { name: '删除媒体', alias: 'media.delete' },

    { name: '编辑媒体', alias: 'media.edit' },
    { name: '编辑媒体信息', alias: 'media.edit.info' },

    { name: '设置媒体', alias: 'media.set' },
    { name: '分配媒体价格', alias: 'media.set.price' },

]

const baike = [
    { name: '百科', alias: 'baike' },

    { name: '创建百科', alias: 'baike.create' },


    { name: '编辑百科', alias: 'baike.edit' },
    { name: '编辑百科信息', alias: 'baike.edit.info' },

]

const collection = [
    { name: '收藏夹', alias: 'collection' },
    { name: '创建收藏夹', alias: 'collection.create' },

]

const order = [
    { name: '媒体订单', alias: 'order' },

    { name: '创建订单', alias: 'order.create' },

    { name: '编辑订单', alias: 'order.edit' },
    { name: '发布订单', alias: 'order.edit.info' },
    { name: '订单退款', alias: 'order.edit.refund' },

    { name: '查看订单', alias: 'order.read' },
    { name: '查看订单列表', alias: 'order.read.list' },


]

export const aliasArray = _.concat([], user, media, baike, collection, order)
