import React from 'react';
import { Layout, Space, Typography } from 'antd';
const { Footer } = Layout;
const { Text } = Typography

const HomeFooter = () => {

    return <Footer className="header" style={{ background: '#050D16' }}>
        <div style={{ width: 1200, margin: '0 auto' }}>
            <div style={{ textAlign: 'center' }}>
                <Space direction='vertical'>
                    <Space>
                        <a style={{ fontSize: 14, color: '#EEEEEE' }} href='https://beian.miit.gov.cn/'>浙ICP备16033965号</a>
                        <Text style={{ fontSize: 14, color: '#EEEEEE' }}>Copyright © 2016 - 2020 搜力科技 版权所有</Text>
                    </Space>
                    <a target="_blank" rel='noopener noreferrer' href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802011882">
                        <Space>
                            <img src="http://static.yunzitui.com/static/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png" style={{ float: 'left' }} alt='' />
                            <Text style={{ fontSize: 14, color: '#EEEEEE' }}>
                                浙公网安备 33010802011882号
                            </Text>
                        </Space>
                    </a>
                </Space>
            </div>
        </div>
    </Footer>
}

export default HomeFooter