import React, { useState, useEffect } from 'react';
import { Table, Layout, Typography, Modal, Form, Button, Space, Row, Col, Divider, message, Radio, Input, Checkbox, Card } from 'antd';
import _ from 'lodash'
import { SearchOutlined, SyncOutlined, PlusOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import dayjs from 'dayjs'
import qs from 'qs'

import { initFormData, tablePagination } from '../../common/tableCommon';
import { NoticeTableConfig } from '../../../role/NoticeTableConfig';
import SearchForm from '../../common/SearchForm';
import CKEditorInstance from '../../../utils/CkeditorInstance';

const axios = require('axios');
const { Content } = Layout;
const { Paragraph } = Typography

const initialModal = {
    preview: false, create: false
}

const NoticeTable = () => {

    const searchDatas = [
        {
            name: 'title',
            label: '订单标题',
            placeholder: '请输入标题',
            type: 'input'
        }, {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: dayjs().subtract(7, 'days')
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        },
    ]

    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState(initialModal)
    const [detail, setDetail] = useState("")
    const [form] = Form.useForm();
    const [content, setContent] = useState("")
    const [notice, setNotice] = useState({
        pinned: 0,
        role_id: ['5'],
        popup: 0,
        attache: 3
    })


    const resetModal = () => setModal({ create: false })
    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))

        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/notice/noticeList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.recordsFiltered
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    const createNotice = async (values) => {
        await axios({
            method: 'post',
            url: "/notice/add",
            data: qs.stringify(values)
        }).then(res => {
            if (res.data.code === 1) {
                message.success("已发布")
                setModal({ ...modal, create: false })
            } else {
                message.error(res.data.data)
            }
        })
    }

    const options = [
        { label: '客户端', value: '5' },
        { label: '财务端', value: '6' },
        { label: '销售端', value: '3' },
        { label: '运营端', value: '2' },
        { label: '资源端', value: '4' },
    ];

    const getNotice = async (id) => {
        await axios({
            method: 'get', url: `/notice/${id}`
        }).then((res) => {
            setNotice(res.data)
            setContent(res?.data?.content)
            setModal({ ...modal, create: true, action: 'EDIT' })
        })
    }

    const editNotice = async (values) => {
        console.log(values)
        await axios({
            method: 'post',
            url: `/notice/edit`,
            data: qs.stringify(values)
        }).then(res => {
            if (res.data.code === 1) {
                message.success("已发布")
                setModal({ ...modal, create: false })
            } else {
                message.error(res.data.data)
            }
        })
    }

    // 查看公告详情
    const checkContent = (data) => {
        setModal({ ...modal, preview: true })
        setDetail(data)
    }

    // 订单操作函数
    const handleOrders = { getNotice, checkContent, reloadTable, }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [pagination.current, pagination.pageSize])

    let columns = NoticeTableConfig({ handleOrders })

    return <>
        <Space direction='vertical' size={16} className='full-width'>
            <Card>
                <Form
                    layout='vertical'
                    labelAlign="left"
                    name="notice-form"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                    <Row>
                        <Col span={12}>
                            <Button type='primary' icon={<PlusOutlined />} onClick={() => setModal({ ...modal, create: true })}>
                                创建公告
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button onClick={() => form.resetFields()}>
                                    <SyncOutlined /> 重置搜索
                                </Button>
                                <Button type="primary" htmlType="submit" loading={loading} >
                                    <SearchOutlined /> 搜索
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card>
                <Table
                    size="middle"
                    columns={columns.filter(e => e.checked === 1)}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                />
            </Card>
        </Space>
        <Modal
            title="创建公告" open={modal.create} width={870}
            onOk={() => resetModal()}
            onCancel={() => resetModal()}
            footer={null}
        >
            <Form
                layout="vertical"
                initialValues={notice}
                onFinish={val => {
                    let values = { ...val, content: content, role_id: JSON.stringify(val['role_id']) }
                    if (modal.action === 'EDIT') {
                        editNotice(values)
                    } else {
                        createNotice(values)
                    }
                }}>
                <Form.Item label='公告标题' name='title'>
                    <Input />
                </Form.Item>
                <Form.Item label='是否置顶' name='pinned'>
                    <Radio.Group>
                        <Radio value={1}>置顶</Radio>
                        <Radio value={0}>不置顶</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='是否弹窗' name='popup'>
                    <Radio.Group>
                        <Radio value={1}>弹窗公告</Radio>
                        <Radio value={0}>普通公告</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='专员' name='attache'>
                    <Radio.Group>
                        <Radio value={2}>组长</Radio>
                        <Radio value={1}>组员</Radio>
                        <Radio value={3}>客户</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='可见端口' name='role_id'>
                    <Checkbox.Group options={options} />
                </Form.Item>
                <Form.Item label='公告内容'>
                    <CKEditorInstance content={content} setContent={setContent} />
                </Form.Item>
                <Form.Item name='id' hidden>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType="submit">提交</Button>
                </Form.Item>
            </Form>
        </Modal>
        <Modal width={870} open={modal.preview}
            title="公告详情"
            onCancel={() => setModal({ ...modal, preview: false })}
            footer={null}
        >
            <Layout style={{
                width: 800,
                margin: '0 auto',
                backgroundColor: '#fff'
            }}>
                <Content id='content' style={{ padding: 24 }}>
                    <Paragraph style={{
                        fontSize: 16,
                        padding: '16px 0',
                    }}>{ReactHtmlParser(detail)}</Paragraph>
                </Content>
            </Layout>
        </Modal>
    </>;
}


export default NoticeTable
