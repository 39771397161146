import React, { useState, useEffect } from 'react';
import {
    Table, Button, Row, Col, Modal, Tabs, Layout, Switch, InputNumber,
    message, Input, Select, Badge, Form, Typography, Spin, Space, Divider
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs'
import qs from 'qs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { serialize } from 'object-to-formdata';

import { resultResponse } from '../../common/ResultResponse';
import { TimeSearchButtonGroup, initFormData, tablePagination, TableTitle } from '../../common/tableCommon';
import SearchForm from '../../common/SearchForm'
import { WechatOrderTableConfig } from '../../../role/wechat/WechatOrderTableConfig'
import OrderSearchConfig from '../../../role/order/OrderSearchConfig'
import { userRoleState, refreshUserState } from '../../atoms/Atoms'
import { getResponse, getText } from '../../../role/order/orderCommon';
import RoleModule from '../../../utils/permission/RoleModule';
import { check } from '../../../utils/permission/rules';

const axios = require('axios');
const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const badgeOffset = [16, 2]

const layout = { layout: 'vertical' };
const seartchLayout = { lg: 24, xl: 12 };
const initialModal = {
    target_id: undefined,
    verifyFailed: false, publishSuccess: false, publishOrderFailed: false,
    updateUrl: false, recallBox: false, errorBox: false,
    download: false, rename: false, history: false, censor: false, updateCost: false,
}

const XiaohongshuOrderTable = ({
    searchFormDisplay = true,
    url, action, miniMode = false, hasTabBar = true,
    orders = [], setOrders = () => console.log('')
}) => {
    const role = useRecoilValue(userRoleState)
    const setRefresh = useSetRecoilState(refreshUserState)

    const permission = {
        'module:media:order:money:cost': check(role, 'module:media:order:money:cost', { action }),
        'module:media:order:money:paid': check(role, 'module:media:order:money:paid', { action }),
        'module:media:order:money:agent': check(role, 'module:media:order:money:agent', null),
        'module:media:order:money:sales:agent': check(role, 'module:media:order:money:sales:agent', null),
    }

    const searchDatas = OrderSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [tab, setTab] = useState('1')

    const [status, setStatus] = useState({ media_status: '', status: '', })
    const [modal, setModal] = useState(initialModal)
    const [text, setText] = useState("")
    const [err_selector, setErr_selector] = useState('1')
    const [rcl_selector, setRcl_selector] = useState('1')
    const [num, setNum] = useState([])
    const [creator, setCreator] = useState("")
    const [name, setName] = useState("")
    const [history, setHistory] = useState([])
    const [cost, setCost] = useState(0)

    const [form] = Form.useForm();

    const reloadTable = () => {
        form.submit();
        setOrders([]);
        setRefresh(true)
    }
    const resetModal = () => { setModal(initialModal); setText("") } // 重置弹出框 以及 文本框

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (creator !== "") {
            params.append("creator_id", creator)
        }
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        _.toPairs(status).filter(e => e[1]).map(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: url || '/wechat/order/myList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                let tmpNum = res.data.extra
                setNum(_.reduce(tmpNum, (result, e) => {
                    return _.assign(result, { [e.state]: e.total })
                }, {}))
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    // 订单审核通过
    const verifyOrderSuccess = async (id) => {
        await axios({
            method: 'post',
            url: "/wechat/order/verify",
            data: qs.stringify({
                ids: _.isNil(id) ? _.join(orders.map(e => e.id), ',') : id,
                status: "waitPublish"
            })
        }).then(res => {
            resultResponse(res, "已通过审核")
            reloadTable()
        })
    }
    // 订单审核不通过
    const verifyOrderFailed = async (id, info) => {
        await axios({
            method: 'post',
            url: "/wechat/order/verify",
            data: qs.stringify({
                ids: _.isNil(id) ? _.join(orders.map(e => e.id), ',') : id,
                status: "notPass",
                reply: info
            })
        }).then(res => {
            resultResponse(res, "未通过审核")
            reloadTable()
        })
    }
    // 订单发布回执
    const publishOrderSuccess = async (id, info) => {
        await axios({
            method: 'post',
            url: "/wechat/order/publish",
            data: qs.stringify({ order_ids: id, status: "success", reply: info })
        }).then(res => {
            resultResponse(res, "已发布回执")
            reloadTable()
        })
    }
    // 订单退稿回执
    const publishOrderFailed = async (id, info) => {
        await axios({
            method: 'post',
            url: "/wechat/order/publish",
            data: qs.stringify({
                order_ids: id ?? _.join(orders.map(e => e.id), ','),
                status: "back",
                reply: info
            })
        }).then(res => {
            resultResponse(res, "已退稿")
            reloadTable()
        })
    }
    // 订单修改回执
    const updateUrl = async (id, info) => {
        await axios({
            method: 'post',
            url: "/wechat/order/update",
            data: qs.stringify({ id: id, back_info: info })
        }).then(res => {
            if (res.data.code === '200') {
                message.success("已成功修改回执")
                reloadTable()
            } else {
                message.error(res.data.message)
            }
        })
    }
    // 订单结算状态修改
    const updatePayinfo = async (id, status) => {
        await axios({
            method: 'post',
            url: "/wechat/order/pay",
            data: qs.stringify({
                ids: _.isNil(id) ? _.join(orders.map(e => e.id), ',') : id,
                paid_status: status === 'paid' ? 'unpaid' : 'paid'
            })
        }).then(res => {
            resultResponse(res, "已修改")
            reloadTable()
        })
    }

    // 已发布订单 退稿
    const rejectOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/wechat/order/reject",
            data: qs.stringify({ id: id })
        }).then(res => {
            resultResponse(res, "已成功退稿")
            reloadTable()
        })
    }
    // 待审核 订单 撤回
    const cancelOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/wechat/order/cancel",
            data: qs.stringify({ id: id })
        }).then(res => {
            resultResponse(res, "已撤回")
            reloadTable()
        })
    }
    // 待审核 订单申请撤回
    const recallOrder = async (id, info) => {
        await axios({
            method: 'post',
            url: "/wechat/order/recall",
            data: qs.stringify({
                id: id,
                recall_type: rcl_selector,
                recall_reason: info
            })
        }).then(res => {
            resultResponse(res, "已提交撤回申请")
            reloadTable()
        })
    }
    // 已发布 订单异常申请
    const errorOrder = async (id, info) => {
        await axios({
            method: 'post',
            url: "/wechat/order/err",
            data: qs.stringify({
                id: id,
                err_type: err_selector,
                err_reason: info
            })
        }).then(res => {
            resultResponse(res, "已提交异议申请")
            reloadTable()
        })
    }

    const rename = async () => {
        await axios({
            method: 'post',
            url: "/wechat/order/rename",
            data: qs.stringify({
                uid: modal.target_id,
                name: name
            })
        }).then(res => {
            resultResponse(res, "已提交修改")
            setModal({ ...modal, rename: false })
            reloadTable()
        })
    }

    const updateCost = async () => {
        await axios({
            method: 'post',
            url: "/wechat/order/update/cost",
            data: serialize({ id: modal.target_id, cost: cost })
        }).then(res => {
            resultResponse(res, "已提交更新")
            setModal({ ...modal, updateCost: false })
            reloadTable()
        })
    }

    const downloadOrders = async () => {
        setModal({ ...modal, download: true })
        let val = _.pickBy(form.getFieldsValue(), _.identity);
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let url = action === 'EDIT'
            ? `/wechat/order/download_user_order?${qs.stringify(val)}`
            : `/wechat/order/downLoadOrder?${qs.stringify(val)}`
        await axios({
            url: url, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '订单详情.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }

    const checkHistory = async (id) => {
        setModal({ ...modal, history: true })
        await axios({
            method: 'post',
            url: `/wechat/order/history/${id}`
        }).then(res => {
            if (res.data.code === 1) {
                setHistory(res.data.data)
            } else {
                message.error(res.data.msg)
            }
        })
    }

    const checkCensor = async (id) => setModal({ ...modal, censor: true, target_id: id })


    // 订单操作函数
    const handleOrders = {
        verifyOrderSuccess, cancelOrder,
        recallOrder, errorOrder, reloadTable, rejectOrder, checkHistory,
        checkCensor,
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [creator])


    //--------------- 批量处理 -----------------------------------------
    const multiCopy = () => orders.reduce((total, val) => total + getText(val) + '\n', '')
    const multiCopySuccess = () => orders.reduce((total, val) => total + getResponse(val) + '\n', '')

    const filterMediaStatus = (e) => {
        setTab(e)
        setOrders([])
        switch (e) {
            case '2':
                setStatus({ status: 'WAIT_VERIFY' })
                break
            case '3':
                setStatus({ status: 'UNCONFIRMED' })
                break
            case '4':
                setStatus({ status: 'WAIT_PUBLISH' })
                break
            case '5':
                setStatus({ status: 'SUCCESS' })
                break
            case '6':
                setStatus({ status: 'BACK' })
                break
            default:
                setStatus({ status: '' })
        }
        form.submit()
    }

    let defaultColumns = WechatOrderTableConfig({ modal, setModal, handleOrders, tab, action })
    const localStorageColumnsName = "____WechatOrderTableConfig____";
    let localStorageColumns = JSON.parse(localStorage.getItem(localStorageColumnsName))
    _.forEach(defaultColumns, (e) => {
        _.forEach(localStorageColumns, (val) => {
            if (e.title === val.title) {
                e.checked = val.checked
            }
        })
    })
    const [columns, setColumns] = useState(defaultColumns)

    return <>
        <Content style={searchFormDisplay ? { background: '#fff', padding: 24, margin: '0 0 24px 0', } : { display: 'none', visibility: 'hidden' }}>
            <Form
                {...layout}
                name="basic"
                form={form}
                initialValues={initialFormData}
                onFinish={val => fetchData(_.pickBy(val, _.identity))}
            >
                {searchFormDisplay &&
                    <>
                        <SearchForm searchDatas={searchDatas} />
                        <Row>
                            <Col {...seartchLayout} >
                                <Space>
                                    <RoleModule action='module:media:order:toolbar' data={{ action }}>
                                        <Space>
                                            {
                                                tab === '2' &&
                                                <Button.Group>
                                                    <Button type="primary" onClick={() => verifyOrderSuccess(modal.target_id)}>批量通过</Button>
                                                    <Button danger onClick={() => setModal({ ...modal, verifyFailed: true })}>批量不通过</Button>
                                                </Button.Group>
                                            }
                                            {
                                                tab === '3' &&
                                                <Button danger onClick={() => setModal({ ...modal, publishOrderFailed: true })} >批量退稿</Button>
                                            }
                                            {
                                                tab === '4' &&
                                                <RoleModule action='module:media:order:editor:paid'>
                                                    <Button onClick={() => updatePayinfo(modal.target_id, 'unpaid')}>批量结款</Button>
                                                </RoleModule>
                                            }
                                            <RoleModule action='module:media:order:solo'>
                                                <Switch defaultChecked={creator === ''} checkedChildren="全部" unCheckedChildren="单独"
                                                    onChange={e => setCreator(e ? "" : 2)} />
                                            </RoleModule>
                                        </Space>
                                    </RoleModule>
                                    <RoleModule action='module:media:order:modify'>
                                        <Button type="primary" onClick={() => setModal({ ...modal, modifyOrder: true })}>批量修改订单</Button>
                                    </RoleModule>
                                    <CopyToClipboard text={multiCopy()} onCopy={() => message.success('已成功复制')}>
                                        <Button type='link'>批量复制来源</Button>
                                    </CopyToClipboard>
                                    {
                                        tab === '4' &&
                                        <CopyToClipboard text={multiCopySuccess()} onCopy={() => message.success('已成功复制')}>
                                            <Button type='link'>批量复制回执</Button>
                                        </CopyToClipboard>
                                    }
                                </Space>
                            </Col>
                            <Col  {...seartchLayout} style={{ textAlign: 'right' }}>
                                <Space>
                                    <TimeSearchButtonGroup form={form} />
                                    {/* {!miniMode && <Button icon={<ExportOutlined />} onClick={() => downloadOrders()}>导出</Button>} */}
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >搜索</Button>
                                </Space>
                            </Col>
                        </Row>
                    </>
                }
            </Form>
        </Content>
        <Content style={miniMode ? { background: '#fff' } : { background: '#fff', padding: 24 }}>
            {
                hasTabBar &&
                <Tabs defaultActiveKey="1" onChange={filterMediaStatus}
                    items={[
                        { label: '全部稿件', key: '1' },
                        {
                            label: <Badge size='small' count={num.waitVerify} offset={badgeOffset} >
                                <div style={{ fontSize: 12 }}>待审核</div>
                            </Badge>,
                            key: '2',
                        },
                        {
                            label: <Badge size='small' count={num.waitPublish} offset={badgeOffset} >
                                <div style={{ fontSize: 12 }}>待发布</div>
                            </Badge>,
                            key: '3',
                        },
                        { label: '已完成', key: '4' },
                        { label: '已退稿', key: '5' },
                        {
                            label: <Badge size='small' count={num.recall} offset={badgeOffset} >
                                <div style={{ fontSize: 12 }}>撤稿申请</div>
                            </Badge>,
                            key: '6',
                        },
                        {
                            label: <Badge size='small' count={num.error} offset={badgeOffset} >
                                <div style={{ fontSize: 12 }}>异常申请</div>
                            </Badge>,
                            key: '7',
                        },
                    ]}
                />
            }
            <Table
                size='small'
                title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} />}
                columns={columns.filter(e => e.checked === 1)}
                rowSelection={{
                    selectedRowKeys: orders.map(e => e.id),
                    onSelect: (record, selected) => {
                        let newMedias = selected ? _.concat(orders, record) : _.differenceBy(orders, [record], 'id');
                        setOrders(_.uniqBy(newMedias, 'id'))
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        let newMedias = selected ? _.concat(orders, changeRows) : _.differenceBy(orders, changeRows, 'id');
                        setOrders(_.uniqBy(newMedias, 'id'))
                    }
                }}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => { setPagination(pagination); form.submit() }}
                summary={pageData => <Summary pageData={pageData} orders={orders} permission={permission} columns={columns} />}
            />
        </Content>

        <Modal title="拒稿理由" open={modal.verifyFailed}
            onOk={() => {
                verifyOrderFailed(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>
        <Modal title="填写回执" open={modal.publishSuccess}
            onOk={() => {
                publishOrderSuccess(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>
        <Modal title="退稿理由" open={modal.publishOrderFailed}
            onOk={() => {
                publishOrderFailed(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>
        <Modal title="编辑回执" open={modal.updateUrl}
            onOk={() => {
                updateUrl(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>
        <Modal title="撤稿申请" open={modal.recallBox}
            onOk={() => {
                recallOrder(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <Select
                showSearch style={{ width: "100%", marginBottom: '8px' }}
                placeholder="请选择撤稿理由"
                defaultValue="1"
                onChange={e => setRcl_selector(e)}
            >
                <Option value="1">选择错误媒体</Option>
                <Option value="2">来源填写错误</Option>
                <Option value="3">稿件内容有误</Option>
                <Option value="9">其他</Option>
            </Select>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>

        <Modal title="异常申请" open={modal.errorBox}
            onOk={() => {
                errorOrder(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <Select
                showSearch style={{ width: "100%", marginBottom: '8px' }}
                placeholder="请选择异常理由"
                defaultValue="1"
                onChange={e => setErr_selector(e)}
            >
                <Option value="1">稿件内容遭修改</Option>
                <Option value="2">稿件被删除</Option>
                <Option value="3">稿件未收录</Option>
                <Option value="9">其他</Option>
            </Select>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>

        <Modal
            open={modal.download}
            footer={null}
            onOk={() => setModal({ ...modal, download: false })}
            onCancel={() => setModal({ ...modal, download: false })}
        >
            <div style={{ textAlign: 'center' }}>
                <Spin tip="下载中..." />
            </div>
        </Modal>
        <Modal
            title="重命名" open={modal.rename}
            footer={null}
            onCancel={() => setModal({ ...modal, rename: false })}
        >
            <Space direction='vertical'>
                <Input type="text" value={name} onChange={e => setName(e.target.value)} />
                <Button type="primary" onClick={() => rename()}>确认修改</Button>
            </Space>
        </Modal>
        <Modal
            title="修改成本" open={modal.updateCost}
            footer={null}
            onCancel={() => setModal({ ...modal, updateCost: false })}
        >
            <Space direction='vertical'>
                <InputNumber type="text" min={0} value={cost} onChange={val => setCost(val)} />
                <Button type="primary" onClick={() => updateCost()}>确认修改</Button>
            </Space>
        </Modal>
    </>;
}


export default XiaohongshuOrderTable

const PriceSum = ({ data, permission }) => {
    let totalCost = _.sumBy(data, 'cost_money');        // 成本
    let totalSale = _.sumBy(data, 'agent_paid_money');  // 销售
    let totalPaid = _.sumBy(data, 'paid_money');        // 付款

    return <Space>
        {permission['module:media:order:money:cost'] && <span>成本: <Text type="danger">{totalCost}</Text></span>}
        {permission['module:media:order:money:agent'] && <span>相对成本: <Text>{totalSale}</Text></span>}
        {permission['module:media:order:money:sales:agent'] && <span>成本: <Text>{totalSale}</Text></span>}
        {permission['module:media:order:money:paid'] && <span>付款: <Text>{totalPaid}</Text></span>}
        <Divider type='vertical' />
        {(permission['module:media:order:money:cost'] && permission['module:media:order:money:agent'])
            && <span>总收益: <Text type="danger">{totalSale - totalCost}</Text></span>}
        {(permission['module:media:order:money:sales:agent'] && permission['module:media:order:money:paid'])
            && <span>总收益: <Text type="danger">{totalPaid - totalSale}</Text></span>}
        <span>共 <Text strong>{_.size(data)}</Text> 条</span>
    </Space>
}

const Summary = ({ pageData, orders, permission, columns }) => {
    let totalNum = _.size(columns.filter(e => e.checked === 1))
    return <tr>
        <td />
        <th>汇总</th>
        <td colSpan={totalNum - 1}>
            <Space>
                <PriceSum data={pageData} permission={permission} />
                {
                    _.size(orders) > 0 && <>
                        <Text strong>已选择</Text>
                        <PriceSum data={orders} permission={permission} />
                    </>
                }
            </Space>
        </td>
    </tr>
}