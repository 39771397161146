import React from 'react';
import { Typography, Button, Layout, message } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const { Paragraph, Text, Title } = Typography;
const { Header, Footer, Content } = Layout;

const PreviewArticle = ({ order }) => {
    const remarkStyle = {
        padding: '6px',
        backgroundColor: '#ffe58f',
        borderRadius: '4px',
        userSelect: 'none'
    }


    const copyToClip = (str) => {
        const listener = (e) => {
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
        message.success('已成功复制')
    }


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{order.title}</title>
                    <meta name="description" content={order.title} />
                </Helmet>
                <Layout style={{
                    width: 800,
                    margin: '0 auto',
                    backgroundColor: '#fff'
                }}>
                    <>
                        <Header style={{
                            borderBottom: '1px solid rgba(99,114,130,0.16)'
                        }}>
                            <Button type="primary" style={{ margin: '12px 8px' }} onClick={e => {
                                copyToClip(order.content)
                            }}>复制内容</Button>
                        </Header>
                        <Content id='content' style={{
                            padding: 24
                        }}>
                            <Title style={{ marginBottom: '1em' }}>{order.title}</Title>
                            {order.url !== '' && <Text style={remarkStyle}>来源: {order.url}</Text>}
                            {order.remark !== '' && <Text style={remarkStyle}>备注: {order.remark}</Text>}
                            <Paragraph style={{
                                fontSize: 16,
                                padding: '16px 0',
                            }}>{ReactHtmlParser(order.content)}</Paragraph>
                            {order.url !== '' && <Text style={remarkStyle}>来源: {order.url}</Text>}
                            {order.remark !== '' && <Text style={remarkStyle}>备注: {order.remark}</Text>}
                        </Content>
                        <Footer style={{
                            borderTop: '1px solid rgba(99,114,130,0.16)',
                            backgroundColor: '#fff',
                            padding: '0 24px'
                        }}>
                            <Button type="primary" style={{ margin: '12px 8px' }} onClick={e => {
                                copyToClip(order.content)
                            }}>复制内容</Button>
                        </Footer>
                    </>
                </Layout>
            </HelmetProvider>

        </>
    )
}

export default PreviewArticle