import EditorOrders from '../resource/EditorOrders'
import EditorMedia from '../resource/EditorMedia'
import EditorWeiboHot from '../resource/EditorWeiboHot'
import EditorWechat from '../resource/EditorWechat'

import React from "react"


const ResourceRoutesConfig = [
    {
        path: '/dashboard/resource/orders',
        component: <EditorOrders />
    },
    {
        path: '/dashboard/resource/media',
        component: <EditorMedia />
    },
    {
        path: '/dashboard/resource/weibo/hot',
        component: <EditorWeiboHot />
    },
    {
        path: '/dashboard/resource/wechat',
        component: <EditorWechat />
    },
    {
        path: '/dashboard/resource/weibo/hot',
        component: <EditorWeiboHot />
    },
    {
        path: '/dashboard/resource/weibo/hot',
        component: <EditorWeiboHot />
    },
]

export default ResourceRoutesConfig