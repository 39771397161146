export const CommonMedia = {
    id: '',
    name: '',
    sample_url: '',
    cost: '',
    sale_price: '',
    comment: '',
    editor_name: '',
    editor_tel: '',
    end_date: '',
    status: '',
    week_available: '',
    channel_type: '新闻资讯',
    media_from: '网络媒体',
    effect: '',
    door_type: '中国五大门户',
    speed: '',
    area: '',

}   // 普通媒体属性
export const MCNMedia = {
    id: '',
    name: '',
    sample_url: '',
    cost: '',
    sale_price: '',
    comment: '',
    editor_name: '',
    editor_tel: '',
    end_date: '',
    status: '',
    week_available: '',
    channel_type: '新闻资讯',
    media_from: '自媒体',
    effect: '自媒体',
    area: '',
    available_time: '',
    fans_num: '',
    page_views: '',
    door_type: '',
}   // 自媒体属性
