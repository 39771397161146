import React, { useState, useEffect } from 'react';
import {
    SearchOutlined, SyncOutlined, RightOutlined
} from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Divider, Layout, Typography, Space, Tag } from 'antd';
import dayjs from 'dayjs'
import _ from 'lodash'

import SearchForm from '../../common/SearchForm'
import { initFormData, tablePagination } from '../../common/tableCommon';
import { formatTime } from '../../../utils/TimeUtils'
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios');

const MediaPrepayHistoryTable = ({ prepay_id }) => {
    const [form] = Form.useForm();                  // 媒体搜索form

    const searchDatas = [
        {
            name: 'media_name',
            label: '媒体名称',
            placeholder: '请输入媒体名称',
            type: 'input'
        }, {
            name: 'action',
            label: '操作',
            type: 'select',
            children: [
                ['全部', ''],
                ['充值', 'RECHARGE'],
                ['绑定', 'BIND'],
                ['解除绑定', 'UNBIND'],
                ['消费', 'CONSUME'],
                ['退款', 'REFUND'],
            ]
        }
    ]
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("prepay_id", prepay_id)

        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))

        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/media/prepay/history',
                data: params,
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    // 初始化列表
    useEffect(() => {
        fetchData()
        return source.cancel('Operation canceled by the user.');
    }, [prepay_id])

    const columns = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            render: (t) => formatTime(t)
        }, {
            title: '消费金额',
            dataIndex: 'amount',
            render: (val, data) => {
                if (data.old_amount < data.new_amount) {
                    return <Text style={{ color: '#03be67' }}>+{val}</Text>
                } else {
                    return <Text style={{ color: '#e01515' }}>-{val}</Text>
                }
            }
        }, {
            title: '金额变化',
            dataIndex: 'new_amount',
            render: (val, data) => {
                if (data.amount) {
                    return <Space>
                        {data.old_amount}
                        <RightOutlined />
                        {val}
                    </Space>
                }

            }
        }, {
            title: '状态',
            dataIndex: 'action',
            render: (val, data) => {
                if (val == null) {
                    return <Tag style={{ margin: 0 }} color="green">消费</Tag>
                }
                let result = '';
                switch (data.action) {
                    case 'CONSUME':
                        result = <Tag style={{ margin: 0 }} color="green">消费</Tag>
                        break;
                    case 'REFUND':
                        result = <Tag style={{ margin: 0 }} color="red">退稿</Tag>;
                        break;
                    case 'RECHARGE':
                        result = <Tag style={{ margin: 0 }} color="volcano">充值</Tag>;
                        break;
                    case 'BIND':
                        result = <Space>
                            <Tag style={{ margin: 0 }} color="orange">绑定媒体</Tag>
                            <Tag style={{ margin: 0 }} color="cyan">{data.media_name}</Tag>
                        </Space>;
                        break;
                    case 'UNBIND':
                        result = <Space>
                            <Tag style={{ margin: 0 }} color="geekblue">解除绑定</Tag>
                            <Tag style={{ margin: 0 }} color="cyan">{data.media_name}</Tag>
                        </Space>;
                        break;
                    default:
                        break;
                }
                return <Space direction='vertical' size='small'>
                    {result}
                </Space>
            }
        }
    ]

    return <>
        <Content className='table-search' style={{ padding: 0 }}>
            <PageHeader
                style={{ padding: 0 }}
                title={<Text level={4}>媒体搜索</Text>}
            >
                <Form
                    layout={'horizontal'}
                    labelAlign="left"
                    name="media-form"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                    <Divider style={{ margin: '8px 0' }} />
                    <Row>
                        <Col span={12}>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button onClick={() => form.resetFields()}>
                                    <SyncOutlined /> 重置搜索
                                </Button>
                                <Button type="primary" htmlType="submit" >
                                    <SearchOutlined /> 搜索
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </PageHeader>
        </Content>
        <Content className='table-main'>
            <Table size='small'
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>
    </>;
}

export default MediaPrepayHistoryTable