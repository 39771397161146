import CreateXiaohongshuOrder from "../xiaohongshu/CreateXiaohongshuOrder"
import Xiaohongshu from "../xiaohongshu/Xiaohongshu"
import XiaohongshuOrder from "../xiaohongshu/XiaohongshuOrder"

import React from "react"
const XiaohongshuRoutesConfig = [
    {
        path: '/dashboard/xiaohongshu/order',
        component: <XiaohongshuOrder />
    },
    {
        path: '/dashboard/xiaohongshu/order/add',
        component: <CreateXiaohongshuOrder />
    },
    {
        path: '/dashboard/xiaohongshu',
        component: <Xiaohongshu />
    }
]

export default XiaohongshuRoutesConfig