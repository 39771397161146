import React from 'react';
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import UserNewSalaryTable from '../user/UserNewSalaryTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;

const FinanceSalary = () => {
    let pageTitle = '薪资统计'

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content style={{ margin: 24 }}>
                <UserNewSalaryTable />
            </Content>
        </HelmetProvider>
    )
}

export default FinanceSalary;