import React, { useState, useEffect } from 'react';
import { Form, Table, Button, Row, Col, Layout, Divider, Space, Typography, Checkbox, Card } from 'antd';
import _ from 'lodash'
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import SearchForm from '../../common/SearchForm'
import { ConsumeTableConfig } from '../../../role/ConsumeTableConfig'
import ConsumeSearchConfig from '../../../role/ConsumeSearchConfig'
import { TimeSearchButtonGroup, initFormData, tablePagination, TableTitle, disposeColumn } from '../../common/tableCommon';
import { resultResponse } from '../../common/ResultResponse';

const axios = require('axios');
const { Content } = Layout;
const { Text } = Typography
const layout = { layout: 'vertical' };

const ConsumeTable = ({
    consumes = [],
    setConsumes = () => console.log('empty'),
    action,
    searchEntity = {},
    rowSelectionDisplay = true
}) => {
    const role = useRecoilValue(userRoleState)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const [form] = Form.useForm();                  // 媒体搜索form

    const reloadTable = () => form.submit()

    const searchDatas = ConsumeSearchConfig({ action })
    const initialFormData = initFormData(searchDatas)

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        searchEntity && _.toPairs(searchEntity).forEach(e => params.set(`${e[0]}`, e[1] ?? ''))

        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/consume/getConsumeList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                let tempEntity = res.data.data
                setData(tempEntity)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    // 撤销用户充值记录
    const deleteUserRechargeLog = async (id) => {
        await axios({
            method: 'post',
            url: `/consume/delete/recharge/${id}`,
        }).then((res) => {
            resultResponse(res, '已成功撤销')
            reloadTable()
        })
    }

    const functions = { deleteUserRechargeLog }

    let defaultColumns = ConsumeTableConfig({ action, functions })
    const localStorageColumnsName = "____ConsumeTableConfig____";
    disposeColumn(localStorageColumnsName, defaultColumns)
    const [columns, setColumns] = useState(defaultColumns)

    return (
        <Space direction='vertical' size={16} style={{ width: '100%' }}>
            <Content>
                <Card>
                    <Form
                        {...layout}
                        name="basic"
                        form={form}
                        initialValues={{ ...initialFormData, type_id: [""] }}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                    >
                        <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                        {
                            role !== 'CUSTOMER' && <Form.Item label="消费类型" name='type_id'>
                                <Checkbox.Group style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', gap: '8px 24px', flexWrap: 'wrap' }}>
                                        <div>
                                            <Divider style={{ margin: '8px 0' }} orientation="left" plain>充值</Divider>
                                            {options.map(e => <Checkbox value={e.value} key={e.value}>{e.label}</Checkbox>)}
                                        </div>
                                        <div>
                                            <Divider style={{ margin: '8px 0' }} orientation="left" plain>媒体</Divider>
                                            {mediaOptions.map(e => <Checkbox value={e.value} key={e.value}>{e.label}</Checkbox>)}
                                        </div>
                                        <div>
                                            <Divider style={{ margin: '8px 0' }} orientation="left" plain>百科</Divider>
                                            {baikeOptions.map(e => <Checkbox value={e.value} key={e.value}>{e.label}</Checkbox>)}
                                        </div>
                                        <div>
                                            <Divider style={{ margin: '8px 0' }} orientation="left" plain>文字链</Divider>
                                            {indexOptions.map(e => <Checkbox value={e.value} key={e.value}>{e.label}</Checkbox>)}
                                        </div>
                                        <div>
                                            <Divider style={{ margin: '8px 0' }} orientation="left" plain>问答</Divider>
                                            {qaOptions.map(e => <Checkbox value={e.value} key={e.value}>{e.label}</Checkbox>)}
                                        </div>
                                        <div>
                                            <Divider style={{ margin: '8px 0' }} orientation="left" plain>用户收益</Divider>
                                            {userEarnOptions.map(e => <Checkbox value={e.value} key={e.value}>{e.label}</Checkbox>)}
                                        </div>
                                        <div>
                                            <Divider style={{ margin: '8px 0' }} orientation="left" plain>白条</Divider>
                                            {userLoanOptions.map(e => <Checkbox value={e.value} key={e.value}>{e.label}</Checkbox>)}
                                        </div>
                                    </div>
                                </Checkbox.Group>
                            </Form.Item>
                        }
                        <Row>
                            <Col span={12} ></Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Space>
                                    <TimeSearchButtonGroup form={form} />
                                    <Button type="primary" htmlType="submit">搜索</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Content>
            <Card>
                <Table
                    size="small"
                    title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} />}
                    columns={columns.filter(e => e.checked === 1)}
                    rowSelection={rowSelectionDisplay ? {
                        selectedRowKeys: consumes.map(e => e.id),
                        onChange: (selectedRowKeys, selectedRows) => {
                            setConsumes(selectedRows.map(e => {
                                return { id: e.id, money: e.money, type_id: e.type_id }
                            }))
                        },
                        getCheckboxProps: record => ({
                            disabled: !(record.invoice_id === 0 && record.in_date === 1 && record.type_id !== 1002),
                            name: record.name,
                        }),
                    } : null}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        reloadTable()
                    }}
                    summary={pageData => {
                        let totalPlus = _.sumBy(data.filter(e => e.amount > 0), 'amount');
                        let totalMinus = _.sumBy(data.filter(e => e.amount < 0), 'amount');
                        return <tr>
                            <td />
                            <th>汇总</th>
                            <td colSpan={5}>
                                <Space>
                                    <span>总收入: <Text style={{ color: '#03be67' }}> +{totalPlus.toFixed(2)}</Text></span>
                                    <span>总支出: <Text type="danger"> {totalMinus}</Text></span>
                                    <Divider type='vertical' />
                                    <span>共 <Text strong>{_.size(pageData)}</Text> 条</span>
                                </Space>
                            </td>
                        </tr>
                    }}
                />
            </Card>
        </Space>

    )
}


export default ConsumeTable

const options = [
    { label: '充值', value: '1001' },
    { label: '后台管理员充值(含税)', value: '1002' },
    { label: '后台管理员充值(不含税)', value: '1003' },
    { label: 'S币转换', value: '1012' },
    // { label: '订单认领', value: '4001' },
];
const mediaOptions = [
    { label: '消费', value: '2001' },
    { label: '退稿退款', value: '2002' },
    { label: '退稿异常退款', value: '2008' },
    { label: '退稿异常退款', value: '2009' },
    { label: '购买优惠券包', value: '1021' },
]
const baikeOptions = [
    { label: '消费', value: '2021' },
    { label: '退稿退款', value: '2022' },
    { label: '客户消费', value: '2023' },
    { label: '高级加盟退稿退款', value: '2024' },
];
const indexOptions = [
    { label: '消费', value: '2031' },
    { label: '退稿退款', value: '2032' },
    { label: '客户消费', value: '2033' },
    { label: '高级加盟退稿退款', value: '2034' },
];
const qaOptions = [
    { label: '消费', value: '2041' },
    { label: '退稿退款', value: '2042' },
];
const userEarnOptions = [
    { label: '稿件成功发布费用', value: '3001' },
    { label: '提现', value: '3002' },
    { label: '企业稿件成功发布费用', value: '3003' },
    { label: '预付套餐收益', value: '3011' },
]
const userLoanOptions = [
    { label: '使用云白条消费', value: '8001' },
    { label: '转化余额至白条', value: '8002' },
]