import React from 'react';
import _ from 'lodash'
import { Typography, Tag, Button, Popconfirm } from 'antd';
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { formatTime } from '../../utils/TimeUtils'

const { Text } = Typography

const EnterpriseInvoiceTableConfig = ({ functions }) => {
    const role = useRecoilValue(userRoleState)

    const { invalidate } = functions;

    const invoiceCol = [
        {
            title: '公司名称',
            dataIndex: 'enterprise_name',
        }, {
            title: '发票代码',
            dataIndex: 'invoice_number',
        }, {
            title: '发票金额',
            dataIndex: 'amount',
            sorter: (a, b) => null,
        }, {
            title: '发票类型',
            dataIndex: 'type',
            render: (val, data) => {
                if (val === 1) {
                    return <Text>专票</Text>
                } else if (val === 2) {
                    return <Text>普票</Text>
                }
            }
        }, {
            title: '备注',
            dataIndex: 'description',
        }, {
            title: '状态',
            dataIndex: 'status',
            width: 72,
            render: (text, data) => {
                let result = '';
                switch (data.status) {
                    case 0:
                        return <Tag style={{ margin: 0 }} color="red">已作废</Tag>;
                    case 1:
                        return <Tag style={{ margin: 0 }} color="green">正常</Tag>;
                    case 2:
                        return <Tag style={{ margin: 0 }} color="orange">已使用</Tag>;
                    default:
                        break;
                }
                return result
            }
        }, {
            title: '创建时间',
            dataIndex: 'create_time',
            render: (t) => formatTime(t),
            sorter: (a, b) => null,
        }, {
            title: '使用时间',
            dataIndex: 'used_time',
            render: (t) => formatTime(t),
            sorter: (a, b) => null,
        }, {
            title: '操作',
            dataIndex: 'id',
            render: (val, data) => {
                return <Popconfirm
                    title={`确认废除当前发票吗?`}
                    onConfirm={() => invalidate(data.id)}
                    onCancel={e => console.log(e)}
                    okText="废除"
                    cancelText="取消">
                    <Button size='small' danger >作废发票</Button>
                </Popconfirm>
            },
        }
    ]



    let result = []
    switch (role) {
        case "FINANCE":
            result = _.concat(
                invoiceCol,
            )
            break;
        default:
            break;
    }
    return result
}

export default EnterpriseInvoiceTableConfig;
