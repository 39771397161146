import React, { useState, useEffect } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Tooltip, Typography, Radio, Button, Spin } from 'antd';
import qs from 'qs';
import _ from 'lodash'
import { useRecoilValue } from 'recoil'

import { userState } from '../../atoms/Atoms'
import { resultResponse } from '../../common/ResultResponse';

const layout = { layout: 'vertical' };

const axios = require('axios');
const { Text } = Typography;
const InvoiceForm = ({ uid = 0 }) => {
    const sessionUser = useRecoilValue(userState)

    const [form] = Form.useForm()
    const [invoice, setInvoice] = useState({
        type: 2,
        title: '',
        invoice_type: 1,
        licence_id: '',
        bank_name: '',
        bank_id: '',
        address: '',
        tel: '',
    })
    const [mode, setMode] = useState("VIEW")
    const [modifyState, setModifyState] = useState(true)
    const [invoiceType, setInvoiceType] = useState(invoice.invoice_type)
    const [loading, setLoading] = useState(false)

    const getUserInvoiceInfo = async (id) => {
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: `/invoice/user/${id}`,
            }).then((res) => {
                let tempInvoice = res.data.data[0]
                let tempData = _.pick(tempInvoice, _.keysIn(invoice))
                setInvoice(tempData)
                setInvoiceType(tempData?.invoice_type)
                setLoading(false)
            })
        }
    }

    const bindInvoiceInfo = async (val) => {
        await axios({
            method: 'post',
            url: '/invoice/bind',
            data: qs.stringify(val)
        }).then((res) => {
            resultResponse(res, '已添加')
            setMode("VIEW")
            setModifyState(true)
        })
    }

    const saleBindInvoiceInfo = async (val) => {
        await axios({
            method: 'post',
            url: `/invoice/bind/${uid}`,
            data: qs.stringify(val)
        }).then((res) => {
            resultResponse(res, '已添加')
            setMode("VIEW")
            setModifyState(true)
        })
    }

    useEffect(() => {
        if (uid !== 0) {
            getUserInvoiceInfo(uid)
        } else {
            getUserInvoiceInfo(sessionUser.id)
        }
    }, [])

    useEffect(() => {
        form.setFieldsValue(invoice)
    }, [invoice, form])

    return <>
        {
            loading
                ? <div style={{ display: 'grid', placeContent: 'center' }}>
                    <Spin />
                </div>
                : <Form
                    {...layout}
                    form={form}
                    onFinish={val => {
                        if (uid === 0) {
                            bindInvoiceInfo(val)
                        } else {
                            saleBindInvoiceInfo(val)
                        }
                    }}
                >
                    <Form.Item label="开具类型" name='type'>
                        <Radio.Group disabled={modifyState}>
                            <Radio value={1}>个人</Radio>
                            <Radio value={2}>企业</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="发票抬头" name="title"
                        rules={[{ required: true, message: '请填写发票抬头！' }]}
                    >
                        <Input disabled={modifyState} />
                    </Form.Item>
                    <Form.Item label="发票类型" name='invoice_type'>
                        <Radio.Group disabled={modifyState} onChange={e => setInvoiceType(e.target.value)}>
                            <Radio value={1}>
                                <Text style={{ marginRight: "8px" }}>
                                    增值税普通发票
                                </Text>
                                <Tooltip title="增值税普通发票开给小规模纳税人或者开票资料不齐全的购买人，购买人取得后不可以进行进项税额抵扣。若您还有疑问，建议联系贵司财务确认后再提交开票需求。">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Radio>
                            <Radio value={2}>
                                <Text style={{ marginRight: "8px" }}>
                                    增值税专用发票
                                </Text>
                                <Tooltip title="增值税专用发票开给一般纳税人，申请时需要提供公司名称、税号、地址电话、开户行名称及账号，一般纳税人批复，购买人取得后可以进行进项税额抵扣。增值税专用发票只能开具纸质发票。若您还有疑问，建议联系贵司财务确认后再提交开票需求。">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="税务登记证号" name="licence_id"
                        rules={[{ required: true, message: '请填写税务登记证号！' }]}
                    >
                        <Input disabled={modifyState} />
                    </Form.Item>
                    <Tooltip title={
                        <>
                            <div>根据国税发16号文件规定，自2017.7.1日，购买方为企业的，索取增值税普通发票时，应向销售方提供纳税人识别号或统一社会信用代码（简称税号）；否则将不得作为税收凭证。</div>
                            <div>公告所称企业，包括公司、非公司制企业法人、企业分支机构、个人独资企业、合伙企业和其他企业</div>
                            <div>注:①如您不属于以上“企业”，如无税号信息，可以不用填写。</div>
                            <div>②如您属于以上“企业”，税号只能是15、17、18、20位数字或字母和数字的组合，请您填写准确</div>
                        </>
                    }>
                        <QuestionCircleOutlined />
                    </Tooltip>
                    <Text style={{ margin: "0 8px" }} type="secondary">
                        请与贵公司财务人员核实后，仔细填写准确的三证合一后的社会统一信用代码或税务登记证号，否则将影响后续发票的正常使用
                    </Text>
                    <Form.Item label="开户银行名称" name="bank_name"
                        rules={[{ required: invoiceType === 2, message: '请输入基本开户账号！' }]}
                    >
                        <Input disabled={modifyState} />
                    </Form.Item>
                    <Form.Item label="基本开户账号" name="bank_id"
                        rules={[{ required: invoiceType === 2, message: '请输入基本开户账号！' }]}
                    >
                        <Input disabled={modifyState} />
                    </Form.Item>
                    <Form.Item label="注册场所地址" name="address"
                        rules={[{ required: invoiceType === 2, message: '请输入注册场所地址！' }]}
                    >
                        <Input disabled={modifyState} />
                    </Form.Item>
                    <Form.Item label="注册固定电话" name="tel"
                        rules={[{ required: invoiceType === 2, message: '请输入注册固定电话！' }]}
                    >
                        <Input disabled={modifyState} />
                    </Form.Item>
                    {
                        {
                            'EDIT':
                                <Button type="primary" onClick={() => form.submit()}>
                                    保存
                                </Button>,
                            'VIEW':
                                <Button type="primary" onClick={() => {
                                    setMode("EDIT")
                                    setModifyState(false)
                                }}>
                                    编辑
                                </Button>
                        }[mode]
                    }
                </Form >
        }
    </>
}


export default InvoiceForm