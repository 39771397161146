import React from 'react'
import {
    Typography, Button, Popconfirm, message, Tag, Space, Tooltip
} from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import _ from 'lodash'

const { Text } = Typography;

export const TagTableConfig = ({ role, attache }) => {
    const size = 'small'

    const defaultConfig = [
        {
            title: '标签',
            dataIndex: 'tag',
            checked: 1,
            render: (t, data) => {
                return <div className='flex-justify'>
                    <Tag color="red">{t}</Tag>
                    {
                        attache === 0 &&
                        <Text type="secondary" style={{ fontSize: '12px' }}>id: {data.id}</Text>
                    }
                </div>
            }
        }
    ]

    const typeConfig = [
        {
            title: '标签领域',
            dataIndex: 'name',
            checked: 1,
            render: (t, data) => {
                return <Tag color="blue">{t}</Tag>
            }
        }
    ]

    const handleUser = {
        title: '用户操作',
        dataIndex: 'back_info',
        checked: 1,
        render: (text, data) => {
            return (
                <Space>
                    <Tooltip title="删除标签" mouseEnterDelay={0.3}>
                        <Popconfirm
                            title="是否删除该标签"
                            // onConfirm={() => deleteUser(data.id)}
                            onCancel={() => message.warn('已取消')}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button type='link' icon={<DeleteTwoTone />} size={size} />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        }
    }



    let result = []

    switch (role) {
        case 'FRANCHISE':
        case 'OPERATOR':
            result = _.concat(
                defaultConfig,
                typeConfig,
                handleUser,
            )
            break;
        default:
            break;
    }
    return result
}
