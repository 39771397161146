import React, { useState, useEffect } from 'react'
import { Calendar, Space, Spin, Typography, Statistic, Row, Col, Drawer, Button, Table, Card } from 'antd';
import _ from 'lodash';
import { UnorderedListOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import { tablePagination } from '../../../common/tableCommon';
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
require('dayjs/locale/zh-cn')
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('zh-cn');

const axios = require('axios');
const { Text } = Typography;

function OperatorSalary() {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [num, setNum] = useState(0);
    const [profit, setProfit] = useState(0);
    const [mediaNum, setMediaNum] = useState(0);
    const [modal, setModal] = useState({});
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [pagination, setPagination] = useState(tablePagination)
    const [date, setDate] = useState();
    const [calendarDate, setCalendarDate] = useState(dayjs());

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function fetchData(date = dayjs(), mode = 'month') {
        if (mode === 'month') {
            let params = new FormData()
            params.append('time', dayjs(date.$d).format('YYYY-MM-DD'))
            params.append('mode', mode)

            if (loading === false) {
                setLoading(true)
                await axios({
                    method: 'post',
                    url: '/user/salary/operator/logs',
                    data: params
                }, {
                    cancelToken: source.token
                }).then((res) => {
                    let data = res.data.data
                    setData(data)
                    let start_month = date.startOf('month').format('YYYY-MM-DD')
                    let end_month = date.endOf('month').format('YYYY-MM-DD')
                    let total_num = _.reduce(data, (result, e) => {
                        if (dayjs(e.time).isSameOrAfter(start_month) && dayjs(e.time).isSameOrBefore(end_month)) {
                            return result + e.num
                        } else {
                            return result
                        }
                    }, 0)
                    let total_profit = _.reduce(data, (result, e) => {
                        if (dayjs(e.time).isSameOrAfter(start_month) && dayjs(e.time).isSameOrBefore(end_month)) {
                            return result + e.profit
                        } else {
                            return result
                        }
                    }, 0)
                    setNum(total_num)
                    setProfit(total_profit)
                    setMediaNum(res.data.extra)
                    setLoading(false)
                })
            }
        }
    }

    function dateCellRender(value) {
        let date = dayjs(value.$d).format('YYYY-MM-DD')
        let title = dayjs(value.$d).format('YYYY年MM月DD日')
        let arr = data.filter(e => e.time === date)
        if (_.size(arr) > 0) {
            let total_profit = _.reduce(arr, (result, e) => {
                return result + e.profit
            }, 0)
            let total_num = _.reduce(arr, (result, e) => {
                return result + e.num
            }, 0)

            return <Space key={date} direction='vertical' size={4}>
                <Button type='link' onClick={() => {
                    setModal({ ...modal, detail: true })
                    setDate(date)
                }} icon={<UnorderedListOutlined />}>{title}</Button>
                <div style={{ padding: '0 12px' }}>
                    <Text>订单收益: ¥{total_profit} ({total_num}条订单)</Text>
                </div>
            </Space >
        }
    }

    function getMonthData(value) {

    }

    function monthCellRender(value) {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    }

    /**
     * 获取每日订单详情
     * @param {日期} date 
     */
    async function getDailyTasks() {
        if (tableLoading === false) {
            let params = new FormData()
            setTableLoading(true)
            params.append('time', date || dayjs(date.$d).format('YYYY-MM-DD'))
            params.append("start", (pagination.current - 1) * pagination.pageSize)
            params.append("length", pagination.pageSize)
            await axios({
                method: 'post',
                url: '/user/salary/operator/orders',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                let data = res.data.data
                setTableData(data)
                setTableLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    function closeDrawer() {
        setModal({})
    }

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        date && getDailyTasks()
    }, [pagination.pageSize, pagination.current, date])

    return <>
        <Space direction='vertical' size={16} className='full-width'>
            <Card loading={loading} >
                <Row gutter={16}>
                    <Col span={6}>
                        <Statistic title="当月累计收益" value={profit} loading={loading} />
                    </Col>
                    <Col span={6}>
                        <Statistic title="当月累计发布数量" value={num} loading={loading} />
                    </Col>
                    <Col span={6}>
                        <Statistic title="当月累计修改媒体" value={_.sumBy(_.filter(mediaNum, e => e?.type_id === 1002), 'num')} loading={loading} />
                    </Col>
                    <Col span={6}>
                        <Statistic title="当月累计新增媒体" value={_.sumBy(_.filter(mediaNum, e => e?.type_id === 1001), 'num')} loading={loading} />
                    </Col>
                </Row>
            </Card>
            <Card loading={loading}>
                <Calendar
                    mode='month'
                    value={calendarDate}
                    dateCellRender={dateCellRender}
                    monthCellRender={monthCellRender}
                    onPanelChange={(date, mode) => {
                        setCalendarDate(date)
                        fetchData(date, mode)
                    }}
                />
            </Card>
        </Space>

        <Drawer
            title={'稿件详情'}
            placement="right"
            size={'large'}
            width='800px'
            onClose={closeDrawer}
            open={modal.detail}
            destroyOnClose={true}
            footer={
                <Space>
                    <Button onClick={closeDrawer}>取消</Button>
                    <Button type="primary" onClick={closeDrawer}>
                        确认
                    </Button>
                </Space>
            }
        >
            {
                tableLoading ?
                    <div style={{ textAlign: 'center', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Spin style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }} />
                    </div>
                    : <Table
                        size='middle'
                        columns={columns}
                        rowKey={record => record.id}
                        dataSource={tableData}
                        pagination={pagination}
                        loading={loading}
                        onChange={(pagination) => {
                            setPagination(pagination)
                        }}
                    />
            }
        </Drawer>
    </>
}

export default OperatorSalary

const columns = [
    {
        title: '名称',
        dataIndex: 'media_name',
    }, {
        title: '标题',
        dataIndex: 'title',
    }, {
        title: '成本',
        dataIndex: 'cost_money',
    }, {
        title: '相对成本',
        dataIndex: 'agent_paid_money',
    }, {
        title: '回执',
        dataIndex: 'back_info',
        render: (val, data) => {
            return <a href={data.back_info} className='table-btn' target="_blank" rel="noopener noreferrer">查看</a>
        }
    },
]