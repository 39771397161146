import React from "react"
import CreateOrder from '../orders/CreateOrder'
import OrderStats from '../orders/OrderStats'
import OrderCenter from '../orders/OrderCenter'
import WorkOrders from '../orders/WorkOrders'
import SendOrders from '../orders/SendOrders'
import EarnOrders from '../orders/EarnOrders'
import ProjectCenter from "../orders/ProjectCenter"
import ProjectDetail from "../orders/ProjectOrderTable"


const OrderRoutesConfig = [
    {
        path: '/dashboard/order/add',
        component: <CreateOrder />
    },
    {
        path: '/dashboard/order/stats',
        component: <OrderStats />
    },
    {
        path: '/dashboard/order',
        component: <OrderCenter />
    },
    {
        path: '/dashboard/order/workorder',
        component: <WorkOrders />
    },
    {
        path: '/dashboard/order/sendorder',
        component: <SendOrders />
    },
    {
        path: '/dashboard/order/earnorder',
        component: <EarnOrders />
    },
    {
        path: '/dashboard/project',
        component: <ProjectCenter />
    },
    {
        path: '/dashboard/project/:id',
        component: <ProjectDetail />
    },
]

export default OrderRoutesConfig