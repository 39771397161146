import React from 'react';
import { Layout, Typography } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Homeheader from '../Homeheader';

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;

const paragraphStyle = { fontSize: '16px', fontFamily: '"PingFang SC", "Microsoft Yahei", Arial, sans-serif', lineHeight: '28px' }
const AgentSolution = () => {
    return <HelmetProvider>
        <Helmet>
            <title>媒介代理</title>
            <meta name="description" content="查看媒介代理解决方案" />
        </Helmet>
        <Homeheader />
        <Content style={{ background: '#fff', margin: '0 auto' }}>
            {/* 揭示行业痛点 */}
            <div style={{ height: '640px' }}>
                <div style={{
                    backgroundImage: 'url(http://static.yunzitui.com/static/asset/solution/agent_solotion_banner.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '640px',
                    width: '100%',
                    position: 'absolute'
                }} />
                <div style={{ width: '1200px', margin: '0 auto' }}>
                    <div style={{
                        position: 'relative',
                        display: 'inline-block',
                        width: '520px',
                        backgroundColor: '#fff',
                        textAlign: 'left',
                        verticalAlign: 'middle',
                        padding: '50px',
                        marginTop: '40px'
                    }}>
                        <Title level={2}>媒介代理使用云自推</Title>
                        <Title style={{ fontSize: '56px', color: '#0067ed', marginTop: '30px' }}>扩展企业市场</Title>
                        <Paragraph style={paragraphStyle}>
                            媒介代理市场竞争日趋激烈，获客成本逐渐走高，客户对代理公司的选择更为慎重，媒体资源成本高，收益少。
                            代理公司多采用平台资源+媒介资源相结合的资源渠道，对接人员每天要对接大量的资源，如何获取最低的成本，最快的出稿速度，还要引导客户进行更多的消费，对于资源渠道的整合提出了更高的要求。
                        </Paragraph>
                        <a href={{}} style={{
                            display: 'block',
                            width: '260px',
                            height: '60px',
                            backgroundColor: '#0067ed',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            fontSize: '22px',
                            lineHeight: '60px'
                        }}>
                            联系我们
                        </a>
                    </div>
                </div>
            </div>
            {/* 强调云自推优势 */}
            <div style={{ margin: '0 auto', backgroundColor: '#0067ed', padding: '30px 0' }}>
                <Title style={{ color: '#FFFFFF', textAlign: 'center' }}>整合优质资源 拒绝中间商赚取差价</Title>
                <Paragraph style={{ ...paragraphStyle, color: '#FFFFFF', textAlign: 'center' }}>
                    云自推长期深耕资源终端，获取一手资源渠道，高效处理订单，降低沟通成本；多元的资源渠道铸就了强大的资源矩阵，{<br />}
                    可以在市场上抢占先机，不为成本烦恼，方便开发客户，提高利润空间。
                </Paragraph>
            </div>
            {/* 优势阐述 */}
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'left', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>独家媒体资源 响应及时处理高效</Title>
                        <Paragraph style={paragraphStyle}>
                            面对客户突如其来的推广需求，多个资源对接渠道往往让销售左右问难，无法及时获取订单进度，处理效率低下。
                            云自推拥有海量独家资源渠道，更有直编入驻，一站式统一处理全渠道项目，快速响应客户需求，及时反馈订单进度，极大提高了工作效率。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/solution/informations.png' alt='' />
                </div>

                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'right', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>多渠道资源整合 建立强大媒体矩阵</Title>
                        <Paragraph style={paragraphStyle}>
                            媒介代理对资源渠道依赖很强，网络媒体资源、百科资源、问答资源等都是重要的推广传播渠道。
                            不同客户的推广需求让公司销售人员感到身心俱疲，工作效率低下。
                            云自推致力于资源终端市场，与资源主进行直接合作，建立媒体矩阵，全面适配企业推广场景，让代理公司能够在市场中抢占先机，提升市场竞争力。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/solution/media_matrix.png' alt='' />
                </div>

                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'left', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>掌握员工销售表现 挖掘金牌员工</Title>
                        <Paragraph style={paragraphStyle}>
                            对客服与销售的工作表现缺乏掌控？优秀的员工得不到挖掘，劣质的服务却在伤及了用户利益后才暴露出来？
                            通过云自推的员工分析管理，你可以精确统计所属客户的消费状况，轻松评估员工表现，树立团队内部标杆典范，提升服务质量。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/solution/sale_manage.png' alt='' />
                </div>

                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'right', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>精细化运营 保证服务质量</Title>
                        <Paragraph style={paragraphStyle}>
                            线上获客渠道复杂，客户的情感维护格外重要，需要及时与客户沟通，筛选高消费意向的潜在客户，避免客户流失。
                            云自推提供邀请码注册机制，每一个销售分配单独邀请码，客户需使用邀请码才能注册使用平台。保证客户与销售一一对应，可持续追踪客户状态，促进交易达成。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/solution/publish.png' alt='' />
                </div>
                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'left', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>客户信息归属公司 保护企业资源</Title>
                        <Paragraph style={paragraphStyle}>
                            行业的特性使得企业的客户资源尤为宝贵，销售离职带走客户的事情屡见不鲜。
                            云自推可将客户信息关系链完全归属于企业，掌控所有商机。同时，未经企业授权，员工无法私自删除注册客户，离职员工账号可自行禁用。
                            更支持消费记录查询、开具发票等，为企业提供了全面的客户资源保障。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/solution/customer_manager.png' alt='' />
                </div>
                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'right', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>多玩法组合营销 持续推广活动</Title>
                        <Paragraph style={paragraphStyle}>
                            云自推提供多种玩法用来激励现有客户进行消费，定期发布平台活动营造气氛，每天更新排名日报，通过新颖的方式和内容调动客户参与的积极性。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/solution/platform_event.png' alt='' />
                </div>
            </div>

            {/* 邀请加入 */}
            <div style={{
                backgroundImage: 'url(https://bqq.gtimg.com/qdweb_2018/static/img/solution/apply_bg.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '400px',
                width: '100%',
                position: 'absolute',
                textAlign: 'center'
            }}>
                <Title style={{ margin: '80px auto 60px auto', fontWeight: 'normal' }}>
                    为了帮助您更好地了解和使用 <span style={{ fontWeight: 'bold' }}>云自推</span>{<br />}
                    我们特别为您提供了产品体验活动
                </Title>
                <a href={{}} style={{
                    display: 'block',
                    width: '320px',
                    height: '72px',
                    backgroundColor: '#0067ed',
                    color: '#FFFFFF',
                    fontSize: '22px',
                    lineHeight: '72px',
                    margin: '0 auto',
                    boxShadow: '0 24px 64px #b4b2b2'
                }}>
                    申请体验
                </a>
            </div>
        </Content>
    </HelmetProvider>
}

export default AgentSolution;