import React, { useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Tag, Space, Table, Button, Layout, Typography, Row, Col, Form, Skeleton } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from "react-router-dom";
import _ from 'lodash';

import { initFormData, tablePagination } from '../../common/tableCommon';
import { ContentCell } from '../../../role/order/orderCommon'
import { formatTime } from '../../../utils/TimeUtils'
import SearchForm from '../../common/SearchForm';
import PageHeader from '../../layout/PageHeader';

const axios = require('axios');

const { Content } = Layout;
const { Title } = Typography

const PAGE_TITLE = '项目详情'
const PIC_STATUS = {
    'WAITING': { color: 'orange', text: '未抓取' },
    'PROCESSING': { color: 'geekblue', text: '处理中' },
    'SUCCESS': { color: 'green', text: '已抓取' },
    'EXCEPTION': { color: 'red', text: '异常' },
}

const searchDatas = [
    {
        name: 'title',
        label: '订单标题',
        placeholder: '请输入标题',
        type: 'input'
    }, {
        name: 'media_name',
        label: '媒体名称',
        placeholder: '请输入媒体名称',
        type: 'input'
    },
]

const routes = (data) => {

    return [
        {
            path: 'dashboard/project',
            breadcrumbName: '项目中心',
        }, {
            path: `${data.id}`,
            breadcrumbName: `${data.proj_name}`,
        },
    ];
}
const ProjectDetail = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [project, setProject] = useState({});
    let params = useParams();
    let navigate = useNavigate();
    const [form] = Form.useForm();

    const initialFormData = initFormData(searchDatas)

    let pid = params?.id
    const columns = [
        {
            title: '媒体名称', dataIndex: 'media_name',
        }, {
            title: '标题', dataIndex: 'title',
        }, {
            title: '内容',
            dataIndex: 'content',
            width: 80,
            checked: 1,
            render: (text, data) => <ContentCell data={data} />
        }, {
            title: '备注',
            dataIndex: 'remarks',
            checked: 1,
        }, {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        }, {
            title: '审核时间',
            dataIndex: 'handle_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        }, {
            title: '发布时间',
            dataIndex: 'media_handle_time',
            width: 96,
            checked: 1,
            render: (t) => formatTime(t)
        }, {
            title: '状态',
            dataIndex: 'status',
            width: 72,
            checked: 1,
            render: (text, data) => {
                if (data.err_status === 2 || data.err_status === -1) {
                    return <Tag style={{ margin: 0 }} color="red">价格异常</Tag>
                }
                let obj = {}
                switch (data.temp_status) {
                    case 'WAIT_PUBLISH':
                        obj = { color: 'gold', text: '待发布' }
                        break;
                    case 'WAIT_VERIFY':
                        obj = { color: 'blue', text: '待审核' }
                        break;
                    case 'SUCCESS':
                        obj = { color: 'green', text: '已发布' }
                        break;
                    case 'BACK':
                        obj = { color: 'red', text: '已退稿' }
                        break;
                    case 'RECALL':
                        obj = { color: 'orange', text: '撤稿中' }
                        break;
                    case 'ERROR':
                        obj = { color: 'geekblue', text: '申诉中' }
                        break;
                    default:
                        break;
                }
                return <Space direction='vertical' size='small'>
                    <Tag style={{ margin: 0 }} color={obj.color}>{obj.text}</Tag>
                </Space>
            }
        }, {
            title: '文章页',
            dataIndex: 'page_status',
            width: 72,
            checked: 1,
            render: (val, data) => {
                let tmp_val = 'PROCESSING'
                let obj = PIC_STATUS[tmp_val]
                return <Space direction='vertical' size='small'>
                    <Tag style={{ margin: 0 }} color={obj.color}>{obj.text}</Tag>
                    {
                        _.indexOf(['SUCCESS', 'EXCEPTION'], tmp_val) > -1 &&
                        <Button type='link' size='small'>查看图片</Button>
                    }
                </Space>
            }
        }, {
            title: '入口',
            dataIndex: 'homepage_status',
            width: 72,
            checked: 1,
            render: (val, data) => {
                let tmp_val = 'SUCCESS'
                let obj = PIC_STATUS[tmp_val]
                return <Space direction='vertical' size='small'>
                    <Tag style={{ margin: 0 }} color={obj.color}>{obj.text}</Tag>
                    {
                        _.indexOf(['SUCCESS', 'EXCEPTION'], tmp_val) > -1 &&
                        <Button type='link' size='small'>查看图片</Button>
                    }
                </Space>
            }
        },
    ]

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        pid && params.append("project_id", pid)
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/proj/search/project',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
                setProject(res.data.extra)
            })
        }
    }

    useEffect(() => {
        pid && fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [pid]);


    return <HelmetProvider>
        <Helmet>
            <title>{PAGE_TITLE}</title>
            <meta name="description" content={PAGE_TITLE} />
        </Helmet>
        <Skeleton loading={loading} style={{ background: '#fff', margin: 24, padding: 24 }}>
            <PageHeader
                title={PAGE_TITLE}
                onBack={() => navigate(-1)}
                tags={<HeaderTag val={project.status} />}
                style={{ backgroundColor: '#fff' }}
                extra={[
                    <Button type='link' onClick={() => console.log()}>截图监控</Button>
                ]}
                breadcrumb={{ routes: routes(project) }}
            />
            <Content style={{ background: '#fff', padding: 24, margin: 24 }}>
                <Form
                    layout='vertical'
                    name="project_order_search_form"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} />
                    <Row>
                        <Col span={12}>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >搜索</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Content>
            <Content style={{ background: '#fff', padding: 24, margin: 24 }}>
                <Title level={4}>订单列表</Title>
                <Table
                    size="middle"
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                />
            </Content>
        </Skeleton>
    </HelmetProvider >
}

export default ProjectDetail

const HeaderTag = ({ val }) => {
    let obj = {}
    switch (val) {
        case 'PROCESSING':
            obj = { color: 'green', text: '处理中' }
            break;
        case 'MONITORING':
            obj = { color: 'orange', text: '抓取中' }
            break;
        case 'ACHIEVED':
            obj = { color: 'geekblue', text: '已归档' }
            break;
        default:
            break
    }
    return <Tag style={{ margin: 0 }} color={obj?.color}>
        {obj?.text}
    </Tag>
}