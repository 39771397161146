import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const UserSearchConfig = ({ miniMode = false }) => {
    const role = useRecoilValue(userRoleState)

    const defaultSearch = [
        {
            name: 'account',
            label: '用户名',
            placeholder: '请输入用户名',
            type: 'input'
        },
        {
            name: 'tel',
            label: '电话',
            placeholder: '请输入电话',
            type: 'input'
        },
        {
            name: 'qq',
            label: 'QQ',
            placeholder: '请输入QQ',
            type: 'input'
        },
    ]

    const levelSearch = [
        {
            name: 'level',
            label: '等级',
            placeholder: '请输入等级',
            type: 'input'
        },
        {
            name: 'points',
            label: '积分',
            type: 'range',
            datas: [
                {
                    name: 'min_points',
                    label: '最小积分',
                    placeholder: '请输入最小积分',
                    type: 'input'
                },
                {
                    name: 'max_points',
                    label: '最大积分',
                    placeholder: '请输入最大积分',
                    type: 'input'
                },
            ]
        },
        {
            name: 'coins',
            label: 'S币',
            type: 'range',
            datas: [
                {
                    name: 'min_coins',
                    label: '最小S币',
                    placeholder: '请输入最小S币',
                    type: 'input'
                },
                {
                    name: 'max_coins',
                    label: '最大S币',
                    placeholder: '请输入最大S币',
                    type: 'input'
                },
            ]
        }, {
            name: 'money',
            label: '余额',
            type: 'range',
            datas: [
                {
                    name: 'min_money',
                    label: '最小余额',
                    placeholder: '请输入最小余额',
                    type: 'input'
                }, {
                    name: 'max_money',
                    label: '最大余额',
                    placeholder: '请输入最大余额',
                    type: 'input'
                },
            ]
        },
    ]

    const timeSearch = [
        {
            name: 'last_login_time',
            label: '登录时间',
            placeholder: '请输入登录时间',
            type: 'datepicker'
        }, {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker'
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker'
        },
    ]

    const operatorSearch = [
        {
            name: 'leader_name',
            label: '所属组长',
            placeholder: '请输入所属组长',
            type: 'input'
        }, {
            name: 'sale_name',
            label: '销售名称',
            placeholder: '请输入销售名称',
            type: 'input'
        }, {
            name: 'sale_group_name',
            label: '所属分组名称',
            placeholder: '请输入所属分组名称',
            type: 'input'
        },
    ]

    const statusSearch = [
        {
            name: 'status',
            label: '用户状态',
            type: 'radio',
            children: [
                ['全部', ''],
                ['正常', '1'],
                ['暂停', '0'],
            ],
            value: '1'
        }, {
            name: 'has_loan',
            label: '白条状态',
            type: 'radio',
            children: [
                ['全部', ''],
                ['开通', true],
                ['未开通', false],
            ],
            value: ''
        }, {
            name: 'is_vip',
            label: '会员状态',
            type: 'radio',
            children: [
                ['全部', ''],
                ['会员', '1'],
            ],
        },
    ]

    let result = []

    switch (role) {
        case "FINANCE":
        case "OPERATOR":
        case "OPERATOR_LEADER":
            if (miniMode) {
                result = _.concat(
                    defaultSearch,
                    operatorSearch,
                    timeSearch,
                )
            } else {
                result = _.concat(
                    defaultSearch,
                    operatorSearch,
                    levelSearch,
                    timeSearch
                )
            }
            break;
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
        case 'SUPPORT_STAFF':
            if (miniMode) {
                result = _.concat(
                    defaultSearch,
                    operatorSearch,
                    timeSearch,
                )
            } else {
                result = _.concat(
                    defaultSearch,
                    operatorSearch,
                    levelSearch,
                    timeSearch,
                    statusSearch
                )
            }
            break;
        default:
            break;
    }

    return result
}

export default UserSearchConfig