import React, { useState, useEffect } from 'react'
import { Col, Row, Layout, Typography, Modal, Card, Space } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom'

import DashHeader from './DashHeader';
import NoticeList from '../notices/NoticeList'
import MediaNoticeList from '../notices/MediaNoticeList'
import { userState } from '../../atoms/Atoms'
import HeaderNav from '../../layout/HeaderNav';

const { Content } = Layout;
const { Title } = Typography;

const Dashboard = () => {
    let navigate = useNavigate();
    const [modal, setModal] = useState({ bindQq: false, exchange: false })

    const sessionUser = useRecoilValue(userState)

    useEffect(() => {
        if (sessionUser.id === '') {
            navigate('/')
        }
        // if (sessionUser.role.role_id === 5) {
        //     setModal({ ...modal, bindQq: _.size(sessionUser.basic.qq) === 0 })
        // }
    }, [])

    return (
        <HelmetProvider>
            <Helmet>
                <title>工作台</title>
                <meta name="description" content="工作台" />
            </Helmet>
            <Content style={{ padding: 12 }}>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Row gutter={[12, 12]}>
                        <Col span={18}>
                            <DashHeader />
                        </Col>
                        <Col span={6}>
                            {/* <Card> */}
                            <div className='nav-list'>
                                <div>
                                    <Link to="/dashboard/order/add">创建文章</Link>
                                </div>
                                <div>
                                    <Link to="/dashboard/order">查看订单</Link>
                                </div>
                                <div>
                                    <Link to="/dashboard/media-center">查看媒体</Link>
                                </div>
                                <div>
                                    <Link to="/dashboard/resource/media">资源入驻</Link>
                                </div>
                                <div>
                                    <Link to="/dashboard/resource/orders">处理订单</Link>
                                </div>
                                <div>
                                    <Link to="/dashboard/invoice/apply">申请发票</Link>
                                </div>
                                <div>
                                    <Link to="/dashboard/coupons/center">优惠中心</Link>
                                </div>
                                <div>
                                    <Link to="/dashboard/events/center">活动中心</Link>
                                </div>
                                <div>
                                    <Link to="/dashboard/missions">任务中心</Link>
                                </div>
                            </div>
                            {/* </Card> */}
                        </Col>
                        <Col span={18}>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Card
                                        title='平台公告'
                                        style={{ height: 400, overflow: 'auto', }} bordered={false}>
                                        <NoticeList />
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card
                                        title='订单变动'
                                        className='resource-log'
                                        style={{ height: 400, overflow: 'auto', }} bordered={false}>
                                        {/* <MediaNoticeList /> */}
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Card
                                title='媒体变动'
                                className='resource-log'
                                style={{ height: 400, overflow: 'auto', }} bordered={false}>
                                <MediaNoticeList />
                            </Card>
                        </Col>
                    </Row>
                    <div style={{ background: 'white' }}>
                        <HeaderNav footer={true} />
                    </div>
                </Space>
            </Content>
            <Modal
                title="绑定QQ"
                open={modal.bindQq}
                onOk={() => navigate.push("/dashboard/user")}
                onCancel={() => setModal({ ...modal, bindQq: false })}
            >
                <Title>通知</Title>
                <p>请未绑定QQ号的用户，及时绑定QQ以便联系</p>
                <p>2020年3月</p>
            </Modal>
        </HelmetProvider >
    );
}

export default Dashboard