import _ from 'lodash'

export const WECHAT = {
    SINGLE: {
        id: 30001,
        name: '单图文价格',
        idx: 1
    },
    MULTI_1: {
        id: 30002,
        name: '多图文头条价格',
        idx: 2
    },
    MULTI_2: {
        id: 30003,
        name: '多图文次条价格',
        idx: 3
    },
    MULTI_OTHER: {
        id: 30004,
        name: '多图文任意价格',
        idx: 4
    },
}

export const getPrice = price => {
    let prices = _.split(price, ',')
    return _.reduce(prices, (result, price) => {
        let tmp = _.split(price, '_')
        return _.assign(result, { [tmp[0]]: tmp[1] })
    }, {})
}