import React, { useState, useEffect } from 'react';
import { Table, message, Layout } from 'antd';

import { tablePagination } from '../../common/tableCommon';
import MissionTableConfig from '../../../role/MissionTableConfig';

const axios = require('axios');
const { Content } = Layout

const MissionsTable = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const fetchData = async (params = new FormData()) => {
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/mission/daily',
                data: params
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.recordsFiltered
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }

    }

    useEffect(() => {
        fetchData()
    }, [])


    const acceptMission = async (id) => {
        await axios({
            method: 'post',
            url: `/mission/item/${id}`,
            dataType: 'json',
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已接受任务")
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const completeMission = async (id) => {
        await axios({
            method: 'post',
            url: `/mission/item/complete/${id}`,
            dataType: 'json',
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已完成任务")
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const handleMedia = { acceptMission, completeMission }
    const columns = MissionTableConfig({ handleMedia })

    return (
        <>
            <Content className='table-search'>
                <Table
                    size='middle'
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                    }}
                />
            </Content>
        </>
    )
}

export default MissionsTable