import React from 'react'
import { Typography, Button, Space } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'

const { Text } = Typography
const EventRuleTableConfig = ({ handleFunctions }) => {
    const role = useRecoilValue(userRoleState)


    const { bindRule } = handleFunctions;

    /**
     * 
     * @param {当前排名} rank 
     * @param {上榜人数} total_people 
     * @param {奖励队列} arr 
     * @param {非奖励金额} price2 
     * @param {达标人数} enoughPeople 
     */
    const getMoney = (rank, total_people, arr, price2, enoughPeople) => {
        // 如果篇数 大于 目标篇数
        // 是否在规定范围内
        // 均分多少钱
        if (rank <= enoughPeople) {
            if (rank <= total_people) {
                return getTargetMoney(rank, arr)
            } else {
                return price2 / (enoughPeople - total_people)
            }
        } else {
            return '未达到排名'
        }
    }

    const getTargetMoney = (n, arr) => {
        return _.filter(arr, e => Math.round(e[0]) === n)[0][1]
    }

    const nameCol = [
        {
            title: '总金额',
            dataIndex: 'total_money',
        }, {
            title: '目标数',
            dataIndex: 'order_num',
        }, {
            title: '规则',
            dataIndex: 'rule',
            render: (t) => {
                let json = JSON.parse(JSON.parse(t))
                let price1 = 0
                let price2 = 0
                let priceArray = []
                let total_people = 0
                _.forEach(json, e => {
                    if (_.size(e.detail) > 0) {
                        price1 += Math.round(e.price)
                        total_people += _.size(e.detail)
                        priceArray = _.concat(priceArray, _.toPairs(e.detail))
                    } else {
                        price2 += Math.round(e.price)
                    }
                })
                return <Space direction='vertical'>
                    <Space>
                        <Text>排名金额: {price1}   总均分金额: {price2}</Text>
                    </Space>
                    <Text>
                        总人数: {total_people}
                    </Text>
                </Space>
            }
        }, {
            title: '说明',
            dataIndex: 'info',
        }, {
            title: '绑定',
            render: (t, data) => {
                return <Button onClick={() => bindRule(data.id)}>绑定</Button>
            }
        }
    ]



    let result = []
    switch (role) {
        case "OPERATOR":
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case "FRANCHISE":
            result = _.concat(
                nameCol,
            )
            break;
        default:
            break;
    }
    return result
}
export default EventRuleTableConfig;
