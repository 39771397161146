import React, { useState, useEffect } from 'react';
import {
    Table, Button, Row, Col, Modal, Layout, Typography,
    message, Input, DatePicker, InputNumber, Form, Space, Divider
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs';
import qs from 'qs';
import { useRecoilValue } from 'recoil';

import { userState } from '../../atoms/Atoms'
import { initFormData, tablePagination } from '../../common/tableCommon';
import OrderTable from './OrderTable';
import { SendOrderTableConfig } from '../../../role/order/SendOrderTableConfig';
import SendOrderSearchConfig from '../../../role/order/SendOrderSearchConfig';
import SendOrderDetailTable from './SendOrderDetailTable';
import SendOrderUserTable from './SendOrderUserTable';
import SearchForm from '../../common/SearchForm';
import { resultResponse } from '../../common/ResultResponse';

const axios = require('axios');
const { Content } = Layout;
const { Text } = Typography;
const { TextArea } = Input;

const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 }, };
const layout = { layout: 'vertical' };
const seartchLayout = { lg: 24, xl: 12 };
const initialModal = { targetId: '', create: false, select: false, orders: false }

const SendOrderTable = () => {

    const sessionUser = useRecoilValue(userState)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState(initialModal)
    const [rowSelected, setRowSelected] = useState({ keys: [], rows: [] })
    const [orders, setOrders] = useState([])

    const [form] = Form.useForm();
    const searchDatas = SendOrderSearchConfig()
    const initialFormData = _.reduce(searchDatas, (result, data) => {
        return _.assign(result, { [data.name]: data.value ?? '' })
    }, {})


    const reloadTable = () => form.submit();
    const resetModal = () => setModal(initialModal)

    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/sendOrder/getList',
                data: params
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.recordsFiltered
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }


    // 创建初始工单
    const createWorkOrder = async (params) => {
        await axios({
            method: 'post',
            url: "/sendOrder/add",
            data: qs.stringify(params)
        }).then(res => {
            if (res.data.code === 1) {
                message.success("已成功添加")
                reloadTable()
            } else {
                message.warning(res.data.msg)
            }
        })
    }


    const selectOrders = (id) => {
        setModal({ ...modal, select: true, targetId: id })
    }

    const bindOrders = async () => {
        let params = new FormData()
        params.append("id", modal.targetId)
        params.append("order_ids[]", orders.map(e => e.id))
        params.append("paid_money", _.sumBy(orders, 'paid_money'))
        await axios({
            method: 'post',
            url: "/sendOrder/bind",
            data: params
        }).then(res => {
            if (res.data.code === 1) {
                message.success("已成功添加")
                reloadTable()
                resetModal()
            } else {
                if (res.data.data) {
                    message.warning(res.data.data)
                } else {
                    message.warning(res.data.msg)
                }
            }
        })
    }

    const finishSaleOrder = async (id, state, sale_price) => {
        await axios({
            method: 'post',
            url: "/sendOrder/finish",
            data: qs.stringify({
                id: id,
                state: state,
                sale_price: sale_price,
            })
        }).then(res => {
            if (res.data.code === 1) {
                message.success("已成功")
                reloadTable()
                resetModal()
            } else {
                if (res.data.data) {
                    message.warning(res.data.data)
                } else {
                    message.warning(res.data.msg)
                }
            }
        })
    }

    const withdraw = async (name, id) => {
        let params = new FormData()
        params.append("ids[]", rowSelected.keys)
        params.append("name", name)
        params.append("id", id)
        await axios({
            method: 'post',
            url: "/sendOrder/withdraw",
            data: params
        }).then(res => {
            resultResponse(res, "已申请")
            reloadTable()
            resetModal()
        })
    }

    // 订单操作函数
    const handleOrders = {
        selectOrders, reloadTable, finishSaleOrder
    }

    const rowSelection = {
        selectedRowKeys: rowSelected.keys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowSelected({ keys: selectedRowKeys, rows: selectedRows })
        },
        getCheckboxProps: record => ({
            disabled: !(record.state === 'SUCCESS' && dayjs(record.target_time).diff(dayjs(), 'days') < 0), 
            name: record.name,
        }),
        selections: rowSelected.rows
    };

    useEffect(() => {
        fetchData()
    }, [])

    let columns = SendOrderTableConfig({ modal, setModal, handleOrders })

    return <>
        <Content className='table-search'>
            <Form
                {...layout}
                name="basic"
                form={form}
                initialValues={initialFormData}
                onFinish={val => fetchData(_.pickBy(val, _.identity))}
            >
                <SearchForm searchDatas={searchDatas} />
                <Row>
                    <Col {...seartchLayout} >
                        <Space>
                            <Button type="primary" onClick={() => setModal({ ...modal, create: true })}><PlusOutlined />创建</Button>
                            <Button onClick={() => setModal({ ...modal, withdraw: true })}>申请结算</Button>
                        </Space>
                    </Col>
                    <Col  {...seartchLayout} style={{ textAlign: 'right' }}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                <SearchOutlined /> 搜索
                                </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Content>
        <Content style={{ background: '#fff', padding: 24 }}>
            <Table
                columns={columns}
                rowSelection={rowSelection}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
                summary={pageData => {
                    let totalBorrow = 0;
                    let totalRepayment = 0;
                    pageData.forEach(({ price, benefit }) => {
                        totalBorrow += price;
                        totalRepayment += benefit;
                    });
                    return (
                        <>
                            <tr>
                                <td colSpan={2} />
                                <th>汇总</th>
                                <td>
                                    <Text type="danger">{totalBorrow}</Text>
                                </td>
                                <td>
                                    <Text>{totalRepayment}</Text>
                                </td>
                                <td colSpan={6} />
                            </tr>
                        </>
                    );
                }}
            />
        </Content>

        <Modal title="创建工单" open={modal.create} footer={null}
            onCancel={() => resetModal()}
        >
            <Form
                name="add-coupon" {...formItemLayout}
                onFinish={(values) => {
                    values = { ...values, target_time: values['target_time'].format("YYYY-MM-DD") }
                    createWorkOrder(values)
                    resetModal()
                }}
            >
                <Form.Item label="名称" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="预计收益" name="benefit">
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item label="预计回款时间" name="target_time">
                    <DatePicker />
                </Form.Item>
                <Form.Item label="备注" name="remark">
                    <TextArea />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                        确认
                    </Button>
                </Form.Item>
            </Form>
        </Modal>

        <Modal title="选择订单" open={modal.select} footer={null}
            onCancel={() => resetModal()}
            width={1500}
        >
            <OrderTable orders={orders} setOrders={setOrders} url='/sendOrder/getOrders' miniMode={true} />
            <Button onClick={() => bindOrders()}>提交</Button>
        </Modal>
        {/* 查看订单 */}
        <Modal title="查看媒体" open={modal.orders} footer={null}
            width={1500}
            onCancel={() => resetModal()}
        >
            <SendOrderDetailTable id={modal.targetId} />
        </Modal>

        <Modal title="订单状态" open={modal.finish} footer={null}
            onCancel={() => resetModal()}
        >
            <Form onFinish={val => {
                finishSaleOrder(modal.targetId, "SUCCESS", val['sale_price'])
            }}>
                <Form.Item label="订单总收入" name="sale_price">
                    <InputNumber min={1} />
                </Form.Item>
                <Divider />
                <div style={{ textAlign: 'right' }}>
                    <Button type='primary' htmlType='submit'>提交</Button>
                </div>
            </Form>
        </Modal>
        {/* 派单用户列表 */}
        <Modal title="分配订单" open={modal.send} footer={null} width={1200}
            onCancel={() => resetModal()}
        >
            <SendOrderUserTable id={modal.targetId} resetModal={resetModal} reloadTable={reloadTable} />
        </Modal>
        {/* 申请结算 */}
        <Modal title="申请结算" open={modal.withdraw} footer={null} onCancel={() => resetModal()}>
            <Form
                initialValues={{ name: sessionUser.basic.alipay_name, id: sessionUser.basic.alipay_id }}
                onFinish={val => withdraw(val['name'], val['id'])} >
                <Form.Item label="支付宝实名" name='name'>
                    <Input />
                </Form.Item>
                <Form.Item label="支付宝账号" name='id'>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button htmlType='submit' type='primary'>提交</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>;
}


export default SendOrderTable
