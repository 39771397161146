import React from 'react'
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import InvoiceForm from './InvoiceForm';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;

const InvoiceInfo = () => {

    let pageTitle = "发票信息"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content style={{ margin: 24 }}>
                <div style={{ background: '#fff', padding: 24 }}>
                    <div style={{ maxWidth: 960, margin: '0 auto' }}>
                        <InvoiceForm />
                    </div>
                </div>
            </Content>

        </HelmetProvider>
    );
}
export default InvoiceInfo