import _ from 'lodash'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'

const ConsumeSearchConfig = ({ action }) => {
    const role = useRecoilValue(userRoleState)

    const manageSearch = [
        {
            name: 'name',
            label: '用户名称',
            placeholder: '请输入用户名称',
            type: 'input',
        }
    ]

    const defaultSearch = [

    ]

    const timeSearch = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: dayjs().subtract(7, 'days')
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        },
    ]

    const statusSearch = [
        {
            name: 'invoice_type_id',
            label: '消费类型',
            type: 'radio',
            children: [
                ['全部', ''],
                ['充值', '1'],
                ['消费', '2'],
                ['退稿', '3'],
                ['收益', '4'],
                ['提现', '5'],
                ['其他', '9'],
            ],
        },
    ]


    let result = []

    switch (role) {
        case "FINANCE":
        case "SALE_ATTACHE":
        case "SALE_LEADER":
        case "CUSTOMER":
            if (action === 'INVOICE') {
                result = _.concat(
                    defaultSearch,
                    timeSearch
                )
            } else {
                result = _.concat(
                    defaultSearch,
                    timeSearch,
                    statusSearch
                )
            }
            break;
        case "FRANCHISE":
            result = _.concat(
                manageSearch,
                defaultSearch,
                timeSearch
            )
            break;
        default:
            break;
    }

    return result
}

export default ConsumeSearchConfig