import React, { useState, useEffect } from 'react';
import { Typography, Button, Statistic, Row, Col, Form, Space, Spin } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import _ from 'lodash'
import dayjs from 'dayjs';

import PageHeader from '../../layout/PageHeader';
import SearchForm from '../../common/SearchForm';

const axios = require('axios');
const { Text } = Typography;

const StatisticsCenter = () => {
    let pageTitle = "统计中心"
    const [datas, setDatas] = useState({})
    const [loading, setLoading] = useState(false)
    const [prefix, setPrefix] = useState("当日")

    const [form] = Form.useForm();

    const searchDatas = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: dayjs().subtract(0, 'days')
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        }
    ]
    const initialFormData = _.reduce(searchDatas, (result, data) => {
        return _.assign(result, { [data.name]: data.value ?? '' })
    }, {})

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: "/finance/stats",
                data: params
            }, {
                cancelToken: source.token
            }).then(res => {
                let data = res.data.data
                updateDatas(data)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    const refreshTableByDays = (val) => {
        let data = form.getFieldsValue()
        if (val === 30) {
            form.setFieldsValue({ ...data, start_time: dayjs().subtract(1, 'months') })
        } else {
            form.setFieldsValue({ ...data, start_time: dayjs().subtract(val, 'days') })
        }
        form.submit()
    }

    const updateDatas = (data) => {
        let tempData = {}
        _.forEach(typeArray, arr => {
            let val = _.sumBy(_.filter(data, t => {
                return t.type_id === arr.id
            }), 'total')
            tempData[arr.name] = val
        })
        setDatas(tempData)
    }

    const reloadTable = () => fetchData()

    const typeArray = [
        { name: 'recharge', id: 1001 },
        { name: 'manager_recharge', id: 1002 },
        { name: 'authentification', id: 1009 },
        { name: 'vip', id: 1011 },
        { name: 'exchange', id: 1012 },
        { name: 'coupon_pack', id: 1021 },
        { name: 'article', id: 2001 },
        { name: 'refuse_article', id: 2002 },
        { name: 'enterprise_article', id: 2003 },
        { name: 'enterprise_refuse_article', id: 2004 },
        { name: 'refuse_article_success', id: 2008 },
        { name: 'enterprise_refuse_article_success', id: 2009 },
        { name: 'buy_package', id: 2011 },
        { name: 'baike', id: 2021 },
        { name: 'refuse_baike', id: 2022 },
        { name: 'enterprise_baike', id: 2023 },
        { name: 'enterprise_refuse_baike', id: 2024 },
        { name: 'index', id: 2031 },
        { name: 'refuse_index', id: 2032 },
        { name: 'enterprise_index', id: 2033 },
        { name: 'enterprise_refuse_index', id: 2034 },
        { name: 'qa', id: 2041 },
        { name: 'refuse_qa', id: 2042 },
        { name: 'enterprise_qa', id: 2043 },
        { name: 'enterprise_refuse_qa', id: 2044 },
        { name: 'earn', id: 3001 },
        { name: 'withdraw', id: 3002 },
        { name: 'enterprise_earn', id: 3003 },
        { name: 'package_earn', id: 3011 },
        { name: 'send_order', id: 4001 },
        { name: 'fee', id: 9001 },
        { name: 'month_fee', id: 9008 },
        { name: 'annually_fee', id: 9009 }
    ]

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <div style={{ margin: 24 }}>
                <div style={{ background: '#fff', padding: 24 }}>
                    <Form
                        layout='vertical'
                        form={form}
                        initialValues={initialFormData}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                    >
                        <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                        <Row gutter={[12, 12]}>
                            <Col span={12} />
                            <Col span={12} key='next' style={{ textAlign: "right" }}>
                                <Space>
                                    <Button.Group key='buttons'>
                                        <Button key="day" onClick={() => { refreshTableByDays(0); setPrefix("当天") }}>当天</Button>
                                        <Button key="week" onClick={() => { refreshTableByDays(7); setPrefix("本周") }}>查看一周</Button>
                                        <Button key="month" onClick={() => { refreshTableByDays(30); setPrefix("本月") }}>查看一个月</Button>
                                    </Button.Group>
                                    <Button type="primary" htmlType="submit">继续</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <Spin spinning={loading} >
                    <div style={{ display: 'grid', gap: 16, marginTop: 24, gridTemplateColumns: '1fr 1fr' }}>
                        <PageHeader
                            title={`${prefix}消费`}
                            style={{ backgroundColor: '#fff', padding: 16, width: '100%' }}
                        >
                            <div style={{ display: 'grid', columnGap: 16, gridTemplateColumns: 'repeat(4, 1fr)' }}>
                                <Statistic title={<Text style={{ fontSize: 16 }}>新闻媒体</Text>} value={datas.article} valueStyle={{ color: '#3f8600' }}
                                    precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>百科</Text>} value={datas.baike} valueStyle={{ color: '#3f8600' }}
                                    precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>文字链</Text>} value={datas.index} valueStyle={{ color: '#3f8600' }}
                                    precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>问答</Text>} value={datas.qa} valueStyle={{ color: '#3f8600' }}
                                    precision={2} prefix={"¥"} />
                            </div>
                        </PageHeader>
                        <PageHeader
                            title={`${prefix}退稿`}
                            style={{ backgroundColor: '#fff', padding: 16, width: '100%' }}
                        >
                            <div style={{ display: 'grid', columnGap: 16, gridTemplateColumns: 'repeat(4, 1fr)' }}>
                                <Statistic title={<Text style={{ fontSize: 16 }}>新闻媒体</Text>} value={datas.refuse_article} valueStyle={{ color: '#cf1322' }}
                                    precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>百科</Text>} value={datas.refuse_baike} valueStyle={{ color: '#cf1322' }}
                                    precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>文字链</Text>} value={datas.refuse_index} valueStyle={{ color: '#cf1322' }}
                                    precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>问答</Text>} value={datas.refuse_qa} valueStyle={{ color: '#cf1322' }}
                                    precision={2} prefix={"¥"} />
                            </div>
                        </PageHeader>
                        <PageHeader
                            title={`${prefix}平台支出`}
                            style={{ backgroundColor: '#fff', padding: 16, width: '100%' }}
                        >
                            <div style={{ display: 'grid', columnGap: 16, gridTemplateColumns: 'repeat(4, 1fr)' }}>
                                <Statistic title={<Text style={{ fontSize: 16 }}>新闻媒体</Text>} value={datas.enterprise_article} precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>百科</Text>} value={datas.enterprise_baike} precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>文字链</Text>} value={datas.enterprise_index} precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>问答</Text>} value={datas.enterprise_qa} precision={2} prefix={"¥"} />
                            </div>
                        </PageHeader>
                        <PageHeader
                            title={`${prefix}平台退款`}
                            style={{ backgroundColor: '#fff', padding: 16, width: '100%' }}
                        >
                            <div style={{ display: 'grid', columnGap: 16, gridTemplateColumns: 'repeat(4, 1fr)' }}>
                                <Statistic title={<Text style={{ fontSize: 16 }}>新闻媒体</Text>} value={datas.enterprise_refuse_article}
                                    precision={2} prefix={"¥"} />
                                <Statistic title="百科" value={datas.enterprise_refuse_baike}
                                    precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>文字链</Text>} value={datas.enterprise_refuse_index}
                                    precision={2} prefix={"¥"} />
                                <Statistic title={<Text style={{ fontSize: 16 }}>问答</Text>} value={datas.enterprise_refuse_qa}
                                    precision={2} prefix={"¥"} />
                            </div>
                        </PageHeader>
                        <PageHeader
                            title={`${prefix}平台充值`}
                            style={{ backgroundColor: '#fff', padding: 16, width: '100%' }}
                        >
                            <div style={{ display: 'grid', columnGap: 16, gridTemplateColumns: 'repeat(4, 1fr)' }}>
                                <Statistic title="用户自行充值" value={datas.recharge}
                                    precision={2} prefix={"¥"} />
                                <Statistic title="管理员充值" value={datas.manager_recharge}
                                    precision={2} prefix={"¥"} />
                            </div>
                        </PageHeader>
                    </div>
                </Spin>
            </div>
        </HelmetProvider >
    )
}

export default StatisticsCenter;