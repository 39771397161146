import React from 'react'
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CouponsPackTable from './CouponsPackTable';
const { Content } = Layout

/**
 * /dashboard/coupons/pack
 */
const CouponPack = () => {

    return (
        <HelmetProvider>
            <Helmet>
                <title>活动管理</title>
                <meta name="description" content="活动管理" />
            </Helmet>
            <div style={{ margin: 24 }}>
                <Content
                    style={{
                        background: '#fff',
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    <CouponsPackTable />
                </Content>
            </div>
        </HelmetProvider>
    )
}

export default CouponPack
