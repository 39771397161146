import React from 'react'
import _ from 'lodash'
import { Button, Popconfirm, Typography } from 'antd'
import { DeleteOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const CouponPackTableConfig = ({ handleMedia }) => {
    const role = useRecoilValue(userRoleState)

    const { getCouponPack, deleteCouponPack, } = handleMedia

    const couponCol = [
        {
            title: '名称',
            dataIndex: 'pack_name',
        }, {
            title: '描述',
            dataIndex: 'pack_description',
        }, {
            title: '原价',
            dataIndex: 'price',
        }, {
            title: '会员价',
            dataIndex: 'price_vip_1',
        }, {
            title: '限购数量',
            dataIndex: 'max_nums',
        }, {
            title: '会员限购数量',
            dataIndex: 'max_vip_nums',
        }, {
            title: '创建时间',
            dataIndex: 'create_time',
        }, {
            title: '失效时间',
            dataIndex: 'expire_time',
        }, {
            title: '购买使用失效',
            dataIndex: 'expire_day',
            render: (t) => (
                <Typography.Text>{t}天</Typography.Text>
            )
        }, {
            title: '操作',
            dataIndex: 'id',
            render: (t, data) => {
                return (
                    <>
                        <Button.Group>
                            <Popconfirm
                                title="是否删除当前卡包?"
                                onConfirm={() => deleteCouponPack(data.id)}
                                onCancel={() => console.log("取消")}
                                okText="确认"
                                cancelText="取消"
                            >
                                <Button><DeleteOutlined />删除</Button>
                            </Popconfirm>
                            <Button onClick={() => getCouponPack(data.id)}><UnorderedListOutlined />查看</Button>
                        </Button.Group>
                    </>
                )
            }
        },
    ]

    let result = []
    switch (role) {
        case "FINANCE":
            result = _.concat(
                couponCol,
            )
            break;
        default:
            result = _.concat(
                couponCol,
            )
            break;
    }
    return result
}
export default CouponPackTableConfig;
