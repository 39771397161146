import React, { useState, useEffect } from 'react';
import {
    Table, Button, Row, Col, Card, Modal, Tabs, Layout, Switch, InputNumber,
    message, Input, Select, Badge, Form, Typography, Spin, Space, Divider, Checkbox
} from 'antd';
import { SearchOutlined, ExportOutlined } from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs'
import qs from 'qs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { serialize } from 'object-to-formdata';

import OrderTimeLine from './OrderTimeLine';
import OrderUserTable from './OrderUserTable'
import OrderCensor from './OrderCensor';
import { resultResponse } from '../../common/ResultResponse';
import { TimeSearchButtonGroup, initFormData, tablePagination, TableTitle } from '../../common/tableCommon';
import SearchForm from '../../common/SearchForm'
import { OrderTableConfig } from '../../../role/order/OrderTableConfig'
import OrderSearchConfig from '../../../role/order/OrderSearchConfig'
import { userRoleState, refreshUserState } from '../../atoms/Atoms'
import { errorReason, getResponse, getText, recallReason } from '../../../role/order/orderCommon';
import RoleModule from '../../../utils/permission/RoleModule';
import { check } from '../../../utils/permission/rules';
import ResourceInfoTable from './ResourceInfoTable';
import ProjectDetail from './ProjectOrderTable';
import ProjectTable from './ProjectTable';


const axios = require('axios');
const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;

const { Option } = Select;
const { TextArea } = Input;

const layout = { layout: 'vertical' };
const seartchLayout = { lg: 24, xl: 12 };
const initialModal = {
    target_id: undefined,
    verifyFailed: false, publishSuccess: false, publishOrderFailed: false,
    updateUrl: false, recallBox: false, errorBox: false,
    download: false, rename: false, history: false, censor: false, updateCost: false,
}

const OrderTable = ({
    searchFormDisplay = true,
    url, action, miniMode = false, hasTabBar = true,
    orders = [], setOrders = () => console.log(''), preData = {}
}) => {
    const role = useRecoilValue(userRoleState)
    const setRefresh = useSetRecoilState(refreshUserState)

    const permission = {
        'module:media:order:money:cost': check(role, 'module:media:order:money:cost', { action }),
        'module:media:order:money:paid': check(role, 'module:media:order:money:paid', { action }),
        'module:media:order:money:agent': check(role, 'module:media:order:money:agent', null),
        'module:media:order:money:sales:agent': check(role, 'module:media:order:money:sales:agent', null),
    }

    const searchDatas = OrderSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [tab, setTab] = useState('1')

    const [status, setStatus] = useState({ media_status: '', status: '', })
    const [modal, setModal] = useState(initialModal)
    const [text, setText] = useState("")
    const [err_selector, setErr_selector] = useState('1')
    const [rcl_selector, setRcl_selector] = useState('1')
    const [num, setNum] = useState([])
    const [creator, setCreator] = useState("")
    const [name, setName] = useState("")
    const [history, setHistory] = useState([])
    const [cost, setCost] = useState(0)
    const [resourceInfo, setResourceInfo] = useState([])
    const [payInfo, setPayInfo] = useState({})

    const [form] = Form.useForm();
    const [resource_form] = Form.useForm();
    const [apply_form] = Form.useForm();

    const reloadTable = () => {
        form.submit();
        setOrders([]);
        setRefresh(true)
    }
    const resetModal = () => { setModal(initialModal); setText("") } // 重置弹出框 以及 文本框

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (creator !== "") {
            params.append("creator_id", creator)
        }
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        preData && _.toPairs(preData).forEach(e => e[1] && params.append(`${e[0]}`, e[1]))
        _.toPairs(status).filter(e => e[1]).map(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: url || '/order/resource/list',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                let tmpNum = res.data.extra
                setNum(_.reduce(tmpNum, (result, e) => {
                    return _.assign(result, { [e.state]: e.total })
                }, {}))
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    // 订单审核通过
    const verifyOrderSuccess = async (id) => {
        await axios({
            method: 'post',
            url: "/order/verify",
            data: qs.stringify({
                ids: _.isNil(id) ? _.join(orders.map(e => e.id), ',') : id,
                status: "waitPublish"
            })
        }).then(res => {
            resultResponse(res, "已通过审核")
            reloadTable()
        })
    }
    // 订单审核不通过
    const verifyOrderFailed = async (id, info) => {
        await axios({
            method: 'post',
            url: "/order/verify",
            data: qs.stringify({
                ids: _.isNil(id) ? _.join(orders.map(e => e.id), ',') : id,
                status: "notPass",
                reason: info
            })
        }).then(res => {
            resultResponse(res, "未通过审核")
            reloadTable()
        })
    }

    // 订单发布回执
    const publishOrderSuccess = async (id, info) => {
        await axios({
            method: 'post',
            url: "/order/publish",
            data: qs.stringify({ order_ids: id, status: "success", reason: info })
        }).then(res => {
            resultResponse(res, "已发布回执")
            reloadTable()
        })
    }
    // 订单退稿回执
    const publishOrderFailed = async (id, info) => {
        await axios({
            method: 'post',
            url: "/order/publish",
            data: qs.stringify({
                order_ids: id ?? _.join(orders.map(e => e.id), ','),
                status: "back",
                reason: info
            })
        }).then(res => {
            resultResponse(res, "已退稿")
            reloadTable()
        })
    }
    // 订单修改回执
    const updateUrl = async (id, info) => {
        await axios({
            method: 'post',
            url: "/order/update",
            data: qs.stringify({ id: id, back_info: info })
        }).then(res => {
            if (res.data.code === '200') {
                message.success("已成功修改回执")
                reloadTable()
            } else {
                message.error(res.data.message)
            }
        })
    }

    // 订单结算状态修改
    const updatePayinfo = async (id, status) => {
        await axios({
            method: 'post',
            url: "/order/pay",
            data: qs.stringify({
                ids: _.isNil(id) ? _.join(orders.map(e => e.id), ',') : id,
                paid_status: status === 'paid' ? 'unpaid' : 'paid'
            })
        }).then(res => {
            resultResponse(res, "已修改")
            reloadTable()
        })
    }

    // 已发布订单 退稿
    const rejectOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/order/reject",
            data: qs.stringify({ id: id })
        }).then(res => {
            resultResponse(res, "已成功退稿")
            reloadTable()
        })
    }
    // 待审核 订单 撤回
    const cancelOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/order/cancel",
            data: qs.stringify({ id: id })
        }).then(res => {
            resultResponse(res, "已撤回")
            reloadTable()
        })
    }
    // 待审核 订单申请撤回
    const recallOrder = async (id, info) => {
        await axios({
            method: 'post',
            url: "/order/recall",
            data: qs.stringify({
                id: id,
                recall_type: rcl_selector,
                recall_reason: info
            })
        }).then(res => {
            resultResponse(res, "已提交撤回申请")
            reloadTable()
        })
    }
    // 已发布 订单异常申请
    const errorOrder = async (id, info) => {
        await axios({
            method: 'post',
            url: "/order/err",
            data: qs.stringify({
                id: id,
                err_type: err_selector,
                err_reason: info
            })
        }).then(res => {
            resultResponse(res, "已提交异议申请")
            reloadTable()
        })
    }

    const rename = async () => {
        await axios({
            method: 'post',
            url: "/order/rename",
            data: qs.stringify({
                uid: modal.target_id,
                name: name
            })
        }).then(res => {
            resultResponse(res, "已提交修改")
            setModal({ ...modal, rename: false })
            reloadTable()
        })
    }

    const updateCost = async () => {
        await axios({
            method: 'post',
            url: "/order/update/cost",
            data: serialize({ id: modal.target_id, cost: cost })
        }).then(res => {
            resultResponse(res, "已提交更新")
            setModal({ ...modal, updateCost: false })
            reloadTable()
        })
    }

    const downloadOrders = async () => {
        setModal({ ...modal, download: true })
        let val = _.pickBy(form.getFieldsValue(), _.identity);
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let url = action === 'EDIT'
            ? `/order/download_user_order?${qs.stringify(_.assign(val, status))}`
            : `/order/downLoadOrder?${qs.stringify(_.assign(val, status))}`
        await axios({
            url: url, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '订单详情.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }

    const checkHistory = async (id) => {
        setModal({ ...modal, history: true })
        await axios({
            method: 'post',
            url: `/order/history/${id}`
        }).then(res => {
            if (res.data.code === 1) {
                setHistory(res.data.data)
            } else {
                message.error(res.data.msg)
            }
        })
    }

    const checkCensor = async (id) => setModal({ ...modal, censor: true, target_id: id })

    const autoFetch = async () => {
        let val = _.pickBy(form.getFieldsValue(), _.identity)
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        val && _.toPairs(val).forEach(e => e[1] && params.append(`${e[0]}`, e[1]))
        preData && _.toPairs(preData).forEach(e => e[1] && params.append(`${e[0]}`, e[1]))
        _.toPairs(status).filter(e => e[1]).map(e => params.append(`${e[0]}`, e[1] ?? ''))

        await axios({
            method: 'post',
            url: '/workOrder/bind/auto',
            data: params
        }).then((res) => {
            console.log(res.data)
        })
    }

    // 绑定资源主信息
    const bindResourceInfo = async () => {
        let values = resource_form.getFieldsValue()
        let formData = new FormData()
        _.toPairs(values).forEach(e => formData.append(`${e[0]}`, e[1] ?? ''))
        formData.append('ids', _.concat([], modal.id))
        await axios({
            method: 'post',
            url: `/order/set/resource-info`,
            data: formData
        }).then(res => {
            resultResponse(res, '已绑定');
            resetModal()
        })
    }

    // 获取资源支付信息
    const getResourceInfo = async () => {
        let temp = _.filter(orders, e => {
            return [2, 5297, 5523].indexOf(e.handle_user_id) !== -1
        })
        let data = _.filter(temp, e => e.paid_status === 'unpaid')

        setOrders(data)
        if (_.size(data) < 1) {
            message.warn('请正确选择内部处理订单!');
            return;
        }
        let formData = new FormData()
        formData.append('ids', _.uniq(_.map(data, e => e.media_id)))
        setModal({ ...modal, apply: true })
        await axios({
            method: 'post',
            url: `/order/get/resource-info`,
            data: formData
        }).then(res => {
            setResourceInfo(res.data.data)
        })
    }

    // 提交申请
    const applyResourceOrders = async (data) => {
        let formData = new FormData()
        formData.append('ids', _.map(orders, e => e.id))
        formData.append('alipay_id', data.alipay_id)
        formData.append('alipay_name', data.alipay_name)
        formData.append('money', _.sumBy(orders, 'cost_money'))
        formData.append('save', Boolean(data?.save))

        await axios({
            method: 'post',
            url: `/order/withdraw/orders`,
            data: formData
        }).then(res => {
            resultResponse(res, '已成功申请')
            reloadTable()
            resetModal()
        })
    }

    const checkResourceInfo = async (media_id) => {
        // 弹窗 调取后台联系方式
        setModal({ ...modal, resource_info: true, media_id: media_id })
    }


    const openResourceInfoForm = (id) => setModal({ ...modal, info: true, id: id }) // 展开资源主联系表格

    /**
     * 修改订单所属项目
     * @param {number} order_id 稿件id
     */
    const modifyOrderProject = async (order_id) => {
        setModal({
            ...modal,
            order_id: order_id,
            modify_project: true
        })
    }

    function showErrorInfo(data, type) {
        setModal({ ...modal, error_info: true, error_data: data, error_type: type })
    }

    // 订单操作函数
    const handleOrders = {
        verifyOrderSuccess, cancelOrder,
        recallOrder, errorOrder, reloadTable, rejectOrder, checkHistory,
        checkCensor, openResourceInfoForm, checkResourceInfo,
        modifyOrderProject, showErrorInfo
    }
    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [creator])


    //--------------- 批量处理 -----------------------------------------
    const multiCopy = () => orders.reduce((total, val) => total + getText(val) + '\n', '')
    const multiCopySuccess = () => orders.reduce((total, val) => total + getResponse(val) + '\n', '')

    const filterMediaStatus = (e) => {
        setTab(e)
        setOrders([])
        switch (e) {
            case '2':
                setStatus({ status: 'waitVerify', media_status: 'waitPublish' })
                break;
            case '3':
                setStatus({ status: 'waitPublish', media_status: 'waitPublish' })
                break;
            case '4':
                setStatus({ status: 'complete', media_status: 'success' })
                break;
            case '5':
                setStatus({ status: '', media_status: 'back' })
                break;
            case '6':
                setStatus({ status: 'waitPublish', media_status: 'recall' })
                break;
            case '7':
                setStatus({ status: 'complete', media_status: 'error' })
                break;
            default:
                setStatus({ status: '', media_status: '' })
        }
        form.submit()
    }

    let defaultColumns = OrderTableConfig({ modal, setModal, handleOrders, tab, action })
    const localStorageColumnsName = "____OrderTableConfig____";
    let localStorageColumns = JSON.parse(localStorage.getItem(localStorageColumnsName))
    _.forEach(defaultColumns, (e) => {
        _.forEach(localStorageColumns, (val) => {
            if (e.title === val.title) {
                e.checked = val.checked
            }
        })
    })
    const [columns, setColumns] = useState(defaultColumns)

    return <>
        <Space direction='vertical' size={16} className='full-width'>
            <Content className={!searchFormDisplay && 'hidden'}>
                <Card title='媒体搜索'>
                    <Form
                        {...layout}
                        name="basic"
                        form={form}
                        initialValues={initialFormData}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                    >
                        {searchFormDisplay &&
                            <>
                                <SearchForm searchDatas={searchDatas} />
                                <Row>
                                    <Col {...seartchLayout} >
                                        <Space>
                                            <RoleModule action='module:media:order:solo'>
                                                <Switch defaultChecked={creator === ''} checkedChildren="全部" unCheckedChildren="单独"
                                                    onChange={e => setCreator(e ? "" : 2)} />
                                            </RoleModule>
                                            <RoleModule action='module:media:order:toolbar' data={{ action }}>
                                                <Space>
                                                    {
                                                        tab === '2' &&
                                                        <Button.Group>
                                                            <Button type="primary" onClick={() => verifyOrderSuccess(modal.target_id)}>批量通过</Button>
                                                            <Button danger onClick={() => setModal({ ...modal, verifyFailed: true })}>批量不通过</Button>
                                                        </Button.Group>
                                                    }
                                                    {
                                                        tab === '3' &&
                                                        <Button danger onClick={() => setModal({ ...modal, publishOrderFailed: true })} >批量退稿</Button>
                                                    }
                                                    {
                                                        tab === '4' &&
                                                        <RoleModule action='module:media:order:editor:apply'>
                                                            <Space>
                                                                <Button type='link' onClick={() => updatePayinfo(modal.target_id, 'unpaid')}>批量结款</Button>
                                                                <Button type='link' onClick={() => getResourceInfo()}>申请结算</Button>
                                                            </Space>
                                                        </RoleModule>
                                                    }
                                                </Space>
                                            </RoleModule>
                                            <RoleModule action='module:media:order:modify'>
                                                <Button type="primary" onClick={() => setModal({ ...modal, modifyOrder: true })}>批量修改订单</Button>
                                            </RoleModule>
                                            {
                                                url === '/workOrder/getOrders' &&
                                                <Button danger onClick={() => autoFetch()}>自动选取</Button>
                                            }
                                            <CopyToClipboard text={multiCopy()} onCopy={() => message.success('已成功复制')}>
                                                <Button type='link'>批量复制来源</Button>
                                            </CopyToClipboard>
                                            {
                                                tab === '4' &&
                                                <CopyToClipboard text={multiCopySuccess()} onCopy={() => message.success('已成功复制')}>
                                                    <Button type='link'>批量复制回执</Button>
                                                </CopyToClipboard>
                                            }
                                        </Space>
                                    </Col>
                                    <Col  {...seartchLayout} style={{ textAlign: 'right' }}>
                                        <Space>
                                            <TimeSearchButtonGroup form={form} />
                                            {!miniMode && <Button icon={<ExportOutlined />} onClick={() => downloadOrders()}>导出</Button>}
                                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >搜索</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Form>
                </Card>
            </Content>
            <Card>
                <Table
                    size='small'
                    title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} menu={hasTabBar && <TabBar num={num} func={filterMediaStatus} />} />}
                    columns={columns.filter(e => e.checked === 1)}
                    rowSelection={{
                        selectedRowKeys: orders.map(e => e.id),
                        onSelect: (record, selected) => {
                            let newMedias = selected ? _.concat(orders, record) : _.differenceBy(orders, [record], 'id');
                            setOrders(_.uniqBy(newMedias, 'id'))
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            let newMedias = selected ? _.concat(orders, changeRows) : _.differenceBy(orders, changeRows, 'id');
                            setOrders(_.uniqBy(newMedias, 'id'))
                        }
                    }}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => { setPagination(pagination); form.submit() }}
                    summary={pageData => <Summary pageData={pageData} orders={orders} permission={permission} columns={columns} />}
                />
            </Card>
        </Space>


        <Modal title="拒稿理由" open={modal.verifyFailed}
            onOk={() => {
                verifyOrderFailed(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>
        <Modal title="填写回执" open={modal.publishSuccess}
            onOk={() => {
                publishOrderSuccess(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>
        <Modal title="退稿理由" open={modal.publishOrderFailed}
            onOk={() => {
                publishOrderFailed(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>
        <Modal title="编辑回执" open={modal.updateUrl}
            onOk={() => {
                updateUrl(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>
        <Modal title="撤稿申请" open={modal.recallBox}
            onOk={() => {
                recallOrder(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <Select
                showSearch style={{ marginBottom: '8px' }}
                className='full-width'
                placeholder="请选择撤稿理由"
                defaultValue="1"
                onChange={e => setRcl_selector(e)}
            >
                <Option value="1">选择错误媒体</Option>
                <Option value="2">来源填写错误</Option>
                <Option value="3">稿件内容有误</Option>
                <Option value="9">其他</Option>
            </Select>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>

        <Modal title="异常申请" open={modal.errorBox}
            onOk={() => {
                errorOrder(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => resetModal()}>
            <Select
                showSearch style={{ marginBottom: '8px' }}
                className='full-width'
                placeholder="请选择异常理由"
                defaultValue="1"
                onChange={e => setErr_selector(e)}
            >
                <Option value="1">稿件内容遭修改</Option>
                <Option value="2">稿件被删除</Option>
                <Option value="3">稿件未收录</Option>
                <Option value="9">其他</Option>
            </Select>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>

        <Modal
            open={modal.modifyOrder}
            footer={null} width={1000}
            onOk={() => setModal({ ...modal, modifyOrder: false })}
            onCancel={() => setModal({ ...modal, modifyOrder: false })}
        >
            <OrderUserTable ids={orders.map(e => e.id)} reloadTable={reloadTable} resetModal={resetModal} />
        </Modal>
        <Modal
            open={modal.download}
            footer={null}
            onOk={() => setModal({ ...modal, download: false })}
            onCancel={() => setModal({ ...modal, download: false })}
        >
            <div style={{ textAlign: 'center' }}>
                <Spin tip="下载中..." />
            </div>
        </Modal>
        <Modal
            open={modal.loading}
            footer={null}
            onOk={() => setModal({ ...modal, loading: false })}
            onCancel={() => setModal({ ...modal, loading: false })}
        >
            <div style={{ textAlign: 'center' }}>
                <Spin tip="处理中..." />
            </div>
        </Modal>
        <Modal
            title="重命名" open={modal.rename}
            footer={null}
            onCancel={() => setModal({ ...modal, rename: false })}
        >
            <Space direction='vertical'>
                <Input type="text" value={name} onChange={e => setName(e.target.value)} />
                <Button type="primary" onClick={() => rename()}>确认修改</Button>
            </Space>
        </Modal>
        <Modal
            title="操作记录" open={modal.history}
            footer={null}
            onCancel={() => setModal({ ...modal, history: false })}
        >
            <OrderTimeLine history={history} />
        </Modal>
        <Modal
            title="违规内容" open={modal.censor}
            footer={null}
            onCancel={() => setModal({ ...modal, censor: false })}
        >
            <OrderCensor id={modal.target_id} />
        </Modal>
        <Modal
            title="修改成本" open={modal.updateCost}
            footer={null}
            onCancel={() => setModal({ ...modal, updateCost: false })}
        >
            <Space direction='vertical'>
                <InputNumber type="text" min={0} value={cost} onChange={val => setCost(val)} />
                <Button type="primary" onClick={() => updateCost()}>确认修改</Button>
            </Space>
        </Modal>
        <Modal
            title="快速绑定" open={modal.info}
            okText="保存"
            cancelText="取消"
            onOk={() => bindResourceInfo()}
            onCancel={() => setModal({ ...modal, info: false })}
            destroyOnClose={true}
        >
            <Form
                form={resource_form}
                name="resource_form"
                layout="vertical"
            >
                <Form.Item name='nickname' label="资源主名称">
                    <Input />
                </Form.Item>
                <Form.Item name='qq' label="资源主QQ">
                    <Input />
                </Form.Item>
                <Form.Item name='tel' label="资源主电话">
                    <Input />
                </Form.Item>
                <Form.Item name='wechat_id' label="资源主微信">
                    <Input />
                </Form.Item>
                <Form.Item name='alipay_id' label="资源主支付宝账号">
                    <Input />
                </Form.Item>
                <Form.Item name='alipay_name' label="资源主支付宝实名">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
        <Modal
            title="申请结算" open={modal.apply}
            footer={null}
            width={800}
            onCancel={() => setModal({ ...modal, apply: false })}
        >
            <Space direction='vertical' className='full-width'>
                <OrdersDetail orders={orders} />
                <Divider />
                <Title level={4}>选择支付信息</Title>
                <Table dataSource={resourceInfo}
                    rowKey={record => record.id}
                    columns={resourceColumns({ setPayInfo, apply_form })} />
                <Form
                    form={apply_form}
                    name="apply_form"
                    layout="vertical"
                >
                    <Form.Item name='alipay_id' label="资源主支付宝账号">
                        <Input />
                    </Form.Item>
                    <Form.Item name='alipay_name' label="资源主支付宝实名">
                        <Input />
                    </Form.Item>
                    <Form.Item name='amount' label="订单数量">
                        <span>{_.size(orders)}</span>
                    </Form.Item>
                    <Form.Item name='money' label="订单总金额">
                        <span>{_.sumBy(orders, 'cost_money')}</span>
                    </Form.Item>
                    <Form.Item name='save' valuePropName="checked">
                        <Checkbox>
                            是否保存联系方式
                        </Checkbox>
                    </Form.Item>
                </Form>
                <Space>
                    <Button type="primary" onClick={() => {
                        let data = apply_form.getFieldsValue()
                        applyResourceOrders(data)
                    }}>确认提交并绑定</Button>
                </Space>
            </Space>
        </Modal>
        <Modal
            title="管理联系方式" open={modal.resource_info}
            footer={null}
            width={800}
            onCancel={() => setModal({ ...modal, resource_info: false })}
            destroyOnClose={true}
        >
            <ResourceInfoTable id={modal.media_id} />
        </Modal>
        <Modal title="项目详情"
            destroyOnClose={true}
            width={800}
            footer={null}
            onCancel={() => setModal({ ...modal, project_detail: false })}
            open={modal.project_detail}
        >
            <ProjectDetail pid={modal.project_id} />
        </Modal>
        <Modal
            title="添加所属项目"
            open={modal.add_project}
            footer={[
                <Button key="back" onClick={() => setModal({
                    ...modal,
                    add_project: false,
                    project_id: '',
                    order_id: ''
                })}>
                    完成
                </Button>
            ]}
            width={800}
            onCancel={() => setModal({ ...modal, modify_project: false })}
            destroyOnClose={true}
        >
            <ProjectTable order_id={modal.order_id} hasSelect={false} />
        </Modal>
        <Modal
            title="修改所属项目"
            open={modal.modify_project}
            footer={[
                <Button key="back" onClick={() => setModal({
                    ...modal,
                    modify_project: false,
                    project_id: '',
                    order_id: ''
                })}>
                    完成
                </Button>
            ]}
            width={800}
            onCancel={() => setModal({ ...modal, modify_project: false })}
            destroyOnClose={true}
        >
            <ProjectTable order_id={modal.order_id} hasSelect={false} />
        </Modal>
        <Modal
            title="查看信息" open={modal.error_info}
            footer={null}
            onCancel={() => setModal({ ...modal, error_info: false })}
        >
            <ErrorInfo data={modal.error_data} type={modal.error_type} />
        </Modal>
    </>;
}


export default OrderTable

const ErrorInfo = ({ data, type }) => {
    if (type === 'recall') {
        return <>
            <p>撤稿原因：{recallReason(data.recall_type)}</p>
            <Paragraph style={{ marginBottom: 0 }}>详情：{data.recall_reason}</Paragraph>
        </>
    } else if (type === 'error') {
        return <>
            <p>回执信息：{data.back_info}</p>
            <p>拒稿原因：{errorReason(data.err_type)}</p>
            <Paragraph style={{ marginBottom: 0 }}>详情：{data.err_reason}</Paragraph>
        </>
    } else {
        return <></>
    }

}

const PriceSum = ({ data, permission }) => {
    let totalCost = _.sumBy(data, 'cost_money');        // 成本
    let totalSale = _.sumBy(data, 'agent_paid_money');  // 销售
    let totalPaid = _.sumBy(data, 'paid_money');        // 付款

    return <Space>
        {permission['module:media:order:money:cost'] && <span>成本: <Text type="danger">{totalCost}</Text></span>}
        {permission['module:media:order:money:agent'] && <span>相对成本: <Text>{totalSale}</Text></span>}
        {permission['module:media:order:money:sales:agent'] && <span>成本: <Text>{totalSale}</Text></span>}
        {permission['module:media:order:money:paid'] && <span>付款: <Text>{totalPaid}</Text></span>}
        <Divider type='vertical' />
        {(permission['module:media:order:money:cost'] && permission['module:media:order:money:agent'])
            && <span>总收益: <Text type="danger">{totalSale - totalCost}</Text></span>}
        {(permission['module:media:order:money:sales:agent'] && permission['module:media:order:money:paid'])
            && <span>总收益: <Text type="danger">{totalPaid - totalSale}</Text></span>}
        <span>共 <Text strong>{_.size(data)}</Text> 条</span>
    </Space>
}

const Summary = ({ pageData, orders, permission, columns }) => {
    let totalNum = _.size(columns.filter(e => e.checked === 1))
    return <tr>
        <td />
        <th>汇总</th>
        <td colSpan={totalNum - 1}>
            <Space>
                <PriceSum data={pageData} permission={permission} />
                {
                    _.size(orders) > 0 && <>
                        <Text strong>已选择</Text>
                        <PriceSum data={orders} permission={permission} />
                    </>
                }
            </Space>
        </td>
    </tr>
}

const TabBar = ({ num, func }) => {
    const badgeOffset = [16, 2]

    return <Tabs defaultActiveKey="1" onChange={func}
        items={[
            { label: '全部稿件', key: '1' },
            {
                label: <Badge size='small' count={num.waitVerify} offset={badgeOffset} >
                    <div className='f-12' >待审核</div>
                </Badge>,
                key: '2',
            },
            {
                label: <Badge size='small' count={num.waitPublish} offset={badgeOffset} >
                    <div className='f-12' >待发布</div>
                </Badge>,
                key: '3',
            },
            { label: '已完成', key: '4' },
            { label: '已退稿', key: '5' },
            {
                label: <Badge size='small' count={num.recall} offset={badgeOffset} >
                    <div className='f-12' >撤稿申请</div>
                </Badge>,
                key: '6',
            },
            {
                label: <Badge size='small' count={num.error} offset={badgeOffset} >
                    <div className='f-12' >异常申请</div>
                </Badge>,
                key: '7',
            },
        ]}
    />
}

const resourceColumns = ({ setPayInfo, apply_form }) => {
    return [
        {
            title: '姓名',
            dataIndex: 'nickname',
            key: 'nickname',
        },
        {
            title: 'QQ',
            dataIndex: 'qq',
            key: 'qq',
        },
        {
            title: '电话',
            dataIndex: 'tel',
            key: 'tel',
        },
        {
            title: '微信',
            dataIndex: 'wechat_id',
            key: 'wechat_id',
        },
        {
            title: '支付宝账号',
            dataIndex: 'alipay_id',
            key: 'alipay_id',
        },
        {
            title: '支付宝实名',
            dataIndex: 'alipay_name',
            key: 'alipay_name',
        },
        {
            title: '选择',
            dataIndex: 'id',
            key: 'id',
            render: (val, data) => {
                return <Button type='link' onClick={() => {
                    apply_form.setFieldsValue({ ...data, save: false })
                    message.success("已选择")
                }}>选择</Button>
            }
        },
    ];
}

const disposeOrders = (orders) => {
    let result = {};
    orders.forEach(e => {
        let key = `${e.media_id}-${e.media_name}`
        let val = _.isNil(result[key]) ? 0 : result[key]['num'];
        let money = _.isNil(result[key]) ? 0 : result[key]['money'];
        let temp = {
            num: val + 1,
            money: money + e.cost_money
        }
        result[key] = temp
    })
    return result;
}

const OrdersDetail = ({ orders }) => {
    let temp = disposeOrders(orders)
    return <Row gutter={[16, 24]}>
        {
            _.map(temp, (k, v) => {
                let arr = _.split(v, '-')
                let name = arr[1]
                return <Col span={6} key={arr[0]}>
                    <Space direction='vertical'>
                        <Text strong size={12}>{name}</Text>
                        <Space>
                            <Text size={12}>共计 {k?.num}篇</Text>
                            <Text size={12}>{k?.money}元</Text>
                        </Space>
                    </Space>
                </Col>
            })
        }
    </Row >
}