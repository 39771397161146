import React from 'react';
import { Layout, Typography, Col, Row, Card } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Homeheader from './Homeheader';
import { ScheduleTwoTone } from '@ant-design/icons';
import HomeFooter from './HomeFooter';

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;

const paragraphStyle = { fontSize: '16px', fontFamily: '"PingFang SC", "Microsoft Yahei", Arial, sans-serif', lineHeight: '28px' }
const Agent = () => {
    return <HelmetProvider>
        <Helmet>
            <title>媒介代理</title>
            <meta name="description" content="查看媒介代理解决方案" />
        </Helmet>
        <Homeheader />
        <Content style={{ background: '#fff', margin: '0 auto' }}>
            {/* 揭示行业痛点 */}
            <div style={{
                backgroundImage: 'url(https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/banner.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '500px',
                width: '100%',
            }} >
                <div style={{ width: '1200px', margin: '0 auto', textAlign: 'center', paddingTop: 80 }}>
                    <Title style={{ fontSize: '64px', color: '#FFFFFF', margin: '0 auto 40px auto', fontWeight: 500 }}>助力企业，共创未来</Title>
                    <Paragraph style={{ ...paragraphStyle, color: '#FFFFFF', fontSize: 20 }}>
                        云自推旨在助力企业用数字化、社交化、智能化科技，更好地连接和理解客户，并通过个性化的沟通和{<br />}
                        互动方式全面升级客户体验，落地各行业数字化转型升级的战略目标和价值，共创企业服务生态未来。
                    </Paragraph>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}>
                        <a href={{}} style={{
                            display: 'block',
                            width: '240px',
                            height: '60px',
                            backgroundColor: '#0067ed',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            fontSize: '22px',
                            lineHeight: '60px'
                        }}>
                            成为合作伙伴
                        </a>
                        <a href={{}} style={{
                            display: 'block',
                            width: '240px',
                            height: '60px',
                            backgroundColor: 'transparent',
                            border: '1px solid #FFFFFF',
                            marginLeft: 30,
                            color: '#FFFFFF',
                            textAlign: 'center',
                            fontSize: '22px',
                            lineHeight: '60px'
                        }}>
                            寻找合作伙伴
                        </a>
                    </div>
                </div>
            </div>
            {/* 优势阐述 */}
            <div style={{ margin: '100px auto', textAlign: 'center' }}>
                <div style={{ display: 'inline-block', flexDirection: 'row', }}>
                    <div style={{ width: '540px', float: 'left', textAlign: 'left', margin: '0 40px' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>成为云自推合作伙伴，你可以获得</Title>
                        <Row gutter={[30, 24]}>
                            <Col span={12}>
                                <div style={{ display: 'flex' }}>
                                    <ScheduleTwoTone style={{ fontSize: 22 }} />
                                    <Title level={3} style={{ marginLeft: 16 }}>培训支持</Title>
                                </div>
                                <Paragraph style={{ ...paragraphStyle, marginLeft: 38 }}>行业优秀讲师团队，提供专业高效的培训，助力合作伙伴迅速提升企点产品应用与技术服务能力。</Paragraph>
                            </Col>
                            <Col span={12}>
                                <div style={{ display: 'flex' }}>
                                    <ScheduleTwoTone style={{ fontSize: 22 }} />
                                    <Title level={3} style={{ marginLeft: 16 }}>商务支持</Title>
                                </div>
                                <Paragraph style={{ ...paragraphStyle, marginLeft: 38 }}>专属的渠道拓展经理，提供强大的业务与商务支持，帮助合作伙伴在相应领域取得成绩。</Paragraph>
                            </Col>
                            <Col span={12}>
                                <div style={{ display: 'flex' }}>
                                    <ScheduleTwoTone style={{ fontSize: 22 }} />
                                    <Title level={3} style={{ marginLeft: 16 }}>宣传支持</Title>
                                </div>
                                <Paragraph style={{ ...paragraphStyle, marginLeft: 38 }}>丰富的市场营销及推广活动，协助企业引流，为合作伙伴提供更多链接客户的良机。</Paragraph>
                            </Col>
                            <Col span={12}>
                                <div style={{ display: 'flex' }}>
                                    <ScheduleTwoTone style={{ fontSize: 22 }} />
                                    <Title level={3} style={{ marginLeft: 16 }}>项目支持</Title>
                                </div>
                                <Paragraph style={{ ...paragraphStyle, marginLeft: 38 }}>强有力的项目支持，全方位定制客户需求，助力合作伙伴在激烈的商业竞争中取得胜利。</Paragraph>
                            </Col>
                        </Row>

                    </div>
                    <img style={{ float: 'right' }} src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/pic1.png' alt='' />
                </div>
            </div>
            <div style={{ margin: '100px auto', textAlign: 'center', width: 1200 }}>
                <Title style={{ fontSize: 40, marginBottom: 30 }}>如何成为云自推合作伙伴</Title>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '40px auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/icon1.png' height='110px' width='110px' alt='' />
                        <Text className='table-font' style={{ fontSize: 20 }}>1. 提交申请</Text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/icon2.png' height='110px' width='110px' alt='' />
                        <Text className='table-font' style={{ fontSize: 20 }}>2. 资质审核</Text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/icon3.png' height='110px' width='110px' alt='' />
                        <Text className='table-font' style={{ fontSize: 20 }}>3. 意向洽谈</Text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/icon4.png' height='110px' width='110px' alt='' />
                        <Text className='table-font' style={{ fontSize: 20 }}>4. 签订协议</Text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/icon5.png' height='110px' width='110px' alt='' />
                        <Text className='table-font' style={{ fontSize: 20 }}>5. 培训认证</Text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/icon6.png' height='110px' width='110px' alt='' />
                        <Text className='table-font' style={{ fontSize: 20 }}>6. 发布解决方案</Text>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <a href={{}} style={{
                        display: 'block',
                        width: '300px',
                        height: '60px',
                        backgroundColor: '#0067ed',
                        color: '#FFFFFF',
                        textAlign: 'center',
                        fontSize: '22px',
                        lineHeight: '60px'
                    }}>
                        立刻申请成为合作伙伴
                    </a>
                </div>
            </div>
            <div style={{ margin: '100px auto', width: 1200 }}>
                <Title style={{ fontSize: 40, marginBottom: 30 }}>云自推认证合作伙伴</Title>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Card bordered={true}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 8 }}>
                                <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/logo/logo-hcw.png' alt='' />
                                <Text style={{ fontSize: 18 }}>北京慧聪国际资讯有限公司</Text>
                            </div>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={true}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 8 }}>
                                <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/logo/logo-hcw.png' alt='' />
                                <Text style={{ fontSize: 18 }}>北京慧聪国际资讯有限公司</Text>
                            </div>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={true}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 8 }}>
                                <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/logo/logo-hcw.png' alt='' />
                                <Text style={{ fontSize: 18 }}>北京慧聪国际资讯有限公司</Text>
                            </div>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={true}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 8 }}>
                                <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/logo/logo-hcw.png' alt='' />
                                <Text style={{ fontSize: 18 }}>北京慧聪国际资讯有限公司</Text>
                            </div>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={true}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 8 }}>
                                <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/logo/logo-hcw.png' alt='' />
                                <Text style={{ fontSize: 18 }}>北京慧聪国际资讯有限公司</Text>
                            </div>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={true}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 8 }}>
                                <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/logo/logo-hcw.png' alt='' />
                                <Text style={{ fontSize: 18 }}>北京慧聪国际资讯有限公司</Text>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            <div style={{ margin: '100px auto', textAlign: 'center', width: 1200 }}>
                <Title style={{ fontSize: 40, marginBottom: 30 }}>云自推认证合作伙伴可以为你提供</Title>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '40px auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 320 }}>
                        <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/icon-company.png' height='110px' width='110px' alt='' />
                        <Text strong className='table-font' style={{ fontSize: 20, margin: 20 }}>企业专属的解决方案</Text>
                        <Paragraph className='table-font' style={{ fontSize: 16, textAlign: 'justify' }}>合作伙伴可以通过专业的企点应用知识，结合对行业的了解，基于企业实际需求提供更符合场景的个性化定制方案。</Paragraph>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 320 }}>
                        <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/icon-service.png' height='110px' width='110px' alt='' />
                        <Text strong className='table-font' style={{ fontSize: 20, margin: 20 }}>你身边的服务与支持</Text>
                        <Paragraph className='table-font' style={{ fontSize: 16, textAlign: 'justify' }}>位于全国各地的合作伙伴可以结合当地市场情况，为企业提供紧密配合的上门服务与支持。</Paragraph>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 320 }}>
                        <img src='https://bqq.gtimg.com/qdweb_2018/static/img/v2/partner/isvandsi/icon-trust.png' height='110px' width='110px' alt='' />
                        <Text strong className='table-font' style={{ fontSize: 20, margin: 20 }}>值得信赖的服务质量</Text>
                        <Paragraph className='table-font' style={{ fontSize: 16, textAlign: 'justify' }}>每一位合作伙伴都经由企点培训认证，并持续进行监控与评估，以确保合作伙伴为企业提供的服务质量有所保证。</Paragraph>
                    </div>
                </div>
            </div>
            {/* 邀请加入 */}
            <div style={{
                backgroundImage: 'url(https://bqq.gtimg.com/qdweb_2018/static/img/solution/apply_bg.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '300px',
                width: '100%',
                textAlign: 'center'
            }}>
                <div style={{ display: 'flex', paddingTop: 80, flexDirection: 'column' }}>
                    <Title>
                        没找到合适的合作伙伴？
                    </Title>
                    <a href={{}} style={{
                        display: 'block',
                        width: '240px',
                        height: '72px',
                        backgroundColor: '#0067ed',
                        color: '#FFFFFF',
                        fontSize: '22px',
                        lineHeight: '72px',
                        margin: '0 auto',
                        boxShadow: '0 24px 64px #b4b2b2'
                    }}>
                        帮我挑选
                    </a>
                </div>

            </div>
        </Content>
        <HomeFooter />
    </HelmetProvider>
}

export default Agent;