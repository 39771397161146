import React, { useState } from 'react'
import { Col, Row, Avatar, Button, Progress, Typography, Statistic, message, Tag, Space, Modal, InputNumber, Spin, Card } from 'antd';
import { UserOutlined, CalendarOutlined } from '@ant-design/icons';
import { NavLink, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs';
import { useRecoilValue, useRecoilState } from 'recoil';
import qs from 'qs';
import _ from 'lodash'

import { getExp } from '../levels/LevelsStats';
import { refreshUserState, userState, platformState } from '../../atoms/Atoms'
import { resultResponse } from '../../common/ResultResponse';
import RoleModule from '../../../utils/permission/RoleModule';
import PageHeader from '../../layout/PageHeader';

const axios = require('axios');
const { Title, Text, Paragraph } = Typography;

const userInfoStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    margin: 'auto'
}
const user_avatar = 'http://static.yunzitui.com/upload/user/common/yh1.jpg';
const DashHeader = () => {

    const sessionUser = useRecoilValue(userState)
    const [refresh, setRefresh] = useRecoilState(refreshUserState)
    const platform_id = useRecoilValue(platformState)

    let navigate = useNavigate();
    const [modal, setModal] = useState({ bindQq: false, exchange: false, loan: false })
    const [exchange, setExchange] = useState(1)
    const [loanRecharge, setLoanRecharge] = useState(0)

    const exchangeCoins = async (amount) => {
        if (amount > 0) {
            await axios({
                method: 'post',
                url: "/user/exchange",
                data: qs.stringify({ amount })
            }).then(res => {
                resultResponse(res, "已转化")
                setExchange(0)
                setModal({ ...modal, exchange: false })
                setRefresh(true)
            })
        } else {
            message.warn("请输入正确的金额")
        }
    }

    const rechargeLoan = async () => {
        await axios({
            method: 'post',
            url: '/user/loan/recharge',
            data: qs.stringify({ money: loanRecharge })
        }).then(res => {
            resultResponse(res, "已转化")
            setLoanRecharge(0)
            setModal({ ...modal, loan: false })
            setRefresh(true)
        })
    }

    const dailySignIn = async () => {
        await axios({
            method: 'get',
            url: "/mission/user/sign",
        }).then(res => {
            if (res.data.code === '200') {
                message.success("已成功签到")
            } else {
                message.warning(res.data.message)
            }
        })
    }

    const greeting = (name) => {
        let hour = dayjs().hour();
        if (hour < 6) {
            return `凌晨了, ${name}, 还不睡觉吗?`
        } else if (hour < 10) {
            return `早上好, ${name}, 来杯咖啡吧!`
        } else if (hour < 14) {
            return `中午好, ${name}, 吃一顿好的吧!`
        } else if (hour < 18) {
            return `下午好, ${name}, 来份下午茶吧!`
        } else if (hour < 22) {
            return `晚上好, ${name}, 出去运动一下!`
        } else if (hour < 24) {
            return `午夜了, ${name}, 早点休息吧!`
        }
    }

    const getExpBar = Math.round(((sessionUser.basic.exp - getExp(sessionUser.basic.level))
        / (getExp(sessionUser.basic.level + 1) - getExp(sessionUser.basic.level))) * 100)


    return <>
        <Card title="工作台" bordered='false'   >
            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '8px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '24px' }}>
                    <Avatar size={72} src={sessionUser.basic.avatar_img || user_avatar} icon={<UserOutlined />} />
                    <PlatformGroup platform_id={platform_id} sessionUser={sessionUser} />
                </div>
                <div style={{ flex: '1', }}>
                    <Paragraph style={{ fontSize: '20px', lineHeight: '28px', color: 'rgba(0,0,0,.85)' }}>{greeting(sessionUser.account)}</Paragraph>
                    <Space size='middle'>
                        <span >用户等级</span>
                        <Button style={{ padding: '0' }} type='link' onClick={() => navigate('/dashboard/missions')}>领取任务</Button>
                        <Button style={{ padding: '0' }} type='link' onClick={() => dailySignIn()}><CalendarOutlined />签到</Button>
                    </Space>
                    {/* 用户等级图标 以及经验条 */}
                    <div style={{ ...userInfoStyle, marginBottom: 16 }}>
                        {
                            sessionUser.basic.level > 0 &&
                            <>
                                <div>
                                    <img src={`/static/images/asset/level/LV${sessionUser?.basic?.level}.png`} alt=''></img>
                                </div>
                                <div style={{ width: "100%", marginLeft: 16, maxWidth: '450px' }}>
                                    <Progress percent={getExpBar} status="active" style={{ height: 16 }} />
                                </div>
                            </>
                        }
                    </div>
                </div>
                {
                    refresh
                        ? <div style={{ flex: '0 1 auto', marginLeft: '80px', minWidth: '400px' }}>
                            <Spin />
                        </div>
                        : <RoleModule action='module:dashboard:balance'>
                            <div style={{ flex: '0 1 auto', marginLeft: '80px', minWidth: '400px' }}>
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <Statistic title="账户余额" value={sessionUser?.balance?.available_money} precision={2} />
                                        <NavLink to="/dashboard/user/recharge">
                                            <Button style={{ marginTop: 16 }} type="primary">
                                                余额充值
                                            </Button>
                                        </NavLink>
                                    </Col>
                                    <Col span={6}>
                                        <Statistic title="平台积分" value={sessionUser?.basic?.points} />
                                    </Col>
                                    <Col span={6}>
                                        <Statistic title="平台S币" value={sessionUser?.basic?.coins} />
                                        <Button style={{ marginTop: 16 }} type="primary"
                                            onClick={() => setModal({ ...modal, exchange: true })}>
                                            S币转换
                                        </Button>
                                    </Col>
                                    {
                                        sessionUser?.balance?.loan_state === 1 &&
                                        <Col span={6}>
                                            <Statistic title="用户白条" value={sessionUser?.balance?.loan_money} />
                                            <Button style={{ marginTop: 16 }} type="primary"
                                                onClick={() => setModal({ ...modal, loan: true })}>
                                                余额转换
                                            </Button>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        </RoleModule>
                }
            </div>
        </Card>
        <Modal
            title="S币转换"
            open={modal.exchange}
            onOk={() => exchangeCoins(exchange)}
            onCancel={() => setModal({ ...modal, exchange: false })}
        >
            <Title>S币转换余额</Title>
            <Paragraph>当前平台提供S币转换余额，转换率为 <Text strong>1S币：1元</Text></Paragraph>
            <Paragraph>您的S币余额为:<Text strong>{sessionUser.basic.coins}</Text></Paragraph>
            <InputNumber min={1} max={sessionUser.basic.coins} defaultValue={1} onChange={e => setExchange(e)} />
        </Modal>

        <Modal
            title="白条额度转换"
            open={modal.loan}
            onOk={() => rechargeLoan()}
            onCancel={() => setModal({ ...modal, loan: false })}
        >
            <Title>余额转换白条</Title>
            <Paragraph>您的白条余额为:
                <Text strong>{`${sessionUser.balance.loan_money} / ${sessionUser.balance.loan_limit_money} `}</Text>
            </Paragraph>
            <InputNumber min={0} max={sessionUser?.balance?.available_money} defaultValue={0} onChange={e => setLoanRecharge(e)} />
        </Modal>
    </>
}

export default DashHeader


const PlatformGroup = ({ platform_id, sessionUser }) => {
    let userGroup = sessionUser?.role?.user_groups
    if (sessionUser.role.role === 'CUSTOMER') {
        let groups = _.filter(userGroup, e => e.platform_id === platform_id)
        if (_.size(groups) > 0) {
            let isVip = Boolean(groups[0].expired_time)
            return isVip
                ? <>
                    <Tag color="gold" style={{ margin: '8px auto' }}>{groups[0]?.sale_name}</Tag>
                    <Text>{dayjs(groups[0].expired_time).format('YYYY-MM-DD')}到期</Text>
                </>
                : <Tag color="gold" style={{ margin: '8px auto' }}>{groups[0]?.sale_name}</Tag>
        } else {
            return <Tag color="gold" style={{ margin: '8px auto' }}>{sessionUser?.sale_group?.sale_group_name}</Tag>
        }
    } else {
        return <Tag color="gold" style={{ margin: '8px auto' }}>{sessionUser?.sale_group?.sale_group_name}</Tag>
    }
}