import React from 'react';
import { Layout, Tabs } from 'antd';
import MediaIndexTable from './MediaIndexTable'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const { Content } = Layout;

const Medias = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>文字链资源列表</title>
                <meta name="description" content="查看文字链资源列表" />
            </Helmet>
            <div style={{ margin: 24 }}>
                <Content
                    style={{
                        background: '#fff',
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    <MediaIndexTable />
                </Content>
            </div>
        </HelmetProvider>

    )
}

export default Medias;