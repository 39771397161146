import React from 'react';
import { Tag } from 'antd';

export const pageViewMap = new Map([
    [1, "少于1000"], [2, "1000~5000"], [3, "5000~1万"], [4, "1万~5万"],
    [5, "5万~10万"], [6, "10万以上"],
])
export const availableTimeMap = new Map([
    [1, "一天"], [2, "三天"], [3, "一周"], [4, "一个月"],
    [5, "一个月以上"]
])
export const fansNum = new Map([
    [1, "少于1000"], [2, "1000~5000"], [3, "5000~1万"], [4, "1万~5万"],
    [5, "5万~10万"], [6, "10万~50万"], [7, "50万以上"],
])

const tagStyle = { padding: '0 2px' }

export const mediaIcons = new Map([
    // ["荐", <img style={{ padding: '0 2px' }} src='http://origin-static.oss-cn-beijing.aliyuncs.com/img/index/icons/jian.png' alt='' />],
    ["荐", <Tag key="荐" color="#ea3721" style={tagStyle}>荐</Tag>],
    ["秒", <Tag key="秒" color="#fbd801" style={tagStyle}>秒</Tag>],
    ["独", <Tag key="独" color="#faad14" style={tagStyle}>独</Tag>],
    ["惠", <Tag key="惠" color="#f5222d" style={tagStyle}>惠</Tag>],
    ["优", <Tag key="优" color="#1890ff" style={tagStyle}>优</Tag>],
    ["新", <Tag key="新" color="#13c2c2" style={tagStyle}>新</Tag>],
    ["包", <Tag key="包" color="#2080f6" style={tagStyle}>包</Tag>],
    ["奖", <Tag key="奖" color="#eb2f96" style={tagStyle}>奖</Tag>],
    ["活", <Tag key="活" color="#24e151" style={tagStyle}>活</Tag>],
    ["热", <Tag key="热" color="#f5222d" style={tagStyle}>热</Tag>],
])


export const minute2hour = minute => {
    let hour = 0;
    if (minute < 1) {
        return "小于1分钟"
    } else if (minute >= 60) {
        hour = Math.round(minute / 60);
        minute = minute % 60;
        return hour + '小时' + minute + '分钟'
    } else {
        return minute + '分钟'
    }
}