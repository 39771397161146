import React from 'react'
import { Layout, } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import PageHeader from '../../layout/PageHeader';
import NoticeTable from './NoticeTable';

const { Content } = Layout
const Notices = () => {

    let pageTitle = "公告管理"
    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content style={{ margin: 24 }}>
                <NoticeTable />
            </Content>

        </HelmetProvider>
    )
}

export default Notices
