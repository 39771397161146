import StatisticsCenter from '../statistics/StatisticsCenter'

import React from "react"
const StatisticsRoutesConfig = [
    {
        path: '/dashboard/statistics',
        component: <StatisticsCenter/>
    },
]

export default StatisticsRoutesConfig