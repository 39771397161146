import React from 'react'
import { Tag, Typography } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const { Text } = Typography
export const WithdrawTableConfig = () => {
    const role = useRecoilValue(userRoleState)

    const consumeType = [
        {
            title: '消费类型',
            dataIndex: 'resource_type',
            checked: 1,
            render: (t) => {
                switch (t) {
                    case 10001:
                        return "新闻媒体"
                    case 10021:
                        return "首页文字链"
                    default:
                        break;
                }
            }
        }, {
            title: '媒体',
            dataIndex: 'media_name',
            checked: 1,
        }, {
            title: '标题',
            dataIndex: 'title',
            checked: 1,
        },
    ]

    const consumeMoney = [
        {
            title: '金额',
            dataIndex: 'cost_money',
            checked: 1,
        },
    ]

    const consumeTime = [
        {
            title: '创建时间',
            dataIndex: 'start_time',
            checked: 1,
        }, {
            title: '预计可提现时间',
            dataIndex: 'target_time',
            checked: 1,
        },
    ]


    const withdrawState = [
        {
            title: '申请状态',
            dataIndex: 'id',
            checked: 1,
            render: (t, data) => {
                if (data.paid_status === 'paid') {
                    return <Tag style={{ margin: 0 }} color="green">已结算</Tag>
                } else if (data.paid_status === 'pending') {
                    return <Tag style={{ margin: 0 }} color="orange">申请中</Tag>
                } else {
                    if (data.state === 'UNPAID') {
                        switch (data.order_state) {
                            case 1:
                                return <Tag style={{ margin: 0 }} color="red">不可申请</Tag>
                            case 2:
                                return <Tag style={{ margin: 0 }} color="orange">待确认</Tag>
                            case 3:
                                if (data.withdraw_list_id !== null) {
                                    return <Tag style={{ margin: 0 }} color="volcano">异常订单</Tag>
                                } else {
                                    return <Tag style={{ margin: 0 }} color="geekblue">可申请</Tag>
                                }
                            default:
                                break;
                        }
                    } else if (data.state === 'PAID') {
                        return <Tag style={{ margin: 0 }} color="green">已结算</Tag>
                    } else if (data.state === 'PENDING') {
                        return <Tag style={{ margin: 0 }} color="blue">已申请</Tag>
                    } else if (data.state === 'INVALID') {
                        return <Tag style={{ margin: 0 }} color="red">无效订单</Tag>
                    }
                }
            }
        }, {
            title: '状态',
            dataIndex: 'state',
            checked: 1,
            render: (t, data) => {
                if (data.paid_status === 'paid') {
                    return <Tag style={{ margin: 0 }} color="green">已结算</Tag>
                } else {
                    switch (t) {
                        case 'UNPAID':
                            return <Tag style={{ margin: 0 }} color="gold">未结算</Tag>
                        case 'PAID':
                            return <Tag style={{ margin: 0 }} color="green">已结算</Tag>
                        case 'PENDING':
                            return <Tag style={{ margin: 0 }} color="blue">处理中</Tag>
                        case 'INVALID':
                            return <Tag style={{ margin: 0 }} color="red">无效订单</Tag>
                        default:
                            break;
                    }
                }

            }
        }, {
            title: '结算时间',
            dataIndex: 'end_time',
            checked: 1,
            render: (t, data) => {
                if (data.end_time) {
                    return <Text>{data.end_time}</Text>
                } else {
                    if (data.paid_status === 'paid') {
                        return <Tag color='geekblue'>预付订单</Tag>
                    }
                }
            }
        },
    ]

    let result = []

    switch (role) {
        case "CUSTOMER":
        case "OPERATOR_ADMIN":
            result = _.concat(
                consumeTime,
                consumeType,
                consumeMoney,
                withdrawState
            )
            break;
        case "FINANCE":
            result = _.concat(
                consumeTime,
                consumeType,
                consumeMoney,
                withdrawState
            )
            break;
        case "OPERATOR":
            result = _.concat(
                consumeTime,
                consumeType,
                consumeMoney,
            )
            break;
        case "SALE":
        case 'FRANCHISE':
            result = _.concat(
                consumeTime,
                consumeType,
                consumeMoney,
            )
            break;
        default:
            break;
    }
    return result
}
