import React, { useState, useEffect } from 'react';
import {
    Table, Button, Row, Col, Modal, Layout,
    Input, Form, Space, message
} from 'antd';
import { SearchOutlined, } from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs';
import qs from 'qs';
import { useRecoilValue } from 'recoil';

import { userState } from '../../atoms/Atoms'
import { initFormData, tablePagination, TableTitle } from '../../common/tableCommon';
import { EarnOrderTableConfig } from '../../../role/order/EarnOrderTableConfig';
import EarnOrderSearchConfig from '../../../role/order/EarnOrderSearchConfig';
import EarnOrderDetailTable from './EarnOrderDetailTable';
import SearchForm from '../../common/SearchForm';
import { resultResponse } from '../../common/ResultResponse';

const axios = require('axios');
const { Content } = Layout;
const layout = { layout: 'vertical' };
const seartchLayout = { lg: 24, xl: 12 };
const initialModal = { targetId: '', create: false, select: false, orders: false, withdraw: false }

const EarnOrderTable = () => {

    const sessionUser = useRecoilValue(userState)
    let attache = sessionUser.role.attache

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState(initialModal)
    const [orders, setOrders] = useState([])

    const [form] = Form.useForm();
    const searchDatas = EarnOrderSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const reloadTable = () => form.submit();
    const resetModal = () => setModal(initialModal)

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/earnOrder/getList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setLoading(false)
                if (res.data.code === 1) {
                    setData(res.data.data)
                    let total = res.data.total
                    if (total < ((pagination.current - 1) * pagination.pageSize)) {
                        setPagination({ ...pagination, current: 1, total })
                    } else {
                        setPagination({ ...pagination, total })
                    }
                } else {
                    message.warn(res.data.data)
                }


            })
        }
    }

    const selectOrders = (id) => {
        setModal({ ...modal, select: true, targetId: id })
    }

    const finishSaleOrder = async (id, state, sale_price) => {
        await axios({
            method: 'post',
            url: "/earnOrder/finish",
            data: qs.stringify({
                id: id,
                state: state,
                sale_price: sale_price,
            })
        }).then(res => {
            resultResponse(res, '成功')
            reloadTable()
            resetModal()
        })
    }

    const withdraw = async (name, id) => {
        let params = new FormData()
        params.append("ids[]", orders.map(e => e.id))
        params.append("name", name)
        params.append("id", id)
        if (_.size(orders) === 0) {
            return message.warn("请选择提现订单")
        }
        await axios({
            method: 'post',
            url: "/earnOrder/withdraw",
            data: params
        }).then(res => {
            resultResponse(res, "已申请")
            reloadTable()
            setOrders([])
            resetModal()
        })
    }

    // 订单操作函数
    const handleOrders = {
        selectOrders, reloadTable, finishSaleOrder
    }

    const rowSelection = {
        selectedRowKeys: orders.map(e => e.id),
        onSelect: (record, selected) => {
            let tempOrder = { id: record.id }
            let newMedias = selected ? _.concat(orders, tempOrder) : _.differenceBy(orders, [tempOrder], 'id');
            setOrders(_.uniqBy(newMedias, 'id'))
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            let tempMedias = changeRows.map(e => ({ id: e.id }))
            let newMedias = selected ? _.concat(orders, tempMedias) : _.differenceBy(orders, tempMedias, 'id');
            setOrders(_.uniqBy(newMedias, 'id'))
        },
        getCheckboxProps: record => ({
            disabled: !((attache === 1 && record.state === 'WAITING') || (attache === 2 && record.leader_state === 'WAITING')), 
            name: record.name,
        })
    };

    useEffect(() => {
        fetchData()
    }, [])


    let defaultColumns = EarnOrderTableConfig({ modal, setModal, handleOrders })
    const localStorageColumnsName = "____EarnOrderTableConfig____";
    let localStorageColumns = JSON.parse(localStorage.getItem(localStorageColumnsName))
    _.forEach(defaultColumns, (e) => {
        _.forEach(localStorageColumns, (val) => {
            if (e.title === val.title) {
                e.checked = val.checked
            }
        })
    })

    const [columns, setColumns] = useState(defaultColumns)

    return <>
        <Content className='table-search'>
            <Form
                {...layout}
                name="basic"
                form={form}
                initialValues={initialFormData}
                onFinish={val => fetchData(_.pickBy(val, _.identity))}
            >
                <SearchForm searchDatas={searchDatas} />
                <Row>
                    <Col {...seartchLayout} >
                        <Space>
                            {/* <Button type="primary" onClick={() => createEarnOrder()}><PlusOutlined />生成本周</Button> */}
                            <Button onClick={() => setModal({ ...modal, withdraw: true })}>申请结算</Button>
                        </Space>
                    </Col>
                    <Col  {...seartchLayout} style={{ textAlign: 'right' }}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                <SearchOutlined /> 搜索
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Content>
        <Content style={{ background: '#fff', padding: 24 }}>
            <Table
                size="small"
                title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} />}
                columns={columns.filter(e => e.checked === 1)}
                rowSelection={rowSelection}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>

        {/* 查看订单 */}
        <Modal title="查看媒体" open={modal.orders} footer={null}
            width={1500}
            onCancel={() => resetModal()}
        >
            <EarnOrderDetailTable id={modal.targetId} />
        </Modal>
        {/* 申请结算 */}
        <Modal title="申请结算" open={modal.withdraw} footer={null} onCancel={() => resetModal()}>
            <Form
                initialValues={{ name: sessionUser.basic.alipay_name, id: sessionUser.basic.alipay_id }}
                onFinish={val => {
                    withdraw(val['name'], val['id'])
                }}>
                <Form.Item label="支付宝实名" name='name'>
                    <Input />
                </Form.Item>
                <Form.Item label="支付宝账号" name='id'>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button htmlType='submit' type='primary'>提交</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>;
}


export default EarnOrderTable
