import React from 'react';
import { Layout, Typography, Form, Row, Col, Input, Button, Radio, InputNumber } from 'antd';
import _ from 'lodash';
import qs from 'qs';

const { Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;
const axios = require('axios');

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const colLayout = {
    span: 24
}
const WbFansForm = ({ size }) => {



    const fetchData = async (val) => {
        console.log(val)
        await axios({
            method: 'post',
            url: '/wbApi/wbFans/addFans',
            data: qs.stringify(val)
        }).then((res) => {
        })
    }


    function customizer(objValue, srcValue) {
        return _.isUndefined(srcValue) ? "" : srcValue;
    }

    return (
        <Content style={{ background: "#fff", padding: '24px' }}>
            <div style={{ maxWidth: '960px', margin: '0 auto' }}>
                <Form
                    {...formItemLayout}
                    initialValues={{
                        ids: "1"
                    }}
                    onFinish={val => fetchData(_.assignInWith([], val, customizer))}>
                    <Row gutter={[24, 24]}>
                        <Col {...colLayout}>
                            <Form.Item label="关注类型" name="ids">
                                <Radio.Group>
                                    <Radio value="1">达人粉丝</Radio>
                                    <Radio value="2">超级粉丝</Radio>
                                    <Radio value="3">顶级粉丝</Radio>
                                    <Radio value="4">极速粉丝</Radio>
                                    <Radio value="30">刷量粉丝</Radio>
                                    <Radio value="35">高级粉丝</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="微博地址" name="wb_url">
                                <Input />
                            </Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <Text type="secondary">输入微博首页地址，格式：http://weibo.com/u/182679**** 或个性域名或微博UID</Text>
                            </div>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="本次数量" name="order_num_total">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="订单速度" name="order_speed">
                                <Radio.Group>
                                    <Radio value="1">慢速</Radio>
                                    <Radio value="2">正常</Radio>
                                    <Radio value="3">加急</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="备注" name="note">
                                <TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Content>
    )
}

export default WbFansForm;