import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Card, Statistic, Table, Spin, Space, message, Tabs } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash'
import { SearchOutlined } from '@ant-design/icons';
import useSWR, { useSWRConfig } from 'swr';

import FinanceIncomeSearchConfig from '../../../role/finance/FinanceIncomeSearchConfig'
import FinanceInconsumeTableConfig from '../../../role/finance/FinanceInconsumeTableConfig'
import { initFormData, tablePagination } from '../../common/tableCommon';
import SearchForm from '../../common/SearchForm'
import RoleModule from '../../../utils/permission/RoleModule';

const axios = require('axios');
const seartchLayout = { lg: 24, xl: 12 };
const layout = { layout: 'vertical' };
const getFetcher = async url => await axios.get(url).then(res => {
    if (res.data.code === 1) {
        return res.data.data
    } else {
        message.warn(res.data.data)
    }
})

function FinanceTable({ tab }) {
    const { mutate } = useSWRConfig()

    const [datas, setDatas] = useState({ table: [], summary: {} })
    const swrOptions = { revalidateOnFocus: false }
    const { data: prepay, isValidating: prepayLoading } = useSWR('/finance/get/prepay', getFetcher, swrOptions)
    const { data: loan, isValidating: loanLoading } = useSWR('/finance/get/loan', getFetcher, swrOptions)
    const { data: coins, isValidating: coinLoading } = useSWR('/finance/get/coins', getFetcher, swrOptions)
    const { data: balance, isValidating: balanceLoading } = useSWR('/finance/get/balance', getFetcher, swrOptions)

    const [workOrderStats, setWorkOrderStats] = useState([])
    const [action, setAction] = useState('media');

    const [sort, setSort] = useState({ sort_field: '', sort_order: '' })
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [form] = Form.useForm();

    const reloadTable = () => form.submit()

    const getUrl = () => {
        if (action === 'sales') {
            return `/finance/sales`
        } else {
            return `/finance/income`
        }
    }

    const searchDatas = FinanceIncomeSearchConfig({ tab: action })
    const initialFormData = initFormData(searchDatas)
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("type", _.upperCase(tab))
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        _.toPairs(sort).forEach(e => e[1] && params.append(`${e[0]}`, e[1]))

        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: `${getUrl()}/stats`,
                data: params
            }, {
                cancelToken: source.token
            }).then(res => {
                let data = res.data.data
                let total = res.data.total
                setDatas(data)
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
            setLoading(false)
        }
    }

    const getWorkOrders = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (!loanLoading) {
            await axios({
                method: 'post',
                url: "/finance/get/workOrders",
                data: params
            }).then(res => {
                if (res.data.code === 1) {
                    setWorkOrderStats(res.data.data)
                } else {
                    message.warn(res.data.data)
                }
            })
        }
    }

    const updateFinanceInfos = async (path) => {
        const key = `/finance/get/${path}`
        const url = `/finance/update/${path}`
        const response = await axios.get(url).then(res => res.data)
        if (response.code === 1) {
            mutate(key, response.data, { revalidate: false })
            message.success('已成功更新！')
        } else {
            message.warn(response.data)
        }
    }

    useEffect(() => {
        fetchData()
        getWorkOrders()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [tab, action])


    const columns = FinanceInconsumeTableConfig({ tab: action })

    const statsDatas = { summary: datas.summary }

    return (
        <Space size={16} direction='vertical' className='full-width'>
            <Card>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => {
                        let params = _.pickBy(val, _.identity)
                        fetchData(params)
                        getWorkOrders(params)
                    }}
                >
                    <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                    <Row>
                        <Col {...seartchLayout} />
                        <Col  {...seartchLayout} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>搜索</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Spin spinning={loading}>
                <StatisticCard statsDatas={statsDatas} />
            </Spin>

            <Row gutter={[16, 16]}>
                <RoleModule action='module:finance:detail' >
                    <Col span={6}>
                        <Spin spinning={prepayLoading}>
                            <Card title='预付款' bordered={false}
                                extra={[<Button type='link' onClick={() => updateFinanceInfos('prepay')}>更新</Button>,]}
                            >
                                <Statistic title={'预付款统计（元）'} value={prepay} precision={2} />
                            </Card >
                        </Spin>
                    </Col>
                    <Col span={6}>
                        <Spin spinning={loanLoading}>
                            <Card title='平台白条统计' bordered={false}
                                extra={<Button type='link' onClick={() => updateFinanceInfos('loan')}>更新</Button>}>
                                <Statistic title='白条统计（元）' value={Math.abs(loan)} precision={2} />
                            </Card >
                        </Spin>
                    </Col>
                    <Col span={6}>
                        <Spin spinning={balanceLoading}>
                            <Card title='平台用户余额' bordered={false}
                                extra={<Button type='link' onClick={() => updateFinanceInfos('balance')}>更新</Button>}>
                                <Statistic title='平台用户余额（元）' value={Math.abs(balance)} precision={2} />
                            </Card >
                        </Spin>
                    </Col>
                    <Col span={6}>
                        <Spin spinning={coinLoading}>
                            <Card title='平台用户余额' bordered={false}
                                extra={<Button type='link' onClick={() => updateFinanceInfos('coins')}>更新</Button>}>
                                <Statistic title='平台用户S币（元）' value={Math.abs(coins)} precision={2} />
                            </Card >
                        </Spin>
                    </Col>
                </RoleModule>
                <Col span={24}>
                    <Spin spinning={loading}>
                        <Card title='工单统计' bordered={false}>
                            <div className='grid-3 gap-8'>
                                <Statistic title='已完成工单（元）' value={getMoney(workOrderStats, 'SUCCESS')} precision={2} />
                                <Statistic title='待审核工单（元）' value={getMoney(workOrderStats, 'UNCHECKED')} precision={2} />
                                <Statistic title='未付款工单（元）' value={getMoney(workOrderStats, 'UNPAID')} precision={2} />
                            </div>
                        </Card >
                    </Spin>
                </Col>
            </Row >
            <RoleModule action='module:finance:sales:list' >
                <Tabs defaultActiveKey={tab} onChange={e => setAction(e)}
                    items={[
                        { key: 'media', label: `媒体列表`, },
                        { key: 'sales', label: `销售列表`, },
                    ]}
                />
                {
                    tab === 'media' &&
                    <div style={{ padding: 24, backgroundColor: '#FFF' }}>
                        <Table
                            columns={columns}
                            rowKey={record => record.id}
                            dataSource={datas?.table}
                            pagination={pagination}
                            loading={loading}
                            onChange={(pagination, filter, sorter) => {
                                setSort({ ...sort, sort_field: sorter.field, sort_order: sortOrder(sorter) })
                                setPagination(pagination)
                                form.submit()
                            }}
                        />
                    </div>
                }
            </RoleModule>
        </Space >
    )
}

export default FinanceTable;


const StatisticCard = ({ statsDatas }) => {
    const { summary } = statsDatas
    return <Row gutter={[16, 16]}>
        <Col xxl={6} xl={12}>
            <Card title='平台营收统计' bordered={false}>
                <div className='grid-3 gap-8'>
                    <Statistic title='成本（元）' value={summary?.abs_cost} precision={2} />
                    <Statistic title='相对成本（元）' value={summary?.rel_cost} precision={2} />
                    <Statistic title='客户付款（元）' value={summary?.total_paid} precision={2} />
                </div>
            </Card >
        </Col>
        <Col xxl={6} xl={12}>
            <Card title='平台订单统计' bordered={false}>
                <div className='grid-3 gap-8'>
                    <Statistic title='订单总数量' value={summary?.total_num} />
                    <Statistic title='未结算订单数量' value={summary?.unpaid_num} />
                </div>
            </Card >
        </Col>
        <Col xxl={6} xl={12}>
            <Card title='平台盈利统计' bordered={false}>
                <div className='grid-3 gap-8'>
                    <Statistic title='起源（元）' value={summary?.rel_cost - summary?.abs_cost} precision={2} />
                    <Statistic title='云自推（元）' value={summary?.total_paid - summary?.rel_cost} precision={2} />
                    <Statistic title='总营收（元）' value={summary?.total_paid - summary?.abs_cost} precision={2} />
                </div>
            </Card >
        </Col>
    </Row>
}

const getMoney = (workOrder, state) => {
    let array = _.split(workOrder[0]?.money, ',')
    let str = _.find(array, e => {
        let string = _.split(e, '-')
        return string[0] === state
    })
    let nums = _.split(str, '-')
    return nums[1]
}

const sortOrder = (sorter) => {
    if (sorter.order === 'ascend') {
        return 'ASC'
    } else if (sorter.order === 'descend') {
        return "DESC"
    } else { return '' }
}