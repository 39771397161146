import SaleGroups from '../levels/SaleGroups'
import React from "react"

const LevelRoutesConfig = [
    {
        path: '/dashboard/user/levels',
        component: <SaleGroups />
    },
]

export default LevelRoutesConfig