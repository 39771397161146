import Notices from '../notices/Notices'
import NoticeCenter from '../notices/NoticeCenter'
import React from "react"

const NoticeRoutesConfig = [
    {
        path: '/dashboard/notices',
        component: <Notices />
    }, {
        path: '/dashboard/notifications',
        component: <NoticeCenter />
    },
]

export default NoticeRoutesConfig