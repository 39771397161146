import React from 'react';
import { Layout, Typography, Row, Col, Card } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Homeheader from '../Homeheader';

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;

const paragraphStyle = { fontSize: '16px', fontFamily: '"PingFang SC", "Microsoft Yahei", Arial, sans-serif', lineHeight: '28px', textAlign: 'justify' }
const BaikeInfo = () => {
    return <HelmetProvider>
        <Helmet>
            <title>百科渠道</title>
            <meta name="description" content="百科渠道" />
        </Helmet>
        <Homeheader />
        <Content style={{ background: '#fff', margin: '0 auto' }}>
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '440px', float: 'left', textAlign: 'left', padding: '50px 0', }}>
                        <Title level={3} style={{ fontSize: 32 }}>云自推营销平台</Title>
                        <Title style={{ fontSize: '56px', color: '#0067ed', margin: '24px 0 0 0' }}>特设百科运营团</Title>
                        <Title level={2} style={{ fontSize: 40, marginTop: 0 }}>助力企业树立权威形象</Title>
                        <Paragraph style={paragraphStyle}>
                            在互联网时代，宣传主体的品牌建设需要整个系统共同发力，全渠道共同创建及维护，才能取得成功。
                            百科在互联网的世界里就是一张名片，全方位的介绍主体本身。并且能够及时的做出最新调整，把前沿、真实、全面的内容展现给检索受众。
                            通过搜索引擎获取的信息量大且碎片化，百度首页非广告展现位置只有十个，能在有限的位置里占有一个高质量的内容展示位，对于网络品牌的宣传意义十分重大。
                        </Paragraph>
                        <a href={{}} style={{
                            display: 'block',
                            width: '260px',
                            height: '60px',
                            backgroundColor: '#0067ed',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            fontSize: '22px',
                            lineHeight: '60px'
                        }}>
                            百科咨询
                        </a>
                    </div>
                    <img style={{ float: 'right', width: 760 }} src='http://static.yunzitui.com/static/asset/biz/baike/baike_banner_1.png' alt='' />
                </div>
            </div>


            {/* 百科的意图 */}
            <div style={{ margin: '0 auto', backgroundColor: '#0067ed', padding: '30px 0' }}>
                <div style={{ width: 1200, margin: '0 auto' }}>
                    <Title style={{ textAlign: 'center', color: '#FFFFFF' }}>百科编辑规则复杂，上手门槛高</Title>
                    <Paragraph style={{ ...paragraphStyle, color: '#FFFFFF', textAlign: 'center' }}>
                        百科虽然是人人可以编辑补充，但是由于审核严格，要求规范，对于普通企业客户来说编辑门槛极高。{<br />}
                        面对“参考资料不可靠”、“包含广告或宣传性质的内容”等审核反馈，无法正确地提供优质的资料，导致企业信息无法及时更新，造成商业上的损失。
                    </Paragraph>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <img src='http://static.yunzitui.com/static/asset/biz/baike/baike_words.png' alt='' />
                </div>
                {/* <div style={{ width: 1200, margin: '0 auto' }}>
                    <img src='http://static.yunzitui.com/static/asset/biz/baike/baike_schema.png' alt='' />
                </div> */}
                {/* <div style={{ width: 1200, margin: '0 auto' }}>
                    <Row gutter={[24, 24]}>
                        <Col span={8}>
                            <Card title={<div>
                                <Title level={3} style={{ color: '#FFFFFF', textAlign: 'center' }}>高认可度</Title>
                            </div>}>
                                <Paragraph style={{ ...paragraphStyle, color: '#FFFFFF', textAlign: 'center' }}>
                                    社会公众认可，建立企业品牌形象
                                </Paragraph>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={<div>
                                <Title level={3} style={{ color: '#FFFFFF', textAlign: 'center' }}>曝光量大</Title>
                            </div>}>
                                <Paragraph style={{ ...paragraphStyle, color: '#FFFFFF', textAlign: 'center' }}>
                                    百科高权重，占据关键词条搜索首页，轻松获取曝光
                                </Paragraph>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={<div>
                                <Title level={3} style={{ color: '#FFFFFF', textAlign: 'center' }}>流量支撑</Title>
                            </div>}>
                                <Paragraph style={{ ...paragraphStyle, color: '#FFFFFF', textAlign: 'center' }}>
                                    超过十年的快速发展，现在每天有超过4亿人次浏览百科内容
                                </Paragraph>
                            </Card>
                        </Col>
                    </Row>
                </div> */}
            </div>

            {/* 优势阐述 */}
            <div style={{ margin: '0 auto', textAlign: 'center', width: 1200 }}>
                <div style={{ marginTop: 40, textAlign: 'center' }}>
                    <Title style={{ fontSize: 40, marginBottom: 30 }}>十年专业团队 打造完美百科</Title>
                    <Paragraph style={{ ...paragraphStyle, textAlign: 'center' }}>
                        面对复杂的百科创建编辑规则，作为企业主往往无从下手，及时能够成功创建基础词条，也无法继续新增内容。{<br />}
                        云自推百科团拥有十年以上的百科处理经验，累计编辑超过5000个词条，熟知百科编辑的流程，精准指导客户百科资料准备，极大地调高百科修改通过率。
                    </Paragraph>
                    <img src='http://static.yunzitui.com/static/asset/biz/baike/baike_table.png' alt='' />
                </div>
            </div>

            {/* 案例展示 */}
            <div style={{ margin: '0 auto', textAlign: 'center', width: 1440 }}>
                <Title style={{ fontSize: 40, margin: '30px auto' }}><Text strong style={{ color: '#0067ed' }}>「 </Text>案例展示<Text strong style={{ color: '#0067ed' }}> 」</Text></Title>
                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'left', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>企业品牌百科</Title>
                        <Paragraph style={paragraphStyle}>
                            <Text strong>老金磨方</Text>是杭州老金食品有限公司旗下品牌，始于1912年，因“壬子年大水”救济灾民而成立，经祖孙三代，代代相传而正式成立于2007年，
                            是浙江杭州一家专业生产加工天然粉等产品的公司。老金磨方始终以“0添加，天然养生”为企业的核心价值，以诚信与创新赢取市场，自2011进驻天猫开设旗舰店至今，一直稳居行业第一。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/biz/baike/baike_case_1.png' alt='' />
                </div>
                <div style={{ display: 'inline-block', flexDirection: 'row' }}>
                    <div style={{ width: '400px', float: 'right', marginTop: '160px', textAlign: 'left' }}>
                        <Title style={{ fontSize: 40, marginBottom: 30 }}>人物百科</Title>
                        <Paragraph style={paragraphStyle}>
                            <Text strong>郭敏华</Text>，法文名：M.G.Lenoir，出生于1984年10月31日，法国时装设计师，法国现代插画家，法国高级时装品牌LYSANDRE PARIS创始人兼艺术总监。
                            代表作品：高级时装触碰系列，高级时装心跳系列，高级时装醉梦系列，梦幻手包，艺术手包火柴盒。
                        </Paragraph>
                    </div>
                    <img style={{ float: 'right' }} src='http://static.yunzitui.com/static/asset/biz/baike/baike_case_2.png' alt='' />
                </div>
            </div>


            {/* 邀请加入 */}
            <div style={{
                backgroundImage: 'url(https://bqq.gtimg.com/qdweb_2018/static/img/solution/apply_bg.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '400px',
                width: '100%',
                position: 'absolute',
                textAlign: 'center'
            }}>
                <div style={{ width: 1200, margin: '0 auto' }}>
                    <Title style={{ fontSize: 40, marginTop: 60 }}>严正声明</Title>
                    <Title style={{ margin: '40px auto', fontWeight: 'normal', fontSize: 22 }}>
                        <Text strong>本平台不是百度官方及代理商！</Text>百度百科人人可编辑，词条创建和修改均免费。{<br />}
                        由于部分百科编辑规则复杂，通过周期长，所以本平台提供代编辑的服务，可节约您的时间和精力。{<br />}
                        百科词条人人可编辑，词条创建和修改均免费，绝不存在官方及代理商付费代编，请勿上当受骗。
                    </Title>
                    <a href={{}} style={{
                        display: 'block',
                        width: '320px',
                        height: '72px',
                        backgroundColor: '#0067ed',
                        color: '#FFFFFF',
                        fontSize: '22px',
                        lineHeight: '72px',
                        margin: '0 auto',
                        boxShadow: '0 24px 64px #b4b2b2'
                    }}>
                        申请体验
                </a>
                </div>
            </div>
        </Content>
    </HelmetProvider>
}

export default BaikeInfo;