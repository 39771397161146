import React, { useState, useEffect } from 'react';
import {
    SearchOutlined, SyncOutlined, DeleteOutlined
} from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Divider, Layout, Typography, Space } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import SearchForm from '../../common/SearchForm'
import MediaSearchConfig from '../../../role/media/MediaSearchConfig'
import { initFormData, tablePagination } from '../../common/tableCommon';
import { SearchFormHori, SearchFormInput } from '../../common/SearchFormTile';
import { resultResponse } from '../../common/ResultResponse';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios');

const MediaPrepayDetailTable = ({ prepay_id }) => {
    const [form] = Form.useForm();                  // 媒体搜索form
    const role = useRecoilValue(userRoleState)

    const searchDatas = MediaSearchConfig({})
    const initialFormData = _.reduce(searchDatas, (result, data) => {
        return _.assign(result, { [data.name]: data.value ?? '' })
    }, {})

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    // 编辑器样式
    const localStorageEditorStyleName = "____SearchStyle____";
    const searchStyle = localStorage.getItem(localStorageEditorStyleName) ?? 'vertical'

    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("prepay_id", prepay_id)

        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/media/prepay/getMediaList',
                data: params,
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    // 删除活动 或者 收藏夹中的媒体
    const removeMediaFromPrepay = async (id, media_id) => {
        let params = new FormData()
        params.append("id", id)
        params.append("prepay_id", prepay_id)
        params.append("media_id", media_id)
        await axios({
            method: 'post',
            url: `/media/prepay/unbind`,
            data: params,
        }).then((res) => {
            resultResponse(res, '已成功删除')
        })
    }

    // 初始化列表
    useEffect(() => {
        fetchData()
        return source.cancel('Operation canceled by the user.');
    }, [prepay_id])

    let columns = [
        {
            title: '媒体名称',
            dataIndex: 'name',
            checked: 1,
            render: (text, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text >{text}</Text>
                        <span><a href={data.sample_url} target='_blank' rel='noopener noreferrer'>查看案例</a></span>
                    </div>
                )
            }
        }, {
            title: '价格',
            dataIndex: 'sale_price',
            checked: 1,
            render: (t, data) => {
                let result = <Text>{t}</Text>
                switch (role) {
                    case 'OPERATOR':
                        result = <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text>成本：{data.cost}</Text>
                            <Text>相对成本: {data.sale_price}</Text>
                        </div>
                        break;
                    default:
                        break;
                }
                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {result}
                </div>
            }
        }, {
            title: '活动操作',
            dataIndex: 'id',
            checked: 1,
            render: (text, data) => (
                <Space>
                    <Button type="link" icon={<DeleteOutlined />} size='small' onClick={() => {
                        removeMediaFromPrepay(data.bind_id, data.id)
                        reloadTable()
                    }} />
                </Space>

            )
        }
    ]

    return <>
        <Content className='table-search' style={{ padding: 0 }}>
            <PageHeader
                style={{ padding: 0 }}
                title={<Text level={4}>媒体搜索</Text>}
            >
                <Form
                    layout={searchStyle === 'horizontal' ? 'vertical' : 'horizontal'}
                    labelAlign="left"
                    name="media-form"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    {searchStyle === 'horizontal' && <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />}
                    {searchStyle === 'vertical' && <SearchFormHori searchDatas={searchDatas} reloadTable={reloadTable} />}
                    <Divider style={{ margin: '8px 0' }} />
                    <Row>
                        <Col span={12}>
                            {searchStyle === 'vertical' && <SearchFormInput searchDatas={searchDatas} reloadTable={reloadTable} />}
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button onClick={() => form.resetFields()}>
                                    <SyncOutlined /> 重置搜索
                                </Button>
                                <Button type="primary" htmlType="submit" >
                                    <SearchOutlined /> 搜索
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </PageHeader>
        </Content>
        <Content className='table-main'>
            <Table size='small'
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>
    </>;
}

export default MediaPrepayDetailTable