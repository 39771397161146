import UserLayoutConfig from './config/UserLayoutConfig'
import OrderLayoutConfig from './config/OrderLayoutConfig'
import CustomerLayoutConfig from './config/CustomerLayoutConfig'
import MediaLayoutConfig from './config/MediaLayoutConfig'
import ManageLayoutConfig from './config/ManageLayoutConfig'
import FinanceLayoutConfig from './config/FinanceLayoutConfig'

const LayoutConfig = {
    UserLayoutConfig,
    OrderLayoutConfig,
    CustomerLayoutConfig,
    MediaLayoutConfig,
    ManageLayoutConfig,
    FinanceLayoutConfig,
}

export default LayoutConfig