import React, { useEffect, createRef } from 'react'
import { Form, Row, Button, Space } from 'antd';
import CreateForm from '../../common/CreateForm';
import _ from 'lodash'
import dayjs from 'dayjs'

import EventDatasConfig from '../../../role/EventDatasConfig';


const layout = {
    layout: 'vertical'
};
const AddEventForm = (props) => {
    const { event, type, drawerAction } = props;
    const { visible, setVisible, createEvent, updateEvent } = drawerAction

    const formRef = createRef();

    const datas = EventDatasConfig({ type })
    let targetCols = _.filter(_.flatten(datas), e => e.type === 'datepicker')

    function customizer(objValue, srcValue) {
        return _.isUndefined(srcValue) ? "" : srcValue;
    }

    /**
     * 预处理数据, 转化String 为 moment 元素
     * @param datas 目标字段
     * @param val 输入值
     */
    const preHandleData = (datas, val) => {
        _.forEach(datas, data => {
            _.forEach(val, (v, k) => {
                if (!_.isNil(v) && !_.isEmpty(v)) {
                    if (k === data.name) {
                        val = { ...val, [k]: dayjs(v, 'YYYY-MM-DD') }
                    }
                }
            })
        })
        return val
    }

    useEffect(() => {
        let tempData = preHandleData(targetCols, event)
        formRef.current.setFieldsValue(tempData)
    }, [event, formRef])

    return (
        <>
            <Form
                {...layout}
                ref={formRef}
                name="basic"
                onFinish={val => {
                    _.forEach(targetCols, data => {
                        _.forEach(val, (v, k) => {
                            if (!_.isNil(v) && !_.isEmpty(v)) {
                                if (k === data.name) {
                                    val = {
                                        ...val,
                                        [k]: dayjs(v.$d).format('YYYY-MM-DD')
                                    }
                                }
                            }
                        })
                    })
                    _.assignInWith(event, val, customizer)
                    if (visible.action === 'ADD') {
                        createEvent()
                    } else if (visible.action === 'EDIT') {
                        updateEvent()
                    }
                    setVisible({ ...visible, addEvent: false })
                }}
                onFinishFailed={errorInfo => {
                    console.log('Failed:', errorInfo);
                }}
                hideRequiredMark
            >
                {datas.map(data => <Row gutter={16} key={data[0].name}>
                    <CreateForm datas={data} span={12} />
                </Row>)}
                <div className='side-drawer'>
                    <Space>
                        <Button onClick={() => { setVisible({ ...visible, addEvent: false }) }} >
                            取消
                        </Button>
                        <Button htmlType='submit' type='primary'>提交</Button>
                    </Space>
                </div>
            </Form>
        </>
    )
}

export default AddEventForm;