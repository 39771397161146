import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { areas } from '../../Apps/common/media/MediaInfo'

const WeiboHotSearchConfig = ({ type }) => {
    const role = useRecoilValue(userRoleState)

    const defaultSearch = [
        {
            name: 'name',
            label: '资源名称',
            placeholder: '请输入资源名称',
            type: 'input'
        }, {
            name: 'verified_type',
            label: '认证类型',
            placeholder: '请选择认证类型',
            type: 'select',
            children: [
                '', '企业认证', '未认证', '蓝V', '金V', '黄V',
            ]
        }, {
            name: 'area',
            label: '媒体地区',
            placeholder: '请输入地区',
            type: 'select',
            children: _.concat([''], areas)
        }, {
            name: 'price',
            label: '价格范围',
            type: 'range',
            datas: [
                {
                    name: 'min_price',
                    label: '最低价格',
                    placeholder: '最低价格',
                    type: 'input'
                },
                {
                    name: 'max_price',
                    label: '最高价格',
                    placeholder: '最高价格',
                    type: 'input'
                },
            ]
        }, {
            name: 'tag',
            label: '资源标签',
            placeholder: '请输入资源标签',
            type: 'input',
        }, {
            name: 'follower',
            label: '粉丝数量',
            placeholder: '请输入粉丝数量',
            type: 'select',
            children: [
                ['全部', ''],
                ['1000以下', '1'],
                ['1000-5000', '2'],
                ['5000-2万', '3'],
                ['2万-5万', '4'],
                ['5万-10万', '5'],
                ['10万以上', '6'],
            ]
        },
    ]

    const statusSearch = [
        {
            name: 'status',
            label: '媒体状态',
            type: 'radio',
            children: [
                ['全部', ''],
                ['正常', '1'],
                ['暂停', '2'],
            ],
            value: '1'
        }, {
            name: 'type',
            label: '发布类型',
            type: 'radio',
            children: [
                ['全部', ''],
                ['直发', '20001'],
                ['转发', '20002'],
                ['微任务直发', '20011'],
                ['微任务转发', '20012'],
            ],
            value: ''
        },
    ]

    const operatorSearch = [
        {
            name: 'creator_name',
            label: '创建者名称',
            placeholder: '请输入创建者名称',
            type: 'input'
        }, {
            name: 'editor_name',
            label: '所属编辑',
            placeholder: '请输入编辑名称',
            type: 'input'
        },
    ]

    let result = []
    switch (role) {
        case "CUSTOMER":
            result = _.concat(
                defaultSearch,
                statusSearch
            )
            break;
        case "OPERATOR":
            result = _.concat(
                defaultSearch,
                operatorSearch,
                statusSearch,
            )
            break;
        case "SALE":
        case 'FRANCHISE':
            result = _.concat(
                defaultSearch,
                statusSearch,
            )
            break;
        default:
            result = _.concat(
                defaultSearch,
                statusSearch
            )
            break;
    }

    return result
}
export default WeiboHotSearchConfig;
