import React, { useState, useEffect } from 'react';
import { Table, Modal, Row, Col, Typography, Layout, message } from 'antd';

import { tablePagination } from '../../common/tableCommon';
import CouponBoughtTableConfig from '../../../role/coupon/CouponBoughtTableConfig';

const axios = require('axios');
const { Title } = Typography;
const { Content } = Layout

const CouponsBoughtTable = ({
    coupons = [],
    setCoupons = () => console.log('empty'),
    rowOnChange = (selectedRowKeys, selectedRows) => {
        setCoupons(selectedRows.map(e => {
            return {
                id: e.id,
                coupon_name: e.coupon_name,
            }
        }))
    },
}) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [count, setCount] = useState(0)
    const [modal, setModal] = useState({ create: false, })
    const [packDatas, setPackDatas] = useState({})

    const reloadTable = () => setCount(count + 1)
    const resetModal = () => setModal({ create: false })

    const fetchData = async (params = new FormData()) => {
        setLoading(true)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        await axios({
            method: 'post',
            url: '/couponPack/bought/list',
            data: params
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
            let total = res.data.recordsFiltered
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    const rowSelection = {
        selectedRowKeys: coupons.map(e => e.id),
        onChange: rowOnChange,
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', 
            name: record.name,
        }),
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination)
    };

    useEffect(() => {
        fetchData()
    }, [pagination.current, pagination.pageSize, count,])

    const selectCouponPack = async (id) => {
        await axios({
            method: 'post',
            url: `/couponPack/select/${id}`,
        }).then((res) => {
            setPackDatas(res.data.data)
            setModal({ ...modal, create: true })
        })
    }

    const checkCouponPack = async (id) => {
        await axios({
            method: 'post',
            url: `/couponPack/bought/check/${id}`,
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功展开")
                reloadTable()
            } else {
                message.warning(res.data.msg)
            }
        })
    }

    const handleMedia = {
        selectCouponPack,
        checkCouponPack,
        reloadTable,
    }
    const columns = CouponBoughtTableConfig({ handleMedia })

    return (
        <>
            <Content className='table-search'>
                <Title style={{ textAlign: 'center' }}>
                    已购买优惠券包需要展开,才能在下单时使用!
                </Title>
                <Table
                    size='middle'
                    title={() => (
                        <>
                            <Row>
                                <Col span={12}>
                                    <Title level={4}>优惠券列表</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'end' }}>
                                </Col>
                            </Row>
                        </>
                    )}
                    columns={columns}
                    rowSelection={rowSelection}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
                <Modal title="查看优惠券" open={modal.create} footer={null} width={600}
                    onCancel={() => resetModal()}
                >
                    <Table
                        size='middle'
                        rowKey={record => record.id}
                        columns={[
                            {
                                title: '名称',
                                dataIndex: 'coupon_name',
                            }, {
                                title: '描述',
                                dataIndex: 'coupon_description',
                            }, {
                                title: '抵用金额',
                                dataIndex: 'money',
                            }, {
                                title: '创建时间',
                                dataIndex: 'create_time',
                            }, {
                                title: '失效时间',
                                dataIndex: 'end_time',
                            }, {
                                title: '数量',
                                dataIndex: 'amount',
                            },
                        ]}
                        dataSource={packDatas}
                    ></Table>
                </Modal>
            </Content>
        </>
    )
}

export default CouponsBoughtTable