export const paths = [
    {
        key: 'page:dashboard',
        title: '工作台',
        path: '/dashboard'
    }, {
        key: 'page:dashboard:error',
        title: '异常页面',
        path: '/dashboard/error'
    },
    // 个人中心
    {
        key: 'page:user:info',
        title: '个人信息',
        path: '/dashboard/user'
    }, {
        key: 'page:invoice:info',
        title: '发票信息',
        path: '/dashboard/invoice/info'
    }, {
        key: 'page:invoice:apply',
        title: '申请发票',
        path: '/dashboard/invoice/apply'
    }, {
        key: 'page:user:consume',
        title: '消费详情',
        path: '/dashboard/user/consume'
    }, {
        key: 'page:coupon:center',
        title: '优惠券中心',
        path: '/dashboard/coupons/center'
    }, {
        key: 'page:event:center',
        title: '活动中心',
        path: '/dashboard/events/center'
    }, {
        key: 'page:mission:center',
        title: '任务中心',
        path: '/dashboard/missions'
    }, {
        key: 'page:user:recharge',
        title: '用户充值',
        path: '/dashboard/user/recharge'
    },
    // 我的资源
    {
        key: 'page:resource:media',
        title: '资源中心',
        path: '/dashboard/resource/media'
    }, {
        key: 'page:resource:order',
        title: '订单审核',
        path: '/dashboard/resource/orders'
    }, {
        key: 'page:withdraw:apply',
        title: '申请提现',
        path: '/dashboard/withdraw/v2/list'
    }, {
        key: 'page:resource:weibo:hot',
        title: '微博中心',
        path: '/dashboard/resource/weibo/hot',
    }, {
        key: 'page:resource:wechat',
        title: '微信中心',
        path: '/dashboard/resource/wechat',
    },
    // 财务中心
    {
        key: 'page:invoice:manage',
        title: '发票申请管理',
        path: '/dashboard/invoices/list'
    }, {
        key: 'page:withdraw:manage',
        title: '提现管理',
        path: '/dashboard/withdraw/all/list'
    }, {
        key: 'page:order:workorder:manage',
        title: '工单管理',
        path: '/dashboard/finance/sale-order/center'
    }, {
        key: 'page:invoice:enterprise:manage',
        title: '公司发票管理',
        path: '/dashboard/enterprise/invoice'
    }, {
        key: 'page:statistics',
        title: '平台统计',
        path: '/dashboard/statistics'
    }, {
        key: 'page:income:stats',
        title: '财务中心',
        path: '/dashboard/finance/income/stats'
    }, {
        key: 'page:salary:stats',
        title: '薪资中心',
        path: '/dashboard/finance/salary/stats'
    },
    // 管理中心
    {
        key: 'page:notice:center',
        title: '公告中心',
        path: '/dashboard/notices'
    }, {
        key: 'page:user:list',
        title: '用户列表',
        path: '/dashboard/user/users'
    }, {
        key: 'page:user:loan:manage',
        title: '白条管理',
        path: '/dashboard/user/loan/manage'
    }, {
        key: 'page:user:salary',
        title: '薪资管理',
        path: '/dashboard/user/salary'
    }, {
        key: 'page:user:level:list',
        title: '等级分组',
        path: '/dashboard/user/levels'
    }, {
        key: 'page:tag:list',
        title: '标签管理',
        path: '/dashboard/tags'
    }, {
        key: 'page:coupon:list',
        title: '优惠券管理',
        path: '/dashboard/coupons'
    }, {
        key: 'page:coupon:pack:list',
        title: '优惠券包',
        path: '/dashboard/coupons/pack'
    }, {
        key: 'page:role:manage',
        title: '权限管理',
        path: '/dashboard/role'
    },
    // 资源中心
    {
        key: 'page:media:list',
        title: '媒体中心',
        path: '/dashboard/media-center'
    }, {
        key: 'page:media:prepay',
        title: '预付管理',
        path: '/dashboard/medias/prepay'
    }, {
        key: 'page:media:collection',
        title: '资源收藏夹',
        path: '/dashboard/media/collections'
    }, {
        key: 'page:media:price',
        title: '媒体价格',
        path: '/dashboard/medias/price'
    }, {
        key: 'page:notification:list',
        title: '通知中心',
        path: '/dashboard/notifications'
    }, {
        key: 'page:event:manage',
        title: '活动管理中心',
        path: '/dashboard/events'
    }, {
        key: 'page:media:package',
        title: '媒体优惠套餐',
        path: '/dashboard/packages'
    }, {
        key: 'page:media:blacklist',
        title: '媒体黑名单',
        path: '/dashboard/medias/blacklist'
    }, {
        key: 'page:weibo:list',
        title: '我的微博',
        path: '/dashboard/weibo',
    }, {
        key: 'page:wechat:list',
        title: '我的微信',
        path: '/dashboard/wechat',
    }, {
        key: 'page:xiaohongshu:list',
        title: '我的小红书',
        path: '/dashboard/xiaohongshu',
    }, {
        key: 'page:douyin:list',
        title: '我的抖音',
        path: '/dashboard/douyin',
    },
    // 订单中心
    {
        key: 'page:media:order:add',
        title: '创建文章',
        path: '/dashboard/order/add'
    }, {
        key: 'page:baike:order:add',
        title: '创建百科',
        path: '/dashboard/baike/add'
    }, {
        key: 'page:index:order:add',
        title: '创建文字链',
        path: '/dashboard/index/add'
    }, {
        key: 'page:qa:order:add',
        title: '创建问答',
        path: '/dashboard/qa/add'
    }, {
        key: 'page:order:stats',
        title: '账单统计',
        path: '/dashboard/order/stats'
    }, {
        key: 'page:order:workorder',
        title: '工单管理',
        path: '/dashboard/order/workorder'
    }, {
        key: 'page:order:workorder:invoice:apply',
        title: '发票申请',
        path: '/dashboard/order/workorder/invoice/apply'
    }, {
        key: 'page:order:sendorder',
        title: '派单管理',
        path: '/dashboard/order/sendorder',
    }, {
        key: 'page:order:earnorder',
        title: '订单收益管理',
        path: '/dashboard/order/earnorder'
    }, {
        key: 'page:media:order:list',
        title: '订单中心',
        path: '/dashboard/order'
    }, {
        key: 'page:baike:order:list',
        title: '订单中心',
        path: '/dashboard/baike/orders',
    }, {
        key: 'page:weibo:order:add',
        title: '创建微博',
        path: '/dashboard/weibo/order/add',
    }, {
        key: 'page:weibo:order:list',
        title: '微博订单中心',
        path: '/dashboard/weibo/order',
    }, {
        key: 'page:wechat:order:add',
        title: '创建微信',
        path: '/dashboard/wechat/order/add',
    }, {
        key: 'page:wechat:order:list',
        title: '微信订单中心',
        path: '/dashboard/wechat/order',
    }, {
        key: 'page:xiaohongshu:order:add',
        title: '创建小红书',
        path: '/dashboard/xiaohongshu/order/add',
    }, {
        key: 'page:xiaohongshu:order:list',
        title: '小红书订单中心',
        path: '/dashboard/xiaohongshu/order',
    }, {
        key: 'page:media:project:list',
        title: '项目中心',
        path: '/dashboard/project'
    }, {
        key: 'page:media:project:detail',
        title: '项目详情',
        path: '/dashboard/project/:id'
    }, {
        key: 'page:media:project:detail',
        title: '项目详情',
        path: '/dashboard/project/about'
    },
]