import React from 'react'
import { Typography, Tooltip, Button, Space, Popconfirm, Avatar, Tag, Popover, Descriptions, Image } from 'antd';
import _ from 'lodash'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';

import { userRoleState, userAttacheState } from '../../Apps/atoms/Atoms'
import { check } from '../../utils/permission/rules';
import { XIAOHONGSHU, getPrice } from '../../Apps/compenents/xiaohongshu/xiaohongshuCommon';

const { Paragraph, Text } = Typography;
const XiaohongshuTableConfig = ({ action, handleMedia }) => {
    const role = useRecoilValue(userRoleState)
    const attache = useRecoilValue(userAttacheState)

    const { getMedia, getSaleGroupPrice, deleteMedia, changeEditor, getDetail } = handleMedia;

    let permission = {
        'module:wechat:money:cost': check(role, 'module:wechat:money:cost', { action }),
        'module:wechat:money:user': check(role, 'module:wechat:money:user', { action }),
        'module:wechat:money:sale': check(role, 'module:wechat:money:sale', null),
        'module:wechat:money:sale:cost': check(role, 'module:wechat:money:sale:cost', null),
    }


    const getImg = img => {
        let arr = _.split(img, '/')
        if (_.indexOf(arr.slice(-1)[0], '.') === -1) {
            return img + '.jpg'
        } else {
            return img
        }
    }

    const nameCol = {
        title: '红人名称',
        dataIndex: 'name',
        width: 220,
        checked: 1,
        render: (val, data) => {
            return (
                <Space>
                    <Avatar size={72} src={getImg(data.header_img)} />
                    <Space direction='vertical' size={4}>
                        <div>
                            <a href={data.url} style={{ color: '#333', fontSize: 14 }} target='_blank' rel='noopener noreferrer'>{val}</a>
                        </div>
                        <div style={{ width: 120 }}>
                            <Paragraph ellipsis={{ rows: 1 }}>{data.description}</Paragraph>
                        </div>
                    </Space>
                </Space >
            )
        }
    }

    const priceCol = [
        {
            title: '价格',
            dataIndex: 'prices',
            checked: 1,
            render: (val, data) => {
                let size = _.size(XIAOHONGSHU)
                let gridStyle = { display: 'grid', gap: 8, gridTemplateColumns: `repeat(${size + 1}, 1fr)` }
                let priceMap = getPrice(data.user_price)
                return <Space size={8} direction='vertical' style={{ width: '100%' }}>
                    <div style={gridStyle}>
                        <Text strong>发布类型</Text>
                        {_.map(XIAOHONGSHU, (v, k) => <Text key={v.id} strong>{v['name'].substring(0, v['name'].length - 2)}</Text>)}
                    </div>
                    {
                        permission['module:wechat:money:cost'] && <div style={gridStyle}>
                            <div>成本</div>
                            {_.map(XIAOHONGSHU, (v, k) => <div key={v.idx} >{data[`price_${v.idx}`] || '暂无'}</div>)}
                        </div>
                    }
                    {
                        permission['module:wechat:money:sale:cost'] && <div style={gridStyle}>
                            <div>销售</div>
                            {_.map(XIAOHONGSHU, (v, k) => <div key={v.idx} >{data[`sale_price_${v.idx}`] || '暂无'}</div>)}
                        </div>
                    }
                    {
                        permission['module:wechat:money:sale'] && <div style={gridStyle}>
                            <div>成本</div>
                            {_.map(XIAOHONGSHU, (v, k) => <div key={v.idx} >{data[`sale_price_${v.idx}`] || '暂无'}</div>)}
                        </div>
                    }
                    {
                        permission['module:wechat:money:user'] && <div style={gridStyle}>
                            <div>报价</div>
                            {_.map(XIAOHONGSHU, (v, k) => <div key={v.idx} >{priceMap[XIAOHONGSHU[k].id] || '暂无'}</div>)}
                        </div>
                    }
                </Space>
            }
        },
    ]

    const mediaCol = [
        {
            title: '媒体详情',
            dataIndex: 'location',
            checked: 1,
            render: (text, data) => {
                return <Space direction='vertical'>
                    <Text strong>粉丝数:{data.follower}</Text>
                    <Text strong>点赞及收藏:{data.favorite}</Text>
                    <Text strong>所在地区:{data.area}</Text>
                </Space>
            }
        }, {
            title: '标签',
            dataIndex: 'tag',
            checked: 1,
            render: (text, data) => {
                if (data.tag !== '') {
                    let arr = _.split(data.tag, ',')
                    return <div>
                        {arr.map(tag => <Tag color='blue' key={tag}>{tag}</Tag>)}
                    </div>
                }
            }
        },
    ]


    const mediaEditorCol = [
        {
            title: '编辑',
            dataIndex: 'editor_name',
            checked: 1,
            render: (t, data) => {
                return (
                    <Space direction='vertical' size={4}>
                        <span>
                            <Text>{data.creator_name}</Text>
                            <Button type='link' size='small' icon={<EditOutlined />} onClick={() => changeEditor(data.id)} />
                        </span>
                        <Text>{data.editor_name}</Text>
                        <Text>{data.editor_tel}</Text>
                    </Space>
                )
            }
        }, {
            title: '内部备注',
            dataIndex: 'inner_remarks',
            checked: 1,
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    <Paragraph ellipsis={{ rows: 3 }}>
                        {text}
                    </Paragraph>
                </Tooltip>
            )
        }
    ]

    const mediaRemarkCol = {
        title: '备注',
        dataIndex: 'remarks',
        checked: 1,
        render: (text) => (
            <Tooltip placement="topLeft" title={text}>
                <Paragraph ellipsis={{ rows: 3 }}>
                    {text}
                </Paragraph>
            </Tooltip>
        )
    }

    const modifyMediaCol = {
        title: '操作',
        dataIndex: 'id',
        fixed: 'right',
        width: 100,
        checked: 1,
        render: (text, data) => (
            <Space>
                <Button onClick={() => getDetail(data.id)}>getDetail</Button>
                <Button type="link" icon={<EditOutlined />} size='small' onClick={() => getMedia(data.id)} />
                <Popconfirm
                    title={`确认删除${data.name}吗?`}
                    onConfirm={() => deleteMedia(data.id)}
                    onCancel={e => console.log(e)}
                    okText="确认"
                    cancelText="取消">
                    <Button type="link" icon={<DeleteOutlined />} size='small' />
                </Popconfirm>
            </Space>
        )
    }


    const updateSaleGroupPriceCol = {
        title: '操作',
        dataIndex: 'id',
        fixed: 'right',
        width: 100,
        checked: 1,
        render: (text, data) => {
            switch (attache) {
                case 0:
                    return <Space>
                        <Button type="link" size='small' onClick={() => getSaleGroupPrice(data.id, 3)}>客户价格分配</Button>
                        <Button type="link" size='small' onClick={() => getSaleGroupPrice(data.id, 9)}>会员价格分配</Button>
                    </Space>
                default:
                    break;
            }
        }
    }

    let result = []
    switch (role) {
        case "CUSTOMER":
            if (action === 'EDIT') {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaRemarkCol,
                    modifyMediaCol,
                )
            } else {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaRemarkCol,
                )
            }

            break;
        case "OPERATOR":
            switch (attache) {
                case 0:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaEditorCol,
                        mediaRemarkCol,
                        modifyMediaCol,
                    )
                    break;
                case 1:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                    break;
                default:
                    break;
            }
            break;
        case "SALE":
        case "FRANCHISE":
            switch (attache) {
                case 0:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                        updateSaleGroupPriceCol
                    )
                    break;
                case 1:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                        updateSaleGroupPriceCol
                    )
                    break;
                case 2:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
    return result
}
export default XiaohongshuTableConfig;
