import React from 'react';
import { Form, Col, Input, DatePicker, Select, Radio, InputNumber, TimePicker, Upload, message, Button } from 'antd';
import _ from 'lodash'
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;
const CreateForm = ({ datas, span }) => {

    const result = [];
    for (let i = 0; i < _.size(datas); i++) {
        switch (datas[i].type) {
            case 'input':
                result.push(
                    <Col span={span} key={datas[i].name} >
                        <Form.Item label={datas[i].label} name={datas[i].name}
                            rules={
                                datas[i].rule
                                    ? _.concat([{ required: datas[i].required, message: `请输入${datas[i].label}` }], datas[i].rule)
                                    : [{ required: datas[i].required, message: `请输入${datas[i].label}` }]
                            }
                        >
                            <Input
                                disabled={datas[i].disabled}
                                placeholder={datas[i].placeholder} />
                        </Form.Item>
                    </Col>
                )
                break;
            case 'inputnumber':
                if (datas[i].hidden === true) {
                    result.push(
                        <Form.Item noStyle>
                            <InputNumber
                                hidden={true}
                                style={{ width: '100%' }} />
                        </Form.Item>
                    )
                } else {
                    result.push(
                        <Col span={span} key={datas[i].name} >
                            <Form.Item label={datas[i].label} name={datas[i].name}
                                rules={[{ required: datas[i].required, message: `请输入${datas[i].label}` }]}
                            >
                                <InputNumber
                                    style={{ width: '100%' }}
                                    placeholder={datas[i].placeholder} />
                            </Form.Item>
                        </Col>
                    )
                }
                break;
            case 'datepicker':
                result.push(
                    <Col span={span} key={datas[i].name} className='time-filter' >
                        <Form.Item label={datas[i].label} name={datas[i].name}>
                            <DatePicker
                                style={{ width: '100%' }}
                                placeholder={datas[i].placeholder}
                            />
                        </Form.Item>
                    </Col>
                )
                break;
            case 'timepicker':
                result.push(
                    <Col span={span} key={datas[i].name} className='time-filter' >
                        <Form.Item label={datas[i].label} name={datas[i].name}
                            rules={[{ required: datas[i].required, message: `请输入${datas[i].label}` }]}
                        >
                            <TimePicker
                                style={{ width: '100%' }}
                                minuteStep={15} secondStep={30}
                                placeholder={datas[i].placeholder}
                            />
                        </Form.Item>
                    </Col>
                )
                break;
            case 'select':
                result.push(
                    <Col span={span} key={datas[i].name} >
                        <Form.Item label={datas[i].label} name={datas[i].name}
                            rules={
                                datas[i].rule
                                    ? _.concat([{ required: datas[i].required, message: `请输入${datas[i].label}` }], datas[i].rule)
                                    : [{ required: datas[i].required, message: `请输入${datas[i].label}` }]
                            }
                        >
                            {
                                datas[i].mode
                                    ? <Select mode={datas[i].mode} optionFilterProp="name">
                                        {datas[i].children?.map(e => <Option key={e.id} name={e.name} value={e.val || e.id}>{e.name}</Option>)}
                                    </Select>
                                    : <Select showSearch>
                                        {datas[i].children
                                            .filter(e => e !== '')
                                            .map(e => <Option key={e} value={e}>{e}</Option>)}
                                    </Select>
                            }
                            {/* <Select showSearch>
                                {datas[i].children
                                    .filter(e => e !== '')
                                    .map(e => <Option key={e} value={e}>{e}</Option>)}
                            </Select> */}
                        </Form.Item>
                    </Col>
                )
                break;
            case 'radio':
                result.push(
                    <Col span={datas[i].children.length < 3 ? span : 2 * span} key={datas[i].name} >
                        <Form.Item label={datas[i].label} name={datas[i].name}
                            rules={[{ required: datas[i].required, message: `请输入${datas[i].label}` }]}>
                            <Radio.Group buttonStyle="solid">
                                {datas[i].children.map(e => <Radio.Button key={e} value={e[1]}>{e[0]}</Radio.Button>)}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                )
                break;
            case 'area':
                result.push(
                    <Col span={24} key={datas[i].name}>
                        <Form.Item label={datas[i].label} name={datas[i].name}  >
                            <Input.TextArea
                                rows={4}
                                placeholder={datas[i].placeholder}
                            />
                        </Form.Item>
                    </Col>
                )
                break;
            case 'upload':
                result.push(
                    <Col span={span} key={datas[i].name} >
                        <Form.Item label={datas[i].label}
                            name={datas[i].name}
                            valuePropName="file"
                            getValueFromEvent={e => {
                                if (Array.isArray(e)) {
                                    return e;
                                }
                                return e && e.fileList;
                            }}
                        >
                            <Upload name='upload_files'
                                beforeUpload={(file) => {
                                    const isLt2M = file.size / 1024 / 1024 < 2;
                                    if (!isLt2M) {
                                        message.error('当前支持图片最大尺寸为2M!');
                                    }
                                }}
                                action={datas[i].action} listType="picture">
                                <Button icon={<UploadOutlined />}>
                                    点击上传
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                )
                break;
            default:
                break;
        }
    }
    return result
}

export default CreateForm