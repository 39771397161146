import React from 'react'
import { Typography, Tooltip, Button, Space } from 'antd';
import _ from 'lodash'
import {
    EditOutlined,
    CaretRightOutlined, PauseOutlined,
} from '@ant-design/icons';

import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const { Paragraph, Text } = Typography;


const BaikeTableConfig = ({ action, attache, handleMedia, simpleMode }) => {
    const role = useRecoilValue(userRoleState)

    const { getMedia, handleStatus, getBaikeSaleGroupPrice, reloadTable } = handleMedia;

    const nameCol = [
        {
            title: '媒体名称',
            dataIndex: 'name',
            render: (text, data) => {
                let img;
                switch (data.platform) {
                    case '百度':
                        img = <img src="/static/images/baike/baidu.jpg" width='72px' alt='百度百科' />
                        break;
                    case '搜狗':
                        img = <img src="/static/images/baike/sougou.jpg" width='72px' alt='搜狗百科' />
                        break;
                    case '360':
                        img = <img src="/static/images/baike/360.jpg" width='72px' alt='360百科' />
                        break;
                    case '互动':
                        img = <img src="/static/images/baike/hudong.jpg" width='72px' alt='互动百科' />
                        break;
                    case '搜搜':
                        img = <img src="/static/images/baike/soso.jpg" width='72px' alt='互动百科' />
                        break;
                    default:
                        break;
                }
                return (
                    <Space>
                        {img}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text>{text}</Text>
                            <span><a href={data.sample_url} target='_blank' rel='noopener noreferrer'>查看案例</a></span>
                        </div>
                    </Space>

                )
            }
        }
    ]

    const priceCol = {
        title: '价格',
        dataIndex: 'sale_price',
        render: (t, data) => {
            let result = <Text strong>{data.user_price}</Text>
            switch (role) {
                case 'OPERATOR':
                    result = <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text strong>成本：{data.cost}</Text>
                        <Text strong>相对成本:{data.sale_price}</Text>
                    </div>
                    break;
                case 'SALE':
                case 'FRANCHISE':
                    result = <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text strong>成本：{data.sale_price}</Text>
                    </div>
                    break;
                default:
                    break;
            }
            return <div style={{ display: 'flex', flexDirection: 'column' }}>
                {result}
            </div>
        }
    }

    const mediaCol = [
        {
            title: '百科类型',
            dataIndex: 'category'
        }, {
            title: '执行周期',
            dataIndex: 'cycle',
            render: (text, data) => {
                return <Text strong>{text}天</Text>
            }
        },
    ]

    const mediaCheckCol = {
        title: '审核',
        dataIndex: 'checked',
        render: (text, data) => {
            let stateCol = data.status === 1
                ? <Tooltip title="媒体正常">
                    <Button onClick={() => handleStatus(data.id, 'status', 2)} style={{ backgroundColor: '#3dbd7d', color: '#ffffff' }}>
                        <CaretRightOutlined />
                    </Button>
                </Tooltip>
                : <Tooltip title="媒体暂停">
                    <Button onClick={() => handleStatus(data.id, 'status', 1)} style={{ backgroundColor: '#ffbf00', color: '#ffffff' }}>
                        <PauseOutlined />
                    </Button>
                </Tooltip>
            return (
                <div className="dt-box">
                    {stateCol}
                </div>
            )
        }
    }

    const mediaRemarkCol = {
        title: '备注',
        dataIndex: 'comment',
        width: '36%',
        render: (text) => (
            <Paragraph ellipsis={{ rows: 3 }}>
                {text}
            </Paragraph>
        )
    }

    const modifyMediaCol = {
        title: '操作',
        dataIndex: 'id',
        fixed: 'right',
        width: 100,
        render: (text, data) => {
            return <Button type="link" icon={<EditOutlined />} size='small' onClick={() => getMedia(data.id)} />;
        }
    }

    const updateSaleGroupPriceCol = {
        title: '操作',
        dataIndex: 'id',
        fixed: 'right',
        width: 100,
        render: (text, data) => {
            switch (attache) {
                case 0:
                    return <>
                        <Button type="link" size='small'
                            onClick={() => {
                                getBaikeSaleGroupPrice(data.id, 3)
                            }}>
                            客户价格分配
                </Button>
                    </>
                default:
                    break;
            }
        }
    }


    let result = []
    switch (role) {
        case "CUSTOMER":
            if (action === 'EDIT') {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaCheckCol,
                    mediaRemarkCol,
                    modifyMediaCol,
                )
            } else {
                result = _.concat(
                    nameCol,
                    priceCol,
                    mediaCol,
                    mediaRemarkCol,
                )
            }

            break;
        case "OPERATOR":
            switch (attache) {
                case 0:
                    if (simpleMode) {
                        result = _.concat(
                            nameCol,
                            priceCol,
                            mediaCol,
                        )
                    } else {
                        result = _.concat(
                            nameCol,
                            priceCol,
                            mediaCol,
                            mediaCheckCol,
                            mediaRemarkCol,
                            modifyMediaCol,
                        )
                    }
                    break;
                case 1:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                    break;
                default:
                    break;
            }
            break;
        case "SALE":
        case "FRANCHISE":
            switch (attache) {
                case 0:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                        updateSaleGroupPriceCol
                    )
                    break;
                case 1:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                    )
                    break;
                case 2:
                    result = _.concat(
                        nameCol,
                        priceCol,
                        mediaCol,
                        mediaRemarkCol,
                        updateSaleGroupPriceCol,
                    )
                    break;
                default:
                    break;
            }
            break;
        default:
            result = _.concat(
                nameCol,
                priceCol,
                mediaCol,
                mediaRemarkCol,
            )
            break;
    }
    return result
}
export default BaikeTableConfig;
