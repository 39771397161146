import React, { useState, useEffect } from 'react';
import { Form, Table, Button, Row, Col, Tabs, message, Modal, Typography, Space, Layout, Divider } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import _ from 'lodash'

import SearchForm from '../../common/SearchForm'
import { WithdrawTableConfig } from '../../../role/withdraw/WithdrawTableConfig';
import WithdrawForm from './WithdrawForm';
import WithdrawSearchConfig from '../../../role/withdraw/WithdrawSearchConfig';
import { initFormData, tablePagination, disposeColumn, TableTitle } from '../../common/tableCommon';

const axios = require('axios');


const { Title } = Typography;
const { Content } = Layout;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    layout: "vertical"
}

const initialModal = {
    target_id: undefined,
    apply_withdraw: false,
    withdraw_details: false,
}
// 'page:withdraw:apply'
// 用户申请提现页面 
const WithdrawTable = ({ withdraw_list_id = null, withdraw_type = null, detail = false }) => {

    const [form] = Form.useForm();
    const [withdrawForm] = Form.useForm();

    let navigate = useNavigate();

    const searchDatas = WithdrawSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const [withdraws, setWithdraws] = useState([])
    const [data, setData] = useState([])
    const [status, setStatus] = useState({ state: '', order_state: null })
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(initialModal)
    const [pagination, setPagination] = useState(tablePagination)
    const [details, setDetails] = useState({})

    const reloadTable = () => form.submit()

    /* 获取信息 */
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        withdraw_list_id && params.append("withdraw_list_id", withdraw_list_id)
        withdraw_type && params.append("withdraw_type", withdraw_type)
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        _.toPairs(status).filter(e => e[1]).map(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/withDraw/detail',
                data: params
            }).then((res) => {
                let tempEntity = res?.data?.data
                tempEntity && setData(tempEntity.map(e => {
                    return ({
                        ...e,
                        enabled: !(e.order_state === 3 && e.state === 'UNPAID' && _.toUpper(e.paid_status) === 'UNPAID')// 保证在7天以外 以及 未提现情况下
                    })
                }))
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    // 提现申请
    const withdrawSubmit = async () => {
        let params = new FormData()

        params.append('ids', withdraws.map(e => e.id))
        params.append('withdraws', JSON.stringify(withdraws))

        await axios({
            method: 'post',
            url: "/withDraw/apply",
            data: params
        }).then(res => {
            if (res.data.code === 1) {
                let data = res.data.data
                setModal({ ...modal, withdraw_details: true })
                setDetails(data)
            } else {
                message.warning(res.data.data)
            }
        })
    }

    // 提现申请
    const withdrawConfirm = async () => {
        let value = _.pickBy(withdrawForm.getFieldsValue(), _.identity)
        if (value.type === undefined) {
            message.warning("请选择提现方式")
            return
        }
        let params = new FormData()
        params.append('ids', details.ids)
        value && _.toPairs(value).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        await axios({
            method: 'post',
            url: "/withDraw/list/add",
            data: params
        }).then(res => {
            if (res.data.code === 1) {
                message.success("已提交申请")
                reloadTable()
                setWithdraws([])
                setModal({ ...modal, withdraw_details: false })
            } else {
                message.warning(res.data.data)
            }
        })
    }

    // 切换Tabs时 进行处理
    const filterMediaStatus = (e) => {
        setWithdraws([])
        switch (e) {
            case '2':
                setStatus({ state: 'UNPAID', order_state: 1 })
                break;
            case '3':
                setStatus({ state: 'UNPAID', order_state: 3 })
                break;
            case '4':
                setStatus({ state: 'PAID', order_state: 3 })
                break;
            case '5':
                setStatus({ state: 'PENDING', order_state: 3 })
                break;
            default:
                setStatus({ state: '', order_state: null })
        }
        form.submit()
    }

    useEffect(() => {
        fetchData()
    }, [])


    /* 获取表格数据 */
    const defaultColumns = WithdrawTableConfig()
    const localStorageColumnsName = "____WithdrawTableConfig____";
    disposeColumn(localStorageColumnsName, defaultColumns)

    const [columns, setColumns] = useState(defaultColumns)

    return (
        <>
            <Content className='table-search'>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                    <Divider style={{ margin: '8px 0' }} />
                    <Row>
                        <Col span={12} >
                            {
                                !detail &&
                                <Space>
                                    <Button type="primary" onClick={() => {
                                        if (_.isEmpty(withdraws)) {
                                            message.warning("请选择订单")
                                            return;
                                        } else {
                                            withdrawSubmit()
                                        }
                                    }}>提交申请</Button>
                                    <Button onClick={() => navigate('/dashboard/user#advanced')}>保存支付宝账号</Button>
                                </Space>
                            }
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">搜索</Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
            <Content style={{ background: '#fff', padding: 24, margin: 0, }}>
                {
                    !detail &&
                    <Tabs defaultActiveKey="1" onChange={filterMediaStatus} style={{ marginBottom: '8px' }}
                        items={[
                            { key: '1', label: `全部订单`, },
                            { key: '2', label: `待确认订单`, },
                            { key: '3', label: `可提现订单`, },
                            { key: '4', label: `已申请订单`, },
                            { key: '5', label: `已完成订单`, },
                        ]}
                    />
                }
                <Table
                    size="middle"
                    title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} />}
                    columns={columns.filter(e => e.checked === 1)}
                    rowSelection={!detail && {
                        selectedRowKeys: withdraws.map(e => e.id),
                        onChange: (selectedRowKeys, selectedRows) => {
                            setWithdraws(selectedRows.map(e => {
                                return {
                                    id: e.id,
                                    withdraw_list_id: e.withdraw_list_id
                                }
                            }))
                        },
                        getCheckboxProps: record => ({
                            disabled: record.enabled,
                            name: record.id,
                        })
                    }}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                />
            </Content>
            <Modal
                title="申请详情"
                open={modal.withdraw_details}
                width={800}
                onOk={() => withdrawConfirm()}
                onCancel={() => setModal({ ...modal, withdraw_details: false })}
            >
                <div>
                    <WithdrawForm withdrawForm={withdrawForm} />
                    <Title level={3} style={{ textAlign: "center" }}>总计{details.sum === undefined ? 0 : details.sum.total_money}元，{details.sum === undefined ? 0 : details.sum.total_num}篇</Title>
                    <Table
                        dataSource={details.details}
                        columns={detailColumns}
                        rowKey={record => record.media_id}
                    />
                </div>
            </Modal>
        </>
    )
}

export default WithdrawTable

const detailColumns = [
    {
        title: '媒体名称',
        dataIndex: 'name',
        key: 'name',
    }, {
        title: '数量',
        dataIndex: 'num',
        key: 'num',
    }, {
        title: '总金额',
        dataIndex: 'cost_money',
        key: 'cost_money',
    },
];