import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import WorkOrderTable from '../../orders/WorkOrderTable';
import PageHeader from '../../../layout/PageHeader';

const { Content } = Layout;


const FinanceOrderCenter = ({ size }) => {
    let pageTitle = "财务订单结算中心"

    const [tab, setTab] = useState("media")
    const [workOrders, setWorkOrders] = useState([])

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                subTitle="用于结算后款客户的消费记录"
                // footer={
                //     <Tabs defaultActiveKey={tab} onChange={e => setTab(e)} items={[
                //         { key: 'media', label: `新闻媒体`, },
                //         { key: 'baike', label: `百科订单`, },
                //         { key: 'index', label: `文字链订单`, },
                //     ]}
                //     />
                // }
            />
            <Content>
                <div style={{ margin: 24 }}>
                    {tab === "media" && <WorkOrderTable workOrders={workOrders} setWorkOrders={setWorkOrders} />}
                </div>
            </Content>
        </HelmetProvider>
    )
}

export default FinanceOrderCenter;