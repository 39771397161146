import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Row, Col, Button, Layout, Modal } from 'antd';
import qs from 'qs';
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import { initFormData, tablePagination } from '../../common/tableCommon';
import AddRulesForm from './AddRulesForm';
import EventRuleTableConfig from '../../../role/EventRuleTableConfig';
import { resultResponse } from '../../common/ResultResponse';

const axios = require('axios');
const { Content } = Layout
const EventRulesTable = ({ event_id }) => {
    const role = useRecoilValue(userRoleState)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [visible, setVisible] = useState({ addEvent: false, addMedias: false, delMedias: false, action: 'ADD' })
    const resetModal = () => setVisible({ addEvent: false, addMedias: false, delMedias: false, action: 'ADD' })

    const fetchData = async (params = new FormData()) => {
        setLoading(true)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        await axios({
            method: 'post',
            url: '/event/rule/all',
            data: params
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
            let total = res.data.recordsFiltered
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    // 绑定规则
    const bindRule = async (id) => {
        await axios({
            method: 'post',
            url: `/event/rule/bind`,
            data: qs.stringify({
                event_id: event_id,
                rule_id: id
            })
        }).then((res) => {
            resultResponse(res, "已成功")
            resetModal()
        })
    }

    useEffect(() => {
        fetchData()
    }, [pagination.current, pagination.pageSize])

    const handleFunctions = { bindRule }

    const columns = EventRuleTableConfig({ handleFunctions })

    return <>
        <Content className='table-search'>
            <Table
                size='middle'
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination, filters, sorter) => {
                    setPagination(pagination)
                }}
                title={() => (
                    <>
                        <Row>
                            <Col span={12}></Col>
                            <Col span={12} style={{ textAlign: 'end' }}>
                                {(role === 'SALE' || role === 'FRANCHISE' || role === 'OPERATOR') &&
                                    <Button.Group>
                                        <Button type="primary" onClick={() => setVisible({ ...visible, addEvent: true, type: '' })}>
                                            <PlusOutlined />添加规则
                                        </Button>
                                    </Button.Group>}
                            </Col>
                        </Row>
                    </>
                )}
            />
        </Content>
        <Modal title="添加规则" open={visible.addEvent} footer={null} onCancel={resetModal}>
            <AddRulesForm />
        </Modal>
    </>;
}

export default EventRulesTable