import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { LinkOutlined } from '@ant-design/icons';
import { Typography, Button, Space, Tag } from 'antd';

const { Text, Link, Paragraph } = Typography;
const CONNECTOR = '=>'
export const tableConfig = ({ handleFns }) => {

    const { checkHistory } = handleFns

    return [
        {
            title: "创建时间",
            dataIndex: "create_time"
        }, {
            title: "通知标题",
            dataIndex: "title",
            render: (t, data) => {
                return <Space size='small'>
                    <Text>{data.account}</Text>
                    <Text type='danger'>{notificationType.get(data.type_id)}</Text>
                    <Text strong>{t}</Text>
                </Space>
            }
        }, {
            title: "内容",
            dataIndex: "content",
            render: (data) => {
                let array = _.filter(_.split(data, '|'), e => e !== '')
                return <Space direction='vertical' size='small'>
                    {
                        array.map(e => {
                            let tmp = _.split(e, ':')
                            let name = tmp[0]
                            if (name === 'url') {
                                let vals = _.split(tmp[2], '->')
                                let old_url = `${tmp[1]}:${vals[0]}`
                                let new_url = `${vals[1]}:${tmp[3]}`
                                return <Paragraph key={name}>
                                    {propertyType.get(name)}: <Link href={old_url}>原案例</Link> {CONNECTOR} <Link href={new_url}>新案例</Link>
                                </Paragraph>
                            } else {
                                let vals = _.split(tmp[1], '->')
                                return <Text key={name}>
                                    {`${propertyType.get(name)}: ${vals[0]} ${CONNECTOR} ${vals[1]}`}
                                </Text >
                            }
                        })
                    }
                </Space >
            }
        }, {
            title: "通知类型",
            dataIndex: "type_id",
            render: (val) => {
                return <Tag color={notificationColorType.get(val)}>
                    {notificationType.get(val)}
                </Tag>
            }
        }, {
            title: "操作用户",
            dataIndex: "account",
            render: (val, data) => {
                let tag;
                switch (data.roles_id) {
                    case 2:
                        tag = <Tag color='red'>运营管理</Tag>
                        break;
                    case 5:
                        tag = <Tag color='blue'>客户</Tag>
                        break;
                    case 32:
                        tag = <Tag color='orange'>内部销售</Tag>
                        break;
                    default:
                        break;
                }

                return <Space direction='vertical'>
                    {tag}
                    <Text>{val}</Text>
                </Space>
            }
        }, {
            title: "查看详情",
            dataIndex: "media_id",
            render: (val, data) => {
                return data?.media_id && <Button type='link' icon={<LinkOutlined />} size='small' onClick={() => checkHistory(val)}>查看记录</Button>
            }
        }
    ]
}

export const notificationType = new Map([
    [1001, "新增媒体"],
    [1002, "编辑媒体"],
])

export const notificationColorType = new Map([
    [1001, "green"],
    [1002, "blue"],
])

export const propertyType = new Map([
    ['cost', "成本变动"],
    ['url', "案例变动"],
    ['effect', "收录变动"],
    ['comment', "备注变动"],
    ['name', "名称变动"],
    ['sale_price', "相对成本变动"],
    ['editor_name', "联系方式"],
])

export const searchConfig = [
    {
        name: 'account',
        label: '操作用户',
        placeholder: '请输入操作用户账号',
        type: 'input'
    }, {
        name: 'media_name',
        label: '媒体名称',
        placeholder: '请输入媒体名称',
        type: 'input'
    }, {
        name: 'start_time',
        label: '开始时间',
        placeholder: '请输入开始时间',
        type: 'datepicker',
        value: dayjs().subtract(7, 'days')
    }, {
        name: 'end_time',
        label: '结束时间',
        placeholder: '请输入结束时间',
        type: 'datepicker',
        value: null
    }, {
        name: 'type_id',
        label: '操作类型',
        type: 'radio',
        children: _.concat([['全部', '']], Array.from(notificationType).map(([k, v]) => [v, k]))
    },
]