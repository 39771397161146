import React, { useState, useEffect } from 'react'
import { List, Typography, Space, Divider } from 'antd';
import {
    CrownTwoTone
} from '@ant-design/icons';
const axios = require('axios');

const { Title, Paragraph } = Typography
const ProfitBillboard = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getBillboard = async (id) => {
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: "/billboard/profit",
            }).then(res => {
                setData(res.data.data)
            })
            setLoading(false)
        }

    }

    useEffect(() => {
        getBillboard()
    }, [])


    return (
        <>
            <Title level={3}><span role="img" aria-label="Crown">👑</span>本月会员收益排行榜<span role="img" aria-label="Strong">💪</span></Title>
            <Divider />
            <List
                dataSource={data}
                loading={loading}
                renderItem={item => (
                    <List.Item key={item.number}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <Space>
                                <Title level={4} style={{ width: '32px' }} >{item.number}</Title>
                                <Title level={4}>{item.account}</Title>
                            </Space>
                            <Title level={3}>{`${item.profit}元`}</Title>
                        </div>
                    </List.Item>
                )}
            />
        </>
    )
}

export default ProfitBillboard
