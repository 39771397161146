import React, { useState } from 'react';
import { Layout, Tabs, Badge } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useRecoilValue } from 'recoil'
import _ from 'lodash'

import { userRoleState, orderNumsState } from '../../atoms/Atoms'
import BaikeOrderTable from '../baike/BaikeOrderTable';
import QAOrderTable from '../qa/QAOrderTable';
import OrderTable from './OrderTable';
import MediaIndexOrderTable from '../mediaindex/MediaIndexOrderTable';
import Weibo from '../weibo/Weibo';
import { check } from '../../../utils/permission/rules';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;


const OrderCenter = () => {
    let pageTitle = "订单中心"
    const role = useRecoilValue(userRoleState)
    const [tab, setTab] = useState("media")
    const [orders, setOrders] = useState([])

    const permission = {
        'tab:media:order': check(role, 'tab:media:order', { action: null }),
        'tab:baike:order': check(role, 'tab:baike:order', null),
        'tab:index:order': check(role, 'tab:index:order', { action: null }),
        'tab:qa:order': check(role, 'tab:qa:order', null),
        'tab:weibo:fans:order': check(role, 'tab:weibo:fans:order', null),
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            permission['tab:media:order'] && { key: 'media', label: <TabName target='MEDIA' />, },
                            permission['tab:baike:order'] && { key: 'baike', label: <TabName target='BAIKE' />, },
                            permission['tab:index:order'] && { key: 'index', label: <TabName target='INDEX' />, },
                            permission['tab:qa:order'] && { key: 'qa', label: <TabName target='QA' />, },
                            permission['tab:weibo:fans:order'] && { key: 'wbfollower', label: '微博粉丝', },
                        ]}
                    />
                }
            />
            <Content>
                <div style={{ margin: 24 }}>
                    {tab === "media" && <OrderTable orders={orders} setOrders={setOrders} />}
                    {tab === "baike" && <BaikeOrderTable />}
                    {tab === "index" && <MediaIndexOrderTable />}
                    {tab === "qa" && <QAOrderTable />}
                    {tab === "wbfollower" && <Weibo />}
                </div>
            </Content>
        </HelmetProvider>
    )
}

export default OrderCenter;

const TabName = ({ target }) => {

    const orderNums = useRecoilValue(orderNumsState)

    const names = {
        MEDIA: { name: '新闻媒体', key: 'MEDIA' },
        BAIKE: { name: '百科订单', key: 'BAIKE' },
        QA: { name: '问答订单', key: 'QA' },
        INDEX: { name: '文字链订单', key: 'INDEX' }
    }

    const hasOrder = (name) => {
        let array = _.find(orderNums, e => e?.name === name)
        return array && array.customer > 0
    }

    return <span>
        {names[target]?.name}
        {hasOrder(names[target]?.key) && <Badge dot offset={[2, -8]} />}
    </span>
}