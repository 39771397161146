import React, { useState, useEffect } from 'react'
import {
    InboxOutlined, AlignLeftOutlined, FileAddOutlined,
    CloudDownloadOutlined, SnippetsOutlined, SaveOutlined, ClearOutlined, EditOutlined
} from '@ant-design/icons';
import {
    Form, Layout, Steps, Button, message, Row, Col, Input, Upload, Descriptions, Popover,
    Card, Tag, Modal, Affix, Divider, Typography, Space, Tabs, AutoComplete
} from 'antd';
import { useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import _ from 'lodash';
import { useSetRecoilState } from 'recoil';

import { refreshUserState } from '../../atoms/Atoms'
import UseCoupon from './UseCoupon';
import UsePackage from './UsePackage';
import { resultResponse } from '../../common/ResultResponse';
import PreviewArticle from '../articles/PreviewArticle';
import MediaTable from '../medias/MediaTable'
import Formatter from '../../service/Formatter'
import CKEditorInstance from '../../../utils/CkeditorInstance';
import { Feedback } from '../../common/CreateCommon'
import PageHeader from '../../layout/PageHeader';

const axios = require('axios');

const { Content } = Layout;
const { TextArea } = Input;
const { Text, Paragraph } = Typography;
const { Step } = Steps;
const { Dragger } = Upload;

const { confirm } = Modal;
const layout = {
    labelCol: { sm: 6, xs: 24 },
    wrapperCol: { md: 12, sm: 12, xs: 24 },
    layout: 'horizontal'
};

const localStorageEditorStyleName = "____EditorStyle____";
const localStorageEditorContentName = "____EditorContent____";
const localStorageEditorContentTitleName = "____EditorContentTitle____";
const localStorageOrderMediaName = "____OrderMedia____";
const MAX_FILE_SIZE = 20

const CreateOrder = () => {

    const location = useLocation();
    const state = location.state;
    const setRefresh = useSetRecoilState(refreshUserState)

    const initOrder = state?.order ?? { title: '', remark: '', url: '', file_url: '', name: '', sales_name: ''  };
    const aid = state?.article_id
    const pid = state?.project_id
    let pageTitle = "创建文章"

    const [content, setContent] = useState('')
    const [current, setCurrent] = useState(0)
    const [medias, setMedias] = useState([])
    const [order, setOrder] = useState(initOrder)
    const [coupon, setCoupon] = useState([])
    const [packages, setPackages] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [feedback, setFeedback] = useState({})
    const [fetching, setFetching] = useState(false)
    const [visible, setVisible] = useState({ preview: false, popover: false })
    const [options, setOptions] = useState([]);
    const [projectDisabled, setProjectDisabled] = useState(false);

    const [projectForm] = Form.useForm();
    // 编辑器样式
    const [editorStyle, setEditorStyle] = useState(_.isNil(localStorage.getItem(localStorageEditorStyleName)) ? 'horizontal' : localStorage.getItem(localStorageEditorStyleName))

    const nextPage = () => setCurrent(current + 1)
    const prevPage = () => setCurrent(current - 1)
    const resetAll = () => {
        setCurrent(0);                                  // step步数
        setMedias([]);                                  // 选择媒体信息
        setOrder({ title: '', remark: '', url: '', file_url: '', name: '', sales_name: '' })    // 文章信息
        setContent('')                                  // 富文本信息
        setCoupon([])                                   // 优惠券信息
        setPackages(0)                                  // 媒体打包套餐
    }
    function firstStepNext() {
        console.log(order, order?.name.split('#'))
        if (order.name !== '' && order.name !== undefined) {
            if (order.project_id === undefined) {
                message.error('请选择项目!')
                return
            }
        }
        nextPage()
    }

    const setTitle = e => setOrder({ ...order, title: e.target.value })
    const setUrl = e => setOrder({ ...order, url: e.target.value })
    const setRemark = e => setOrder({ ...order, remark: e.target.value })
    const setSalesName = e => setOrder({ ...order, sales_name: e.target.value })


    useEffect(() => {
        if (aid) {
            axios.post(`/article/asyn/detail/${aid}`)
                .then(res => {
                    const data = res.data.data
                    setContent(data.content)
                })
        }
    }, [aid])

    useEffect(() => {
        if (pid) {
            setProjectDisabled(true)
            setOrder({ ...order, project_id: pid })
        }
    }, [order, pid])

    // ANTD 拖拽上传 docx 配置
    const props = {
        name: 'upload_file',
        multiple: true,
        action: `/article/upload/docx?_r=${Math.random()}`,
        beforeUpload(file) {
            const isLt10M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
            if (!isLt10M) {
                message.error(`当前支持文档最大尺寸为${MAX_FILE_SIZE}M!`);
                return false
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                let response = info.file.response
                setContent(response.data.content)
                setOrder({ ...order, file_url: response.data.file_url })
                message.success(`${info.file.name} 已成功上传！`);
            } else if (status === 'error') {
                message.error(`${info.file.name} 上传失败，请检查文件格式`);
            }
        },
    };

    const saveLocalStorageContent = (val) => {
        localStorage.setItem(localStorageEditorContentName, val)
        localStorage.setItem(localStorageEditorContentTitleName, JSON.stringify(order))
        message.success("成功保存草稿")
    }
    const loadLocalStorageContent = () => {
        setContent(localStorage.getItem(localStorageEditorContentName))
        setOrder(JSON.parse(localStorage.getItem(localStorageEditorContentTitleName)))
        message.success("成功读取草稿")
    }
    const saveLocalStorageMedias = (val) => {
        localStorage.setItem(localStorageOrderMediaName, JSON.stringify(val))
        message.success("成功保存发稿记录")
    }

    // 发布订单
    const sendOrder = async () => {
        if (_.size(medias) === 0) { message.error("请选择媒体!"); return; }
        if (order.title === '') { message.error('请填写标题后提交！'); return }
        if (order.url === '' && content.trim() === '') { message.error('请填写内容后提交！'); return; }
        let formData = new FormData()
        const data = {
            title: order.title.trim(),
            remarks: order.remark.trim(),
            source_url: order.url.trim(),
            sales_name: order.sales_name.trim() || "",
            file_url: order.file_url.trim(),
            content,
            media_datas: JSON.stringify(_.uniqBy(medias, 'id')),
            coupon_datas: coupon,
            packages
        }
        for (let key in data) {
            if (typeof (data[key]) === 'object') {
                for (let subKey in data[key]) {
                    formData.append(`${key}.${subKey}`, data[key][subKey]);
                }
            } else {
                formData.append(key, data[key]);
            }
        }

        order?.project_id && formData.append('project_id', order?.project_id)
        order?.name && formData.append('name', order?.name?.split('#')[0])
        if (fetching === false) {
            setFetching(true)
            nextPage()
            await axios({
                method: 'post',
                url: '/article',
                data: formData
            }).then((res) => {
                resultResponse(res, '已成功发布！')
                setFeedback(res.data)
                setFetching(false)
                setRefresh(true)
            })
        }
        saveLocalStorageMedias(medias)
    }

    const checkRepeat = () => {
        let params = new FormData()
        params.append("title", order.title)
        params.append("ids", medias.map(e => e.id))
        axios({
            method: 'post',
            url: '/order/repeat_check',
            data: params
        }).then((res) => {
            if (res.data.code !== 1) {
                confirm({
                    title: <Paragraph>
                        <Text strong>{res.data.data}</Text> 已发布过相同标题稿件, 请确认!
                    </Paragraph>,
                    content: '是否继续提交',
                    onOk() { nextPage() },
                    onCancel() { return false },
                })
                return false
            } else {
                nextPage()
            }
        })
    }

    async function handleSearch(value) {
        let params = new FormData()
        params.append('name', value)
        await axios({
            method: 'post',
            url: `/proj/search`,
            data: params
        }).then((res) => {
            let data = res.data.data
            let result = _.map(data, e => {
                let time = e?.create_time.substring(0, 7)
                return {
                    value: `${e.proj_name}#${e.id}`,
                    label: `[${time}] [${e.proj_name}]`,
                    id: e.id
                }
            })
            setOptions(value ? result : []);
        })
    }

    async function createProject(value) {
        let params = new FormData()
        params.append('name', value?.proj_name.trim())
        await axios({
            method: 'post',
            url: `/proj/insert`,
            data: params
        }).then((res) => {
            resultResponse(res, '创建成功')
            setVisible({ ...visible, popover: false })
            projectForm.resetFields()
        })
    }

    const onSelect = (value) => {
        let tmp = _.filter(options, e => e.value === value)
        setOrder({ ...order, name: value, project_id: tmp[0]?.id })
        message.success(`选择项目 ${value} 成功`)
        setProjectDisabled(true)
    };

    const onChange = val => setOrder({ ...order, name: val })

    const EditorUploader = () => <Dragger {...props}>
        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
        <p className="ant-upload-text">点击或者拖拽上传文本，支持doc / docx格式转码</p>
    </Dragger>

    const EditorButtons = () => <Space direction={editorStyle}>
        <Button.Group>
            <Button icon={<AlignLeftOutlined />} onClick={() => Formatter({ content, setContent })}>自动排版</Button>
            <Button icon={<SnippetsOutlined />} onClick={() => setVisible({ ...visible, preview: true })}>预览</Button>
        </Button.Group>
        <Button.Group>
            <Button onClick={() => saveLocalStorageContent(content)} icon={<SaveOutlined />}>保存草稿</Button>
            <Button onClick={() => loadLocalStorageContent()} icon={<CloudDownloadOutlined />}>读取草稿</Button>
            <Button onClick={() => {
                setOrder({ title: '', remark: '', url: '', file_url: '', name: '' });
                setContent("")
            }} icon={<ClearOutlined />}>重置文本</Button>
        </Button.Group>
    </Space>

    // 分布发稿
    const steps = [
        {
            title: '创建稿件',
            content: (
                <Card>
                    <Space direction='vertical' size={16} style={{ width: '100%' }}>
                        {
                            editorStyle === 'horizontal' &&
                            <Form {...layout}>
                                <Row gutter={[12, 12]}>
                                    <Col span={24} key='title' >
                                        <Form.Item label="订单标题">
                                            <Input placeholder="请输入订单标题"
                                                value={order?.title} onChange={setTitle} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} key='source_url' >
                                        <Form.Item label='订单来源'>
                                            <Input placeholder="请输入以https://或者http://开头的订单来源链接"
                                                value={order?.url} onChange={setUrl} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} key='remark' >
                                        <Form.Item label='订单备注'>
                                            <TextArea placeholder="请输入订单备注, 如:图片注释居中, 小标题加粗, 注意排版"
                                                value={order?.remark} rows={3} onChange={setRemark} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} key='name' >
                                        <Form.Item label="项目名称">
                                            <AutoComplete
                                                dropdownMatchSelectWidth={252}
                                                style={{ width: 300, }}
                                                options={options}
                                                value={order.name}
                                                onSelect={onSelect}
                                                onChange={onChange}
                                                onSearch={handleSearch}
                                                disabled={projectDisabled}
                                                allowClear={true}
                                            >
                                                <Input placeholder="请输入项目名称" />
                                            </AutoComplete>
                                            {
                                                projectDisabled
                                                    ? (
                                                        <Button type='link' icon={<EditOutlined />} onClick={() => {
                                                            setProjectDisabled(false)
                                                            setOrder({ ...order, project_id: undefined })
                                                        }} >编辑</Button>
                                                    )
                                                    : (
                                                        <Popover 
                                                            content={<div>
                                                                <Form
                                                                    name="projectForm"
                                                                    form={projectForm}
                                                                    labelCol={{ span: 8, }}
                                                                    wrapperCol={{ span: 16, }}
                                                                    onFinish={createProject}
                                                                >
                                                                    <Form.Item label="项目名称" name="proj_name"
                                                                        rules={[{ required: true, message: '请输入项目名称', }]}
                                                                    >
                                                                        <Input />
                                                                    </Form.Item>
                                                                    <Form.Item wrapperCol={{ offset: 8, span: 16, }}>
                                                                        <Space>
                                                                            <Button type="primary" htmlType="submit">
                                                                                提交
                                                                            </Button>
                                                                            <Button danger onClick={() => projectForm.resetFields()}>
                                                                                重置
                                                                            </Button>
                                                                        </Space>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>} 
                                                            title="创建项目"
                                                            trigger="click" 
                                                            open={visible.popover}
                                                            onOpenChange={visible => setVisible({ ...visible, popover: visible })}
                                                        >
                                                            <Button type='link' icon={<FileAddOutlined />} >创建项目</Button>
                                                        </Popover>
                                                    )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} key='sales_name' >
                                        <Form.Item label='对接人名称'>
                                            <Input placeholder="请输入对接人名称"
                                                value={order?.sales_name} onChange={setSalesName} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} key='upload'>
                                        <Form.Item label='上传文本'>
                                            <EditorUploader />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} key='format'>
                                        <EditorButtons />
                                    </Col>
                                    <Col span={12} key='next' style={{ textAlign: "right" }}>
                                        <Button type="primary" onClick={firstStepNext}>继续</Button>
                                    </Col>
                                </Row>
                            </Form >
                        }
                        {
                            editorStyle === 'vertical' &&
                            <Form layout="vertical">
                                <Row gutter={[12, 12]}>
                                    <Col span={8} key='title' >
                                        <Form.Item label="订单标题">
                                            <Input placeholder="请输入订单标题" value={order.title} onChange={setTitle} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} key='source_url' >
                                        <Form.Item label='订单来源'>
                                            <Input placeholder="请输入以https://或者http://开头的订单来源链接"
                                                value={order.url} onChange={setUrl} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} key='remark' >
                                        <Form.Item label='订单备注'>
                                            <TextArea placeholder="请输入订单备注, 如:图片注释居中, 小标题加粗, 注意排版"
                                                value={order.remark} rows={1} onChange={setRemark} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} key='name' >
                                        <Form.Item label="项目名称">
                                            <AutoComplete
                                                dropdownMatchSelectWidth={252}
                                                style={{ width: 300, }}
                                                options={options}
                                                onSelect={onSelect}
                                                onChange={onChange}
                                                onSearch={handleSearch}
                                                disabled={projectDisabled}
                                                allowClear={true}
                                            >
                                                <Input placeholder="请输入项目名称" />
                                            </AutoComplete>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} key='sales_name' >
                                        <Form.Item label='对接人名称'>
                                            <Input placeholder="请输入对接人名称"
                                                value={order?.sales_name} onChange={setSalesName} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} key='upload'>
                                        <EditorUploader />
                                    </Col>
                                    <Col span={8} key='format'>
                                        <EditorButtons />
                                    </Col>
                                    <Col span={24} key='next' style={{ textAlign: "right" }}>
                                        <Button type="primary" onClick={firstStepNext}>继续</Button>
                                    </Col>
                                </Row>
                            </Form>
                        }
                        <div id='create'>
                            <CKEditorInstance content={content} setContent={setContent} />
                        </div>
                        <Row>
                            <Col span={24} key='next' style={{ textAlign: "right" }}>
                                <Button type="primary" onClick={firstStepNext}>继续</Button>
                            </Col>
                        </Row>
                    </Space>
                </Card >
            ),
        }, {
            title: '选择媒体', // 传递选择媒体数据，搜索表格数据，是否读取完成数据
            content: (
                <>
                    <Tabs defaultActiveKey='media'
                        items={[
                            {
                                key: 'media',
                                label: <Text style={{ fontSize: 14 }}>新闻媒体</Text>,
                                children: <MediaTable action="CREATE" medias={medias} setMedias={setMedias} hasTitle={true} media_from="网络媒体" />
                            },
                            {
                                key: 'mcn',
                                label: <Text style={{ fontSize: 14 }}>自媒体</Text>,
                                children: <MediaTable action="CREATE" medias={medias} setMedias={setMedias} hasTitle={true} media_from="自媒体" />
                            },
                        ]}
                    />
                    {
                        _.size(medias) > 0
                            ? <Affix offsetBottom={24}>
                                <Card title={<Space>
                                    <div>
                                        <Text>已选择{_.size(medias)}个媒体</Text>
                                        <Divider type='vertical' />
                                        <Text>共计{_.sumBy(medias, 'price')}元</Text>
                                    </div>
                                    <div>
                                        <Button danger onClick={() => setMedias([])}>清空媒体</Button>
                                    </div>
                                </Space>}>
                                    <TagCloud medias={medias} setMedias={setMedias} />
                                    <Divider />
                                    <Row>
                                        <Col span={12}>
                                            <Button onClick={prevPage}>返回</Button>
                                        </Col>
                                        <Col span={12} style={{ textAlign: "right" }}>
                                            <Button type="primary" onClick={() => {
                                                if (order.title === '') { message.error('请填写标题后提交！'); return }
                                                if (order.url === '' && content.trim() === '') { message.error('请填写内容后提交！'); return; }
                                                if (_.size(medias.filter(media => media.status !== 1)) > 0) {
                                                    message.warning("当前选择的媒体中有处于暂停状态的媒体, 请核对");
                                                    return;
                                                }
                                                if (checkRepeat()) { nextPage() }
                                            }}>
                                                继续
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Affix>
                            : <Card style={{ marginTop: 16 }}>
                                <Row>
                                    <Col span={12}>
                                        <Button onClick={prevPage}>返回</Button>
                                    </Col>
                                    <Col span={12} style={{ textAlign: "right" }}>
                                        <Button type="primary" onClick={() => {
                                            if (_.size(medias) === 0) { message.error("请选择媒体!"); return; }
                                            if (order.title === '') { message.error('请填写标题后提交！'); return }
                                            if (order.url === '' && content.trim() === '') { message.error('请填写内容后提交！'); return; }
                                        }}>
                                            继续
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                    }
                </>
            ),
        }, {
            title: '确定下单',
            content: (
                <Space direction="vertical" size={16}>
                    <Card>
                        <Descriptions title="订单信息">
                            <Descriptions.Item label="订单标题" span={3}>{order?.title}</Descriptions.Item>
                            <Descriptions.Item label="订单备注" span={3}>{order?.remark}</Descriptions.Item>
                            <Descriptions.Item label="订单来源" span={3}>{order?.url}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                    <Card
                        title={<Space>
                            <Text>已选择{_.size(medias)}个媒体</Text>
                            <Divider type='vertical' />
                            <Text>共计{_.sumBy(medias, 'price') - discount}元</Text>
                            <Text>共计折扣{discount}元</Text>
                        </Space>}
                    >
                        <TagCloud medias={medias} setMedias={setMedias} />
                    </Card>
                    {
                        // sessionUser?.balance?.loan_state !== 1 && 
                        // <>
                        //     {_.size(medias) > 1 && <UsePackage medias={medias} packages={packages} setPackages={setPackages} setDiscount={setDiscount} />}
                        //     <UseCoupon medias={medias} coupon={coupon} setCoupon={setCoupon} />
                        // </>
                    }
                    <Card>
                        <Row>
                            <Col span={12}>
                                <Button onClick={prevPage}>返回</Button>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <Button type="primary" loading={fetching} onClick={sendOrder}>提交</Button>
                            </Col>
                        </Row>
                    </Card>
                </Space>
            ),
        }, {
            title: '提交反馈',
            content: <Feedback fetching={fetching} feedback={feedback} resetAll={resetAll} prevPage={prevPage} />,
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
            // subTitle={<Switch defaultChecked={editorStyle === 'vertical'}
            //     checkedChildren="横板" unCheckedChildren="竖版"
            //     onChange={e => {
            //         if (e) {
            //             setEditorStyle("vertical")
            //             localStorage.setItem(localStorageEditorStyleName, "vertical")
            //         } else {
            //             setEditorStyle("horizontal")
            //             localStorage.setItem(localStorageEditorStyleName, "horizontal")
            //         }
            //     }} />}
            >
                <Steps
                    current={current}
                    items={steps.map(item => ({ title: item.title }))}
                />
            </PageHeader>
            <Content style={{ margin: '24px' }}>
                <div className="steps-content">
                    {steps[current].content}
                </div>
            </Content>
            {/* 预览文章 */}
            <Modal width={870} open={visible.preview}
                onCancel={() => setVisible({ ...visible, preview: false })}
                footer={null}
            >
                <PreviewArticle order={{ ...order, content }} />
            </Modal>
        </HelmetProvider>
    )
}

export default CreateOrder

const TagCloud = ({ medias, setMedias }) => {
    function removeMedia(e) {
        setMedias(medias.filter(media => media.id !== e.id))
    }

    return <Space size={[0, 8]} wrap>
        {medias.map(e => <Tag
            closable
            color="blue"
            key={e.id}
            onClose={() => removeMedia(e)}
        >
            {e.status === 1 ? e.name : `${e.name}(媒体暂停)`}
        </Tag>)}
    </Space>
}