import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Layout, Breadcrumb } from 'antd';
import CouponsTable from './CouponsTable';
import { Link } from 'react-router-dom'
import PageHeader from '../../layout/PageHeader';

/**
 * /dashboard/coupons
 */
const Coupon = () => {
    let pageTitle = "优惠券管理"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            >
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {pageTitle}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </PageHeader>
            <Layout.Content style={{ margin: 24 }}>
                <CouponsTable />
            </Layout.Content>
        </HelmetProvider>
    )
}

export default Coupon
