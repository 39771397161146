import CreateWechatOrder from "../wechat/CreateWechatOrder"
import Wechat from "../wechat/Wechat"
import WechatOrder from "../wechat/WechatOrder"

import React from "react"
const WechatRoutesConfig = [
    {
        path: '/dashboard/wechat/order',
        component: <WechatOrder/>
    },
    {
        path: '/dashboard/wechat/order/add',
        component: <CreateWechatOrder/>
    },
    {
        path: '/dashboard/wechat',
        component: <Wechat/>
    }
]

export default WechatRoutesConfig