import React, { useState, useEffect } from 'react';
import { Form, Table, Button, Row, Col, Tabs, message, Modal, Typography, Spin, Space, Card } from 'antd';
import { SearchOutlined, ExportOutlined } from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs';
import qs from 'qs';
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import SearchForm from '../../common/SearchForm'
import { WithdrawManageTableConfig } from '../../../role/withdraw/WithdrawManageTableConfig';
import WithdrawManageSearchConfig from '../../../role/withdraw/WithdrawManageSearchConfig';
import { initFormData, tablePagination, disposeColumn, TableTitle } from '../../common/tableCommon';
import { check } from '../../../utils/permission/rules';
import WithdrawTable from './WithdrawTable';


const axios = require('axios');

const { Text } = Typography;
const layout = { layout: 'vertical' };
const initialModal = { target_id: undefined, detail: false, }

// 用户 和 打款管理员 能查看
// 用于管理用户已经申请的提现订单 
const WithdrawManageTable = () => {
    const role = useRecoilValue(userRoleState)
    const permission = {
        'module:withdraw:batch:comfirm': check(role, 'module:withdraw:batch:comfirm', null),
    }

    const [withdraws, setWithdraws] = useState([])
    const [data, setData] = useState([])
    const [status, setStatus] = useState({ state: '' })
    const [rowSelected, setRowSelected] = useState({ keys: [], rows: [] })
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(initialModal)
    const [pagination, setPagination] = useState(tablePagination)
    const [buttonLoading, setButtonLoading] = useState(false)

    const searchDatas = WithdrawManageSearchConfig({ role })
    const initialFormData = initFormData(searchDatas)

    const reloadTable = () => {
        form.submit()
        setWithdraws([]) // 重置已选项
    }
    const [form] = Form.useForm();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        _.toPairs(status).filter(e => e[1] !== '').map(e => params.append(`${e[0]}`, e[1]))
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }

        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/withDraw/all',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                let tempEntity = res.data.data
                setData(tempEntity?.map(e => { // 待处理订单可以批量处理   
                    return ({ ...e, disabled: e.state !== 'PENDING' || e.amount === 0 })
                }))
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    const confirmOrder = async (id, state) => {
        if (!buttonLoading) {
            setButtonLoading(true)
            let params = new FormData()
            params.append('ids', id === undefined ? _.join(rowSelected.keys, ',') : id)
            params.append('state', state)
            await axios({
                method: 'post',
                url: '/withDraw/confirm',
                data: params
            }).then((res) => {
                if (res.data.code === 1) {
                    message.success("已更新")
                    reloadTable()
                } else {
                    message.warning(res.data.data)
                }
                setButtonLoading(false)
            })
        }

    }

    const downloadOrders = async () => {
        setModal({ ...modal, download: true })
        await axios({
            url: '/withDraw/download/orders',
            method: 'POST',
            responseType: 'blob', // important
            data: qs.stringify({
                state: status.state,
                create_name: form.getFieldValue("create_name")
            })
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '未支付提现信息.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }

    const rowSelection = {
        selectedRowKeys: withdraws.map(e => e.id),
        onChange: (selectedRowKeys, selectedRows) => {
            setRowSelected({ keys: selectedRowKeys, rows: selectedRows })
            setWithdraws(selectedRows.map(e => {
                return {
                    id: e.id,
                    withdraw_list_id: e.withdraw_list_id
                }
            }))
        },
        getCheckboxProps: record => ({
            disabled: record.disabled,
            name: record.name,
        }),
    }

    // 切换Tabs时 进行处理
    const filterMediaStatus = (e) => {
        setRowSelected({ keys: [], rows: [] })
        switch (e) {
            case '2':
                setStatus({ state: 'PENDING' })
                break;
            case '3':
                setStatus({ state: 'PAID' })
                break;
            case '4':
                setStatus({ state: 'FAILED' })
                break;
            default:
                setStatus({ state: '' })
        }
        form.submit()
    }

    useEffect(() => {
        fetchData()
        return source.cancel('Operation canceled by the user.');
    }, [])

    // 订单操作函数
    const handleOrders = { confirmOrder, reloadTable, }

    const defaultColumns = WithdrawManageTableConfig({ handleOrders, modal, setModal })

    const localStorageColumnsName = "____WithdrawManageTableConfig____";
    disposeColumn(localStorageColumnsName, defaultColumns)

    const [columns, setColumns] = useState(defaultColumns)

    return (
        <>
            <Space direction='vertical' size={16} className='full-width'>
                <Card>
                    <Form
                        {...layout}
                        name="withdraw-form"
                        form={form}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                        initialValues={initialFormData}
                    >
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                            <Row>
                                <Col span={12} >
                                    {
                                        permission['module:withdraw:batch:comfirm'] &&
                                        <Button type="primary" loading={buttonLoading} onClick={() => confirmOrder(undefined, 'PAID')}>批量通过</Button>
                                    }
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Space>
                                        <Button onClick={() => downloadOrders()}><ExportOutlined /> 导出</Button>
                                        <Button type="primary" htmlType="submit"><SearchOutlined /> 搜索</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                    </Form>
                </Card>
                <Card>
                    <Table
                        size="small"
                        title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName}
                            menu={<Tabs defaultActiveKey="1" onChange={filterMediaStatus}
                                items={[
                                    { key: '1', label: `全部订单`, },
                                    { key: '2', label: `处理中订单`, },
                                    { key: '3', label: `已完成订单`, },
                                    { key: '4', label: `已拒绝订单`, },
                                ]}
                            />} />}
                        columns={columns.filter(e => e.checked === 1)}
                        rowSelection={rowSelection}
                        rowKey={record => record.id}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={(pagination) => {
                            setPagination(pagination)
                            form.submit()
                        }}
                        summary={pageData => <Summary pageData={pageData} rowSelected={rowSelected} />}
                    />
                </Card>
            </Space>

            <Modal
                title="订单详情"
                open={modal.detail}
                width={1200}
                footer={null}
                onCancel={() => setModal({ ...modal, detail: false })}
                destroyOnClose={true}
            >
                <WithdrawTable withdraw_list_id={modal.target_id} withdraw_type={modal.type} detail={true} />
            </Modal>

            <Modal
                open={modal.download}
                footer={null}
                onOk={() => setModal({ ...modal, download: false })}
                onCancel={() => setModal({ ...modal, download: false })}
            >
                <div style={{ textAlign: 'center' }}>
                    <Spin tip="下载中..." />
                </div>
            </Modal>
        </>
    )
}


export default WithdrawManageTable

const Summary = ({ pageData, rowSelected }) => {
    let total = _.sumBy(pageData, 'money')

    return <tr>
        <td />
        <th>汇总</th>
        <td colSpan={7}>
            <Space>
                <Space>
                    <Text>当前页面共: {_.size(pageData)} 条</Text>
                    <Text type="danger">当前页面共: {total.toFixed(2)} 元</Text>
                </Space>
                {
                    _.size(rowSelected.rows) > 0 &&
                    <Space>
                        <Text strong>已选择共: {_.size(rowSelected.rows)} 条</Text>
                        <Text type="danger">已选择共: {_.sumBy(rowSelected.rows, 'money').toFixed(2)} 元</Text>
                    </Space>
                }
            </Space>
        </td>
    </tr>
}