const FinanceInconsumeTableConfig = ({ tab }) => {

    let result = []
    switch (tab) {
        case "media":
            result = [
                {
                    title: '媒体名称',
                    dataIndex: 'media_name',
                    key: 'media_name'
                }, {
                    title: '成本',
                    dataIndex: 'abs_cost',
                    key: 'abs_cost',
                }, {
                    title: '相对成本',
                    dataIndex: 'rel_cost',
                    key: 'rel_cost',
                }, {
                    title: '总利润',
                    dataIndex: 'total_paid',
                    key: 'total_profit',
                    render: (t, data) => data?.rel_cost - data?.abs_cost
                }, {
                    title: '总付款金额',
                    dataIndex: 'total_paid',
                    key: 'total_paid',
                }, {
                    title: '订单数',
                    dataIndex: 'total_num',
                    key: 'total_num',
                    sorter: (a, b) => null,
                }, {
                    title: '未结算订单数',
                    dataIndex: 'unpaid_num',
                    key: 'unpaid_num',
                    sorter: (a, b) => null,
                }, {
                    title: '未结算金额',
                    dataIndex: 'unpaid_money',
                    key: 'unpaid_money',
                    sorter: (a, b) => null,
                },
            ]
            break;
        case 'sales':
            result = [
                {
                    title: '销售名',
                    dataIndex: 'account',
                    key: 'account'
                }, {
                    title: '所属主管',
                    dataIndex: 'mananger_name',
                    key: 'mananger_name'
                }, {
                    title: '成本',
                    dataIndex: 'rel_cost',
                    key: 'rel_cost',
                }, {
                    title: '总营收',
                    dataIndex: 'total_paid',
                    key: 'total_paid',
                }, {
                    title: '总收益',
                    dataIndex: 'profit',
                    key: 'profit',
                    render: (val, data) => {
                        return data.total_paid - data.rel_cost
                    }
                }, {
                    title: '订单数',
                    dataIndex: 'total_num',
                    key: 'total_num',
                }
            ]
            break;
        default:
            break;
    }
    return result
}

export default FinanceInconsumeTableConfig