import React, { useState, useEffect } from 'react';
import {
    PlusOutlined
} from '@ant-design/icons';
import { Table, Row, Col, Button, Drawer, message, Layout, Space } from 'antd';
import _ from 'lodash'
import qs from 'qs';
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../atoms/Atoms'
import EventTableConfig from '../../../role/EventTableConfig';
import AddEventForm from './AddEventForm';
import MediaTable from '../medias/MediaTable';
import { initFormData, tablePagination } from '../../common/tableCommon';
import EventRulesTable from './EventRulesTable';
import CollectionDetailTable from './CollectionDetailTable';
import { resultResponse } from '../../common/ResultResponse';

const axios = require('axios');
const { Content } = Layout
const initialEvent = {
    id: '',
    name: '',
    start_time: '',
    end_time: '',
    price: '',
    target: '',
    description: '',
    type: 'event'
}


const EventTable = () => {

    const role = useRecoilValue(userRoleState)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectId, setSelectId] = useState('')
    const [pagination, setPagination] = useState(tablePagination)
    const [medias, setMedias] = useState([])
    const [count, setCount] = useState(0)
    const [visible, setVisible] = useState({
        addEvent: false,
        addMedias: false,
        delMedias: false,
        rules: false,
        action: 'ADD'
    })

    const [event, setEvent] = useState(initialEvent)

    const reloadTable = () => setCount(count + 1)
    const resetEvent = () => setEvent(initialEvent)
    const resetMedias = () => setMedias([])

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async (params = new FormData()) => {
        setLoading(true)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        await axios({
            method: 'post',
            url: '/event/api/getEventList',
            data: params
        }, {
            cancelToken: source.token
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
            let total = res.data.total
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    useEffect(() => {
        fetchData()
        return source.cancel('Operation canceled by the user.');
    }, [pagination.current, pagination.pageSize, count])

    // 创建活动
    const createEvent = async () => {
        await axios({
            method: 'post',
            url: '/event/add',
            data: qs.stringify(event)
        }).then((res) => {
            if (res.data.code === '200') {
                message.success("已成功添加")
                resetEvent()
                reloadTable()
            } else {
                message.error(res.data.message)
            }

        })
    }

    // 修改活动
    const updateEvent = async () => {
        await axios({
            method: 'post',
            url: '/event/modify',
            data: qs.stringify(event)
        }).then((res) => {
            resultResponse(res, "已成功")
            resetEvent()
            reloadTable()
        })
    }

    // 获取活动信息
    const getEvent = async (id) => {
        setVisible({ ...visible, addEvent: true, action: 'EDIT' })
        await axios({
            method: 'post',
            url: `/event/api/getEventList`,
            data: qs.stringify({ id, length: 1 })
        }).then((res) => {
            let tempMedia = res.data.data[0]
            setEvent(_.pick(tempMedia, _.keysIn(event)))
        })
    }

    // 打开活动添加媒体页面 并读取以添加媒体
    const eventAddMedia = async (id) => {
        await axios({
            method: 'post',
            url: `/eventMedia/select/${id}`
        }).then((res) => {
            const datas = res.data.data
            setMedias(datas.map(e => ({ id: e.media_id, })))
        })
        setVisible({ ...visible, addMedias: true, action: 'ADD' })
    }
    // 查看或者移除活动媒体
    const eventDelMedia = async (id) => {
        setVisible({ ...visible, delMedias: true, action: 'ADD' })
    }

    // 添加活动媒体
    const addEventMedias = async () => {
        let params = new FormData()
        params.append("event_id", selectId)
        params.append("ids", medias.map(e => e.id))
        await axios({
            method: 'post',
            url: `/eventMedia/add`,
            data: params
        }).then((res) => {
            resultResponse(res, "已成功")
            resetMedias()
            reloadTable()
        })
    }
    // 移除活动
    const delEvent = async (id) => {
        await axios({
            method: 'delete',
            url: `/event/${id}`
        }).then((res) => {
            if (res.data.ajaxResponse.code === '200') {
                message.success("已成功删除")
                reloadTable()
            } else {
                message.error(res.data.ajaxResponse.message)
            }
        })
    }
    // 完成活动
    const finishEvent = async (id) => {
        await axios({
            method: 'post',
            url: `/billboard/save/${id}`
        }).then((res) => {
            resultResponse(res, "已成功")
            reloadTable()
        })
    }

    // 查看或者移除活动媒体
    const eventCheckRule = () => setVisible({ ...visible, rules: true, action: 'ADD' })

    const getTitle = () => {
        return visible.action === 'EDIT' ? '编辑活动' : '添加活动'
    }
    // 修改活动状态
    const handleStatus = async (id, key, val) => {
        await axios({
            method: 'post',
            url: `/event/modify`,
            data: qs.stringify({ id, [key]: val })
        }).then((res) => {
            resultResponse(res, "已成功")
            reloadTable()
        })
    }

    const updateProirity = async (id, action) => {
        await axios({
            method: 'post',
            url: `/event/priority/${id}`,
            data: qs.stringify({ action })
        }).then((res) => {
            resultResponse(res, "已成功修改")
            reloadTable()
        })
    }

    const rowOnSelect = (record, selected) => {
        let tempMedia = { id: record.id }
        let newMedias = selected ? _.concat(medias, tempMedia) : _.differenceBy(medias, [tempMedia], 'id');
        setMedias(_.uniqBy(newMedias, 'id'))
    }

    const handleFunctions = {
        getEvent, delEvent, eventAddMedia,
        eventDelMedia, handleStatus,
        finishEvent, updateProirity, eventCheckRule
    }
    const columns = EventTableConfig({ handleFunctions, setSelectId })
    const drawerAction = { visible, setVisible, createEvent, updateEvent }

    return <>
        <Content className='table-search'>
            <Table
                size='middle'
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                }}
                title={() => (
                    <>
                        <Row>
                            <Col span={12}>
                            </Col>
                            <Col span={12} style={{ textAlign: 'end' }}>
                                {(role === 'SALE' || role === 'FRANCHISE' || role === 'OPERATOR') &&
                                    <Button.Group>
                                        <Button type="primary" onClick={() => setVisible({ ...visible, addEvent: true, type: '' })}>
                                            <PlusOutlined />添加活动
                                    </Button>
                                    </Button.Group>}
                            </Col>
                        </Row>
                    </>
                )}
            />
        </Content>


        <Drawer
            title={getTitle()}
            width={960}
            onClose={() => {
                setVisible({ ...visible, addEvent: false })
                resetEvent()
            }}
            open={visible.addEvent}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <AddEventForm event={event} type="event" drawerAction={drawerAction} />
        </Drawer>
        {/* 添加媒体 */}
        <Drawer
            title='添加媒体'
            width={1170}
            onClose={() => setVisible({ ...visible, addMedias: false })}
            open={visible.addMedias}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <MediaTable medias={medias} setMedias={setMedias} rowOnSelect={rowOnSelect}
                menu={false} simpleMode={true} />
            <Space className="side-drawer">
                <Button onClick={() => setVisible({ ...visible, addMedias: false })} >
                    取消
                </Button>
                <Button onClick={() => {
                    addEventMedias()
                    setVisible({ ...visible, addMedias: false })
                }} type="primary">
                    提交
                </Button>
            </Space>
        </Drawer>

        <Drawer
            title='查看媒体'
            width={1170}
            onClose={() => setVisible({ ...visible, delMedias: false })}
            open={visible.delMedias}
        >
            <CollectionDetailTable initialEventId={selectId} />
        </Drawer>

        <Drawer
            title='查看规则'
            width={870}
            onClose={() => setVisible({ ...visible, rules: false })}
            open={visible.rules}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <EventRulesTable event_id={selectId} />
        </Drawer>
    </>;
}

export default EventTable