import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Table, Modal, Space, Card } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash'
import { SearchOutlined } from '@ant-design/icons';

import SearchForm from '../../../common/SearchForm'
import { tableConfig, searchConfig as searchDatas } from './NotificationConfig';
import MediaChangeHistory from './MediaChangeHistory'
import { initFormData, tablePagination } from '../../../common/tableCommon';

const axios = require('axios');

const seartchLayout = { lg: 24, xl: 12 };
const layout = { layout: 'vertical' };


const NotificationsTable = () => {

    const initialFormData = initFormData(searchDatas)

    const [datas, setDatas] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const [modal, setModal] = useState({ content: false })
    const [form] = Form.useForm();

    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, _.trimStart(e[1]) ?? ''))
        }

        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: `/notifications/notificationList`,
                data: params
            }, {
                cancelToken: source.token
            }).then(res => {
                let data = res.data.data
                let total = res.data.total
                setDatas(data)
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
            setLoading(false)
        }
    }


    const checkHistory = (id) => setModal({ ...modal, id: id, history: true })
    const handleFns = { checkHistory }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])


    return (
        <>
            <Space direction='vertical' size={16} className='full-width'>
                <Card>
                    <Form
                        {...layout}
                        name="basic"
                        form={form}
                        initialValues={initialFormData}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                    >
                        <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                        <Row>
                            <Col {...seartchLayout} />
                            <Col  {...seartchLayout} style={{ textAlign: 'right' }}>
                                <Button type="primary" htmlType="submit"><SearchOutlined /> 搜索</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Card>
                    <Table
                        columns={tableConfig({ handleFns })}
                        size={'small'}
                        rowKey={record => record.id}
                        dataSource={datas}
                        pagination={pagination}
                        loading={loading}
                        onChange={(pagination) => { setPagination(pagination); form.submit() }}
                    />
                </Card>
            </Space>
            <Modal
                title="修改记录" open={modal.history}
                footer={null}
                onCancel={() => setModal({ ...modal, history: false })}
            >
                <MediaChangeHistory id={modal.id} />
            </Modal>
        </>
    )
}

export default NotificationsTable;