export const CommonMediaIndex = {
    id: '',
    name: '',
    sample_url: '',
    cost: '',
    sale_price: '',
    agent_price: '',
    comment: '',
    editor_name: '',
    editor_tel: '',
    status: '',
    profession: '新闻资讯',
    category: '中国五大门户',
    location: '',
    url: '',
    area: '',
}