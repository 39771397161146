import React from 'react'
import _ from 'lodash'
import { Button, Tag, Typography, Rate } from 'antd'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'
const { Text, Paragraph } = Typography
const MissionTableConfig = ({ handleMedia }) => {
    const role = useRecoilValue(userRoleState)

    const { acceptMission, completeMission } = handleMedia

    const couponCol = [
        {
            title: '任务类型',
            dataIndex: 'type',
            render: (t) => {
                return t === 1
                    ? <Text>日常任务</Text>
                    : <Text>特殊任务</Text>
            }
        }, {
            title: '详情',
            dataIndex: 'name',
            render: (t, data) => {
                let category = ""
                switch (data.item_id) {
                    case 1:
                        category = "篇"
                        break;
                    case 2:
                        category = "元"
                        break;
                    default:
                        break;
                }
                return <>
                    <Paragraph>
                        {t}{data.target_num}{category}
                    </Paragraph>
                    <Paragraph>
                        可获得{data.points}点经验值
                    </Paragraph>
                </>
            }
        }, {
            title: '进度',
            dataIndex: 'available_nums',
            render: (t, data) => {
                let category = ""
                switch (data.item_id) {
                    case 1:
                        category = "篇"
                        break;
                    case 2:
                        category = "元"
                        break;
                    default:
                        break;
                }
                let result = `已完成${t}${category}`
                let length = _.split(data.points, '/').filter(e => e > t).length;
                let star = <Rate value={3 - length} disabled count={3} />

                return <>
                    {star}
                    <br />
                    {result}
                </>
            }
        }, {
            title: '状态',
            dataIndex: 'id',
            render: (t, data) => {
                if (data.pending === 1) {
                    if (data.complete === 1) {
                        return < Tag color='green'> 已完成</Tag >
                    } else {
                        return < Tag color='gold'> 未完成</Tag >
                    }
                } else {
                    return < Tag color='red'> 未接受</Tag >
                }

            }
        }, {
            title: '操作',
            dataIndex: 'id',
            render: (t, data) => {
                return (
                    <>
                        <Button.Group>
                            {data.pending === 1
                                ? <Button type='primary' onClick={() => completeMission(data.id)}>完成任务</Button>
                                : <Button onClick={() => acceptMission(data.id)}>接受任务</Button>
                            }
                        </Button.Group>
                    </>
                )
            }
        },
    ]


    let result = []
    switch (role) {
        default:
            result = _.concat(
                couponCol,
            )
            break;
    }
    return result
}
export default MissionTableConfig;
