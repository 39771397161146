import React, { useState, useEffect } from 'react';
import { Table, Form, Layout, Row, Col, Button, Modal, Input, Tabs, Space, Typography, Card } from 'antd';
import _ from 'lodash'
import qs from 'qs';

import { initFormData, tablePagination } from '../../common/tableCommon';
import InvoiceSearchConfig from '../../../role/finance/InvoiceSearchConfig'
import SearchForm from '../../common/SearchForm'
import InvoiceTableConfig from '../../../role/finance/InvoiceTableConfig'
import { resultResponse } from '../../common/ResultResponse';
import { number2currency } from '../../../utils/NumberUtils';

const { Content } = Layout;
const { Text } = Typography

const axios = require('axios');

const layout = { layout: 'vertical' };
const alipayUrl = 'https://mapi.alipay.com/gateway.do?_input_charset=utf-8'

const InvoicesTable = () => {
    const searchDatas = InvoiceSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const [form] = Form.useForm()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState({})
    const [express, setExpress] = useState('')
    const [state, setState] = useState('')
    const [invoices, setInvoices] = useState([])
    const [datas, setDatas] = useState(initialFormData)

    const reloadTable = () => form.submit()
    const resetModal = () => setModal({})

    const fetchData = async (val) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        params.append('state', state)
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/invoice/getInvoicesList',
                data: params
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }

    }

    const sendExpress = async (id) => {
        await axios({
            method: 'post',
            url: "/invoice/express",
            data: qs.stringify({ id, express_number: express })
        }).then(res => {
            resultResponse(res, '已寄出')
            resetModal()
            reloadTable()
        })
    }

    const payTax = async (id, tax) => {
        await axios({
            method: 'post',
            url: '/pay/tax',
            data: qs.stringify({ id: id, amount: tax })
        }).then(res => {
            setDatas(res.data.data.data)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (datas !== initialFormData) {
            document.getElementById('alipayForm').submit()
        }
    }, [datas])


    const PayForm = ({ obj }) => {
        let data = _.toPairs(obj)
        return <>
            <form id='alipayForm' action={alipayUrl} method='POST'>
                {
                    data.map(e => <input type='hidden' name={e[0]} value={e[1]} key={e[0]}></input>)
                }
            </form>
        </>
    }


    const sendExpressModal = (id) => setModal({ ...modal, express: true, id: id })

    const handleMedia = { reloadTable, sendExpressModal, payTax }
    const columns = InvoiceTableConfig({ handleMedia })

    return (
        <Space direction='vertical' size={16} style={{ width: '100%' }}>
            {
                _.size(searchDatas) > 0 &&
                <Content>
                    <Card>
                        <Form
                            {...layout}
                            name="basic"
                            form={form}
                            onFinish={val => fetchData(_.pickBy(val, _.identity))}
                        >
                            <>
                                <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                                <Row>
                                    <Col span={12} />
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <Button type="primary" htmlType="submit">搜索</Button>
                                    </Col>
                                </Row>
                            </>
                        </Form>
                    </Card>
                </Content>
            }
            <Card>
                <Tabs defaultActiveKey=""
                    onChange={e => {
                        setState(e)
                        form.submit()
                    }}
                    items={[
                        { key: '', label: `全部稿件`, },
                        { key: 'UNPAID', label: `未支付`, },
                        { key: 'PREPAID', label: `未付款`, },
                        { key: 'PENDING', label: `待确认`, },
                        { key: 'IN_TRANSIT', label: `邮寄中`, },
                        { key: 'PRE_IN_TRANSIT', label: `后款邮寄中`, },
                        { key: 'PAID', label: `已付款`, },
                        { key: 'SUCCESS', label: `已完成`, },
                        { key: 'INVALID', label: `已撤回`, },
                    ]}
                />
                <Table
                    size='small'
                    columns={columns}
                    rowSelection={{
                        columnWidth: '24px',
                        selectedRowKeys: invoices.map(e => e.id),
                        onSelect: (record, selected) => {
                            let tempData = {
                                id: record.id,
                                real_money: record.real_money,
                                money: record.money,
                                tax_money: record.tax_money
                            }
                            let newData = selected ? _.concat(invoices, tempData) : _.differenceBy(invoices, [tempData], 'id');
                            setInvoices(_.uniqBy(newData, 'id'))
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            let tempData = changeRows.map(e => ({
                                id: e.id,
                                real_money: e.real_money,
                                money: e.money,
                                tax_money: e.tax_money
                            }))
                            let newData = selected ? _.concat(invoices, tempData) : _.differenceBy(invoices, tempData, 'id');
                            setInvoices(_.uniqBy(newData, 'id'))
                        },
                        getCheckboxProps: record => ({
                            disabled: false,
                            name: record.name,
                        }),
                    }}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                    summary={pageData => {

                        let totalMoney = _.sum(_.map(pageData, e => e.real_money ?? e.money + e.tax_money));
                        let selectMoney = _.sum(_.map(invoices, e => e.real_money ?? e.money + e.tax_money));

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell colSpan={2} />
                                    <Table.Summary.Cell>总共</Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text type="danger">{number2currency(totalMoney)}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell colSpan={9} />
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell colSpan={2} />
                                    <Table.Summary.Cell>已选择</Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text type="danger">{number2currency(selectMoney)}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell colSpan={9} />
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                />
                <PayForm obj={datas}></PayForm>
            </Card>

            <Modal
                title="快递单号" open={modal?.express}
                footer={null}
                onCancel={() => {
                    resetModal()
                    setExpress('')
                }}
            >
                <Space>
                    <Input onChange={e => setExpress(e.target.value)} placeholder='请输入快递单号' />
                    <Button type='primary' onClick={() => sendExpress(modal.id)}>提交</Button>
                </Space>
            </Modal>
        </Space>
    )
}

export default InvoicesTable