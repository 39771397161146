import React, { useState, useEffect } from 'react'
import { Modal, Popconfirm, List, message, Typography, Button, Space } from 'antd';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import WithdrawInfo from '../money/WithdrawInfo';
import { resultResponse } from '../../common/ResultResponse';
import { check } from '../../../utils/permission/rules';
import { userRoleState } from '../../atoms/Atoms'

const { Text, Title } = Typography
const UserAdvancedForm = ({ sessionUser }) => {

    const [modal, setModal] = useState({ alipay: false })
    const [access, setAccess] = useState({})

    const role = useRecoilValue(userRoleState)

    const permission = {
        'module:user:api': check(role, 'module:user:api', null),
        'module:user:code': check(role, 'module:user:code', null),
        'module:user:wechat:unique_key': check(role, 'module:user:wechat:unique_key', null)
    }

    const hasAlipay = sessionUser.basic.alipay_id || sessionUser.basic.alipay_name

    const updateUserCode = async () => {
        await axios.get("/user/update/code")
            .then(res => resultResponse(res, '已成功修改'))
    }

    const updateUserUniqueKey = async () => {
        await axios.get("/user/update/unique_key")
            .then(res => resultResponse(res, '已成功修改'))
    }

    const getUserAccess = async () => {
        await axios.get("/auth/get")
            .then(res => setAccess(res.data.data))
    }

    const insertUserAccess = async () => {
        await axios.get("/auth/create/access")
            .then(res => resultResponse(res, '成功'))
    }
    const updateUserAccess = async () => {
        await axios.get("/auth/refresh/access")
            .then(res => resultResponse(res, '成功'))
    }

    useEffect(() => {
        getUserAccess()
    }, [])


    return (
        <div style={{ flex: '1' }} >
            <List bordered={false}>
                <List.Item>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Title level={4}>支付宝信息</Title>
                        {
                            hasAlipay
                                ? <Space>
                                    <div>
                                        <Text>当前支付宝账号: </Text>
                                        <Text code>{sessionUser.basic.alipay_id}</Text>
                                    </div>
                                    <div>
                                        <Text>当前支付宝姓名: </Text>
                                        <Text code>{sessionUser.basic.alipay_name}</Text>
                                    </div>
                                </Space>
                                : <div>
                                    <Text>当前未绑定支付宝账号</Text>
                                </div>
                        }
                    </div>
                    <Space>
                        <Button type='link' onClick={() => setModal({ ...modal, alipay: true })}>{hasAlipay ? '更改支付宝信息' : '绑定支付宝'}</Button>
                    </Space>
                </List.Item>
                {
                    permission['module:user:api'] && <List.Item>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Title level={4}>API接口</Title>
                            {
                                access
                                    ? <Space>
                                        <div>
                                            <Text>当前ACCESS_KEY_ID: </Text>
                                            <Text code>{access?.app_id}</Text>
                                        </div>
                                        <div>
                                            <Text>当前ACCESS_KEY_SECRET: </Text>
                                            <Text code>{access?.app_secret}</Text>
                                        </div>
                                    </Space>
                                    : <div>
                                        <Text>当前未申请API接口</Text>
                                    </div>
                            }
                        </div>
                        <Space>
                            <Button type='link' onClick={() => access ? updateUserAccess() : insertUserAccess()}>{access ? '更新密钥' : '生成密钥'}</Button>
                        </Space>
                    </List.Item>
                }
                {
                    permission['module:user:code'] &&
                    <List.Item>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Title level={4}>邀请码</Title>
                            <div>
                                <Text>当前邀请码: </Text>
                                <Text code>{sessionUser.basic.code}</Text>
                            </div>
                        </div>
                        <Space>
                            <Popconfirm
                                title="是否确认更新邀请码？"
                                onConfirm={() => updateUserCode()}
                                onCancel={() => message.warn('已取消')}
                                okText="更新"
                                cancelText="取消"
                            >
                                <Button type='link'>更新邀请码</Button>
                            </Popconfirm>
                            <CopyToClipboard text={sessionUser.basic.code} onCopy={() => message.success('已成功复制')}>
                                <Button type='link'>复制邀请码</Button>
                            </CopyToClipboard>
                        </Space>
                    </List.Item>
                }
                {
                    permission['module:user:wechat:unique_key'] &&
                    <List.Item>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Title level={4}>微信绑定码</Title>
                            <div>
                                <Text>当前微信绑定码: </Text>
                                <Text code>{sessionUser.basic.unique_key}</Text>
                            </div>
                        </div>
                        <Space>
                            <Popconfirm
                                title="是否确认更新微信绑定码？"
                                onConfirm={() => updateUserUniqueKey()}
                                onCancel={() => message.warn('已取消')}
                                okText="更新"
                                cancelText="取消"
                            >
                                <Button type='link'>更新绑定码</Button>
                            </Popconfirm>
                            <CopyToClipboard text={sessionUser.basic.unique_key} onCopy={() => message.success('已成功复制')}>
                                <Button type='link'>复制绑定码</Button>
                            </CopyToClipboard>
                        </Space>
                    </List.Item>
                }
            </List>
            <Modal
                width={650}
                open={modal.alipay} footer={null}
                onOk={() => setModal({ ...modal, alipay: false })}
                onCancel={() => setModal({ ...modal, alipay: false })}
            >
                <WithdrawInfo />
            </Modal>
        </div >
    );
}


export default UserAdvancedForm