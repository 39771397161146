import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import EarnOrderTable from './EarnOrderTable';

import PageHeader from '../../layout/PageHeader';
const { Content } = Layout;

const EarnOrders = () => {
    let pageTitle = "收益订单"

    const [tab, setTab] = useState("media")

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: 'media', label: `新闻媒体`, },
                            { key: 'baike', label: `百科订单`, },
                            { key: 'index', label: `文字链订单`, },
                        ]}
                    />
                }
                subTitle="用于结算所属客户收益"
            />
            <Content>
                <div style={{ margin: 24 }}>
                    {tab === "media" && <EarnOrderTable />}
                </div>
            </Content>
        </HelmetProvider>
    )
}

export default EarnOrders;