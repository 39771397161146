import React, { useState, useEffect } from 'react';
import {
    Table, Button, Row, Col, Modal, Tabs, Layout, Card,
    message, Input, Select, Upload, Form, Typography, Spin, Space, Badge
} from 'antd';
import { SearchOutlined, ExportOutlined, UploadOutlined } from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs';
import qs from 'qs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRecoilValue } from 'recoil';

import { userRoleState, userState } from '../../atoms/Atoms'
import SearchForm from '../../common/SearchForm'
import MediaIndexOrderSearchConfig from '../../../role/index/MediaIndexOrderSearchConfig'
import { TimeSearchButtonGroup, initFormData, tablePagination, TableTitle } from '../../common/tableCommon';
import { MediaIndexOrderTableConfig } from '../../../role/index/MediaIndexOrderTableConfig';
import { resultResponse } from '../../common/ResultResponse';
import RoleModule from '../../../utils/permission/RoleModule';

const axios = require('axios');
const { Content } = Layout;
const { Text, Paragraph } = Typography;

const { Option } = Select;
const { TextArea } = Input;

const layout = { layout: 'vertical' }
const seartchLayout = { lg: 24, xl: 12 };
const initialModal = {
    target_id: undefined,
    verifyFailed: false,
    publishSuccess: false,
    publishOrderFailed: false,
    updateUrl: false,
    recallBox: false,
    errorBox: false,
    download: false
}

const MediaIndexOrderTable = ({ searchFormDisplay = true, url, action, miniMode = false }) => {
    const sessionUser = useRecoilValue(userState)
    const role = useRecoilValue(userRoleState)

    const searchDatas = MediaIndexOrderSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState('1')
    const [pagination, setPagination] = useState(tablePagination)
    const [status, setStatus] = useState('')
    const [modal, setModal] = useState(initialModal)
    const [rowSelected, setRowSelected] = useState({ keys: [], rows: [] })
    const [text, setText] = useState("")
    const [selector, setSelector] = useState("")
    const [num, setNum] = useState([])

    const [form] = Form.useForm();

    const reloadTable = () => form.submit()
    const resetModal = () => { setModal(initialModal); setText(""); setSelector("") } // 重置弹出框 以及 文本框

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        status && params.append(`status`, status)
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: url || '/mediaIndex/order/myList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                let tmpNum = res.data.extra
                setNum(_.reduce(tmpNum, (result, e) => _.assign(result, { [e.state]: e.total }), {}))
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }

    }

    // 订单审核通过
    const verifyOrderSuccess = async (id) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/verify",
            data: qs.stringify({
                ids: id === undefined ? _.join(rowSelected.keys, ',') : id,
                status: "waitPublish"
            })
        }).then(res => {
            resultResponse(res, "已通过审核")
            reloadTable()
        })
    }
    // 订单审核不通过
    const verifyOrderFailed = async (id, info) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/verify",
            data: qs.stringify({
                ids: id === undefined ? _.join(rowSelected.keys, ',') : id,
                status: "notPass",
                reason: info
            })
        }).then(res => {
            resultResponse(res, "未通过审核")
            reloadTable()
        })
    }
    // 订单发布回执
    const publishOrderSuccess = async (id, val) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/publish",
            data: qs.stringify({
                order_ids: id,
                status: 'success',
                reason: val['reason'],
                image_url: val['image_url'],
            })
        }).then(res => {
            resultResponse(res, "已发布回执")
            reloadTable()
            resetModal()
        })
    }
    // 订单退稿回执
    const publishOrderFailed = async (id, info) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/publish",
            data: qs.stringify({
                order_ids: id,
                status: "back",
                reason: info,
            })
        }).then(res => {
            resultResponse(res, "已成功退稿")
            resetModal()
            reloadTable()
        })
    }
    // 订单修改回执
    const updateUrl = async (id, val) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/update",
            data: qs.stringify({
                id: id,
                back_info: val['reason'],
                back_info_img: val['image_url'],
            })
        }).then(res => {
            if (res.data.code === '200') {
                message.success("已成功修改回执")
                reloadTable()
                resetModal()
            } else {
                message.error(res.data.message)
            }
        })
    }
    // 订单结算状态修改
    const updatePayinfo = async (id, status) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/pay",
            data: qs.stringify({
                ids: id === undefined ? _.join(rowSelected.keys, ',') : id,
                paid_status: status === 'paid' ? 'unpaid' : 'paid'
            })
        }).then(res => {
            if (res.data.code === '200') {
                message.success("已修改")
                reloadTable()
            }
        })
    }
    // 已发布订单 退稿
    const rejectOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/reject",
            data: qs.stringify({ id: id })
        }).then(res => {
            if (res.data.code === '200') {
                message.success("已成功退稿")
            } else {
                message.error(res.data.message)
            }
            reloadTable()
        })
    }
    // 待审核 订单 撤回
    const cancelOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/cancel",
            data: qs.stringify({ id: id })
        }).then(res => {
            if (res.data.code === '200') {
                message.success("已撤回")
            } else {
                message.error(res.data.message)
            }
            reloadTable()
        })
    }
    // 待审核 订单申请撤回
    const recallOrder = async (id, info) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/recall",
            data: qs.stringify({
                id: id,
                recall_type: selector,
                recall_reason: info
            })
        }).then(res => {
            if (res.data.code === '200') {
                message.success("已提交撤回申请")
            } else {
                message.error(res.data.message)
            }
            reloadTable()
        })
    }
    // 已发布 订单异常申请
    const errorOrder = async (id, info) => {
        await axios({
            method: 'post',
            url: "/mediaIndex/order/err",
            data: qs.stringify({
                id: id,
                err_type: selector,
                err_reason: info
            })
        }).then(res => {
            if (res.data.code === '200') {
                message.success("已提交异议申请")
            } else {
                message.error(res.data.message)
            }
            reloadTable()
        })
    }

    const downloadOrders = async () => {
        setModal({ ...modal, download: true })
        let val = _.pickBy(form.getFieldsValue(), _.identity);
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        await axios({
            url: action === 'EDIT'
                ? `/mediaIndex/order/download_user_order?${qs.stringify(_.assign(val, status))}`
                : `/mediaIndex/order/downLoadOrder?${qs.stringify(_.assign(val, status))}`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '订单详情.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }
    // 订单操作函数
    const handleOrders = {
        verifyOrderSuccess, updatePayinfo, cancelOrder,
        recallOrder, errorOrder, reloadTable, rejectOrder,
    }

    const rowSelection = {
        selectedRowKeys: rowSelected.keys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowSelected({ keys: selectedRowKeys, rows: selectedRows })
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
        selections: rowSelected.rows
    };

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])


    //--------------- 批量处理 -----------------------------------------
    const multiCopy = () => {
        let result = ''
        rowSelected.rows.map(data => {
            let copyUrl = data.source_url === "" ? `http://p.yunzitui.com/post/${data.order_no}` : data.source_url
            let copyText = `媒体：${data.mediaIndex_name}\n标题：${data.title}\n地址：${copyUrl}\n\n`
            let copyTextWithRemark = `媒体：${data.mediaIndex_name}\n标题：${data.title}\n地址：${copyUrl}\n备注：${data.remarks}\n\n`
            result += (data.remarks === '' || data.remarks === null) ? copyText : copyTextWithRemark
            return null
        })
        return result
    }

    const filterMediaStatus = (e) => {
        setTab(e)
        setRowSelected({ keys: [], rows: [] })
        switch (e) {
            case '2':
                setStatus('WAIT_VERIFY')
                break;
            case '3':
                setStatus('WAIT_PUBLISH')
                break;
            case '4':
                setStatus('SUCCESS')
                break;
            case '5':
                setStatus('BACK')
                break;
            case '6':
                setStatus('RECALL')
                break;
            case '7':
                setStatus('ERROR')
                break;
            default:
                setStatus('')
        }
        form.submit()
    }

    let defaultColumns = MediaIndexOrderTableConfig({ modal, setModal, handleOrders, tab, action })
    const localStorageColumnsName = "____MediaIndexOrderTableConfig____";
    let localStorageColumns = JSON.parse(localStorage.getItem(localStorageColumnsName))
    _.forEach(defaultColumns, (e) => {
        _.forEach(localStorageColumns, (val) => {
            if (e.title === val.title) {
                e.checked = val.checked
            }
        })
    })
    const [columns, setColumns] = useState(defaultColumns)

    return <>
        <Space direction='vertical' size={16} className='full-width'>
            <Content className={!searchFormDisplay && 'hidden'}>
                <Card title='媒体搜索'>
                    <Form
                        {...layout}
                        name="basic"
                        form={form}
                        initialValues={initialFormData}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                    >
                        {searchFormDisplay &&
                            <>
                                <SearchForm searchDatas={searchDatas} />
                                <Row>
                                    <Col {...seartchLayout} >
                                        <RoleModule action='module:media:order:toolbar' data={{ action }}>
                                            <Space>
                                                {tab === '2' &&
                                                    <Button.Group >
                                                        <Button type="primary" onClick={() => { verifyOrderSuccess(modal.target_id) }}>批量通过</Button>
                                                        <Button danger style={{ margin: '0 8px 0 0' }}
                                                            onClick={() => { setModal({ ...modal, verifyFailed: true }) }}>批量不通过</Button>
                                                    </Button.Group>}
                                                {tab === '3' && <Button danger >批量退稿</Button>}
                                                {(tab === '4' && sessionUser.role.attache === 0) && <Button >批量结款</Button>}
                                                <CopyToClipboard text={multiCopy()} onCopy={() => message.success('已成功复制')}>
                                                    <Button type='link'>批量复制</Button>
                                                </CopyToClipboard>
                                            </Space>
                                        </RoleModule>
                                    </Col>
                                    <Col  {...seartchLayout} style={{ textAlign: 'right' }}>
                                        <Space>
                                            <TimeSearchButtonGroup form={form} />
                                            <Button onClick={() => downloadOrders()} icon={<ExportOutlined />}>导出</Button>
                                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>搜索</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </>}
                    </Form>
                </Card>
            </Content>
            <Card>
                <Table
                    size="middle"
                    title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} menu={<TabBar num={num} func={filterMediaStatus} />} />}
                    columns={columns.filter(e => e.checked === 1)}
                    rowSelection={(role === 'OPERATOR' || action === 'EDIT') ? rowSelection : undefined}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination);
                        form.submit()
                    }}
                    footer={() => {
                        switch (role) {
                            case 'CUSTOMER':
                                return action === 'EDIT'
                                    ? <Paragraph>
                                        当前页面共<Text strong>{_.size(data)}</Text>条订单, 共计<Text strong>{_.sumBy(data, 'cost_money')}</Text>元
                                    </Paragraph>
                                    : <Paragraph>
                                        当前页面共<Text strong>{_.size(data)}</Text>条订单, 共计<Text strong>{_.sumBy(data, 'paid_money')}</Text>元
                                    </Paragraph>
                            case 'OPERATOR':
                                if (sessionUser.role.attache === 0) {
                                    return <Paragraph>
                                        当前页面共<Text strong>{_.size(data)}</Text>条订单, 成本共计<Text strong>{_.sumBy(data, 'cost_money')}</Text>元, 客户付款共计<Text strong>{_.sumBy(data, 'paid_money')}</Text>元
                                    </Paragraph>
                                } else {
                                    return <Paragraph>
                                        当前页面共<Text strong>{_.size(data)}</Text>条订单, 共计<Text strong>{_.sumBy(data, 'cost_money')}</Text>元
                                    </Paragraph>
                                }

                            case 'SALE':
                                return <Paragraph>
                                    当前页面共<Text strong>{_.size(data)}</Text>条订单, 共计<Text strong>{_.sumBy(data, 'paid_money')}</Text>元
                                </Paragraph>
                            default:
                                return <Paragraph>
                                    当前页面共<Text strong>{_.size(data)}</Text>条订单, 共计<Text strong>{_.sumBy(data, 'paid_money')}</Text>元
                                </Paragraph>
                        }
                    }}
                />
            </Card>
        </Space>

        <Modal title="拒稿理由" open={modal.verifyFailed}
            onOk={() => {
                verifyOrderFailed(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => { resetModal() }}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>

        <Modal title="填写回执" open={modal.publishSuccess} footer={null}>
            <Form onFinish={val => {
                val = { ...val, image_url: val['image_url'][0]['response']['data'] }
                publishOrderSuccess(modal.target_id, val)
            }}>
                <Form.Item label='上传图片' name='image_url'
                    valuePropName="file"
                    getValueFromEvent={e => {
                        console.log('Upload event:', e);
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                    }}
                >
                    <Upload name='upload_files'
                        beforeUpload={(file) => {
                            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                            if (!isJpgOrPng) {
                                message.error('请上传JPG/PNG格式的图片!');
                            }
                            const isLt2M = file.size / 1024 / 1024 < 2;
                            if (!isLt2M) {
                                message.error('当前支持图片最大尺寸为2M!');
                            }
                        }}
                        action={'/mediaIndex/order/uploadImg'}
                        listType="picture">
                        <Button icon={<UploadOutlined />}>
                            点击上传
                        </Button>
                    </Upload>
                </Form.Item>
                <Form.Item label="回执" name='reason'>
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType='submit' type='primary'>提交</Button>
                </Form.Item>
            </Form>
        </Modal>

        <Modal title="退稿理由" open={modal.publishOrderFailed}
            onOk={() => {
                publishOrderFailed(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => { resetModal() }}>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>

        <Modal title="编辑回执" open={modal.updateUrl} onCancel={resetModal} footer={null}>
            <Form onFinish={val => {
                val = { ...val, image_url: val['image_url'][0]['response']['data'] }
                updateUrl(modal.target_id, val)
            }}>
                <Form.Item label='上传图片' name='image_url'
                    valuePropName="file"
                    getValueFromEvent={e => {
                        console.log('Upload event:', e);
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                    }}
                >
                    <Upload name='upload_files'
                        beforeUpload={(file) => {
                            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                            if (!isJpgOrPng) {
                                message.error('请上传JPG/PNG格式的图片!');
                            }
                            const isLt2M = file.size / 1024 / 1024 < 2;
                            if (!isLt2M) {
                                message.error('当前支持图片最大尺寸为2M!');
                            }
                        }}
                        action={'/mediaIndex/order/uploadImg'}
                        listType="picture">
                        <Button icon={<UploadOutlined />}>
                            点击上传
                        </Button>
                    </Upload>
                </Form.Item>
                <Form.Item label="回执" name='reason'>
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType='submit' type='primary'>提交</Button>
                </Form.Item>
            </Form>
        </Modal>

        <Modal title="撤稿申请" open={modal.recallBox}
            onOk={() => {
                recallOrder(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => { resetModal() }}>
            <Select
                showSearch style={{ width: "100%", marginBottom: '8px' }}
                placeholder="请选择撤稿理由"
                onChange={e => setSelector(e)}
            >
                <Option value={1}>选择错误媒体</Option>
                <Option value={2}>来源填写错误</Option>
                <Option value={3}>稿件内容有误</Option>
                <Option value={9}>其他</Option>
            </Select>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>

        <Modal title="异常申请" open={modal.errorBox}
            onOk={() => {
                errorOrder(modal.target_id, text)
                resetModal()
            }}
            onCancel={() => { resetModal() }}>
            <Select
                showSearch style={{ width: "100%", marginBottom: '8px' }}
                placeholder="请选择异常理由"
                onChange={e => setSelector(e)}
            >
                <Option value={1}>稿件内容遭修改</Option>
                <Option value={2}>稿件被删除</Option>
                <Option value={3}>稿件未收录</Option>
                <Option value={9}>其他</Option>
            </Select>
            <TextArea rows={4} value={text} onChange={e => setText(e.target.value)} />
        </Modal>

        <Modal
            open={modal.download}
            footer={null}
            onOk={() => setModal({ ...modal, download: false })}
            onCancel={() => setModal({ ...modal, download: false })}
        >
            <div style={{ textAlign: 'center' }}>
                <Spin tip="下载中..." />
            </div>
        </Modal>
    </>;
}


export default MediaIndexOrderTable

const TabBar = ({ num, func }) => {
    const badgeOffset = [16, 2]

    return <Tabs defaultActiveKey="1" onChange={func}
        items={[
            { label: '全部稿件', key: '1' },
            {
                label: <Badge size='small' count={num.waitVerify} offset={badgeOffset} >
                    <div style={{ fontSize: 12 }}>待审核</div>
                </Badge>,
                key: '2',
            },
            {
                label: <Badge size='small' count={num.waitPublish} offset={badgeOffset} >
                    <div style={{ fontSize: 12 }}>待发布</div>
                </Badge>,
                key: '3',
            },
            { label: '已完成', key: '4' },
            { label: '已退稿', key: '5' },
            {
                label: <Badge size='small' count={num.recall} offset={badgeOffset} >
                    <div style={{ fontSize: 12 }}>撤稿申请</div>
                </Badge>,
                key: '6',
            },
            {
                label: <Badge size='small' count={num.error} offset={badgeOffset} >
                    <div style={{ fontSize: 12 }}>异常申请</div>
                </Badge>,
                key: '7',
            },
        ]}
    />
}