import React, { useState, useEffect } from 'react';
import { UpSquareOutlined, SearchOutlined, DownSquareOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Divider, message, Layout, Space, Typography } from 'antd';
import _ from 'lodash'
import qs from 'qs'
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import SearchForm from '../../common/SearchForm'
import MediaSearchConfig from '../../../role/media/MediaSearchConfig'
import { initFormData, tablePagination } from '../../common/tableCommon';

const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios');
const layout = { layout: 'vertical' };

const CollectionDetailTable = ({ initialEventId = "" }) => {

    const [form] = Form.useForm();                  // 媒体搜索form

    const role = useRecoilValue(userRoleState)

    const searchDatas = MediaSearchConfig({})
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("event_id", initialEventId)
        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/media/getEventMediaList',
                data: params,
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }


    // 更改媒体在活动/收藏夹中的顺序
    const updateMediaProirity = async (id, action) => {
        await axios({
            method: 'post',
            url: `/eventMedia/priority/${id}`,
            data: qs.stringify({ action })
        }).then((res) => {
            if (res.data.code === '200') {
                message.success("已成功修改")
            } else {
                message.error(res.data.message)
            }
            reloadTable()
        })
    }

    // 删除活动 或者 收藏夹中的媒体
    const removeMediaFromEvent = async (id) => {
        await axios({
            method: 'delete',
            url: `/eventMedia/del/${id}`
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功删除")
            } else {
                message.error(res.data.msg)
            }
        })
    }

    // 初始化列表
    useEffect(() => {
        fetchData()
        return source.cancel('Operation canceled by the user.');
    }, [initialEventId])

    const columns = [
        {
            title: '媒体名称',
            dataIndex: 'name',
            checked: 1,
            render: (text, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text >{text}</Text>
                        <span><a href={data.sample_url} target='_blank' rel='noopener noreferrer'>查看案例</a></span>
                    </div>
                )
            }
        }, {
            title: '优先级',
            dataIndex: 'priority',
            checked: 1,
            render: (t, data) => {
                return (
                    <Space direction='vertical'>
                        <Text>优先级: {t}</Text>
                        <Space>
                            <Button type="link" icon={<UpSquareOutlined />} onClick={() => updateMediaProirity(data.event_media_id, 'add')} />
                            <Button type="link" icon={<DownSquareOutlined />} onClick={() => updateMediaProirity(data.event_media_id, 'minus')} />
                        </Space>
                    </Space>
                )
            }
        },
        {
            title: '活动操作',
            dataIndex: 'id',
            checked: 1,
            render: (text, data) => (
                <Space>
                    <Button type="link" icon={<DeleteOutlined />} size='small' onClick={() => {
                        removeMediaFromEvent(data.event_media_id)
                        reloadTable()
                    }} />
                </Space>

            )
        }
    ]

    return <>
        <Content style={{ background: '#fff' }}>
            <Form
                {...layout}
                name="media-form"
                form={form}
                initialValues={initialFormData}
                onFinish={val => fetchData(_.pickBy(val, _.identity))}
            >
                <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                <Divider />
                <Row>
                    <Col span={12}>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" style={{ margin: "0 0 0 8px" }}>
                            <SearchOutlined /> 搜索
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Table
                size='middle'
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>
    </>;
}

export default CollectionDetailTable