import React, { useState, useEffect } from 'react';
import {
    Table, Button, Row, Col, Modal, Layout, Typography,
    Input, Form, Space, Divider, Tabs, Card
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs';
import qs from 'qs';

import { initFormData, tablePagination } from '../../common/tableCommon';
import { WorkOrderTableConfig } from '../../../role/order/WorkOrderTableConfig';
import OrderTable from './OrderTable';
import WorkOrderDetailTable from './WorkOrderDetailTable';
import SearchForm from '../../common/SearchForm';
import WorkOrderSearchConfig from '../../../role/order/WorkOrderSearchConfig'
import { resultResponse } from '../../common/ResultResponse';
import WorkOrderUserBindTable from './WorkOrderUserBindTable';

const axios = require('axios');
const { Content } = Layout;

const { TextArea } = Input;
const { Text } = Typography;

const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 }, };
const layout = { layout: 'vertical' };
const seartchLayout = { lg: 24, xl: 12 };
const initialModal = { id: '', create: false, select: false, orders: false }

// 用于内部销售给客户开票
// 或者财务结算用
const WorkOrderTable = ({ workOrders = [], setWorkOrders, hasRowSelection = false, initialState = '' }) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState(initialModal)
    const [orders, setOrders] = useState([])
    const [status, setStatus] = useState(initialState)

    const [form] = Form.useForm();
    const searchDatas = WorkOrderSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const reloadTable = () => form.submit();
    const resetModal = () => setModal(initialModal)

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append('state', status)
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/workOrder/getList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }


    // 创建初始工单
    const createWorkOrder = async (params) => {
        await axios({
            method: 'post',
            url: "/workOrder/add",
            data: qs.stringify(params)
        }).then(res => {
            resultResponse(res, '已成功添加')
            reloadTable()
        })
    }


    const selectOrders = (id, customer_id) => setModal({ ...modal, select: true, id, customer_id })

    // 绑定工单
    const bindOrders = async () => {
        let params = new FormData()
        params.append("id", modal.id)
        params.append("order_ids", orders.map(e => e.id))
        await axios({
            method: 'post',
            url: "/workOrder/bind",
            data: params
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
            resetModal()
        })
    }

    const finishSaleOrder = async (id, state, val) => {
        await axios({
            method: 'post',
            url: "/workOrder/finish",
            data: qs.stringify({
                id: id,
                state: state,
                sale_price: val['sale_price'],
                alipay_id: val['alipay_id']
            })
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
            resetModal()
        })
    }

    const confirmOrder = async (id, state) => {
        await axios({
            method: 'post',
            url: "/workOrder/finish",
            data: qs.stringify({
                id: id,
                state: state
            })
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
            resetModal()
        })
    }

    const checkOrders = async (id) => {
        await axios({
            method: 'post',
            url: `/workOrder/check/${id}`,
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
            resetModal()
        })
    }

    // 切换Tabs时 进行处理
    const filterMediaStatus = (e) => {
        switch (e) {
            case '2':
                setStatus('PENDING')
                break;
            case '3':
                setStatus('CONFIRMED')
                break;
            case '4':
                setStatus('UNCHECKED')
                break;
            case '5':
                setStatus('UNPAID')
                break;
            case '6':
                setStatus('SUCCESS')
                break;
            case '7':
                setStatus('FAILED')
                break;
            default:
                setStatus('')
        }
        form.submit()
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    function hideData(id) {
        setData(_.filter(data, d => d.id !== id))
    }

    const bindUser = (id) => setModal({ ...modal, bind: true, id: id })
    // 订单操作函数
    const handleOrders = {
        selectOrders, reloadTable, confirmOrder, checkOrders, bindUser, hideData
    }

    let columns = WorkOrderTableConfig({ modal, setModal, handleOrders })

    return <>
        <Space direction='vertical' size={16} className='full-width'>
            <Card>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} />
                    <Row>
                        <Col {...seartchLayout} >
                            <Button type="primary" onClick={() => setModal({ ...modal, create: true })}><PlusOutlined />创建</Button>
                        </Col>
                        <Col  {...seartchLayout} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    <SearchOutlined /> 搜索
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card>
                {
                    hasRowSelection
                        ? <Tabs defaultActiveKey="6" onChange={filterMediaStatus}
                            items={[
                                { key: '6', label: `已支付订单`, },
                                { key: '5', label: `未打款订单`, },
                                { key: '3', label: `待确认`, },
                                { key: '4', label: `待发布`, },
                                { key: '5', label: `已完成`, },
                                { key: '6', label: `已退稿`, },
                            ]}
                        />
                        : <Tabs defaultActiveKey="1" onChange={filterMediaStatus}
                            items={[
                                { key: '1', label: `全部订单`, },
                                { key: '2', label: `未完成订单`, },
                                { key: '3', label: `待支付订单`, },
                                { key: '4', label: `待审核订单`, },
                                { key: '5', label: `未打款订单`, },
                                { key: '6', label: `已支付订单`, },
                                { key: '7', label: `已取消订单`, },
                            ]}
                        />
                }
                <Table
                    size="small"
                    columns={columns}
                    rowKey={record => record.id}
                    rowSelection={hasRowSelection
                        ? {
                            selectedRowKeys: workOrders.map(e => e.id),
                            onSelect: (record, selected) => {
                                let newMedias = selected ? _.concat(workOrders, record) : _.differenceBy(workOrders, [record], 'id');
                                setWorkOrders(_.uniqBy(newMedias, 'id'))
                            },
                            onSelectAll: (selected, selectedRows, changeRows) => {
                                let newMedias = selected ? _.concat(workOrders, changeRows) : _.differenceBy(workOrders, changeRows, 'id');
                                setWorkOrders(_.uniqBy(newMedias, 'id'))
                            },
                            getCheckboxProps: record => ({
                                disabled: !((record.state === 'SUCCESS' || record.state === 'UNPAID') && record.customer_name && record.invoice_id === 0),
                                name: record.name,
                            }),
                        }
                        : {
                            selectedRowKeys: workOrders.map(e => e.id),
                            onSelect: (record, selected) => {
                                let newMedias = selected ? _.concat(workOrders, record) : _.differenceBy(workOrders, [record], 'id');
                                setWorkOrders(_.uniqBy(newMedias, 'id'))
                            },
                            onSelectAll: (selected, selectedRows, changeRows) => {
                                let newMedias = selected ? _.concat(workOrders, changeRows) : _.differenceBy(workOrders, changeRows, 'id');
                                setWorkOrders(_.uniqBy(newMedias, 'id'))
                            },
                        }}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                    summary={pageData => <Summary pageData={pageData} orders={workOrders} columns={columns} />}
                />
            </Card>
        </Space>


        <Modal title="创建工单"
            open={modal.create}
            footer={null}
            onCancel={() => resetModal()}
        >
            <Form
                name="add-coupon" {...formItemLayout}
                onFinish={(values) => {
                    createWorkOrder({
                        ...values, name: _.trim(values.name), remark: _.trim(values.remark)
                    })
                    resetModal()
                }}
            >
                <Form.Item label="名称" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="备注" name="remark">
                    <TextArea />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                        确认
                    </Button>
                </Form.Item>
            </Form>
        </Modal>

        <Modal title="选择订单" open={modal.select} footer={null}
            onCancel={() => resetModal()}
            width={1500}
            destroyOnClose={true}
        >
            <OrderTable orders={orders} setOrders={setOrders} url='/workOrder/getOrders' hasTabBar={false} miniMode={true} preData={{ customer_id: modal?.customer_id }} />
            <Button type='primary' onClick={() => bindOrders()}>提交</Button>
        </Modal>
        {/* 查看媒体 */}
        <Modal title="查看订单" open={modal.orders} footer={null}
            width={1500}
            onCancel={() => resetModal()}
        >
            <WorkOrderDetailTable id={modal.id} />
        </Modal>

        <Modal title="订单状态" open={modal.finish} footer={null}
            onCancel={() => resetModal()}
        >
            <Form
                layout='vertical'
                onFinish={val => {
                    if (modal.paid) {
                        finishSaleOrder(modal.id, "UNCHECKED", val)
                    } else {
                        finishSaleOrder(modal.id, 'UNPAID', val)
                    }
                }}
            >
                <Form.Item label="其他款项" name="sale_price">
                    <Input />
                </Form.Item>
                {
                    modal.paid && <Form.Item label="支付宝订单号" name="alipay_id">
                        <Input />
                    </Form.Item>
                }
                <Divider />
                <div style={{ textAlign: 'right' }}>
                    <Button type='primary' htmlType='submit'>提交</Button>
                </div>
            </Form>
        </Modal>

        <Modal title="绑定客户" open={modal.bind} footer={null}
            width={1500}
            onCancel={() => resetModal()}
        >
            <WorkOrderUserBindTable id={modal.id} resetModal={resetModal} reloadTable={reloadTable} />
        </Modal>
    </>;
}


export default WorkOrderTable

const Summary = ({ pageData, orders, columns }) => {
    let totalNum = _.size(columns.filter(e => e.checked === 1))
    return <tr>
        <td />
        <th>汇总</th>
        <td colSpan={totalNum - 1}>
            <Space>
                <Text>共{_.size(pageData)}条订单</Text>
                <Text>共{_.sumBy(pageData, 'sale_price')}元</Text>
                {
                    _.size(orders) > 0 && <>
                        <Text strong>已选择{_.size(orders)}条订单 </Text>
                        <Text>共{_.sumBy(orders, 'sale_price')}元</Text>
                    </>
                }
            </Space>
        </td>
    </tr>
}
