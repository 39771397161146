import React from 'react'
import { Typography, Button, Space, Tag } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { resultResponse } from '../../Apps/common/ResultResponse';

const { Text } = Typography;

const axios = require('axios');

export const SalaryTableConfig = () => {
    const role = useRecoilValue(userRoleState)

    const updateSalaryLog = async (user_id) => {
        let formData = new FormData()
        formData.append('user_id', user_id)
        await axios({
            method: 'post',
            url: '/user/salary/ins',
            data: formData
        }).then((res) => {
            resultResponse(res, '已成功')
        })
    }

    const defaultConfig = [
        {
            title: '岗位名称',
            dataIndex: 'name',
            render: (val, data) => {
                return <Space direction='vertical'>
                    <Text strong>{data.real_name || data.account}</Text>
                    <Tag color='orange'>{data.name}</Tag>
                </Space>
            }
        }, {
            title: '基本薪资',
            dataIndex: 'base_salary',
        }, {
            title: '岗位薪资',
            dataIndex: 'post_salary',
        }, {
            title: '补助薪资',
            dataIndex: 'subsidized_salary',
        }, {
            title: '绩效工资',
            dataIndex: 'performance_salary',
            render: (t, data) => {
                return <Space direction='vertical' size='small'>
                    <Text strong>基本绩效薪资: {data.performance_salary} 元</Text>
                    <Text strong>绩效提成: {data.performance_ratio * 100}%</Text>
                </Space>
            }
        }, {
            title: '状态',
            dataIndex: 'status',
            render: (val, data) => {
                if (val === 1) {
                    return <Tag style={{ margin: 0 }} color="green">正常</Tag>
                } else {
                    return <Tag style={{ margin: 0 }} color="red">已停用</Tag>
                }
            }
        }, {
            title: '操作',
            dataIndex: 'user_id',
            render: (val, data) => {
                if (data.status === 1) {
                    return <Button type='link' onClick={() => updateSalaryLog(val)}>更新</Button>
                }
            }
        },
    ]


    let result = []

    switch (role) {
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
        case 'SUPPORT_STAFF':
        case 'FINANCE':
            result = _.concat(
                defaultConfig
            )
            break;
        default:
            break;
    }
    return result
}
