import React from 'react';
import { Typography, Breadcrumb, Space } from 'antd'

const { Text, Title } = Typography

const PageHeader = (props) => {


    const { title, footer, breadcrumbs, subTitle, extra } = props




    return <div className={footer ? 'page-header' : 'page-header page-header-no-footer'}    >
        {
            breadcrumbs &&
            <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="">Application Center</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>An Application</Breadcrumb.Item>
            </Breadcrumb>
        }
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Space>
                <Title level={4} style={{ marginTop: '0.75em' }}>{title}</Title>
                {
                    subTitle &&
                    <Text type='secondary'>
                        {subTitle}
                    </Text>
                }
            </Space>
            {extra}
        </div>
        {props.children}
        {footer}
    </div>
}

export default PageHeader