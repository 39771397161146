import React from 'react';
import { Layout, Menu, Typography } from 'antd';
import { Link } from 'react-router-dom'
import { HomeOutlined, SolutionOutlined, ProjectOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';

import Logged from './Logged';
import { userLoginState } from '../../atoms/Atoms'


const { Header } = Layout;
const { Text } = Typography

const logoImg2 = 'http://origin-static.oss-cn-beijing.aliyuncs.com/img/index/yzt/logo-bordless.png'

const Homeheader = () => {
    const loggedIn = useRecoilValue(userLoginState)
    const fontStyle = { fontSize: '14px' }

    const menuItems = [
        {
            label: <Link to="/" ><Text style={fontStyle}>首页</Text></Link>,
            key: 'home',
            icon: <HomeOutlined />
        },
        {
            label: <Text style={fontStyle}>解决方案</Text>,
            key: 'solution',
            icon: <SolutionOutlined />,
            children: [
                { label: <Link to="/solution/agent">媒介代理</Link>, key: 'agent' },
                { label: <Link to="/solution/enterprise">企业客户</Link>, key: 'enterprise' }
            ],
        },
        {
            label: <Text style={fontStyle}>业务详情</Text>,
            key: 'business',
            icon: <ProjectOutlined />,
            children: [
                { label: <Link to="/info/marketing">整合营销</Link>, key: 'info-marketing' },
                { label: <Link to="/info/baike">百科列表</Link>, key: 'info-baike' }
            ],
        },
        // { label: <Link to="/alliance">代理加盟</Link>, key: 'home' },
        // { label: <Link to="/">关于我们</Link>, key: 'about-us' },
    ]

    const baseMenu = <Menu
        className='top-menu'
        mode="horizontal"
        items={menuItems} />;

    return <Header className="header" style={{ height: '68px', boxShadow: '0 2px 8px #f0f1f2' }}>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: "1200px",
            margin: '0 auto',
            padding: 0,
            height: 64,
            alignItems: 'center'
        }}>
            <div>
                <img style={{ height: 40, width: 'auto' }} src={logoImg2} alt='' />
            </div>
            {
                loggedIn
                    ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {baseMenu}
                        <Logged />
                    </div>
                    : <div>
                        {baseMenu}
                    </div>
            }
        </div>
    </Header>
}

export default Homeheader