import React, { useEffect } from 'react'
import { Form, Row, Button, Space } from 'antd';
import _ from 'lodash'

import CreateForm from '../../common/CreateForm';
import WechatDatasConfig from '../../../role/wechat/WechatDatasConfig';


const AddXiaohongshuForm = (props) => {
    const { media, drawerAction } = props;
    const { visible, setVisible, createMedia, updateMedia, } = drawerAction

    const Datas = WechatDatasConfig()
    function customizer(objValue, srcValue) {
        return _.isUndefined(srcValue) ? "" : srcValue;
    }

    const [form] = Form.useForm()

    useEffect(() => {
        let data = media
        if (media.tag) {
            data = { ...media, tag: _.split(media.tag, ',') }
        }
        form.setFieldsValue(data)
    }, [media, form])

    return <Form layout='vertical' form={form} name="basic"
        onFinish={(val) => {
            let data = _.assignInWith(media, val, customizer)
            data = { ...data, tag: _.join(data.tag, ',') }
            if (visible.action === 'ADD') {
                createMedia(data)
            } else if (visible.action === 'EDIT') {
                updateMedia(data)
            }
        }}
    >
        {Datas.map(data => <Row gutter={16} key={data[0].name}>
            <CreateForm datas={data} span={12} />
        </Row>
        )}
        <div style={{ display: 'flex', flexDirection: 'row-reverse', margin: '8px 0' }}>
            <Space>
                <Button onClick={() => setVisible({ ...visible, addMedia: false })}>取消</Button>
                <Button htmlType='submit' type='primary'>提交</Button>
            </Space>
        </div>
    </Form>
}

export default AddXiaohongshuForm