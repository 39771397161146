import React from "react";
import { renderToStaticMarkup } from 'react-dom/server';
import parse, { domToReact } from 'html-react-parser';
import _ from 'lodash'

const clearHtml = (text) => {
  return text.replace(/\n/g, "")
    .replace(/p>/g, "p>\n")
    .replace(/br>/g, "br>\n")
    .replace(/&nbsp;/g, "")
    .replace(/h1>/g, "h1>\n")
    .replace(/h2>/g, "h2>\n")
    .replace(/h3>/g, "h3>\n")
    .replace(/h4>/g, "h4>\n")
    .replace(/h5>/g, "h5>\n")
    .replace(/h6>/g, "h6>\n")
    .replace(/\u200B/g, '');
}

const clearResult = (text) => {
  return text.replace(/\n/g, "")
    .replace(/<p><\/p>/g, "")
    .replace(/<p style="text-align:left"><\/p>/g, "")
    .replace(/ style="\S" \/>/g, "");
}

const imgParagraph = (paragraph) => {
  return paragraph.children.filter(e => {
    return e.name === 'img'
  })
}

// const emptyParagraph = (paragraph) => {
//   return paragraph.children.filter(e => {
//     console.log(e)
//     if (e.data) {
//       console.log(e.data.trim())
//       console.log(e.data.trim().length)
//     }
//     return e.name === 'text'
//   })
// }


const options = {
  replace: (e) => {
    // console.log(e)
    // 段落首字母空两格
    if (e.type === 'text') {
      if (e.data.trim().length === 0) {
        return <p></p>
      } else {
        if (e.parent.name === 'p' && e.prev === null) {
          _.set(e, 'data', `　　${e.data.trim()}`)
        }
      }
    }
    if (!e.attribs) return;

    // 判断图片居中
    if (e.name === 'p') {
      let imgPara = imgParagraph(e)
      if (_.isEmpty(imgPara)) {
        return <p style={{ textAlign: 'left' }}>{domToReact(e.children, options)}</p>;
      } else {
        imgPara[0]['attribs']['style'] = null
        return <p style={{ textAlign: 'center' }}>{domToReact(imgPara, options)}</p>;
      }
    }

  }
};


const Formatter = ({ content, setContent }) => {
  let result = renderToStaticMarkup(parse(clearHtml(content), options))
  let text = clearResult(result)
  setContent(text)
};

export default Formatter;
