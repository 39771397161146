import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const BaikeSearchConfig = () => {
    const role = useRecoilValue(userRoleState)

    const statusSearch = [
        {
            name: 'name',
            label: '百科平台',
            type: 'radio',
            children: [
                ['全部', ''],
                ['百科创建', '创建'],
                ['百科修改', '修改'],
            ]
        },
        {
            name: 'platform',
            label: '百科平台',
            type: 'radio',
            children: [
                ['全部', ''],
                ['百度', '百度'],
                ['搜狗', '搜狗'],
                ['360', '360'],
                ['互动', '互动'],
                ['其他', '其他'],
            ]
        },
        {
            name: 'category',
            label: '百科类型',
            type: 'radio',
            children: [
                ['全部', ''],
                ['企业百科', '企业百科'],
                ['品牌百科', '品牌百科'],
                ['人物百科', '人物百科'],
                ['网站百科', '网站百科'],
                ['产品百科', '产品百科'],
                ['软件APP', '软件APP'],
            ]
        },
    ]

    let result = []

    switch (role) {
        case "CUSTOMER":
            result = _.concat(
                statusSearch
            )
            break;
        case "OPERATOR":
            result = _.concat(
                statusSearch,
            )
            break;
        case "SALE":
        case 'FRANCHISE':
            result = _.concat(
                statusSearch,
            )
            break;
        default:
            result = _.concat(
                statusSearch
            )
            break;
    }

    return result
}

export default BaikeSearchConfig