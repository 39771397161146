import React, { useState } from 'react';
import { Layout, Tabs, Typography, Form, Row, Col, Input, Button, Radio, InputNumber, Select, Checkbox } from 'antd';
import _ from 'lodash';
import qs from 'qs';
import WbApiArea from '../../../../entities/WbApiArea';

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Title, Paragraph, Text } = Typography;
const axios = require('axios');

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const colLayout = {
    span: 24
}
const WbFaroriteForm = ({ size }) => {

    const [formType, setFormType] = useState({
        ids: "10",
        iftiming: '0',
        adtype: '0',
        order_speed: '2',
        brush_praise_type: '1',
    })

    const fetchData = async (val) => {
        console.log(val)
        await axios({
            method: 'post',
            url: '/wbApi/wbFollower/addFans',
            data: qs.stringify(val)
        }).then((res) => {
        })
    }


    function customizer(objValue, srcValue) {
        return _.isUndefined(srcValue) ? "" : srcValue;
    }

    const Infomation = ({ id }) => {
        switch (id) {
            case "10":
                return <Paragraph >
                    1.精品点赞使用精选微博活跃账号，不屏蔽，权重高。
                </Paragraph>
            case "74":
                return <>
                    <Paragraph >
                        1.特级点赞，使用精选微博等级20+的活跃达人账号，不屏蔽，与特级关注搭配使用可以提高粉丝互动。
                        </Paragraph>
                    <Paragraph >
                        2.正常维护全平台单链接上限为2000；广告维护（价格乘2）全平台单链接上限可达10000；若博文为广告类博文：包括软广（文字类、图片类、视频类广告）、硬广（带链接、带@xxx等广告）；博文非广告但评论带广告性质的文字链接@xxx等请选择广告推广，否则大概率被拒单；若下单时博文为非广告类，但后来编辑成广告类博文或者评论加wx或链接等，被监测发现到将被拉入失信名单，该博主将无法在平台做转评赞业务。
                    </Paragraph>
                    <Paragraph >
                        3.该产品上限为2000；订单数量最小为10，不足10按10计费；违规内容包括但不限于：售卖小说电影漫画类、手机网络兼职类、白条花呗信用卡变现类、VPN等加速器类、推广破解软件类、各种刷量业务类推广、XX洗浴、KTV类、陪聊陪玩、呼死你、短信轰炸、人脸雷达、隐私查询、代孕类等。
                    </Paragraph>
                </>
            case "73":
                return <>
                    <Paragraph >
                        1.超级点赞，用精选微博等级20+活跃账号，不屏蔽，与超级关注搭配使用可以提高粉丝互动。
                        </Paragraph>
                    <Paragraph >
                        2.正常维护全平台单链接上限为2000；广告维护（价格乘2）全平台单链接上限可达10000；若博文为广告类博文：包括软广（文字类、图片类、视频类广告）、硬广（带链接、带@xxx等广告）；博文非广告但评论带广告性质的文字链接@xxx等请选择广告推广，否则大概率被拒单；若下单时博文为非广告类，但后来编辑成广告类博文或者评论加wx或链接等，被监测发现到将被拉入失信名单，该博主将无法在平台做转评赞业务。
                        </Paragraph>
                    <Paragraph >
                        3.该产品上限为2000；订单数量最小为20，不足20按20计费；违规内容包括但不限于：售卖小说电影漫画类、手机网络兼职类、白条花呗信用卡变现类、VPN等加速器类、推广破解软件类、各种刷量业务类推广、XX洗浴、KTV类、陪聊陪玩、呼死你、短信轰炸、人脸雷达、隐私查询、代孕类等。
                    </Paragraph>
                </>
            case "72":
                return <>
                    <Paragraph >
                        1.顶级点赞，使用精选微博等级10+活跃账号，不屏蔽，与顶级关注搭配使用可以提高粉丝互动。
                    </Paragraph>
                    <Paragraph >
                        2.正常维护全平台单链接上限为2000；广告维护（价格乘2）全平台单链接上限可达10000；若博文为广告类博文：包括软广（文字类、图片类、视频类广告）、硬广（带链接、带@xxx等广告）；博文非广告但评论带广告性质的文字链接@xxx等请选择广告推广，否则大概率被拒单；若下单时博文为非广告类，但后来编辑成广告类博文或者评论加wx或链接等，被监测发现到将被拉入失信名单，该博主将无法在平台做转评赞业务。
                    </Paragraph>
                    <Paragraph >
                        3.该产品上限为2000；订单数量最小为50，不足50按50计费；违规内容包括但不限于：售卖小说电影漫画类、手机网络兼职类、白条花呗信用卡变现类、VPN等加速器类、推广破解软件类、各种刷量业务类推广、XX洗浴、KTV类、陪聊陪玩、呼死你、短信轰炸、人脸雷达、隐私查询、代孕类等。
                </Paragraph>
                </>
            case "71":
                return <>
                    <Paragraph >
                        1.高级点赞，使用精选微博等级4+活跃账号，不屏蔽，效果与价廉兼备的不二之选。
                    </Paragraph>
                    <Paragraph >
                        2.正常维护全平台单链接上限为2000；广告维护（价格乘2）全平台单链接上限可达10000；若博文为广告类博文：包括软广（文字类、图片类、视频类广告）、硬广（带链接、带@xxx等广告）；博文非广告但评论带广告性质的文字链接@xxx等请选择广告推广，否则大概率被拒单；若下单时博文为非广告类，但后来编辑成广告类博文或者评论加wx或链接等，被监测发现到将被拉入失信名单，该博主将无法在平台做转评赞业务。
                    </Paragraph>
                    <Paragraph >
                        3.该产品上限为2000；订单数量最小为100，不足100按100计费；违规内容包括但不限于：售卖小说电影漫画类、手机网络兼职类、白条花呗信用卡变现类、VPN等加速器类、推广破解软件类、各种刷量业务类推广、XX洗浴、KTV类、陪聊陪玩、呼死你、短信轰炸、人脸雷达、隐私查询、代孕类等。
                </Paragraph>
                </>
            case "70":
                return <>
                    <Paragraph >1.普通点赞，使用入门级微博活跃账号，不屏蔽，效果好，性价比高。
                </Paragraph>
                    <Paragraph >
                        2.正常维护全平台单链接上限为2000；广告维护（价格乘2）全平台单链接上限可达10000；若博文为广告类博文：包括软广（文字类、图片类、视频类广告）、硬广（带链接、带@xxx等广告）；博文非广告但评论带广告性质的文字链接@xxx等请选择广告推广，否则大概率被拒单；若下单时博文为非广告类，但后来编辑成广告类博文或者评论加wx或链接等，被监测发现到将被拉入失信名单，该博主将无法在平台做转评赞业务。
                </Paragraph>
                    <Paragraph >
                        3.该产品上限为3000；订单数量最小为100，不足100按100计费；违规内容包括但不限于：售卖小说电影漫画类、手机网络兼职类、白条花呗信用卡变现类、VPN等加速器类、推广破解软件类、各种刷量业务类推广、XX洗浴、KTV类、陪聊陪玩、呼死你、短信轰炸、人脸雷达、隐私查询、代孕类等。
                </Paragraph>
                </>
            case "12":
                return <Paragraph >1.刷量点赞使用有头像，有昵称的账号，刷完后可能屏蔽，价格便宜，下单无需人工审核。</Paragraph>
            default:
                return <Paragraph>无</Paragraph>
        }
    }


    return (
        <Content style={{ background: "#fff", padding: '24px' }}>
            <Infomation id={formType.ids} />
            <div style={{ maxWidth: '960px', margin: '0 auto' }}>
                <Form
                    {...formItemLayout}
                    initialValues={{
                        ids: formType.ids,
                        iftiming: formType.iftiming,
                        order_speed: formType.order_speed,
                        brush_praise_type: formType.brush_praise_type,
                        adtype: formType.adtype,
                        area: "0",
                        fast_rate: '30'
                    }}
                    onFinish={val => fetchData(_.assignInWith([], val, customizer))}>
                    <Row gutter={[24, 24]}>
                        <Col {...colLayout}>
                            <Form.Item label="关注类型" name="ids">
                                <Radio.Group onChange={e => setFormType({ ...formType, ids: e.target.value })}>
                                    {/* <Radio value="9">达人点赞</Radio> */}
                                    <Radio value="10">精品点赞</Radio>
                                    <Radio value="12">刷量点赞</Radio>
                                    <Radio value="70">普通点赞</Radio>
                                    <Radio value="71">高级点赞</Radio>
                                    <Radio value="72">顶级点赞</Radio>
                                    <Radio value="73">超级点赞</Radio>
                                    <Radio value="74">特级点赞</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="博文地址" name="wb_url">
                                <Input />
                            </Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <Text type="secondary">输入博文地址，格式：http://weibo.com/18****/At****或手机端博文地址</Text>
                            </div>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="刷赞类型" name="brush_praise_type">
                                <Radio.Group onChange={e => setFormType({ ...formType, brush_praise_type: e.target.value })}>
                                    <Radio value="1">微博赞</Radio>
                                    {
                                        _.indexOf(["10", "12"], formType.ids) !== -1 &&
                                        <Radio value="2">评论赞</Radio>
                                    }
                                    {
                                        _.indexOf(["70", "71", "72", "73", "74"], formType.ids) !== -1 &&
                                        <Radio value="3">表情赞</Radio>
                                    }
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {
                            formType.brush_praise_type === "2" &&
                            <Col {...colLayout}>
                                <Form.Item label="评论赞ID" name="review_id">
                                    <Input />
                                </Form.Item>
                                <div style={{ textAlign: 'center' }}>
                                    <Text type="danger">使用谷歌浏览器找到评论，点投诉，在弹出的页面右键查看源代码，找到数字rid</Text>
                                </div>
                            </Col>
                        }
                        {
                            formType.brush_praise_type === "3" &&
                            <Col {...colLayout}>
                                <Form.Item label="选择表情" name="expression[]">
                                    <Checkbox.Group>
                                        <Checkbox value="1">大拇子</Checkbox>
                                        <Checkbox value="2">高兴</Checkbox>
                                        <Checkbox value="3">惊讶</Checkbox>
                                        <Checkbox value="4">难受</Checkbox>
                                        <Checkbox value="5">发怒</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        }
                        <Col {...colLayout}>
                            <Form.Item label="本次数量" name="order_num_total">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        {
                            _.indexOf(["70", "71", "72", "73", "74"], formType.ids) !== -1 &&
                            <>
                                <Col {...colLayout}>
                                    <Form.Item label="维护类型" name="adtype">
                                        <Radio.Group>
                                            <Radio value="0">正常维护</Radio>
                                            <Radio value="1">广告推广</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col {...colLayout}>
                                    <Form.Item label="时间计划" name="iftiming">
                                        <Radio.Group onChange={e => setFormType({ ...formType, iftiming: e.target.value })}>
                                            <Radio value="0">自动计划</Radio>
                                            <Radio value="1">手动计划</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                {
                                    formType.iftiming === '1' &&
                                    <>
                                        <Col {...colLayout}>
                                            <Form.Item label="计划开始日期" name="startdate">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col {...colLayout}>
                                            <Form.Item label="开始时刻点" name="perhour">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col {...colLayout}>
                                            <Form.Item label="每次执行添加多少关注" name="pernum">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                            </>
                        }


                        <Col {...colLayout}>
                            <Form.Item label="订单速度" name="order_speed">
                                <Radio.Group onChange={e => setFormType({ ...formType, order_speed: e.target.value })}>
                                    <Radio value="1">慢速</Radio>
                                    <Radio value="2">正常</Radio>
                                    <Radio value="3">加急</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {
                            formType.order_speed === "3" &&
                            <Col {...colLayout}>
                                <Form.Item label="加急比率" name="fast_rate">
                                    <Select placeholder="请选择加急比率">
                                        <Option value="30">30%</Option>
                                        <Option value="40">40%</Option>
                                        <Option value="50">50%</Option>
                                        <Option value="60">60%</Option>
                                        <Option value="70">70%</Option>
                                        <Option value="80">80%</Option>
                                        <Option value="90">90%</Option>
                                        <Option value="100">100%</Option>
                                    </Select>
                                </Form.Item>
                                <div style={{ textAlign: 'center' }}>
                                    <Text type="secondary">上浮比例越高执行速度越快，选择150%及以上时无需排队</Text>
                                </div>
                            </Col>
                        }
                        <Col {...colLayout}>
                            <Form.Item label="备注" name="note">
                                <TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Content>
    )
}

export default WbFaroriteForm;