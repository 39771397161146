import React from 'react'
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import NotificationsTable from './notifications/NotificationsTable'
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout


const NoticeCenter = () => {
    let pageTitle = "通知中心"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content style={{ margin: 24 }}>
                <NotificationsTable />
            </Content>
        </HelmetProvider>
    )
}

export default NoticeCenter
