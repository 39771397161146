import _ from 'lodash'
import { message } from 'antd';

export const resultResponse = (res, msg) => {
    if (res.data.code === 1) {
        message.success(_.isNil(res.data.data) ? msg : res.data.data)
    } else {
        if (_.isNil(res.data.data)) {
            message.error(res.data.msg)
        } else {
            message.error(res.data.data)
        }
    }
}
