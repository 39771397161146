import React, { useState, useEffect } from 'react';
import { Form, Table, Button, Row, Col, message, Avatar, Divider, Layout, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import _ from 'lodash'
import dayjs from 'dayjs'
import qs from 'qs';

import SearchForm from '../../common/SearchForm'
import UserSearchConfig from '../../../role/user/UserSearchConfig'
import { SearchUser } from '../../../entities/User'
import { initFormData, tablePagination } from '../../common/tableCommon';
import { formatTime } from '../../../utils/TimeUtils'

const { Content } = Layout;
const { Text } = Typography;

const layout = {
    layout: 'vertical'
};
const axios = require('axios');
const initialSearch = SearchUser

const SendOrderUserTable = ({ searchFormDisplay = true, id = "", resetModal, reloadTable }) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [search, setSearch] = useState(initialSearch)

    const userTableConfig = [
        {
            title: '',
            dataIndex: 'avatar_img',
            width: 40,
            render: (t, data) => {
                let base_img = data.avatar_img ? data.avatar_img + "_xs" : `http://origin-static.oss-cn-beijing.aliyuncs.com/static/images/defaultPic.jpg_xs`;
                return <Avatar shape="square" icon={<UserOutlined />} src={base_img} />;
            }
        }, {
            title: '账号名',
            dataIndex: 'account',
            render: (t, data) => {
                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>{data.sell_group_name}</Text>
                    <Text strong>{t}</Text>
                    <Text>{data.available_money === null ? 0 : data.available_money}元</Text>
                </div>
            }
        }, {
            title: '用户信息',
            render: (text, data) => {
                let real_name = (data.real_name === null || data.real_name === undefined) ? "" : data.real_name;
                let company = (data.company === null || data.company === undefined) ? "" : data.company;
                return (
                    <>
                        <Text>所属销售：{data.create_name}</Text>
                        <br />
                        <Text>真实姓名：{real_name}</Text>
                        <br />
                        <Text>所属公司：{company}</Text>
                    </>
                )
            }
        }, {
            title: '最后登录',
            dataIndex: 'last_login_time',
            width: 96,
            render: (t) => formatTime(t)
        }, {
            title: '联系方式',
            dataIndex: 'handle_user',
            render: (t, data) => {
                return (
                    <>
                        <div className='flex-justify'>
                            <Text>QQ：</Text>
                            <Text copyable>{data.qq}</Text>
                        </div>
                        <div className='flex-justify'>
                            <Text>电话：</Text>
                            <Text copyable>{data.tel}</Text>
                        </div>
                    </>
                )
            }
        }, {
            title: '操作',
            dataIndex: 'id',
            render: (t, data) => {
                return <Button onClick={() => allotUser(data.id)} type="link">分配</Button>
            }
        }
    ]



    const fetchData = async (params = new FormData()) => {
        params.append("attache", 3)
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        _.toPairs(search).filter(e => e[1] !== '').map(e => params.append(`${e[0]}`, e[1]))
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/sendOrder/getUsers',
                data: params
            }).then((res) => {
                setData(res.data.data)
                let total = res.data.recordsFiltered
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
                setLoading(false)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const allotUser = async (uid) => {
        await axios({
            method: 'post',
            url: '/sendOrder/allot',
            data: qs.stringify({
                id,
                user_id: uid
            })
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功绑定")
                resetModal()
                reloadTable()
            } else {
                message.warn(res.data.msg)
            }
        })
    }

    useEffect(() => {
        fetchData()
        return () => {
            console.log("FETCH DATA FINISHED")
        }
    }, [pagination.current, pagination.pageSize])

    let miniMode = true
    const searchDatas = UserSearchConfig({ miniMode })
    function customizer(objValue, srcValue) {
        return _.isUndefined(srcValue) ? "" : srcValue;
    }

    let newData = {}
    searchDatas.map(e => _.assign(newData, { [e.name]: e.value || "" }))

    return <>
        <Content>
            {searchFormDisplay && <div>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={newData}
                    onFinish={(val) => {
                        let dateDatas = searchDatas.filter(e => e.type === 'datepicker')
                        _.forEach(dateDatas, data => {
                            _.forEach(val, (v, k) => {
                                if (!_.isNil(v) && !_.isEmpty(v)) {
                                    if (k === data.name) {
                                        val = {
                                            ...val,
                                            [k]: dayjs(v.$d).format('YYYY-MM-DD')
                                        }
                                    }
                                }
                            })
                        })
                        setSearch(_.assignInWith(search, val, customizer))
                        fetchData()
                    }}
                    onFinishFailed={errorInfo => {
                        console.log('Failed:', errorInfo);
                    }}
                >
                    <SearchForm search={search} setSearch={setSearch} searchDatas={searchDatas} reloadTable={reloadTable} />
                    <Divider />
                    <Row>
                        <Col span={12}>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                搜索
                        </Button>
                        </Col>
                    </Row>
                </Form>
            </div>}
            <Table
                size="middle"
                columns={userTableConfig}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination, filters, sorter) => {
                    setPagination(pagination)
                }}
            />
        </Content>
    </>;
}


export default SendOrderUserTable
