import React from 'react';
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MediaPriceTable from './MediaPriceTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;
const MediasPrice = () => {
    let pageTitle = "媒体价格表"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content style={{ margin: 24 }}>
                <MediaPriceTable />
            </Content>
        </HelmetProvider>

    )
}

export default MediasPrice;