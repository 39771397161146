import React from 'react';
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MediaBlacklistTable from './MediaBlacklistTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;
const MediaBlacklist = (props) => {
    let pageTitle = "媒体黑名单"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content style={{ margin: 24 }}>
                <MediaBlacklistTable rowSelectionDisplay={false} />
            </Content>
        </HelmetProvider >
    )
}

export default MediaBlacklist;