import React, { useState } from 'react';
import { Layout, Tabs,  } from 'antd';
import WbfollowerForm from './follower/WbfollowerForm';
import WbFansForm from './fans/WbFansForm';
import WbFaroriteForm from './favorite/WbFaroriteForm';
import WeiboHotTable from './WeiboHotTable'
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;

const Weibo = () => {

    const [tab, setTab] = useState("hots")

    return (
        <>
            <Content style={{ background: "#fff", marginBottom: '24px' }}>
                <PageHeader
                    title="微博中心"
                    style={{ backgroundColor: '#fff' }}
                    footer={
                        <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                            items={[
                                { key: 'hots', label: `微博大号`, },
                            ]}
                        />
                    }
                />
            </Content>
            <div style={{ margin: 24 }}>
                {tab === 'hots' && <WeiboHotTable />}
                {tab === "follower" && <WbfollowerForm />}
                {tab === "fan" && <WbFansForm />}
                {tab === "favorite" && <WbFaroriteForm />}
            </div >
        </ >
    )
}

export default Weibo;