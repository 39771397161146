import React, { useEffect, createRef } from 'react'
import { Row, Button, Form, Space } from 'antd';
import _ from 'lodash'

import CreateForm from '../../common/CreateForm';
import QaDatasConfig from '../../../role/qa/QaDatasConfig';


const layout = {
    layout: 'vertical'
};
const AddQAForm = (props) => {
    const { qa, setQa, drawerAction } = props;
    const { visible, setVisible, createMedia, updateMedia } = drawerAction

    const Datas = QaDatasConfig()
    function customizer(objValue, srcValue) {
        return _.isUndefined(srcValue) ? "" : srcValue;
    }

    const formRef = createRef();
    useEffect(() => {
        formRef.current.setFieldsValue(qa)
    }, [qa, formRef])

    return (
        <>
            <Form
                {...layout}
                ref={formRef}
                name="basic"
                onFinish={(val) => {
                    if (visible.action === 'ADD') {
                        createMedia(_.assignInWith(qa, val, customizer))
                    } else if (visible.action === 'EDIT') {
                        updateMedia(_.assignInWith(qa, val, customizer))
                    }
                    setVisible({ ...visible, addMedia: false })
                }}
                onFinishFailed={errorInfo => {
                    console.log('Failed:', errorInfo);
                }}
                hideRequiredMark
            >
                {Datas.map(data => <Row gutter={16} key={data[0].name}>
                    <CreateForm entity={qa} setEntity={setQa} datas={data} span={12} />
                </Row>)}
                <div className='side-drawer'>
                    <Space>
                        <Button onClick={() => {
                            setVisible({ ...visible, addMedia: false })
                        }}>
                            取消
                        </Button>
                        <Button htmlType='submit' type='primary'>提交</Button>
                    </Space>
                </div>
            </Form>
        </>
    )
}

export default AddQAForm