import React, { useState, useEffect } from 'react';
import { Table, Form, Card, Row, Col, Button, Modal, Input, InputNumber, Radio, Space, Typography } from 'antd';
import _ from 'lodash'
import { PlusOutlined } from '@ant-design/icons';
import qs from 'qs'
import dayjs from 'dayjs';

import { initFormData, tablePagination } from '../../common/tableCommon';
import SearchForm from '../../common/SearchForm'
import EnterpriseInvoiceTableConfig from '../../../role/finance/EnterpriseInvoiceTableConfig'
import EnterpriseInvoiceSearchConfig from '../../../role/finance/EnterpriseInvoiceSearchConfig'
import { resultResponse } from '../../common/ResultResponse';

const axios = require('axios');
const { Text } = Typography

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const layout = { layout: 'vertical' };

const EnterpriseInvoiceTable = () => {
    const searchDatas = EnterpriseInvoiceSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const [form] = Form.useForm()
    const [addForm] = Form.useForm()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [sort, setSort] = useState({ sort_field: '', sort_order: '' })
    const [modal, setModal] = useState({})
    const [invoices, setInvoices] = useState([])

    const reloadTable = () => form.submit()
    const resetModal = () => setModal({})

    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val).forEach(e => e[1] && params.append(`${e[0]}`, e[1]))
        }
        _.toPairs(sort).forEach(e => e[1] && params.append(`${e[0]}`, e[1]))
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/enterprise/invoice/getInvoices',
                data: params
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const addInvoice = async (val) => {
        await axios({
            method: 'post',
            url: '/enterprise/invoice/add',
            data: qs.stringify(val)
        }).then((res) => {
            resultResponse(res, "已添加")
            reloadTable()
            resetModal()
            addForm.resetFields()
        })
    }

    /**
     * 使用企业发票
     */
    const consumeInvoice = async () => {
        let formData = new FormData()
        formData.append('ids', invoices.map(e => e.id));
        await axios({
            method: 'post',
            url: '/enterprise/invoice/use',
            data: formData
        }).then((res) => {
            resultResponse(res, "已使用")
            reloadTable()
            setInvoices([])
            resetModal()
        })
    }

    /**
     * 作废企业发票
     */
    const invalidate = async (id) => {
        await axios({
            method: 'post',
            url: `/enterprise/invoice/invalidate/${id}`,
        }).then((res) => {
            resultResponse(res, "已作废")
            reloadTable()
            setInvoices([])
        })
    }

    const functions = { reloadTable, invalidate }
    const columns = EnterpriseInvoiceTableConfig({ functions })

    return (
        <>
            <Space direction='vertical' size={16} className='full-width'>
                <Card>

                    <Form
                        {...layout}
                        name="basic"
                        form={form}
                        initialValues={initialFormData}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                    >
                        {
                            _.size(searchDatas) > 0 &&
                            <>
                                <Space direction='vertical' size={16} className='full-width'>
                                    <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                                    <Row>
                                        <Col span={12}>
                                            <Space>
                                                <Button type="primary"
                                                    onClick={() => setModal({ ...modal, addInvoice: true })}>
                                                    <PlusOutlined />添加发票
                                                </Button>
                                                <Button type="link" onClick={() => setModal({ ...modal, use: true })}>使用发票</Button>
                                            </Space>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <Button type="primary" htmlType="submit">搜索</Button>
                                        </Col>
                                    </Row>
                                </Space>
                            </>
                        }
                    </Form>
                </Card>
                <Card >
                    <Table
                        size='small'
                        columns={columns}
                        rowSelection={{
                            columnWidth: '24px',
                            selectedRowKeys: invoices.map(e => e.id),
                            onSelect: (record, selected) => {
                                let tempData = {
                                    id: record.id,
                                    amount: record.amount
                                }
                                let newData = selected ? _.concat(invoices, tempData) : _.differenceBy(invoices, [tempData], 'id');
                                setInvoices(_.uniqBy(newData, 'id'))
                            },
                            onSelectAll: (selected, selectedRows, changeRows) => {
                                let tempData = changeRows.map(e => ({
                                    id: e.id, amount: e.amount
                                }))
                                let newData = selected ? _.concat(invoices, tempData) : _.differenceBy(invoices, tempData, 'id');
                                setInvoices(_.uniqBy(newData, 'id'))
                            },
                            getCheckboxProps: record => ({
                                disabled: record.status !== 1,
                                name: record.name,
                            }),
                        }}
                        rowKey={record => record.id}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={(pagination, filters, sorter) => {
                            setSort({ ...sort, sort_field: sorter.field, sort_order: sortOrder(sorter) })
                            setPagination(pagination)
                            form.submit()
                        }}
                        summary={pageData => {
                            let totalMoney = _.sumBy(pageData, 'amount').toFixed(2);
                            let selectMoney = _.sumBy(invoices, 'amount').toFixed(2)
                            return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell colSpan={2} />
                                        <Table.Summary.Cell>总共</Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <Text type="danger">{totalMoney}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={6} />
                                    </Table.Summary.Row>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell colSpan={2} />
                                        <Table.Summary.Cell>已选择</Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <Text type="danger">{selectMoney}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={6} />
                                    </Table.Summary.Row>
                                </>
                            );
                        }}
                    />
                </Card>
            </Space>
            <Modal title='添加发票' open={modal?.addInvoice} footer={null} onCancel={resetModal} >
                <div style={{ width: '100%', margin: '0 auto' }}>
                    <Form
                        form={addForm}
                        name="add-invoice" {...formItemLayout}
                        onFinish={(values) => addInvoice(values)}
                    >
                        <Form.Item label="所属公司" name="enterprise_name">
                            <Radio.Group>
                                <Radio value="搜力科技">搜力科技</Radio>
                                <Radio value="举世传媒">举世传媒</Radio>
                                <Radio value="八面通网络">八面通网络</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="发票编号" name="invoice_number">
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="发票金额" name="amount">
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="发票类型" name="type">
                            <Radio.Group>
                                <Radio value="1">专票</Radio>
                                <Radio value="2">普票</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="描述" name="description">
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                            <Button type="primary" htmlType="submit">
                                继续
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Modal
                title="确认发票详情" open={modal?.use}
                footer={[
                    <Button key="back" onClick={() => resetModal()}>
                        返回
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => consumeInvoice()}>
                        确认
                    </Button>,
                ]}
                onCancel={() => resetModal()}
            >
                <Space direction='vertical'>
                    <Text>共使用 {_.size(invoices)} 张发票</Text>
                    <Text>共记 {_.sumBy(invoices, 'amount')} 元</Text>
                </Space>
            </Modal>
        </>
    )
}

export default EnterpriseInvoiceTable

const sortOrder = (sorter) => {
    if (sorter.order === 'ascend') {
        return 'ASC'
    } else if (sorter.order === 'descend') {
        return "DESC"
    } else { return '' }
}