import Role from "../permission/Role"

import React from "react"
const RoleRoutesConfig = [
    {
        path: '/dashboard/role',
        component: <Role />
    },
]

export default RoleRoutesConfig