import CreateWeiboHotOrder from "../weibo/CreateWeiboHotOrder"
import Weibo from "../weibo/Weibo"
import WeiboHotOrder from "../weibo/WeiboHotOrder"

import React from "react"
const WeiboRoutesConfig = [
    {
        path: '/dashboard/weibo/order',
        component: <WeiboHotOrder/>
    },
    {
        path: '/dashboard/weibo/order/add',
        component: <CreateWeiboHotOrder/>
    },
    {
        path: '/dashboard/weibo',
        component: <Weibo/>
    }
]

export default WeiboRoutesConfig