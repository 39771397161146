
export const Platform = {
    media: {
        id: 1,
        name: '媒体平台'
    },
    weibo: {
        id: 2,
        name: '微博平台'
    },
    wechat: {
        id: 3,
        name: '微信平台'
    },
    xiaohongshu: {
        id: 4,
        name: '小红书平台'
    },
    douyin: {
        id: 5,
        name: '抖音平台'
    }
}
