import React from 'react';
import { Layout,  } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageHeader from '../../layout/PageHeader';

import XiaohongshuTable from './XiaohongshuTable';
const { Content } = Layout;

const Xiaohongshu = () => {
    let pageTitle = "小红书"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <Content style={{ background: "#fff", marginBottom: '24px' }}>
                <PageHeader
                    title={pageTitle}
                    style={{ backgroundColor: '#fff' }}
                />
            </Content>
            <div style={{ margin: 24 }}>
                <XiaohongshuTable />
            </div >
        </HelmetProvider>
    )
}

export default Xiaohongshu;