import React, { useState, useEffect } from 'react';
import {
    PlusOutlined, SearchOutlined, ExportOutlined,
    CloudDownloadOutlined, SaveOutlined,
    UploadOutlined, SyncOutlined
} from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Drawer, message, Layout, Modal, Spin, InputNumber, Typography, Upload, Space, Switch, Card } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';
import qs from 'qs';

import SearchForm from '../../common/SearchForm'
import MediaTableConfig from '../../../role/media/MediaTableConfig'
import MediaSearchConfig from '../../../role/media/MediaSearchConfig'
import { userRoleState } from '../../atoms/Atoms'
import AddMediaForm from './AddMediaForm';
import { CommonMedia, MCNMedia } from '../../../entities/Media'
import MediaMenu from './MediaMenu'
import { disposeColumn, initFormData, tablePagination, TableTitle } from '../../common/tableCommon';
import MediaEditorTable from './MediaEditorTable';
import { SearchFormHori, SearchFormInput } from '../../common/SearchFormTile';
import MediaTagTable from './MediaTagTable';
import MediaIconForm from './MediaIconForm';
import { resultResponse } from '../../common/ResultResponse';
import { useNavigate } from "react-router-dom";
import WhiteList from './WhiteList';
import RoleModule from '../../../utils/permission/RoleModule';

const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios');

const MediaTable = ({
    action, medias = [], setMedias = () => console.log('empty'), media_from = '',
    rowOnSelect = (record, selected) => {
        let tempMedia = {
            id: record.id,
            name: record.name,
            price: record.user_price,
            status: record.status
        }
        let newMedias = selected ? _.concat(medias, tempMedia) : _.differenceBy(medias, [tempMedia], 'id');
        setMedias(_.uniqBy(newMedias, 'id'))
    },
    url, menu = true, rowSelectionDisplay = true,
    simpleMode = false, hasTitle = true,
}) => {

    const role = useRecoilValue(userRoleState)
    let navigate = useNavigate();

    const [saleGroupForm] = Form.useForm();         // 价格分配form

    const [form] = Form.useForm();                  // 媒体搜索form

    const searchDatas = MediaSearchConfig({ action, type: media_from })
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [visible, setVisible] = useState({ addMedia: false, type: '', action: 'ADD' })
    const [eventId, setEventId] = useState('')
    const [media, setMedia] = useState(CommonMedia)
    const [modal, setModal] = useState({ download: false, saleGroupPrice: false, target: '' })
    const [saleGroupDatas, setSaleGroupDatas] = useState([])
    const [saleGroupLoading, setSaleGroupLoading] = useState(false)
    const [drawerLoading, setDrawerLoading] = useState(false)

    // 编辑器样式
    const localStorageEditorStyleName = "____SearchStyle____";
    const [searchStyle, setSearchStyle] = useState(localStorage.getItem(localStorageEditorStyleName) ?? 'vertical')

    const reloadTable = () => form.submit()
    const resetModal = () => setModal({ download: false, saleGroupPrice: false, target: '' })
    const controller = new AbortController();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("media_from", media_from)

        eventId && params.append("event_id", eventId)
        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: url || '/media/getMediaList',
                data: params,
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }
    controller.abort()
    // 初始化列表
    useEffect(() => {
        fetchData()
    }, [eventId])

    // 初始化媒体分配价格
    useEffect(() => {
        if (_.size(saleGroupDatas) > 0) {
            let result = {}
            saleGroupDatas.map(e => _.assign(result, {
                [`price_${e.sale_group_id}`]: e.price
            }))
            saleGroupForm.setFieldsValue(result)
        }
    }, [saleGroupDatas, saleGroupForm])

    const drawerAction = { visible, setVisible, reloadTable }

    // 获取媒体信息
    const getMedia = async (id, type) => {
        setDrawerLoading(true)
        let targetMedia = type === 'MCN' ? MCNMedia : CommonMedia
        setVisible({ ...visible, addMedia: true, action: 'EDIT', type })
        let url = role === "CUSTOMER" ? `/media/getEditorResourceList` : `/media/getMediaList`
        await axios({
            method: 'post', url: url, data: qs.stringify({ id })
        }).then((res) => {
            let tempMedia = res.data.data[0]
            setMedia(_.pick(tempMedia, _.keysIn(targetMedia)))
            setDrawerLoading(false)
        })
    }

    const deleteMedia = async (id) => {
        await axios({
            method: 'get', url: `/media/del/${id}`
        }).then((res) => {
            resultResponse(res, '成功')
            reloadTable()
        })
    }

    const handleStatus = async (id, key, val) => {
        await axios({
            method: 'post',
            url: `/media/edit`,
            data: qs.stringify({
                id,
                is_common: 'yes',
                [key]: val
            })
        }).then((res) => {
            resultResponse(res, '已成功修改')
            reloadTable()
        })
    }

    const pinMedia = async (id, val) => {
        await axios({
            method: 'post',
            url: `/media/favorite`,
            data: qs.stringify({ media_id: id, pinned: val })
        }).then((res) => {
            resultResponse(res, "成功")
            reloadTable()
        })
    }

    const delMediaEdior = async (id) => {
        await axios({
            method: 'delete',
            url: `/mediaEditor/${id}`
        }).then((res) => {
            if (res.data.code === 1) {
                message.success("已成功删除")
                reloadTable()
            } else {
                message.error(res.data.message)
            }
        })
    }

    const downloadMedias = async () => {
        setModal({ ...modal, download: true })
        let params = new FormData()
        let val = _.pickBy(form.getFieldsValue(), _.identity);
        params.append('media_from', media_from)
        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        eventId && params.append("event_id", eventId)

        await axios({
            url: `/media/downLoadPackageMedia`, //your url
            method: 'post',
            responseType: 'blob', // important
            data: params
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '媒体资源详情.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }

    const downloadMediasPrice = async () => {
        setModal({ ...modal, download: true })
        let params = new FormData()
        let val = _.pickBy(form.getFieldsValue(), _.identity);
        params.append('media_from', media_from)
        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        eventId && params.append("event_id", eventId)

        await axios({
            url: `/media/downLoadPackageMediaPrice`, //your url
            method: 'post',
            responseType: 'blob', // important
            data: params
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '媒体价格分配.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }

    const props = {
        name: 'upload_file',
        action: '/media/importPrice',
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} 上传成功!`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败!`);
            }
        },
    };

    // 修改媒体顺序
    const updateProirity = async (id, action) => {
        await axios({
            method: 'post',
            url: `/media/priority/${id}`,
            data: qs.stringify({ action })
        }).then((res) => {
            resultResponse(res, '已成功修改')
            reloadTable()
        })
    }
    // 获取媒体分组价格
    const getSaleGroupPrice = async (id, type) => {
        if (!saleGroupLoading) {
            setSaleGroupLoading(true)
            setModal({ ...modal, saleGroupPrice: true })
            await axios({
                method: 'get',
                url: `/media/getSaleGroupPrices?id=${id}&type=${type}`
            }).then(res => {
                if (res.data.code === 1) {
                    setSaleGroupDatas(res.data.data)
                    setSaleGroupLoading(false)
                } else {
                    message.error(res.data.msg)
                }
            })
        }
    }

    // 更新媒体分组价格
    const updateSaleGroupPrice = async (val) => {
        let formData = new FormData()
        let tmp = _.pickBy(val, (v, k) => parseInt(v) > 0)
        formData.append('resource_id', saleGroupDatas[0].resource_id)
        formData.append('prices', JSON.stringify(tmp))
        if (!saleGroupLoading) {
            setSaleGroupLoading(true)
            await axios({
                method: 'post',
                url: `/media/update/saleGroupPrice`,
                data: formData
            }).then(res => {
                resultResponse(res, '已成功更新')
                setSaleGroupLoading(false)
                setModal({ ...modal, saleGroupPrice: false })
            })

        }
    }

    // 修改资源所属
    const allotUser = async (mid, uid) => {
        await axios({
            method: 'post',
            url: '/media/update/editor',
            data: qs.stringify({
                resource_id: mid,
                uid: uid
            })
        }).then((res) => {
            resultResponse(res, '已成功绑定')
            resetModal()
            reloadTable()
        })
    }

    const localStorageOrderMediaName = "____OrderMedia____";
    const saveLocalStorageMedias = (val) => {
        localStorage.setItem(localStorageOrderMediaName, JSON.stringify(val))
        message.success("成功保存发稿记录")
    }
    const loadLocalStorageMedias = () => {
        setMedias(JSON.parse(localStorage.getItem(localStorageOrderMediaName)))
        message.success("成功读取发稿记录")
    }
    const exclude = (id) => {
        let data = form.getFieldsValue()
        form.setFieldsValue({ ...data, 'exclude_creator': _.concat(form.getFieldValue('exclude_creator'), id) })
        form.submit();
    }
    const changeEditor = (id) => setModal({ ...modal, editor: true, target: id })
    const addTags = (id) => setModal({ ...modal, tag: true, target: id })
    const addIcons = (id) => setModal({ ...modal, icons: true, target: id })

    const handleMedia = {
        getMedia, handleStatus, updateProirity, exclude, addTags, addIcons, deleteMedia,
        pinMedia, delMediaEdior, reloadTable, getSaleGroupPrice, changeEditor
    }
    let defaultColumns = MediaTableConfig({ action, handleMedia, simpleMode, type: media_from })
    const localStorageColumnsName = "____MediaTableConfig____";
    disposeColumn(localStorageColumnsName, defaultColumns)

    const [columns, setColumns] = useState(defaultColumns)

    return <>
        <Space direction='vertical' size={16} className='full-width'>
            <Content>
                <Card
                    style={{ padding: 0 }}
                    title='媒体搜索'
                    bordered={false}
                    subTitle={
                        !simpleMode && <Switch checked={searchStyle === 'vertical'}
                            checkedChildren="竖版" unCheckedChildren="横板"
                            onChange={e => {
                                if (e) {
                                    setSearchStyle("vertical")
                                    localStorage.setItem(localStorageEditorStyleName, "vertical")
                                } else {
                                    setSearchStyle("horizontal")
                                    localStorage.setItem(localStorageEditorStyleName, "horizontal")
                                }
                            }} />}
                    extra={!simpleMode && [
                        <RoleModule action='module:media:add' data={{ action: action }} key='module:media:add'>
                            <Button.Group key='create-medias' style={{ marginRight: 8 }}>
                                {!simpleMode && <>
                                    <Button type="primary"
                                        onClick={() => {
                                            setMedia(CommonMedia)
                                            setVisible({ ...visible, addMedia: true, type: '', action: 'ADD' })
                                        }}>
                                        <PlusOutlined />添加媒体
                                    </Button>
                                    <Button type="primary"
                                        onClick={() => {
                                            setMedia(MCNMedia)
                                            setVisible({ ...visible, addMedia: true, type: 'MCN', action: 'ADD' });
                                        }}>
                                        <PlusOutlined />添加自媒体
                                    </Button>
                                </>}
                            </Button.Group>
                        </RoleModule>,
                        <RoleModule action='module:media:whitelist' key='module:media:whitelist'>
                            <Button key='media-whitelist' onClick={e => setModal({ ...modal, whitelist: true })}>查看白名单</Button>
                        </RoleModule>,
                        <Button key='media-blacklist' type='text' onClick={e => navigate("/dashboard/medias/blacklist")}>查看黑名单</Button>
                    ]}
                >
                    <Form
                        layout={searchStyle === 'horizontal' ? 'vertical' : 'horizontal'}
                        labelAlign="left"
                        name="media-form"
                        form={form}
                        initialValues={initialFormData}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                    >
                        {searchStyle === 'horizontal' && <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />}
                        {searchStyle === 'vertical' && <SearchFormHori searchDatas={searchDatas} reloadTable={reloadTable} />}
                        {/* <Divider style={{ margin: '8px 0' }} /> */}
                        <Row>
                            <Col span={12}>
                                {searchStyle === 'vertical' && <SearchFormInput searchDatas={searchDatas} reloadTable={reloadTable} />}
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Space>
                                    {action === 'CREATE' &&
                                        <Button.Group>
                                            <Button onClick={() => saveLocalStorageMedias(medias)}><SaveOutlined />保存媒体记录</Button>
                                            <Button onClick={() => loadLocalStorageMedias()}><CloudDownloadOutlined />读取媒体记录</Button>
                                        </Button.Group>}
                                    <Button.Group>
                                        {
                                            !simpleMode && <Space>
                                                <RoleModule action='module:media:export' data={{ action }}>
                                                    <Button onClick={() => downloadMedias()}>
                                                        <ExportOutlined /> 导出
                                                    </Button>
                                                </RoleModule>
                                                <RoleModule action='module:media:import:price'>
                                                    <Button.Group>
                                                        <Button icon={<ExportOutlined />} onClick={() => downloadMediasPrice()}>
                                                            导出价格
                                                        </Button>
                                                        <Upload {...props}>
                                                            <Button icon={<UploadOutlined />}>
                                                                导入价格
                                                            </Button>
                                                        </Upload>
                                                    </Button.Group>
                                                </RoleModule>
                                            </Space>
                                        }
                                    </Button.Group>
                                    <Button onClick={() => form.resetFields()}>
                                        <SyncOutlined /> 重置搜索
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={loading} >
                                        <SearchOutlined /> 搜索
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Content>
            <Card>
                <Table size='small'
                    title={hasTitle ? () => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} menu={menu && <MediaMenu setEventId={setEventId} />} /> : null}
                    columns={columns.filter(e => e.checked === 1)}
                    rowSelection={rowSelectionDisplay ? {
                        columnWidth: '24px',
                        selectedRowKeys: medias.map(e => e.id),
                        onSelect: rowOnSelect,
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            let tempMedias = changeRows.map(e => ({
                                id: e.id,
                                name: e.name,
                                price: e.user_price,
                                status: e.status
                            }))
                            let newMedias = selected ? _.concat(medias, tempMedias) : _.differenceBy(medias, tempMedias, 'id');
                            setMedias(_.uniqBy(newMedias, 'id'))
                        },
                        getCheckboxProps: record => ({
                            disabled: record.name === 'Disabled User',
                            name: record.name,
                        }),
                    } : null}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                />
            </Card>
        </Space>
        {/* 编辑媒体 */}
        <Drawer
            title={action === 'EDIT' ? "编辑媒体" : '创建媒体'}
            width={720}
            onClose={() => setVisible({ ...visible, addMedia: false })}
            open={visible.addMedia}
        >
            <Spin spinning={drawerLoading}>
                <AddMediaForm mediaType={visible.type} media={media} drawerAction={drawerAction} />
            </Spin>
        </Drawer>
        {/* 下载媒体提示框 */}
        <Modal
            open={modal.download}
            footer={null}
            onOk={() => setModal({ ...modal, download: false })}
            onCancel={() => setModal({ ...modal, download: false })}
            maskClosable={false}
        >
            <div style={{ textAlign: 'center' }}>
                <Spin tip="下载中..." />
            </div>
        </Modal>
        {/* 销售组分配价格 */}
        <Modal
            title="媒体价格分配"
            width={480}
            open={modal.saleGroupPrice}
            okText="确认"
            cancelText="取消"
            onOk={() => {
                saleGroupForm
                    .validateFields()
                    .then((values) => updateSaleGroupPrice(values))
            }}
            onCancel={() => setModal({ ...modal, saleGroupPrice: false })}
        >
            <Spin spinning={saleGroupLoading} >
                <SaleGroupForm datas={saleGroupDatas} form={saleGroupForm} />
            </Spin>
        </Modal>

        <Modal
            title='修改资源所属编辑'
            open={modal.editor}
            footer={null}
            onOk={() => setModal({ ...modal, editor: false })}
            onCancel={() => setModal({ ...modal, editor: false })}
            width={960}
        >
            <MediaEditorTable mid={modal.target} allotUser={allotUser} />
        </Modal>
        <Modal
            open={modal.tag}
            footer={null}
            onOk={() => setModal({ ...modal, tag: false })}
            onCancel={() => setModal({ ...modal, tag: false })}
            width={960}
        >
            <MediaTagTable />
        </Modal>
        <Modal
            open={modal.icons}
            footer={null}
            onOk={() => setModal({ ...modal, icons: false })}
            onCancel={() => setModal({ ...modal, icons: false })}
            width={960}
        >
            <MediaIconForm mid={modal.target} handleStatus={handleStatus} resetModal={resetModal} />
        </Modal>
        <Modal
            open={modal.whitelist}
            footer={null}
            onOk={() => setModal({ ...modal, whitelist: false })}
            onCancel={() => setModal({ ...modal, whitelist: false })}
            width={960}
        >
            <WhiteList />
        </Modal>
    </>;
}

export default MediaTable

const SaleGroupForm = ({ form, datas }) => {
    return <Form form={form} >
        <Row gutter={16}>
            {datas.map(e => <Col span={24} style={{ display: 'flex' }} key={e.sale_group_id}>
                <Form.Item style={{ flex: '1 0 0' }}>
                    <Text strong>{e.sale_group_name}</Text>
                </Form.Item>
                <Form.Item name={`price_${e.sale_group_id}`}>
                    <InputNumber min={0} />
                </Form.Item>
            </Col>)}
        </Row>
    </Form>
}