import React from 'react'
import { Tag, Typography, Button, Modal, Popconfirm, Space } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';
import qs from 'qs';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { formatTime } from '../../utils/TimeUtils'
import { check } from '../../utils/permission/rules';
import { ContentCell } from '../order/orderCommon'
import { resultResponse } from '../../Apps/common/ResultResponse';

const axios = require('axios');

const { Paragraph, Text } = Typography;
const { confirm } = Modal;
export const BaikeOrderTableConfig = ({ modal, setModal, handleOrders, tab, action = '' }) => {
    const { verifyOrderSuccess, cancelOrder, reloadTable } = handleOrders;
    const size = 'small'

    const role = useRecoilValue(userRoleState)
    const permission = {
        'module:media:order:money:cost': check(role, 'module:media:order:money:cost', { action }),
        'module:media:order:cost:modify': check(role, 'module:media:order:cost:modify', null),
        'module:media:order:money:paid': check(role, 'module:media:order:money:paid', { action }),
        'module:media:order:money:agent': check(role, 'module:media:order:money:agent', null),
        'module:media:order:money:sales:agent': check(role, 'module:media:order:money:sales:agent', null),
        'module:media:order:sales:status': check(role, 'module:media:order:sales:status', null),
        'module:media:order:status': check(role, 'module:media:order:status', null),
        'module:media:order:history': check(role, 'module:media:order:history', null),
        'module:media:order:editor:edit': check(role, 'module:media:order:editor:edit', null),
        'module:media:order:recall:handle': check(role, 'module:media:order:recall:handle', { action }),
        'module:media:order:error:handle': check(role, 'module:media:order:error:handle', { action }),
        'module:media:order:success:reject': check(role, 'module:media:order:success:reject', null),
        'module:media:order:success:handle': check(role, 'module:media:order:success:handle', { action }),
        'module:media:order:success:error': check(role, 'module:media:order:success:error', { action }),
        'module:media:order:publish:handle': check(role, 'module:media:order:publish:handle', { action }),
        'module:media:order:publish:recall': check(role, 'module:media:order:publish:recall', { action }),
        'module:media:order:verify:handle': check(role, 'module:media:order:verify:handle', { action }),
        'module:media:order:verify:cancel': check(role, 'module:media:order:verify:cancel', { action }),
        'module:media:order:customer:name': check(role, 'module:media:order:customer:name', null),
        'module:media:order:sales:name': check(role, 'module:media:order:sales:name', null),
    }

    // 订单已结算 
    const paidOrder = async (id, status) => {
        await axios({
            method: 'post',
            url: "/baike/order/pay",
            data: qs.stringify({
                ids: id,
                paid_status: status,
            })
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
        })
    }

    const paidSalesOrder = async (id, status) => {
        await axios({
            method: 'post',
            url: "/baike/order/sales/pay",
            data: qs.stringify({
                ids: id,
                sales_status: status,
            })
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
        })
    }

    const orderSalesAndCustomer = {
        title: '销售姓名',
        dataIndex: 'sale_name',
        render: (t, data) => {
            return <Space direction='vertical' size={2}>
                {permission['module:media:order:customer:name'] && <Text strong>{data.customer_name}</Text>}
                {permission['module:media:order:sales:name'] && <Text>{data.sale_name}</Text>}
            </Space >
        }
    }

    const defaultConfig = [
        {
            title: '媒体名称',
            dataIndex: 'baike_name',
            width: 120
        }, {
            title: '标题',
            dataIndex: 'title',
            render: (text, data) => {
                let url = `http://p.yunzitui.com/post/${data.order_no}`
                let copyUrl = data.source_url === "" ? url : data.source_url
                let copyText = `媒体：${data.media_name}\n标题：${data.title}\n地址：${copyUrl}\n`
                return (
                    <>
                        <Paragraph style={{ marginBottom: 0 }} copyable={{
                            text: (data.remarks === '' || data.remarks === null) ? copyText : copyText + `备注：${data.remarks}`
                        }}>{text}</Paragraph>
                    </>
                )
            }
        }
    ]

    const orderPrice = {
        title: '价格',
        dataIndex: 'paid_money',
        width: 96,
        render: (t, data) => {
            return <Space direction='vertical' size={2}>
                {permission['module:media:order:money:cost'] && <Text strong>成本：{data.cost_money}</Text>}
                {permission['module:media:order:money:agent'] && <Text strong>相对：{data.agent_paid_money}</Text>}
                {permission['module:media:order:money:sales:agent'] && <Text strong>成本：{data.agent_paid_money}</Text>}
                {permission['module:media:order:money:paid'] && <Text strong>付款：{data.paid_money}</Text>}
                {data.add_money > 0 && <Text type="secondary">额外付款{data.add_money}</Text>}
            </Space>
        }
    }

    const orderContent = [
        {
            title: '内容',
            dataIndex: 'content',
            width: 96,
            render: (text, data) => <ContentCell data={data} />
        }, {
            title: '备注',
            dataIndex: 'remarks'
        }
    ]

    const orderTime = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            render: (t) => formatTime(t)
        }, {
            title: '审核时间',
            dataIndex: 'handle_time',
            width: 96,
            render: (t) => formatTime(t)
        }, {
            title: '发布时间',
            dataIndex: 'publish_time',
            width: 96,
            render: (t) => formatTime(t)
        },
    ]

    const orderStatus = {
        title: '状态',
        dataIndex: 'status',
        width: 72,
        fixed: 'right',
        render: (text, data) => {
            let result = '';
            switch (data.status) {
                case 'NOT_PASS':
                    return <Tag style={{ margin: 0 }} color="red">未通过</Tag>;
                case 'WAIT_VERIFY':
                    return <Tag style={{ margin: 0 }} color="blue">待审核</Tag>;
                case 'WAIT_PUBLISH':
                    return <Tag style={{ margin: 0 }} color="gold">待发布</Tag>;
                case 'UNCONFIRMED':
                    return <Tag style={{ margin: 0 }} color="geekblue">待确认</Tag>;
                case 'SUCCESS':
                    return <Tag style={{ margin: 0 }} color="green">已发布</Tag>;
                case 'BACK':
                    return <Tag style={{ margin: 0 }} color="red">已退稿</Tag>;
                default:
                    break;
            }
            return result
        }
    }

    const orderStatusCol = {
        title: '结算状态',
        dataIndex: 'paid_status',
        width: 72,
        checked: 1,
        render: (val, data) => {
            let obj = val === 'unpaid'
                ? { color: 'red', text: '未支付', state: 'paid' }
                : { color: 'green', text: '已支付', state: 'unpaid' }
            // 必须为管理员才能处理结算状态
            return <Tag style={{ margin: 0 }} onClick={() => permission['module:media:order:status'] && paidOrder(data.id, obj.state)} color={obj.color}>
                {obj.text}
            </Tag>
        }
    }

    const salesOrderStatusCol = {
        title: '结算状态',
        dataIndex: 'sales_status',
        width: 72,
        checked: 1,
        render: (val, data) => {
            let obj = val === 'unpaid'
                ? { color: 'red', text: '未打款', state: 'paid' }
                : { color: 'green', text: '已打款', state: 'unpaid' }
            return <Tag style={{ margin: 0 }} onClick={() => permission['module:media:order:sales:status'] && paidSalesOrder(data.id, obj.state)} color={obj.color}>{obj.text}</Tag>
        }
    }

    const orderBackInfo = {
        title: '回执信息',
        dataIndex: 'back_info',
        width: 160,
        render: (text, data) => {
            let result = '';
            if (data.reason) {
                result = data.reason;
            } else if (data.back_info) {
                result = data.back_info;
            }
            switch (data.status) {
                case 'WAIT_VERIFY':
                    return <Popconfirm
                        title="确认撤销稿件吗?"
                        onConfirm={() => cancelOrder(data.id)}
                        onCancel={() => console.log("取消")}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button>撤稿</Button>
                    </Popconfirm>;
                case 'WAIT_PUBLISH':
                    return <Tag style={{ margin: 0 }} color="gold">待发布</Tag>;
                case 'UNCONFIRMED':
                    return <>
                        <Button onClick={e => {
                            setModal({
                                ...modal,
                                target_id: data.id,
                                unconfirm: true,
                                order: data
                            })
                        }}>查看详情</Button>
                    </>;
                case 'SUCCESS':
                    result = <>
                        <Button size={size} className='table-btn'
                            onClick={() => window.open(data.back_info, '_blank')}>查看</Button>
                    </>
                    break;
                default:
                    result = <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>{result}</Paragraph>
                    break;
            }
            return result
        }
    }

    const handleOrder = {
        title: '处理订单',
        dataIndex: 'back_info',
        width: 160,
        render: (text, data) => {
            let result = '';
            if (data.reason) {
                result = data.reason;
            } else if (data.back_info) {
                result = data.back_info;
            }
            switch (data.status) {
                case 'WAIT_VERIFY':
                    return <Space>
                        <Button size={size} type="primary"
                            onClick={() => {
                                verifyOrderSuccess(data.id)
                            }}
                        >通过</Button>
                        <Button size={size} danger
                            onClick={() => {
                                setModal({
                                    ...modal, target_id: data.id, verifyFailed: true
                                })
                            }}>不通过</Button>
                    </Space>
                case 'WAIT_PUBLISH':
                    return <Space>
                        <Button size={size} type="primary"
                            onClick={() => {
                                setModal({
                                    ...modal, target_id: data.id, publishSuccess: true
                                })
                            }}>回执</Button>
                        <Button size={size} danger
                            onClick={() => {
                                setModal({
                                    ...modal, target_id: data.id, publishOrderFailed: true
                                })
                            }}>退稿</Button>
                    </Space>
                case 'UNCONFIRMED':
                    return <Button size={size}
                        onClick={() => {
                            confirm({
                                content: <>
                                    <Paragraph>理由: <Text strong>{data.reason}</Text></Paragraph>
                                    <Paragraph>需要再支付<Text strong>{data.add_money}</Text>元</Paragraph>
                                    <Paragraph>总计共支付<Text strong>{data.add_money + data.paid_money}</Text>元</Paragraph>
                                </>
                            })
                        }}>查看</Button>;
                case 'SUCCESS':
                    result = (
                        <Space>
                            <Button type="primary" size={size}
                                onClick={() => {
                                    setModal({ ...modal, target_id: data.id, updateUrl: true })
                                }}>编辑</Button>
                            <Button size={size} className='table-btn'
                                onClick={() => window.open(data.back_info, '_blank')}>查看</Button>
                        </Space>
                    );
                    break;
                default:
                    break;
            }
            return result
        }
    }



    let result = []
    let defaultOrder = _.concat(
        defaultConfig,
        orderContent,
        orderPrice,
    )

    switch (role) {
        case "CUSTOMER":
            result = _.concat(
                defaultOrder,
                orderTime,
                orderStatus,
                orderBackInfo,
            )
            break;
        case "OPERATOR":
        case "OPERATOR_ADMIN":
        case "OPERATOR_BAIKE":
            result = _.concat(
                orderSalesAndCustomer,
                defaultOrder,
                orderTime,
                orderStatusCol,
                orderStatus,
                handleOrder,
            )
            break;
        case "SALE":
        case 'SUPPORT_STAFF':
        case 'FRANCHISE':
        case "SALE_LEADER":
        case "SALE_ATTACHE":
            result = _.concat(
                orderSalesAndCustomer,
                defaultOrder,
                orderTime,
                salesOrderStatusCol,
                orderStatus,
                orderBackInfo,
            )
            break;
        default:
            break;
    }
    return result
}
