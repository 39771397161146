import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import {
    MCNs, mediaChannelTypes, areas, mediaDoorTypes, mediaEffectTypes
} from '../../Apps/common/media/MediaInfo'
import media_json from '../../json/media.json'

const MediaDatasConfig = ({ mediaType }) => {
    const role = useRecoilValue(userRoleState)
    let isMCN = mediaType === 'MCN'

    const getDomain = (domain) => {
        if (domain) {
            let str = _.split(domain, '//')
            if (_.size(str) > 1) {
                let path = _.split(str[1], '/')
                return path[0]
            } else {
                return domain
            }
        } else {
            return ''
        }
    }

    const mcnMediaBasicDatas = [
        {
            name: 'door_type',
            label: '媒体平台',
            required: true,
            placeholder: '请选择媒体平台',
            type: 'select',
            children: MCNs.map(e => e.text)
        }
    ]

    const mediaBasicDatas = [
        {
            name: 'name',
            label: '媒体名称',
            required: true,
            placeholder: '请输入媒体名称',
            type: 'input'
        },
        {
            name: 'sample_url',
            label: '案例地址',
            required: true,
            placeholder: '请输入案例地址',
            type: 'input'
        },
    ]

    const mediaCustomerPriceDatas = [
        {
            name: 'cost',
            label: '媒体价格',
            required: true,
            placeholder: '请输入媒体价格',
            type: 'inputnumber'
        },
        {
            name: 'sale_price',
            label: '相对成本',
            placeholder: '请输入相对成本',
            hidden: true
        }
    ]

    const mediaPriceDatas = [
        {
            name: 'cost',
            label: '媒体价格',
            required: true,
            placeholder: '请输入媒体价格',
            type: 'inputnumber'
        },
        {
            name: 'sale_price',
            label: '相对成本',
            placeholder: '请输入相对成本',
            type: 'inputnumber'
        }
    ]

    const mediaPropsDatas = [
        {
            name: 'effect',
            label: '收录类型',
            placeholder: '请输入媒体收录类型',
            required: true,
            type: 'radio',
            children: mediaEffectTypes.map(e => [e, e])
        },
        {
            name: 'channel_type',
            label: '媒体频道',
            placeholder: '请输入媒体媒体频道',
            required: true,
            type: 'select',
            children: mediaChannelTypes
        },
        {
            name: 'door_type',
            label: '门户类型',
            placeholder: '请输入媒体门户类型',
            required: true,
            type: 'select',
            children: mediaDoorTypes,
            rule: ({ getFieldValue }) => ({
                validator(rule, value) {
                    if (value && value === '中国五大门户') {
                        if (media_json['wudamenhu'].some((e) => getDomain(getFieldValue('sample_url')).indexOf(e['domain']) !== -1)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('不属于五大门户!!!!'));
                    }
                    if (value && value === '中央新闻门户') {
                        if (media_json['zhongyangxinwen'].some((e) => getDomain(getFieldValue('sample_url')).indexOf(e['domain']) !== -1)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('不属于中央新闻门户!!!!'));
                    }
                    return Promise.resolve()
                },
            }),
        },
        {
            name: 'area',
            label: '媒体地区',
            placeholder: '请输入媒体地区',
            required: true,
            type: 'select',
            children: areas
        },
        {
            name: 'end_date',
            label: '截稿时间',
            placeholder: '请输入媒体名称',
            required: true,
            type: 'timepicker',
            value: '18:00:00'
        },
        {
            name: 'week_available',
            label: '媒体周末状态',
            type: 'radio',
            required: true,
            children: [
                ['周末可发', '周末可发'],
                ['周末休息', '周末休息'],
            ]
        },
    ]

    const mcnMediaPropsDatas = [
        {
            name: 'area',
            label: '媒体地区',
            placeholder: '请选择媒体地区',
            required: true,
            type: 'select',
            children: areas
        },
        {
            name: 'auth_type',
            label: '认证类型',
            placeholder: '请输入认证类型',
            type: 'radio',
            children: [
                ['官方认证', 1],
                ['个人认证', 2],
                ['未认证', 3],
            ]
        },
        {
            name: 'available_time',
            label: '稿件时效',
            placeholder: '请输入稿件时效',
            required: true,
            type: 'radio',
            children: [
                ['一天', 1],
                ['三天', 2],
                ['一周', 3],
                ['一个月', 4],
                ['一个月以上', 5],
            ]
        },
        {
            name: 'fans_num',
            label: '粉丝数量',
            placeholder: '请输入粉丝数量',
            required: true,
            type: 'radio',
            children: [
                ['0-1000', 1],
                ['1000-5000', 2],
                ['5000-1万', 3],
                ['1万-5万', 4],
                ['5万-10万', 5],
                ['10万-50万', 6],
                ['50万以上', 7],
            ]
        },
        {
            name: 'page_views',
            label: '媒体阅读量',
            placeholder: '请输入媒体阅读量',
            required: true,
            type: 'radio',
            children: [
                ['0-1000', 1],
                ['1000-5000', 2],
                ['5000-1万', 3],
                ['1万-5万', 4],
                ['5万-10万', 5],
                ['10万以上', 6],
            ]
        },
    ]
    const mediaBasis = _.concat(isMCN ? mcnMediaBasicDatas : [], mediaBasicDatas)
    const mediaBaseData = isMCN ? mcnMediaPropsDatas : mediaPropsDatas;

    const editorDatas = [
        {
            name: 'editor_name',
            label: '编辑姓名',
            placeholder: '请输入编辑姓名',
            type: 'input'
        },
        {
            name: 'editor_tel',
            label: '联系方式',
            placeholder: '请输入联系方式',
            type: 'input',
            required: true
        },
    ]

    const commentDatas = [
        {
            name: 'comment',
            label: '备注',
            placeholder: '请输入备注',
            type: 'area'
        },
    ]

    let result = []

    switch (role) {
        case "CUSTOMER":
            result = [
                mediaBasis,
                mediaCustomerPriceDatas,
                mediaBaseData,
                editorDatas,
                commentDatas
            ]
            break;
        case "OPERATOR":
        case "OPERATOR_LEADER":
            result = [
                mediaBasis,
                mediaPriceDatas,
                mediaBaseData,
                editorDatas,
                commentDatas
            ]
            break;
        case "SALE_ATTACHE":
            result = [
                mediaBasis,
                mediaPriceDatas,
                mediaBaseData,
                editorDatas,
                commentDatas
            ]
            break;
        default:
            break;
    }

    return result
}

export default MediaDatasConfig