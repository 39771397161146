import React, { useState } from 'react';
import { Layout, Tabs,  } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageHeader from '../../layout/PageHeader';

import WechatTable from './WechatTable';
const { Content } = Layout;


const Wechat = () => {
    const [tab, setTab] = useState("hots")
    let pageTitle = "微信中心"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
                <meta name="referrer" content="never" />
            </Helmet>
            <Content style={{ background: "#fff", marginBottom: '24px' }}>
                <PageHeader
                    title="微信中心"
                    style={{ backgroundColor: '#fff' }}
                    footer={
                        <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                            items={[
                                { key: 'hots', label: `微信公众号`, },
                            ]}
                        />
                    }
                />
            </Content>
            <div style={{ margin: 24 }}>
                {tab === 'hots' && <WechatTable />}
            </div >
        </HelmetProvider>
    )
}

export default Wechat;