import React, { useState, useEffect } from 'react';
import { PlusOutlined, ExportOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Form, Table, Row, Col, Button, Divider, Drawer, message, Layout,
    Switch, Modal, Spin, Input, Typography, Upload, Space
} from 'antd';
import _ from 'lodash'
import qs from 'qs';
import { useRecoilValue } from 'recoil';

import { userRoleState, userState } from '../../atoms/Atoms'
import AddMediaIndexForm from './AddMediaIndexForm';
import SearchForm from '../../common/SearchForm'
import MediaIndexSearchConfig from '../../../role/index/MediaIndexSearchConfig'
import MediaIndexTableConfig from '../../../role/index/MediaIndexTableConfig'
import { CommonMediaIndex } from '../../../entities/MediaIndex'
import { initFormData, tablePagination, TableTitle } from '../../common/tableCommon';
import { resultResponse } from '../../common/ResultResponse';
import MediaIndexEditorTable from './MediaIndexEditorTable';
import { SearchFormHori, SearchFormInput } from '../../common/SearchFormTile';
import PageHeader from '../../layout/PageHeader';

const { Text } = Typography;
const { Content } = Layout;
const axios = require('axios');
const MediaIndexTable = ({ action, medias = [], setMedias = () => console.log(""),
    url, simpleMode = false
}) => {

    const sessionUser = useRecoilValue(userState)
    const role = useRecoilValue(userRoleState)
    const attache = sessionUser.role.attache

    const isEditor = role === 'CUSTOMER' && action === 'EDIT'
    const isOperator = role === 'OPERATOR'

    const [form] = Form.useForm();                  // 媒体搜索form
    const [saleGroupForm] = Form.useForm();

    const searchDatas = MediaIndexSearchConfig({})
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [visible, setVisible] = useState({ addMedia: false, action: 'ADD' })
    const [media, setMedia] = useState(CommonMediaIndex)
    const [drawerLoading, setDrawerLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [modal, setModal] = useState({ download: false, saleGroupPrice: false, editor: false, target: '' })
    const [saleGroupDatas, setSaleGroupDatas] = useState([])
    const [saleGroupLoading, setSaleGroupLoading] = useState(false)

    // 编辑器样式
    const localStorageEditorStyleName = "____SearchStyle____";
    const [searchStyle, setSearchStyle] = useState(localStorage.getItem(localStorageEditorStyleName) ?? 'vertical')

    const reloadTable = () => form.submit()
    const resetModal = () => setModal({ download: false, saleGroupPrice: false, editor: false, target: '' })

    const resetMedia = () => setMedia(CommonMediaIndex)

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val)
                .filter(e => e[1].length > 0)
                .forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: url || '/mediaIndex/getMediaIndexList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    const rowSelection = {
        selectedRowKeys: medias.map(e => e.id),
        onChange: (selectedRowKeys, selectedRows) => {
            const data = selectedRows.map(e => {
                return ({
                    id: e.id,
                    name: e.name,
                    price: e.user_price
                })
            })
            setMedias(data)
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', 
            name: record.name,
        }),
    };


    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    // 初始化媒体分配价格
    useEffect(() => {
        if (_.size(saleGroupDatas) > 0) {
            let result = {}
            saleGroupDatas.map(e => _.assign(result, {
                [`price_${e.sale_group_id}`]: e.price
            }))
            saleGroupForm.setFieldsValue(result)
        }
    }, [saleGroupDatas, saleGroupForm])

    const createMedia = async (media) => {
        let formData = new FormData();
        _.toPairs(media).map(e => formData.append(`${e[0]}`, e[1]))
        fileList.forEach(file => {
            formData.append('upload_files', file);
        })
        await axios({
            method: 'post',
            url: '/mediaIndex/addMediaIndex',
            data: formData
        }).then((res) => {
            resultResponse(res, '已成功添加')
            resetMedia()
            reloadTable()
        })
    }

    const updateMedia = async (media) => {
        let formData = new FormData();
        _.toPairs(media).map(e => formData.append(`${e[0]}`, e[1]))
        fileList.forEach(file => {
            formData.append('upload_files', file);
        })
        await axios({
            method: 'post',
            url: '/mediaIndex/editMediaIndex',
            data: formData
        }).then((res) => {
            resultResponse(res, '已成功修改')
            resetMedia()
            reloadTable()
        })
    }

    const downloadMedias = async () => {
        setModal({ ...modal, download: true })
        let data = _.pickBy(form.getFieldsValue(), _.identity);
        await axios({
            url: `/mediaIndex/downLoadMediaIndex?${qs.stringify(data)}`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '媒体资源详情.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }

    const downloadMediasPrice = async () => {
        setModal({ ...modal, download: true })
        let data = _.pickBy(form.getFieldsValue(), _.identity);
        await axios({
            url: `/mediaIndex/downLoadPackageMediaPrice?${qs.stringify(data)}`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '媒体价格分配.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
        setModal({ ...modal, download: false })
    }


    const props = {
        name: 'upload_file',
        action: '/mediaIndex/importPrice',
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} 上传成功!`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败!`);
            }
        },
    };


    const drawerAction = { visible, setVisible, createMedia, updateMedia, fileList, setFileList }

    const getMedia = async (id) => {
        setVisible({ ...visible, addMedia: true, action: 'EDIT' })
        setDrawerLoading(true)
        await axios({
            method: 'post',
            url: url || `/mediaIndex/getMediaIndexList`,
            data: qs.stringify({ id })
        }).then((res) => {
            let tempMedia = res.data.data[0]
            setMedia(_.pick(tempMedia, _.keysIn(media)))
            setDrawerLoading(false)
        })
    }


    const deleteMedia = async (id) => {
        await axios({
            method: 'post',
            url: `/mediaIndex/delete`,
            data: qs.stringify({ id })
        }).then((res) => {
            resultResponse(res, '已成功删除')
            reloadTable()
        })
    }

    const getTitle = () => {
        let title = '添加媒体';
        if (action === 'ADD') {
            title = '添加媒体'
        } else if (action === 'EDIT') {
            title = '编辑媒体'
        }
        return title
    }

    const handleStatus = async (id, key, val) => {
        await axios({
            method: 'post',
            url: `/mediaIndex/editMediaIndex`,
            data: qs.stringify({
                id,
                is_common: 'yes',
                [key]: val
            })
        }).then((res) => {
            resultResponse(res, '已成功修改')
            reloadTable()
        })
    }

    const getSaleGroupPrice = async (id, type) => {
        setSaleGroupLoading(true)
        setModal({ ...modal, saleGroupPrice: true })
        await axios({
            method: 'get',
            url: `/mediaIndex/getSaleGroupPrices?id=${id}&type=${type}`,
        }).then(res => {
            if (res.data.code === 1) {
                setSaleGroupDatas(res.data.data)
                setSaleGroupLoading(false)
            } else {
                message.error(res.data.message)
            }
        })
    }

    const updateSaleGroupPrice = async (val) => {
        let formData = new FormData()
        let tmp = _.pickBy(val, (v, k) => parseInt(v) > 0)
        formData.append('resource_id', saleGroupDatas[0].resource_id)
        formData.append('prices', JSON.stringify(tmp))
        await axios({
            method: 'post',
            url: `/mediaIndex/update/saleGroupPrice`,
            data: formData
        }).then(res => {
            resultResponse(res, '已更新')
            setModal({ ...modal, saleGroupPrice: false })
        })
    }

    const changeEditor = (id) => setModal({ ...modal, editor: true, target: id })


    const handleMedia = {
        getMedia, getSaleGroupPrice, handleStatus,
        reloadTable, deleteMedia, changeEditor
    }
    let defaultColumns = MediaIndexTableConfig({ action, handleMedia })
    const localStorageColumnsName = "____MediaIndexTableConfig____";
    let localStorageColumns = JSON.parse(localStorage.getItem(localStorageColumnsName))
    _.forEach(defaultColumns, (e) => {
        _.forEach(localStorageColumns, (val) => {
            if (e.title === val.title) {
                e.checked = val.checked
            }
        })
    })
    const [columns, setColumns] = useState(defaultColumns)

    return <>
        <Content className='table-search'>
            <PageHeader
                style={{ padding: 0 }}
                title={<Text level={4}>媒体搜索</Text>}
                subTitle={
                    !simpleMode && <Switch checked={searchStyle === 'vertical'}
                        checkedChildren="竖版" unCheckedChildren="横板"
                        onChange={e => {
                            if (e) {
                                setSearchStyle("vertical")
                                localStorage.setItem(localStorageEditorStyleName, "vertical")
                            } else {
                                setSearchStyle("horizontal")
                                localStorage.setItem(localStorageEditorStyleName, "horizontal")
                            }
                        }} />}
                extra={!simpleMode && [
                    (isEditor || isOperator) &&
                    <Button.Group key='create-medias' style={{ marginRight: 8 }}>
                        {!simpleMode && <>
                            <Button type="primary" onClick={() => setVisible({ ...visible, addMedia: true, type: '' })}>
                                <PlusOutlined />添加资源
                            </Button>
                        </>}
                    </Button.Group>
                ]}
            >
                <Form
                    layout={searchStyle === 'horizontal' ? 'vertical' : 'horizontal'}
                    labelAlign="left"
                    name="media-form"
                    initialValues={initialFormData}
                    form={form}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    {searchStyle === 'horizontal' && <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />}
                    {searchStyle === 'vertical' && <SearchFormHori searchDatas={searchDatas} reloadTable={reloadTable} />}
                    <Divider />
                    <Row>
                        <Col span={12}>
                            {searchStyle === 'vertical' && <SearchFormInput searchDatas={searchDatas} reloadTable={reloadTable} />}
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button.Group>
                                    {
                                        (action !== 'EDIT' && sessionUser.id > 0 && !simpleMode && role === 'CUSTOMER') &&
                                        <Button onClick={() => downloadMedias()}>
                                            <ExportOutlined /> 导出
                                        </Button>
                                    }
                                    {
                                        (sessionUser.id > 0 && !simpleMode && (role === 'SALE' || role === 'FRANCHISE')) &&
                                        <>
                                            <Button onClick={() => downloadMediasPrice()}>
                                                <ExportOutlined /> 导出价格
                                            </Button>
                                            {attache === 0 && <Upload {...props}>
                                                <Button icon={<UploadOutlined />}>导入价格</Button>
                                            </Upload>}
                                        </>
                                    }
                                </Button.Group>
                                <Button type="primary" htmlType="submit" loading={loading}>搜索</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </PageHeader>
        </Content>
        <Content style={{ background: '#fff', padding: 24, margin: 0, }}        >
            <Table
                size='middle'
                title={() => <TableTitle columns={columns} setColumns={setColumns} name={localStorageColumnsName} />}
                columns={columns.filter(e => e.checked === 1)}
                rowSelection={rowSelection}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>

        <Drawer
            title={getTitle()}
            width={720}
            onClose={() => setVisible({ ...visible, addMedia: false })}
            open={visible.addMedia}
            bodyStyle={{ paddingBottom: 80 }}
        >
            {
                drawerLoading
                    ? <div style={{ textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Spin style={{ display: 'flex', alignContent: 'center' }} />
                    </div>
                    : <AddMediaIndexForm media={media} drawerAction={drawerAction} />
            }
        </Drawer>
        {/* 销售组分配价格 */}
        <Modal
            forceRender
            title="媒体价格分配"
            width={480}
            open={modal.saleGroupPrice}
            footer={null}
            onOk={() => setModal({ ...modal, saleGroupPrice: false })}
            onCancel={() => setModal({ ...modal, saleGroupPrice: false })}
        >
            {
                saleGroupLoading
                    ? <Spin />
                    : <Form form={saleGroupForm} onFinish={val => updateSaleGroupPrice(val)}>
                        <Row gutter={16}>
                            {saleGroupDatas.map(e => <React.Fragment key={e.sale_group_id}>
                                <Col span={24} style={{ display: 'flex' }}>
                                    <Form.Item style={{ flex: '1 0 0' }}>
                                        <Text strong>{e.sale_group_name}</Text>
                                    </Form.Item>
                                    <Form.Item name={`price_${e.sale_group_id}`}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </React.Fragment>
                            )}
                        </Row>
                        <Button type="primary" htmlType="submit">保存</Button>
                    </Form>
            }
        </Modal>
        {/* 下载媒体提示框 */}
        <Modal
            open={modal.download}
            footer={null}
            onOk={() => setModal({ ...modal, download: false })}
            onCancel={() => setModal({ ...modal, download: false })}
        >
            <div style={{ textAlign: 'center' }}>
                <Spin tip="下载中..." />
            </div>
        </Modal>
        <Modal
            open={modal.editor}
            footer={null}
            onOk={() => setModal({ ...modal, editor: false })}
            onCancel={() => setModal({ ...modal, editor: false })}
            width={960}
        >
            <MediaIndexEditorTable mid={modal.target} resetModal={resetModal} reloadTable={reloadTable} />
        </Modal>
    </>;
}

export default MediaIndexTable