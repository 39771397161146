export const MCNs = [
    { id: '百家号', text: '百家号' },
    { id: '今日头条', text: '今日头条' },
    { id: '一点资讯', text: '一点资讯' },
    { id: '网易号', text: '网易号' },
    { id: '新浪看点', text: '新浪看点' },
    { id: '腾讯快报', text: '腾讯快报' },
    { id: '凤凰号', text: '凤凰号（大风号）' },
    { id: '搜狐号', text: '搜狐号' },
    { id: '新浪乐居', text: '新浪乐居' },
    { id: '搜狐焦点家居', text: '搜狐焦点家居' },
    { id: '知乎专栏', text: '知乎专栏' },
    { id: 'UC头条', text: 'UC头条' },
    { id: '东方头条', text: '东方头条' },
    { id: '豆瓣', text: '豆瓣' },
    { id: '北京时间', text: '北京时间' },
    { id: '简书', text: '简书' },
    { id: '东方财富号', text: '东方财富号' },
    { id: '汽车头条', text: '汽车头条' },
    { id: '易车号', text: '易车号' },
    { id: '车家号', text: '车家号' },
    { id: '新浪博客', text: '新浪博客' },
    { id: '封面新闻', text: '封面新闻' },
    { id: '触电新闻', text: '触电新闻' },
    { id: '重庆晨报', text: '重庆晨报' },
    { id: '中金在线', text: '中金在线' },
    { id: '同花顺', text: '同花顺' },
    { id: '360个人图书馆', text: '360个人图书馆' },
    { id: '人民号', text: '人民号' },
    { id: '艾瑞网', text: '艾瑞网' },
    { id: '趣头条', text: '趣头条' },
    { id: '雪球号', text: '雪球号' },
    { id: '齐鲁壹点', text: '齐鲁壹点' },
    { id: '澎湃新闻', text: '澎湃新闻' },
    { id: '快传号', text: '快传号' },
    { id: '马蜂窝', text: '马蜂窝' },
    { id: '驴妈妈', text: '驴妈妈' },
    { id: '携程', text: '携程' },
    { id: '太平洋号', text: '太平洋号' },
    { id: 'Zaker', text: 'Zaker' },
    { id: '和讯号', text: '和讯号' },
    { id: '金色财经', text: '金色财经' },
    { id: '人民强国', text: '人民强国' },
    { id: '新华客户端', text: '新华客户端' },
    { id: '环游号', text: '环游号' },
    { id: '途牛', text: '途牛' },
    { id: '去哪儿', text: '去哪儿' },
    { id: '爱奇艺', text: '爱奇艺' },
    { id: '小红书', text: '小红书' },
    { id: '搜狗号', text: '搜狗号' },
    { id: '维科号', text: '维科号' },
    { id: '云掌号', text: '云掌号' },
    { id: '惠头条', text: '惠头条' },
    { id: '华人号', text: '华人号' },
    { id: '健康界', text: '健康界' },
    { id: 'CSDN', text: 'CSDN' },
    { id: '美柚', text: '美柚' },
    { id: '美篇', text: '美篇' },
    { id: '其他', text: '其他' }
]

export const mediaChannelTypes = [
    '新闻资讯', '财经金融', 'IT科技数码', '生活消费',
    '酒店住宿', '女性时尚', '房产家居', '汽车配饰',
    '游戏动漫', '影视娱乐', '文化艺术', '健康医疗',
    '鞋帽服饰', '亲子母婴', '教育培训', '贸易能源',
    '食品餐饮', '五金建材', '企业', '体育运动',
    '区块链', '环保公益', '旅游交通', '人资管理',
    '婚嫁摄影', '农林畜牧', '通信电子', '安全防护',
    '水利能源', '工业制造', '服装纺织', '电商物流'
]

export const areas = [
    '全国', '北京', '上海', '广东', '浙江', '湖北',
    '湖南', '江苏', '陕西', '天津', '山西', '重庆',
    '河北', '河南', '四川', '安徽', '福建', '广西', '宁夏',
    '山东', '辽宁', '吉林', '海南', '内蒙古', '黑龙江', '新疆', '西藏',
    '江西', '贵州', '云南', '甘肃', '青海', '台湾', '其它',
]

export const mediaDoorTypes = [
    '中国五大门户', '中央新闻门户', '全国商业门户',
    '地方重点门户', '地方商业门户', '海外媒体'
]

export const mediaEffectTypes = [
    '网页收录', '包网页收录',
    '新闻源收录', '包新闻源收录'
]