import React, { useState } from 'react';
import { Tabs,  } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageHeader from '../../layout/PageHeader';

import WithdrawTable from './WithdrawTable';
import WithdrawManageTable from './WithdrawManageTable';

// 用户选择订单申请提现
// 查看已申请订单
// /dashboard/withdraw/v2/list
const Withdraw = () => {

    let pageTitle = "提现管理"
    const [tab, setTab] = useState('1')

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: '1', label: `订单提现`, },
                            { key: '2', label: `提现查询`, },
                        ]}
                    />
                }
            />
            < div style={{ margin: 24 }}>
                {tab === '1' &&
                    <WithdrawTable />
                }
                {
                    tab === '2' &&
                    <WithdrawManageTable />
                }
            </div >
        </HelmetProvider >
    )
}

export default Withdraw;