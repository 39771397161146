import React from 'react'
import { Typography, Space } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const { Text } = Typography;

export const UserNewSalaryTableConfig = ({ handleUsers }) => {
    const role = useRecoilValue(userRoleState)

    const defaultConfig = [
        {
            title: '销售名称',
            dataIndex: 'sale_name',
            render: (val, data) => {
                return <Space>
                    <Text strong style={{ fontSize: 14 }}>{data.sale_name}</Text>
                </Space>
            }
        }, {
            title: '客户名称',
            dataIndex: 'customer_name',
            render: (val, data) => {
                return <Space>
                    <Text strong style={{ fontSize: 14 }}>{data.customer_name}</Text>
                </Space>
            }
        }, {
            title: '订单汇总',
            dataIndex: 'id',
            render: (t, data) => {
                // 退款订单总数
                let array = data?.details
                let backNum = _.reduce(array, (money, e) => {
                    return money + (e.temp_status === 'BACK' ? e?.num : 0)
                }, 0)
                let successNum = _.reduce(array, (money, e) => {
                    return money + (e.temp_status === 'SUCCESS' ? e?.num : 0)
                }, 0)
                let errorNum = _.sumBy(array, 'total_times')
                // 异常总数
                return <Space direction='vertical' size='small'>
                    <Text strong>已发布: {successNum}</Text>
                    <Text>已退稿: {backNum}</Text>
                    <Text>异常次数: {errorNum}</Text>
                </Space>
            }
        }, {
            title: '已打款订单',
            dataIndex: 'paid',
            render: (t, data) => {
                // 退款订单总数
                let array = data?.details
                // 已发布 已付款
                let paid = _.find(array, e => e.temp_status === 'SUCCESS' && e.sales_status === 'paid')
                let paid_totalPaid = paid?.total_paid
                let paid_totalCost = paid?.total_cost
                return <Space direction='vertical' size='small'>
                    <Text strong>客户付款: {paid_totalPaid}</Text>
                    <Text strong>订单成本: {paid_totalCost}</Text>
                    <Text style={{ fontSize: 16 }} strong>利润率: {getProfitRate(paid_totalPaid, paid_totalCost) + `%`}</Text>
                </Space>
            }
        }, {
            title: '未打款订单',
            dataIndex: 'unpaid',
            render: (t, data) => {
                // 退款订单总数
                let array = data?.details
                // 已发布 未付款
                let unpaid = _.find(array, e => e.temp_status === 'SUCCESS' && e.sales_status === 'unpaid')
                let unpaid_totalPaid = unpaid?.total_paid
                let unpaid_totalCost = unpaid?.total_cost
                return <Space direction='vertical' size='small'>
                    <Text strong>客户付款: {unpaid_totalPaid}</Text>
                    <Text strong>订单成本: {unpaid_totalCost}</Text>
                    <Text style={{ fontSize: 16 }} strong>利润率: {getProfitRate(unpaid_totalPaid, unpaid_totalCost) + `%`}</Text>
                </Space>
            }
        },
    ]


    let result = []

    switch (role) {
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
        case 'SUPPORT_STAFF':
        case 'FINANCE':
            result = _.concat(
                defaultConfig
            )
            break;
        default:
            break;
    }
    return result
}

const getProfitRate = (paid, cost) => {
    return (((paid - cost) / paid) * 100).toFixed(2)
}
