import _ from 'lodash'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const MediaIndexOrderSearchConfig = () => {
    const role = useRecoilValue(userRoleState)

    const defaultSearch = [
        {
            name: 'title',
            label: '订单标题',
            placeholder: '请输入标题',
            type: 'input'
        }, {
            name: 'mediaIndex_name',
            label: '媒体名称',
            placeholder: '请输入媒体名称',
            type: 'input'
        },
    ]

    const timeSearch = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: dayjs().subtract(7, 'days')
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        },
    ]


    const operatorSearch = [
        {
            name: 'customer_name',
            label: '所属客户',
            placeholder: '请输入客户名称',
            type: 'input'
        }, {
            name: 'sale_name',
            label: '所属销售',
            placeholder: '请输入销售名称',
            type: 'input'
        }, {
            name: 'editor_name',
            label: '所属编辑',
            placeholder: '请输入编辑名称',
            type: 'input'
        }, {
            name: 'paid_status',
            label: '结算状态',
            type: 'select',
            children: [
                ['全部', ''],
                ['已结算', 'paid'],
                ['未结算', 'unpaid'],
            ]
        },
    ]


    const saleSearch = [
        {
            name: 'name',
            label: '项目名称',
            placeholder: '请输入项目名称',
            type: 'input'
        }, {
            name: 'sale_name',
            label: '所属销售',
            placeholder: '请输入销售名称',
            type: 'input'
        }, {
            name: 'customer_name',
            label: '所属客户',
            placeholder: '请输入客户名称',
            type: 'input'
        }, {
            name: 'sales_status',
            label: '结算状态',
            type: 'select',
            children: [
                ['全部', ''],
                ['已打款', 'paid'],
                ['未打款', 'unpaid'],
            ]
        },
    ]

    let result = []

    switch (role) {
        case "CUSTOMER":
            result = _.concat(
                defaultSearch,
                timeSearch
            )
            break;
        case "OPERATOR":
        case "OPERATOR_ADMIN":
        case "OPERATOR_MEDIA":
            result = _.concat(
                defaultSearch,
                operatorSearch,
                timeSearch
            )
            break;
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'SUPPORT_STAFF':
        case 'FRANCHISE':
            result = _.concat(
                defaultSearch,
                saleSearch,
                timeSearch
            )
            break;
        default:
            break;
    }

    return result
}

export default MediaIndexOrderSearchConfig