import React, { useState, useEffect } from 'react';
import { PlusOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Modal, Form, Row, Col, Radio, Input, Button, InputNumber, Typography, Space, DatePicker, Spin } from 'antd';
import qs from 'qs';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs'
import _ from 'lodash'

import { userRoleState } from '../../atoms/Atoms'
import { SaleGroupTableConfig } from '../../../role/SaleGroupTableConfig';
import SearchForm from '../../common/SearchForm';
import { resultResponse } from '../../common/ResultResponse';
import { timeToChinese } from '../../../utils/TimeConvert'
import { initFormData, tablePagination } from '../../common/tableCommon';
import { check } from '../../../utils/permission/rules';
import { Platform } from '../../common/CONSTANTS';

const axios = require('axios');
const { Text } = Typography
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const colLayout = { span: 24 }
const layout = { layout: 'vertical' };
const seartchLayout = { lg: 24, xl: 12 };

const SaleGroupTable = () => {

    const [fetching, setFetching] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const role = useRecoilValue(userRoleState)

    const permission = {
        'module:salegroup:add:customer': check(role, 'module:salegroup:add:customer'),
        'module:salegroup:add:vip': check(role, 'module:salegroup:add:vip'),
        'module:salegroup:add:sales:leader': check(role, 'module:salegroup:add:sales:leader'),
        'module:salegroup:add:sales:attache': check(role, 'module:salegroup:add:sales:attache'),
    }

    const initialFormData = initFormData(searchDatas)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState({ create: false })
    const [saleGroupType, setSaleGroupType] = useState(null)

    const [form] = Form.useForm();
    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();

    const resetModal = () => setModal({ create: false })
    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        setLoading(true)
        await axios({
            method: 'post',
            url: '/sale_group/saleGroupsList',
            data: params
        }, {
            cancelToken: source.token
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
            let total = res.data.total
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])


    const createSaleGroup = async (value) => {
        if (!modalLoading) {
            setModalLoading(true)
            await axios({
                method: 'post',
                url: `/sale_group/add`,
                data: qs.stringify(value)
            }).then((res) => {
                resultResponse(res, '已添加')
                reloadTable()
                setModalLoading(false)
                resetModal()
            })
        }
    }

    const getSaleGroup = async (id) => {
        setModal({ ...modal, edit: true })
        if (!fetching) {
            setFetching(true)
            await axios({
                method: 'get',
                url: `/sale_group/get/${id}`
            }).then((res) => {
                editForm.setFieldsValue(res.data.data)
                setFetching(false)
            })
        }
    }

    const editSaleGroup = async (value) => {
        if (!modalLoading) {
            setModalLoading(true)
            await axios({
                method: 'post',
                url: `/sale_group/edit`,
                data: qs.stringify(value)
            }).then((res) => {
                resultResponse(res, '已修改')
                reloadTable()
                setModalLoading(false)
                resetModal()
            })
        }
    }

    const deleteSaleGroup = async (id) => {
        await axios({
            method: 'post',
            url: `/sale_group/del/${id}`
        }).then((res) => {
            resultResponse(res, '已删除')
            reloadTable()
            resetModal()
        })
    }

    const createPremiumMemeber = async (value) => {
        await axios({
            method: 'post',
            url: `/sale_group/premium`,
            data: qs.stringify(value)
        }).then((res) => {
            resultResponse(res, '已添加')
            reloadTable()
            resetModal()
        })
    }

    const updateStrategy = async (value) => {
        await axios({
            method: 'post',
            url: `/sale_group/premium/strategy`,
            data: qs.stringify(value)
        }).then((res) => {
            resultResponse(res, '已添加')
            reloadTable()
            resetModal()
        })
    }

    const setDefault = async (id) => {
        await axios({
            method: 'post',
            url: `/sale_group/setDefault`,
            data: qs.stringify({ id })
        }).then((res) => {
            resultResponse(res, '已更新')
            reloadTable()
            resetModal()
        })
    }

    const setVisible = async (id, visible) => {
        await axios({
            method: 'post',
            url: `/sale_group/setVisible`,
            data: qs.stringify({
                id, visible
            })
        }).then((res) => {
            resultResponse(res, '已更新')
            reloadTable()
            resetModal()
        })
    }

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: '策略名称',
                dataIndex: 'name',
            }, {
                title: '时间',
                dataIndex: 'type',
                render: (t, data) => {
                    let year = Math.floor(data.length / 12)
                    let month = data.length % 12
                    return `${timeToChinese(year, month)}(${data.length}个月)`
                }
            }, {
                title: '额外折扣',
                dataIndex: 'extra_type',
                render: (t, data) => {
                    if (data.extra_length > 0) {
                        let year = Math.floor(data.extra_length / 12)
                        let month = data.extra_length % 12
                        return `赠送${timeToChinese(year, month)}(${data.extra_length}个月)`
                    } else {
                        return `当前无折扣`
                    }
                }
            }, {
                title: '默认价格',
                dataIndex: 'price',
                render: (t, data) => {
                    return <Space direction='vertical'>
                        <Text>折扣: {parseInt((1 - data.discount) * 100)} %</Text>
                        <Text>原价: {data.price}</Text>
                        <Text>折后价: {data.price * data.discount}</Text>
                    </Space>
                }
            }, {
                title: '折扣期限',
                dataIndex: 'start_time',
                render: (t, data) => {
                    return <Space direction='vertical'>
                        <Text>开始时间: {data.start_time}</Text>
                        <Text>结束时间: {data.end_time}</Text>
                    </Space>
                }
            }, {
                title: '操作',
                dataIndex: 'id',
                render: (t, data) => {
                    return <Button type='link' size='small' icon={<EditOutlined />}
                        onClick={() => setModal({ ...modal, id: data.id, strategy: true })} />
                }
            },
        ];
        return <Table columns={columns} dataSource={record.strategy} pagination={false} rowKey={record => record.id} />;
    };

    const setPremiumMember = (id) => setModal({ ...modal, member: true, id: id })

    const handleUsers = { setPremiumMember, setDefault, setVisible, getSaleGroup, deleteSaleGroup }
    const columns = SaleGroupTableConfig({ handleUsers, })

    return <div style={{ flex: 'auto' }}>
        <Form
            {...layout}
            name="sale-group-form"
            form={form}
            initialValues={initialFormData}
            onFinish={val => fetchData(_.pickBy(val, _.identity))}
        >
            <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
            <Row style={{ margin: '16px 0' }}>
                <Col {...seartchLayout} >
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => setModal({ ...modal, create: true })}                    >
                        创建分组
                    </Button>
                </Col>
                <Col  {...seartchLayout} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}> 搜索</Button>
                </Col>
            </Row>
        </Form>
        <Table
            size="middle"
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={(pagination) => {
                setPagination(pagination)
                form.submit()
            }}
            expandable={{
                expandedRowRender,
                rowExpandable: record => _.size(record.strategy) > 0
            }}
        />
        <Modal title="创建用户组" open={modal.create}
            onOk={() => {
                addForm
                    .validateFields()
                    .then((values) => createSaleGroup(values))
            }}
            onCancel={() => resetModal()}
            confirmLoading={modalLoading}
        >
            <Form
                {...formItemLayout}
                form={addForm}
            >
                <Row gutter={[24, 24]}>
                    <Col {...colLayout}>
                        <Form.Item label="分组" name="type">
                            <Radio.Group onChange={e => setSaleGroupType(e.target.value)}>
                                {permission['module:salegroup:add:customer'] && <Radio value={3}>用户分组</Radio>}
                                {permission['module:salegroup:add:vip'] && <Radio value={9}>会员分组</Radio>}
                                {permission['module:salegroup:add:sales:leader'] && <Radio value={1}>组长分组</Radio>}
                                {permission['module:salegroup:add:sales:attache'] && <Radio value={2}>组员分组</Radio>}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {
                        (saleGroupType === 3 || saleGroupType === 9) && <Col {...colLayout}>
                            <Form.Item label="所属平台" name="platform_id">
                                <Radio.Group>
                                    {_.map(Platform, e => <Radio value={e.id}>{e.name}</Radio>)}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    }
                    <Col {...colLayout}>
                        <Form.Item label="销售组名称" name="sale_name"
                            rules={[{ required: true, message: '请填写销售组名' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
        <Modal title="编辑用户分组" open={modal.edit}
            onOk={() => {
                editForm
                    .validateFields()
                    .then((values) => editSaleGroup(values))
            }}
            onCancel={() => resetModal()}
            confirmLoading={modalLoading}
        >
            <Spin spinning={fetching}>
                <Form
                    {...formItemLayout}
                    form={editForm}
                >
                    <Row gutter={[24, 24]}>
                        <Col {...colLayout}>
                            <Form.Item label="销售组名称" name="sale_name"
                                rules={[{ required: true, message: '请填写销售组名' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item hidden name="id">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="返现倍率" name="back_ratio" rules={[{ required: true, message: '请填写返现倍率' }]}>
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col {...colLayout}>
                            <Form.Item label="排序顺序" name="weight" rules={[{ required: true, message: '请填写排序顺序' }]}>
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
        {/* 绑定用户销售会员组价格 */}
        <Modal title="会员组售价" open={modal.member}
            onOk={() => resetModal()}
            onCancel={() => resetModal()}
            footer={null}
        >
            <Form
                {...formItemLayout}
                onFinish={val => createPremiumMemeber({ ...val, sell_group_id: modal.id })}
            >
                <Row gutter={[24, 24]}>
                    <Col {...colLayout}>
                        <Form.Item label="销售策略名称" name="name"
                            rules={[{ required: true, message: '请填写销售策略名称' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col {...colLayout}>
                        <Form.Item label="时长/月份" name="length">
                            <InputNumber min={1} />
                        </Form.Item>
                    </Col>
                    <Col {...colLayout}>
                        <Form.Item label="默认售价" name="price"
                            rules={[{ required: true, message: '请填写售价' }]}>
                            <InputNumber />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
        {/* 绑定用户会员策略 */}
        <Modal title="策略详情" open={modal.strategy}
            onOk={() => resetModal()}
            onCancel={() => resetModal()}
            footer={null}
        >
            <Form
                {...formItemLayout}
                onFinish={val => {
                    updateStrategy({
                        ...val,
                        id: modal.id,
                        start_time: dayjs(val['start_time'].$d).format('YYYY-MM-DD'),
                        end_time: dayjs(val['end_time'].$d).format('YYYY-MM-DD')
                    })
                }}
            >
                <Row gutter={[24, 24]}>
                    <Col {...colLayout}>
                        <Form.Item label="额外时长" name="extra_length">
                            <InputNumber min={1} />
                        </Form.Item>
                    </Col>
                    <Col {...colLayout}>
                        <Form.Item label="默认价格" name="price"
                            rules={[{ required: true, message: '请输入默认价格' }]}>
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col {...colLayout}>
                        <Form.Item label="折扣" name="discount"
                            rules={[{ required: true, message: '请输入0 - 1之前的数字' }]}>
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col {...colLayout}>
                        <Form.Item label='开始时间' name="start_time">
                            <DatePicker picker='date' />
                        </Form.Item>
                    </Col>
                    <Col {...colLayout}>
                        <Form.Item label='结束时间' name="end_time">
                            <DatePicker picker='date' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>;
}

export default SaleGroupTable

const searchDatas = [
    {
        name: 'type',
        label: '分组类型',
        type: 'radio',
        value: 9,
        children: [
            ['组长', 1],
            ['组员', 2],
            ['用户', 3],
            ['会员', 9],
        ]
    }, {
        name: 'platform_id',
        label: '平台类型',
        type: 'radio',
        value: '',
        children: [
            ['全部', ''],
            ['媒体平台', 1],
            ['微博平台', 2],
            ['微信平台', 3],
            ['小红书平台', 4],
            ['抖音平台', 5],
        ]
    },
]