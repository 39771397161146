import React, { useState } from 'react'
import { Layout, Form, Steps, Button, Typography, Tooltip, Card, Space, message, Row, Col, Divider, Input, Tabs } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import _ from 'lodash'

import InvoiceInfoForm from './InvoiceInfoForm';
import ConsumeTable from '../consume/ConsumeTable';
import { resultResponse } from '../../common/ResultResponse';
import InvoicesTable from './InvoicesTable';
import { InvoiceText, defaultInvoice } from './CommonInvoice'
import PageHeader from '../../layout/PageHeader';

const axios = require('axios');
const { Content } = Layout;

const { Step } = Steps;
const { Title, Text } = Typography;
const Invoices = () => {

    let pageTitle = "申请发票"

    const [current, setCurrent] = useState(0)
    const [consumes, setConsumes] = useState([])
    const [invoice, setInvoice] = useState(defaultInvoice)
    const [tab, setTab] = useState('invoice')
    const [type, setType] = useState('')

    const nextPage = () => setCurrent(current + 1)
    const prevPage = () => setCurrent(current - 1)

    const getTax = (money) => {
        let taxRatio = 0.06
        let result = money * taxRatio
        return (Math.round(result * 100) / 100).toFixed(2)
    }

    const taxInfo = "本平台开具专票收取6%的税点（票面6%）"

    /**添加发票 */
    const addInvoice = async (val) => {
        let params = new FormData();
        _.toPairs(invoice).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        val && _.toPairs(val).forEach(e => params.set(`${e[0]}`, e[1] ?? ''))
        params.append('type', type)
        await axios({
            method: 'post',
            url: '/invoice/add',
            data: params
        }).then((res) => {
            resultResponse(res, "已成功")
            setTab('list')
        })
    }

    const steps = [
        {
            title: '选择订单',
            content: <Content>
                <ConsumeTable
                    consumes={consumes}
                    setConsumes={setConsumes}
                    searchEntity={{ invoice_type_id: '1', invoice: 'true', start_time: '2020-08-01' }}
                    action="INVOICE"
                />
                <Row style={{ width: '100%' }}>
                    <Col span={24} key='next' style={{ textAlign: "right" }}>
                        <Button type="primary" onClick={() => {
                            let typeMap = _.uniq(consumes.map(e => e.type_id))
                            if (_.size(typeMap) > 1) {
                                message.error('只能选择相同消费类型的订单进行申请');
                                return;
                            }
                            setType(typeMap[0])
                            const money = _.sumBy(consumes, 'money')
                            if (money <= 0) {
                                message.error('请选择订单');
                            } else {
                                if (money < 200) {
                                    message.warning('发票累计金额不得低于200元');
                                } else {
                                    nextPage()
                                    setInvoice({
                                        ...invoice,
                                        money: _.sumBy(consumes, 'money'),
                                        consume_ids: consumes.map(e => e.id)
                                    })
                                }
                            }
                        }}>继续</Button>
                    </Col>
                </Row>
            </Content>,
        }, {
            title: '填写信息', // 传递选择媒体数据，搜索表格数据，是否读取完成数据
            content: (
                <>
                    <Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
                        <div style={{ maxWidth: 960, margin: '0 auto' }}>
                            <Title level={4} style={{ textAlign: 'center' }}>
                                当前所选开票金额为 {_.sumBy(consumes, 'money')} 元
                            </Title>
                            <div style={{ textAlign: 'center', }}>
                                <Text type="secondary" style={{ margin: '0 8px' }}>
                                    需要补交税点{getTax(_.sumBy(consumes, 'money'))} 元
                                </Text>
                                <Tooltip title={taxInfo}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                            <Divider />
                            <InvoiceInfoForm />
                            <Divider />
                            <Form layout='vertical' onFinish={val => addInvoice(val)}>
                                <Form.Item label="收件人姓名" name='name'
                                    rules={[{ required: true, message: '请填写收件人姓名！' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item label="手机号" name='tel'
                                    rules={[{ required: true, message: '请填写收件人手机号！' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item label="收件地址" name='address'
                                    rules={[{ required: true, message: '请填写收件人地址！' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item label="备注" name='remark'>
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Row>
                                        <Col span={12}>
                                            <Button onClick={prevPage}>返回</Button>
                                        </Col>
                                        <Col span={12} style={{ textAlign: "right" }}>
                                            <Button type="primary" htmlType='submit'>
                                                提交
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </div>
                    </Content>
                </>
            ),
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey='invoice' activeKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: 'invoice', label: `申请发票`, },
                            { key: 'list', label: `查看申请`, },
                        ]}
                    />
                } />
            <Content style={{ margin: '24px' }}>
                <Space direction='vertical' size={16} style={{ width: '100%' }}>
                    <InvoiceText />
                    {
                        tab === 'invoice' && <Space direction='vertical' size={16} style={{ width: '100%' }}>
                            <Card style={{ padding: 16 }}>
                                <Steps current={current}>
                                    {steps.map(item => <Step key={item.title} title={item.title} />)}
                                </Steps>
                            </Card>
                            <div className="steps-content">
                                {steps[current].content}
                            </div>
                        </Space>
                    }
                    {
                        tab === 'list' && <InvoicesTable />
                    }
                </Space>
            </Content>
        </HelmetProvider>
    );
}
export default Invoices