import React from 'react';
import { Layout, } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import WeiboHotTable from '../weibo/WeiboHotTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;


// /dashboard/resource/weibo/hot
// 入住编辑页面
const EditorWeiboHot = () => {

    let pageTitle = "我的微博"
    const action = 'EDIT'

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content>
                <div style={{ margin: 24 }}>
                    <WeiboHotTable action={action} url='/weibo/hot/editor/list' />
                </div>
            </Content>
        </HelmetProvider>

    )
}

export default EditorWeiboHot;