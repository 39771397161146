import React from 'react'
import { Typography, Switch, Tag, Button, Space, Popconfirm } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'

const { Text } = Typography;

export const SaleGroupTableConfig = ({ handleUsers }) => {
    const role = useRecoilValue(userRoleState)

    const { setPremiumMember, setDefault, setVisible, getSaleGroup, deleteSaleGroup } = handleUsers

    const defaultConfig = [
        {
            title: '分组类型',
            checked: 1,
            render: (t, data) => {
                switch (data.type) {
                    case 1:
                        return <Tag color="blue">组长分组</Tag>
                    case 2:
                        return <Tag color="red">组员分组</Tag>
                    case 3:
                        return <Tag color="orange">用户分组</Tag>
                    case 4:
                        return <Tag color="lime">加盟分组</Tag>
                    case 9:
                        return <Tag color="gold">会员分组</Tag>
                    default:
                        return
                }
            }
        },
        {
            title: '销售组',
            dataIndex: 'sale_name',
            checked: 1,
            render: (t, data) => {
                return <Space direction='vertical'>
                    <Text type="secondary" style={{ fontSize: '12px' }}>id: {data.id}</Text>
                    <Text strong>{data.sale_name}</Text>
                </Space>
            }
        }
    ]

    const levelInfoCol = [
        {
            title: '默认分组',
            dataIndex: 'is_default',
            render: (val, data) => <Switch checkedChildren="已选" unCheckedChildren="缺省" checked={val} onClick={() => setDefault(data.id)} />
        }, {
            title: '是否可见',
            dataIndex: 'is_visible',
            render: (val, data) => <Switch checkedChildren="可见" unCheckedChildren="隐藏" checked={val} onClick={() => setVisible(data.id, !data.is_visible)} />
        }, {
            title: '所属平台',
            dataIndex: 'platform_id',
            render: (t, data) => {
                switch (data.platform_id) {
                    case 1:
                        return <Tag color="blue">媒体平台</Tag>
                    case 3:
                        return <Tag color="red">微信平台</Tag>
                    case 2:
                        return <Tag color="orange">微博平台</Tag>
                    case 4:
                        return <Tag color="geekblue">小红书平台</Tag>
                    case 5:
                        return <Tag color="green">抖音平台</Tag>
                    default:
                        return
                }
            }
        }
    ]

    const levelDetailCol = [{
        title: '会员策略',
        dataIndex: 'strategy',
        checked: 1,
        render: (t, data) => {
            // 是会员 分组分开
            if (data.type === 9) {
                if (_.size(data.strategy) >= 4) {
                    return <Text>当前策略已满</Text>
                } else {
                    return <Button type='link' icon={<PlusOutlined />} style={{ margin: 0 }}
                        onClick={() => setPremiumMember(data.id)}>添加策略
                    </Button>
                }
            } else {
                return <Text>当前分组无需购买</Text>
            }
        }
    }, {
        title: '返现倍率',
        dataIndex: 'back_ratio',
        checked: 1,
    }, {
        title: '平台抽成',
        dataIndex: 'ratio',
        checked: 1,
    }]

    const handleUser = {
        title: '用户操作',
        dataIndex: 'back_info',
        width: 190,
        checked: 1,
        render: (text, data) => {
            return <Space direction='vertical'>
                <Text code>分组权重: {data.weight}</Text>
                <Space size='small'>
                    <Button type='link' icon={<EditOutlined />} size='small' onClick={() => getSaleGroup(data.id)}>编辑</Button>
                    <Popconfirm
                        title={`确认删除${data.sale_name}吗?`}
                        onConfirm={() => deleteSaleGroup(data.id)}
                        onCancel={e => console.log(e)}
                        okText="确认"
                        cancelText="取消">
                        <Button type='link' icon={<DeleteOutlined />} size='small'>删除</Button>
                    </Popconfirm>
                </Space>
            </Space>
        }
    }

    let result = []

    switch (role) {
        case 'FRANCHISE':
            result = _.concat(
                defaultConfig,
                levelDetailCol,
                levelInfoCol,
                handleUser,
            )
            break;
        default:
            break;
    }
    return result
}
