import React from 'react'
import { Button, List, Checkbox, Popover, Radio } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import _ from 'lodash'

export const initFormData = searchDatas => _.reduce(searchDatas, (result, data) => _.assign(result, { [data.name]: data.value ?? '' }), {})

export const tablePagination = {
    current: 1,
    pageSize: 10,
    pageSizeOptions: ['10', '25', '50', '100', '200', '400'],
    showSizeChanger: true,
    total: 0,
    showTotal: total => `共计 ${total} 条`,
    showQuickJumper: true
}

export const TableTitle = ({ columns = [], setColumns = e => console.log(e), name = '', menu }) => {
    let content = <List
        size="small"
        split={false}
        dataSource={columns.filter(e => e.title !== "")}
        renderItem={e =>
            <List.Item>
                <Checkbox onChange={val => {
                    let newCol = columns.map(value => value.title === e.title ? { ...value, checked: val.target.checked ? 1 : 0 } : value)
                    setColumns(newCol)
                    localStorage.setItem(name, JSON.stringify(newCol
                        .filter(e => e.checked === 0)
                        .map(e => {
                            return {
                                title: e.title,
                                checked: e.checked
                            }
                        })))
                }} checked={e.checked === 1}>
                    {e.title}
                </Checkbox>
            </List.Item>}
    />
    return <div className='table-title'>
        <Popover content={content} trigger="click" placement="bottomRight">
            <Button style={{ border: 'none', padding: 0, }}>
                <SettingOutlined style={{ fontSize: 20, verticalAlign: 'top', }} />
            </Button>
        </Popover>
        {menu}
    </div>
}

export const TimeSearchButtonGroup = ({ form }) => {

    const updateSearchTime = (val, form) => {
        let data = form.getFieldsValue()
        if (val === 30) {
            form.setFieldsValue({ ...data, start_time: dayjs().subtract(1, 'months'), end_time: null })
        } else {
            form.setFieldsValue({ ...data, start_time: dayjs().subtract(val, 'days'), end_time: null })
        }
        form.submit()
    }

    return <Radio.Group buttonStyle="solid" onChange={e => updateSearchTime(e.target.value, form)} defaultValue={7}>
        <Radio.Button value={3}>3天内</Radio.Button>
        <Radio.Button value={7}>7天内</Radio.Button>
        <Radio.Button value={30}>30天内</Radio.Button>
    </Radio.Group>
}

export const disposeColumn = (name, columns) => {
    let localStorageColumns = JSON.parse(localStorage.getItem(name))
    _.forEach(columns, (e) => {
        _.forEach(localStorageColumns, (val) => {
            if (e.title === val.title) {
                e.checked = val.checked
            }
        })
    })
}