import React, { useState } from 'react'
import { Tabs, Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import EventTable from './EventTable'
import EventRulesTable from './EventRulesTable';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout


/**
 * /dashboard/events
 * 只有运营组长 以及 管理员可以见到
 */
const Events = () => {
    let pageTitle = "活动管理"
    const [tab, setTab] = useState("event")

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            { key: 'event', label: `活动中心`, },
                            { key: 'rules', label: `活动规则`, },
                        ]}
                    />
                }
            />
            <Content style={{ margin: 24 }}>
                {tab === "event" && <EventTable />}
                {tab === "rules" && <EventRulesTable />}
            </Content>
        </HelmetProvider>
    )
}

export default Events
