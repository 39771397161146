import React, { useState } from 'react'
import { EditTwoTone, HistoryOutlined, UnorderedListOutlined, PlusOutlined } from '@ant-design/icons';
import { Spin, Menu, Dropdown, Tag, Typography, Button, Modal, message, Popconfirm, Space, Tooltip, Popover, Descriptions, Badge } from 'antd';
import _ from 'lodash'
import qs from 'qs';
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from 'recoil';

import { recallReason, errorReason, getName, getText, getStatus, ContentCell } from './orderCommon'
import { userRoleState } from '../../Apps/atoms/Atoms'
import { formatTime } from '../../utils/TimeUtils'
import { resultResponse } from '../../Apps/common/ResultResponse';
import { check } from '../../utils/permission/rules';
import { minute2hour } from '../../entities/MediaProperty';

const axios = require('axios');

const { Paragraph, Text } = Typography;
const { confirm } = Modal;

export const OrderTableConfig = ({ modal, setModal, handleOrders, action }) => {

    const role = useRecoilValue(userRoleState)
    const [workOrderLoading, setWorkOrderLoading] = useState(false);

    const permission = {
        'module:media:order:money:cost': check(role, 'module:media:order:money:cost', { action }),
        'module:media:order:cost:modify': check(role, 'module:media:order:cost:modify', null),
        'module:media:order:money:paid': check(role, 'module:media:order:money:paid', { action }),
        'module:media:order:money:agent': check(role, 'module:media:order:money:agent', null),
        'module:media:order:money:sales:agent': check(role, 'module:media:order:money:sales:agent', null),
        'module:media:order:sales:status': check(role, 'module:media:order:sales:status', null),
        'module:media:order:status': check(role, 'module:media:order:status', null),
        'module:media:order:history': check(role, 'module:media:order:history', null),
        'module:media:order:editor:edit': check(role, 'module:media:order:editor:edit', null),
        'module:media:order:recall:handle': check(role, 'module:media:order:recall:handle', { action }),
        'module:media:order:error:handle': check(role, 'module:media:order:error:handle', { action }),
        'module:media:order:success:reject': check(role, 'module:media:order:success:reject', null),
        'module:media:order:success:handle': check(role, 'module:media:order:success:handle', { action }),
        'module:media:order:success:error': check(role, 'module:media:order:success:error', { action }),
        'module:media:order:publish:handle': check(role, 'module:media:order:publish:handle', { action }),
        'module:media:order:publish:recall': check(role, 'module:media:order:publish:recall', { action }),
        'module:media:order:verify:handle': check(role, 'module:media:order:verify:handle', { action }),
        'module:media:order:verify:cancel': check(role, 'module:media:order:verify:cancel', { action }),
        'module:media:order:customer:name': check(role, 'module:media:order:customer:name', null),
        'module:media:order:sales:name': check(role, 'module:media:order:sales:name', null),
        'module:order:search:workorder': check(role, 'module:order:search:workorder', null),
        'module:media:project:modify': check(role, 'module:media:project:modify', null),
    }

    const {
        verifyOrderSuccess, // 订单审核通过
        cancelOrder,    // 撤回订单
        reloadTable,    // 刷新页面
        rejectOrder,    // 拒稿
        checkHistory,   // 查看历史
        checkCensor,    // 查看违禁词
        openResourceInfoForm,   // 添加媒体编辑支付信息
        checkResourceInfo,   // 管理媒体编辑支付信息
        modifyOrderProject,  // 修改订单所属项目
        showErrorInfo
    } = handleOrders;

    const size = 'small'
    let navigate = useNavigate();

    // 客户申请撤稿 编辑同意撤稿 
    const agreeRecallOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/order/publish",
            data: qs.stringify({
                order_ids: id,
                status: "back",
                reason: "客户申请撤稿"
            })
        }).then(res => {
            resultResponse(res, '已退稿')
            reloadTable()
        })
    }

    // 客户申请撤稿 编辑拒绝撤稿 
    const refuseRecallOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/order/confirm",
            data: qs.stringify({
                id: id,
                action: "recall",
            })
        }).then(res => {
            resultResponse(res, '成功操作')
            reloadTable()
        })
    }

    // 客户申请稿件异常 编辑同意退稿 
    const agreeErrorOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/order/reject",
            data: qs.stringify({ id: id })
        }).then(res => {
            resultResponse(res, '已退稿')
            reloadTable()
        })
    }

    // 客户申请稿件异常 编辑拒绝拒稿 
    const refuseErrorOrder = async (id) => {
        await axios({
            method: 'post',
            url: "/order/confirm",
            data: qs.stringify({
                id: id,
                action: "error",
            })
        }).then(res => {
            resultResponse(res, '成功操作')
            reloadTable()
        })
    }

    // 订单已结算 
    const paidOrder = async (id, status) => {
        await axios({
            method: 'post',
            url: "/order/pay",
            data: qs.stringify({
                ids: id,
                paid_status: status,
            })
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
        })
    }

    const paidSalesOrder = async (id, status) => {
        await axios({
            method: 'post',
            url: "/order/sales/pay",
            data: qs.stringify({
                ids: id,
                sales_status: status,
            })
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
        })
    }

    const closedMedia = async (id) => {
        await axios({
            method: 'post',
            url: `/media/closed/${id}`
        }).then(res => {
            resultResponse(res, "成功")
            reloadTable()
        })
    }

    const searchWorkOrder = async (id) => {
        setWorkOrderLoading(true)
        await axios({
            method: 'post',
            url: `/workOrder/search`,
            data: qs.stringify({
                order_id: id,
            })
        }).then(res => {
            confirm({
                content: <Spin spinning={workOrderLoading}>
                    <WorkOrderInfo data={res.data.data} />
                </Spin>,
                width: 650,
                icon: <></>
            });
            setWorkOrderLoading(false)
        })
    }

    const orderSalesAndCustomer = {
        title: '销售姓名',
        dataIndex: 'sale_name',
        width: 130,
        checked: 1,
        render: (val, data) => {
            return <Space direction='vertical' size={2}>
                {permission['module:media:order:customer:name'] && <Text strong>{data.customer_name}</Text>}
                {permission['module:media:order:sales:name'] && <Text>{data.sale_name}</Text>}
            </Space>
        }
    }

    const defaultConfig = [
        {
            title: '媒体名称',
            dataIndex: 'media_name',
            width: 130,
            checked: 1,
            render: (val, data) => {
                let media_name = getName(data)

                return <Space direction='vertical' size={2}>
                    <a
                        className='title-link'
                        href={data.sample_url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {media_name}
                    </a>
                    {
                        (data.pending === 2 && data.media_status === 'waitPublish') &&
                        <Tag color="red">已截稿</Tag>
                    }
                    <MediaInfo data={data} closedMedia={closedMedia} />
                </Space>
            }
        }, {
            title: '标题',
            dataIndex: 'title',
            checked: 1,
            render: (val, data) => {
                return <Space direction='vertical' size={2}>
                    {
                        (data.name && data.name !== '' && data.name !== 'undefined')
                            ? <Space size={2}>
                                <Link to={`/dashboard/project/${data.project_id}`} state={{ pid: data.project_id, status: data.project_status }} >
                                    <Button type='link' size='small' style={{ padding: 0 }}>
                                        {
                                            data.sales_name 
                                            ? `【${data.name}】 - 【${data.sales_name}】`
                                            : `【${data.name}】`
                                        }
                                    </Button>
                                </Link>
                                {
                                    permission['module:media:project:modify'] &&
                                    <Button type='link' size='small' onClick={() => modifyOrderProject(data.id)} icon={<EditTwoTone />} />
                                }
                            </Space>
                            : <>
                                {
                                    permission['module:media:project:modify'] &&
                                    <Button type='link' size='small' onClick={() => modifyOrderProject(data.id)} icon={<PlusOutlined />} />
                                }
                            </>
                    }
                    <Paragraph style={{ marginBottom: 0 }} copyable={{ text: getText(data) }}>{val}</Paragraph>
                </Space>
            }
        }
    ]

    const orderPrice = {
        title: '价格',
        dataIndex: 'paid_money',
        width: 96,
        checked: 1,
        render: (t, data) => {
            return <Space direction='vertical' size={2}>
                {
                    permission['module:media:order:money:cost'] &&
                    <Text strong>
                        成本：{data.cost_money}
                        {
                            permission['module:media:order:cost:modify'] &&
                            <Tooltip title="修改订单成本">
                                <Button icon={<EditTwoTone />} type='link' size={"small"} onClick={() => {
                                    setModal({ ...modal, target_id: data.id, updateCost: true })
                                }} />
                            </Tooltip>
                        }
                    </Text>
                }
                {permission['module:media:order:money:agent'] && <Text strong>相对：{data.agent_paid_money}</Text>}
                {permission['module:media:order:money:sales:agent'] && <Text strong>成本：{data.agent_paid_money}</Text>}
                {permission['module:media:order:money:paid'] && <Text strong>付款：{data.paid_money}</Text>}
                {data.coupon_money > 0 && <Text type="secondary" style={{ fontSize: '12px' }}>优惠金额{data.coupon_money}</Text>}
            </Space>
        }
    }

    const orderContent = [
        {
            title: '内容',
            dataIndex: 'content',
            width: 80,
            checked: 1,
            render: (text, data) => <ContentCell data={data} />
        }, {
            title: '备注',
            dataIndex: 'remarks',
            checked: 1,
        },
    ]

    const republic = {
        title: '补发',
        dataIndex: 'additional',
        checked: 1,
        render: (text, data) => {
            return <Space direction='vertical'>
                <Button type='link' size={size} style={{ padding: 0 }} onClick={() => {
                    navigate("/dashboard/order/add", {
                        state: {
                            order: { title: data.title, remark: data.remarks, url: data.source_url, file_url: data.file_url, name: data.name, content: data.content, sales_name:data.sales_name },
                            article_id: data.article_id,
                            project_id: data.project_id !== 0 ? data.project_id : undefined
                        }
                    })
                }}>来源发布</Button>
                {
                    data.media_status === 'success' &&
                    <Button size={size} type='link' style={{ padding: 0 }} onClick={() => {
                        navigate("/dashboard/order/add", {
                            state: {
                                order: { title: data.title, remark: data.remarks, url: data.back_info, file_url: data.file_url, name: data.name },
                                project_id: data.project_id !== 0 ? data.project_id : undefined
                            }
                        })
                    }}>回执发布</Button>
                }
            </Space >
        }
    }

    const orderTime = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 80,
            checked: 1,
            render: (t) => formatTime(t)
        }, {
            title: '审核时间',
            dataIndex: 'handle_time',
            width: 80,
            checked: 1,
            render: (t) => formatTime(t)
        }, {
            title: '发布时间',
            dataIndex: 'media_handle_time',
            width: 80,
            checked: 1,
            render: (t) => formatTime(t)
        },
    ]


    const orderStatusCol = [
        {
            title: '编辑结算',
            dataIndex: 'paid_status',
            width: 72,
            checked: 1,
            render: (val, data) => {
                let obj = {}
                switch (val) {
                    case 'unpaid':
                        obj = { color: 'red', text: '未支付', state: 'paid' }
                        break;
                    case 'pending':
                        obj = { color: 'orange', text: '申请中' }
                        break;
                    case 'paid':
                        obj = { color: 'green', text: '已支付', state: 'unpaid' }
                        break;
                    default:
                        break
                }
                // 必须为管理员才能处理结算状态
                return <Space direction='vertical'>
                    <Tag style={{ margin: 0 }} onClick={() => permission['module:media:order:status'] && paidOrder(data.id, obj?.state)} color={obj?.color}>
                        {obj?.text}
                    </Tag>
                </Space>
            }
        },
    ]


    const salesOrderStatusCol = {
        title: '客户结算',
        dataIndex: 'sales_status',
        width: 72,
        checked: 1,
        render: (val, data) => {
            let obj = {}
            switch (val) {
                case 'unpaid':
                    obj = { color: 'red', text: '未支付', state: 'paid' }
                    break;
                case 'pending':
                    obj = { color: 'orange', text: '申请中' }
                    break;
                case 'paid':
                    obj = { color: 'green', text: '已支付', state: 'unpaid' }
                    break;
                default:
                    break
            }
            return <Tag style={{ margin: 0 }} onClick={() => permission['module:media:order:sales:status'] && paidSalesOrder(data.id, obj.state)} color={obj.color}>{obj.text}</Tag>
        }
    }

    const orderStatus = {
        title: '状态',
        dataIndex: 'status',
        width: 72,
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            if (data.err_status === 2 || data.err_status === -1) {
                return <Tag style={{ margin: 0 }} color="red">价格异常</Tag>
            }
            let obj = {}
            switch (data.media_status) {
                case 'waitPublish':
                    obj = { color: 'gold', text: '待发布' }
                    if (data.status === 'waitVerify') {
                        obj = { color: 'blue', text: '待审核' }
                    }
                    break;
                case 'success':
                    obj = { color: 'green', text: '已发布' }
                    break;
                case 'back':
                    obj = { color: 'red', text: '已退稿' }
                    break;
                case 'recall':
                    obj = { color: 'orange', text: '撤稿中' }
                    break;
                case 'error':
                    obj = { color: 'geekblue', text: '申诉中' }
                    break;
                default:
                    break;
            }
            return <Space direction='vertical' size='small'>
                <Tag style={{ margin: 0 }} color={obj.color}>{obj.text}</Tag>
                {data.censor === 1 && <Tag style={{ margin: 0 }} color="red" onClick={() => checkCensor(data.article_id)}>敏感</Tag>}
                {permission['module:media:order:history'] && <Button size='small' icon={<HistoryOutlined />} onClick={() => checkHistory(data.id)} style={{ width: '100%' }} />}
            </Space>
        }
    }

    const handleUser = {
        title: '所属运营',
        dataIndex: 'handle_user',
        checked: 1,
        render: (val, data) => {
            return <Space direction='vertical'>
                <Text>{val}</Text>
                {
                    data.handle_user_id === 2 &&
                    <Space size='small'>
                        <Button type='link' size='small' style={{ padding: 0 }} onClick={() => checkResourceInfo(data.media_id)}>管理</Button>
                        <Button type='link' size='small' style={{ padding: 0 }} onClick={() => openResourceInfoForm(data.media_id)}>添加</Button>
                    </Space>
                }
            </Space>
        }
    }

    const mediaEditor = {
        title: '所属编辑',
        dataIndex: 'editor_name',
        checked: 1,
        render: (t, data) => {
            return <Space direction='vertical' size={2}>
                <Space>
                    {data.editor_name}
                    {
                        permission['module:media:order:editor:edit'] &&
                        <Button icon={<EditTwoTone />} type='link' size={"small"} onClick={() => {
                            setModal({ ...modal, target_id: data.handle_user_id, rename: true })
                        }} />
                    }
                </Space>
                <span>{data.editor_tel}</span>
            </Space>
        }
    }

    const handler = {
        title: '处理订单',
        dataIndex: 'back_info',
        fixed: 'right',
        width: 160,
        checked: 1,
        render: (text, data) => {
            switch (getStatus(data)) {
                case 'WAIT_VERIFY':
                    return <Space>
                        {
                            permission['module:media:order:verify:handle'] && <>
                                <Button size={size} type="primary" onClick={() => verifyOrderSuccess(data.id)}>通过</Button>
                                <Button size={size} danger onClick={() => setModal({ ...modal, target_id: data.id, verifyFailed: true })}>不通过</Button>
                            </>
                        }
                        {
                            permission['module:media:order:verify:cancel'] && <Popconfirm title="确认撤销稿件吗?"
                                onConfirm={() => cancelOrder(data.id)}
                                onCancel={() => console.log("取消")}
                                okText="确认"
                                cancelText="取消"
                            >
                                <Button>撤稿</Button>
                            </Popconfirm>
                        }
                    </Space>
                case 'WAIT_PUBLISH':
                    return <Space>
                        {
                            permission['module:media:order:publish:handle'] && <>
                                <Button size={size} type="primary" onClick={() => setModal({ ...modal, target_id: data.id, publishSuccess: true })}>回执</Button>
                                <Button size={size} danger onClick={() => setModal({ ...modal, target_id: data.id, publishOrderFailed: true })}>退稿</Button>
                            </>
                        }
                        {
                            permission['module:media:order:publish:recall'] &&
                            <Button onClick={e => setModal({ ...modal, target_id: data.id, recallBox: true })}>申请撤稿</Button>
                        }
                    </Space>
                case 'SUCCESS':
                    let copyUrl = data.back_info;
                    let copyText = `媒体：${getName(data)}\n标题：${data.title}\n地址：${copyUrl}\n`
                    return <Space direction='vertical'>
                        <Space>
                            {
                                permission['module:media:order:success:error'] &&
                                <Button size={size} onClick={() => setModal({ ...modal, target_id: data.id, errorBox: true })}>异议</Button>
                            }
                            {
                                permission['module:media:order:success:handle'] &&
                                <Button type="primary" size={size} onClick={() => setModal({ ...modal, target_id: data.id, updateUrl: true })}>编辑</Button>
                            }
                            <Paragraph style={{ marginBottom: 0 }} copyable={{ text: copyText }}>
                                <a href={data.back_info} className='table-btn' target="_blank" rel="noopener noreferrer">查 看</a>
                            </Paragraph>
                            {
                                permission['module:order:search:workorder'] &&
                                <Dropdown
                                    menu={<Menu
                                        items={[
                                            {
                                                key: '1',
                                                label: (
                                                    <Button type='link' size='small' onClick={() => searchWorkOrder(data.id)}>查看所属工单</Button>
                                                ),
                                            },
                                        ]}
                                    />
                                    }>
                                    <Button type='link' icon={<UnorderedListOutlined />} size='small' />
                                </Dropdown>
                            }
                        </Space>
                        {
                            permission['module:media:order:success:reject'] &&
                            <Popconfirm
                                title="是否退稿"
                                onConfirm={() => rejectOrder(data.id)}
                                onCancel={() => message.warn('已取消')}
                                okText="确认"
                                cancelText="取消"
                            >
                                <Button danger size={size}>退稿</Button>
                            </Popconfirm>
                        }
                    </Space>
                case 'BACK':
                    return <Space>
                        {
                            data.reason && <Paragraph ellipsis={{ rows: 3, expandable: true }} style={{ margin: 0 }}>
                                {data.reason}
                            </Paragraph>
                        }
                        {
                            data.back_info && <Paragraph ellipsis={{ rows: 3, expandable: true }} style={{ margin: 0 }}>
                                {data.back_info}
                            </Paragraph>
                        }
                    </Space>
                case 'RECALL':
                    return <Space direction='vertical'>
                        <Button size={size} onClick={() => showErrorInfo(data, 'recall')}>
                            查看详情
                        </Button>
                        {
                            permission['module:media:order:recall:handle'] &&
                            <Space>
                                <Button size={size} type="primary" onClick={() => agreeRecallOrder(data.id)}>同意</Button>
                                <Button size={size} danger onClick={() => refuseRecallOrder(data.id)}>拒绝</Button>
                            </Space>
                        }
                    </Space>
                case 'ERROR':
                    return <Space direction='vertical'>
                        <Button size={size} onClick={() => showErrorInfo(data, 'error')}>
                            查看详情
                        </Button>
                        {
                            permission['module:media:order:error:handle'] &&
                            <Space>
                                <Button size={size} type="primary" onClick={() => refuseErrorOrder(data.id)}>已处理</Button>
                                <Button size={size} danger onClick={() => agreeErrorOrder(data.id)}>退稿</Button>
                            </Space>
                        }
                    </Space>
                default:
                    return
            }
        }
    }

    let result = []
    let defaultOrder = _.concat(
        defaultConfig,
        orderContent,
        orderPrice,
    )

    switch (role) {
        case "CUSTOMER":
            if (action === 'EDIT') {
                result = _.concat(
                    defaultOrder,
                    orderTime,
                    orderStatus,
                    handler,
                )
            } else {
                result = _.concat(
                    defaultOrder,
                    republic,
                    orderTime,
                    orderStatus,
                    handler,
                )
            }
            break;
        case "OPERATOR":
        case "OPERATOR_LEADER":
        case "OPERATOR_ADMIN":
            result = _.concat(
                orderSalesAndCustomer,
                defaultOrder,
                handleUser, mediaEditor,
                orderTime,
                orderStatusCol,
                orderStatus,
                handler,
            )
            break;
        case "OPERATOR_MEDIA":
            result = _.concat(
                defaultConfig,
                orderContent,
                handleUser, mediaEditor,
                orderTime,
                orderStatusCol,
                orderStatus,
                handler,
            )
            break;
        case "SALE":
        case 'SUPPORT_STAFF':
        case 'FRANCHISE':
        case "SALE_LEADER":
        case "SALE_ATTACHE":
            result = _.concat(
                orderSalesAndCustomer,
                defaultOrder,
                orderTime,
                salesOrderStatusCol,
                orderStatus,
                handler,
            )
            break;
        default:
            break;
    }
    return result
}

const MediaInfo = ({ data, closedMedia }) => {
    let week_available = data.week_available === '周末休息'
        ? <Tag color="blue">周末休息</Tag> : <Tag color="#1677ff">周末可发</Tag>
    return <Popover
        placement="bottomLeft"
        trigger="click"
        content={<Descriptions title="媒体详情" bordered>
            <Descriptions.Item label="收录类型">{data.effect}</Descriptions.Item>
            <Descriptions.Item label="周末可发">
                {week_available}
            </Descriptions.Item>
            <Descriptions.Item label="出稿率">
                <Text strong>{data.publish_rate === null ? 0 : data.publish_rate}%</Text>
            </Descriptions.Item>
            <Descriptions.Item label="平均发布时间">
                <Text strong>{minute2hour(data.publish_time)}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="媒体状态" span={3}>
                {
                    Boolean(data.closed)
                        ? <Space>
                            <Badge status="warning" text="截稿" />
                            <Button type='primary' size='small' onClick={() => closedMedia(data.media_id)}>恢复</Button>
                        </Space>
                        : <Space>
                            <Badge status="processing" text="正常" />
                            <Button type='primary' danger size='small' onClick={() => closedMedia(data.media_id)}>截稿</Button>
                        </Space>
                }
            </Descriptions.Item>
        </Descriptions>}>
        <Button type='link' size='small' style={{ padding: 0 }}>媒体详情</Button>
    </Popover>
}


const WorkOrderInfo = ({ data }) => {
    if (!_.isEmpty(data)) {
        let state = <Badge status="" text="" />
        switch (data[0].state) {
            case 'PENDING':
                state = <Badge color="blue" text="未完成" />
                break;
            case 'CONFIRMED':
                state = <Badge color="geekblue" text="待支付" />
                break;
            case 'UNCHECKED':
                state = <Badge color="gold" text="待审核" />
                break;
            case 'UNPAID':
                state = <Badge color="purple" text="未打款" />
                break;
            case 'SUCCESS':
                state = <Badge color="green" text="已支付" />
                break;
            case 'FAILED':
                state = <Badge color="red" text="已取消" />
                break;
            default:
        }

        return <Descriptions title="所属工单详情" bordered>
            <Descriptions.Item label="工单名称" span={2}>{data[0].name}</Descriptions.Item>
            <Descriptions.Item label="状态">
                {state}
            </Descriptions.Item>
            <Descriptions.Item label="所属销售" span={3}>{data[0].account}</Descriptions.Item>
            <Descriptions.Item label="备注" span={3}>{data[0].remark}</Descriptions.Item>
            <Descriptions.Item label="创建时间" span={3}>{data[0].create_time}</Descriptions.Item>
            <Descriptions.Item label="完成时间" span={3}>{data[0].finish_time}</Descriptions.Item>
        </Descriptions>
    } else {
        return <div>
            暂未绑定工单
        </div>
    }

}