import React, { useEffect, useState } from 'react'
import { Tag, List, Typography, Space, Tooltip, Popconfirm, Button, message } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';

const axios = require('axios');

export const TagTypes = () => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (id) => {
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/tag/types',
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    return <div>
        <List
            loading={loading}
            dataSource={data}
            renderItem={e => (
                <List.Item>
                    <Space>
                        <Tag key={e.id} color='blue'>{e.name}</Tag>
                        <Typography.Text>数量: {e.num}</Typography.Text>
                    </Space>
                    <Tooltip title="删除标签" mouseEnterDelay={0.3}>
                        <Popconfirm
                            title="是否删除该标签"
                            // onConfirm={() => deleteUser(data.id)}
                            onCancel={() => message.warn('已取消')}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button type='link' icon={<DeleteTwoTone />} size='small' />
                        </Popconfirm>
                    </Tooltip>
                </List.Item>
            )}
        />
    </div>
}