import { Button, Col, Form, Layout, Checkbox, Row, } from 'antd';
import _ from 'lodash';
import React from 'react';
const { Content } = Layout;
const layout = { layout: 'vertical' };

const MediaIconForm = ({ mid, handleStatus, resetModal }) => {

    return <>
        <Content className='table-search'>
            <Form
                {...layout}
                name="basic"
                onFinish={val => {
                    resetModal()
                    handleStatus(mid, 'media_icons', _.join(val.icons, ','))
                }}
            >
                <Form.Item name="icons" label="媒体标识">
                    <Checkbox.Group>
                        <Row>
                            <Col span={8}>
                                <Checkbox value="荐" style={{ lineHeight: '32px' }}>
                                    荐
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="秒" style={{ lineHeight: '32px' }}>
                                    秒
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="独" style={{ lineHeight: '32px' }}>
                                    独
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="惠" style={{ lineHeight: '32px' }}>
                                    惠
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="优" style={{ lineHeight: '32px' }}>
                                    优
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="新" style={{ lineHeight: '32px' }}>
                                    新
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="包" style={{ lineHeight: '32px' }}>
                                    包
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="奖" style={{ lineHeight: '32px' }}>
                                    奖
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="活" style={{ lineHeight: '32px' }}>
                                    活
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="热" style={{ lineHeight: '32px' }}>
                                    热
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                        保存
                    </Button>
                </Form.Item>
            </Form>
        </Content>
    </>;
}


export default MediaIconForm
