import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';

import { userLoginState, userRoleState, userState, orderNumsState, refreshUserState } from '../Apps/atoms/Atoms'
import User from '../entities/VO/User'

let AuthContext = React.createContext()
const USER_INFO = '_USER_INFO_'

export function AuthProvider({ children }) {
    const loggedIn = useRecoilValue(userLoginState)
    const role = useRecoilValue(userRoleState)
    const [refresh, setRefresh] = useRecoilState(refreshUserState)
    const setUser = useSetRecoilState(userState)
    const setOrderState = useSetRecoilState(orderNumsState)
    let navigate = useNavigate();

    // 获取用户信息 绑定sessionUser 以及 cookie
    const getUserInfo = () => {
        axios.get('/getUser')
            .then((res) => {
                if (res.data) {
                    let user = res.data
                    setUser(user)
                } else {
                    resetLoginState()
                }
                setRefresh(false)
            })
    }

    useEffect(() => {
        if (loggedIn) {
            if (role === 'CUSTOMER') {
                refresh && getUserInfo()
            }
        }
    }, [loggedIn, role, refresh])

    // 更新用户订单数据
    const getUserOrderNums = useCallback(() => {
        axios.get('/user/order/nums').then((res) => {
            if (res.data === '请重新登录!') {
                resetLoginState()
                navigate('/')
            }
            setOrderState(res.data)
        })
    }, [])

    const timerRef = useRef()
    const time = 1000 * 60 * 2

    useEffect(() => {
        if (loggedIn) {
            getUserOrderNums()
            const timer = setInterval(() => {
                // console.log(dayjs().format('MMMM Do YYYY, h:mm:ss a'))
                getUserOrderNums()
            }, time)
            timerRef.current = timer
        } else {
            clearInterval(timerRef.current)
            setOrderState([])
        }
    }, [loggedIn])

    const resetLoginState = () => {
        setUser(User)
        localStorage.removeItem(USER_INFO)
    }

    let values = {
        resetLoginState
    }
    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export const useAuthUser = () => {
    return useContext(AuthContext)
}