import _ from 'lodash'

const getTotalPeople = (json) => {
    let total_people = 0
    _.forEach(json, e => {
        if (_.size(e.detail) > 0) {
            total_people += _.size(e.detail)
        }
    })
    return total_people;
}

const getPriceArray = (json) => {
    let priceArray = []
    _.forEach(json, e => {
        if (_.size(e.detail) > 0) {
            priceArray = _.concat(priceArray, _.toPairs(e.detail))
        }
    })
    return priceArray
}

const getPrice2 = (json) => {
    let price2 = 0
    _.forEach(json, e => {
        if (_.size(e.detail) === 0) {
            price2 += Math.round(e.price)
        }
    })
    return price2
}

const getTargetMoney = (n, arr) => {
    return _.filter(arr, e => Math.round(e[0]) === n)[0][1]
}

/**
  * 
  * @param {当前排名} rank 
  * @param {达标人数} enoughPeople 
  * @param {规则}     rule
  */
export const getMoney = (rank, enoughPeople, rule) => {
    if (rank < 1 || enoughPeople < 1) {
        return 0
    }
    let json = JSON.parse(JSON.parse(rule))
    let total_people = getTotalPeople(json)
    let arr = getPriceArray(json)
    let price2 = getPrice2(json)

    // 如果篇数 大于 目标篇数
    // 是否在规定范围内
    // 均分多少钱
    if (rank <= enoughPeople) {
        if (rank <= total_people) {
            return getTargetMoney(rank, arr).toFixed(2)
        } else {
            return (price2 / (enoughPeople - total_people)).toFixed(2)
        }
    } else {
        return '未达到排名'
    }
}