import { atom, selector } from 'recoil';
import _ from 'lodash'

import User from '../../entities/VO/User'
import { Platform } from '../common/CONSTANTS';


const CryptoJS = require("crypto-js");

const USER_INFO = '_USER_INFO_'
const userEncrypt = localStorage.getItem(USER_INFO)

let SECURE_KEY = 'e2f8c145-ac9a-c5d8-d5d8-cdcfff2401b6'

// 确认用户是否用待处理订单
export const orderNumsState = atom({
    key: 'user_has_order_state',
    default: []
});

export const customerHasOrderState = selector({
    key: 'customer_has_order',
    get: ({ get }) => {
        const hasOrder = get(orderNumsState);
        return _.reduce(hasOrder, (result, e) => {
            return Boolean(e?.customer) || Boolean(result)
        }, false)
    },
});

export const editorHasOrderState = selector({
    key: 'editor_has_order',
    get: ({ get }) => {
        const hasOrder = get(orderNumsState);
        return _.reduce(hasOrder, (result, e) => {
            return Boolean(e?.editor) || Boolean(result)
        }, false)
    },
});

// 用户账号信息
export const userState = atom({
    key: 'user_state',
    default: userEncrypt
        ? JSON.parse(CryptoJS.AES.decrypt(userEncrypt, SECURE_KEY).toString(CryptoJS.enc.Utf8))
        : User
});

export const platformState = atom({
    key: 'platform_state',
    default: Platform.media.id
})

export const platformChange = atom({
    key: 'platform_loading',
    default: false
})

export const refreshUserState = atom({
    key: 'refresh_user_state',
    default: false
})

export const sidebarCollapsed = atom({
    key: 'sidebar_collapsed',
    default: false
})

// 用户角色信息
export const userRoleState = selector({
    key: 'user_role_state',
    get: ({ get }) => {
        const user = get(userState);
        return user?.role?.role
    },
});

// 用户登录信息
export const userAttacheState = selector({
    key: 'user_attache_state',
    get: ({ get }) => {
        const user = get(userState);
        return user?.role?.attache
    },
});

// 用户登录信息
export const userLoginState = selector({
    key: 'user_login_state',
    get: ({ get }) => {
        const user = get(userState);
        return user?.id ? true : false
    },
});

export const userVipState = selector({
    key: 'is_user_vip_state',
    get: ({ get }) => {
        const user = get(userState);
        return user.sale_group && user.sale_group.vip !== 1 && user.role.attache === 3
    },
});