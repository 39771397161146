import React from 'react';
import { Layout } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import PageHeader from '../../layout/PageHeader';
import MediaPrepayTable from './MediaPrepayTable';

const { Content } = Layout;


// 媒体预付管理
const MediaPrepay = () => {
    let pageTitle = "媒体预付管理"
    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                style={{ backgroundColor: '#fff' }}
            />
            <Content style={{ margin: 24 }}>
                <MediaPrepayTable />
            </Content>
        </HelmetProvider>

    )
}

export default MediaPrepay;