import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const SendOrderSearchConfig = () => {
    const role = useRecoilValue(userRoleState)

    const defaultSearch = [
        {
            name: 'name',
            label: '订单标题',
            placeholder: '请输入标题',
            type: 'input'
        }
    ]

    const handleSearch = [
        {
            name: 'account',
            label: '派单对象',
            placeholder: '请输入派单对象',
            type: 'input'
        },
    ]



    const timeSearch = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: null
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        },
    ]



    let result = []

    switch (role) {
        case "CUSTOMER":
            result = _.concat(
                defaultSearch,
                timeSearch
            )
            break;
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
            result = _.concat(
                defaultSearch,
                handleSearch,
                timeSearch
            )
            break;
        default:
            break;
    }

    return result
}

export default SendOrderSearchConfig