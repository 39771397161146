import React, { useEffect, createRef } from 'react'
import { Form, Row, Button, Space } from 'antd';
import CreateForm from '../../common/CreateForm';
import UserDatasConfig from '../../../role/user/UserDatasConfig';
import _ from 'lodash'

const layout = {
    layout: 'vertical'
};

const AddUserForm = (props) => {
    const { user, drawerAction, type } = props;
    const { visible, setVisible, createUser, editUser, resetUserForm, resetVisible } = drawerAction

    const Datas = UserDatasConfig({ type })
    function customizer(objValue, srcValue) {
        return _.isUndefined(srcValue) ? "" : srcValue;
    }
    const formRef = createRef();

    useEffect(() => {
        formRef.current.setFieldsValue(user)
    }, [user, formRef])

    return (
        <>
            <Form
                {...layout}
                ref={formRef}
                name="basic"
                onFinish={(val) => {
                    if (visible.type === 'ADD') {
                        createUser(_.assignInWith(user, val, customizer))
                    } else if (visible.type === 'EDIT') {
                        editUser(_.assignInWith(user, val, customizer))
                    }
                    resetVisible()
                    resetUserForm()
                }}
                onFinishFailed={errorInfo => {
                    console.log('Failed:', errorInfo);
                }}
            >
                {Datas.map(data => <Row gutter={16} key={data[0].name}>
                    <CreateForm datas={data} span={12} />
                </Row>)}
                <div className='side-drawer'>
                    <Space>
                        <Button onClick={() => {
                            resetVisible()
                            resetUserForm()
                        }} style={{ marginRight: 8 }}>
                            取消
                    </Button>
                        <Button htmlType='submit' type='primary'>提交</Button>
                    </Space>
                </div>
            </Form>
        </>
    )
}

export default AddUserForm