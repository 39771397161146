import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Spin } from 'antd';
import _ from 'lodash'

import { resultResponse } from '../../common/ResultResponse';

const layout = { layout: 'vertical' };

const axios = require('axios');
const UserSalaryForm = ({ uid = 0, reloadTable }) => {

    const [form] = Form.useForm()
    const [salary, setSalary] = useState({
        user_id: '',
        base_salary: '',
        extra_salary: '',
        performance_amount: '',
        performance_salary: '',
        bonus_salary: ''
    })

    const [mode, setMode] = useState("VIEW")
    const [modifyState, setModifyState] = useState(true)
    const [loading, setLoading] = useState(false)

    const getUserSalary = async (id) => {
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: `/user/salary/${id}`,
            }).then((res) => {
                let tempInvoice = res.data.data
                let tempData = _.pick(tempInvoice, _.keysIn(salary))
                setSalary(tempData)
                setLoading(false)
            })
        }
    }

    const addUserSalary = async (val) => {
        let params = new FormData()
        val && _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        params.append('user_id', uid)
        await axios({
            method: 'post',
            url: `/user/salary/add`,
            data: params
        }).then((res) => {
            resultResponse(res, '已添加')
            reloadTable()
            setMode("VIEW")
            setModifyState(true)
        })
    }

    useEffect(() => {
        getUserSalary(uid)
    }, [])

    useEffect(() => {
        form.setFieldsValue(salary)
    }, [salary, form])

    return <>
        {
            loading
                ? <div style={{ display: 'grid', placeContent: 'center' }}>
                    <Spin />
                </div>
                : <Form
                    {...layout}
                    form={form}
                    onFinish={val => addUserSalary(val)}
                >
                    <Form.Item label="底薪" name="base_salary"
                        rules={[{ required: true, message: '请填写底薪' }]}
                    >
                        <Input disabled={modifyState} />
                    </Form.Item>
                    <Form.Item label="绩效" name="performance_salary">
                        <Input disabled={modifyState} />
                    </Form.Item>
                    <Form.Item label="绩效指标" name="performance_amount">
                        <Input disabled={modifyState} />
                    </Form.Item>
                    <Form.Item label="奖金" name="bonus_salary">
                        <Input disabled={modifyState} />
                    </Form.Item>
                    {
                        {
                            'EDIT':
                                <Button type="primary" onClick={() => form.submit()}>
                                    保存
                                </Button>,
                            'VIEW':
                                <Button type="primary" onClick={() => {
                                    setMode("EDIT")
                                    setModifyState(false)
                                }}>
                                    编辑
                                </Button>
                        }[mode]
                    }
                </Form >
        }
    </>
}


export default UserSalaryForm