import React from 'react'
import { Tag, Typography, Button, Space } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState, userAttacheState } from '../../Apps/atoms/Atoms'
import { formatTime } from '../../utils/TimeUtils'

const { Paragraph, Text } = Typography;
const tableStyle = { display: 'flex', flexDirection: 'column' }


export const QaOrderTableConfig = ({ modal, setModal, handleOrders }) => {
    const role = useRecoilValue(userRoleState)
    const attache = useRecoilValue(userAttacheState)

    const { verifyOrderSuccess } = handleOrders;
    const size = 'small'

    const orderSalesAndCustomer = {
        title: '销售姓名',
        dataIndex: 'sale_name',
        render: (t, data) => {
            return (
                <>
                    <Text strong>{data.customer_name}</Text>
                    <br />
                    <Text>{t}</Text>
                </>
            )
        }
    }

    const defaultConfig = [
        {
            title: '媒体名称',
            dataIndex: 'qa_name',
            width: 120
        }, {
            title: '标题',
            dataIndex: 'title',
            render: (text, data) => {
                let copyUrl = `http://p.yunzitui.com/post/${data.order_no}`
                let copyText = `媒体：${data.qa_name}\n标题：${data.title}\n地址：${copyUrl}\n`
                return (
                    <>
                        <Paragraph style={{ marginBottom: 0 }} copyable={{
                            text: (data.remarks === '' || data.remarks === null) ? copyText : copyText + `备注：${data.remarks}`
                        }}>{text}</Paragraph>
                    </>
                )
            }
        }
    ]

    const orderPrice = {
        title: '价格',
        dataIndex: 'paid_money',
        render: (t, data) => {
            switch (role) {
                case 'CUSTOMER':
                    return <div>
                        <Text>价格: {data.paid_money}</Text>
                    </div>
                case 'OPERATOR':
                    if (attache === 0) {
                        return (
                            <div>
                                <Text>成本:{data.cost_money}{data.add_money > 0 && (`(${data.add_money + data.cost_money})`)}</Text><br />
                                <Text>付款:{data.paid_money}{data.add_money > 0 && (`(${data.add_money + data.paid_money})`)}</Text><br />
                                {data.agent_paid_money > 0 && <Text>加盟:{data.agent_paid_money}{data.add_money > 0 && (`(${data.add_money + data.agent_paid_money})`)}</Text>}
                                {data.coupon_money > 0 && <><br /><Text type="secondary" style={{ fontSize: '12px' }}>优惠金额{data.coupon_money}</Text></>}
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                {data.cost_money}
                            </div>
                        )
                    }

                case 'SALE':
                    return data.paid_money
                default:
                    return data.paid_money
            }
        }
    }

    const orderContent = [
        {
            title: '内容',
            dataIndex: 'content',
            width: 96,
            render: (text, data) => {
                let content = <a href={`http://p.yunzitui.com/post/${data.order_no}`} target="_blank" rel='noopener noreferrer'>文本查看</a>;
                return (
                    <div style={tableStyle}>
                        {data.content === 1 && <span>{content}</span>}
                    </div>
                )
            }
        },
        {
            title: '备注',
            dataIndex: 'remarks'
        }
    ]

    const orderTime = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 96,
            render: (t) => formatTime(t)
        },
        {
            title: '审核时间',
            dataIndex: 'handle_time',
            width: 96,
            render: (t) => formatTime(t)
        },
        {
            title: '发布时间',
            dataIndex: 'qa_handle_time',
            width: 96,
            render: (t) => formatTime(t)
        },
    ]

    const orderStatus = {
        title: '状态',
        dataIndex: 'status',
        width: 72,
        fixed: 'right',
        render: (text, data) => {
            let result = '';
            switch (data.qa_status) {
                case 'waitPublish':
                    switch (data.status) {
                        default:
                            return <Tag style={{ margin: 0 }} color="blue">待审核</Tag>;
                        case 'waitPublish':
                            return <Tag style={{ margin: 0 }} color="gold">待发布</Tag>;
                        case 'unconfirmed':
                            return <Tag style={{ margin: 0 }} color="geekblue">待确认</Tag>;
                    }
                case 'success':
                    result = <Tag style={{ margin: 0 }} color="green">已发布</Tag>;
                    break;
                case 'back':
                    result = <Tag style={{ margin: 0 }} color="red">已退稿</Tag>;
                    break;
                case 'recall':
                    result = <Tag style={{ margin: 0 }} color="orange">撤稿中</Tag>;
                    break;
                case 'error':
                    result = <Tag style={{ margin: 0 }} color="geekblue">申诉中</Tag>;
                    break;
                default:
                    break;
            }
            return result
        }
    }

    const orderBackInfo = {
        title: '回执信息',
        dataIndex: 'back_info',
        width: 150,
        fixed: 'right',
        render: (text, data) => {
            let result = '';
            if (data.reason) {
                result = data.reason;
            } else if (data.back_info) {
                result = data.back_info;
            }
            switch (data.qa_status) {
                case 'success':
                    result = <>
                        <Button size={size} className='table-btn'
                            onClick={() => {
                                setModal({ ...modal, data: data, response: true })
                            }}>查看</Button>
                    </>
                    break;
                default:
                    result = <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}></Paragraph>
                    break;
            }
            return result
        }
    }

    const handleOrder = {
        title: '处理订单',
        dataIndex: 'back_info',
        width: 160,
        fixed: 'right',
        render: (text, data) => {
            let result = '';
            if (data.reason) {
                result = data.reason;
            } else if (data.back_info) {
                result = data.back_info;
            }
            switch (data.qa_status) {
                case 'waitPublish':
                    switch (data.status) {
                        default:
                            return <Space>
                                <Button size={size} type="primary" className='table-btn'
                                    onClick={() => {
                                        verifyOrderSuccess(data.id)
                                    }}
                                >通过</Button>
                                <Button size={size} danger className='table-btn'
                                    onClick={() => {
                                        setModal({
                                            ...modal, target_id: data.id, verifyFailed: true
                                        })
                                    }}>不通过</Button>
                            </Space>
                        case 'waitPublish':
                            return <Space>
                                <Button size={size} type="primary" className='table-btn'
                                    onClick={() => {
                                        setModal({
                                            ...modal, target_id: data.id, publishSuccess: true
                                        })
                                    }}>回执</Button>
                                <Button size={size} danger className='table-btn'
                                    onClick={() => {
                                        setModal({
                                            ...modal, target_id: data.id, publishOrderFailed: true
                                        })
                                    }}>退稿</Button>
                            </Space>
                    }
                case 'success':
                    result = (
                        <Space>
                            <Button type="primary" size={size} className='table-btn'
                                onClick={() => {
                                    setModal({ ...modal, target_id: data.id, updateUrl: true })
                                }}>编辑</Button>
                            <Button size={size} className='table-btn'
                                onClick={() => {
                                    setModal({ ...modal, data: data, response: true })
                                }}>查看</Button>
                        </Space>
                    );
                    break;
                default:
                    break;
            }
            return result
        }
    }



    let result = []
    let defaultOrder = _.concat(
        defaultConfig,
        orderContent,
        orderPrice,
    )

    switch (role) {
        case "CUSTOMER":
            result = _.concat(
                defaultOrder,
                orderTime,
                orderStatus,
                orderBackInfo,
            )
            break;
        case "OPERATOR":
            result = attache === 1 ? _.concat(
                defaultOrder,
                orderTime,
                orderStatus,
                orderBackInfo,
            ) : _.concat(
                orderSalesAndCustomer,
                defaultOrder,
                orderTime,
                orderStatus,
                handleOrder,
            )
            break;
        case "SALE":
        case 'FRANCHISE':
            result = _.concat(
                orderSalesAndCustomer,
                defaultOrder,
                orderTime,
                orderStatus,
                orderBackInfo,
            )
            break;
        default:
            break;
    }
    return result
}
