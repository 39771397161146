import React, { useState, useEffect } from 'react'
import { Typography, Table } from 'antd';
import { tablePagination } from '../../common/tableCommon';
import _ from 'lodash'

const axios = require('axios');

const { Text } = Typography
const MediaModal = ({ eventId }) => {

    const [datas, setDatas] = useState([])
    const [pagination, setPagination] = useState(tablePagination)
    const [loading, setLoading] = useState(false)


    const checkDetail = async (id) => {
        setLoading(true)
        let params = new FormData()

        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        params.append("event_id", eventId)
        await axios({
            method: 'post',
            url: `/media/getMediaList`,
            data: params
        }).then((res) => {
            setDatas(res.data.data)
            let total = res.data.total
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
        setLoading(false)
    }


    useEffect(() => {
        if (!_.isNil(eventId)) {
            checkDetail()
        }
    }, [pagination.current, pagination.pageSize, eventId])

    return (
        <>
            <Table
                size='middle'
                rowKey={record => record.id}
                loading={loading}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                    }, {
                        title: '价格',
                        dataIndex: 'sale_price',
                        checked: 1,
                        render: (t, data) => {
                            return data.user_price
                        }
                    }, {
                        title: '媒体详情',
                        dataIndex: 'location',
                        checked: 1,
                        render: (text, data) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>
                                        <Text className="table-text">频道类型:</Text>
                                        <Text strong>{data.channel_type}</Text>
                                    </div>
                                    <div>
                                        <Text className="table-text">收录效果:</Text>
                                        <Text strong>{data.effect}</Text>
                                    </div>
                                    <div>
                                        <Text className="table-text">所在地区:</Text>
                                        <Text strong>{data.area}</Text>
                                    </div>
                                </div>
                            )
                        }
                    }
                ]}
                dataSource={datas}
                pagination={pagination}
                onChange={(pagination) => {
                    setPagination(pagination)
                }}
            />
        </>
    )
}

export default MediaModal
