import React from 'react'
import { Tag, Typography, Button, message, Popconfirm, Space } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { formatTime } from '../../utils/TimeUtils'
import { check } from '../../utils/permission/rules';


const { Paragraph, Text } = Typography;

export const WechatOrderTableConfig = ({ modal, setModal, handleOrders, tab, action }) => {

    const role = useRecoilValue(userRoleState)
    const permission = {
        'module:media:order:money:cost': check(role, 'module:media:order:money:cost', { action }),
        'module:media:order:cost:modify': check(role, 'module:media:order:cost:modify', null),
        'module:media:order:money:paid': check(role, 'module:media:order:money:paid', { action }),
        'module:media:order:money:agent': check(role, 'module:media:order:money:agent', null),
        'module:media:order:money:sales:agent': check(role, 'module:media:order:money:sales:agent', null),
        'module:media:order:sales:status': check(role, 'module:media:order:sales:status', null),
        'module:media:order:status': check(role, 'module:media:order:status', null),
        'module:media:order:history': check(role, 'module:media:order:history', null),
        'module:media:order:editor:edit': check(role, 'module:media:order:editor:edit', null),
        'module:media:order:recall:handle': check(role, 'module:media:order:recall:handle', { action }),
        'module:media:order:error:handle': check(role, 'module:media:order:error:handle', { action }),
        'module:media:order:success:reject': check(role, 'module:media:order:success:reject', null),
        'module:media:order:success:handle': check(role, 'module:media:order:success:handle', { action }),
        'module:media:order:success:error': check(role, 'module:media:order:success:error', { action }),
        'module:media:order:publish:handle': check(role, 'module:media:order:publish:handle', { action }),
        'module:media:order:publish:recall': check(role, 'module:media:order:publish:recall', { action }),
        'module:media:order:verify:handle': check(role, 'module:media:order:verify:handle', { action }),
        'module:media:order:verify:cancel': check(role, 'module:media:order:verify:cancel', { action }),
        'module:media:order:customer:name': check(role, 'module:media:order:customer:name', null),
        'module:media:order:sales:name': check(role, 'module:media:order:sales:name', null),
    }

    const { verifyOrderSuccess, cancelOrder, rejectOrder } = handleOrders;

    const size = 'small'

    const orderSalesAndCustomer = {
        title: '销售姓名',
        dataIndex: 'sale_name',
        width: 130,
        checked: 1,
        render: (val, data) => {
            return <Space direction='vertical' size={2}>
                {permission['module:media:order:customer:name'] && <Text strong>{data.customer_name}</Text>}
                {permission['module:media:order:sales:name'] && <Text>{data.sale_name}</Text>}
            </Space>
        }
    }

    const defaultConfig = [
        {
            title: '媒体名称',
            dataIndex: 'wechat_name',
            width: 130,
            checked: 1,
            render: (val, data) => {

                return <Space direction='vertical' size={2}>
                    <a
                        className='title-link'
                        href={data.sample_url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {val}
                    </a>
                    {
                        (data.pending === 2 && data.media_status === 'waitPublish') &&
                        <Tag color="red">已截稿</Tag>
                    }
                </Space>
            }
        }, {
            title: '标题',
            dataIndex: 'title',
            checked: 1,
            render: (val, data) => <Paragraph style={{ marginBottom: 0 }}>{val}</Paragraph>
        }
    ]

    const orderPrice = {
        title: '价格',
        dataIndex: 'paid_money',
        width: 96,
        checked: 1,
        render: (t, data) => {
            return <Space direction='vertical' size={2}>
                {permission['module:media:order:money:cost'] && <Text strong>成本：{data.cost_money}</Text>}
                {permission['module:media:order:money:agent'] && <Text strong>相对：{data.agent_paid_money}</Text>}
                {permission['module:media:order:money:sales:agent'] && <Text strong>成本：{data.agent_paid_money}</Text>}
                {permission['module:media:order:money:paid'] && <Text strong>付款：{data.paid_money}</Text>}
                {data.coupon_money > 0 && <Text type="secondary" style={{ fontSize: '12px' }}>优惠金额{data.coupon_money}</Text>}
            </Space>
        }
    }

    const orderContent = [
        {
            title: '内容',
            dataIndex: 'content',
            width: 80,
            checked: 1,
            render: (text, data) => {
                let content = <a href={`http://p.yunzitui.com/post/${data.order_no}`} target="_blank" rel='noopener noreferrer'>文本查看</a>;
                let url = <a href={`${data.url}`} target="_blank" rel='noopener noreferrer'>来源地址</a>;
                return (
                    <Space direction='vertical'>
                        {data.content === 1 && content}
                        {!(_.isNil(data.url) || data.url === "") && url}
                    </Space>
                )
            }
        }, {
            title: '备注',
            dataIndex: 'remarks',
            checked: 1,
        },
    ]

    const orderTime = [
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 80,
            checked: 1,
            render: (t) => formatTime(t)
        }, {
            title: '审核时间',
            dataIndex: 'handle_time',
            width: 80,
            checked: 1,
            render: (t) => formatTime(t)
        }, {
            title: '发布时间',
            dataIndex: 'publish_time',
            width: 80,
            checked: 1,
            render: (t) => formatTime(t)
        },
    ]

    const orderStatus = {
        title: '状态',
        dataIndex: 'status',
        width: 72,
        fixed: 'right',
        checked: 1,
        render: (text, data) => {
            let result = '';
            switch (data.status) {
                case 'NOT_PASS':
                    return <Tag style={{ margin: 0 }} color="red">未通过</Tag>;
                case 'WAIT_VERIFY':
                    return <Tag style={{ margin: 0 }} color="blue">待审核</Tag>;
                case 'WAIT_PUBLISH':
                    return <Tag style={{ margin: 0 }} color="gold">待发布</Tag>;
                case 'UNCONFIRMED':
                    return <Tag style={{ margin: 0 }} color="geekblue">待确认</Tag>;
                case 'SUCCESS':
                    return <Tag style={{ margin: 0 }} color="green">已发布</Tag>;
                case 'BACK':
                    return <Tag style={{ margin: 0 }} color="red">已退稿</Tag>;
                default:
                    break;
            }
            return result
        }
    }

    const handleUser = {
        title: '所属运营',
        dataIndex: 'handle_user',
        checked: 1,
    }

    const mediaEditor = {
        title: '所属编辑',
        dataIndex: 'editor_name',
        checked: 1,
        render: (t, data) => {
            return <Space direction='vertical' size={2}>
                <Space>{data.editor_name}</Space>
                <span>{data.editor_tel}</span>
            </Space>
        }
    }

    const handler = {
        title: '处理订单',
        dataIndex: 'reply',
        fixed: 'right',
        width: 160,
        checked: 1,
        render: (text, data) => {
            switch (data.status) {
                case 'WAIT_VERIFY':
                    return <Space>
                        {
                            permission['module:media:order:verify:handle'] && <>
                                <Button size={size} type="primary" onClick={() => verifyOrderSuccess(data.id)}>通过</Button>
                                <Button size={size} danger onClick={() => setModal({ ...modal, target_id: data.id, verifyFailed: true })}>不通过</Button>
                            </>
                        }
                        {
                            permission['module:media:order:verify:cancel'] && <Popconfirm title="确认撤销稿件吗?"
                                onConfirm={() => cancelOrder(data.id)}
                                onCancel={() => console.log("取消")}
                                okText="确认"
                                cancelText="取消"
                            >
                                <Button>撤稿</Button>
                            </Popconfirm>
                        }
                    </Space>
                case 'WAIT_PUBLISH':
                    return <Space>
                        {
                            permission['module:media:order:publish:handle'] && <>
                                <Button size={size} type="primary" onClick={() => setModal({ ...modal, target_id: data.id, publishSuccess: true })}>回执</Button>
                                <Button size={size} danger onClick={() => setModal({ ...modal, target_id: data.id, publishOrderFailed: true })}>退稿</Button>
                            </>
                        }
                        {
                            permission['module:media:order:publish:recall'] &&
                            <Button onClick={e => setModal({ ...modal, target_id: data.id, recallBox: true })}>申请撤稿</Button>
                        }
                    </Space>
                case 'SUCCESS':
                    let copyUrl = data.reply;
                    let copyText = `媒体：${data.weibo_hot_name}\n标题：${data.title}\n地址：${copyUrl}\n`
                    return <Space direction='vertical'>
                        <Space>
                            {
                                permission['module:media:order:success:error'] &&
                                <Button size={size} onClick={() => setModal({ ...modal, target_id: data.id, errorBox: true })}>异议</Button>
                            }
                            {
                                permission['module:media:order:success:handle'] &&
                                <Button type="primary" size={size} onClick={() => setModal({ ...modal, target_id: data.id, updateUrl: true })}>编辑</Button>
                            }
                            <Paragraph style={{ marginBottom: 0 }} copyable={{ text: copyText }}>
                                <a href={data.reply} className='table-btn' target="_blank" rel="noopener noreferrer">查 看</a>
                            </Paragraph>
                        </Space>
                        {
                            permission['module:media:order:success:reject'] &&
                            <Popconfirm
                                title="是否退稿"
                                onConfirm={() => rejectOrder(data.id)}
                                onCancel={() => message.warn('已取消')}
                                okText="确认"
                                cancelText="取消"
                            >
                                <Button danger size={size}>退稿</Button>
                            </Popconfirm>
                        }
                    </Space>
                case 'BACK':
                    return <Space>
                        {
                            data.reply && <Paragraph ellipsis={{ rows: 3, expandable: true }} style={{ margin: 0 }}>
                                {data.reply}
                            </Paragraph>
                        }
                    </Space>
                default:
                    return
            }
        }
    }

    let result = []
    let defaultOrder = _.concat(
        defaultConfig,
        orderContent,
        orderPrice,
    )

    switch (role) {
        case "CUSTOMER":
            if (action === 'EDIT') {
                result = _.concat(
                    defaultOrder,
                    orderTime,
                    orderStatus,
                    handler,
                )
            } else {
                result = _.concat(
                    defaultOrder,
                    orderTime,
                    orderStatus,
                    handler,
                )
            }
            break;
        case "OPERATOR":
        case "OPERATOR_ADMIN":
            result = _.concat(
                orderSalesAndCustomer,
                defaultOrder,
                handleUser, mediaEditor,
                orderTime,
                orderStatus,
                handler,
            )
            break;
        case "OPERATOR_MEDIA":
            result = _.concat(
                orderSalesAndCustomer,
                defaultConfig,
                orderContent,
                handleUser, mediaEditor,
                orderTime,
                orderStatus,
                handler,
            )
            break;
        case "SALE":
        case 'SUPPORT_STAFF':
        case 'FRANCHISE':
        case "SALE_LEADER":
        case "SALE_ATTACHE":
            result = _.concat(
                orderSalesAndCustomer,
                defaultOrder,
                orderTime,
                orderStatus,
                handler,
            )
            break;
        default:
            break;
    }
    return result
}
