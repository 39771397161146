import React from 'react';
import { Typography, Tag, Space } from 'antd'
import { useRecoilValue } from 'recoil';

import { getRouter } from '../../../utils/permission/rules';
import { userRoleState, userState, userVipState, platformState, customerHasOrderState } from '../../atoms/Atoms'
import { Platform } from '../../common/CONSTANTS';

const { Text } = Typography

const OrderLayoutConfig = () => {
    const user = useRecoilValue(userState)
    const role = useRecoilValue(userRoleState)

    const isVip = useRecoilValue(userVipState)
    const hasOrder = useRecoilValue(customerHasOrderState)

    const platform = useRecoilValue(platformState)

    let sendOrderTitle = !isVip
        ? '派单管理'
        : <Space>
            <Text type='secondary'>派单管理</Text>
            <Tag color='red'>会员专享</Tag>
        </Space>

    const router = [
        {
            key: 'page:media:order:add',
            title: '创建文章',
            url: '/dashboard/order/add',
            platform: Platform.media.id
        }, {
            key: 'page:baike:order:add',
            title: '创建百科',
            url: '/dashboard/baike/add',
            platform: Platform.media.id
        }, {
            key: 'page:index:order:add',
            title: '创建文字链',
            url: '/dashboard/index/add',
            platform: Platform.media.id
        }, {
            key: 'page:qa:order:add',
            title: '创建问答',
            url: '/dashboard/qa/add',
            platform: Platform.media.id
        }, {
            key: 'page:order:stats',
            title: '账单统计',
            url: '/dashboard/order/stats',
            platform: Platform.media.id
        }, {
            key: 'page:order:workorder',
            title: '工单管理',
            url: '/dashboard/order/workorder',
            platform: Platform.media.id
        }, {
            key: 'page:order:workorder:invoice:apply',
            title: '发票申请',
            url: '/dashboard/order/workorder/invoice/apply',
            platform: Platform.media.id
        }, {
            key: 'page:order:sendorder',
            title: sendOrderTitle,
            url: '/dashboard/order/sendorder',
            disabled: isVip,
            platform: Platform.media.id
        }, {
            key: 'page:order:earnorder',
            title: '订单收益管理',
            url: '/dashboard/order/earnorder',
            platform: Platform.media.id
        }, {
            key: 'page:media:order:list',
            title: '订单中心',
            url: '/dashboard/order',
            value: hasOrder,
            platform: Platform.media.id
        }, {
            key: 'page:media:project:list',
            title: '项目中心',
            url: '/dashboard/project',
            platform: Platform.media.id
        }, {
            key: 'page:baike:order:list',
            title: '百科订单',
            url: '/dashboard/baike/orders',
            platform: Platform.media.id
        }, {
            key: 'page:weibo:order:add',
            title: '创建微博',
            url: '/dashboard/weibo/order/add',
            platform: Platform.weibo.id
        }, {
            key: 'page:weibo:order:list',
            title: '微博订单中心',
            url: '/dashboard/weibo/order',
            platform: Platform.weibo.id
        }, {
            key: 'page:wechat:order:add',
            title: '创建微信',
            url: '/dashboard/wechat/order/add',
            platform: Platform.wechat.id
        }, {
            key: 'page:wechat:order:list',
            title: '微信订单中心',
            url: '/dashboard/wechat/order',
            platform: Platform.wechat.id
        }, {
            key: 'page:xiaohongshu:order:add',
            title: '创建小红书',
            url: '/dashboard/xiaohongshu/order/add',
            platform: Platform.xiaohongshu.id
        }, {
            key: 'page:xiaohongshu:order:list',
            title: '小红书订单中心',
            url: '/dashboard/xiaohongshu/order',
            platform: Platform.xiaohongshu.id
        }, 
        // {
        //     key: 'page:withdraw:apply',
        //     title: '申请提现',
        //     url: '/dashboard/withdraw/v2/list',
        //     platform: Platform.media.id
        // },
    ]

    const data = { user, platform }
    return getRouter({ role, router, data })

}

export default OrderLayoutConfig