import React from 'react'
import _ from 'lodash'

export const formatTime = (time) => {
    if (_.isNil(time)) { return null }
    return (
        <>
            <span>{time.substr(0, 10)}</span><br />
            <span>{time.substr(11)}</span>
        </>
    )
}

export const formatTimeToDay = (time, type) => {
    if (_.isNil(time)) { return null }
    return <span>{time.substr(0, 10)}</span>
}