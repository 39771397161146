import React from 'react'
import { Typography, Space, Tag, Divider, Popover, Descriptions, Button } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { number2currency } from '../../utils/NumberUtils';

const { Text } = Typography;


export const SalaryLogTableConfig = ({ handleUsers }) => {
    const role = useRecoilValue(userRoleState)

    const defaultConfig = [
        {
            title: '月份',
            dataIndex: 'create_time',
        }, {
            title: '账号名',
            dataIndex: 'account',
            render: (val, data) => {
                return <Space direction='vertical'>
                    <Space direction='horizontal'>
                        <Text>{data.account}</Text>
                        <Text strong>({data.real_name})</Text>
                    </Space>
                    <Tag color={'orange'}>{data.post_name}</Tag>
                </Space>
            }
        }, {
            title: '基本工资',
            dataIndex: 'base_salary',
        }, {
            title: '岗位工资',
            dataIndex: 'post_salary',
        }, {
            title: '绩效工资',
            dataIndex: 'performance_salary',
            render: (val, data) => {
                return <Space direction='vertical'>
                    <Space>
                        <Text>保底绩效: {number2currency(data.performance_salary)}</Text>
                        <Text>额外绩效: {number2currency(data.extra_performance_salary)}</Text>
                    </Space>
                    <Text>未分配绩效: {number2currency(data.undistributed_salary)}</Text>
                </Space>
            }
        }, {
            title: '当月业绩',
            dataIndex: 'performance_salary',
            render: (val, data) => {
                return <>
                    <Space direction='vertical' size={2}>
                        <Text>已付款营收: {number2currency(data.paid_money)}</Text>
                        <Text>未付款营收: {number2currency(data.unpaid_money)}</Text>
                        <Text>实际营收: {number2currency(data.actual_money)}</Text>
                    </Space>
                </>
            }
        }, {
            title: '补助薪资',
            dataIndex: 'subsidized_salary',
        }, {
            title: '工资构成',
            dataIndex: 'id',
            render: (val, data) => {
                let salary = data.performance_salary + data.extra_performance_salary + data.base_salary + data.post_salary + data.subsidized_salary
                return <Space direction='vertical'>
                    <SalaryInfo data={data} />
                    <Text style={{ fontSize: 14 }} strong>总工资: {number2currency(salary)}</Text>
                </Space>
            }
        },
    ]


    let result = []

    switch (role) {
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
        case 'SUPPORT_STAFF':
        case 'FINANCE':
            result = _.concat(
                defaultConfig
            )
            break;
        default:
            break;
    }
    return result
}

function SalaryInfo({ data }) {

    let salary = data.performance_salary + data.extra_performance_salary + data.base_salary + data.post_salary + data.subsidized_salary

    return <Popover
        placement='bottomLeft'
        trigger='click'
        content={
            <Descriptions title="薪资详情" bordered>
                <Descriptions.Item label="基本工资">{data.base_salary}</Descriptions.Item>
                <Descriptions.Item label="岗位工资">
                    {data.post_salary}
                </Descriptions.Item>
                <Descriptions.Item label="保底绩效">
                    <Text>{data.performance_salary}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="额外绩效">
                    <Text strong>{data.extra_performance_salary} ({data.paid_money} * 6%)</Text>
                </Descriptions.Item>
                <Descriptions.Item label="补助薪资" span={3}>
                    {data.subsidized_salary}
                </Descriptions.Item>
                <Descriptions.Item label="总薪资" span={3}>
                    <Text strong> {salary.toFixed(2)}</Text>
                </Descriptions.Item>
            </ Descriptions>
        }>
        <Button type='link' size='small' style={{ padding: 0 }}>媒体详情</Button>
    </Popover>
}