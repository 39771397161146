import React, { useEffect, createRef } from 'react'
import { Row, Button, Form, Space } from 'antd';
import _ from 'lodash'
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import BaikeDatasConfig from '../../../role/baike/BaikeDatasConfig';
import CreateForm from '../../common/CreateForm';


const layout = { layout: 'vertical' };
const AddBaikeForm = (props) => {
    const role = useRecoilValue(userRoleState)
    const { baike, setBaike, drawerAction } = props;
    const { visible, setVisible, createMedia, updateMedia } = drawerAction

    const Datas = BaikeDatasConfig(role)
    function customizer(objValue, srcValue) {
        return _.isUndefined(srcValue) ? "" : srcValue;
    }

    const formRef = createRef();
    useEffect(() => {
        formRef.current.setFieldsValue(baike)
    }, [baike, formRef])


    return (
        <>
            <Form
                {...layout}
                ref={formRef}
                name="basic"
                onFinish={(val) => {
                    if (visible.action === 'ADD') {
                        createMedia(_.assignInWith(baike, val, customizer))
                    } else if (visible.action === 'EDIT') {
                        updateMedia(_.assignInWith(baike, val, customizer))
                    }
                    setVisible({ ...visible, addMedia: false })
                }}
                onFinishFailed={errorInfo => {
                    console.log('Failed:', errorInfo);
                }}
                hideRequiredMark
            >
                {Datas.map(data => <Row gutter={16} key={data[0].name}>
                    <CreateForm entity={baike} setEntity={setBaike} datas={data} span={12} />
                </Row>)}
                <div className='side-drawer'>
                    <Space>
                        <Button onClick={() => {
                            setVisible({ ...visible, addMedia: false })
                        }}>
                            取消
                        </Button>
                        <Button htmlType='submit' type='primary'>提交</Button>
                    </Space>
                </div>
            </Form>
        </>
    )
}

export default AddBaikeForm