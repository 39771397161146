import { useRecoilValue } from 'recoil';

import { getRouter } from '../../../utils/permission/rules';
import { editorHasOrderState, userRoleState, platformState } from '../../atoms/Atoms'
import { Platform } from '../../common/CONSTANTS';
const CustomerLayoutConfig = () => {

    const role = useRecoilValue(userRoleState)
    const platform = useRecoilValue(platformState)
    const hasOrder = useRecoilValue(editorHasOrderState)

    const router = [
        {
            key: 'page:resource:media',
            title: '资源中心',
            url: '/dashboard/resource/media',
            platform: Platform.media.id
        }, {
            key: 'page:resource:order',
            title: '订单审核',
            url: '/dashboard/resource/orders',
            value: hasOrder,
            platform: Platform.media.id
        }, {
            key: 'page:withdraw:apply',
            title: '申请提现',
            url: '/dashboard/withdraw/v2/list',
            platform: Platform.media.id
        }, {
            key: 'page:resource:weibo:hot',
            title: '我的微博',
            url: '/dashboard/resource/weibo/hot',
            platform: Platform.weibo.id
        }, {
            key: 'page:resource:wechat',
            title: '我的微信',
            url: '/dashboard/resource/wechat',
            platform: Platform.wechat.id
        }, {
            key: 'page:resource:xiaohongshu',
            title: '我的小红书',
            url: '/dashboard/resource/xiaohongshu',
            platform: Platform.xiaohongshu.id
        }, {
            key: 'page:resource:douyin',
            title: '我的抖音',
            url: '/dashboard/resource/douyin',
            platform: Platform.douyin.id
        },
    ]

    if (role === 'CUSTOMER' || role === 'SALE_ATTACHE') {
        return getRouter({ role: role, router, data: { platform } })
    } else {
        return []
    }
}

export default CustomerLayoutConfig