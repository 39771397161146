import React, { useState, useEffect } from 'react';
import {
    PlusOutlined, SearchOutlined, SyncOutlined, DeleteOutlined, SettingOutlined, UnorderedListOutlined
} from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Divider, InputNumber, Popconfirm, message, Layout, Modal, Input, Typography, Space, Card } from 'antd';
import _ from 'lodash'
import qs from 'qs';
import { useRecoilValue } from 'recoil';

import MediaPrepayDetailTable from './MediaPrepayDetailTable'
import MediaPrepayOrderDetailTable from './MediaPrepayOrderDetailTable'
import MediaTable from './MediaTable'
import SearchForm from '../../common/SearchForm'
import { initFormData, tablePagination } from '../../common/tableCommon';
import { resultResponse } from '../../common/ResultResponse';
import { check } from '../../../utils/permission/rules';
import { userRoleState } from '../../atoms/Atoms'
import MediaPrepayHistoryTable from './MediaPrepayHistoryTable';

const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios');

const size = 'small'
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};


const MediaPrepayTable = () => {

    const [form] = Form.useForm();                  // 媒体搜索form
    const role = useRecoilValue(userRoleState)


    const searchDatas = [{
        name: 'name',
        label: '预付名称',
        placeholder: '请输入预付名称',
        type: 'input'
    }]
    const initialFormData = initFormData(searchDatas)

    const permission = {
        'module:media:prepay:charge': check(role, 'module:media:prepay:charge', null),
        'module:media:prepay:add': check(role, 'module:media:prepay:add', null),
        'module:media:prepay:del': check(role, 'module:media:prepay:del', null),
    }

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState({ create: false })
    const [medias, setMedias] = useState([])
    const [text, setText] = useState(0)

    const reloadTable = () => form.submit()
    const resetModal = () => setModal({ create: false })

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)

        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/media/prepay/getPrepayList',
                data: params,
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.recordsFiltered
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    const createPrepay = async (val) => {
        await axios({
            method: 'post',
            url: '/media/prepay/add',
            data: qs.stringify(val)
        }).then((res) => {
            resultResponse(res, '已成功添加')
            reloadTable()
            resetModal()
        })
    }

    const deletePrepay = async (id) => {
        await axios({
            method: 'delete',
            url: `/media/prepay/delete/${id}`
        }).then((res) => {
            resultResponse(res, '已成功删除')
            reloadTable()
            resetModal()
        })
    }

    const balanceRecharge = async (id, money) => {
        await axios({
            method: 'post',
            url: `/media/prepay/recharge`,
            data: qs.stringify({
                id: id,
                left_money: money
            })
        }).then((res) => {
            resultResponse(res, '已成功充值')
            reloadTable()
            resetModal()
        })
    }

    const bindMedias = async (ids, prepay_id) => {
        let params = new FormData()
        params.append('ids', ids)
        params.append('prepay_id', prepay_id)
        await axios({
            method: 'post',
            url: '/media/prepay/bind',
            data: params
        }).then((res) => {
            resultResponse(res, '已成功添加')
            setMedias([])
            reloadTable()
            resetModal()
        })
    }

    // 初始化列表
    useEffect(() => {
        fetchData()
        return source.cancel('Operation canceled by the user.');
    }, [])


    let columns = [
        {
            title: '名称',
            dataIndex: 'name'
        }, {
            title: '创建时间',
            dataIndex: 'create_time',
        }, {
            title: '剩余额度',
            dataIndex: 'left_money',
            render: (t, data) => {
                return <Space direction='vertical'>
                    <Text code style={{ marginBottom: '8px' }}>剩余额度: {data.left_money}</Text>
                    {
                        permission['module:media:prepay:charge'] &&
                        <Button size={size} type='primary' onClick={() => setModal({ ...modal, recharge: true, id: data.id })}>充值</Button>
                    }
                </Space>
            }
        }, {
            title: '总额度',
            dataIndex: 'money',
        }, {
            title: '操作',
            dataIndex: 'id',
            render: (t, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text code style={{ marginBottom: '8px' }}>媒体数量: {data.total}</Text>
                        <Button.Group>
                            {
                                permission['module:media:prepay:del'] &&
                                <Popconfirm
                                    title="是否确认删除"
                                    onConfirm={() => deletePrepay(data.id)}
                                    onCancel={() => console.log('取消')}
                                    okText="确认"
                                    cancelText="取消"
                                >
                                    <Button size={size}><DeleteOutlined />删除</Button>
                                </Popconfirm>
                            }
                            {
                                permission['module:media:prepay:add'] &&
                                <Button size={size} icon={<SettingOutlined />}
                                    onClick={() => setModal({ ...modal, select: true, id: data.id })}>关联媒体</Button>
                            }
                            <Button size={size} icon={<UnorderedListOutlined />}
                                onClick={() => setModal({ ...modal, view: true, id: data.id })}>查看媒体</Button>
                            <Button size={size} icon={<UnorderedListOutlined />}
                                onClick={() => setModal({ ...modal, orders: true, id: data.id })}>查看订单</Button>
                            <Button size={size} icon={<UnorderedListOutlined />}
                                onClick={() => setModal({ ...modal, history: true, id: data.id })}>操作记录</Button>
                        </Button.Group>
                    </div>
                )
            }
        },
    ]

    return <>
        <Space direction='vertical' size={16} className='full-width'>
            <Card>
                <Form
                    layout='vertical'
                    labelAlign="left"
                    name="media-form"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                    <Row>
                        <Col span={12}>
                            <Button type="primary" icon={<PlusOutlined />}
                                onClick={() => setModal({ ...modal, create: true })}>
                                添加预付
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button onClick={() => form.resetFields()}>
                                    <SyncOutlined /> 重置搜索
                                </Button>
                                <Button type="primary" htmlType="submit" >
                                    <SearchOutlined /> 搜索
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card>
                <Table size='small'
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                    summary={pageData => {
                        let totalCost = _.sumBy(data, 'left_money');
                        return <tr>
                            <td />
                            <th>汇总</th>
                            <td colSpan={4}>
                                <Space>
                                    <span>剩余: <Text type="danger">{totalCost}</Text></span>
                                </Space>
                            </td>
                        </tr>
                    }}
                />
            </Card>
        </Space>
        <Modal title="创建预付" open={modal.create} footer={null} onCancel={resetModal}>
            <Form
                name="add-prepay" {...formItemLayout}
                onFinish={(val) => createPrepay(val)}
            >
                <Form.Item label="名称" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="金额" name="money">
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                        确认
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        <Modal title="选择媒体" open={modal.select} footer={null} onCancel={resetModal} width={1500}>
            <MediaTable
                medias={medias}
                setMedias={setMedias}
                menu={false}
                simpleMode={true}
                url='/media/prepay/getMediaList'
            />
            <Button onClick={() => bindMedias(medias.map(e => e.id), modal.id)}>提交</Button>
        </Modal>
        <Modal title="查看媒体" open={modal.view} footer={null} onCancel={resetModal} width={1500}>
            <MediaPrepayDetailTable prepay_id={modal.id} />
        </Modal>
        <Modal title="查看订单" open={modal.orders} footer={null} onCancel={resetModal} width={1500}>
            <MediaPrepayOrderDetailTable prepay_id={modal.id} />
        </Modal>
        <Modal title="预付记录" open={modal.history} footer={null} onCancel={resetModal} width={1500}>
            <MediaPrepayHistoryTable prepay_id={modal.id} />
        </Modal>
        <Modal
            title="充值" open={modal.recharge}
            footer={null}
            onCancel={() => {
                resetModal()
                setText(0)
            }}
        >
            <Input type="text" value={text} onChange={e => {
                const number = parseInt(e.target.value || 0, 10);
                if (isNaN(number)) {
                    return;
                }
                if (number < 0) {
                    message.error("请输入大于0的数值")
                    return
                }
                return setText(number)
            }} />
            <Divider />
            <div style={{ textAlign: "right" }}>
                <Button type='primary' onClick={() => {
                    resetModal()
                    balanceRecharge(modal.id, text)
                }}>充值</Button>
            </div>
        </Modal>
    </>;
}

export default MediaPrepayTable