import React, { useState, useEffect } from 'react';
import { Form, Table, Button, Row, Col, Avatar, message, Typography, Divider, Input, Layout } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import _ from 'lodash'
import qs from 'qs';

import { initFormData, tablePagination } from '../../common/tableCommon';

const { Content } = Layout;
const { Text, Paragraph } = Typography;

const axios = require('axios');
/**
 * 修改用户所属的管理销售
 * @param {*} param0 
 */
const UserManagerTable = ({ uid, resetVisible, reloadTable, dispatch }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (dispatch === 'attache') {
            params.append("attache", 2)
        } else {
            params.append("attache", 1)
        }
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/user/getUserList',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
                setLoading(false)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [pagination.current, pagination.pageSize])


    // 编辑用户管理
    const editUserManager = async (id, uid) => {
        await axios({
            method: 'post',
            url: `/user/update`,
            data: qs.stringify({ manager_id: id, id: uid })
        }).then((res) => {
            if (res.data.code === '200') {
                message.success("已成功修改")
                resetVisible()
                reloadTable()
            } else {
                message.error(res.data.message)
            }
        })
    }

    const columns = [
        {
            title: '',
            dataIndex: 'avatar_img',
            width: 40,
            render: (t, data) => {
                let base_img = data.avatar_img ? data.avatar_img + "_xs" : `http://origin-static.oss-cn-beijing.aliyuncs.com/static/images/defaultPic.jpg_xs`;
                return <Avatar shape="square" icon={<UserOutlined />} src={base_img} />;
            }
        }, {
            title: '账号名',
            dataIndex: 'account',
            render: (t, data) => {
                return (
                    <>
                        <div className='flex-justify'>
                            <Text>{data.sell_group_name}</Text>
                        </div>
                        <div className='flex-justify'>
                            <Text strong>{t}</Text>
                        </div>
                    </>
                )
            }
        }, {
            title: '备注',
            render: (text, data) => {
                return <Paragraph>{data.remark}</Paragraph>
            }
        }, {
            title: '分配',
            dataIndex: 'id',
            width: 190,
            fixed: 'right',
            render: (text, data) => {
                return <Button type='link' onClick={() => editUserManager(data.id, uid)}>分配</Button>
            }
        }
    ]

    return <>
        <Content style={{ background: '#fff' }}>
            <div style={{ paddingBottom: 16 }}>
                <Form layout='vertical' name="basic" onFinish={val => fetchData(_.pickBy(val, _.identity))}>
                    <Form.Item label="用户名" name='account'>
                        <Input />
                    </Form.Item>
                    <Divider />
                    <Row>
                        <Col span={12}>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Table
                size="small"
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination, filters, sorter) => {
                    setPagination(pagination)
                }}
            />
        </Content>
    </>;
}


export default UserManagerTable
