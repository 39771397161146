import React, { useEffect, useState } from 'react';
import { List, Spin, Typography } from 'antd';
import _ from 'lodash'

const axios = require('axios');
const { Text } = Typography
const OrderCensor = ({ id }) => {

    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)

    const getCensorMsg = async () => {
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'get',
                url: `/order/censor/${id}`
            }).then(res => {
                if (res.data.code === 1) {
                    let msg = res.data.data
                    setMsg(JSON.parse(msg))
                }
            })
        }
        setLoading(false)
    }

    const getWords = (arrays) => {
        let wordsArray = _.reduce(arrays, (result, e) => {
            return _.concat(result, e.words)
        }, [])
        return _.join(_.uniq(wordsArray), ',')
    }

    useEffect(() => {
        getCensorMsg()
    }, [id])

    return (
        <div>
            {
                loading
                    ? <div style={{ display: 'grid', placeContent: 'center' }}>
                        <Spin />
                    </div>
                    : <List
                        size="large"
                        bordered={false}
                        dataSource={msg}
                        renderItem={item => <List.Item>
                            <div>
                                原因: <Text strong>{item.msg}</Text>
                            </div>
                            <div>
                                关键词: <Text strong type='danger'>{getWords(item.hits)}</Text>
                            </div>
                        </List.Item>}
                    />
            }

        </div>
    )
}

export default OrderCensor;