import React, { useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Layout, Tabs } from 'antd';
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import UserSalaryTable from './UserSalaryTable';
import SalaryTable from './salary/SalaryTable';
import UserPostTable from './salary/UserPostTable';
import SalaryLogTable from './salary/SalaryLogTable';
import { check } from '../../../utils/permission/rules';
import OperatorSalary from './salary/OperatorSalary';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;


const UserSalary = () => {
    const [tab, setTab] = useState('logs')
    const role = useRecoilValue(userRoleState)

    useEffect(() => {
        if (role === 'OPERATOR_LEADER') {
            setTab('operate')
        }
    }, [role]);

    const permission = {
        'module:user:salary:edit': check(role, 'module:user:salary:edit'),
        'module:user:salary:list': check(role, 'module:user:salary:list'),
        'module:operater:salary': check(role, 'module:operater:salary'),
    }
    let pageTitle = "薪资管理"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader
                title={pageTitle}
                footer={
                    <Tabs defaultActiveKey={tab} onChange={e => setTab(e)}
                        items={[
                            permission['module:user:salary:list'] && { key: 'detail', label: `销售薪资`, },
                            permission['module:user:salary:list'] && { key: 'logs', label: `薪资日志`, },
                            permission['module:user:salary:edit'] && { key: 'list', label: `员工列表`, },
                            permission['module:user:salary:edit'] && { key: 'posts', label: `职位列表`, },
                            permission['module:operater:salary'] && { key: 'operate', label: `运营提成`, },
                        ]}
                    />}
            />
            <Content style={{ margin: 24 }}>
                {
                    {
                        'detail': <UserSalaryTable />,
                        'list': <SalaryTable />,
                        'posts': <UserPostTable />,
                        'logs': <SalaryLogTable />,
                        'operate': <OperatorSalary />
                    }[tab]
                }
            </Content>
        </HelmetProvider>
    )
}

export default UserSalary
