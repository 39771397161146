import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../Apps/atoms/Atoms'
const PackageSearchConfig = () => {

    const role = useRecoilValue(userRoleState)

    const conmmonSearch = [
        {
            name: 'name',
            label: '媒体名称',
            placeholder: '请输入媒体名称',
            type: 'input'
        },
    ]


    const defaultSearch = _.concat(
        conmmonSearch,
    )

    const statusSearch = [
        {
            name: 'status',
            label: '媒体状态',
            type: 'radio',
            children: [
                ['全部', ''],
                ['正常', '1'],
                ['已停用', '2'],
            ],
            value: '1'
        },
    ]


    let result = []
    switch (role) {
        case "CUSTOMER":
        case "OPERATOR":
        case 'FRANCHISE':
        case "SALE":
        default:
            result = _.concat(
                defaultSearch,
                statusSearch
            )
            break;
    }

    return result
}

export default PackageSearchConfig