import React, { useState, useEffect } from 'react';
import { PlusOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Divider, Layout, Typography, Space, Modal, Input, DatePicker, Steps, InputNumber } from 'antd';
import _ from 'lodash'
import qs from 'qs';

import MediaTable from '../medias/MediaTable';
import { initFormData, tablePagination } from '../../common/tableCommon';
import SearchForm from '../../common/SearchForm'
import PackagesTableConfig from '../../../role/PackagesTableConfig';
import PackageSearchConfig from '../../../role/PackageSearchConfig'
import { resultResponse } from '../../common/ResultResponse';
import RoleModule from '../../../utils/permission/RoleModule';
import PageHeader from '../../layout/PageHeader';

const axios = require('axios');

const { Content } = Layout;
const { Text } = Typography;
const { Step } = Steps;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const PackagesTable = () => {

    const [form] = Form.useForm();                  // 媒体搜索form

    const searchDatas = PackageSearchConfig()
    const initialFormData = initFormData(searchDatas)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [visible, setVisible] = useState({ addMedia: false, action: 'ADD' })
    const [medias, setMedias] = useState([])

    const [current, setCurrent] = useState(0)

    const reloadTable = () => form.submit()
    const resetModal = () => setVisible({ addMedia: false, action: 'ADD' })

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)

        val && _.toPairs(val).filter(e => e[1].length > 0).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        setLoading(true)
        await axios({
            method: 'post',
            url: '/packages/getPackageList',
            data: params,
        }, {
            cancelToken: source.token
        }).then((res) => {
            setData(res.data.data)
            setLoading(false)
            let total = res.data.total
            if (total < ((pagination.current - 1) * pagination.pageSize)) {
                setPagination({ ...pagination, current: 1, total })
            } else {
                setPagination({ ...pagination, total })
            }
        })
    }

    // 初始化列表
    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])

    const createPackage = async (val) => {
        await axios({
            method: 'post',
            url: '/packages/addPackage',
            data: qs.stringify(val),
        }).then((res) => {
            resultResponse(res, "已成功")
            reloadTable()
            resetModal()
        })
    }

    const bindPackMedia = async (data) => {
        await axios({
            method: 'post',
            url: "/packages/addMedias",
            data: data
        }).then((res) => {
            resultResponse(res, "已成功绑定")
            reloadTable()
            resetModal()
        })
    }

    const bindMedia = id => setVisible({ ...visible, select: true, id: id })
    const handleMedia = { reloadTable, bindMedia }


    let columns = PackagesTableConfig({ handleMedia })
    const prev = () => setCurrent(current - 1)
    const next = () => setCurrent(current + 1)
    const steps = [
        {
            title: '选择媒体',
            content: <>
                <MediaTable
                    medias={medias}
                    setMedias={setMedias}
                    rowOnSelect={(record, selected) => {
                        let tempMedia = {
                            id: record.id,
                            name: record.name,
                        }
                        let newMedias = selected ? _.concat(medias, tempMedia) : _.differenceBy(medias, [tempMedia], 'id');
                        setMedias(_.uniqBy(newMedias, 'id'))
                    }}
                    simpleMode={true}
                    menu={false}
                />
                <Button onClick={next}>继续</Button>
            </>,
        },
        {
            title: '分配价格',
            content: <>
                <Form
                    name="media-prices" {...formItemLayout}
                    onFinish={val => {
                        let data = _.reduce(val, (result, v, k) => {
                            return _.concat(result, {
                                package_id: visible.id,
                                media_id: k,
                                price: v
                            })
                        }, [])
                        bindPackMedia(data)
                    }}
                >
                    <Row gutter={[24, 24]}>
                        {
                            medias.map(e => {
                                return <Col span={8} key={e.id}>
                                    <Form.Item label={e.name} name={e.id} >
                                        <InputNumber />
                                    </Form.Item>
                                </Col>
                            })
                        }
                    </Row>
                    <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Form.Item>
                </Form>
                <Button onClick={prev}>返回</Button>
            </>,
        }
    ];


    return <>
        <Content className='table-search'>
            <PageHeader
                style={{ padding: 0 }}
                title={<Text level={4}>优惠套餐搜索</Text>}
                extra={[
                    <RoleModule action='module:media:package:add' key='button-group'>
                        <Button.Group>
                            <Button type="primary" icon={<PlusOutlined />} key='create-medias'
                                onClick={() => setVisible({ ...visible, addMedia: true, action: 'ADD' })}>
                                添加套餐
                            </Button>
                        </Button.Group>
                    </RoleModule>
                ]}
            >
                <Form
                    layout={'vertical'}
                    labelAlign="left"
                    name="packages-form"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                    <Divider style={{ margin: '8px 0' }} />
                    <Row>
                        <Col span={12} />
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button onClick={() => form.resetFields()}>
                                    <SyncOutlined /> 重置搜索
                                </Button>
                                <Button type="primary" htmlType="submit" >
                                    <SearchOutlined /> 搜索
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </PageHeader>
        </Content>
        <Content className='table-main'>
            <Table size='small'
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>

        <Modal title="选择媒体" open={visible.select} footer={null} onCancel={resetModal} width={1500}>
            <Steps size="small" current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>


        </Modal>
        <Modal title="创建优惠券" open={visible.addMedia} footer={null} onCancel={resetModal}>
            <Form
                name="add-coupon" {...formItemLayout}
                onFinish={val => {
                    createPackage({ ...val, expire_time: val['expire_time'].format('YYYY-MM-DD') })
                }}
            >
                <Form.Item label="名称" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="失效时间" name="expire_time">
                    <DatePicker />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                        确认
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    </>;
}

export default PackagesTable