import React from 'react'
import { Typography, Button, Space, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
import { check } from '../../utils/permission/rules';

const { Text } = Typography;


export const UserSalaryTableConfig = ({ handleUsers }) => {
    const role = useRecoilValue(userRoleState)
    const { modifyUserSalary } = handleUsers;

    const permission = {
        'module:user:salary': check(role, 'module:user:salary', null)
    }

    const defaultConfig = [
        {
            title: '账号名',
            dataIndex: 'account',
            render: (val, data) => {
                const menuItems = [
                    {
                        label: <Button type='link' onClick={() => modifyUserSalary(data.user_id)}>修改用户薪资</Button>,
                        key: 'modify-salary',
                    },
                ]
                return <Space>
                    <Text strong style={{ fontSize: 14 }}>{data.account}</Text>
                    {
                        permission['module:user:salary'] &&
                        <Dropdown menu={<Menu items={menuItems} />}>
                            <a className="ant-dropdown-link" href="true" onClick={e => e.preventDefault()}>
                                <DownOutlined />
                            </a>
                        </Dropdown>
                    }
                </Space >
            }
        }, {
            title: '底薪',
            dataIndex: 'base_salary',
        },
        //  {
        //     title: '绩效标准',
        //     dataIndex: 'performance_amount',
        //     render: (t, data) => {
        //         let count = _.split(data.count, ',')
        //         let paid_nums = _.find(count, e => _.split(e, '_')[1] === 'paid') ?? 0
        //         let nums = parseInt(paid_nums)
        //         let performance_salary = 0
        //         if (nums >= (data?.performance_amount ?? 0)) {
        //             performance_salary = data.performance_salary
        //         } else {
        //             performance_salary = data.performance_salary * nums / data.performance_amount
        //         }

        //         return <Space direction='vertical' size='small'>
        //             <Text strong>已发布: {nums} / {data.performance_amount} 条</Text>
        //             <Text strong style={{ fontSize: 14 }}>应得绩效: {performance_salary.toFixed(2)} / {data.performance_salary} 元</Text>
        //         </Space>
        //     }
        // }, 
        // {
        //     title: '奖金',
        //     dataIndex: 'bonus_salary',
        // },
        {
            title: '已付款订单',
            dataIndex: 'profit',
            render: (t, data) => {
                let medias = _.split(data.medias, ',')
                let media_paid = _.reduce(medias, (result, e) => {
                    let arr = _.split(e, '_')
                    return parseFloat(arr[1] === 'paid' ? arr[0] : 0) + result
                }, 0)
                let orders = _.split(data.orders, ',')
                let order_paid = _.reduce(orders, (result, e) => {
                    let arr = _.split(e, '_')
                    return parseFloat(arr[1] === 'paid' ? arr[0] : 0) + result
                }, 0)
                return <Space direction='vertical' size='small'>
                    <Text strong>客户已付款收益: {parseFloat(order_paid).toFixed(2)} 元</Text>
                    <Text strong>已付款自营媒体收益: {parseFloat(media_paid).toFixed(2)} 元</Text>
                    <Text strong style={{ fontSize: 14 }}>应得提成: {(order_paid + media_paid).toFixed(2)} 条</Text>
                </Space>
            }
        }, {
            title: '未付款订单',
            dataIndex: 'count',
            render: (t, data) => {
                let medias = _.split(data.medias, ',')
                let media_unpaid = _.reduce(medias, (result, e) => {
                    let arr = _.split(e, '_')
                    return parseFloat(arr[1] === 'unpaid' ? arr[0] : 0) + result
                }, 0)
                let orders = _.split(data.orders, ',')
                let order_unpaid = _.reduce(orders, (result, e) => {
                    let arr = _.split(e, '_')
                    return parseFloat(arr[1] === 'unpaid' ? arr[0] : 0) + result
                }, 0)
                return <Space direction='vertical' size='small'>
                    <Text strong>客户未付款收益: {parseFloat(order_unpaid).toFixed(2)} 元</Text>
                    <Text strong>未付款自营媒体收益: {parseFloat(media_unpaid).toFixed(2)} 元</Text>
                    <Text strong style={{ fontSize: 14 }}>应得提成: {(order_unpaid + media_unpaid).toFixed(2)} 条</Text>
                </Space>
            }
        },
        // {
        //     title: '提成工资',
        //     dataIndex: 'count',
        //     render: (t, data) => {
        //         let profit = _.split(data.profit, ',')
        //         let count = _.split(data.count, ',')
        //         let paid_money = _.find(profit, e => _.split(e, '_')[1] === 'paid') ?? 0
        //         let paid_nums = _.find(count, e => _.split(e, '_')[1] === 'paid') ?? 0
        //         let earn = parseFloat(paid_money) * 0.06 + parseInt(paid_nums) * 0.6

        //         let nums = parseInt(paid_nums)
        //         let performance_salary = 0
        //         if (nums >= (data?.performance_amount ?? 0)) {
        //             performance_salary = data.performance_salary
        //         } else {
        //             performance_salary = data.performance_salary * nums / data.performance_amount
        //         }
        //         let total = (parseInt(performance_salary * 100)
        //             + parseInt(earn * 100)
        //             + parseInt(data.base_salary * 100)
        //             + parseInt(data.bonus_salary * 100)) / 100
        //         if ((data.total_salary - total).toFixed(2) !== '0.00') {
        //             return <Text style={{ fontSize: 16 }} type='danger' strong>计算有误 ({`${total.toFixed(2)} / ${data.total_salary}`})</Text>
        //         } else {
        //             return <Text style={{ fontSize: 16 }} strong>总工资: {total.toFixed(2)}</Text>
        //         }
        //     }
        // },
        {
            title: '提成工资',
            dataIndex: 'count',
            render: (t, data) => {
                let medias = _.split(data.medias, ',')
                let media_paid = _.reduce(medias, (result, e) => {
                    let arr = _.split(e, '_')
                    return parseFloat(arr[1] === 'paid' ? arr[0] : 0) + result
                }, 0)
                let orders = _.split(data.orders, ',')
                let order_paid = _.reduce(orders, (result, e) => {
                    let arr = _.split(e, '_')
                    return parseFloat(arr[1] === 'paid' ? arr[0] : 0) + result
                }, 0)
                return <Text style={{ fontSize: 16 }} strong>总工资: {(data.base_salary + media_paid + order_paid).toFixed(2)}</Text>
            }
        },
    ]


    let result = []

    switch (role) {
        case "SALE":
        case "SALE_LEADER":
        case "SALE_ATTACHE":
        case 'FRANCHISE':
        case 'SUPPORT_STAFF':
        case 'FINANCE':
            result = _.concat(
                defaultConfig
            )
            break;
        default:
            break;
    }
    return result
}
