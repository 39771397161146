import React from 'react'
import { Typography, Layout, Breadcrumb } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CollectionsTable from './CollectionsTable'
import { Link } from 'react-router-dom'
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout
const { Title } = Typography;


const Collections = () => {

    let pageTitle = "媒体收藏夹"

    return (
        <HelmetProvider>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <PageHeader style={{ backgroundColor: '#fff' }}>
                <Title level={4}>{pageTitle}</Title>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
                </Breadcrumb>
            </PageHeader>
            <Content>
                <div style={{ margin: 24 }}>
                    <Content
                        style={{
                            background: '#fff',
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <CollectionsTable />
                    </Content>
                </div>
            </Content>
        </HelmetProvider>
    )
}

export default Collections
