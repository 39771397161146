import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Modal, Row, Table, Space, Typography, Card } from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil'

import { userRoleState } from '../../atoms/Atoms'
import SearchForm from '../../common/SearchForm';
import { initFormData, tablePagination } from '../../common/tableCommon';
import { UserNewSalaryTableConfig } from '../../../role/user/UserNewSalaryTableConfig';
import UserSalaryForm from './UserSalaryForm';

const { Text } = Typography;

const layout = { layout: 'vertical' };
const axios = require('axios');
const initialModal = {
    type: '', addUser: false, recharge: false, id: '',
    editUser: false, group: false, sale_group_id: '',
    changeManage: false, consume: false, loan: false
}
const UserNewSalaryTable = ({ searchFormDisplay = true, url, dispatch }) => {
    const role = useRecoilValue(userRoleState)

    const initialFormData = initFormData(searchDatas)

    const [form] = Form.useForm()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)
    const [modal, setModal] = useState(initialModal)

    const reloadTable = () => form.submit()
    const resetModal = () => setModal(initialModal)

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let names = searchDatas.filter(e => e.type === 'datepicker').map(e => e.name)
        names.forEach(name => {
            _.forEach(val, (v, k) => {
                if (v && k === name) {
                    val = { ...val, [k]: dayjs(v.$d).format('YYYY-MM-DD') }
                }
            })
        })
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val).forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (loading === false) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/user/salary/v2/details',
                data: params
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])


    const modifyUserSalary = (id) => setModal({ ...modal, salary: true, id: id })
    const handleUsers = { modifyUserSalary }
    const columns = UserNewSalaryTableConfig({ visible: modal, setVisible: setModal, handleUsers, dispatch })

    return <>
        <Space direction='vertical' size={16} style={{ width: '100%' }}>
            <Card>
                {searchFormDisplay && <div>
                    <Form
                        {...layout}
                        name="basic"
                        form={form}
                        initialValues={initialFormData}
                        onFinish={val => fetchData(_.pickBy(val, _.identity))}
                    >
                        <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />
                        <Divider style={{ margin: '8px 0' }} />
                        <Row>
                            <Col span={12}>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Button type="primary" htmlType="submit">
                                    搜索
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>}
            </Card>
            <Card>
                <Table
                    size="small"
                    columns={columns}
                    rowKey={record => record.user_id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={(pagination) => {
                        setPagination(pagination)
                        form.submit()
                    }}
                    summary={pageData => {
                        let totalCost = _.sumBy(data, 'total_salary');
                        switch (role) {
                            case 'SALE':
                            case 'FRANCHISE':
                            case 'SALE_LEADER':
                            case 'SALE_ATTACHE':
                                return <tr>
                                    <th>汇总</th>
                                    <td colSpan={5}>
                                    </td>
                                    <td>
                                        <Space>
                                            <span>总工资: <Text type="danger">{totalCost}</Text></span>
                                        </Space>
                                    </td>
                                </tr>
                            default:
                                return null
                        }
                    }}
                />
            </Card>
        </Space>
        <Modal
            title="修改销售薪资" open={modal.salary} width={1200}
            onOk={() => resetModal()}
            onCancel={() => resetModal()}
            destroyOnClose={true}
            maskClosable={false}
        >
            <UserSalaryForm uid={modal.id} reloadTable={reloadTable} />
        </Modal>
    </>;
}


export default UserNewSalaryTable

const searchDatas = [
    {
        name: 'sale_name',
        label: '销售名称',
        placeholder: '请输入销售名称',
        type: 'input'
    },
    {
        name: 'customer_name',
        label: '客户名称',
        placeholder: '请输入客户名称',
        type: 'input'
    },
    {
        name: 'start_time',
        label: '开始时间',
        placeholder: '请输入开始时间',
        type: 'datepicker',
        value: dayjs().subtract(6, 'months')
    },
    {
        name: 'end_time',
        label: '结束时间',
        placeholder: '请输入结束时间',
        type: 'datepicker',
        value: null
    },
]