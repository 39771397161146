import React from 'react';
import { Row, Col, Input, DatePicker, Select, Radio, Form, Space } from 'antd';

const { Option } = Select;
const SearchForm = ({ searchDatas, reloadTable }) => {
    const colLayout = { xs: 24, sm: 12, md: 12, lg: 8, xl: 8, xxl: 6 }

    const result = [];
    const status_result = [];

    for (const data of searchDatas) {
        switch (data.type) {
            case 'input':
                result.push(
                    <Col {...colLayout} key={data.name} >
                        <Form.Item label={data.label} name={data.name}                            >
                            <Input placeholder={data.placeholder} />
                        </Form.Item>
                    </Col>
                )
                break;
            case 'range':
                let datas = data.datas
                result.push(
                    <Col {...colLayout} key={data.name} >
                        <Form.Item label={data.label}>
                            <Input.Group compact>
                                <Form.Item name={datas[0].name} noStyle>
                                    <Input style={{ width: 'calc(50% - 15px)' }} placeholder={datas[0].placeholder} />
                                </Form.Item>
                                <Input className='range-connector' placeholder="-" disabled />
                                <Form.Item name={datas[1].name} noStyle>
                                    <Input style={{ width: 'calc(50% - 15px)', borderLeft: 0 }} placeholder={datas[1].placeholder} />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                )
                break;
            case 'datepicker':
                result.push(
                    <Col {...colLayout} key={data.name} className='time-filter' >
                        <Form.Item label={data.label} name={data.name}>
                            <DatePicker style={{ width: '100%' }} placeholder={data.placeholder} picker={data.picker ?? 'date'} />
                        </Form.Item>
                    </Col>
                )
                break;
            case 'select':
                result.push(
                    <Col {...colLayout} key={data.name} >
                        <Form.Item label={data.label} name={data.name}>
                            {
                                data.mode
                                    ? <Select mode={data.mode} optionFilterProp="name">
                                        {data.children?.map(e => <Option key={e.id} name={e.name} value={e.id}>{e.name}</Option>)}
                                    </Select>
                                    : <Select showSearch>
                                        {data.children?.map(e => {
                                            if ((typeof e) === 'string') {
                                                return <Option key={e} value={e}>{e || "全部"}</Option>
                                            } else {
                                                return <Option key={e[0]} value={e[1]}>{e[0] || "全部"}</Option>
                                            }
                                        })}
                                    </Select>
                            }
                        </Form.Item>
                    </Col>
                )
                break;
            case 'radio':
                status_result.push(
                    <div key={data.name}>
                        <Form.Item label={data.label} name={data.name} >
                            <Radio.Group buttonStyle="solid" onChange={e => reloadTable()}>
                                {data.children.map(e => <Radio.Button key={e} value={e[1]}>{e[0]}</Radio.Button>)}
                            </Radio.Group>
                        </Form.Item>
                    </div>
                )
                break;
            default:
                break;
        }
    }

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <Row gutter={16}>
                {result}
            </Row>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Space>
                    {status_result}
                </Space>
            </div>
        </Space>
    )
}

export default SearchForm