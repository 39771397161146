import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const MediaIndexSearchConfig = ({ action }) => {
    const role = useRecoilValue(userRoleState)


    const defaultSearch = [
        {
            name: 'name',
            label: '媒体名称',
            placeholder: '请输入媒体名称',
            type: 'input'
        },
        {
            name: 'profession',
            label: '频道类型',
            placeholder: '请输入频道类型',
            type: 'select',
            children: [
                '', '新闻资讯', '财经金融', 'IT科技数码',
                '生活消费', '酒店住宿', '女性时尚', '房产家居',
                '汽车配饰', '游戏动漫', '影视娱乐',
                '文化艺术', '健康医疗', '鞋帽服饰', '亲子母婴',
                '教育培训', '贸易能源', '食品餐饮', '五金建材',
                '企业', '体育运动', '区块链', '环保公益', '旅游交通',
                '人资管理', '婚嫁摄影',
            ]
        },
        {
            name: 'area',
            label: '媒体地区',
            placeholder: '请输入地区',
            type: 'select',
            children: [
                '', '全国', '北京', '上海',
                '广东', '浙江', '湖北', '湖南',
                '江苏', '陕西', '天津', '山西',
                '重庆', '河北', '河南', '四川',
                '安徽', '福建', '广西', '山东', '宁夏',
                '辽宁', '吉林', '海南', '内蒙古', '西藏',
                '黑龙江', '新疆', '江西', '贵州', '云南',
                '甘肃', '青海', '台湾', '其它',
            ]
        },
        {
            name: 'category',
            label: '门户类型',
            placeholder: '请输入媒体名称',
            type: 'select',
            children: [
                '',
                '中国五大门户',
                '中央新闻门户',
                '全国商业门户',
                '地方重点门户',
                '地方商业门户',
                '海外媒体'
            ]
        },
        {
            name: 'location',
            label: '资源类型',
            placeholder: '请输入媒体名称',
            type: 'select',
            children: [
                '',
                '文字链',
                '焦点图',
                '客户端推荐'
            ]
        },
        {
            name: 'price',
            label: '价格范围',
            type: 'range',
            datas: [
                {
                    name: 'min_price',
                    label: '最低价格',
                    placeholder: '最低价格',
                    type: 'input'
                },
                {
                    name: 'max_price',
                    label: '最高价格',
                    placeholder: '最高价格',
                    type: 'input'
                },
            ]
        },
    ]
    const operatorSearch = [
        // {
        //     name: 'include_type',
        //     label: '媒体类型',
        //     placeholder: '请输入媒体名称',
        //     type: 'select',
        //     children: [
        //         '', 'A类', 'B类', 'C类', 'D类', 'E类',
        //     ]
        // },
        {
            name: 'creator_name',
            label: '创建者名称',
            placeholder: '请输入创建者名称',
            type: 'input'
        },
        {
            name: 'editor_name',
            label: '所属编辑',
            placeholder: '请输入编辑名称',
            type: 'input'
        },
    ]

    const statusSearch = [
        {
            name: 'status',
            label: '媒体状态',
            type: 'radio',
            children: [
                ['全部', ''],
                ['正常', '1'],
                ['暂停', '2'],
            ],
            value: '1'
        },
    ]

    const manageSearch = [
        {
            name: 'checked',
            label: '审核状态',
            type: 'radio',
            children: [
                ['全部', ''],
                ['已通过', '1'],
                ['未通过', '2'],
            ]
        },
    ]

    let result = []
    switch (role) {
        case "CUSTOMER":
            if (action === 'EDIT') {
                result = _.concat(
                    defaultSearch,
                    statusSearch,
                    manageSearch
                )
            } else {
                result = _.concat(
                    defaultSearch,
                    statusSearch
                )
            }
            break;
        case "OPERATOR":
        case "OPERATOR_LEADER":
        case "OPERATOR_ADMIN":
            result = _.concat(
                defaultSearch,
                operatorSearch,
                statusSearch,
                manageSearch,
            )
            break;
        case "SALE":
        case 'FRANCHISE':
            result = _.concat(
                defaultSearch,
                statusSearch,
            )
            break;
        default:
            result = _.concat(
                defaultSearch,
                statusSearch
            )
            break;
    }

    return result
}

export default MediaIndexSearchConfig