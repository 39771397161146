import React from "react"
import Events from '../events/Events'
import EventsCenter from '../events/EventsCenter'
import Collections from '../events/Collections'

const EventRoutesConfig = [
    {
        path: '/dashboard/events',
        component: <Events />
    },
    {
        path: '/dashboard/events/center',
        component: <EventsCenter />
    },
    {
        path: '/dashboard/media/collections',
        component: <Collections />
    },
]

export default EventRoutesConfig