import React, { useState, useEffect } from 'react';
import {
    SearchOutlined, SyncOutlined
} from '@ant-design/icons';
import { Form, Table, Row, Col, Button, Divider, Layout, Typography, Space, Switch } from 'antd';
import _ from 'lodash'
import { useNavigate } from "react-router-dom";

import SearchForm from '../../common/SearchForm'
import MediaSearchConfig from '../../../role/media/MediaSearchConfig'
import { initFormData, tablePagination } from '../../common/tableCommon';
import { SearchFormHori, SearchFormInput } from '../../common/SearchFormTile';
import { resultResponse } from '../../common/ResultResponse';
import PageHeader from '../../layout/PageHeader';

const { Content } = Layout;
const { Text, Paragraph } = Typography;
const axios = require('axios');

const MediaBlacklistTable = ({ action }) => {
    let navigate = useNavigate();
    const [form] = Form.useForm();                  // 媒体搜索form

    const searchDatas = MediaSearchConfig({ action })
    const initialFormData = _.reduce(searchDatas, (result, data) => {
        return _.assign(result, { [data.name]: data.value ?? '' })
    }, {})

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(tablePagination)

    // 编辑器样式
    const localStorageEditorStyleName = "____SearchStyle____";
    const [searchStyle, setSearchStyle] = useState(localStorage.getItem(localStorageEditorStyleName) ?? 'vertical')

    const reloadTable = () => form.submit()

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async (val = _.pickBy(initialFormData, _.identity)) => {
        let params = new FormData()
        params.append("start", (pagination.current - 1) * pagination.pageSize)
        params.append("length", pagination.pageSize)
        if (val) {
            _.toPairs(val)
                .filter(e => e[1].length > 0)
                .forEach(e => params.append(`${e[0]}`, e[1] ?? ''))
        }
        if (!loading) {
            setLoading(true)
            await axios({
                method: 'post',
                url: '/media/blacklist',
                data: params,
            }, {
                cancelToken: source.token
            }).then((res) => {
                setData(res.data.data)
                setLoading(false)
                let total = res.data.total
                if (total < ((pagination.current - 1) * pagination.pageSize)) {
                    setPagination({ ...pagination, current: 1, total })
                } else {
                    setPagination({ ...pagination, total })
                }
            })
        }
    }

    // 初始化列表
    useEffect(() => {
        fetchData()
        return function () {
            source.cancel('Operation canceled by the user.');
        }
    }, [])


    const removeBlackList = async (id) => {
        await axios({
            method: 'post',
            url: `/media/blacklist/remove/${id}`,
        }).then((res) => {
            resultResponse(res, "已恢复")
            reloadTable()
        })
    }

    const columns = [
        {
            title: '媒体名称',
            dataIndex: 'name',
            checked: 1,
            render: (text, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text >{text}</Text>
                        <Space>
                            <a href={data.sample_url} target='_blank' rel='noopener noreferrer'>查看案例</a>
                        </Space>
                    </div>
                )
            }
        }, {
            title: '备注',
            dataIndex: 'comment',
            checked: 1,
            render: (text) => (
                <Paragraph ellipsis={{ rows: 3, expandable: true }} style={{ margin: 0 }}>
                    {text}
                </Paragraph>
            )
        }, {
            title: '操作',
            dataIndex: 'id',
            fixed: 'right',
            width: 100,
            checked: 1,
            render: (text, data) => {
                return <Button type="link" size='small' onClick={() => removeBlackList(data.id)} >取消拉黑</Button>;
            }
        }
    ]
    return <>
        <Content className='table-search'>
            <PageHeader
                style={{ padding: 0 }}
                title={<Text level={4}>媒体搜索</Text>}
                subTitle={
                    <Switch checked={searchStyle === 'vertical'}
                        checkedChildren="竖版" unCheckedChildren="横板"
                        onChange={e => {
                            if (e) {
                                setSearchStyle("vertical")
                                localStorage.setItem(localStorageEditorStyleName, "vertical")
                            } else {
                                setSearchStyle("horizontal")
                                localStorage.setItem(localStorageEditorStyleName, "horizontal")
                            }
                        }}></Switch>
                }
                extra={[
                    <Button onClick={e => navigate("/dashboard/media-center")}>返回媒体列表</Button>
                ]}
            >
                <Form
                    layout={searchStyle === 'horizontal' ? 'vertical' : 'horizontal'}
                    labelAlign="left"
                    name="media-form"
                    form={form}
                    initialValues={initialFormData}
                    onFinish={val => fetchData(_.pickBy(val, _.identity))}
                >
                    {searchStyle === 'horizontal' && <SearchForm searchDatas={searchDatas} reloadTable={reloadTable} />}
                    {searchStyle === 'vertical' && <SearchFormHori searchDatas={searchDatas} reloadTable={reloadTable} />}
                    <Divider style={{ margin: '8px 0' }} />
                    <Row>
                        <Col span={12}>
                            {searchStyle === 'vertical' && <SearchFormInput searchDatas={searchDatas} reloadTable={reloadTable} />}
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button onClick={() => form.resetFields()}>
                                    <SyncOutlined /> 重置搜索
                                </Button>
                                <Button type="primary" htmlType="submit" >
                                    <SearchOutlined /> 搜索
                            </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </PageHeader>
        </Content>
        <Content className='table-main'>
            <Table
                size='small'
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => {
                    setPagination(pagination)
                    form.submit()
                }}
            />
        </Content>
    </>;
}

export default MediaBlacklistTable