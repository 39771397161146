import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { getRouter } from '../../../utils/permission/rules';
import { userRoleState, platformState } from '../../atoms/Atoms'
import { Platform } from '../../common/CONSTANTS';
const MediaLayoutConfig = () => {
    const role = useRecoilValue(userRoleState)
    const platform = useRecoilValue(platformState)

    const router = [
        {
            key: 'page:media:list',
            title: '媒体中心',
            url: '/dashboard/media-center',
            platform: Platform.media.id
        }, {
            key: 'page:media:prepay',
            title: '预付管理',
            url: '/dashboard/medias/prepay',
            platform: Platform.media.id
        }, {
            key: 'page:media:collection',
            title: '资源收藏夹',
            url: '/dashboard/media/collections',
            platform: Platform.media.id
        }, {
            key: 'page:media:price',
            title: '媒体价格',
            url: '/dashboard/medias/price',
            platform: Platform.media.id
        }, {
            key: 'page:notification:list',
            title: '通知中心',
            url: '/dashboard/notifications',
            platform: Platform.media.id
        },
        // {
        //     key: 'page:event:manage',
        //     title: '活动管理中心',
        //     url: '/dashboard/events',
        //     platform: Platform.media.id
        // }, 
        // {
        //     key: 'page:media:package',
        //     title: '媒体优惠套餐',
        //     url: '/dashboard/packages',
        //     platform: Platform.media.id
        // },
        {
            key: 'page:weibo:list',
            title: '微博中心',
            url: '/dashboard/weibo',
            platform: Platform.weibo.id
        }, {
            key: 'page:wechat:list',
            title: '微信中心',
            url: '/dashboard/wechat',
            platform: Platform.wechat.id
        }, {
            key: 'page:xiaohongshu:list',
            title: '小红书中心',
            url: '/dashboard/xiaohongshu',
            platform: Platform.xiaohongshu.id
        }, {
            key: 'page:douyin:list',
            title: '抖音中心',
            url: '/dashboard/douyin',
            platform: Platform.douyin.id
        }
    ]

    return getRouter({ role, router, data: { platform } })
}

export default MediaLayoutConfig