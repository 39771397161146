import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'
const MediaIndexDatasConfig = () => {
    const role = useRecoilValue(userRoleState)

    const mediaBasicDatas = [
        {
            name: 'name',
            label: '媒体名称',
            required: true,
            placeholder: '请输入媒体名称',
            type: 'input'
        }, {
            name: 'sample_url',
            label: '案例地址',
            required: true,
            placeholder: '请输入案例地址',
            type: 'input'
        }, {
            name: 'url',
            label: '案例图片',
            required: true,
            placeholder: '请上传案例图片',
            type: 'upload',
            max_number: 1,
            action: '/mediaIndex/uploadImg',
        },
    ]

    const mediaCustomerPriceDatas = [
        {
            name: 'cost',
            label: '媒体价格',
            placeholder: '请输入媒体价格',
            type: 'inputnumber'
        }, {
            name: 'sale_price',
            label: '相对成本',
            placeholder: '请输入相对成本',
            hidden: true
        }, {
            name: 'agent_price',
            label: '代理端价格',
            placeholder: '请输入代理端价格',
            hidden: true
        },
    ]

    const mediaPriceDatas = [
        {
            name: 'cost',
            label: '媒体价格',
            placeholder: '请输入媒体价格',
            type: 'inputnumber'
        },
        {
            name: 'sale_price',
            label: '相对成本',
            placeholder: '请输入相对成本',
            type: 'inputnumber'
        },
        {
            name: 'agent_price',
            label: '代理端价格',
            placeholder: '请输入代理端价格',
            type: 'inputnumber'
        },
    ]

    const mediaPropsDatas = [
        {
            name: 'profession',
            label: '媒体频道',
            placeholder: '请输入媒体名称',
            type: 'select',
            children: [
                '新闻资讯',
                '财经金融',
                'IT科技数码',
                '生活消费',
                '酒店住宿',
                '女性时尚',
                '房产家居',
                '汽车配饰',
                '游戏动漫',
                '影视娱乐',
                '文化艺术',
                '健康医疗',
                '鞋帽服饰',
                '亲子母婴',
                '教育培训',
                '贸易能源',
                '食品餐饮',
                '五金建材',
                '企业',
                '体育运动',
                '区块链',
                '环保公益',
                '旅游交通',
                '人资管理',
                '婚嫁摄影',
                '农林畜牧',
                '通信电子',
                '安全防护',
                '水利能源',
                '工业制造',
                '服装纺织'
            ]
        },
        {
            name: 'category',
            label: '门户类型',
            placeholder: '请输入媒体名称',
            type: 'select',
            children: ['中国五大门户', '中央新闻门户', '全国商业门户', '地方重点门户', '地方商业门户', '海外媒体']
        },
        {
            name: 'area',
            label: '媒体地区',
            placeholder: '请输入媒体名称',
            type: 'select',
            children: [
                '全国', '北京', '上海', '广东', '浙江', '湖北',
                '湖南', '江苏', '陕西', '天津', '山西', '重庆',
                '河北', '河南', '四川', '安徽', '福建', '广西', '宁夏',
                '山东', '辽宁', '吉林', '海南', '内蒙古', '黑龙江', '新疆', '西藏',
                '江西', '贵州', '云南', '甘肃', '青海', '台湾', '其它',
            ]
        },
        {
            name: 'location',
            label: '资源类型',
            placeholder: '请选择资源类型',
            type: 'select',
            children: [
                '全国', '焦点图', '文字链', '客户端推荐',
            ]
        },
    ]

    const editorDatas = [
        {
            name: 'editor_name',
            label: '编辑姓名',
            placeholder: '请输入媒体名称',
            type: 'input'
        },
        {
            name: 'editor_tel',
            label: '联系方式',
            placeholder: '请输入媒体名称',
            type: 'input'
        },
    ]

    const commentDatas = [
        {
            name: 'comment',
            label: '备注',
            placeholder: '请输入备注',
            type: 'area'
        },
    ]


    let result = []

    switch (role) {
        case "CUSTOMER":
            result = [
                mediaBasicDatas,
                mediaCustomerPriceDatas,
                mediaPropsDatas,
                editorDatas,
                commentDatas
            ]
            break;
        case "OPERATOR":
            result = [
                mediaBasicDatas,
                mediaPriceDatas,
                mediaPropsDatas,
                editorDatas,
                commentDatas
            ]
            break;
        case "SALE":

            break;
        default:
            break;
    }

    return result
}

export default MediaIndexDatasConfig