import _ from 'lodash'
import { useRecoilValue } from 'recoil';

import { getRouter } from '../../../utils/permission/rules';
import { userRoleState, platformState  } from '../../atoms/Atoms'
import { Platform } from '../../common/CONSTANTS';
const FinanceLayoutConfig = () => {
    const role = useRecoilValue(userRoleState)
    const platform = useRecoilValue(platformState)

    const router = [
        {
            key: 'page:invoice:manage',
            title: '发票申请管理',
            url: '/dashboard/invoices/list',
            platform: Platform.media.id
        }, {
            key: 'page:withdraw:manage',
            title: '提现管理',
            url: '/dashboard/withdraw/all/list',
            platform: Platform.media.id
        }, {
            key: 'page:order:workorder:manage',
            title: '工单管理',
            url: '/dashboard/finance/sale-order/center',
            platform: Platform.media.id
        }, {
            key: 'page:invoice:enterprise:manage',
            title: '公司发票管理',
            url: '/dashboard/enterprise/invoice',
            platform: Platform.media.id
        }, {
            key: 'page:statistics',
            title: '平台统计',
            url: '/dashboard/statistics',
            platform: Platform.media.id
        }, {
            key: 'page:income:stats',
            title: '财务中心',
            url: '/dashboard/finance/income/stats',
            platform: Platform.media.id
        }, {
            key: 'page:salary:stats',
            title: '薪资中心',
            url: '/dashboard/finance/salary/stats',
            platform: Platform.media.id
        }, 
    ]

    return getRouter({ role, router, data: { platform } })

}

export default FinanceLayoutConfig