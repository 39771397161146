import _ from 'lodash'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil';

import { userRoleState } from '../../Apps/atoms/Atoms'

const InvoiceSearchConfig = () => {
    const role = useRecoilValue(userRoleState)

    const defaultSearch = [
        {
            name: 'customer_name',
            label: '用户名称',
            placeholder: '请输入用户名称',
            type: 'input'
        }, {
            name: 'sale_name',
            label: '销售名称',
            placeholder: '请输入销售名称',
            type: 'input'
        }
    ]

    const timeSearch = [
        {
            name: 'start_time',
            label: '开始时间',
            placeholder: '请输入开始时间',
            type: 'datepicker',
            value: dayjs().subtract(7, 'days')
        }, {
            name: 'end_time',
            label: '结束时间',
            placeholder: '请输入结束时间',
            type: 'datepicker',
            value: null
        },
    ]


    let result = []

    switch (role) {
        case "FINANCE":
            result = _.concat(
                defaultSearch,
                timeSearch
            )
            break;
        default:
            result = _.concat(
                timeSearch
            )
            break;
    }

    return result
}

export default InvoiceSearchConfig